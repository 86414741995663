import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CarDocumentUploadBody, CarDocuments } from 'src/app/core/models/car.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-car-documents',
  templateUrl: './car-documents.component.html',
  styleUrls: ['./car-documents.component.scss']
})
export class CarDocumentsComponent implements OnInit {
  documents: CarDocuments[] = this.carFlowService.carDetails!.documents;

  docNameControl = new FormControl('', Validators.required)
  docVisibilityControl = new FormControl(true);

  docSelectedName = '';
  docSelectedData = '';

  routeSubscription = new Subscription();

  constructor(private utilsService: UtilsService,
    private carService: CarService,
    private carFlowService: CarFlowSyncService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit() {
    this.routeSubscription = this.carFlowService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.carFlowService.setCurrentTab(value);
      }
    );
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  uploadFile(event: Event) {
    const target = event.target as HTMLInputElement;

    let file = target.files![0];

    this.utilsService.convertToBase64(file).then(fileBase64 => {
      this.docSelectedData = fileBase64;
      this.docSelectedName = file.name;
    });
  }

  saveFile() {
    if (this.docNameControl.invalid || this.docSelectedData.length === 0) {
      this.snackbar.negativeSentiment('No document name or no document uploaded');

      return;
    }

    this.spinner.showProgressBar.next(true);

    let file = {
      name: this.docNameControl.value!,
      data: this.docSelectedData,
      visible: this.docVisibilityControl.value ? this.docVisibilityControl.value : false
    }

    let body = new CarDocumentUploadBody(this.carFlowService.carId, file);

    this.carService.addCarDocument(body).subscribe({
      next: resp => {
        this.docNameControl.reset();
        this.docVisibilityControl.reset(false);
        this.docSelectedName = '';
        this.docSelectedData = '';

        this.carFlowService.carDetails!.documents = resp;

        this.documents = resp;

        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Document uplodaded');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  removeFile() {
    this.docSelectedData = '';
    this.docSelectedName = '';
  }

  deleteFile(docId: string) {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    this.carService.deleteCarDocument(this.carFlowService.carId, docId).subscribe({
      next: resp => {
        this.documents.splice(this.documents.findIndex(d => d.id === docId), 1);

        this.carFlowService.carDetails!.documents = this.documents;

        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Document deleted');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  downloadFile(docId: string) {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    this.carService.downloadCarDocument(this.carFlowService.carId, docId).subscribe({
      next: resp => {
        window.open(resp, "_blank");

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }
}
