import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { CarsInventoryModule } from "../cars-inventory/cars-inventory.module";
import { CarShopCardComponent } from "./car-shop-card/car-shop-card.component";
import { CarViewPageComponent } from "./car-view-page/car-view-page.component";
import { InternalShopComponent } from "./internal-shop.component";

@NgModule({
  imports: [
    SharedModule,
    CarsInventoryModule
  ],
  declarations: [
    InternalShopComponent,
    CarShopCardComponent,
    CarViewPageComponent
  ]
})

export class InternalShopModule { }
