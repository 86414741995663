import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { BehaviorSubject, debounceTime, first, forkJoin, pipe, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { CarTaxesRequest, CreateCarJourneyBody, EditManualCarBody } from 'src/app/core/models/car.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CarManualUploadComponent } from '../../cars-inventory/car-manual-upload/car-manual-upload.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CarStatus } from 'src/app/core/models/info.model';

@Component({
  selector: 'app-car-details',
  templateUrl: './car-details.component.html',
  styleUrls: ['./car-details.component.scss']
})
export class CarDetailsComponent extends CarManualUploadComponent implements OnInit {
  subscriptions = new Subscription();

  car = this.carFlowSyncService.carDetails!;

  override technicalDataForm = this.fb.group({
    engineVolume: this.car.engineVolume,
    enginePower: [this.car.enginePower, Validators.required],
    bodyType: [this.car.bodyType, Validators.required],
    drivingWheels: [this.car.drivingWheels, Validators.required],
    co2NEDC: [this.car.co2NEDC, Validators.required],
    co2WLTP: [this.car.co2WLTP]
  })

  override pricesForm = this.fb.group({
    buyingPrice: [this.car.buyingPrice, Validators.required],
    minSellingPrice: [this.car.minSellingPrice, Validators.required],
    damages: [this.car.damages],
    vatStatus: [this.car.vatStatus, Validators.required],
  });

  override registrationForm = this.fb.group({
    firstReg: new FormControl<any>(this.car.firstReg),
    mileage: [this.car.mileage, Validators.required],
    manufactureYear: [this.car.manufactureYear.toString(), Validators.required],
    color: this.car.color,
    seats: this.car.seats,
    doors: this.car.doors
  });

  override identificationForm = this.fb.group({
    vin: new FormControl<string | null>(this.car.vin, [vinValidator()]),
    regNo: this.car.regNo,
    adLink: this.car.adLink,
    otherInfo: this.car.otherInfo,
    location: new FormControl<string | null>(this.car.location, Validators.required),
    availableFrom: new FormControl<any>(this.car.available?.from, Validators.required),
    cocFromSeller: this.car.cocFromSeller === true ? true : false
  });

  override carDrivingWheels = this.carFlowSyncService.carDrivingWheels;
  override carBodyTypes = this.carFlowSyncService.carBodyTypes;
  override carColors = this.carFlowSyncService.carColors;
  override journeyGroups = this.carFlowSyncService.carGroups;

  loadCarBaseData = new BehaviorSubject<boolean>(false);
  resetBaseInfo = false;

  @ViewChild('resetJourneysModal') resetJourneysModalTemplate: TemplateRef<any> | undefined;
  @ViewChild('changeJourneyGroupModal') changeJourneyGroupModalTemplate: TemplateRef<any> | undefined;
  @ViewChild('resetCarModal') resetCarModalTemplate: TemplateRef<any> | undefined;

  dialogRef = new BehaviorSubject<MatDialogRef<any> | null>(null);

  carStatus = CarStatus;

  journeyToBeUpdated = new FormControl();

  constructor(override carService: CarService,
    override snackbar: SnackbarService,
    override spinnerHandlerService: SpinnerHandlerService,
    override coreService: CoreService,
    override sellerService: SellerService,
    override carFlowSyncService: CarFlowSyncService,
    override utilsService: UtilsService,
    override router: Router,
    override dialog: MatDialog,
    override fb: FormBuilder) {
    super(fb, coreService, carFlowSyncService, spinnerHandlerService, carService, snackbar, dialog, router, utilsService, sellerService);
  }

  override ngOnInit() {
    this.subscriptions.add(this.carFlowSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (!this.carFlowSyncService.carId && value != 'ad-settings') {
          this.snackbar.negativeSentiment("Save ad settings and car details before continuing!");
          return;
        }
        if (this.technicalDataForm.dirty || this.identificationForm.dirty || this.registrationForm.dirty || this.pricesForm.dirty) {
          let goNext = await this.carFlowSyncService.showUnsavedChangesModal();

          goNext ? this.carFlowSyncService.setCurrentTab(value) : 0;
        } else {
          this.carFlowSyncService.setCurrentTab(value);
        }
      }
    ));

    // load seller info
    if (!this.carFlowSyncService.selectedSeller) {
      this.sellerService.getSeller(this.carFlowSyncService.carDetails!.seller).subscribe({
        next: resp => {
          this.selectedSeller = resp;
          this.carFlowSyncService.selectedSeller = resp;
          this.sellerControl.setValue(this.selectedSeller.sellerCompanyDetails.name);
        },
        error: err => {
          this.snackbar.negativeSentiment(err.error);
        }
      });
    } else {
      this.selectedSeller = this.carFlowSyncService.selectedSeller;
      this.sellerControl.setValue(this.selectedSeller.sellerCompanyDetails.name);
    }

    this.baseCarDataForm.patchValue(this.car);

    this.loadJourneys();

    this.subscriptions.add(this.dialogRef.asObservable().subscribe(dialogVal => {
      if (dialogVal) {
        dialogVal.afterClosed().pipe(first()).subscribe(resp => {
          if (dialogVal.id === 'reset-journeys' && resp) {
            this.resetJourney();
          } else if (dialogVal.id === 'journey-group-change' && resp) {
            this.updateJourney(this.journeyToBeUpdated);
          } else if (dialogVal.id === 'reset-car' && resp) {
            this.resetBaseData();
          }
        })
      }
    }));

    this.subscriptions.add(this.sellerControl.valueChanges.pipe(debounceTime(400), tap(() => { }), takeUntil(this.destroyed))
      .subscribe((value) => {
        if (value) {
          this.searchSeller(value);
        } else {
          this.resultItems.next([]);
        }
      }));

    this.subscriptions.add(this.resultItems.pipe(tap((result) => {
      this.existsResults.next(result.length > 0);
    })).subscribe());
  }

  override ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveCar() {
    if (this.spinnerHandlerService.showProgressBar.value) return;

    if (this.technicalDataForm.invalid || this.pricesForm.invalid || this.registrationForm.invalid || this.identificationForm.invalid) {
      this.technicalDataForm.markAllAsTouched();
      this.pricesForm.markAllAsTouched();
      this.registrationForm.markAllAsTouched();
      this.identificationForm.markAllAsTouched();
      this.snackbar.negativeSentiment("Not all required field completed!");
      return;
    }

    if (!this.selectedSeller) {
      this.snackbar.negativeSentiment('No seller selected');
      return;
    }

    this.spinnerHandlerService.showProgressBar.next(true);

    let car = new EditManualCarBody(this.baseCarDataForm.value, this.technicalDataForm.value, this.pricesForm.value, this.registrationForm.value, this.identificationForm.value, this.resetBaseInfo, this.selectedSeller!.companyId);

    this.carService.editCarDetails(car, this.carFlowSyncService.carId!).subscribe({
      next: resp => {
        this.carFlowSyncService.carDetails = {
          ...this.car,
          ...car
        };

        if (this.resetBaseInfo) {
          this.carFlowSyncService.c2vEq = [];
          this.carFlowSyncService.bc = [];
        }

        this.technicalDataForm.markAsPristine();
        this.identificationForm.markAsPristine();
        this.registrationForm.markAsPristine();
        this.pricesForm.markAsPristine();

        this.snackbar.positiveSentiment(resp);
        this.spinnerHandlerService.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
        this.spinnerHandlerService.showProgressBar.next(false);
      }
    })
  }

  duplicateCar() {
    this.coreService.openUnsavedChangesModal('You are about to leave this page. Are you sure?').then(resp => {
      if (resp) {
        this.carFlowSyncService.carDuplicateBody = this.car;

        this.loadingInfo.next(true);

        forkJoin({
          masterEq: this.carService.getCarMasterEquipments(this.car.carMainInfoId),
          customEq: this.carService.getCarCustomEquipments(this.car.carMainInfoId)
        }).subscribe(resp => {
          if (!resp.masterEq.standardEquipment) {
            this.carFlowSyncService.carMasterEq = {
              standardEquipment: [],
              optionalEquipment: [],
              packEquipment: []
            }
          } else {
            this.carFlowSyncService.carMasterEq = resp.masterEq;
          }
          this.carFlowSyncService.carC2VEq = resp.customEq;

          this.loadingInfo.next(false);

          this.router.navigate(['online-car-upload']);
        });
      }
    })
  }

  loadCarTaxes() {
    let body: CarTaxesRequest = {
      method: 'Generate',
      cars: [{
        make: this.car.make,
        model: this.car.model,
        fuelType: this.car.fuelType,
        engineVolume: this.technicalDataForm.controls.engineVolume.value,
        enginePower: this.technicalDataForm.controls.enginePower.value,
        co2WLTP: this.technicalDataForm.controls.co2WLTP.value,
        co2NEDC: this.technicalDataForm.controls.co2NEDC.value,
        drivingWheel: this.technicalDataForm.controls.drivingWheels.value,
        gearbox: this.car.gearbox,
        firstReg: this.registrationForm.controls.firstReg.value,
        buyingPrice: this.pricesForm.controls['buyingPrice'].value
      }]
    }

    this.carService.getCarTaxes(body).subscribe(resp => {
      this.carTaxes = resp[0].taxes.map(t => ({ value: t.tax, country: t.country }));
    })
  }

  override loadJourneys() {
    this.car.carGroupJourney.forEach(j => {
      let localeStartTime = this.utilsService.utcDateToLocal(j.dateFrom);
      let localeEndTime = this.utilsService.utcDateToLocal(j.dateTo);

      let startTime = this.utilsService.utcDateToLocal(j.dateFrom).split(' ')[1].split(':')[0];
      let endTime = this.utilsService.utcDateToLocal(j.dateTo).split(' ')[1].split(':')[0];

      const journey = new FormControl({
        group: new FormControl<string>(j.groupId, Validators.required),
        from: new FormControl<Date>(new Date(localeStartTime), Validators.required),
        until: new FormControl<Date>(new Date(localeEndTime), Validators.required),
        startTime: new FormControl<string>(startTime, Validators.required),
        endTime: new FormControl<string>(endTime, Validators.required),
        price: new FormControl<number>(j.sellingPrice, Validators.required),
        journeyId: new FormControl(j.journeyId),
        unavailabilityForm: this.fb.group({
          startDate: [new Date()],
          endDate: [new Date(localeStartTime) > new Date() ? new Date() : new Date(localeStartTime)]
        }),
        pastJourney: false
      });

      if (moment(localeEndTime).isBefore(moment())) journey.value!.pastJourney = true;

      this.journeysGroupForm.push(journey);
    });

    this.journeysGroupForm.controls.forEach((jc, index) => {
      jc.value.startTime.disable();
      jc.value.from.disable();

      if (index + 1 < this.journeysGroupForm.controls.length) {
        jc.value.endTime.disable();
        jc.value.until.disable();
      }

      if (jc.value!.pastJourney || this.car.carStatus === CarStatus.Reserved) {
        jc.value.group.disable();
        jc.value.price.disable();
        jc.value.endTime.disable();
        jc.value.until.disable();
      }
    });
  }

  override addJourney() {
    if (this.journeysGroupForm.controls.some(c => c.value['journeyId'].value === 'newJourney')) {
      this.snackbar.negativeSentiment('You must complete previous journey before adding a new one!');
      return;
    }

    let firstJourney = true;
    let lastJourney = new FormControl<any>('');

    if (this.journeysGroupForm.controls.length > 0) {
      lastJourney = this.journeysGroupForm.controls.at(-1)!;

      lastJourney.value.from.disable();
      lastJourney.value.until.disable();
      lastJourney.value.startTime.disable();
      lastJourney.value.endTime.disable();

      firstJourney = false;
    }

    const journey = new FormControl({
      group: new FormControl<string | null>('', Validators.required),
      from: new FormControl<string | null>(
        {
          value: !firstJourney ? lastJourney.value.until.value : null,
          disabled: !firstJourney
        }, Validators.required),
      until: new FormControl<string | null>(null, Validators.required),
      startTime: new FormControl<string | null>({
        value: !firstJourney ? lastJourney.value.endTime.value : null,
        disabled: !firstJourney
      }, Validators.required),
      endTime: new FormControl<string | null>(null, Validators.required),
      price: new FormControl<number | null>(null, Validators.required),
      journeyId: new FormControl(`newJourney`),
      unavailabilityForm: this.fb.group({
        startDate: [firstJourney ? new Date() : lastJourney.value.to]
      })
    });

    this.journeysGroupForm.push(journey);
  }

  getMinJourneyEndDate(j: FormControl) {
    if (j.value.from.value && j.value.from.value < new Date()) {
      return new Date();
    } else if (j.value.from.value) {
      return j.value.from.value;
    } else {
      return null;
    }
  }

  saveJourney() {
    if (this.spinnerHandlerService.showProgressBar.value) return;

    let journey = this.journeysGroupForm.controls[this.journeysGroupForm.controls.length - 1];

    if (journey.value['from'].invalid || journey.value['until'].invalid || journey.value['group'].invalid || journey.value['price'].invalid || journey.value['startTime'].invalid || journey.value['endTime'].invalid) {
      journey.value['from'].markAsTouched();
      journey.value['price'].markAsTouched();
      journey.value['until'].markAsTouched();
      journey.value['group'].markAsTouched();
      journey.value['startTime'].markAsTouched();
      journey.value['endTime'].markAsTouched();

      return;
    }

    if (this.sellingPriceValidation(journey)) {
      this.snackbar.negativeSentiment('Selling price must be higher than min selling price');
      return;
    }

    let body = new CreateCarJourneyBody(moment(journey.value.from.value).format('yyyy-MM-DD'),
      journey.value.startTime.value,
      moment(journey.value.until.value).format('yyyy-MM-DD'),
      journey.value.endTime.value,
      journey.value.group.value,
      this.car.carMainInfoId,
      journey.value.price.value
    );

    if (new Date(body.dateTo) <= new Date(body.dateFrom)) {
      this.snackbar.negativeSentiment('Journey end date and time is set before start time');

      return;
    }

    this.spinnerHandlerService.showProgressBar.next(true);

    this.carService.createCarJourneyGroup(body).subscribe({
      next: resp => {
        this.journeysGroupForm.controls[this.journeysGroupForm.controls.length - 1].value.journeyId.value = resp;

        this.carFlowSyncService.carDetails!.carGroupJourney.push({
          ...body,
          journeyId: resp,
          groupId: body.groupId
        });

        this.spinnerHandlerService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Journey saved');
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinnerHandlerService.showProgressBar.next(false);
      }
    })
  }

  setHours(hour: string) {
    this.inactiveFrom = new Date(moment(this.inactiveFrom).hours(parseInt(hour)).format('yyyy/MM/DD HH:mm'));
  }

  checkJourneyUpdate(journey: FormControl<any>) {
    let journeyId = journey.value['journeyId'].value;

    const carJourney = this.carFlowSyncService.carDetails!.carGroupJourney.find(j => j.journeyId === journeyId)!;

    if (carJourney.groupId != journey.value['group'].value) {
      this.journeyToBeUpdated = journey;

      this.dialogRef.next(this.dialog.open(this.changeJourneyGroupModalTemplate!, {
        width: '800px',
        maxWidth: '90vw',
        id: 'journey-group-change',
      }));

    } else {
      this.updateJourney(journey);
    }
  }

  updateJourney(journey: FormControl<any>) {
    if (journey.value['from'].invalid || journey.value['until'].invalid || journey.value['group'].invalid || journey.value['price'].invalid || journey.value['startTime'].invalid || journey.value['endTime'].invalid) {
      journey.value['from'].markAsTouched();
      journey.value['price'].markAsTouched();
      journey.value['until'].markAsTouched();
      journey.value['group'].markAsTouched();
      journey.value['startTime'].markAsTouched();
      journey.value['endTime'].markAsTouched();

      return;
    }

    if (this.sellingPriceValidation(journey)) {
      this.snackbar.negativeSentiment('Selling price must be higher than min selling price');
      return;
    }

    if (this.spinnerHandlerService.showProgressBar.value) return;

    let body = new CreateCarJourneyBody(moment(journey.value.from.value).format('yyyy-MM-DD'),
      journey.value.startTime.value,
      moment(journey.value.until.value).format('yyyy-MM-DD'),
      journey.value.endTime.value,
      journey.value.group.value,
      this.car.carMainInfoId,
      journey.value.price.value);

    if (new Date(body.dateTo) <= new Date(body.dateFrom)) {
      this.snackbar.negativeSentiment('Journey end date and time is set before start time');

      return;
    }

    this.spinnerHandlerService.showProgressBar.next(true);

    this.carService.editCarJourneyGroup(body, journey.value.journeyId.value).subscribe({
      next: resp => {
        this.spinnerHandlerService.showProgressBar.next(false);

        let index = this.car.carGroupJourney.findIndex(cj => cj.journeyId === journey.value.journeyId.value);

        this.carFlowSyncService.carDetails!.carGroupJourney[index] = {
          ...this.carFlowSyncService.carDetails!.carGroupJourney[index],
          groupId: body.groupId,
          dateTo: body.dateTo,
          sellingPrice: body.sellingPrice
        };

        this.snackbar.positiveSentiment('Journey updated');
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinnerHandlerService.showProgressBar.next(false);
      }
    })
  }

  resetBaseData() {
    this.loadCarBaseData.next(true);

    forkJoin({
      models: this.carService.getCarNomenclatorData(`models?make=${this.car.make}`),
      fuelTypes: this.carService.getCarSpecificationData(`fuelTypes?make=${encodeURIComponent(this.car.make)}&model=${encodeURIComponent(this.car.model)}`),
      gearboxes: this.carService.getCarSpecificationData(`gearboxes?make=${encodeURIComponent(this.car.make)}&model=${encodeURIComponent(this.car.model)}&fuelType=${encodeURIComponent(this.car.fuelType)}`),
      variants: this.carService.getCarNomenclatorData(`variants?make=${encodeURIComponent(this.car.make)}&model=${encodeURIComponent(this.car.model)}&fuelType=${encodeURIComponent(this.car.fuelType)}&gearbox=${encodeURIComponent(this.car.gearbox)}`),
      trims: this.carService.getCarNomenclatorData(`trims?make=${encodeURIComponent(this.car.make)}&model=${encodeURIComponent(this.car.model)}&fuelType=${encodeURIComponent(this.car.fuelType)}&gearbox=${encodeURIComponent(this.car.gearbox)}&variant=${encodeURIComponent(this.car.variant)}`),
    }).subscribe(resp => {
      this.carModels = resp.models.map(m => { return { viewValue: m, value: m } });
      this.carFuelTypes = resp.fuelTypes.map(m => { return { viewValue: m.name, value: m.name } });
      this.carGearboxes = resp.gearboxes.map(m => { return { viewValue: m.name, value: m.name } });
      this.carVariants = resp.variants.map(m => { return { viewValue: m, value: m } });
      this.carTrims = resp.trims.map(m => { return { viewValue: m, value: m } });

      this.baseCarDataForm.enable();

      this.baseCarDataForm.patchValue({
        make: this.car.make,
        model: this.car.model,
        fuelType: this.car.fuelType,
        gearbox: this.car.gearbox,
        variant: this.car.variant,
        trim: this.car.trim
      });

      this.resetBaseInfo = true;

      this.loadCarBaseData.next(false);
    });
  }

  openWarningModalModal(templateRef: TemplateRef<any>, id: string) {
    this.dialogRef.next(this.dialog.open(templateRef, {
      width: '800px',
      maxWidth: '90vw',
      id: id,
    }));
  }

  cancelModal() {
    this.dialogRef.value?.close(false);
  }

  confirmModal() {
    this.dialogRef.value?.close(true);
  }

  resetJourney() {
    this.spinnerHandlerService.showProgressBar.next(true);

    this.carService.resetAllCarJourneyGroup(this.car.carMainInfoId).subscribe({
      next: resp => {
        this.carFlowSyncService.carDetails!.carGroupJourney = [];
        this.journeysGroupForm = new FormArray<FormControl>([]);

        this.car.carStatus = 'Inactive';

        this.snackbar.positiveSentiment(resp);

        this.spinnerHandlerService.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinnerHandlerService.showProgressBar.next(false);
      }
    })
  }
}

export function vinValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      let vin: string = control.value.toUpperCase();

      if (control.value.length != 17) {
        return { 'invalidLength': true };
      } else if (vin.includes('I') || vin.includes('O') || vin.includes('Q')) {
        return { 'invalidChars': true };
      }
    }
    return null;
  };
}
