<div class="dropdown-filter relative">
  <p class="absolute z-10 text-white-300 text-xs -top-5" *ngIf="label">{{label}}</p>
  <mat-form-field class="border border-white-300 w-full bg-white rounded text-blue-300" appearance="outline">
    <input [id]="id + '-dropdown-filter'" [attr.data-cy]="id + '-dropdown-filter'" type="text" placeholder="Pick one"
      matInput [formControl]="dropdownControl" [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
