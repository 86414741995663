import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-add-car-group-modal',
  templateUrl: './add-car-group-modal.component.html',
  styleUrls: ['./add-car-group-modal.component.scss']
})
export class AddCarGroupModalComponent implements OnInit {
  nameControl = new FormControl("", Validators.required);

  constructor(private settingsService: SettingsService,
    private snackbar: SnackbarService,
    public dialogRef: MatDialogRef<AddCarGroupModalComponent>) { }

  ngOnInit() {
  }

  keyPress(key: string) {
    if (key === 'Enter') this.saveCarGroup();
  }

  saveCarGroup() {
    if (this.nameControl.invalid) {
      this.snackbar.negativeSentiment("Name is required");
      return;
    }

    this.dialogRef.close(this.nameControl.value!);
  }

  close() {
    this.dialogRef.close();
  }
}
