import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { SearchOrdersBody, ShortInfoOrder } from 'src/app/core/models/order.model';
import { OrdersService } from 'src/app/core/services/orders.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { MonthList } from '../../settings/country-holiday/country-holiday.component';
import moment from 'moment';
import { FormBuilder } from '@angular/forms';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { CoreService } from 'src/app/core/services/core.service';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from 'src/app/core/services/user.service';

export enum OrderStatus {
  FINISHED = "FINISHED",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  CANCELED = "CANCELED",
}

@Component({
  selector: 'app-orders-view',
  templateUrl: './orders-view.component.html',
  styleUrls: ['./orders-view.component.scss']
})
export class OrdersViewComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);
  tableLoading = new BehaviorSubject<boolean>(false);

  orders: ShortInfoOrder[] = [];

  kams: DropdownOption[] = [];

  tableColumns: TableHeaderMap[] = [
    {
      value: 'orderId',
      tableView: 'order id'
    },
    {
      value: 'buyer',
      tableView: 'buyer'
    },
    {
      value: 'delegatedKam',
      tableView: 'delegated Kam'
    },
    {
      value: 'orderDate',
      tableView: 'order date',
      isDateHours: true
    },
    {
      value: 'status',
      tableView: 'order status'
    },
    {
      value: 'profit',
      tableView: 'total profit'
    },
    {
      value: 'invoiceSellingPrice',
      tableView: 'total invoiced'
    },
    {
      value: 'carsInOrder',
      tableView: 'cars in order'
    }
  ];

  displayedColumns = this.tableColumns.map(t => t.value).concat(['details']);

  ordersStatusData: any;
  finishedStatusData: any;

  orderStatuses = OrderStatus;
  months = Object.keys(MonthList);

  currentMonth = '';
  year = 1990;
  totalInvoicedCurrentYear = 0;

  mostProfitableMonth = '';
  mostProfitableMonthValue = 0;

  leastProfitableMonth = '';
  leastProfitableMonthValue = 0;

  filterGroup = this.fb.group({
    orderId: [''],
    createdBy: [''],
    delegatedKam: [''],
    buyer: [''],
    status: [''],
    paymentStatus: [''],
    dateFrom: [''],
    dateTo: [''],
  })

  statusesDropdown: DropdownOption[] = Object.keys(this.orderStatuses).map(s => ({ value: s, viewValue: s }));

  isAdmin = this.coreService.isAdmin;

  results = 0;

  @ViewChild('paginator') paginator: MatPaginator | undefined;

  constructor(private orderservice: OrdersService,
    private router: Router,
    private coreService: CoreService,
    private userService: UserService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    forkJoin({
      kams: this.isAdmin ? this.userService.getKAMUsers() : of(null),
      orders: this.orderservice.getOrders({ page: 0, itemsPerPage: 5 }),
    }).subscribe(resp => {
      this.orders = resp.orders.orders;

      this.results = resp.orders.nrOfOrders;

      if (resp.kams) {
        this.kams = resp.kams.map(k => ({ value: k.username, viewValue: k.username }));
      }

      this.year = new Date().getFullYear();
      let currentMonth = new Date().getMonth();

      this.currentMonth = MonthList[currentMonth];

      this.setPieChartData();

      this.setLineChartData(currentMonth);

      this.setProfitData(currentMonth);

      this.loading.next(false);

      setTimeout(() => {
        const mostProfitable = this.mostProfitableMonthValue / this.totalInvoicedCurrentYear * 100;
        const leastrofitable = this.leastProfitableMonthValue / this.totalInvoicedCurrentYear * 100;

        document.getElementById('mostProfitable')!.style.width = `${mostProfitable}%`;
        document.getElementById('leastProfitable')!.style.width = `${leastrofitable}%`;
      }, 0)
    });
  }

  goToOrder(order: ShortInfoOrder) {
    if ((order.status === OrderStatus.PENDING || order.status === OrderStatus.APPROVED) && this.isAdmin) {
      this.router.navigate([`process-order/${order.orderId}`]);
    } else if (this.coreService.user!.role === 'KAM') {
      this.router.navigate([`order-kam-view/${order.orderId}`]);
    } else if (order.status === OrderStatus.FINISHED || order.status === OrderStatus.CANCELED) {
      this.router.navigate([`order/${order.orderId}`]);
    } else if (order.status === OrderStatus.PENDING) {
      this.router.navigate([`order-pending-process/${order.orderId}`]);
    }
  }

  setPieChartData() {
    let activeOrdersPercentage = '0';
    let savedOrdersPercentage = '0';
    let finishedOrdersPercentage = '0';
    let pendingOrdersPercentage = '0';

    if (this.orders.length > 0) {
      activeOrdersPercentage = (this.orders.filter(o => o.status === this.orderStatuses.APPROVED).length / this.orders.length * 100).toFixed(2);
      savedOrdersPercentage = (this.orders.filter(o => o.status === this.orderStatuses.CANCELED).length / this.orders.length * 100).toFixed(2);
      finishedOrdersPercentage = (this.orders.filter(o => o.status === this.orderStatuses.FINISHED).length / this.orders.length * 100).toFixed(2);
      pendingOrdersPercentage = (this.orders.filter(o => o.status === this.orderStatuses.PENDING).length / this.orders.length * 100).toFixed(2);
    }

    this.ordersStatusData = {
      labels: [
        `Active orders ${activeOrdersPercentage}%`,
        `Pending orders  ${pendingOrdersPercentage}%`,
        `Finished orders  ${finishedOrdersPercentage}%`,
        `Not sent orders  ${savedOrdersPercentage}%`
      ],
      datasets: [{
        data: [
          activeOrdersPercentage,
          pendingOrdersPercentage,
          finishedOrdersPercentage,
          savedOrdersPercentage
        ],
        pointStyle: 'circle',
        pointBorderColor: 'rgb(0, 0, 0)',
        backgroundColor: [
          '#32D9EE',
          '#32FF46',
          '#778BEB',
          '#FD4082',
          '#FFBF32'
        ],
        hoverOffset: 4,
        cutout: '80%',
        label: `Orders status - ${this.year}`
      }],
    };
  }

  setLineChartData(month: number) {
    let orders = this.orders.filter(o => moment(o.orderDate).year() === this.year && o.status === this.orderStatuses.FINISHED);
    let monthsData = [];

    for (let i = 0; i < month + 1; i++) {
      monthsData.push(orders.filter(o => moment(o.orderDate).month() === i).length);
    }

    this.finishedStatusData = {
      labels: this.months.filter(m => isNaN(Number(m))).map(m => m.substring(0, 3)),
      datasets: [{
        label: `Finished orders ${this.year} - monthly breakdown`,
        data: monthsData,
        fill: true,
        borderColor: '#A349FD',
        backgroundColor: '#F9F4FF'
      }],
    }
  }

  setProfitData(month: number) {
    this.totalInvoicedCurrentYear = this.orders.filter(o => moment(o.orderDate).year() === this.year && o.status === this.orderStatuses.FINISHED).reduce((sum: number, order: ShortInfoOrder) => {
      return sum + order.invoiceSellingPrice;
    }, 0);

    let monthsData = [];

    for (let i = 0; i < month + 1; i++) {
      monthsData.push(this.orders.filter(o => moment(o.orderDate).year() === this.year && moment(o.orderDate).month() === i && o.status === this.orderStatuses.FINISHED).reduce((sum: number, order: ShortInfoOrder) => {
        return sum + order.profit;
      }, 0));
    }

    this.mostProfitableMonthValue = Math.max(...monthsData);
    this.mostProfitableMonth = MonthList[monthsData.findIndex(m => m === this.mostProfitableMonthValue)];

    this.leastProfitableMonthValue = Math.min(...monthsData);
    this.leastProfitableMonth = MonthList[monthsData.findIndex(m => m === this.leastProfitableMonthValue)];
  }

  searchOrders(resetPage?: boolean) {
    this.tableLoading.next(true);

    let filters = this.filterGroup.value;

    let body: SearchOrdersBody = {
      page: resetPage ? 0 : this.paginator!.pageIndex,
      itemsPerPage: this.paginator!.pageSize,
      orderId: filters.orderId ? filters.orderId : undefined,
      createdBy: filters.createdBy ? filters.createdBy : undefined,
      delegatedKam: filters.delegatedKam ? filters.delegatedKam : undefined,
      buyer: filters.buyer ? filters.buyer : undefined,
      status: filters.status ? filters.status : undefined,
      paymentStatus: filters.paymentStatus ? filters.paymentStatus : undefined,
      dateFrom: filters.dateFrom ? moment(filters.dateFrom).format('yyyy-MM-DD') : undefined,
      dateTo: filters.dateTo ? moment(filters.dateTo).format('yyyy-MM-DD') : undefined,
    };

    this.orderservice.getOrders(body).subscribe(resp => {
      this.orders = resp.orders;

      this.tableLoading.next(false);

      if (resetPage) this.paginator!.pageIndex = 0;
    })
  }

  resetFilters() {
    this.filterGroup.reset();

    this.searchOrders(true);
  }
}
