import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SellerOffer } from 'src/app/core/models/offer.model';
import { OffersService } from 'src/app/core/services/offers.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';

interface SellerOfferCounterOfferModalData {
  offer: SellerOffer
}

@Component({
  selector: 'app-seller-offer-counter-offer-modal',
  templateUrl: './seller-offer-counter-offer-modal.component.html',
  styleUrls: ['./seller-offer-counter-offer-modal.component.scss']
})
export class SellerOfferCounterOfferModalComponent implements OnInit {
  currentOffer = this.data.offer.currentPrice;

  priceControl = new FormControl(null, Validators.required);

  constructor(public dialogRef: MatDialogRef<SellerOfferCounterOfferModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SellerOfferCounterOfferModalData,
    private offerService: OffersService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit(): void {
  }

  sendCounterOffer() {
    if (this.priceControl.invalid) {
      this.priceControl.markAsTouched();

      return;
    }

    this.spinner.showProgressBar.next(true);

    this.offerService.counterSellerOffer(this.data.offer.id, this.priceControl.value!).subscribe(resp => {
      this.spinner.showProgressBar.next(false);

      this.dialogRef.close(resp);
    });
  }
}
