<div class="flex flex-col gap-9">
  <p class="font-medium">Search car by ID</p>

  <div class="grid grid-cols-3 gap-4">
    <app-input id="search-id" class="col-span-2" [control]="searchControl" placeholder="Insert ID"></app-input>
    <secondary-btn id="search-car" class="col-span-1" btnText="Search" (clickEmitter)="searchCar()"></secondary-btn>
  </div>

  <p *ngIf="error.length > 0" class="text-red text-sm">{{error}}</p>
</div>
