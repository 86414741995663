import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Buyer } from "../models/buyer.model";
import { AddCarsToOrderBody, AllOrderTasks, ApproveCarBody, CarOrderDetailsResp, ConfirmSellerPaymentBody, CreateOrderBodyClass, CreateTransportBody, GetOrdersResponse, IDocument, IOrderCarFlatView, Order, OrderCar, OrderSeller, OrderTeammate, OrderTransportResponse, OrderUpdatedProfitResponse, RemoveCarFromOrderBody, RemoveOrderDocumentBody, SaveProfitSplitBody, SaveSellerCurrencyBody, SearchOrdersBody, SearchOrdersCarsBody, ShortInfoOrder, UpdateCarAvailability, UpdateCarAvailabilityAndReadyForPickupBody, UpdateCarDeliveryDetailsClass, UpdateCarPickupDetailsClass, UpdateCarPriceSettingsBody, UpdateCarTransportCostBody, UpdateCarsInvoicingCompanyResp, UpdateDocumentsAddressBody, UpdateDocumentsDetailsBody, UpdateOrderOptionsBody, UpdateTransportDetailsBody, UpdateTransportDocumentsSendDate, UpdateTransportStatusBody, UploadCarDocumentBody, UploadOrderDocumentBody, UploadTransportDocumentBody } from "../models/order.model";

@Injectable({
  providedIn: 'root'
})

export class OrdersService {

  constructor(private http: HttpClient) { }

  public getUserBuyers(): Observable<Buyer[]> {
    return this.http.get<Buyer[]>(`${environment.backendApiUrl}search-buyer-companies`);
  }

  public createOrder(order: CreateOrderBodyClass): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}send-order`, order);
  }

  public finalizeOrder(order: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}finalize-order`, { orderId: order });
  }

  public saveCurrentOrder(order: CreateOrderBodyClass): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}save-order`, order);
  }

  public dropOrder(orderId: string, reason: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}delete-order`, { orderId: orderId, reason: reason });
  }

  public getOrders(body: SearchOrdersBody): Observable<GetOrdersResponse> {
    return this.http.post<GetOrdersResponse>(`${environment.backendApiUrl}get-orders`, body);
  }

  public getOrder(orderId: string): Observable<Order> {
    return this.http.get<Order>(`${environment.backendApiUrl}get-order/${orderId}`);
  }

  public getOrdersCarsFlatView(body: SearchOrdersCarsBody): Observable<IOrderCarFlatView[]> {
    return this.http.post<IOrderCarFlatView[]>(`${environment.backendApiUrl}orders-cars-flat-view`, body);
  }

  public getCreatedOrder(orderId: string): Observable<Order> {
    return this.http.get<Order>(`${environment.backendApiUrl}get-sent-order-details/${orderId}`);
  }

  public updateCarDeliveryDetails(body: UpdateCarDeliveryDetailsClass): Observable<OrderSeller[]> {
    return this.http.post<OrderSeller[]>(`${environment.backendApiUrl}update-delivery-and-contact`, body);
  }

  public updateBulkDeliveryDetails(orderId: string): Observable<OrderSeller[]> {
    return this.http.post<OrderSeller[]>(`${environment.backendApiUrl}bulk-update-delivery-and-contact`, { orderId: orderId });
  }

  public updateCarPickupDetails(body: UpdateCarPickupDetailsClass): Observable<OrderSeller[]> {
    return this.http.post<OrderSeller[]>(`${environment.backendApiUrl}update-car-pickup-location-and-ready-at-and-contact`, body);
  }

  public addCarToOrder(body: AddCarsToOrderBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}add-car-to-sent-order`, body);
  }

  public requestConfirmation(carIds: string[]): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}request-seller-car-availability`, { cars: carIds });
  }

  public sendConfirmation(orderId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}send-buyer-confirmation/${orderId}`, {});
  }

  public removeCarsFromOrder(body: RemoveCarFromOrderBody): Observable<any> {
    return this.http.delete<any>(`${environment.backendApiUrl}remove-car-from-sent-order`, { body: body });
  }

  public updateCarAvailabilityAndReadyForPickup(body: UpdateCarAvailabilityAndReadyForPickupBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}update-order-car-availability`, body);
  }

  public updateCarReadyAt(body: any): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}update-order-car-ready-at`, body);
  }

  public saveProfitSplit(body: SaveProfitSplitBody) {
    return this.http.post<any>(`${environment.backendApiUrl}set-profit-split`, body);
  }

  public getCarsFromOrder(orderId: string): Observable<any> {
    return this.http.get<any>(`${environment.backendApiUrl}get-sellers-and-cars-from-order/${orderId}`);
  }

  public getOrderPossibleTeammates(): Observable<OrderTeammate[]> {
    return this.http.get<OrderTeammate[]>(`${environment.backendApiUrl}get-possible-teammates`);
  }

  public addTeammateToOrder(userId: string, orderId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}add-custom-teammate`, { orderId: orderId, userId: userId });
  }

  public removeTeammateToOrder(userId: string, orderId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}remove-custom-teammate`, { orderId: orderId, userId: userId });
  }

  public saveSellerCurrency(body: SaveSellerCurrencyBody) {
    return this.http.post<any>(`${environment.backendApiUrl}set-seller-currency-exchange-rate`, body);
  }

  public updateCarPriceSettings(body: UpdateCarPriceSettingsBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}update-car-pricing-additional`, body);
  }

  public updateCarDocumentsSettings(body: UpdateDocumentsDetailsBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}update-car-documents-details`, body);
  }

  public updateCarDocumentsAddress(body: UpdateDocumentsAddressBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}update-car-documents-additional-details`, body);
  }

  public setMainActor(orderId: string, userId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}set-main-actor`, { orderId: orderId, userId: userId });
  }

  public confirmBuyerPayment(orderId: string, paid: boolean, date: Date): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}update-buyer-paid`, { orderId: orderId, buyerPaid: paid, buyerPaidAt: date });
  }

  public confirmSellerPayment(body: ConfirmSellerPaymentBody): Observable<OrderSeller[]> {
    return this.http.post<OrderSeller[]>(`${environment.backendApiUrl}update-seller-payment`, body);
  }

  public sendSellerEmailNotification(orderId: string, sellerId: string, invoiceEmailSent: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}payment-email-sent-to-seller`, { orderId: orderId, sellerId: sellerId, invoiceEmailSent: invoiceEmailSent });
  }

  public sendKAMReminder(orderId: string, sellerId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}kam-seller-payment-remainder/${orderId}/${sellerId}`, {});
  }

  public uploadCarDocument(body: UploadCarDocumentBody): Observable<any[]> {
    return this.http.post<any[]>(`${environment.backendApiUrl}upload-order-car-document`, body);
  }

  public getTasks(orderId: string): Observable<AllOrderTasks> {
    return this.http.get<AllOrderTasks>(`${environment.backendApiUrl}get-mandatory-tasks/${orderId}`);
  }

  public getOrderProfits(orderId: string): Observable<OrderUpdatedProfitResponse> {
    return this.http.get<OrderUpdatedProfitResponse>(`${environment.backendApiUrl}get-updated-order-profits/${orderId}`);
  }

  public updateOrderBatchID(orderId: string, batchId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}update-order-vin-batch-id`, { orderId: orderId, batchId: batchId, });
  }

  public approveProposedCar(body: ApproveCarBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}approve-proposed-car`, body);
  }

  public rejectProposedCar(body: ApproveCarBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}reject-proposed-car`, body);
  }

  public createOrderTransport(body: CreateTransportBody): Observable<OrderTransportResponse> {
    return this.http.post<OrderTransportResponse>(`${environment.backendApiUrl}add-transport-order`, body);
  }

  public addCarsToTransport(orderId: string, transportId: string, carIds: string[]): Observable<OrderTransportResponse> {
    return this.http.post<OrderTransportResponse>(`${environment.backendApiUrl}add-cars-to-transport-order`, { orderId: orderId, transportId: transportId, cars: carIds });
  }

  public removeCarsFromTransport(orderId: string, transportId: string, carIds: string[]): Observable<OrderTransportResponse> {
    return this.http.post<OrderTransportResponse>(`${environment.backendApiUrl}remove-cars-from-transport`, { orderId: orderId, transportId: transportId, cars: carIds });
  }

  public updateCarTransportCost(body: UpdateCarTransportCostBody): Observable<Order> {
    return this.http.post<Order>(`${environment.backendApiUrl}update-car-transport-actual-value`, body);
  }

  public uploadTransportDocument(body: UploadTransportDocumentBody): Observable<OrderTransportResponse> {
    return this.http.post<OrderTransportResponse>(`${environment.backendApiUrl}upload-transport-order-document`, body);
  }

  public updateTransportInvoiceSentDate(body: UpdateTransportDocumentsSendDate): Observable<OrderTransportResponse> {
    return this.http.post<OrderTransportResponse>(`${environment.backendApiUrl}update-transport-invoice-sent-date`, body);
  }

  public updateCMRSentDate(body: UpdateTransportDocumentsSendDate): Observable<OrderTransportResponse> {
    return this.http.post<OrderTransportResponse>(`${environment.backendApiUrl}update-transport-cmr-date`, body);
  }

  public updateTransportDeliveryDate(body: UpdateTransportDocumentsSendDate): Observable<OrderTransportResponse> {
    return this.http.post<OrderTransportResponse>(`${environment.backendApiUrl}set-estimated-delivery-date`, body);
  }

  public updateOrderOptions(body: UpdateOrderOptionsBody): Observable<Order> {
    return this.http.post<Order>(`${environment.backendApiUrl}update-order-options`, body);
  }

  public getTransportStatuses(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.backendApiUrl}get-transport-statuses`);
  }

  public updateTransportStatus(body: UpdateTransportStatusBody): Observable<OrderTransportResponse> {
    return this.http.post<OrderTransportResponse>(`${environment.backendApiUrl}update-transport-status`, body);
  }

  public updateTransportDetails(body: UpdateTransportDetailsBody): Observable<OrderTransportResponse> {
    return this.http.post<OrderTransportResponse>(`${environment.backendApiUrl}update-transport-order-details`, body);
  }

  public getCarDetails(orderId: string, sellerId: string, carMainInfoId: string): Observable<CarOrderDetailsResp> {
    return this.http.post<CarOrderDetailsResp>(`${environment.backendApiUrl}see-car-details`, { orderId: orderId, sellerId: sellerId, carMainInfoId: carMainInfoId });
  }

  public updateCarsInvoicingCompany(orderId: string, cars: { carMainInfoId: string, invoicingCompanyId: string }[]): Observable<UpdateCarsInvoicingCompanyResp> {
    return this.http.post<UpdateCarsInvoicingCompanyResp>(`${environment.backendApiUrl}update-cars-invoicing-company`, { orderId: orderId, cars: cars });
  }

  public updateCarAvailability(body: UpdateCarAvailability): Observable<Order> {
    return this.http.post<Order>(`${environment.backendApiUrl}update-car-availability`, body);
  }

  public updateRemovedCarAvailability(orderId: string, carMainInfoId: string): Observable<Order> {
    return this.http.post<Order>(`${environment.backendApiUrl}update-removed-car-availability`, { orderId: orderId, carMainInfoId: carMainInfoId });
  }

  public uploadOrderDocument(body: UploadOrderDocumentBody): Observable<IDocument[]> {
    return this.http.post<IDocument[]>(`${environment.backendApiUrl}upload-order-document`, body);
  }

  public downloadOrderDocument(orderId: string, docId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}download-order-document`, { orderId: orderId, id: docId });
  }

  public downloadTransportDocument(body: { orderId: string, transportId: string, docType: string, id: string }): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}download-transport-order-document`, body);
  }

  public downloadOrderCarDocument(body: { orderId: string, carMainInfoId: string, docType: string, id: string }): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}download-order-car-doc`, body);
  }

  public removeOrderDocument(body: RemoveOrderDocumentBody): Observable<IDocument[]> {
    return this.http.post<IDocument[]>(`${environment.backendApiUrl}remove-order-document`, body);
  }

  public getOrderSubstatuses(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.backendApiUrl}get-sub-statuses`);
  }

  public updateOrderSubstatus(orderId: string, substatus: string): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}update-order-sub-status`, { subStatus: substatus, orderId: orderId });
  }
}
