<div class="flex flex-col gap-9">
  <!-- <div class="flex justify-between">
    <div class="flex gap-6">
      <primary-btn id="open-add-car-manual-modal" (clickEmitter)="openManuallyAddCarsModal()" [blueBtn]="true">Manually
        add cars</primary-btn>

      <secondary-btn id="open-add-car-offered-modal" (clickEmitter)="openAddCarsFromOffersModal()">Add cars from
        offers</secondary-btn>
    </div>

    <secondary-btn id="remove-cars" (clickEmitter)="removeCars()">Remove selected</secondary-btn>
  </div> -->

  <!-- <div class="flex gap-6">
    <app-white-btn id="send-buyer-confirmation" (clickEmitter)="sendConfirmation()">Send confirmation to
      buyer</app-white-btn>
    <app-white-btn id="request-confirmation" (clickEmitter)="requestConfirmation()">Request confirmation</app-white-btn>
  </div> -->

  <div class="overflow-x-auto cars-table">
    <table #carsTable mat-table matSort [dataSource]="cars"
      class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">

      <ng-container matColumnDef="confirm">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1"></th>

        <td mat-cell *matCellDef="let element;let i = index" class="whitespace-normal !min-w-[10rem] !px-3 !py-3">
          <secondary-btn (clickEmitter)="confirmCar(element, 'Confirmed')">Confirm</secondary-btn>
        </td>
      </ng-container>

      <ng-container matColumnDef="cancel">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1"></th>

        <td mat-cell *matCellDef="let element;let i = index" class="whitespace-normal !min-w-[10rem] !px-3 !py-3">
          <secondary-btn (clickEmitter)="confirmCar(element, 'Canceled')">Cancel</secondary-btn>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="col.value" *ngFor="let col of headers">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
          {{col.tableView}}</th>
        <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
          {{getValue(element, col)}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100"></tr>
      <tr [ngClass]="{'isSelected' : row.isSelected}" class="hover:bg-white-500" mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
  </div>

  <div *ngIf="removedCars && removedCars.length > 0" class="flex justify-between">
    <p class="font-bold">Removed cars</p>
  </div>

  <!-- <app-table *ngIf="removedCars && removedCars.length > 0" id="removed-cars" #removedCarsTable
    [displayedColumns]="removedCarsColumns" [headersMap]="headers" [data]="removedCars"></app-table> -->

  <div *ngIf="removedCars && removedCars.length > 0" class="overflow-x-auto cars-table">
    <table #removedCarsTable mat-table matSort [dataSource]="removedCars"
      class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">

      <ng-container matColumnDef="confirm">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1"></th>

        <td mat-cell *matCellDef="let element;let i = index" class="whitespace-normal !min-w-[10rem] !px-3 !py-3">
          <secondary-btn (clickEmitter)="readdRemovedCard(element)">Confirm</secondary-btn>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="col.value" *ngFor="let col of headers">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
          {{col.tableView}}</th>
        <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
          {{getValue(element, col)}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="removedCarsColumns" class="bg-white-100"></tr>
      <tr [ngClass]="{'isSelected' : row.isSelected}" class="hover:bg-white-500" mat-row
        *matRowDef="let row; columns: removedCarsColumns;"></tr>
    </table>

    <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
