import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Dealer } from 'src/app/core/models/seller.model';
import { CoreService } from 'src/app/core/services/core.service';
import { InfoService } from 'src/app/core/services/infos.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { BtnToggleOption } from 'src/app/shared/app-btn-toggle/app-btn-toggle.component';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-dealer-mapping',
  templateUrl: './dealer-mapping.component.html',
  styleUrls: ['./dealer-mapping.component.scss']
})
export class DealerMappingComponent implements OnInit {
  public searchBy: BtnToggleOption[] = [{ value: 'company', viewValue: 'Company name' }, { value: 'country', viewValue: 'Country' }];

  countries: DropdownOption[] = this.coreService.countries.map(r => ({ viewValue: r.name, value: r.iso }));
  selectedCountryPortalId: number | undefined;

  searchControl = new FormControl();

  dealers: Dealer[] = [];

  tableHeaders: TableHeaderMap[] = [
    {
      value: 'name',
      tableView: 'name'
    },
    {
      value: 'address',
      tableView: 'address'
    }
  ]
  displayedColumns = ['selectAll', 'name', 'address'];

  constructor(private settingsService: SettingsService,
    private coreService: CoreService,
    private spinnerService: SpinnerHandlerService) { }

  ngOnInit() {
  }

  countrySelected(countryIso: string) {
    this.selectedCountryPortalId = undefined;
    this.settingsService.getPortalId(countryIso).subscribe(resp => this.selectedCountryPortalId = resp[0].portalId);
  }

  getDealers() {
    this.spinnerService.showProgressBar.next(true);
    this.settingsService.getDealers(this.searchControl.value ? this.searchControl.value : "", this.selectedCountryPortalId!).subscribe(resp => {
      this.dealers = resp.map(r => ({ ...r, isSelected: false }));

      this.spinnerService.showProgressBar.next(false);
    })
  }

  mergeDealers() {
    console.log(this.dealers.filter(d => d.isSelected));
  }
}
