import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CarOrderDetailsResp, OrderCar } from 'src/app/core/models/order.model';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderSeeCarDetailsModalComponent } from '../../../order-shared-components/order-see-car-details-modal/order-see-car-details-modal.component';
import { CarService } from 'src/app/core/services/car.service';
import { VINCheckDetails } from 'src/app/core/models/car.model';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';

@Component({
  selector: 'app-order-process-car-details',
  templateUrl: './order-process-car-details.component.html',
  styleUrls: ['./order-process-car-details.component.scss']
})
export class OrderProcessCarDetailsComponent implements OnInit {
  // loading = new BehaviorSubject<boolean>(true);

  headers: TableHeaderMap[] = [
    {
      value: 'carConfig.sellerName',
      tableView: 'seller'
    },
    {
      value: 'carPricing.invoiceSellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatString',
      tableView: 'vat type'
    },
    {
      value: 'carConfig.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carConfig.make',
      tableView: 'make'
    },
    {
      value: 'carConfig.model',
      tableView: 'model'
    },
    {
      value: 'carConfig.vin',
      tableView: 'vin'
    },
    {
      value: 'carConfig.mileage',
      tableView: 'mileage'
    },
    {
      value: 'carConfig.firstReg',
      tableView: 'first registration'
    },
    {
      value: 'carConfig.color',
      tableView: 'color'
    }
  ]

  displayedColumns = this.headers.map(h => h.value).concat(['details']);

  cars: OrderCar[] = this.orderFlowService.carList;
  carsVINDetails: VINCheckDetails[] = [];

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;

  constructor(private orderFlowService: OrdersFlowSyncService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService,
    private orderService: OrdersService) { }

  ngOnInit() {

  }

  async getCarDetails(car: OrderCar) {
    this.spinner.showProgressBar.next(true);

    let resp = await firstValueFrom(this.orderService.getCarDetails(this.orderFlowService.orderId!, car.carConfig.seller, car.carMainInfoId));

    this.spinner.showProgressBar.next(false);

    this.openModal(resp);
  }

  openModal(car: CarOrderDetailsResp) {
    const dialogRef = this.dialog.open(
      OrderSeeCarDetailsModalComponent, {
      width: '1200px',
      maxWidth: '95vw',
      autoFocus: false,
      data: {
        car: car,
      }
    });
  }
}
