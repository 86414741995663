import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BulkUpdateCarsBody } from "src/app/features/cars-inventory/manage-cars/update-cars-modal/update-cars-modal.component";
import { environment } from "src/environments/environment";
import { CarBusinessCase, CarBusinesscaseRequestBody, CarC2VEquipments, CarDetails, CarDocuments, CarDocumentUploadBody, CarGroupDetails, CarGroupJourney, CarMasterEquipments, CarPhoto, CarSearchResp, CarsFilterBody, CarSpecificationResponse, CarStatuses, CarsVINCheck, CarTaxesRequest, CarTaxesResponse, CreateCarJourneyBody, CreateManualCarBody, CreateManualCarResponse, EditManualCarBody, IdentifyCarRequestClass, IdentifyCarResponse, ReorderCarPhotos, UploadCarPhoto, VINCheckDetails, VINCheckResponse, VINCountries } from "../models/car.model";
import { CarOnlineListUploadBody, CarOnlineListUploadResponse, CarsOnListImportList, CarsOnListImportResponse, ICarBatchIdentifyBody, OnlineListCarsBCBody } from "../models/cars-inventory.model";
import { OrderCar } from "../models/order.model";
import { SimpleResponse } from "../models/request.model";
import { SearchCarsWOOffersBody, SearchCarWithoutOfferResp } from "../models/offer.model";


@Injectable({
  providedIn: 'root'
})

export class CarService {
  constructor(private http: HttpClient) { }

  //car CRUD
  public createCarDetails(car: CreateManualCarBody): Observable<CreateManualCarResponse> {
    return this.http.post<CreateManualCarResponse>(`${environment.backendApiUrl}cars`, car);
  }

  public getCarDetails(carId: string): Observable<CarDetails> {
    return this.http.get<CarDetails>(`${environment.backendApiUrl}cars/${carId}`);
  }

  public editCarDetails(car: EditManualCarBody, carId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}cars/${carId}`, car);
  }

  public deleteCar(carId: string): Observable<string> {
    return this.http.delete<string>(`${environment.backendApiUrl}cars/${carId}`);
  }

  //identify car endpoints
  public getCarNomenclatorData(query: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.backendApiUrl}car-nomenclator/${query}`);
  }

  public getCarSpecificationData(query: string): Observable<CarSpecificationResponse[]> {
    return this.http.get<CarSpecificationResponse[]>(`${environment.backendApiUrl}car-nomenclator/${query}`);
  }

  public identifyCar(carInfo: IdentifyCarRequestClass): Observable<IdentifyCarResponse[]> {
    return this.http.post<IdentifyCarResponse[]>(`${environment.backendApiUrl}car-identify`, carInfo);
  }

  public getCarEquipmentsFromADAC(carId: string): Observable<CarMasterEquipments> {
    return this.http.get<CarMasterEquipments>(`${environment.backendApiUrl}car-c2c-equip-history/${carId}`);
  }

  public getCarGroups(): Observable<SimpleResponse[]> {
    return this.http.get<SimpleResponse[]>(`${environment.backendApiUrl}car-groups`);
  }

  public getCarGroupsDetails(): Observable<CarGroupDetails[]> {
    return this.http.get<CarGroupDetails[]>(`${environment.backendApiUrl}car-groups-details`);
  }

  public getCarStatuses(): Observable<CarStatuses[]> {
    return this.http.get<CarStatuses[]>(`${environment.backendApiUrl}car-statuses`);
  }

  public getCarSources(): Observable<SimpleResponse[]> {
    return this.http.get<SimpleResponse[]>(`${environment.backendApiUrl}car-sources`);
  }

  //car group journey CRUD
  public createCarJourneyGroup(data: CreateCarJourneyBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}car-group-journey-create`, data);
  }

  public resetAllCarJourneyGroup(carId: string): Observable<string> {
    return this.http.delete<string>(`${environment.backendApiUrl}car-group-journey-delete/${carId}`);
  }

  public editCarJourneyGroup(data: CreateCarJourneyBody, journeyId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}car-group-journey-edit/${journeyId}`, data);
  }

  public getCarGroupJourneys(carId: string): Observable<CarGroupJourney[]> {
    return this.http.get<CarGroupJourney[]>(`${environment.backendApiUrl}car-group-journeys/${carId}`);
  }

  //car photos endpoints
  public uploadCarPhoto(body: UploadCarPhoto): Observable<ReorderCarPhotos> {
    return this.http.post<ReorderCarPhotos>(`${environment.backendApiUrl}upload-custom-photos`, body);
  }

  public getCarPhotos(carId: string): Observable<CarPhoto[]> {
    return this.http.get<CarPhoto[]>(`${environment.backendApiUrl}photos/${carId}`);
  }

  public reorderCarPhotos(body: ReorderCarPhotos): Observable<ReorderCarPhotos> {
    return this.http.post<ReorderCarPhotos>(`${environment.backendApiUrl}reorder-photos`, body);
  }

  public importCarStockPhoto(carId: string): Observable<ReorderCarPhotos> {
    return this.http.post<ReorderCarPhotos>(`${environment.backendApiUrl}import-stock-photos`, { carMainInfoId: carId });
  }

  //car equipments endpoints
  public getC2VEquipments(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.backendApiUrl}c2v-equipment`);
  }

  public getCarCustomEquipments(carId: string): Observable<CarC2VEquipments> {
    return this.http.get<CarC2VEquipments>(`${environment.backendApiUrl}car-c2v-equipments/${carId}`);
  }

  public getCarMasterEquipments(carId: string): Observable<CarMasterEquipments> {
    return this.http.get<CarMasterEquipments>(`${environment.backendApiUrl}car-master-equipments/${carId}`);
  }

  public createCarMasterEquipments(carId: string, equipments: CarMasterEquipments): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}car-master-equipment-create/${carId}`, equipments);
  }

  public editCarMasterEquipments(carId: string, equipments: CarMasterEquipments): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}car-master-equipment-edit/${carId}`, equipments);
  }

  public createCarC2VEquipments(carId: string, equipments: CarC2VEquipments): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}car-c2v-equipment-create/${carId}`, equipments);
  }

  public editCarC2VEquipments(carId: string, equipments: CarC2VEquipments): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}car-c2v-equipment-edit/${carId}`, equipments);
  }

  //documents endpoints
  public addCarDocument(body: CarDocumentUploadBody): Observable<CarDocuments[]> {
    return this.http.post<CarDocuments[]>(`${environment.backendApiUrl}upload-car-doc`, body);
  }

  public editCarDocument(carId: string, docId: string, visible: boolean): Observable<CarDocuments[]> {
    return this.http.post<CarDocuments[]>(`${environment.backendApiUrl}edit-car-doc`, { carMainInfoId: carId, id: docId, visible: visible });
  }

  public deleteCarDocument(carId: string, docId: string): Observable<CarDocuments[]> {
    return this.http.post<CarDocuments[]>(`${environment.backendApiUrl}delete-car-doc`, { carMainInfoId: carId, id: docId });
  }

  public downloadCarDocument(carId: string, docId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}download-car-doc`, { carMainInfoId: carId, id: docId });
  }

  //online list upload endpoints
  public uploadOnlineListCars(body: CarOnlineListUploadBody): Observable<string[]> {
    return this.http.post<string[]>(`${environment.backendApiUrl}online-car-list-upload`, body);
  }

  public getOnlineListCarsBC(body: OnlineListCarsBCBody[]): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}online-car-list-bc`, { cars: body });
  }

  public getOnlineListCarsBCAsyncPost(body: OnlineListCarsBCBody[]): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}online-car-list-bc-async-post`, { cars: body });
  }

  public getOnlineListCarsBCAsync(analysisId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}online-car-list-bc-async`, { analysisId: analysisId });
  }

  //more endpoints
  public getCarTaxes(body: CarTaxesRequest): Observable<CarTaxesResponse[]> {
    return this.http.post<CarTaxesResponse[]>(`${environment.backendApiUrl}car-taxes`, body);
  }

  public getCarsBusinessCase(cars: CarBusinesscaseRequestBody): Observable<CarBusinessCase[]> {
    return this.http.post<CarBusinessCase[]>(`${environment.backendApiUrl}business-case`, cars);
  }

  public createCarsOnListImportList(list: CarsOnListImportList): Observable<CarsOnListImportResponse> {
    return this.http.post<CarsOnListImportResponse>(`${environment.backendApiUrl}car-list-create`, list);
  }

  public getCarLists(): Observable<CarsOnListImportList[]> {
    return this.http.get<CarsOnListImportList[]>(`${environment.backendApiUrl}car-lists`);
  }

  public getCarList(listId: string): Observable<CarsOnListImportList> {
    return this.http.get<CarsOnListImportList>(`${environment.backendApiUrl}car-list/${listId}`);
  }

  public getCarListing(source: string): Observable<CarDetails[]> {
    return this.http.get<CarDetails[]>(`${environment.backendApiUrl}car-listing/${source}`);
  }

  public getCarFilteredStaff(body: CarsFilterBody): Observable<CarSearchResp> {
    return this.http.post<CarSearchResp>(`${environment.backendApiUrl}filter-cars-staff`, body);
  }

  public carBatchIdentify(body: ICarBatchIdentifyBody): Observable<CarOnlineListUploadResponse[]> {
    return this.http.post<CarOnlineListUploadResponse[]>(`${environment.backendApiUrl}car-batch-identify`, body);
  }

  public editMultipleCars(body: BulkUpdateCarsBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}multiple-car-edit`, body);
  }

  public searchCarsStaff(buyerId: string, clientId: string, query: string): Observable<OrderCar[]> {
    return this.http.post<OrderCar[]>(`${environment.backendApiUrl}search-cars-staff${query}`, { buyerId: buyerId, clientId: clientId });
  }

  public searchCarsOffer(body: SearchCarsWOOffersBody): Observable<SearchCarWithoutOfferResp> {
    return this.http.post<SearchCarWithoutOfferResp>(`${environment.backendApiUrl}search-cars-wo-offers`, body);
  }

  public vinCheck(vinCheckBody: CarsVINCheck): Observable<VINCheckResponse> {
    return this.http.post<VINCheckResponse>(`${environment.backendApiUrl}send-vin`, vinCheckBody);
  }

  public getVINDetails(batchId: string): Observable<VINCheckDetails[] | string> {
    return this.http.get<VINCheckDetails[]>(`${environment.backendApiUrl}get-vin-details/${batchId}`);
  }

  public getVINCountries(): Observable<VINCountries[]> {
    return this.http.get<VINCountries[]>(`${environment.backendApiUrl}get-list-of-vin-check-available-countries`);
  }

  public getCarShopTypes(): Observable<SimpleResponse[]> {
    return this.http.get<SimpleResponse[]>(`${environment.backendApiUrl}car-shop-types`);
  }

  public getCarVINCheck(vin: string, country: string): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}single-vin-check`, { vin: vin, iso: country });
  }

  public updateCarBCFlag(carId: string, businessCaseFlag: boolean): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}update-car-business-case-flag`, { carMainInfoId: carId, businessCaseFlag: businessCaseFlag });
  }

  public updateCarBCValues(body: any): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}update-car-business-case-values`, body);
  }
}

