<div class="twoCols">
  <div class="col-span-2 flex justify-between">
    <div class="flex gap-6">
      <app-dropdown id="users" [options]="userDropdown" [control]="userControl" label="User" placeholder="Select user"
        (emitOnChangeSelect)="changeUser($event)"></app-dropdown>
    </div>

    <div class="flex gap-6">
      <secondary-btn [disableBtn]="userDetailsForm.disabled" id="save-user" btnText="Save user"
        (clickEmitter)="saveUser()"></secondary-btn>
      <tertiary-btn id="add-user" btnText="Add user" (clickEmitter)="addUser()"></tertiary-btn>
    </div>
  </div>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>User Details</p>
    </div>

    <app-main-content-row-layout>
      <p>Stores*</p>

      <app-dropdown id="stores" [options]="stores" placeholder="Select stores" [multipleSelect]="true"
        class="secondContent" [control]="storeControl" (emitOnChangeSelect)="changeStoresSelected()">
      </app-dropdown>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>First name*</p>

      <app-input id="firstname" placeholder="Insert first name" class="secondContent"
        [control]="userDetailsForm.controls.firstName"></app-input>
    </app-main-content-row-layout>


    <app-main-content-row-layout>
      <p>Last name*</p>

      <app-input id="lastname" placeholder="Insert last name" class="secondContent"
        [control]="userDetailsForm.controls.lastName"></app-input>
    </app-main-content-row-layout>


    <app-main-content-row-layout>
      <div class="flex items-center">
        <p>Email*</p>

        <p class="relative -left-[2.9375rem] top-6 text-sm text-red opacity-0 whitespace-nowrap"
          [ngClass]="{'opacity-100':userDetailsForm.controls.email.hasError('email')}">Insert valid email</p>
      </div>

      <app-input id="email" class="secondContent" [control]="userDetailsForm.controls.email" placeholder="Insert email">
      </app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Country*</p>

      <app-dropdown id="country" placeholder="Select country" class="secondContent"
        [control]="userDetailsForm.controls.countryId" [options]="countries"></app-dropdown>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Phone</p>

      <app-input id="phone" placeholder="Insert phone" class="secondContent"
        [control]="userDetailsForm.controls.phoneNumber"></app-input>
    </app-main-content-row-layout>
  </app-main-content-layout>


  <div class="col-span-1 flex flex-col gap-6">
    <app-main-content-layout>
      <div class="title">
        <p>User store allocation</p>
      </div>

      <app-main-content-row-layout>
        <p>Store</p>

        <app-dropdown id="stores" [options]="storesSelected" placeholder="Select store" class="secondContent"
          [control]="selectedStoreControl" (emitOnChangeSelect)="changeStoreSelected($event)">
        </app-dropdown>
      </app-main-content-row-layout>

      <div class="twoCols">
        <app-main-content-row-layout class="col-span-1">
          <p>User is also contact</p>

          <mat-checkbox class="secondContent" id="is-contact-checkbox" [(ngModel)]="isContact"
            [disabled]="userDetailsForm.disabled"></mat-checkbox>
        </app-main-content-row-layout>

        <div class="col-span-1 flex flex-col gap-4 pl-6 border-l border-white-500">
          <div class="flex justify-between">
            <p>Send accounting email to this user</p>

            <app-toggle id="accounting-email" [toggleDisabled]="!isContact"
              [toggleFormControl]="contactForm.controls.accountingEmail"></app-toggle>
          </div>

          <div class="flex justify-between">
            <p>Send car delivery email to this user</p>

            <app-toggle id="delivery-email" [toggleDisabled]="!isContact"
              [toggleFormControl]="contactForm.controls.carDeliveryEmail"></app-toggle>
          </div>

          <div class="flex justify-between">
            <p>Send documents email to this user</p>

            <app-toggle id="documents-email" [toggleDisabled]="!isContact"
              [toggleFormControl]="contactForm.controls.documentsEmail"></app-toggle>
          </div>
        </div>
      </div>
    </app-main-content-layout>

    <app-main-content-layout>
      <div class="title">
        <p>User info</p>
      </div>

      <app-main-content-row-layout>
        <p>Status</p>

        <div class="secondContent text-end">
          <app-toggle id="status" class="secondContent" [toggleFormControl]="statusControl"
            [toggleLabel]="statusControl.value? 'Active' :'Inactive'"></app-toggle>
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Date created</p>

        <app-read-only-input class="secondContent">
          <p>{{dateCreated | date: 'dd/MM/yyyy'}}</p>
        </app-read-only-input>
      </app-main-content-row-layout>
    </app-main-content-layout>
  </div>
</div>
