import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Offer, OfferStatus, SearchOffersBody } from 'src/app/core/models/offer.model';
import { CoreService } from 'src/app/core/services/core.service';
import { OffersService } from 'src/app/core/services/offers.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { CreateOfferCarsOfferPriceModalComponent } from '../create-offer/create-offer-cars/create-offer-cars-offer-price-modal/create-offer-cars-offer-price-modal.component';
import { CarBusinessCase, CarBusinesscaseRequestBody } from 'src/app/core/models/car.model';
import { CarService } from 'src/app/core/services/car.service';
import { VATType } from 'src/app/core/models/info.model';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/core/services/utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-view-offers',
  templateUrl: './view-offers.component.html',
  styleUrls: ['./view-offers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ViewOffersComponent implements OnInit {
  loading = new BehaviorSubject(true);
  loadingTable = new BehaviorSubject(false);

  headers: TableHeaderMap[] = [
    {
      value: 'buyerName',
      tableView: 'buyer'
    }, {
      value: 'clientData.clientUsername',
      tableView: 'buyer user'
    },
    {
      value: 'sellerName',
      tableView: 'seller Name'
    },
    {
      value: 'offerStatus',
      tableView: 'offer Status'
    },
    {
      value: 'latestOffer',
      tableView: 'offer price'
    },
    {
      value: 'carDetails.shopPrice',
      tableView: 'shop Price'
    },
    {
      value: 'expiresOn',
      tableView: 'valid until',
      isDateSimple: true,
    },
    {
      value: 'carDetails.make',
      tableView: 'make'
    },
    {
      value: 'carDetails.model',
      tableView: 'model'
    },
    {
      value: 'carDetails.gearbox',
      tableView: 'gearbox'
    },
    {
      value: 'carDetails.mileage',
      tableView: 'mileage'
    },
    // {
    //   value: 'carDetails.power',
    //   tableView: 'power'
    // }
  ];

  displayedColumns = [...this.headers.map(h => h.value), 'expand'];

  offers: Offer[] = [];

  offerSelected: Offer | undefined;

  offerStatus = OfferStatus;

  bc: CarBusinessCase | undefined;
  countries = this.coreService.countriesDropdown;
  statuses = Object.keys(OfferStatus).map(s => ({ value: s, viewValue: s }));

  window = window;

  vatStatus = VATType;

  sellerNameSearchControl = new FormControl();
  statusControl = new FormControl();
  countryControl = new FormControl();

  results = 0;

  @ViewChild('paginator') paginator: MatPaginator | undefined;

  constructor(private offerService: OffersService,
    private coreService: CoreService,
    private carService: CarService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService,
    private utilsService: UtilsService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.offerService.getOffers({ page: 0, itemsPerPage: 5 }).subscribe(resp => {
      this.offers = resp.offers.map(o => ({
        ...o,
        latestOffer: o.proposedPrices[o.proposedPrices.length - 1].value
      }));

      this.results = resp.nrOfOffers;

      this.loading.next(false);
    })
  }

  selectOffer(offer: Offer) {
    if (this.spinner.showProgressBar.value) return;

    this.bc = undefined;

    this.offerSelected = {
      ...offer,
      proposedPrices: offer.proposedPrices.map(p => ({
        ...p,
        date: new Date(this.utilsService.utcDateToLocal(p.addedOn))
      })),
      latestOffer: offer.proposedPrices.slice(-1)[0].value
    }
  }

  isNaN(value: number) {
    return isNaN(value);
  }

  cancelOffer() {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    this.offerService.cancelOffer(this.offerSelected!.offerId).subscribe({
      next: resp => {
        this.offers.splice(this.offers.findIndex(o => o.offerId === this.offerSelected!.offerId), 1);

        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment("Offer canceled");

        this.offerSelected!.offerStatus = this.offerStatus.Canceled;
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  declineOffer() {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    this.offerService.declineOffer(this.offerSelected!.offerId).subscribe({
      next: resp => {
        this.offerSelected!.offerStatus = this.offerStatus.Rejected;

        this.offers[this.offers.findIndex(o => o.offerId === this.offerSelected!.offerId)] = this.offerSelected!;

        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment("Offer declined");

        this.offerSelected!.c2cConfirmation = this.offerStatus.Rejected;
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  acceptOffer() {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    this.offerService.acceptOffer(this.offerSelected!.offerId).subscribe({
      next: resp => {
        this.offerSelected!.c2cConfirmation = this.offerStatus.Accepted;
        this.offerSelected!.offerStatus = this.offerStatus.Accepted;

        this.offers[this.offers.findIndex(o => o.offerId === this.offerSelected!.offerId)] = this.offerSelected!;

        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment("Offer accepted");

        this.offerSelected!.c2cConfirmation = this.offerStatus.Accepted;
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  openCounterOfferModal() {
    if (this.spinner.showProgressBar.value) return;

    const dialogRef = this.dialog.open(
      CreateOfferCarsOfferPriceModalComponent, {
      width: '800px',
      maxWidth: '95vw',
      autoFocus: false,
      data: {
        car: this.offerSelected?.carDetails,
        bc: this.bc,
        buyerCountry: this.offerSelected?.buyerLocation,
        latestOffer: this.offerSelected?.latestOffer,
        expirationDate: this.offerSelected?.expiresOn,
        orderStatus: this.offerSelected?.offerStatus
      },
    });

    dialogRef.afterClosed().subscribe(counterOffer => {
      if (counterOffer) {
        this.spinner.showProgressBar.next(true);

        let body = {
          offerId: this.offerSelected!.offerId,
          value: counterOffer.value,
          expiresOn: counterOffer.expiresOn ? counterOffer.expiresOn.format('yyyy-MM-DD') : null
        }

        this.offerService.counterOffer(body).subscribe({
          next: resp => {
            this.offerSelected?.proposedPrices.push({
              username: this.coreService.user!.user.email,
              offeredBy: 'C2C',
              addedOn: new Date().toDateString(),
              date: new Date(),
              value: counterOffer.value
            });

            this.offerSelected!.latestOffer = counterOffer.value;

            this.offerSelected!.c2cConfirmation = this.offerStatus.Accepted;
            this.offerSelected!.clientConfirmation = this.offerStatus.Pending;
            this.offerSelected!.offerStatus = this.offerStatus.Pending;
            this.offerSelected!.expiresOn = counterOffer.expiresOn ? counterOffer.expiresOn.format('yyyy-MM-DD') : this.offerSelected!.expiresOn;

            this.offers[this.offers.findIndex(o => o.offerId === this.offerSelected!.offerId)] = this.offerSelected!;

            this.spinner.showProgressBar.next(false);
            this.snackbar.positiveSentiment("Counter offer created");
          },
          error: err => {
            this.spinner.showProgressBar.next(false);
            this.snackbar.negativeSentiment(err.error);
          }
        })
      }
    });
  }

  showOfferButtons() {
    return this.offerSelected?.c2cConfirmation === this.offerStatus.Pending &&
      this.offerSelected.clientConfirmation === this.offerStatus.Accepted &&
      this.offerSelected.offerStatus === this.offerStatus.Pending;
  }

  getCarBC() {
    let country = this.coreService.countries.find(c => c.id === this.offerSelected?.buyerLocationId);

    if (country) {
      let body: CarBusinesscaseRequestBody = {
        countries: [country.iso],
        make: this.offerSelected?.carDetails.make!,
        model: this.offerSelected?.carDetails.model!,
        manufactureYear: this.offerSelected?.carDetails.manufactureYear!,
        fuelType: this.offerSelected?.carDetails.fuelType!,
        gearbox: this.offerSelected?.carDetails.gearbox!,
        bodyType: this.offerSelected!.carDetails.bodyType,
        variant: this.offerSelected?.carDetails.variant!,
        mileage: this.offerSelected?.carDetails.mileage!,
        enginePower: this.offerSelected?.carDetails.enginePower!,
        firstReg: this.offerSelected!.carDetails.firstReg.toString(),
        accessories: [],
        carMainInfoId: this.offerSelected!.carDetails.carMainInfoId
      };

      this.carService.getCarsBusinessCase(body).subscribe({
        next: resp => {
          this.bc = resp.find(b => b.spotCountry === country!.iso);

          if (!this.bc || !this.bc.spotPrice || isNaN(this.bc.spotPrice)) {
            this.bc = undefined;
          }

          this.loading.next(false);
        },
        error: err => {
          this.loading.next(false);
        }
      });
    } else {
      this.snackbar.negativeSentiment('Buyer country not in system');
    }
  }

  isCreatedByUser(): boolean {
    return this.offerSelected != undefined && this.offerSelected.createdBy === this.coreService.user!.user.id && this.offerSelected.offerStatus === this.offerStatus.Pending;
  }

  searchOffers(resetPage?: boolean) {
    this.loadingTable.next(true);

    let body: SearchOffersBody = {
      page: this.paginator!.pageIndex,
      itemsPerPage: this.paginator!.pageSize,
      seller: this.sellerNameSearchControl.value ? this.sellerNameSearchControl.value : undefined,
      status: this.statusControl.value ? this.statusControl.value : undefined,
      country: this.countryControl.value ? this.countryControl.value : undefined
    }

    this.offerService.getOffers(body).subscribe(resp => {
      this.offers = resp.offers.map(o => ({
        ...o,
        latestOffer: o.proposedPrices[o.proposedPrices.length - 1].value
      }));

      if (resetPage) this.paginator!.pageIndex = 0;

      this.loadingTable.next(false);

      this.results = resp.nrOfOffers;
    });
  }

  getLogDate(log: string) {
    return log.split(' on ')[1];
  }

  getValue(element: any, value: TableHeaderMap): string {
    return this.utilsService.getValue(element, value);
  }
}
