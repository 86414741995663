import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { CompanyInvoice } from '../company-invoices.component';

interface AddCompanyInvoiceModalData {
  countries: DropdownOption[],
  company: CompanyInvoice,
}

@Component({
  selector: 'app-add-company-invoice-modal',
  templateUrl: './add-company-invoice-modal.component.html',
  styleUrls: ['./add-company-invoice-modal.component.scss']
})
export class AddCompanyInvoiceModalComponent implements OnInit {
  company = this.data.company;
  countries = this.data.countries;

  countryControl = new FormControl('', Validators.required);
  addressControl = new FormControl('', Validators.required);
  nameControl = new FormControl('', Validators.required);
  valueControl = new FormControl<null | number>(null, [Validators.required, Validators.max(99), Validators.min(1)]);

  constructor(public dialogRef: MatDialogRef<AddCompanyInvoiceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddCompanyInvoiceModalData,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    if (this.company) {
      this.countryControl.setValue(this.company.countryId);
      this.nameControl.setValue(this.company.name);
      this.valueControl.setValue(this.company.taxValue);
    }
  }

  confirm() {
    if (this.countryControl.invalid) {
      this.snackbar.negativeSentiment('Select country');
      return;
    }

    if (this.nameControl.invalid) {
      this.snackbar.negativeSentiment('Insert company name');
      return;
    }

    if (this.addressControl.invalid) {
      this.snackbar.negativeSentiment('Insert company address');
      return;
    }

    if (this.valueControl.invalid) {
      this.snackbar.negativeSentiment('Insert VAT value between 0 and 100');
      return;
    }

    let company: CompanyInvoice = {
      countryId: this.countryControl.value!,
      name: this.nameControl.value!,
      taxValue: this.valueControl.value!,
      address: this.addressControl.value!
    }

    if(this.company) company.id = this.company.id!;

    this.dialogRef.close(company);
  }
}
