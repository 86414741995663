<div class="h-[90vh] flex relative justify-center">
  <img class="object-contain" [src]="selectedPhoto" alt="">

  <div class="absolute top-2 right-3">
    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close()"></fa-icon>
  </div>

  <div (click)="previous()"
    class="absolute left-0 top-[40%] bg-white-bgGrey hover:bg-white-hoverGrey text-white-greyText cursor-pointer text-4xl px-4 rounded py-1">
    <fa-icon [icon]="['far','chevron-left']"></fa-icon>
  </div>

  <div (click)="next()"
    class="absolute right-0 top-[40%] bg-white-bgGrey hover:bg-white-hoverGrey text-white-greyText cursor-pointer text-4xl px-4 rounded py-1">
    <fa-icon [icon]="['far','chevron-right']"></fa-icon>
  </div>

  <div class="absolute -bottom-6 left-[50%] text-xs text-white-greyText">
    {{getIndex()}}/{{photos.length}}
  </div>
</div>
