import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [OrdersFlowSyncService]
})
export class OrdersComponent implements OnInit {
  loading = this.orderService.loading;

  subscription = new Subscription();

  routes = [
    {
      viewValue: "Order settings",
      value: "settings",
      disabled: new BehaviorSubject(false)
    },
    {
      viewValue: "Select cars",
      value: "cars",
      disabled: new BehaviorSubject(true)
    },
    {
      viewValue: "Equipment",
      value: "equipment",
      disabled: new BehaviorSubject(true)
    },
    {
      viewValue: "Review order",
      value: "review",
      disabled: new BehaviorSubject(true)
    }
  ];

  page = this.routes[0].value;

  canDropOrder = this.orderService.isOwner;

  constructor(private orderService: OrdersFlowSyncService,
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerHandlerService) { }

  ngOnInit(): void {
    this.subscription.add(this.orderService.currentTab$.subscribe(
      (page) => {
        let route = this.routes.find(r => r.value === page);
        if (route) {
          this.page = page;
          route.disabled.next(false);
        }
      }
    ));

    this.activatedRoute.params.subscribe(param => {
      if (param['orderId']) {
        this.orderService.orderId = param['orderId'];
        this.routes.forEach(r => { r.disabled.next(false) });
      }
    })

    this.orderService.loadInfo();
  }

  continue(event: MouseEvent) {
    this.orderService.emitContinueEvent(event);
  }

  changePage(page: string) {
    if (this.page === page) return;

    this.orderService.changeTab(page);
  }

  sendOrder(event: MouseEvent) {
    if (this.spinner.showProgressBar.value) return;

    this.orderService.emitSendOrderEvent(event);
  }

  saveOrder(event: MouseEvent) {
    if (this.spinner.showProgressBar.value) return;

    this.orderService.emitSaveOrderEvent(event);
  }

  openDropOrderModal() {

  }
}
