import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CreateSellerStoreBody, EditSellerStoreBody } from 'src/app/core/models/seller-models/seller-edit.model';
import { ISellerStore } from 'src/app/core/models/seller.model';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-view-b2b-seller-stores',
  templateUrl: './view-b2b-seller-stores.component.html',
  styleUrls: ['./view-b2b-seller-stores.component.scss']
})
export class ViewB2bSellerStoresComponent implements OnInit {
  createMode: boolean = false;
  editMode = false;
  isAdmin = this.coreService.isAdmin;

  sellerId = this.b2bService.sellerId;

  public saveFormSubscription: Subscription = new Subscription();
  public routeSubscription: Subscription = new Subscription();

  storeDetailsForm = this.fb.group({
    name: ['', Validators.required],
    city: ['', Validators.required],
    address: ['', Validators.required],
    postalCode: ['', Validators.required],
    repMakes: [[''], Validators.required],
    soldMakes: [[''], Validators.required],
    countryBank: [false, Validators.required]
  });

  companyCountry = this.b2bService.sellerResponse!.sellerCompanyDetails.countryDetails;

  storesDropdown: DropdownOption[] = this.b2bService.sellerResponse!.stores.map(s => ({ value: s.id!, viewValue: s.name! }));

  makes = this.b2bService.makes;
  dateCreated: Date | undefined;
  dateUpdated: Date | undefined;
  statusControl = new FormControl({ value: false, disabled: true });
  defaultStoreControl = new FormControl({ value: false, disabled: true });

  stores: ISellerStore[] = this.b2bService.sellerResponse!.stores;
  users: string[] = [];

  public storeToggleControl: FormControl = new FormControl();

  constructor(private fb: FormBuilder,
    private coreService: CoreService,
    private snackbar: SnackbarService,
    private b2bService: B2BSellerSyncService,
    private sellerService: SellerService,
    private spinnerService: SpinnerHandlerService) { }

  ngOnInit() {
    this.routeSubscription = this.b2bService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.createMode) {
          let resp = await this.coreService.openUnsavedChangesModal();

          if (resp) {
            this.b2bService.setCurrentTab(value);
          }
        } else {
          this.b2bService.setCurrentTab(value);
        }
      }
    );

    this.storeToggleControl.setValue(this.stores[0].id!);

    this.patchForm(this.stores[0]);

    this.storeDetailsForm.disable();
  }

  ngOnDestroy() {
    this.saveFormSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  saveStore() {
    if (this.spinnerService.showProgressBar.value) return;

    if (this.storeDetailsForm.invalid) {
      this.snackbar.negativeSentiment('You need to complete all required fields');
      return;
    }

    this.spinnerService.showProgressBar.next(true);

    if (this.createMode) {
      let body = new CreateSellerStoreBody(this.storeDetailsForm.value, this.sellerId!, this.b2bService.sellerResponse!.sellerCompanyDetails.countryDetails.countryId);

      this.sellerService.createSellerStore(body).subscribe({
        next: resp => {
          this.b2bService.sellerResponse!.stores.push(resp);

          this.storesDropdown = this.b2bService.sellerResponse!.stores.map(s => ({ value: s.id!, viewValue: s.name! }));

          this.storeToggleControl.setValue(resp.id);

          this.spinnerService.showProgressBar.next(false);
          this.createMode = false;

          this.storeDetailsForm.markAsPristine();

          this.snackbar.positiveSentiment("Store created succesffully!");

        },
        error: err => {
          this.spinnerService.showProgressBar.next(false);

          this.snackbar.negativeSentiment(err.error);
        }
      });
    } else {
      let body = new EditSellerStoreBody(this.storeDetailsForm.value, this.sellerId!, this.statusControl.value!, this.storeToggleControl.value!, this.defaultStoreControl.value!, this.companyCountry.countryId);

      this.sellerService.editSellerStore(body).subscribe({
        next: resp => {
          this.b2bService.sellerResponse!.stores = resp;

          this.stores = resp;

          this.snackbar.positiveSentiment('Store updated');

          this.spinnerService.showProgressBar.next(false);
        },
        error: err => {
          this.spinnerService.showProgressBar.next(false);

          this.snackbar.negativeSentiment(err.error);
        }
      });
    }
  }

  async changeStore(storeId: string) {
    if (this.createMode) {
      const currentStoreId = this.storeToggleControl.value;

      let result = await this.coreService.openUnsavedChangesModal();

      if (result) {
        let store = this.stores.find(s => s.id === storeId)!;

        this.storesDropdown.splice(-1);

        this.patchForm(store);
      } else {
        this.storeToggleControl.setValue(currentStoreId);
      }
    } else {
      let store = this.stores.find(s => s.id === storeId)!;

      this.patchForm(store);
    }
  }

  addNewStore() {
    if (this.spinnerService.showProgressBar.value) return;

    if (this.createMode) {
      this.snackbar.negativeSentiment("You already started to create a new store");
      return;
    }

    this.dateCreated = new Date();
    this.dateUpdated = new Date();

    this.storesDropdown.push({ value: 'newStore', viewValue: 'New Store' });
    this.storeToggleControl.setValue('newStore');
    this.storeDetailsForm.reset({
      countryBank: false
    });
    this.createMode = true;
  }

  copyDataFromCompany() {
    if (this.spinnerService.showProgressBar.value) return;

    if (this.b2bService.sellerResponse) {
      this.storeDetailsForm.patchValue({
        name: this.b2bService.sellerResponse.sellerCompanyDetails.name,
        address: this.b2bService.sellerResponse.sellerCompanyDetails.address,
        city: this.b2bService.sellerResponse.sellerCompanyDetails.city,
        postalCode: this.b2bService.sellerResponse.sellerCompanyDetails.postalCode
      })
    } else {
      this.snackbar.negativeSentiment('No company saved!');
    }
  }

  patchForm(store: ISellerStore) {
    this.storeDetailsForm.patchValue({
      name: store.name,
      address: store.address,
      city: store.city,
      postalCode: store.postalCode,
      soldMakes: store.soldMakes,
      repMakes: store.repMakes,
      countryBank: store.countryBank
    });


    this.createMode = false;
    this.users = this.b2bService.sellerResponse!.users.filter(u => u.details?.sellerStores.find(s => s.store === store.id)).map(u => `${u.firstName} ${u.lastName}`);

    this.statusControl.setValue(store.status!);
    this.defaultStoreControl.setValue(store.defaultStore);

    this.dateCreated = store.createdAt!;
    this.dateUpdated = store.modifiedAt!;
  }

  enterEditMode() {
    this.editMode = true;
    this.storeDetailsForm.enable();
    this.statusControl.enable();
  }

  makeDefaultStore() {
    if (this.spinnerService.showProgressBar.value) return;

    let store = this.b2bService.sellerResponse!.stores.find(s => s.id === this.storeToggleControl.value!)!;

    let body = new EditSellerStoreBody(store, this.sellerId!, store.status, store.id, true, store.countryDetails.countryId);

    this.spinnerService.showProgressBar.next(true);

    this.sellerService.editSellerStore(body).subscribe({
      next: resp => {
        this.b2bService.sellerResponse!.stores = resp;
        this.stores = resp;

        this.defaultStoreControl.setValue(true);

        this.spinnerService.showProgressBar.next(false);
      },
      error: err => {
        this.spinnerService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }
}
