import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { CarPhoto, VINCheckDetails, VINCheckDetailsEquipment } from 'src/app/core/models/car.model';
import { CarOrderDetailsResp } from 'src/app/core/models/order.model';
import { UtilsService } from 'src/app/core/services/utils.service';


@Component({
  selector: 'app-order-see-car-details-modal',
  templateUrl: './order-see-car-details-modal.component.html',
  styleUrls: ['./order-see-car-details-modal.component.scss']
})
export class OrderSeeCarDetailsModalComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  car: CarOrderDetailsResp = this.data.car;

  carPhotos = this.car.photos ? this.car.photos.map(p => p.originalPhoto) : [];

  routes = [
    {
      viewValue: "Car details",
      value: "details",
    },
    {
      viewValue: "Equipments",
      value: "equipments",
    }
  ];

  tab = this.routes[0].value;

  customEquipment: string[] = [];

  equipments: string[] = [];

  constructor(public dialogRef: MatDialogRef<OrderSeeCarDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilsService: UtilsService) { }

  ngOnInit(): void {
    if (this.car.carConfig.c2cEquipment.standardEquipment) {
      this.utilsService.equipmentArrayTransform(this.equipments, this.car.carConfig.c2cEquipment.optionalEquipment);
      this.utilsService.equipmentArrayTransform(this.equipments, this.car.carConfig.c2cEquipment.standardEquipment);
      this.utilsService.equipmentArrayTransform(this.equipments, this.car.carConfig.c2cEquipment.packEquipment);
    }

    this.equipments = [...this.equipments, ...this.car.carConfig.c2vEquipment.map(c => this.utilsService.splitWordByUnderline(c))];
  }

  changeTab(tab: string) {
    this.tab = tab;
  }

  close() {
    this.dialogRef.close();
  }
}
