import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-transport-modal',
  templateUrl: './create-transport-modal.component.html',
  styleUrls: ['./create-transport-modal.component.scss']
})
export class CreateTransportModalComponent implements OnInit {
  nameControl = new FormControl('', Validators.required);

  constructor(public dialogRef: MatDialogRef<CreateTransportModalComponent>) { }

  ngOnInit(): void {
  }

  createTransport() {
    if (this.nameControl.invalid) {
      this.nameControl.markAsTouched();
      return;
    }

    this.dialogRef.close(this.nameControl.value);
  }
}
