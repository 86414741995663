import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageFullScreenComponent } from './image-full-screen/image-full-screen.component';

@Component({
  selector: 'app-image-slider',
  templateUrl: './app-image-slider.component.html',
  styleUrls: ['./app-image-slider.component.scss']
})
export class AppImageSliderComponent implements OnInit {
  @Input() images: string[] = [];

  public isMobile: boolean = false;
  public selectedImage: string = '';

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    if (this.images.length > 0) {
      this.selectedImage = this.images[0];
    }

    this.isMobile = window.innerWidth < 1024;

    window.onresize = () => this.isMobile = window.innerWidth < 1024;
  }

  selectImage(image: string) {
    this.selectedImage = image;
  }

  openFullScreenPhoto() {
    const dialogRef = this.dialog.open(
      ImageFullScreenComponent, {
      width: '95vw',
      maxWidth: '95vw',
      autoFocus: false,
      data: {
        photos: this.images
      },
    });
  }
}
