<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loadingInfo | async);else placeholder">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">Create B2B seller</p>

      <primary-btn id="save" [blueBtn]="true" btnText="Complete step" (clickEmitter)="saveForm($event)"></primary-btn>
    </div>

    <app-btn-toggle [options]="routes" [selectedOption]="page" (changeEmitter)="navigateTo($event)"></app-btn-toggle>

    <div [ngSwitch]="page">
      <app-seller-company *ngSwitchCase="'company'"></app-seller-company>

      <app-seller-stores *ngSwitchCase="'stores'"></app-seller-stores>

      <app-seller-store-contacts *ngSwitchCase="'contacts'"></app-seller-store-contacts>

      <app-seller-users *ngSwitchCase="'users'"></app-seller-users>

      <app-seller-store-restrictions *ngSwitchCase="'restrictions'"></app-seller-store-restrictions>

      <app-seller-reports *ngSwitchDefault=""></app-seller-reports>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="grid grid-cols-2 gap-6 gap-y-9">
    <div class="placeholder-animation w-1/4 h-6 col-span-1"></div>

    <div class="placeholder-animation w-1/4 h-6 col-span-1 place-self-end"></div>

    <div class="placeholder-animation h-6 col-span-2"></div>

    <app-main-content-placeholder class="col-span-1"></app-main-content-placeholder>
    <app-main-content-placeholder class="col-span-1"></app-main-content-placeholder>
  </div>
</ng-template>
