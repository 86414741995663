import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BuyerStoreContact, BuyerStoreContactClass, CreateBuyerContactBody } from 'src/app/core/models/buyer.model';
import { BuyerSyncService } from 'src/app/core/services/buyer-flow-sync.service';
import { BuyerService } from 'src/app/core/services/buyer.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';

@Component({
  selector: 'app-view-buyer-contacts',
  templateUrl: './view-buyer-contacts.component.html',
  styleUrls: ['./view-buyer-contacts.component.scss']
})
export class ViewBuyerContactsComponent implements OnInit {
  public loadingPage: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public routeSubscription: Subscription = new Subscription();

  createMode: boolean = false;
  buyerId = this.buyerSyncService.buyerId;

  @Input() isAdmin = false;
  editMode = false;

  dateCreated: Date | undefined;
  dateUpdated: Date | undefined;
  statusControl = new FormControl({ value: false, disabled: true });

  stores: DropdownOption[] = this.buyerSyncService.stores.map(s => ({ viewValue: s.name!, value: s.id ? s.id : s.tempId! }));
  storesControl = new FormControl();

  contactControl = new FormControl();
  contactsDropdown: DropdownOption[] = [];

  public contactForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: [''],
    status: [true],
    accountingEmail: [false],
    carDeliveryEmail: [false],
    documentsEmail: [false]
  })

  usersContact: string[] = [];

  constructor(
    private fb: FormBuilder,
    private buyerSyncService: BuyerSyncService,
    private snackbar: SnackbarService,
    private spinnerService: SpinnerHandlerService,
    private buyerService: BuyerService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.storesControl.setValue(this.stores[0].value);
    this.selectStore(this.stores[0].value);
    this.getUserStoreContacts(this.stores[0].value);

    this.routeSubscription = this.buyerSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.contactForm.dirty) {
          let goNext = await this.buyerSyncService.showUnsavedChangesModal();

          goNext ? this.buyerSyncService.setCurrentTab(value) : 0;
        } else {
          this.buyerSyncService.setCurrentTab(value);
        }
      }
    );

    this.contactForm.disable();
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  saveContact() {
    if (this.spinnerService.showProgressBar.value) return;

    if (this.contactForm.invalid) {
      this.snackbar.negativeSentiment('You need to complete all required fields');
      return;
    }

    let contact = new CreateBuyerContactBody(this.contactForm.value, this.buyerId!, this.storesControl.value!);

    this.spinnerService.showProgressBar.next(true);

    if (this.createMode) {
      this.buyerService.createBuyerContact(contact).subscribe({
        next: resp => {
          let store = this.buyerSyncService.buyerGetResponse!.stores.find(s => s.id === this.storesControl.value!)!;

          store.contacts.push(contact);

          this.buyerSyncService.buyerGetResponse!.stores[this.buyerSyncService.buyerGetResponse!.stores.findIndex(s => s.id === store.id)] = store;

          this.loadContactDropdown(store.id);
          this.contactControl.setValue(resp.email);

          this.spinnerService.showProgressBar.next(false);

          this.createMode = false;

          this.contactForm.markAsPristine();

          this.snackbar.positiveSentiment("Contact created successfully!");
        },
        error: err => {
          this.spinnerService.showProgressBar.next(false);

          this.snackbar.negativeSentiment(err.error);
        }
      })
    } else {
      // contact.status = this.statusControl.value!;

      // this.buyerService.editBuyerStoreContact(contact, this.contactControl.value).subscribe(resp => {
      //   this.buyerSyncService.contacts[this.buyerSyncService.contacts.findIndex(c => c.id === resp.id)] = resp;

      //   this.spinnerService.showProgressBar.next(false);

      //   this.snackbar.positiveSentiment("Contact edited successfully!");
      // })
    }

    this.contactForm.markAsPristine();
  }

  selectStore(storeId: string) {
    if (this.createMode) {
      const currentContactId = this.contactControl.value;
      const currentStoreId = this.storesControl.value;

      const dialogRef = this.dialog.open(
        UnsavedChangesModalComponent, {
        width: '650px',
        autoFocus: false,
        data: {}
      });

      dialogRef.afterClosed().subscribe(resp => {
        if (resp) {
          if (currentContactId) this.loadContactDropdown(this.storesControl.value);

          this.createMode = false;

          this.getUserStoreContacts(storeId);

          this.continueChangeStoreFlow(storeId);
        } else {
          this.storesControl.setValue(currentStoreId)
          this.contactControl.setValue(currentContactId)
        }
      })
    } else {
      this.continueChangeStoreFlow(storeId)
    }
  }

  continueChangeStoreFlow(storeId: string) {
    this.contactForm.reset({
      accountingEmail: false,
      carDeliveryEmail: false,
      documentsEmail: false
    });

    this.contactControl.reset();

    this.loadContactDropdown(storeId);

    this.getUserStoreContacts(storeId);

    let contacts = this.buyerSyncService.buyerGetResponse!.stores.find(s => s.id === storeId)!.contacts;

    if (contacts.length > 0) {
      this.contactControl.setValue(contacts[0].email);
      this.changeContact(contacts[0].email, storeId);
    } else {
      this.contactForm.disable();
    }
  }

  loadContactDropdown(storeId: string) {
    this.contactsDropdown = this.buyerSyncService.buyerGetResponse!.stores.find(s => s.id === storeId)!.contacts.map(c => ({ viewValue: `${c.firstName} ${c.lastName}`, value: c.email }));
  }

  addContact() {
    if (this.spinnerService.showProgressBar.value) return;

    if (this.createMode) return this.snackbar.negativeSentiment('You already started to create a new contact!');

    this.createMode = true;

    this.contactsDropdown.push({ value: 'newContact', viewValue: 'New Contact' });

    this.contactForm.reset({
      accountingEmail: false,
      carDeliveryEmail: false,
      documentsEmail: false
    });

    this.contactForm.enable();

    this.contactControl.setValue('newContact');
  }

  changeContact(contactId: string, storeId?: string) {
    if (this.createMode) {
      const currentContactId = this.contactControl.value;

      const dialogRef = this.dialog.open(
        UnsavedChangesModalComponent, {
        width: '650px',
        autoFocus: false,
        data: {}
      });

      dialogRef.afterClosed().subscribe(resp => {
        if (resp) {
          if (currentContactId) this.loadContactDropdown(this.storesControl.value);

          this.continueChangeContactFlow(contactId, storeId);
        } else {
          this.contactControl.setValue(currentContactId);
        }
      })
    } else {
      this.continueChangeContactFlow(contactId, storeId);
    }
  }

  continueChangeContactFlow(contactId: string, storeId?: string) {
    let contact = this.buyerSyncService.buyerGetResponse!.stores.find(s => s.id === (storeId ? storeId : this.storesControl.value!))!.contacts.find(c => c.email === contactId);

    this.patchContactForm(contact!);
  }

  patchContactForm(contact: BuyerStoreContact) {
    this.contactForm.patchValue({
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phone,
      accountingEmail: contact.accountingEmail!,
      carDeliveryEmail: contact.carDeliveryEmail!,
      documentsEmail: contact.documentsEmail!
    });

    this.statusControl.setValue(true);

    this.createMode = false;

    if (this.editMode) {
      this.contactForm.enable();
    }
  }

  getUserStoreContacts(storeId: string) {
    this.usersContact = this.buyerSyncService.buyerGetResponse!.users.filter(u => u.details.buyerStores!.some(st => st.store === storeId && st.roles.includes('contact'))).map(u => u.email);
  }

  checkStoreContacts(): boolean {
    return this.stores.some(s => {
      let users = this.buyerSyncService.users.filter(u => u.buyerStores!.some(st => st.store === s.value && st.roles.includes('contact')));

      let contacts = this.buyerSyncService.contacts.filter(c => c.store === s.value);

      return (users.length + contacts.length) === 0;
    });
  }

  enterEditMode() {
    this.editMode = true;

    if (this.contactsDropdown.length > 0) this.contactForm.enable();
  }
}
