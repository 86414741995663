import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CreateStaffUserBody } from 'src/app/core/models/user.model';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.scss']
})
export class CreateUsersComponent implements OnInit {
  userForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    country: ['', Validators.required],
    roles: ['', Validators.required]
  });

  kamSeller = false;
  kamBuyer = false;

  countries = this.coreService.countriesDropdown;

  constructor(private fb: FormBuilder,
    private coreService: CoreService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService,
    private userService: UserService) { }

  ngOnInit(): void {
  }

  createUser() {
    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();

      return;
    }

    if (this.userForm.controls.roles.value === 'kam' && !(this.kamBuyer || this.kamSeller)) {
      this.snackbar.negativeSentiment('You must select one kam role');

      return;
    }

    let roles: string[] = [];

    if (this.userForm.controls.roles.value === 'admin') {
      roles.push('admin');
    } else {
      this.kamBuyer ? roles.push('kam_buyer') : 0;
      this.kamSeller ? roles.push('kam_seller') : 0;
    }

    let body = new CreateStaffUserBody(this.userForm.value, roles);

    this.spinner.showProgressBar.next(true);

    this.userService.createStaffUser(body).subscribe({
      next: resp => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment('User created. Email sent');

        this.userForm.reset();
        this.kamBuyer = false;
        this.kamSeller = false;

      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    })
  }
}
