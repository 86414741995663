<div class="flex flex-col gap-9">
  <div class="flex justify-between">
    <div class="flex gap-6">
      <primary-btn id="manually-add-cars" (clickEmitter)="openCarsModal()">Manually add
        cars</primary-btn>

      <!-- <secondary-btn id="import-cars-from-cart">Import cars from cart</secondary-btn> -->
    </div>

    <secondary-btn id="remove-cars" (clickEmitter)="removeCars()">Remove selected cars</secondary-btn>
  </div>

  <app-dropdown id="seller-filter" [options]="sellerFilters" [control]="sellerFilterControl" class="flex"
    label="Filter sellers" (emitOnChangeSelect)="filterCars($event)" placeholder="Select seller"
    [resetValue]="true"></app-dropdown>

  <div class="overflow-x-auto">
    <table mat-table matSort [dataSource]="dataSource"
      class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">

      <ng-container matColumnDef="selectAll">
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex gap-3 uppercase !min-w-[10rem] !px-3 !py-1">
            <mat-checkbox id="select-all-checkbox" data-cy="select-all-checkbox"
              (change)="selectAll($event)"></mat-checkbox>
            <p>SELECT ALL</p>
          </div>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-checkbox [id]="'select-row-' + (i + 1) + '-checkbox'"
            [attr.data-cy]="'select-row-' + (i + 1) + '-checkbox'" [(ngModel)]="element.isSelected"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="offeredPrice">
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex gap-3 uppercase !min-w-[10rem] !px-3 !py-1">
            <p>OFFERED PRICE</p>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.offeredPrice;else noOffer">
            <div class="text-white rounded-2xl py-1 px-3 bg-green-200 ml-2 w-fit">
              €{{element.offeredPrice | number}}
            </div>
          </div>

          <ng-template #noOffer>
            <div class="text-white rounded-2xl py-1 px-3 bg-orange-100 ml-2 w-fit">No price</div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="carGroupDetails.name">
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex gap-3 uppercase !min-w-[10rem] !px-3 !py-1">
            <p>GROUP</p>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <p>{{element.carGroupDetails.name}}</p>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="col.value" *ngFor="let col of headers">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
          {{col.tableView}}</th>
        <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
          <p>{{isNumber(getValue(element, col.value)) ?
            (getValue(element, col.value) | number) : getValue(element, col.value) }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="setPrice">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1"></th>
        <td class="!py-2" mat-cell *matCellDef="let element; let i = index">
          <tertiary-btn [id]="'open-price-modal-' + i" (clickEmitter)="openSetPriceModal(element)"
            [disableBtn]="element.existingOffer > 0">Set
            price</tertiary-btn>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100"></tr>
      <tr [ngClass]="{'isSelected' : row.isSelected}" class="hover:bg-white-500" mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
