<div class="flex flex-col gap-9">
  <div class="text-2xl">
    <p>Add new holiday</p>
  </div>
  <div class="flex gap-6">
    <app-dropdown id="countries-modal" [disabled]="true" [options]="countries" [control]="countryControl"
      label="Country" placeholder="Select country"></app-dropdown>

    <app-dropdown id="months-modal" [options]="months" [control]="monthControl" label="Month" placeholder="Select month"
      (emitOnChangeSelect)="loadDays($event)"></app-dropdown>

    <app-dropdown id="days-modal" [options]="days" [control]="dayControl" placeholder="Select day"
      label="Day"></app-dropdown>
  </div>
  <div class="w-1/4 ">
    <primary-btn id="add-holiday-modal" btnText="Add holiday" (clickEmitter)="addHoliday()"
      [disableBtn]="countryControl.invalid || monthControl.invalid || dayControl.invalid"></primary-btn>
  </div>
</div>
