import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { CoreService } from './core/services/core.service';
import { Router } from '@angular/router';
import { UserRoles } from './core/models/user.model';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Cars2Click-Admin';

  constructor(private msalService: MsalService,
    private coreService: CoreService,
    private router: Router,
    private authService: AuthService) {

    this.msalService.handleRedirectObservable().subscribe((resp: AuthenticationResult) => {
      if (resp) {
        if (this.msalService.instance.getAllAccounts().length > 0) {
          let account = this.msalService.instance.getAllAccounts();

          this.msalService.instance.setActiveAccount(account[0]);
        }

        localStorage.setItem('msalToken', resp.idToken);

        setTimeout(() => {
          this.coreService.loadInfo().then(user => {
            if (user && (user.role === UserRoles.ADMIN || user.role === UserRoles.KAM)) {
              if (localStorage.getItem('redirectURL')) {
                let redirect = localStorage.getItem('redirectURL');

                localStorage.removeItem('redirectURL');

                this.router.navigate([redirect]);
              } else {
                this.router.navigate(['shop']);
              }
            } else {
              this.router.navigate(['restricted']);
            }
          });
        }, 1000)
      } else if (this.msalService.instance.getActiveAccount()) {
        this.authService.loginRedirect.next(true);
      }
    });
  }
}
