<div class="px-12 py-5 bg-blue-100 flex text-white justify-between items-center">
  <div class="flex items-center">
    <img class="w-[6.25rem]" src="./assets/icons/full-logo-white.svg" alt="">

    <p class="ml-1 text-white font-light">- Admin</p>
  </div>
  <div class="flex gap-12">
    <!-- <p class="cursor-pointer"> Dashboard</p> -->

    <div class="cursor-pointer flex gap-2" [matMenuTriggerFor]="carsInventory">
      <p>Cars inventory</p>
      <fa-icon [icon]="['fal','chevron-down']"></fa-icon>
    </div>

    <mat-menu #carsInventory="matMenu">
      <button mat-menu-item (click)="goTo('shop')">Shop</button>
      <button mat-menu-item [matMenuTriggerFor]="importCars" *ngIf="isAdmin">Import cars</button>
      <!-- <button mat-menu-item [matMenuTriggerFor]="manageCars" *ngIf="isAdmin">Manage Cars</button> -->
    </mat-menu>

    <mat-menu #importCars="matMenu">
      <button mat-menu-item (click)="goTo('online-car-upload')">Online car upload</button>
      <button mat-menu-item (click)="goTo('online-list-upload')">Online list upload</button>
      <!-- <button mat-menu-item (click)="goToCars()">Online API upload</button>
      <button mat-menu-item (click)="goToCarsOnListImport()">Cars on list import</button> -->
    </mat-menu>

    <!-- <mat-menu #manageCars="matMenu">
      <button mat-menu-item (click)="goToAllCarsList()">Online Shop</button>
      <button mat-menu-item (click)="goToCarsOnList()">Cars on list</button>
    </mat-menu> -->

    <div class="cursor-pointer flex gap-2" [matMenuTriggerFor]="buyersMenu">
      <p>Buyers</p>
      <fa-icon [icon]="['fal','chevron-down']"></fa-icon>
    </div>

    <mat-menu #buyersMenu="matMenu">
      <button mat-menu-item (click)="goTo('buyers')">Search buyers</button>
      <button mat-menu-item (click)="goTo('buyer/company')" *ngIf="isAdmin">Create buyer</button>
    </mat-menu>

    <div class="cursor-pointer flex gap-2" [matMenuTriggerFor]="sellers">
      <p>Sellers</p>
      <fa-icon [icon]="['fal','chevron-down']"></fa-icon>
    </div>

    <mat-menu #sellers="matMenu">
      <button mat-menu-item (click)="goTo('sellers')">Search sellers</button>
      <button mat-menu-item (click)="goTo('create-seller')" *ngIf="isAdmin">Create seller</button>
      <!-- <button mat-menu-item [matMenuTriggerFor]="carRequest">Car Request</button> -->
      <button mat-menu-item>Reports</button>
    </mat-menu>

    <!-- <mat-menu #carRequest="matMenu">
      <button mat-menu-item (click)="goToCreateCarRequest()">Create car request</button>
      <button mat-menu-item (click)="goToViewCarRequests()">View car request</button>
    </mat-menu> -->

    <div class="cursor-pointer flex gap-2" [matMenuTriggerFor]="kam" *ngIf="!isAdmin">
      <p>Key account area</p>
      <fa-icon [icon]="['fal','chevron-down']"></fa-icon>
    </div>

    <mat-menu #kam="matMenu">
      <!-- <button mat-menu-item (click)="goToCreateOrder()">Create order</button> -->
      <button mat-menu-item (click)="goTo('orders')">View orders</button>
      <button mat-menu-item (click)="goTo('create-offer')">Create offers</button>
      <button mat-menu-item (click)="goTo('view-offers')">View offers</button>
    </mat-menu>


    <div class="cursor-pointer flex gap-2" [matMenuTriggerFor]="sales" *ngIf="isAdmin">
      <p>Sales</p>
      <fa-icon [icon]="['fal','chevron-down']"></fa-icon>
    </div>

    <mat-menu #sales="matMenu">
      <!-- <button mat-menu-item (click)="goToCreateOrder()">Create order</button> -->
      <button mat-menu-item (click)="goTo('orders')">View orders</button>
      <button mat-menu-item (click)="goTo('create-offer')">Create offers</button>
      <button mat-menu-item (click)="goTo('view-offers')">View offers</button>
      <button mat-menu-item (click)="goTo('orders-cars')">View orders cars</button>
      <button mat-menu-item (click)="goTo('sellers-offers')">Seller offers</button>
    </mat-menu>


    <!-- <p class="cursor-pointer"> Campaign panel</p> -->

    <p class="cursor-pointer" (click)="goTo('settings/transport-matrix')" *ngIf="isAdmin"> Settings</p>
  </div>

  <div class="flex">
    <p class="text-white cursor-pointer scale-95" (click)="logout()">LOGOUT</p>
  </div>
</div>
