import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-number-ngmodel',
  templateUrl: './app-input-number-ngmodel.component.html',
  styleUrls: ['./app-input-number-ngmodel.component.scss']
})
export class AppInputNumberNgmodelComponent implements OnInit {
  @Input() inputValue: number | undefined;
  @Output() inputValueChange = new EventEmitter<number>();

  @Input() placeholder: string = '';
  @Input() label: string | undefined;
  @Input() id = 'id';

  @Input() disabled = false;


  constructor() { }

  ngOnInit(): void {
  }

}
