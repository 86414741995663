import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BuyerSyncService } from 'src/app/core/services/buyer-flow-sync.service';
import { BtnToggleOption } from 'src/app/shared/app-btn-toggle/app-btn-toggle.component';

const routes = [
  {
    viewValue: "Main company",
    value: "company",
    disabled: new BehaviorSubject(false)
  },
  {
    viewValue: "Stores",
    value: "stores",
    disabled: new BehaviorSubject(true)
  },
  {
    viewValue: "Users",
    value: "users",
    disabled: new BehaviorSubject(true)
  },
  {
    viewValue: "Contacts",
    value: "contacts",
    disabled: new BehaviorSubject(true)
  },
  {
    viewValue: "Deliveries",
    value: "restrictions",
    disabled: new BehaviorSubject(true)
  }
]

@Component({
  selector: 'app-create-buyer',
  templateUrl: './create-buyer.component.html',
  styleUrls: ['./create-buyer.component.scss'],
  providers: [BuyerSyncService]
})

export class CreateBuyerComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  routes: BtnToggleOption[] = routes;

  loadingInfo = this.buyerSyncService.loadingInfo;
  public page: string = '';

  constructor(private activatedRoute: ActivatedRoute,
    private buyerSyncService: BuyerSyncService,
    private location: Location) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      if (params['page'] && this.routes.find((r: { value: string; }) => r.value === params['page'])) {
        this.page = this.routes.find((r: { value: string; }) => r.value === params['page'])!.value;
      }

      this.page = 'company';
      this.location.replaceState(`buyer/company`);

      this.buyerSyncService.loadInfo();
    }));

    this.subscriptions.add(this.buyerSyncService.currentTab$.subscribe(
      (page) => {
        let route = this.routes.find((r: { value: string; }) => r.value === page);

        if (route) {
          this.page = route.value;

          route.disabled!.next(false);

          if (this.buyerSyncService.buyerId) {
            this.location.replaceState(`buyer/${this.page}/${this.buyerSyncService.buyerId}`);
          } else {
            this.location.replaceState(`buyer/${this.page}`);
          }
        }
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveForm(event: MouseEvent) {
    this.buyerSyncService.emitSaveEvent(event);
  }

  navigateTo(page: string) {
    this.buyerSyncService.changeTab(page);
  }

  disableBtn(route: string) {
    if (route === 'company') return false;

    let variable = JSON.stringify(this.buyerSyncService[route as keyof typeof this.buyerSyncService])

    return (JSON.parse(variable)).length === 0;
  }
}
