<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <p class="font-bold text-2xl">Create transport</p>

  <div class="flex justify-between gap-6">
    <app-input placeholder="Insert company name" [control]="nameControl"></app-input>

    <app-blue-btn id="create-transport-modal" (clickEmitter)="createTransport()">Create transport</app-blue-btn>
  </div>

  <p *ngIf="nameControl.invalid && nameControl.touched" class="text-red text-sm">Please insert a name for the company
  </p>
</div>
