<div class="flex flex-col gap-9 p-6 overflow-y-scroll">
  <div class="text-2xl font-bold">
    <p>Continue with Business case</p>
  </div>

  <div>
    <p>Info: You have uploaded a large list of cars and this might take a significative amount of time to calculate. Are
      you sure you want continue with Business case?</p>
  </div>


  <div class="flex justify-end gap-6">
    <secondary-btn (clickEmitter)="close(2)">Continue without business case</secondary-btn>

    <primary-btn (clickEmitter)="close(1)">Continue</primary-btn>
  </div>
</div>
