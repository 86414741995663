<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <div class="flex justify-between items-center mb-3">
    <p class="text-2xl">Order setup</p>

    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close(false)"></fa-icon>
  </div>

  <div class="py-9 border-t border-white-500">
    <p>This order has {{companies.length}} active invoice companies declared. Therefore, your order will be split into
      {{companies.length}} orders.</p>
  </div>

  <div class="font-bold flex flex-col gap-3">
    <p *ngFor="let c of companies">{{c}}</p>
  </div>

  <div class="flex justify-end gap-6">
    <app-white-btn (clickEmitter)="close(false)">Go back</app-white-btn>

    <primary-btn (clickEmitter)="close(true)">Continue</primary-btn>
  </div>
</div>
