import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CreateFullB2BSellerResponse, ISeller, ISellerResponse, ISellerStore, SellerSearch } from "../models/seller.model";
import { CreateSellerContactBody, CreateSellerStoreBody, CreateSellerUserBody, EditSellerCompanyBody, EditSellerPickupsBody, EditSellerStoreBody, EditSellerUserBody } from "../models/seller-models/seller-edit.model";
import { SellerType } from "../models/seller-models/seller-helpers.models";
import { FullB2BSellerDTO } from "../models/seller-models/seller-create.model";

@Injectable({
  providedIn: 'root'
})

export class SellerService {
  constructor(private http: HttpClient) { }

  //create seller
  public createFullB2BSeller(seller: FullB2BSellerDTO): Observable<CreateFullB2BSellerResponse> {
    return this.http.post<CreateFullB2BSellerResponse>(`${environment.backendApiUrl}full-b2b-seller-create`, seller);
  }

  //get seller
  public getSeller(sellerId: string): Observable<ISellerResponse> {
    return this.http.get<ISellerResponse>(`${environment.backendApiUrl}seller-details/${sellerId}`);
  }

  //search sellers
  public searchSeller(value: SellerSearch): Observable<ISellerResponse[]> {
    return this.http.post<ISellerResponse[]>(`${environment.backendApiUrl}search-seller`, value);
  }

  public getAllSellers(): Observable<ISellerResponse[]> {
    return this.http.get<ISellerResponse[]>(`${environment.backendApiUrl}sellers`);
  }

  //seller helpers
  public getSellerTypes(): Observable<SellerType[]> {
    return this.http.get<SellerType[]>(`${environment.backendApiUrl}seller-types`);
  }

  public getValidationTypes(): Observable<SellerType[]> {
    return this.http.get<SellerType[]>(`${environment.backendApiUrl}validation-types`);
  }

  //endpoints for editing seller
  public editB2BSeller(seller: EditSellerCompanyBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}seller-b2b-company-main-details-edit`, seller);
  }

  public createSellerStore(store: CreateSellerStoreBody): Observable<ISellerStore> {
    return this.http.post<ISellerStore>(`${environment.backendApiUrl}seller-b2b-add-new-store`, store);
  }

  public createSellerUser(user: CreateSellerUserBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}seller-b2b-company-add-new-user`, user);
  }

  public createSellerContact(contact: CreateSellerContactBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}seller-b2b-store-add-contact`, contact);
  }

  public editSellerStore(store: EditSellerStoreBody): Observable<ISellerStore[]> {
    return this.http.post<ISellerStore[]>(`${environment.backendApiUrl}seller-b2b-company-store-details-edit`, store);
  }

  public editSellerUser(user: EditSellerUserBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}seller-b2b-company-user-edit`, user);
  }

  public editSellerPickups(body: EditSellerPickupsBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}seller-b2b-store-pickup-edit`, body);
  }
}
