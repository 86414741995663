import { Component, OnInit } from '@angular/core';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-view-car-requests',
  templateUrl: './view-car-requests.component.html',
  styleUrls: ['./view-car-requests.component.scss']
})
export class ViewCarRequestsComponent implements OnInit {
  headers: TableHeaderMap[] = [
    {
      value: 'dateReq',
      tableView: 'date requested'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'fuelType',
      tableView: 'fuel type'
    },
    {
      value: 'gearbox',
      tableView: 'gearbox'
    },
    {
      value: 'year',
      tableView: 'manufacture year range'
    },
    {
      value: 'mileage',
      tableView: 'mileage range'
    },
    {
      value: 'noOfSellers',
      tableView: 'Number of sellers'
    },
  ];

  displayedColumns = this.headers.map(h => h.value);

  requests = [
    {
      dateReq: new Date(),
      make: 'BMW',
      fuelType: 'Diesel',
      gearbox: 'Automatic',
      year: '2022',
      mileage: 120000,
      noOfSellers: 40
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
