import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { IdentifyCarRequestClass } from 'src/app/core/models/car.model';
import { CarOnlineListUploadResponse } from 'src/app/core/models/cars-inventory.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CarIdentifiedModalComponentCar } from 'src/app/features/car-view/car-details/car-identified-modal/car-identified-modal.component';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

interface ModifyUnidentifiedCarsModalData {
  car: CarOnlineListUploadResponse,
  makes: DropdownOption[]
}

@Component({
  selector: 'app-modify-unidentified-cars-modal',
  templateUrl: './modify-unidentified-cars-modal.component.html',
  styleUrls: ['./modify-unidentified-cars-modal.component.scss']
})
export class ModifyUnidentifiedCarsModalComponent implements OnInit {
  progressBarActive = new BehaviorSubject<boolean>(false);

  identificationForm = this.fb.group({
    make: ['', Validators.required],
    model: ['', Validators.required],
    fuelType: ['', Validators.required],
    gearbox: ['', Validators.required],
    variant: ['', Validators.required],
    trim: ['', Validators.required],
  });

  makes = this.data.makes;
  models: DropdownOption[] = [];
  fuelTypes: DropdownOption[] = [];
  gearboxes: DropdownOption[] = [];
  variants: DropdownOption[] = [];
  trims: DropdownOption[] = [];

  car = this.data.car;

  carAlternatives: CarIdentifiedModalComponentCar[] = [];

  constructor(private fb: FormBuilder,
    private carFlowSyncService: CarFlowSyncService,
    private carService: CarService,
    private snackbar: SnackbarService,
    public dialogRef: MatDialogRef<ModifyUnidentifiedCarsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModifyUnidentifiedCarsModalData,
    private utilsService: UtilsService) { }

  ngOnInit(): void { }

  loadModels(make: string) {
    this.progressBarActive.next(true);

    //reset controls
    this.identificationForm.controls.model.reset();
    this.identificationForm.controls.fuelType.reset();
    this.identificationForm.controls.gearbox.reset();
    this.identificationForm.controls.variant.reset();
    this.identificationForm.controls.trim.reset();

    const query = `models?make=${encodeURIComponent(make)}`;

    this.carService.getCarNomenclatorData(query).subscribe(resp => {
      this.models = resp.map(m => { return { viewValue: m, value: m } });

      this.progressBarActive.next(false);
    });
  }

  loadFueltypes(model: string) {
    this.progressBarActive.next(true);

    //reset controls
    this.identificationForm.controls.fuelType.reset();
    this.identificationForm.controls.gearbox.reset();
    this.identificationForm.controls.variant.reset();
    this.identificationForm.controls.trim.reset();

    const query = `fuelTypes?make=${encodeURIComponent(this.identificationForm.controls.make.value!)}&model=${encodeURIComponent(model)}`;

    this.carService.getCarSpecificationData(query).subscribe(resp => {
      this.fuelTypes = resp.map(m => { return { viewValue: m.name, value: m.name } });

      this.progressBarActive.next(false);
    })
  }

  loadGearbox(fuelType: string) {
    this.progressBarActive.next(true);

    //reset controls
    this.identificationForm.controls.gearbox.reset();
    this.identificationForm.controls.variant.reset();
    this.identificationForm.controls.trim.reset();

    let query = `gearboxes?make=${encodeURIComponent(this.identificationForm.controls.make.value!)}&model=${encodeURIComponent(this.identificationForm.controls.model.value!)}&fuelType=${encodeURIComponent(fuelType)}`;

    this.carService.getCarSpecificationData(query).subscribe(resp => {
      this.gearboxes = resp.map(m => { return { viewValue: m.name, value: m.name } });

      this.progressBarActive.next(false);
    })
  }

  loadVariants(gearbox: string) {
    this.progressBarActive.next(true);

    //reset controls
    this.identificationForm.controls.variant.reset();
    this.identificationForm.controls.trim.reset();

    let query = `variants?make=${encodeURIComponent(this.identificationForm.controls.make.value!)}&model=${encodeURIComponent(this.identificationForm.controls.model.value!)}&fuelType=${encodeURIComponent(this.identificationForm.controls.fuelType.value!)}&gearbox=${encodeURIComponent(gearbox)}`;

    this.carService.getCarNomenclatorData(query).subscribe(resp => {
      this.variants = resp.map(m => { return { viewValue: m, value: m } });

      this.progressBarActive.next(false);
    })
  }

  loadTrims(variant: string) {
    this.progressBarActive.next(true);

    //reset controls
    this.identificationForm.controls.trim.reset();

    let query = `trims?make=${encodeURIComponent(this.identificationForm.controls.make.value!)}&model=${encodeURIComponent(this.identificationForm.controls.model.value!)}&fuelType=${encodeURIComponent(this.identificationForm.controls.fuelType.value!)}&gearbox=${encodeURIComponent(this.identificationForm.controls.gearbox.value!)}&variant=${encodeURIComponent(variant)}`;

    this.carService.getCarNomenclatorData(query).subscribe(resp => {
      this.trims = resp.map(m => { return { viewValue: m, value: m } });

      this.progressBarActive.next(false);
    })
  }

  identifyCar() {
    this.progressBarActive.next(true);

    let carRequest = new IdentifyCarRequestClass(this.identificationForm.value);

    this.carAlternatives = [];

    this.carService.identifyCar(carRequest).subscribe({
      next: (resp) => {
        let params = ["model", "variant", "trim", "enginePower", "drivingWheels", "gearbox", "bodyType", "fuelType"];

        resp.forEach(c => {
          let car: CarIdentifiedModalComponentCar = {
            ...c,
            identificationToggle: false,
            equipmentToggle: false,
            equipmentString: [],
            identificationStrings: []
          };

          this.utilsService.equipmentArrayTransform(car.equipmentString, c.optionalEquipment);
          this.utilsService.equipmentArrayTransform(car.equipmentString, c.standardEquipment);
          this.utilsService.equipmentArrayTransform(car.equipmentString, c.packEquipment);

          car.equipmentString = car.equipmentString.map(c => c).filter((v, i, a) => a.indexOf(v) === i);

          params.forEach(p => {
            let value = car[p as keyof typeof car];

            if (value) car.identificationStrings!.push({
              value: value.toString(),
              label: this.utilsService.splitWordByCamelcase(p)
            });
          })

          this.carAlternatives.push(car);
        })

        this.snackbar.positiveSentiment('Car identified');
        this.progressBarActive.next(false);
      },
      error: (e) => {
        if (e.status === 404) this.snackbar.negativeSentiment('Car not found')

        this.progressBarActive.next(false);
      }
    })
  }

  toggleCarIdentification(index: number) {
    this.carAlternatives[index].identificationToggle = this.carAlternatives[index].identificationToggle ? false : true;

    let panel = document.getElementById(`car-identification-${index}`)!;

    if (this.carAlternatives[index].identificationToggle) {
      panel!.style.marginTop = `16px`;
      panel!.style.maxHeight = `${panel!.scrollHeight}px`;
    } else {
      panel!.style.maxHeight = '0';
      panel!.style.marginTop = '0';
    }

    if (!this.carAlternatives[index].identificationToggle && this.carAlternatives[index].equipmentToggle) this.toggleCarEquipment(index)
  }

  toggleCarEquipment(index: number) {
    this.carAlternatives[index].equipmentToggle = this.carAlternatives[index].equipmentToggle ? false : true;

    let panel = document.getElementById(`car-equipment-${index}`)!;

    if (this.carAlternatives[index].equipmentToggle) {
      panel!.style.marginTop = `16px`;
      panel!.style.maxHeight = `${panel!.scrollHeight}px`;
    } else {
      panel!.style.maxHeight = '0';
      panel!.style.marginTop = '0';
    }
  }

  closeModal(index: number) {
    this.carAlternatives[index].alternatives = this.carAlternatives;

    this.dialogRef.close(this.carAlternatives[index]);
  }
}
