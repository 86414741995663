<div class="twoCols">
  <app-main-content-layout>
    <div class="title">
      <p>Selected Buyer</p>
    </div>

    <app-main-content-row-layout>
      <p>Main company name</p>

      <app-read-only-input class="secondContent">{{buyer?.name}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>VAT number</p>

      <app-read-only-input class="secondContent">{{buyer?.vatDetails?.vat}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Address</p>

      <app-read-only-input class="secondContent">{{buyer?.address}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>City</p>

      <app-read-only-input class="secondContent">{{buyer?.city}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Postal Code</p>

      <app-read-only-input class="secondContent">{{buyer?.postalCode}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>City</p>

      <app-read-only-input class="secondContent">{{buyer?.city}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Country</p>

      <app-read-only-input class="secondContent">{{buyer?.countryDetails?.country}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>User name</p>

      <app-read-only-input class="secondContent">{{user?.firstName}} {{user?.lastName}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>User email</p>

      <app-read-only-input class="secondContent">{{user?.email}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>User phone</p>

      <app-read-only-input class="secondContent">{{user?.phoneNumber}}</app-read-only-input>
    </app-main-content-row-layout>
  </app-main-content-layout>

  <app-main-content-layout>
    <div class="title">
      <p>Delivery store</p>
    </div>

    <app-main-content-row-layout>
      <p>Store name</p>

      <app-read-only-input class="secondContent">{{store?.name}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Country</p>

      <app-read-only-input class="secondContent">{{store?.countryDetails?.country}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>City</p>

      <app-read-only-input class="secondContent">{{store?.city}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Address</p>

      <app-read-only-input class="secondContent">{{store?.address}}</app-read-only-input>
    </app-main-content-row-layout>


    <app-main-content-row-layout>
      <p>Postal Code</p>

      <app-read-only-input class="secondContent">{{store?.postalCode}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Contact name</p>

      <app-read-only-input class="secondContent">{{contact?.firstName}} {{contact?.lastName}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Contact email</p>

      <app-read-only-input class="secondContent">{{contact?.email}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Contact phone</p>

      <app-read-only-input class="secondContent">{{contact?.phone}}</app-read-only-input>
    </app-main-content-row-layout>
  </app-main-content-layout>

  <app-main-content-layout class="col-span-2">
    <div class="title">
      <p>Options</p>
    </div>

    <div class="flex gap-6 flex-wrap">
      <app-datepicker id="request-delivery-date" [control]="orderOptions.controls.deliveryDate"
        label="Requested delivery date"></app-datepicker>

      <app-datepicker id="estimated-payment-date" [control]="orderOptions.controls.estimatedPaymentDate"
        label="Estimated payment date"></app-datepicker>

      <app-toggle id="transport-by-c2c" [toggleFormControl]="orderOptions.controls.transportC2C"
        toggleLabel="Transport by cars2click"></app-toggle>

      <app-toggle id="separate-invoice-transport" [toggleFormControl]="orderOptions.controls.separateTransportInvoice"
        toggleLabel="Separate invoice for
      transport"></app-toggle>

      <app-toggle id="separate-invoice" [toggleFormControl]="orderOptions.controls.separateInvoice"
        toggleLabel="One invoice per car"></app-toggle>

      <app-toggle id="documents-company-address" [toggleFormControl]="orderOptions.controls.documentsSameAddress"
        toggleLabel="Documents to company
        address"></app-toggle>
    </div>

    <div>
      <textarea id="notes-textarea" data-cy="notes-textarea" [(ngModel)]="notes" rows="4"
        class="w-full rounded border border-white-500 p-6 text-blue-100 text-sm focus:outline-none"
        placeholder="Insert notes here..."></textarea>
    </div>
  </app-main-content-layout>

  <app-orders-kpi class="col-span-2" ></app-orders-kpi>

  <div class="col-span-2">
    <app-table [data]="cars" [headersMap]="headers" [displayedColumns]="displayedColumns"></app-table>
  </div>
</div>
