import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  value = Math.floor(Math.random() * (50 - 30) + 30);
  redirectSubscription = new Subscription();

  constructor(private msalService: MsalService,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.value += Math.floor(Math.random() * (50 - 30) + 30);
    }, 2000);

    this.redirectSubscription = this.authService.loginRedirect$.subscribe(resp => {
      this.router.navigate(['shop']);
    });
  }

  login() {
    this.msalService.loginRedirect();
  }

  isLoggedIn() {
    return this.msalService.instance.getActiveAccount() != null;
  }
}
