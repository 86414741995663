export const environment = {
  production: false,
  backendApiUrl: 'https://cars2click-api.dev.cars2click.com/api/',
  authRedirectUrl: 'https://devcars2clickusers.b2clogin.com/devcars2clickusers.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sign-in&client_id=134e24dc-dd3c-4e7f-a4e2-a372280dd5ff&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdev-admin.cars2click.eu%2Fafter-login&scope=openid&response_type=id_token&prompt=login',
  carPlaceholder: './assets/background-photos/car-placeholder.png',

  MSAL_CLIENT_ID: '134e24dc-dd3c-4e7f-a4e2-a372280dd5ff',
  MSAL_AUTHORITY: 'https://devcars2clickusers.b2clogin.com/devcars2clickusers.onmicrosoft.com/B2C_1_sign-in',
  MSAL_LOGIN_REDIRECT_URI: 'https://dev-admin.cars2click.eu',
  MSAL_LOGOUT_REDIRECT_URI: 'https://dev-admin.cars2click.eu',
  MSAL_KNOWN_AUTHORITIES: 'devcars2clickusers.b2clogin.com',
  MSAL_SCOPES: 'https://devcars2clickusers.onmicrosoft.com/134e24dc-dd3c-4e7f-a4e2-a372280dd5ff/user.read'
};
