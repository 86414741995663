<app-navbar></app-navbar>

<app-page-layout class="min-h-[85vh]">
  <div class="flex flex-col gap-9">
    <p class="font-bold text-2xl">Edit car</p>

    <div class="flex overflow-auto">
      <app-btn-toggle [options]="routes" [selectedOption]="page" (changeEmitter)="changePage($event)"></app-btn-toggle>
    </div>

    <div *ngIf="!(loadingInfo | async); else spinner">
      <div [ngSwitch]="page">
        <app-car-details *ngSwitchCase="'car-details'"></app-car-details>

        <app-car-equipment *ngSwitchCase="'car-equipment'"></app-car-equipment>

        <app-car-images *ngSwitchCase="'car-images'"></app-car-images>

        <app-car-businesscase *ngSwitchCase="'businessCase'"></app-car-businesscase>

        <app-car-documents *ngSwitchCase="'documents'"></app-car-documents>

        <app-car-transport *ngSwitchCase="'transport'"></app-car-transport>
      </div>
    </div>
  </div>
</app-page-layout>

<ng-template #spinner>
  <div class="twoCols" *ngIf="page != 'transport';else transportPlaceholder">
    <app-main-content-placeholder class="col-span-1"></app-main-content-placeholder>
    <app-main-content-placeholder class="col-span-1"></app-main-content-placeholder>
  </div>

  <ng-template #transportPlaceholder>
    <div class="p-9 bg-white rounded grid grid-cols-2 gap-9">
      <div class="col-span-1 placeholder-animation h-9"></div>
      <div class="h-[1px] bg-white-500 col-span-2"></div>
      <div class="col-span-1 flex flex-col gap-6">
        <div class="placeholder-animation h-9"></div>
        <div class="placeholder-animation h-9"></div>
        <div class="placeholder-animation h-9"></div>
      </div>
      <div class="col-span-1 flex flex-col gap-6">
        <div class="placeholder-animation h-9"></div>
        <div class="placeholder-animation h-9"></div>
        <div class="placeholder-animation h-9"></div>
      </div>
    </div>
  </ng-template>
</ng-template>
