<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loading | async);else tablePlaceholder">
    <div class="font-bold text-2xl">
      <p>My orders - view by car level</p>
    </div>

    <!-- filters -->
    <div class="flex flex-wrap gap-6 items-center gap-y-8">
      <div class="relative">
        <app-input placeholder="Insert VIN" [control]="filterBody.controls.vin" label="VIN"></app-input>
        <p class="absolute text-xs text-red" *ngIf="filterBody.controls.vin.invalid">Minimum 3 characters required</p>
      </div>

      <app-input placeholder="Insert reg no" [control]="filterBody.controls.regNo" label="Reg no"></app-input>

      <app-dropdown [resetValue]="true" placeholder="Select make" [resetValue]="true" [options]="makes"
        [control]="filterBody.controls.make" label="Make" (emitOnChangeSelect)="loadModels($event)"></app-dropdown>

      <app-dropdown [resetValue]="true" placeholder="Select model" [options]="models"
        [control]="filterBody.controls.model" label="Model" (emitOnChangeSelect)="loadVariants($event)"></app-dropdown>

      <app-dropdown [resetValue]="true" placeholder="Select variant" [options]="variants"
        [control]="filterBody.controls.variant" label="Variant"></app-dropdown>

      <app-input placeholder="Insert order no" [control]="filterBody.controls.orderNo" label="Order number"></app-input>

      <app-datepicker [control]="filterBody.controls.orderFrom" label="Order from"></app-datepicker>

      <app-datepicker [control]="filterBody.controls.orderTo" label="Order to"></app-datepicker>

      <app-dropdown [resetValue]="true" placeholder="Select order status" [control]="filterBody.controls.orderStatus"
        label="Order status" [options]="orderStatuses"></app-dropdown>

      <app-dropdown [resetValue]="true" placeholder="Select order substatus" [control]="filterBody.controls.subStatus"
        label="Order substatus" [options]="orderSubstatuses"></app-dropdown>

      <app-dropdown [resetValue]="true" placeholder="Select car status" [control]="filterBody.controls.carStatus"
        label="Car status" [options]="carStatuses"></app-dropdown>

      <app-dropdown [resetValue]="true" placeholder="Select car location" [options]="countries"
        [control]="filterBody.controls.carLocation" label="Car location"></app-dropdown>

      <app-dropdown [resetValue]="true" placeholder="Select car delivery location" [options]="countries"
        [control]="filterBody.controls.carDeliveryLocation" label="Car delivery location"></app-dropdown>

      <app-dropdown [resetValue]="true" placeholder="Select transport status"
        [control]="filterBody.controls.transportStatus" label="Transport status"
        [options]="transportStatuses"></app-dropdown>

      <app-dropdown [resetValue]="true" placeholder="Select car ready status" [control]="filterBody.controls.carReady"
        label="Car ready" [options]="boolDropdown"></app-dropdown>

      <app-datepicker [control]="filterBody.controls.pickupDateFrom" label="Pick date from"></app-datepicker>

      <app-datepicker [control]="filterBody.controls.pickupDateTo" label="Pick date to"></app-datepicker>

      <app-dropdown [resetValue]="true" placeholder="Select transport by C2C status"
        [control]="filterBody.controls.transportByC2C" label="Transport by C2C" [options]="boolDropdown"></app-dropdown>

      <app-input placeholder="Insert KAM buyer" [control]="filterBody.controls.kamBuyer" label="KAM Buyer"></app-input>

      <app-input placeholder="Insert KAM seller" [control]="filterBody.controls.kamSeller"
        label="KAM Seller"></app-input>

      <tertiary-btn (clickEmitter)="resetFilters()">Reset filters</tertiary-btn>
      <primary-btn (clickEmitter)="search()">Search</primary-btn>
      <app-blue-btn (clickEmitter)="downloadCars()">Download table</app-blue-btn>
    </div>

    <div class="overflow-x-auto">
      <table mat-table matSort [dataSource]="dataSource"
        class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">

        <ng-container [matColumnDef]="'vin'" sticky>
          <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            VIN
          </th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <p>
              {{element.vin}}
            </p>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'regNo'" sticky>
          <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            reg No
          </th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <p>
              {{element.regNo}}
            </p>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'carDetails'" sticky>
          <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            car Details
          </th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <div class="flex flex-wrap gap-3 w-max">
              <p class="!border-r !border-white-500 !pr-3">
                {{element.make}}
              </p>

              <p class="!border-r !border-white-500 !pr-3">
                {{element.model}}
              </p>

              <p>
                {{element.variant}}
              </p>
            </div>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="col.value" *ngFor="let col of tableMap">
          <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            {{col.tableView}}</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            {{ col.isNumber ? (element[col.value] | number) : getValue(element, col)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="viewCar" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="!px-4">
          </th>

          <td class="!p-6" mat-cell *matCellDef="let element;let i = index">
            <a class="px-4 py-3 bg-gray-100 rounded" [routerLink]="'/order/'+element.orderId" routerLinkActive="active"
              target="_blank">View order</a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumns" class="bg-white-100"></tr>
        <tr [ngClass]="{'isSelected' : row.isSelected}" class="hover:bg-white-500" mat-row
          *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator #paginator class="bg-white-400" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</app-page-layout>

<ng-template #tablePlaceholder>
  <app-table-placeholder></app-table-placeholder>
</ng-template>
