import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { ViewSellerOffersComponent } from './view-seller-offers/view-seller-offers.component';
import { SellerOfferComponent } from './seller-offer/seller-offer.component';
import { SellerOfferDetailsComponent } from './seller-offer/seller-offer-details/seller-offer-details.component';
import { SellerOfferCounterOfferModalComponent } from './seller-offer/seller-offer-details/seller-offer-counter-offer-modal/seller-offer-counter-offer-modal.component';
import { SellerOfferBusinesscaseComponent } from './seller-offer/seller-offer-businesscase/seller-offer-businesscase.component';
import { SellerOfferVinComponent } from './seller-offer/seller-offer-vin/seller-offer-vin.component';
import { PublishCarModalComponent } from './seller-offer/publish-car-modal/publish-car-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ViewSellerOffersComponent,
    SellerOfferComponent,
    SellerOfferDetailsComponent,
    SellerOfferCounterOfferModalComponent,
    SellerOfferBusinesscaseComponent,
    SellerOfferVinComponent,
    PublishCarModalComponent,
  ]
})

export class SellYourCarModule { }
