<app-expansion-panel [title]="'Make and model'" [expandedPanel]="true" [panelId]="filterId"
  [showXMark]="filterId != 'firstFilter'" (emitXMark)="removeFilter()">
  <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
    <app-dropdown [id]="'make-' + id" [placeholder]="'Make'" (emitOnChangeSelect)="loadModels($event)" [options]="makes" [resetValue]="true"
      [control]="makeControl">
    </app-dropdown>

    <app-dropdown [id]="'model-' + id" [placeholder]="'Model'" [options]="models" [multipleSelect]="true"
      [control]="modelControl" (emitOnChangeSelect)="changeModels($event)">
    </app-dropdown>
  </div>
</app-expansion-panel>
