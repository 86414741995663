import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EditSellerCompanyBody } from 'src/app/core/models/seller-models/seller-edit.model';
import { ISeller } from 'src/app/core/models/seller.model';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-view-b2b-seller-company',
  templateUrl: './view-b2b-seller-company.component.html',
  styleUrls: ['./view-b2b-seller-company.component.scss']
})
export class ViewB2bSellerCompanyComponent implements OnInit {
  isAdmin = this.coreService.isAdmin;
  user = this.coreService.user!.user;

  editMode = false;

  public saveFormSubscription: Subscription = new Subscription();
  public routeSubscription: Subscription = new Subscription();

  sellerId = this.b2bSellerSyncService.sellerId;
  stores = this.b2bSellerSyncService.sellerResponse!.stores;
  seller = this.b2bSellerSyncService.sellerResponse!.sellerCompanyDetails;

  vatControl: FormControl = new FormControl('', Validators.required);

  makes: DropdownOption[] = this.b2bSellerSyncService.makes;
  sellerTypes: DropdownOption[] = this.b2bSellerSyncService.sellerTypes;
  validationTypes: DropdownOption[] = this.b2bSellerSyncService.validationTypes;
  kamUsers: DropdownOption[] = this.b2bSellerSyncService.kams;

  dateCreated: Date | undefined;
  dateUpdated: Date | undefined;
  statusControl = new FormControl({ value: false, disabled: true });
  vatStatusControl = new FormControl({ value: false, disabled: true });
  defaultStoreControl = new FormControl();
  defaultStores: DropdownOption[] = this.b2bSellerSyncService.sellerResponse!.stores.map(s => ({ value: s.id, viewValue: s.name }));
  countries = this.coreService.countriesDropdown;

  sellerDetailsForm = this.fb.group({
    kamId: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    website: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    makesSold: new FormControl<string[] | undefined>([]),
    makesRep: new FormControl<string[] | undefined>([]),
    sellerType: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required)
  });

  constructor(private coreService: CoreService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private b2bSellerSyncService: B2BSellerSyncService,
    private sellerService: SellerService,
    private spinnerService: SpinnerHandlerService) { }

  ngOnInit() {
    this.routeSubscription = this.b2bSellerSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.sellerDetailsForm.invalid) {
          this.snackbar.negativeSentiment('You need to complete ad settings to continue');
        } else {
          this.b2bSellerSyncService.setCurrentTab(value);
        }
      }
    )

    this.sellerDetailsForm.setValidators(Validators.required);

    this.patchB2BSellerValues(this.b2bSellerSyncService.sellerResponse!.sellerCompanyDetails);
  }

  ngOnDestroy() {
    this.saveFormSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  saveSeller() {
    if (this.spinnerService.showProgressBar.value) return;

    let seller = new EditSellerCompanyBody(this.sellerDetailsForm.value, this.sellerDetailsForm.controls.country.value!, this.vatControl.value!, this.vatStatusControl.value!, this.sellerId!, this.statusControl.value!);

    this.spinnerService.showProgressBar.next(true);

    this.sellerService.editB2BSeller(seller).subscribe({
      next: resp => {
        this.seller = {
          ...this.seller,
          ...seller,
        };

        this.b2bSellerSyncService.sellerResponse!.sellerCompanyDetails = this.seller;

        let kam = this.b2bSellerSyncService.kams.find(k => k.value === seller.kamId)!;

        this.b2bSellerSyncService.sellerResponse!.kamDetails.email = kam.viewValue;
        this.b2bSellerSyncService.sellerResponse!.kamDetails.id = kam.value;

        this.snackbar.positiveSentiment("Seller edited successfully");

        this.spinnerService.showProgressBar.next(false);
      },
      error: err => {
        this.spinnerService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  patchB2BSellerValues(seller: ISeller) {
    this.sellerDetailsForm.patchValue({
      name: seller.name,
      sellerType: seller.typeDetails.typeId,
      address: seller.address,
      city: seller.city,
      country: seller.countryDetails.countryId,
      postalCode: seller.postalCode,
      website: seller.companyWebsite,
      phone: seller.phone,
      email: seller.email,
      kamId: this.b2bSellerSyncService.sellerResponse!.kamDetails.id,
      makesRep: seller.repMakes,
      makesSold: seller.soldMakes
    })


    this.dateCreated = seller.createdAt;
    this.dateUpdated = seller.modifiedAt;

    this.statusControl.setValue(seller.status!);
    this.vatControl.setValue(seller.vatDetails.vat);
    this.vatStatusControl.setValue(seller.vatDetails.vatStatus);

    this.vatControl.disable();
    this.sellerDetailsForm.disable();
  }

  enterEditMode() {
    this.editMode = true;

    this.statusControl.enable();
    this.sellerDetailsForm.enable();
    this.sellerDetailsForm.controls.country.disable();
  }
}
