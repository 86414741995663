import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropdownOption } from '../app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-main-content-row-dropdown',
  template: `
    <app-main-content-row-layout>
      <p [ngClass]="{'text-red font-bold' : control.invalid && control.touched}">{{title}}*</p>

      <app-dropdown class="secondContent" [id]="componentId" [placeholder]="placeholder" [control]="control" [options]="options" [multipleSelect]="multipleSelect" [selectAll]="selectAll"></app-dropdown>
    </app-main-content-row-layout>
  `,
})
export class MainContentRowDropdownComponent implements OnInit {
  @Input() control = new FormControl();
  @Input() title = '';
  @Input() componentId = '';
  @Input() placeholder = '';
  @Input() options: DropdownOption[] = [];
  @Input() multipleSelect = false;
  @Input() selectAll = false;

  constructor() { }

  ngOnInit() { }
}
