import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { BuyerCompanyComponent } from "./buyer-company/buyer-company.component";
import { BuyerStoresComponent } from "./buyer-stores/buyer-stores.component";
import { BuyerStoreContactsComponent } from "./buyer-store-contacts/buyer-store-contacts.component";
import { BuyerUsersComponent } from "./buyer-users/buyer-users.component";
import { BuyerStoreRestrictionsComponent } from "./buyer-store-restrictions/buyer-store-restrictions.component";
import { CreateBuyerComponent } from "./create-buyer.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    BuyerCompanyComponent,
    BuyerStoresComponent,
    BuyerStoreContactsComponent,
    BuyerUsersComponent,
    BuyerStoreRestrictionsComponent,
    CreateBuyerComponent
  ]
})

export class CreateBuyerModule { }
