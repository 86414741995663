import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom, Subscription } from 'rxjs';
import { Buyer, EditBuyerBody } from 'src/app/core/models/buyer.model';
import { BuyerSyncService } from 'src/app/core/services/buyer-flow-sync.service';
import { BuyerService } from 'src/app/core/services/buyer.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';

@Component({
  selector: 'app-view-buyer-company',
  templateUrl: './view-buyer-company.component.html',
  styleUrls: ['./view-buyer-company.component.scss']
})
export class ViewBuyerCompanyComponent implements OnInit {
  subscriptions: Subscription = new Subscription();

  buyerId = this.buyerSyncService.buyerId;

  vatControl: FormControl = new FormControl('', Validators.required);
  validatedVAT = false;

  countries = this.coreService.countries.map(c => ({ value: c.id, viewValue: c.name }));
  makes = this.buyerSyncService.makes;
  priorities = this.buyerSyncService.priorities;
  storesOptions = this.buyerSyncService.stores.map(s => ({ viewValue: s.name!, value: s.id! }));
  kams = this.buyerSyncService.kams;

  carGroups: string[] = [];

  companyForm = this.fb.group({
    name: ['', Validators.required],
    postalCode: ['', Validators.required],
    address: ['', Validators.required],
    city: ['', Validators.required],
    country: ['', Validators.required],
    make: [[''], Validators.required],
    priority: ['', Validators.required],
    website: ['', Validators.required],
    cars2viewDealer: [''],
    phone: [''],
    email: ['', Validators.email],
    kamId: ['', Validators.required],
  });

  dateCreated: Date | undefined;
  dateUpdated: Date | undefined;
  statusControl = new FormControl({ value: false, disabled: true });
  vatStatusControl = new FormControl({ value: false, disabled: true });

  @Input() isAdmin = false;
  user = this.coreService.user!.user;

  editMode = false;

  constructor(private snackbarService: SnackbarService,
    private buyerSyncService: BuyerSyncService,
    private buyerService: BuyerService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private coreService: CoreService,
    private spinnerService: SpinnerHandlerService) { }

  ngOnInit() {
    this.subscriptions.add(this.buyerSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.companyForm.dirty) {
          let goNext = await this.unsavedChangesModal();

          goNext ? this.buyerSyncService.setCurrentTab(value) : 0;
        } else {
          this.buyerSyncService.setCurrentTab(value);
        }
      }
    ));

    this.subscriptions.add(this.buyerSyncService.saveFormEvent$.subscribe(
      (value) => {
        this.saveForm();
      }
    ))

    this.companyForm.disable();

    if (this.buyerSyncService.buyer) {
      this.patchFormValues(this.buyerSyncService.buyer);
    }

    this.carGroups = this.buyerSyncService.buyerGetResponse!.users.reduce((arr: string[], user) => {
      if (user.details.closedSalesGroups) {
        user.details.closedSalesGroups.forEach(g => {
          if (!arr.includes(g)) arr.push(g);
        });
      }

      return arr;
    }, []).filter(g => this.buyerSyncService.groups.find(gr => gr.value === g)).map(g => this.buyerSyncService.groups.find(gr => gr.value === g)!.viewValue);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  patchFormValues(buyer: Buyer) {
    this.companyForm.patchValue({
      name: buyer.name,
      address: buyer.address,
      city: buyer.city,
      postalCode: buyer.postalCode,
      website: buyer.companyWebsite,
      priority: buyer.priorityDetails.priorityId,
      email: buyer.email,
      cars2viewDealer: buyer.dealerC2V,
      phone: buyer.phone,
      country: buyer.countryDetails.countryId,
      make: buyer.makes,
      kamId: this.buyerSyncService.buyerKam,
    });

    this.dateCreated = buyer.createdAt;
    this.dateUpdated = buyer.modifiedAt;

    this.statusControl.setValue(buyer.status);
    this.vatStatusControl.setValue(buyer.vatDetails.vatStatus);

    this.vatControl.setValue(buyer.vatDetails.vat);
    this.vatControl.disable();
    this.validatedVAT = true;
  }

  saveForm() {
    if (this.spinnerService.showProgressBar.value) return;

    if (this.companyForm.invalid || this.vatControl.invalid || !this.companyForm.controls.name.value) {
      this.snackbarService.negativeSentiment('Complete all required fields');
      return;
    }

    this.spinnerService.showProgressBar.next(true);

    const company = new EditBuyerBody(this.companyForm.value, this.companyForm.controls.country.value!, this.vatControl.value, this.vatStatusControl.value!, this.buyerId!, this.statusControl.value!);

    this.buyerService.editBuyer(company).subscribe({
      next: resp => {
        this.buyerSyncService.buyer = {
          ...this.buyerSyncService.buyer!,
          ...company,
        };

        let kam = this.buyerSyncService.kams.find(k => k.value === company.kamId)!;

        this.buyerSyncService.buyerGetResponse!.buyerCompanyDetails = this.buyerSyncService.buyer!;
        this.buyerSyncService.buyerGetResponse!.kamDetails.email = kam.viewValue;
        this.buyerSyncService.buyerGetResponse!.kamDetails.id = kam.value;
        this.buyerSyncService.buyerKam = kam.value;

        this.companyForm.markAsPristine();

        this.spinnerService.showProgressBar.next(false);

        this.snackbarService.positiveSentiment('Buyer successfully edited');
      },
      error: err => {

        this.spinnerService.showProgressBar.next(false);

        this.snackbarService.negativeSentiment(err.error);
      }
    })
  }

  async unsavedChangesModal(): Promise<boolean> {
    const dialogRef = this.dialog.open(
      UnsavedChangesModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
      }
    });

    return await firstValueFrom(dialogRef.afterClosed())
  }

  enterEditMode() {
    this.editMode = true;

    this.companyForm.enable();
    this.companyForm.controls.country.disable();
    this.statusControl.enable();
  }
}
