<div class="twoCols">
  <div class="col-span-2 flex justify-between">
    <div class="flex gap-6">
      <app-dropdown id="stores" label="Store" placeholder="Select store" [options]="stores"
        (emitOnChangeSelect)="selectStore($event)" [control]="storesControl"></app-dropdown>
      <app-dropdown id="contacts" label="Contact" placeholder="Select contact" [options]="contactsDropdown"
        [control]="contactControl" (emitOnChangeSelect)="changeContact($event)">
      </app-dropdown>
    </div>

    <secondary-btn id="edit-mode" *ngIf="isAdmin && !editMode" (clickEmitter)="enterEditMode()">Edit
      contacts</secondary-btn>

    <div class="flex gap-6" *ngIf="editMode">
      <secondary-btn id="save-contact" btnText="Save contact" (clickEmitter)="saveContact()"></secondary-btn>
      <tertiary-btn id="add-contact" btnText="Add a contact person" (clickEmitter)="addContact()"></tertiary-btn>
    </div>
  </div>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Contact Details</p>
    </div>

    <app-main-content-row-layout>
      <p>First name*</p>

      <app-input id="firstname" class="secondContent" [control]="contactForm.controls.firstName"
        placeholder="Insert first name"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Last name*</p>

      <app-input id="lastname" class="secondContent" [control]="contactForm.controls.lastName"
        placeholder="Insert last name"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <div class="flex items-center">
        <p>Email*</p>

        <p class="relative -left-[2.9375rem] top-6 text-sm text-red opacity-0 whitespace-nowrap"
          [ngClass]="{'opacity-100':contactForm.controls.email.hasError('email')}">Insert valid email</p>
      </div>

      <app-input id="email" class="secondContent" [control]="contactForm.controls.email" placeholder="Insert email">
      </app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Phone</p>

      <app-input id="phone" class="secondContent" [control]="contactForm.controls.phone" placeholder="Insert phone">
      </app-input>
    </app-main-content-row-layout>

    <!-- <app-main-content-row-layout>
      <p>GDPR status</p>

      <app-app-radio-btns class="secondContent" [options]="gdprRadio"></app-app-radio-btns>
    </app-main-content-row-layout> -->

    <div class="grid grid-cols-2 gap-5 pt-6 border-t border-white-500">
      <div class="col-span-1">
        <p>Send accounting email to this user</p>
      </div>

      <div class="col-span-1">
        <app-toggle id="accounting-email" [toggleFormControl]="contactForm.controls.accountingEmail"></app-toggle>
      </div>

      <div class="col-span-1">
        <p>Send car delivery email to this user</p>
      </div>

      <div class="col-span-1">
        <app-toggle id="delivery-email" [toggleFormControl]="contactForm.controls.carDeliveryEmail"></app-toggle>
      </div>

      <div class="col-span-1">
        <p>Send documents email to this user</p>
      </div>

      <div class="col-span-1">
        <app-toggle id="documents-email" [toggleFormControl]="contactForm.controls.documentsEmail"></app-toggle>
      </div>
    </div>
  </app-main-content-layout>

  <div class="flex flex-col gap-6">
    <app-main-content-layout>
      <div class="title">
        <p>Users already set to receive notifications</p>
      </div>

      <div class="grid grid-cols-2 gap-6">
        <app-read-only-input *ngFor="let user of usersContact">{{user}}</app-read-only-input>
      </div>
    </app-main-content-layout>

    <app-main-content-layout class="col-span-1">
      <div class="title">
        <p> Contact Info </p>
      </div>
      <app-main-content-row-layout>
        <div>
          <p>Status</p>
        </div>

        <div class="secondContent text-end">
          <app-toggle id="status" [toggleDisabled]="createMode" [toggleFormControl]="statusControl"
            [toggleLabel]="statusControl.value ? 'Active' : 'Inactive'" [toggleDisabled]="true"></app-toggle>
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Date created</p>

        <div *ngIf="dateCreated; else noDate" class="secondContent text-end">
          {{dateCreated | date:'dd/MM/YYYY'}}
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Last updated</p>

        <div *ngIf="dateUpdated; else noDate" class="secondContent text-end">
          {{dateUpdated | date:'dd/MM/YYYY'}}
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout class="border-b border-white-500 pb-6">
        <p>User last update</p>

        <app-read-only-input class="secondContent">
          username
        </app-read-only-input>
      </app-main-content-row-layout>
    </app-main-content-layout>
  </div>
</div>

<ng-template #noDate>
  <app-read-only-input>
    <p>dd/mm/yy</p>
  </app-read-only-input>
</ng-template>
