import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right-side-content-layout',
  template: `
    <div class="bg-white rounded-2xl py-12">
      <ng-content></ng-content>
    </div>
  `,
})

export class RightSideContentLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
