<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9">
    <div class="page-title">
      <p>Cars on list import</p>
    </div>

    <div class="flex justify-between">
      <app-btn-toggle [options]="routes" [selectedOption]="page" (changeEmitter)="changePage($event)"></app-btn-toggle>

      <primary-btn id="save-settings" btnText="Save" (clickEmitter)="saveSettings()"
        *ngIf="page === 'settings';else reviewBtn"></primary-btn>

      <ng-template #reviewBtn>
        <div class="flex gap-6">
          <primary-btn id="modify-selected" btnText="Modify selected" [blueBtn]="true"
            (clickEmitter)="openModifyCarsModal()"></primary-btn>

          <primary-btn id="upload-cars" btnText="Upload cars" [disableBtn]="checkCars()"
            (clickEmitter)="uploadCars()"></primary-btn>
        </div>
      </ng-template>
    </div>

    <div [ngSwitch]="page">
      <app-car-on-list-import-settings #settings *ngSwitchCase="'settings'"
        (saveEmitter)="changePage('review')"></app-car-on-list-import-settings>

      <app-car-on-list-import-review #review *ngSwitchCase="'review'"></app-car-on-list-import-review>
    </div>
  </div>
</app-page-layout>
