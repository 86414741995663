<div class="twoCols">
  <div class="flex flex-col gap-6">
    <app-main-content-layout>
      <div class="flex gap-6">
        <div class="w-60 h-full flex">
          <img class="" [src]="offer!.carDetails.images[0]" alt="">
        </div>

        <div class="flex flex-col gap-6">
          <p class="offer-status">{{offer!.carDetails.make}} {{offer!.carDetails.model}}, {{offer!.carDetails.variant}}
            <span class="ml-6 capitalize " [ngClass]="offer!.status">{{offer!.status}}</span>
          </p>

          <div class="flex flex-wrap gap-3">
            <div class="info-box">
              {{offer!.carDetails.manufactureYear}}
            </div>

            <div class="info-box">
              {{offer!.carDetails.mileage | number}}km
            </div>

            <div class="info-box">
              {{offer!.carDetails.fuelType}}
            </div>

            <div class="info-box">
              {{offer!.carDetails.gearbox}}
            </div>
          </div>
        </div>
      </div>
    </app-main-content-layout>

    <app-main-content-layout>
      <div class="title">
        <p>Imported data</p>
      </div>

      <p class="text-white-600">Technical data</p>

      <div class="flex flex-wrap gap-6">
        <div class="info-box">
          <span>Make</span> {{offer!.carDetails.make}}
        </div>

        <div class="info-box">
          <span>Model</span> {{offer!.carDetails.model}}
        </div>

        <div class="info-box">
          <span>Variant</span> {{offer!.carDetails.variant}}
        </div>

        <div class="info-box">
          <span>Trim</span> {{offer!.carDetails.trim}}
        </div>

        <div class="info-box">
          <span>Fueltype</span> {{offer!.carDetails.fuelType}}
        </div>

        <div class="info-box">
          <span>Gearbox</span> {{offer!.carDetails.gearbox}}
        </div>
      </div>

      <p class="text-white-600">Specs data</p>

      <div class="flex flex-wrap gap-6">
        <div class="info-box">
          <span>Engine power</span> {{offer!.carDetails.enginePower}}
        </div>

        <div class="info-box">
          <span>Engine volume</span> {{offer!.carDetails.engineVolume}}
        </div>

        <div class="info-box">
          <span>CO2 NEDC</span> {{offer!.carDetails.CO2NEDC}}
        </div>

        <div class="info-box">
          <span>CO2 WLTP</span> {{offer!.carDetails.CO2WLTP}}
        </div>

        <div class="info-box">
          <span>Chassis</span> {{offer!.carDetails.bodyType}}
        </div>

        <div class="info-box">
          <span>Driving wheels</span> {{offer!.carDetails.drivingWheels}}
        </div>
      </div>

      <p class="text-white-600">Registration & idetification</p>

      <div class="flex flex-wrap gap-6">
        <div class="info-box">
          <span>First registration</span> {{offer!.carDetails.firstRegDate | date : 'dd-MM-yyyy'}}
        </div>

        <div class="info-box">
          <span>Manufacture year</span> {{offer!.carDetails.manufactureYear}}
        </div>

        <div class="info-box">
          <span>Location</span> {{offer!.carDetails.country.name}}
        </div>

        <div class="info-box">
          <span>Registration number</span> {{offer!.carDetails.regNo}}
        </div>

        <div class="info-box">
          <span>VIN</span> {{offer!.carDetails.vin}}
        </div>

        <div class="info-box">
          <span>Doors</span> {{offer!.carDetails.doors}}
        </div>

        <div class="info-box">
          <span>Color</span> {{offer!.carDetails.color}}
        </div>

        <div class="info-box">
          <span>Seats</span> {{offer!.carDetails.seats}}
        </div>
      </div>

      <p class="text-white-600">Vehicle photos</p>

      <div class="flex flex-wrap gap-6">
        <div class="w-40 h-40 flex" *ngFor="let photo of offer!.carDetails.images">
          <img class="object-contain" [src]="photo" alt="">
        </div>
      </div>
    </app-main-content-layout>
  </div>

  <app-main-content-layout>
    <div class="flex gap-9">
      <div>
        <p class="text-sm text-white-600">Current offer price</p>

        <p class="text-blue-500 text-2xl font-bold mt-1">€{{offer!.currentPrice | number}} <span
            class="text-sm text-white-600 font-normal">excl.
            VAT</span></p>
      </div>

      <div>
        <p class="text-sm text-white-600">Initial price</p>

        <p class="text-green-200 text-2xl font-bold mt-1">€{{offer!.initialPrice | number}} <span
            class="text-sm text-white-600 font-normal">excl.
            VAT</span></p>
      </div>
    </div>

    <div class="pt-6 border-t border-white-500 flex justify-end gap-6"
      *ngIf="offer!.status === offerStatus.OfferPending || (offer!.status === offerStatus.CounterOffer && offer!.lastPriceBySeller)">
      <primary-btn id="accept-offer" (clickEmitter)="acceptOffer()">Accept offer</primary-btn>

      <app-blue-btn id="counter-offer" (clickEmitter)="openCounterOfferModal()">Counter offer</app-blue-btn>

      <secondary-btn id="refuse-offer" (clickEmitter)="refuseOffer()">Refuse offer</secondary-btn>
    </div>

    <div class="twoCols">
      <p class="col-span-2 text-white-600 text-sm">Offer history</p>

      <div *ngFor="let log of offer!.logs" class="flex text-sm flex-wrap gap-4 p-3 justify-between rounded"
        [ngClass]="log.user.role === 'admin' ? 'bg-white-100' : 'bg-white-300'">
        <p class="text-white-600">{{log.user.name}}</p>

        <p class="text-white-600">{{log.user.role}}</p>

        <p class="text-white-600">{{log.createdAt | date : 'dd-MM-yyyy'}}</p>

        <p class="capitalize">{{log.newStatus}}</p>

        <p *ngIf="log.newPrice">€{{log.newPrice | number}}</p>
      </div>
    </div>

  </app-main-content-layout>
</div>
