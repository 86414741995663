import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, firstValueFrom, zip } from 'rxjs';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { AddCarGroupModalComponent } from './add-car-group-modal/add-car-group-modal.component';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { CarGroupDetails } from 'src/app/core/models/car.model';

@Component({
  selector: 'app-car-groups',
  templateUrl: './car-groups.component.html',
  styleUrls: ['./car-groups.component.scss']
})

export class CarGroupsComponent implements OnInit {
  loading = new BehaviorSubject(true);

  headers: TableHeaderMap[] = [
    {
      value: 'name',
      tableView: 'name'
    },
    {
      value: 'nrOfCars',
      tableView: 'no Of Cars'
    },
    {
      value: 'createdAt',
      tableView: 'created At'
    },
  ]

  displayedColumns: string[] = ['selectAll'].concat(this.headers.map(h => h.value));
  groups: CarGroupDetails[] = [];

  @ViewChild('groupsTable') groupsTable: AppTableComponent | undefined;

  constructor(private carService: CarService,
    private dialog: MatDialog,
    private settingsService: SettingsService,
    private spinnerService: SpinnerHandlerService,
    private snackbarService: SnackbarService) { }

  ngOnInit() {
    this.loadCarGroups(undefined, true);
  }

  loadCarGroups(snackbar?: string, onInit?: boolean) {
    this.carService.getCarGroupsDetails().subscribe(resp => {
      this.groups = resp.map(r => ({ ...r, isSelected: false }));

      this.loading.next(false);

      this.spinnerService.showProgressBar.next(false);

      if (snackbar) this.snackbarService.positiveSentiment(snackbar);

      if (!onInit) {
        this.groupsTable?.updateTable(this.groups);
      }
    })
  }

  openAddCarGroupModal() {
    const dialogRef = this.dialog.open(
      AddCarGroupModalComponent, {
      width: '800px',
      autoFocus: false,
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.spinnerService.showProgressBar.next(true);

        this.settingsService.createCarGroup(resp).subscribe(value => {
          this.loadCarGroups(resp);
        })
      }
    })
  }

  removeSelected() {
    let groupsToBeDelete = this.groupsTable!.dataSource.data.filter(r => r.isSelected);

    if (groupsToBeDelete.length === 0) {
      this.snackbarService.negativeSentiment('No group selected');
      return;
    }

    this.spinnerService.showProgressBar.next(true);

    const deletePromise: Promise<string>[] = [];

    groupsToBeDelete.forEach((r: { id: string; }) => {
      deletePromise.push(firstValueFrom(this.settingsService.deleteCarGroup(r.id)));
    })

    zip(deletePromise).subscribe((t) => {
      this.loadCarGroups("Groups deleted!")
    });
  }
}
