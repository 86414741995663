import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

interface AddCountryModalData {
  countries: DropdownOption[]
}

@Component({
  selector: 'app-add-country-modal',
  templateUrl: './add-country-modal.component.html',
  styleUrls: ['./add-country-modal.component.scss']
})
export class AddCountryModalComponent implements OnInit {
  countryControl = new FormControl();
  countries = this.data.countries;

  constructor(public dialogRef: MatDialogRef<AddCountryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddCountryModalData,
    private snackbar: SnackbarService) { }

  ngOnInit() { }

  confirm() {
    if (!this.countryControl.value) {
      this.snackbar.negativeSentiment('Please select a country');

      return;
    }

    this.dialogRef.close(this.countryControl.value);
  }
}
