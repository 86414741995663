import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CarBusinessCase, CarBusinesscaseRequestBody, CarDetails } from 'src/app/core/models/car.model';
import { SellerOffer } from 'src/app/core/models/offer.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-seller-offer-businesscase',
  templateUrl: './seller-offer-businesscase.component.html',
  styleUrls: ['./seller-offer-businesscase.component.scss']
})
export class SellerOfferBusinesscaseComponent implements OnInit {
  loading = new BehaviorSubject(true);

  rows: CarBusinessCase[] = [];

  headersMap: TableHeaderMap[] = [
    {
      value: 'spotCountry',
      tableView: 'Country'
    },
    {
      value: 'spotPrice',
      tableView: 'market value'
    },
    {
      value: 'salesFactor',
      tableView: 'sales Factor'
    },
    {
      value: 'carsSold30Days',
      tableView: 'cars Sold 30 Days'
    },
    {
      value: 'carsOnline',
      tableView: 'cars Online'
    }
  ]

  displayedColumns = this.headersMap.map(h => h.value);

  @Input() offer: SellerOffer | undefined;
  car: Partial<CarDetails> | undefined;
  carPrice = 0;
  firstReg = new Date();

  bc: CarBusinessCase[] = [];

  constructor(private coreService: CoreService,
    private carService: CarService,
    private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.car = {
      ...this.offer!.carDetails,
      idAdac: parseInt(this.offer!.carDetails.idAdac)
    };

    this.carPrice = this.offer!.currentPrice;
    this.firstReg = this.offer!.carDetails.firstRegDate;

    let body: CarBusinesscaseRequestBody = {
      countries: this.coreService.countries.map(c => c.iso),
      make: this.car.make!,
      model: this.car.model!,
      manufactureYear: this.car.manufactureYear!,
      fuelType: this.car.fuelType!,
      gearbox: this.car.gearbox!,
      bodyType: this.car.bodyType!,
      variant: this.car.variant!,
      mileage: this.car.mileage!,
      enginePower: this.car.enginePower!,
      firstReg: this.utilsService.formatDate(new Date(this.firstReg)),
      accessories: [],
      carMainInfoId: ''
    };

    this.carService.getCarsBusinessCase(body).subscribe({
      next: resp => {
        this.bc = resp;

        this.loading.next(false);
      },
      error: err => {
        this.loading.next(false);
      }
    });
  }

}
