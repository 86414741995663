<div class="flex flex-col gap-9 h-max-[80vh] h-fit p-6 overflow-y-scroll">
  <p class="text-2xl">Publish seller offer car</p>

  <app-main-content-row-layout>
    <p>Min selling price</p>

    <app-input-number class="secondContent" [control]="minSellingPriceControl"
      placeholder="Insert min selling price"></app-input-number>
  </app-main-content-row-layout>

  <p class="text-sm">Create journey</p>

  <div class="grid grid-cols-2 gap-6 items-center">
    <app-dropdown [options]="groups" [control]="groupControl" placeholder="Select group" label="Group"></app-dropdown>

    <app-input-number [control]="priceControl" placeholder="Insert selling price"
      label="Selling price"></app-input-number>

    <app-datepicker class="mt-1" [control]="dateFromControl" label="Date from" [min]="today"
      [max]="dateToControl.value"></app-datepicker>

    <app-dropdown label="Start hour (local time)" placeholder="Select hour" [options]="hours"
      [control]="startTime"></app-dropdown>

    <app-datepicker class="mt-1" [control]="dateToControl" label="Date to"
      [min]="dateFromControl.value"></app-datepicker>

    <app-dropdown label="End hour (local time)" placeholder="Select hour" [options]="hours"
      [control]="endTime"></app-dropdown>

    <p *ngIf="showError" class="text-sm text-red">Complete all fields</p>
  </div>

  <app-blue-btn class="w-fit self-end" id="create-car-modal" (clickEmitter)="publishCar()">Create car</app-blue-btn>
</div>
