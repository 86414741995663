import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ViesStatus } from "src/app/shared/countries-availability-modal/countries-availability-modal.component";
import { environment } from "src/environments/environment";
import { Buyer, BuyerResponse, BuyerSearchBody, BuyerSearchResp, BuyerStore, BuyerStoreContact, CreateBuyerContactBody, CreateBuyerObjectClass, CreateBuyerStoreBody, CreateBuyerUserBody, CreateFullBuyerResponse, EditBuyerBody, EditBuyerDeliveriesBody, EditBuyerStoreBody, EditBuyerUserBody } from "../models/buyer.model";
import { User } from "../models/user.model";

@Injectable({
  providedIn: 'root'
})

export class BuyerService {
  constructor(private http: HttpClient) { }

  public createBuyer(buyer: CreateBuyerObjectClass): Observable<CreateFullBuyerResponse> {
    return this.http.post<CreateFullBuyerResponse>(`${environment.backendApiUrl}full-b2b-buyer-create`, buyer);
  }

  public createBuyerStore(body: CreateBuyerStoreBody): Observable<BuyerStore> {
    return this.http.post<BuyerStore>(`${environment.backendApiUrl}b2b-buyer-store-create`, body);
  }

  public createBuyerUser(body: CreateBuyerUserBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}b2b-buyer-user-create`, body);
  }

  public createBuyerContact(body: CreateBuyerContactBody): Observable<CreateBuyerContactBody> {
    return this.http.post<CreateBuyerContactBody>(`${environment.backendApiUrl}b2b-buyer-store-contact-create`, body);
  }

  public getVATStatus(): Observable<ViesStatus[]> {
    return this.http.get<ViesStatus[]>(`${environment.backendApiUrl}vies-countries-status`);
  }

  public editBuyer(buyer: EditBuyerBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}b2b-buyer-edit`, buyer);
  }

  public editBuyerStore(body: EditBuyerStoreBody): Observable<BuyerStore[]> {
    return this.http.post<BuyerStore[]>(`${environment.backendApiUrl}b2b-buyer-store-edit`, body);
  }

  public editBuyerUser(body: EditBuyerUserBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}b2b-buyer-user-edit`, body);
  }

  public editBuyerDeliveries(body: EditBuyerDeliveriesBody): Observable<any> {
    return this.http.post<BuyerStoreContact>(`${environment.backendApiUrl}b2b-buyer-store-delivery-edit`, body);
  }

  public getBuyer(buyerId: string): Observable<BuyerResponse> {
    return this.http.get<BuyerResponse>(`${environment.backendApiUrl}company-info/${buyerId}`);
  }

  public searchBuyers(body: BuyerSearchBody): Observable<BuyerSearchResp> {
    return this.http.post<BuyerSearchResp>(`${environment.backendApiUrl}search-buyer-companies`, body);
  }

  public getUserBuyers(): Observable<Buyer[]> {
    return this.http.get<Buyer[]>(`${environment.backendApiUrl}search-buyer-companies`);
  }

  public getBuyerClients(buyerId: string): Observable<User[]> {
    return this.http.post<User[]>(`${environment.backendApiUrl}search-buyer-company-clients`, { buyerId: buyerId });
  }

  public getLoggedKAMBuyerUsers(buyerId: string): Observable<User[]> {
    return this.http.post<User[]>(`${environment.backendApiUrl}get-logged-kam-buyer-company-users`, { buyerId: buyerId });
  }

  public getLoggedKAMBuyerStores(buyerId: string, clientId: string): Observable<User[]> {
    return this.http.post<User[]>(`${environment.backendApiUrl}get-logged-kam-buyer-stores-by-users`, { buyerId: buyerId, clientId: clientId });
  }

  public getBuyersAndUsers(): Observable<BuyerResponse[]> {
    return this.http.get<BuyerResponse[]>(`${environment.backendApiUrl}get-companies-and-users`);
  }
}
