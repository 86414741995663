import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BuyerResponse } from 'src/app/core/models/buyer.model';
import { BuyerUser, User } from 'src/app/core/models/user.model';
import { OffersService } from 'src/app/core/services/offers.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-create-offer-settings',
  templateUrl: './create-offer-settings.component.html',
  styleUrls: ['./create-offer-settings.component.scss']
})
export class CreateOfferSettingsComponent implements OnInit {
  tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))

  selectedKAM: User | undefined;
  selectedBuyer: BuyerResponse | undefined;
  selectedUser: BuyerUser | undefined;

  orderSettingsControls = this.fb.group({
    expiresOn: new FormControl<any>(null, Validators.required),
    buyer: ['', Validators.required],
    client: ['', Validators.required]
  });

  @Input() buyers: BuyerResponse[] = [];
  usersDropdown: DropdownOption[] = [];
  buyersDropdown: DropdownOption[] = [];

  constructor(private fb: FormBuilder,
    private offerService: OffersService,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.buyersDropdown = this.buyers.map(b => ({ value: b.companyId, viewValue: b.buyerCompanyDetails.name! }));

    if (this.offerService.offerSettings) {
      this.orderSettingsControls.patchValue({
        expiresOn: this.offerService.offerSettings.expiresOn,
        buyer: this.offerService.offerSettings.buyerId,
        client: this.offerService.offerSettings.clientId
      });

      this.selectBuyer(this.offerService.offerSettings.buyerId);
      this.selectUser(this.offerService.offerSettings.clientId);
    }
  }

  selectBuyer(buyerId: string) {
    this.selectedBuyer = this.buyers.find(b => b.companyId === buyerId);

    this.usersDropdown = this.selectedBuyer!.users!.map(u => ({ value: u.id!, viewValue: u.username! }));
  }

  selectUser(userId: string) {
    this.selectedUser = this.selectedBuyer?.users?.find(u => u.id === userId);
  }

  save(): boolean {
    if (this.orderSettingsControls.invalid) {
      this.snackbar.negativeSentiment('Complete all required fields!');
      return false;
    }

    let date = this.orderSettingsControls.controls.expiresOn.value;

    this.offerService.offerSettings = {
      buyerId: this.orderSettingsControls.controls.buyer.value!,
      clientId: this.orderSettingsControls.controls.client.value!,
      expiresOn: typeof (date) === 'string' ? date : date.format("yyyy-MM-DD")
    }

    return true;
  }
}
