import { CarDetails } from "./car.model"

export interface OfferSettings {
  expiresOn: Date,
  clientId: string,
  buyerId: string,
  cars?: {
    carMainInfoId: string,
    value: number
  }[]
}

export interface Offer {
  buyerId: string,
  buyerLocation: string,
  buyerLocationId: string,
  buyerLocationIso: string,
  buyerName: string,
  c2cConfirmation: string,
  carDetails: CarOfferDetails,
  clientConfirmation: string,
  clientData: {
    clientId: string,
    clientUsername: string,
  },
  createdAt: string,
  createdBy: string,
  expiresOn: string,
  offerId: string,
  offerStatus: string,
  offerType: string,
  sellerId: string,
  sellerName: string,
  proposedPrices: {
    username: string,
    offeredBy: string,
    value: number,
    addedOn: string,
    date?: Date
  }[],
  logs: string[],
  latestOffer?: number
}

export interface CarOfferDetails extends CarDetails {
  shopPrice: number
}

export enum OfferStatus {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Expired = 'Expired',
  Canceled = 'Canceled'
}

export enum SellerOfferStatus {
  OfferPending = 'pending',
  OfferRejected = 'rejected',
  OfferAccepted = 'accepted',
  SoftDeleted = 'soft_deleted',
  CounterOffer = 'countered',
  Published = 'published'
}

export interface SellerOffer {
  isSelected: boolean;
  user: {
    id: string,
    name: string
  },
  seller: {
    id: string,
    name: string
  },
  carDetails: {
    enginePower: number,
    consumption: number,
    CO2NEDC: number,
    mileage: number,
    color: string,
    engineVolume: number,
    CO2WLTP: number,
    images: string[],
    make: string,
    carId: string,
    accessories: {
      standardEquipment: any,
      optionalEquipment: any,
      packEquipment: any
    },
    fuelType: string,
    firstRegDate: Date,
    bodyType: string,
    drivingWheels: string,
    trim: string,
    gearbox: string,
    vin: string,
    idAdac: string,
    seats: number,
    variant: string,
    regNo: string,
    model: string,
    manufactureYear: number,
    doors: number,
    country: {
      id: string,
      iso: string,
      name: string,
    }
    vinCheckId: string
  },
  logs: {
    user: {
      id: string,
      name: string,
      role: string
    },
    oldPrice: number,
    createdAt: Date,
    newStatus: string,
    oldStatus: string,
    newPrice: number
  }[],
  id: string,
  vat: boolean,
  lastPriceBySeller: boolean,
  vatType: string,
  createdAt: Date | string,
  initialPrice: number,
  status: string,
  currentPrice: number,
  modifiedAt: Date,
  damages: number
}

export interface PublishSellerOfferCarBody {
  carGroupJourneys:
  {
    dateFrom: string,
    dateTo: string,
    group: string,
    sellingPrice: number
  }[],
  minSellingPrice: number
}

export interface CounterOfferBody {
  offerId: string,
  value: number,
  expiresOn?: string
}

export class SearchCarsWOOffersBody {
  page: number;
  itemsPerPage: number;
  clientId: string;
  buyerId: string;
  makeModel: {
    make: string,
    model: string
  }[];
  regNo: string | undefined;
  vin: string | undefined;

  constructor(
    page: number,
    itemsPerPage: number,
    clientId: string,
    buyerId: string,
    regNo: string | undefined,
    vin: string | undefined,
    makeModel: any[]
  ) {
    this.page = page;
    this.itemsPerPage = itemsPerPage;
    this.clientId = clientId;
    this.buyerId = buyerId;
    this.regNo = regNo ? regNo : undefined;
    this.vin = vin ? vin : undefined;
    this.makeModel = makeModel;
  }
}

export interface SearchCarWithoutOffer extends CarDetails {
  sellerDetails: {
    id: string,
    name: string,
    b2BReady: boolean,
    vat: string,
    vatStatus: boolean,
    address: string,
    city: string,
    country: {
      id: string,
      name: string,
    }
  },
  carGroupDetails: {
    id: string,
    name: string,
  },
  sourceDetails: {
    id: string,
    name: string,
  },
  carPrices: {
    buyingPrice: number,
    minSellingPrice: number,
    sellingPrice: number,
    shopPrice: number,
  },
  locationDetails: {
    id: string,
    name: string,
    iso: string,
  },
  vatType: string,
  offeredPrice: number,
}

export interface SearchCarWithoutOfferResp {
  cars: SearchCarWithoutOffer[],
  nrOfCars: number
}

export interface SearchOffersBody {
  page: number,
  itemsPerPage: number,
  seller?: string,
  country?: string,
  status?: string
}

export interface SearchOfferResp {
  nrOfOffers: number,
  offers: Offer[]
}
