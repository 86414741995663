import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderTeammate } from 'src/app/core/models/order.model';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

export interface OrderProcessProfitAddteammateModalData {
  teammates: OrderTeammate[],
  possibleTeammates: OrderTeammate[]
}

@Component({
  selector: 'app-order-process-profit-addteammate-modal',
  templateUrl: './order-process-profit-addteammate-modal.component.html',
  styleUrls: ['./order-process-profit-addteammate-modal.component.scss']
})
export class OrderProcessProfitAddteammateModalComponent implements OnInit {
  teammates: DropdownOption[] = [];

  teammateControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<OrderProcessProfitAddteammateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderProcessProfitAddteammateModalData,) { }

  ngOnInit(): void {
    this.teammates = this.data.possibleTeammates.filter(t => !this.data.teammates.find(tm => tm.userId === t.userId)).map(t => ({ value: t.userId, viewValue: t.username }));

  }

  addTeammate() {
    this.dialogRef.close(this.data.possibleTeammates.find(t => t.userId === this.teammateControl.value));
  }
}
