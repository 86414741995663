import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderCar, UpdateCarPriceSettingsBody } from 'src/app/core/models/order.model';
import { OrdersService } from 'src/app/core/services/orders.service';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderProcessCarPricesModalComponent } from './order-process-car-prices-modal/order-process-car-prices-modal.component';
import { BehaviorSubject } from 'rxjs';
import { InfoService } from 'src/app/core/services/infos.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { OrderStatus } from '../../../orders-view/orders-view.component';

@Component({
  selector: 'app-order-process-car-prices',
  templateUrl: './order-process-car-prices.component.html',
  styleUrls: ['./order-process-car-prices.component.scss']
})
export class OrderProcessCarPricesComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  headers: TableHeaderMap[] = [
    {
      value: 'carConfig.sellerName',
      tableView: 'seller'
    },
    {
      value: 'carConfig.make',
      tableView: 'make'
    },
    {
      value: 'carConfig.model',
      tableView: 'model'
    },
    {
      value: 'carConfig.vin',
      tableView: 'vin'
    },
    {
      value: 'carConfig.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carPricing.actualBuyingPrice',
      tableView: 'actual Buying Price'
    },
    {
      value: 'carPricing.currency',
      tableView: 'buying price currency'
    },
    {
      value: 'carPricing.actualBuyingPriceEuro',
      tableView: 'actual Buying Price euro'
    },
    {
      value: 'carPricing.offerDetails.offeredValue',
      tableView: 'offered Price'
    },
    {
      value: 'carPricing.buyingPrice',
      tableView: 'Buying price'
    },
    {
      value: 'carPricing.minSellingPrice',
      tableView: 'minimum selling price'
    },
    {
      value: 'carPricing.shopPrice',
      tableView: 'Online Selling price'
    },
    {
      value: 'carPricing.invoiceSellingPrice',
      tableView: 'invoice selling price'
    },
    {
      value: 'carPricing.actualProfit',
      tableView: 'profit'
    },
    {
      value: 'documentsDetails.documents.coc.required',
      tableView: 'requires COC'
    },
    {
      value: 'documentsDetails.documents.coc.cocValue',
      tableView: 'coc value'
    },
    {
      value: 'carConfig.damages',
      tableView: 'Initial declared damages'
    },
    {
      value: 'carConfig.actualDamages',
      tableView: 'final damage'
    },
    {
      value: 'carPricing.transportationActualFee',
      tableView: 'Transportation fee'
    },
    {
      value: 'photoEditing.photoEditActualValue',
      tableView: 'photo edit fee'
    }
  ]

  displayedColumns = this.headers.map(h => h.value).concat(['edit']);

  cars: OrderCar[] = this.orderFlowService.carList;

  finishedOrder = this.orderFlowService.orderStatus === OrderStatus.FINISHED;

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;

  constructor(private orderService: OrdersService,
    private orderFlowService: OrdersFlowSyncService,
    private dialog: MatDialog,
    private infoService: InfoService,
    private spinner: SpinnerHandlerService,
    private snackbar: SnackbarService) { }

  ngOnInit() {
    if (this.orderFlowService.currencies.length === 0) {
      this.infoService.getCurrencyExchangeRatesToEuro().subscribe(currencies => {
        this.orderFlowService.currencies = Object.keys(currencies).map(c => ({ currency: c, rate: currencies[c] }));
        this.loading.next(false);
      });
    } else {
      this.loading.next(false);
    }
  }

  openModal(car: OrderCar) {
    const dialogRef = this.dialog.open(
      OrderProcessCarPricesModalComponent, {
      width: '1200px',
      autoFocus: false,
      disableClose: !this.finishedOrder,
      data: {
        car: JSON.parse(JSON.stringify(car)),
        currencies: this.orderFlowService.currencies,
        transportC2C: this.orderFlowService.transportByCars2click.value,
        finishedOrder: this.finishedOrder
      }
    });

    dialogRef.afterClosed().subscribe(modalResp => {
      if (modalResp) {
        this.spinner.showProgressBar.next(true);

        let body: UpdateCarPriceSettingsBody = {
          orderId: this.orderFlowService.order?.orderId!,
          carMainInfoId: car.carMainInfoId!,
          photoEditActualValue: modalResp.photoEditActualValue,
          actualBuyingPrice: modalResp.actualBuyingPrice,
          actualBuyingPriceEuro: modalResp.actualBuyingPriceEuro,
          invoiceSellingPrice: modalResp.invoiceSellingPrice,
          actualDamages: modalResp.actualDamages,
          currency: modalResp.currency,
          cocValue: modalResp.cocValue,
          exchangeRate: modalResp.rate,
          cocBySeller: modalResp.cocBySeller,
          cocFrom: modalResp.cocFrom,
          notes: modalResp.notes
        };

        this.orderService.updateCarPriceSettings(body).subscribe({
          next: async value => {
            this.orderFlowService.loadProfits(value);

            this.carsTable?.updateTable(this.orderFlowService.carList);

            this.spinner.showProgressBar.next(false);
            this.snackbar.positiveSentiment('Car price settings saved!');
          },
          error: err => {
            this.spinner.showProgressBar.next(false);
            this.snackbar.negativeSentiment(err.error.error);
          }
        })
      }
    })
  }
}
