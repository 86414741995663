<div>
  <div>
    <p class="text-2xl">Check country availability</p>
  </div>

  <div class="my-9">
    <p>Some general text regarding VAT regulations in below shown countries. May also work general info and this
      paragraph may work on max. 8-10 lines of text. Also we can integrate here reasons why service may be unavailable.
    </p>
  </div>
  <div class="overflow-scroll max-h-[50vh] scroll-x overflow-x-hidden">
    <div class="grid grid-cols-3 gap-4 text-sm text-white-600 py-3 border-b broder-white-500">
      <div class="col-span-1">Country list</div>
      <div class="col-span-1">VAT example</div>
      <div class="col-span-1">Status</div>
    </div>
    <div class="grid grid-cols-3 gap-4 py-3 border-b border-white-500" *ngFor="let status of statuses"
      [ngClass]="{'disabled-text' : status.status != 0}">
      <div class="col-span-1">{{status.countryName}}</div>
      <div class="col-span-1">{{status.vatExample}}</div>
      <div class="col-span-1">{{status.interpretedStatus}}</div>
    </div>
  </div>
</div>
