import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss']
})
export class AppNavbarComponent implements OnInit {
  isAdmin = this.coreService.isAdmin;

  constructor(private router: Router,
    private coreService: CoreService,
    private authService: AuthService) { }

  ngOnInit() {
  }

  goToBuyers() {
    this.router.navigate(['buyers']);
  }

  goToCreateBuyer() {
    this.router.navigate(['buyer/company']);
  }

  goToCars() {
    this.router.navigate(['online-car-upload']);
  }

  goToCarsOnListImport() {
    this.router.navigate(['cars-on-list-import/settings']);
  }

  goToOnlineListUpload() {
    this.router.navigate(['online-list-upload']);
  }

  goToCarsOnList() {
    this.router.navigate(['cars-on-list']);
  }

  goToInternalShop() {
    this.router.navigate(['shop']);
  }

  goToSettings() {
    this.router.navigate(['settings/transport-matrix']);
  }

  goToCreateOnlineSeller() {
    this.router.navigate(['seller/online-seller']);
  }

  goToCreateB2BSeller() {
    this.router.navigate(['create-b2b-seller']);
  }

  goToViewSellers() {
    this.router.navigate(['sellers']);
  }

  goToAllCarsList() {
    this.router.navigate(['manage-cars']);
  }

  goToCreateOrder() {
    this.router.navigate(['create-order']);
  }

  goToOrdersKAM() {
    this.router.navigate(['orders']);
  }

  goToOrders() {
    this.router.navigate(['orders']);
  }

  goToCreateOffer() {
    this.router.navigate(['create-offer']);
  }

  goToOffersKAM() {
    this.router.navigate(['view-offers']);
  }

  goToOffers() {
    this.router.navigate(['view-offers']);
  }

  goToSellerOffers() {
    this.router.navigate(['sellers-offers']);
  }

  goToCreateCarRequest() {
    this.router.navigate(['request-car']);
  }

  goToViewCarRequests() {
    this.router.navigate(['car-requests']);
  }

  logout() {
    this.authService.logout();
  }

  goTo(route: string) {
    this.router.navigate([route]);
  }
}
