import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { OrderCar } from 'src/app/core/models/order.model';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-seller-filter-table',
  templateUrl: './seller-filter-table.component.html',
  styleUrls: ['./seller-filter-table.component.scss']
})
export class SellerFilterTableComponent implements OnInit {
  @Input() cars: OrderCar[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() headersMap: TableHeaderMap[] = [];

  sellerFilterControl = new FormControl('');
  sellerFilters: DropdownOption[] = [];
  dataSource = new MatTableDataSource<OrderCar>();

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;
  @Output() openModalEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.cars = this.cars.map((d: OrderCar) => ({
      ...d,
      isSelected: false
    }));

    this.sellerFilters = (this.cars.map(c => c.carConfig.sellerName!).filter((v, i, a) => a.indexOf(v) === i)).map(s => ({ value: s, viewValue: s }));
    this.sellerFilters.unshift({ value: '', viewValue: 'All' });
  }

  ngAfterViewInit() {
    this.carsTable!.dataSource.filterPredicate = (data: OrderCar, filter: string) => !filter || data.carConfig.sellerName == filter;
  }


  filterCars(seller: string) {
    this.carsTable!.dataSource.filter = seller;
  }

  openModal(car: OrderCar) {
    this.openModalEmitter.emit(car);
  }

  updateTable(cars: OrderCar[]) {
    this.carsTable?.updateTable(cars);
  }
}
