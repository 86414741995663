import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { CarOnListImportReviewComponent } from './car-on-list-import-review/car-on-list-import-review.component';
import { CarOnListImportSettingsComponent } from './car-on-list-import-settings/car-on-list-import-settings.component';

@Component({
  selector: 'app-cars-on-list-import',
  templateUrl: './cars-on-list-import.component.html',
  styleUrls: ['./cars-on-list-import.component.scss']
})
export class CarsOnListImportComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  page = 'settings';

  public routes = [
    {
      viewValue: "Settings",
      value: "settings"
    },
    {
      viewValue: "Car review & validation",
      value: "review"
    }
  ];

  @ViewChild('settings') settings: CarOnListImportSettingsComponent | undefined;
  @ViewChild('review') review: CarOnListImportReviewComponent | undefined;

  constructor(private activatedRoute: ActivatedRoute,
    private carSyncService: CarFlowSyncService,
    private carService: CarService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params['page']) {
        let route = this.routes.find(r => r.value === params['page']);

        this.page = route ? route.value : 'settings';
      }
    });

    this.carService.getCarGroups().subscribe(resp => {
      this.carSyncService.carGroups = resp.map(r => { return { viewValue: r.name, value: r.id } });

      this.loading.next(false);
    })
  }

  ngOnDestroy() {
  }

  changePage(page: string) {
    this.page = page;
  }

  saveSettings() {
    this.settings?.uploadCars();
  }

  openModifyCarsModal() {
    this.review?.editMultipleCars();
  }

  checkCars() {
    return true;
  }

  uploadCars() {
    this.review?.uploadCars();
  }
}
