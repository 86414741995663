<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
    <div class="font-bold text-2xl">
      <p>Seller offers</p>
    </div>

    <div class="grid grid-cols-4 2xl:grid-cols-7 gap-6">
      <div class="flex flex-col gap-9">
        <app-dropdown componentId="status" placeholder="Select status" label="Status" [options]="statusFilters"
          [control]="filterGroup.controls.status"></app-dropdown>

        <app-dropdown componentId="make" placeholder="Select make" label="Make" [options]="makes"
          [control]="filterGroup.controls.make" (emitOnChangeSelect)="loadModels($event)"></app-dropdown>

        <app-dropdown componentId="model" placeholder="Select model" label="Model" [options]="models"
          [control]="filterGroup.controls.model"></app-dropdown>

        <app-dropdown componentId="year" placeholder="Select year" label="Year" [options]="years"
          [control]="filterGroup.controls.year"></app-dropdown>

        <app-input componentId="vin" placeholder="Insert VIN" label="VIN"
          [control]="filterGroup.controls.vin"></app-input>

        <app-blue-btn componentId="filter" (clickEmitter)="filterTable()">Filter</app-blue-btn>
      </div>

      <app-table #offersTable class="col-span-3 2xl:col-span-6 offer-table" [displayedColumns]="displayedColumns"
        [headersMap]="headers" [data]="offers" (objectEmitter)="goToOffer($event)" [addCellValueClass]="true">
      </app-table>
    </div>
  </div>

  <ng-template #placeholder>

  </ng-template>
</app-page-layout>
