<div class="twoCols">
  <div class="col-span-2 flex justify-between">
    <div class="flex gap-6">
      <app-dropdown id="stores" label="Store" placeholder="Select store" [options]="stores"
        (emitOnChangeSelect)="selectStore($event)" [control]="storesControl"></app-dropdown>

      <app-dropdown id="contacts" label="Contact" placeholder="Select contact" [options]="contactsDropdown"
        [control]="contactControl" (emitOnChangeSelect)="changeContact($event)">
      </app-dropdown>
    </div>

    <secondary-btn id="edit-mode" *ngIf="isAdmin && !editMode" (clickEmitter)="enterEditMode()">Edit
      contacts</secondary-btn>

    <div class="flex gap-6" *ngIf="editMode">
      <secondary-btn id="save-contact" btnText="Save contact" (clickEmitter)="saveContact()"></secondary-btn>
      <primary-btn id="add-contact" btnText="Add a contact person" (clickEmitter)="addContact()"></primary-btn>
    </div>
  </div>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Contact Details</p>
    </div>

    <app-main-content-row-layout>
      <p>First name*</p>

      <app-input id="firstname" placeholder="Insert first name" class="secondContent"
        [control]="contactDetailsForm.controls.firstName"></app-input>
    </app-main-content-row-layout>


    <app-main-content-row-layout>
      <p>Last name*</p>

      <app-input id="lastname" placeholder="Insert last name" class="secondContent"
        [control]="contactDetailsForm.controls.lastName"></app-input>
    </app-main-content-row-layout>


    <app-main-content-row-layout>
      <div class="flex items-center">
        <p>Email*</p>

        <p class="relative -left-[2.9375rem] top-6 text-sm text-red opacity-0 whitespace-nowrap"
          [ngClass]="{'opacity-100':contactDetailsForm.controls.email.hasError('email')}">Insert valid email</p>
      </div>

      <app-input id="email" placeholder="Insert email" class="secondContent"
        [control]="contactDetailsForm.controls.email"></app-input>
    </app-main-content-row-layout>


    <app-main-content-row-layout>
      <p>Phone*</p>

      <app-input id="phone" placeholder="Insert phone" class="secondContent"
        [control]="contactDetailsForm.controls.phone"></app-input>
    </app-main-content-row-layout>

    <div class="grid grid-cols-2 gap-5 pt-6 border-t border-white-500">
      <div class="col-span-1">
        <p>Send accounting email to this user</p>
      </div>
      <div class="col-span-1">
        <app-toggle id="send-acc-email" [toggleFormControl]="contactDetailsForm.controls.accountingEmail"></app-toggle>
      </div>

      <div class="col-span-1">
        <p>Send pickup email to this user</p>
      </div>
      <div class="col-span-1">
        <app-toggle id="send-pickup-email"
          [toggleFormControl]="contactDetailsForm.controls.carPickupEmail"></app-toggle>
      </div>

      <div class="col-span-1">
        <p>Send documents email to this user</p>
      </div>
      <div class="col-span-1">
        <app-toggle id="send-doc-email" [toggleFormControl]="contactDetailsForm.controls.documentsEmail"></app-toggle>
      </div>
    </div>
  </app-main-content-layout>

  <app-main-content-layout>
    <div class="title">
      <p>Users already set to receive notifications</p>
    </div>

    <div class="grid grid-cols-2 gap-6">
      <app-read-only-input *ngFor="let user of usersContact">{{user}}</app-read-only-input>
    </div>
  </app-main-content-layout>
</div>
