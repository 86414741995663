<div class="w-full rounded p-9 h-min grid grid-cols-2 gap-9" *ngIf="!(loadingPage | async); else spinner">
  <div class="col-span-2 flex justify-end gap-6">
    <secondary-btn id="reorder-photos" (clickEmitter)="reorderPhotos()">Save photos order</secondary-btn>

    <primary-btn id="upload-photos" (clickEmitter)="uploadPhoto()">Upload photos</primary-btn>

    <fa-icon class="mat-menu-button" [matMenuTriggerFor]="moreBtns" [icon]="['fal','ellipsis-vertical']"></fa-icon>

    <mat-menu #moreBtns="matMenu">
      <!-- <button mat-menu-item id="import-db" (click)="importPhoto()">Background removal</button> -->

      <button mat-menu-item id="import-db" (click)="importPhoto()">Import database picture</button>

      <button mat-menu-item id="remove-selected" (click)="removePhotos()">Remove selected</button>
    </mat-menu>
  </div>

  <div class="col-span-1 bg-white rounded p-9 h-full">
    <div class="flex bg-black" *ngIf="selectedPhoto.length > 0;else noPhotos">
      <img [src]="selectedPhoto" class="object-contain h-96 m-auto" alt="">
    </div>
  </div>

  <ng-template #noPhotos>
    <div class="bg-white rounded flex justify-center items-start pt-6">
      <img class="rounded object-none cursor-pointer h-96" src="./assets/background-photos/car-placeholder.png">
    </div>
  </ng-template>

  <div class="col-span-1 flex flex-col gap-6 bg-white rounded p-9 overflow-auto box-content" id="photoContainer">
    <gridster [options]="options" class="h-96 !p-0 bg-white">
      <gridster-item [item]="item" *ngFor="let item of dashboard; let i = index" class="!w-28 !h-28 rounded">
        <div class="car-photos relative w-28 h-28 rounded flex">
          <mat-checkbox [id]="'checkbox-'+i" class="!absolute right-2 top-2" [(ngModel)]="item['isChecked']">
          </mat-checkbox>

          <img [src]="item['originalPhoto']" class="rounded object-cover" alt=""
            (click)="selectedPhoto = item['originalPhoto']">

          <div class="top-0 left-0 absolute text-[0.625rem] py-1 px-[0.625rem] text-red bg-red-100 rounded"
            *ngIf="!item['_id']">
            <p>UNSAVED</p>
          </div>
        </div>
      </gridster-item>
    </gridster>

    <div class="flex flex-wrap gap-6">
      <div id="add-box"
        class="w-28 h-28 border-[3px] border-blue-200 rounded text-4xl text-blue-200 border-dashed flex justify-center items-center cursor-pointer hover:shadow-lg active:scale-95"
        (click)="selectImage.click()">
        <fa-icon class="w-10 h-10 bg-white-400 text-center" [icon]="['fal','plus']"></fa-icon>
      </div>

    </div>

    <input class="hidden" [multiple]="true" #selectImage type="file" [formControl]="uploadControl"
      (change)="handleFileSelect($event)">
  </div>
</div>



<ng-template #spinner>
  <div class="twoCols">
    <app-main-content-placeholder></app-main-content-placeholder>
    <app-main-content-placeholder></app-main-content-placeholder>
  </div>
</ng-template>
