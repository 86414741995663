import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, debounceTime, forkJoin, takeUntil, tap } from 'rxjs';
import { OrderCar } from 'src/app/core/models/order.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

export interface ManuallyAddCarsModalData {
  buyerId: string,
  clientId: string,
  carList: OrderCar[]
}

@Component({
  selector: 'app-manually-add-cars-modal',
  templateUrl: './manually-add-cars-modal.component.html',
  styleUrls: ['./manually-add-cars-modal.component.scss']
})

export class ManuallyAddCarsModalComponent implements OnInit {
  loading = new BehaviorSubject(true);

  private filtersStringEvent: Subject<string> = new Subject();
  public filtersStringEvent$ = this.filtersStringEvent.asObservable();
  public destroyed = new Subject<void>();

  headers: TableHeaderMap[] = [
    {
      value: 'sellerName',
      tableView: 'seller'
    },
    {
      value: 'sellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'regNo',
      tableView: 'Registration number'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'firstReg',
      tableView: 'First registration'
    },
    {
      value: 'offeredPrice',
      tableView: 'offered price'
    },
    {
      value: 'inCart',
      tableView: 'Exists in my cart'
    },
    {
      value: 'color',
      tableView: 'color'
    }
  ]

  displayColumn = ['selectAll'].concat(this.headers.map(h => h.value));

  buyerId = this.data.buyerId;
  clientId = this.data.clientId;

  cars: OrderCar[] = [];
  carList = this.data.carList;

  makes: DropdownOption[] = [];
  models: DropdownOption[] = [];

  makeControl = new FormControl();
  modelControl = new FormControl();
  regNoControl = new FormControl();
  vinControl = new FormControl();
  idControl = new FormControl();

  countries = this.coreService.countries;

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;

  constructor(private carService: CarService,
    private coreService: CoreService,
    public dialogRef: MatDialogRef<ManuallyAddCarsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ManuallyAddCarsModalData,) {
  }

  ngOnInit(): void {
    forkJoin({
      cars: this.carService.searchCarsStaff(this.buyerId, this.clientId, ''),
      makes: this.carService.getCarNomenclatorData('makes')
    }).subscribe(resp => {
      this.makes = [{ value: 'all', viewValue: 'All makes' }].concat(resp.makes.map(m => ({ value: m, viewValue: m })));

      this.cars = resp.cars.map(c => ({
        ...c,
        vatType: c.vatString,
        inCart: this.checkCarInCart(c.carMainInfoId!),
        isSelected: false
      }));

      this.cars = this.cars.filter(c => !this.carList.find(cc => cc.carMainInfoId === c.carMainInfoId));

      this.loading.next(false);
    })

    this.filtersStringEvent$.pipe(debounceTime(1000),
      tap(() => { }),
      takeUntil(this.destroyed)).subscribe(resp => {
        let query = '?';

        query = this.makeControl.value && this.makeControl.value != 'all' ? `${query}make=${this.makeControl.value},` : query;
        query = this.modelControl.value && this.modelControl.value != 'all' ? `${query}model=${this.modelControl.value},` : query;
        query = this.vinControl.value ? `${query}vin=${this.vinControl.value},` : query;
        query = this.regNoControl.value ? `${query}regNo=${this.regNoControl.value},` : query;
        query = this.idControl.value ? `${query}id=${this.idControl.value},` : query;

        query = query.slice(0, -1);

        this.carService.searchCarsStaff(this.buyerId, this.clientId, query).subscribe(resp => {
          resp = resp.filter(c => !this.carList.find(cc => cc.carMainInfoId === c.carMainInfoId));

          this.carsTable?.updateTable(resp.map(c => ({
            ...c,
            vatType: c.vatString,
            inCart: this.checkCarInCart(c.carMainInfoId!)
          })));
        })
      });

    this.vinControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next('');
    });

    this.regNoControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next('');
    });

    this.idControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next('');
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  selectMake(make: string) {
    this.modelControl.reset();
    this.models = [];

    if (make === 'all') {
      this.modelControl.reset();
      this.models = [];
    } else {
      this.carService.getCarNomenclatorData(`models?make=${make}`).subscribe(resp => this.models = [{ value: 'all', viewValue: 'All makes' }].concat(resp.map(m => ({ value: m, viewValue: m }))));
    }

    setTimeout(() => {
      this.filtersStringEvent.next('');
    })
  }

  selectModel(model: string) {
    this.filtersStringEvent.next('');
  }

  resetFilters() {
    this.regNoControl.reset();
    this.vinControl.reset();
    this.idControl.reset();
    this.makeControl.reset();
    this.modelControl.reset();
    this.models = [];
  }

  addCars() {
    let cars = this.carsTable?.dataSource.data.filter(c => c.isSelected);

    this.dialogRef.close(cars);
  }

  checkCarInCart(carMainInfoId: string): boolean {
    return this.data.carList.find(c => c.carMainInfoId === carMainInfoId) ? true : false;
  }
}
