import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';

@Component({
  selector: 'app-view-b2b-seller',
  templateUrl: './view-b2b-seller.component.html',
  styleUrls: ['./view-b2b-seller.component.scss'],
  providers: [B2BSellerSyncService]
})
export class ViewB2bSellerComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  public loadingInfo = this.b2bSyncService.loadingInfo;

  routes = [
    {
      viewValue: "Main company",
      value: "company",
    },
    {
      viewValue: "Stores",
      value: "stores",
    },
    {
      viewValue: "Users",
      value: "users",
    },
    {
      viewValue: "Contacts",
      value: "contacts",
    },
    {
      viewValue: "Pickups",
      value: "restrictions",
    }
  ]

  page = this.routes[0].value;

  constructor(private b2bSyncService: B2BSellerSyncService,
    private activatedRoute: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe(params => {
        if (params['page'] && this.routes.find((r: { value: string; }) => r.value === params['page'])) {
          this.page = this.routes.find((r: { value: string; }) => r.value === params['page'])!.value;
        }

        if (params['sellerId']) {
          this.b2bSyncService.sellerId = params['sellerId'];
        }

        this.b2bSyncService.loadInfo();
      }));

    this.subscriptions.add(
      this.b2bSyncService.currentTab$.subscribe(
        (page) => {
          let route = this.routes.find((r: { value: string; }) => r.value === page);

          if (route) {
            this.page = route.value;

            this.location.replaceState(`seller/${this.page}/${this.b2bSyncService.sellerId}`);
          }
        }
      ));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  navigateTo(page: string) {
    this.b2bSyncService.changeTab(page);
  }
}
