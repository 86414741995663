<div class="flex flex-col gap-6" *ngIf="!(loading | async);else placeholder">
  <div class="flex justify-end gap-6">
    <secondary-btn id="remove-company" btnText="Remove selected companies"
      (clickEmitter)="removeCompany()"></secondary-btn>

    <tertiary-btn id="add-company" btnText="Add company" (clickEmitter)="addCompany()">
    </tertiary-btn>
  </div>

  <div class="overflow-x-auto">
    <app-table (goToEmitter)="editCompany($event)" #companiesTable [data]="companies"
      [displayedColumns]="displayedColumns" [headersMap]="headersMap"></app-table>
  </div>
</div>

<ng-template #placeholder>
  <div class="w-full flex flex-col gap-6">
    <div class="w-full h-9 placeholder-animation"></div>

    <div class="flex flex-col gap-2 col-span-2">
      <div class="bg-white-100 h-8"></div>
      <div class="placeholder-animation h-6" *ngFor="let i of [].constructor(5)"></div>
    </div>
  </div>
</ng-template>
