export interface User {
  tempId?: string,
  username?: string,
  password?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  date_joined?: string,
  clientId?: string,
  address?: string,
  phoneNumber?: string,
  country?: string,
  roles?: string[],
  createdAt?: Date,
  modifiedAt?: Date,
  details?: {
    buyerStores: {
      store: string
    }[],
    sellerCompany: string,
    sellerStores: {
      store: string,
      roles: string[],
      contactRoleDetails: {
        accEmailFlag: boolean,
        carPickupEmailFlag: boolean,
        documentEmailFlag: boolean,
      }
    }[]
  },
  buyerCompany?: string,
  buyerStores?: {
    store: string,
    roles: string[],
    contactRoleDetails?:
    {
      accEmailFlag: boolean,
      carDeliveryEmailFlag: boolean,
      documentEmailFlag: boolean
    }
  }[],
  sellerCompany?: string,
  sellerStores?: {
    store: string,
    roles: string[],
    contactRoleDetails?:
    {
      accEmailFlag: boolean,
      carPickupEmailFlag: boolean,
      documentEmailFlag: boolean
    }
  }[],
  closedSalesGroups?: string[]
}

export class UserClass implements User {
  public constructor(init?: Partial<UserClass>) {
    Object.assign(this, init);
  }
}

export class CreateStaffUserBody {
  roles: string[];
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: string;

  constructor(userForm: any, roles: string[]) {
    this.roles = roles;
    this.firstName = userForm.firstName;
    this.lastName = userForm.lastName;
    this.email = userForm.email;
    this.phoneNumber = userForm.phoneNumber;
    this.country = userForm.country;
  }
}

export interface KAMAllocationRow {
  startDate: Date;
  endDate: Date;
  kamName: string;
  email: string;
}

export interface UserInfo {
  country: {
    country: string,
    id: string
  },
  role: string,
  superAdmin: boolean,
  user: {
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    id: string
  }
}

export interface KAMBuyers {
  kamId: string,
  email: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  buyers:
  {
    id: string,
    name: string
  }[]
}


export enum UserRoles {
  Contact = 'contact',
  User = 'user',
  KAM = 'KAM',
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT'
}

export interface KAMUser {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  username: string,
  role: string
}

export interface KAMSearch {
  kamId: string,
  username: string
}

export interface BuyerUser {
  email: string,
  firstName: string,
  lastName: string,
  username: string,
  phoneNumber: string,
  isActive: boolean,
  details: {
    buyerCompany: string,
    buyerStores: {
      store: string,
      roles: string[],
      contactRoleDetails: {
        accEmailFlag: boolean,
        carDeliveryEmailFlag: boolean,
        documentEmailFlag: boolean,
      }
    }[],
    closedSalesGroups: string[]
  },
  id: string,
  createdAt: number,
  modifiedAt: number,
  countryId: string,
  tempId?: string
}

export class BuyerUserClassDTO {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  phoneNumber: string;
  isActive: boolean;
  buyerStores: {
    store: string,
    roles: string[],
    contactRoleDetails: {
      accEmailFlag: boolean,
      carDeliveryEmailFlag: boolean,
      documentEmailFlag: boolean,
    }
  }[];
  closedSalesGroups: string[];
  countryId: string;
  tempId: string;

  constructor(userForm: any, tempId: string, stores: any) {
    this.email = userForm.email;
    this.firstName = userForm.firstName;
    this.lastName = userForm.lastName;
    this.username = userForm.username;
    this.phoneNumber = userForm.phoneNumber ? userForm.phoneNumber : '';
    this.isActive = true;
    this.countryId = userForm.countryId;
    this.tempId = tempId
    this.closedSalesGroups = userForm.closedSalesGroup ? userForm.closedSalesGroup : [];
    this.buyerStores = stores;
  }
}
