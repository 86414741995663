import { Directive, OnDestroy, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[inputWithComma]',
  providers: [DecimalPipe]
})
export class NumberCommaDirective implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription;

  constructor(private ngControl: NgControl, private decimal: DecimalPipe) {
  }

  ngOnInit() {
    const control = this.ngControl.control;

    this.subscription = control!.valueChanges.pipe(
      map(value => {
        if (value && isNaN(Number(value))) {
          value = value.replace(/[A-Za-z!@#$%^&*(),]/g, '');
        }

        if (typeof (value) === 'string' && value.length === 0) value = 0;

        return parseInt(value);
      })
    ).subscribe(v => {
      if (v === 0) {
        control!.setValue(null, { emitEvent: false });
        control!.setValue(0, { emitEvent: false });
      } else if (v) {
        control!.setValue(v, { emitEvent: false })
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
