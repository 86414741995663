import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, skip, Subscription, take } from 'rxjs';
import { CarDetails } from 'src/app/core/models/car.model';
import { InternalShopService } from 'src/app/core/services/internal-shop.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { AppTableComponent } from 'src/app/shared/app-table/app-table.component';
import { Filter } from '../../internal-shop/internal-shop.component';
import { CarsFilterComponent } from '../cars-filter/cars-filter.component';
import { UpdateCarsModalComponent } from './update-cars-modal/update-cars-modal.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-manage-cars',
  templateUrl: './manage-cars.component.html',
  styleUrls: ['./manage-cars.component.scss'],
  providers: [InternalShopService]
})

export class ManageCarsComponent implements OnInit {
  loading = new BehaviorSubject(true);
  tableLoading = this.internalShopService.loadingCars;

  private subscriptions: Subscription = new Subscription();

  cars: CarDetails[] = [];
  results = 0;

  tableColumns = [
    {
      value: "createdAtString",
      tableView: "created at"
    },
    {
      value: "inactiveFromString",
      tableView: "inactive From"
    },
    {
      value: "carStatus",
      tableView: "status"
    },
    {
      value: "carGroup",
      tableView: "group"
    },
    {
      value: "make",
      tableView: "make"
    },
    {
      value: "model",
      tableView: "model"
    },
    {
      value: "manufactureYear",
      tableView: "year"
    },
    {
      value: "firstReg",
      tableView: "registration date"
    },
    {
      value: "fuelType",
      tableView: "fuel"
    },
    {
      value: "gearbox",
      tableView: "gearbox"
    },
    {
      value: "mileage",
      tableView: "km"
    },
    {
      value: "sellingPrice",
      tableView: "price"
    }
  ];

  displayedColumns = ['selectAll', ...this.tableColumns.map(t => t.value), 'edit'];

  filtersSelected: Filter[] = [];

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;
  @ViewChild('carsFilter') carsFilter: CarsFilterComponent | undefined;

  @ViewChild('paginator') paginator: MatPaginator | undefined;

  constructor(private router: Router,
    private utilsService: UtilsService,
    private internalShopService: InternalShopService,
    private dialog: MatDialog,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.subscriptions.add(this.internalShopService.filtersSelectedEvent$.subscribe(resp => this.filtersSelected = resp));

    let initLoadingObs = this.internalShopService.loading.asObservable();

    this.subscriptions.add(initLoadingObs.subscribe(resp => {
      if (!resp) {
        this.cars = this.formatCarsInfo(this.internalShopService.cars);
        this.results = this.cars.length;

        this.loading.next(false);
      }
    }));

    this.subscriptions.add(this.internalShopService.carSubjectEvent$.subscribe(resp => {
      this.cars = resp.cars;

      this.carsTable ? this.carsTable.updateTable(this.formatCarsInfo(resp.cars)) : 0;

      if (resp.pageReset && this.paginator) this.paginator.pageIndex = 0;

      this.results = resp.nrOfCars;
    }));

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  formatCarsInfo(cars: CarDetails[]): CarDetails[] {
    return cars.map(c => ({
      ...c,
      createdAtString: this.utilsService.formatDate(new Date(c.createdAt)),
      inactiveFromString: c.inactiveFrom,
      isSelected: false
    }));
  }

  removeFilter(filter: Filter) {
    this.carsFilter?.removeFilter(filter);
  }

  goToCar(car: CarDetails) {
    this.router.navigate([`car/car-details/${car.carMainInfoId}`]);
  }

  updateCars() {
    if (!this.carsTable?.dataSource.data.some(c => c.isSelected)) {
      this.snackbar.negativeSentiment('No car selected!');
      return;
    }

    let dialogRef = this.dialog.open(
      UpdateCarsModalComponent, {
      width: '800px',
      autoFocus: false,
      data: {
        cars: this.carsTable?.dataSource.data.filter(c => c.isSelected)
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      this.carsFilter?.reloadCars();
    })
  }

  changePage(event: PageEvent) {
    this.internalShopService.changePage(event.pageIndex);
  }
}
