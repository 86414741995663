<div class="app-input relative">
  <p class="absolute z-10 text-white-300 text-xs -top-5" *ngIf="label">{{label}}</p>
  <mat-form-field class="border border-white-300" [ngClass]="{ 'disabled-input' : control.disabled || disabled}"
    class="bg-white w-full text-blue-100 rounded" appearance="outline">

    <input [id]="id + '-input'" [attr.data-cy]="id + '-input'" autocomplete="off" matInput [placeholder]="placeholder"
      [disabled]="disabled" [formControl]="control" (keypress)="keyPressEmit($event)"
      *ngIf="!isNumberInput;else numberInput">

    <ng-template #numberInput>
      <input [id]="id + '-input'" [attr.data-cy]="id + '-input'" autocomplete="off" matInput [placeholder]="placeholder"
        type="number" [formControl]="control" (keypress)="keyPressEmit($event)" (focusOut)="focusOutEmit()">
    </ng-template>
  </mat-form-field>
</div>
