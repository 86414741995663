<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9">
    <div class="font-bold text-2xl">
      <p>Request cars - View requests</p>
    </div>

    <secondary-btn id="delete-selected" class="self-end">Delete selected</secondary-btn>

    <div class="grid grid-cols-4 gap-6">
      <div class="flex flex-col gap-8">
        <app-dropdown id="year" placeholder="Select year" label="Year"></app-dropdown>
        <app-dropdown id="month" placeholder="Select month" label="Month"></app-dropdown>

        <app-blue-btn id="Fitler">Filter</app-blue-btn>
      </div>

      <app-table [displayedColumns]="displayedColumns" [headersMap]="headers" [data]="requests"
        class="col-span-3"></app-table>
    </div>
  </div>
</app-page-layout>
