<div class="overflow-x-auto">
  <table mat-table matSort [dataSource]="dataSource"
    class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">

    <ng-container matColumnDef="selectAll">
      <th mat-header-cell *matHeaderCellDef>
        <div class="flex gap-3">
          <mat-checkbox [id]="'select-all-'+id+'-checkbox'" [attr.data-cy]="'select-all-'+id+'-checkbox'"
            (change)="selectAll($event)"></mat-checkbox>
          <p>SELECT ALL</p>
        </div>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-checkbox [id]="'select-'+ (i + 1) + '-'+ id +'-checkbox'"
          [attr.data-cy]="'select-'+ (i + 1) + '-'+ id +'-checkbox'" [(ngModel)]="element.isSelected"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="col.value" *ngFor="let col of headersMap">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        {{col.tableView}}
      </th>

      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <ng-container
          *ngTemplateOutlet="cellValue; context: { $implicit: {addCellValueClass:addCellValueClass, addCellColClass:addCellColClass, colName: col.value, value: getValue(element, col), isDateHours: col.isDateHours}}">
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>
        EDIT
      </th>
      <td class="!py-6" mat-cell *matCellDef="let element;let i = index">
        <fa-icon [id]="'edit-' + (i + 1) + '-' + id + '-btn'" [attr.data-cy]="'edit-' + (i + 1) + '-' + id + '-btn'"
          class="cursor-pointer" (click)="goToElement(element)" [icon]="['fal','pen-to-square']"></fa-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td class="!py-2" mat-cell *matCellDef="let element; let i = index">
        <primary-btn [id]="'see-details-' + i + '-' + id" (clickEmitter)="seeDetails(element)">See details</primary-btn>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100"></tr>
    <tr [ngClass]="{'isSelected' : row.isSelected}" class="hover:bg-white-500" mat-row
      *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons *ngIf="!hidePaginator">
  </mat-paginator>
</div>

<ng-template #cellValue let-data>
  <p class="{{data.addCellValueClass ? 'cell-value-' + data.value : ''}}
  {{data.addCellColClass ? 'cell-col-' + data.colName : ''}}">
    {{!isNumber(data.value) ? data.value : (data.value | number)}}
  </p>
</ng-template>
