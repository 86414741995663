<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <div>
    <p class="text-2xl">Add teammate to profit analysis</p>
  </div>

  <div class="flex justify-between">
    <app-dropdown id="teammates" class="w-1/2" [options]="teammates" [control]="teammateControl" label="Teammate" placeholder="Select teammate"></app-dropdown>

    <primary-btn id="add-teammate-modal" (clickEmitter)="addTeammate()">Add teammate</primary-btn>
  </div>
</div>
