<div class="grid grid-cols-3 gap-6">
  <div class="flex flex-col">
    <div class="p-9 bg-blue-700 flex gap-4 items-center rounded-t">
      <fa-icon class="py-3 px-4 rounded bg-white" [icon]="['far','sigma']"></fa-icon>
      <div class="flex flex-col gap-1">
        <p class="font-bold text-lg">€{{order!.profitAnalysis.totalInvoiced| number}}</p>
        <p class="text-sm text-white-800">Invoice amount</p>
      </div>
    </div>

    <div class="bg-white p-9 flex flex-col gap-6 rounded-b">
      <div class="flex justify-between">
        <p class="text-white-800">Number of cars</p>
        <p class="text-blue-500">{{cars.length| number}}</p>
      </div>
      <div class="flex justify-between">
        <p class="text-white-800">Total buying price</p>
        <p class="text-blue-500">€{{getTotalBuyingPrice() | number}}</p>
      </div>
      <div class="flex justify-between">
        <p class="text-white-800">Total cars invoiced amount </p>
        <p class="text-blue-500">€{{getTotalInvoicedAmount() | number}}</p>
      </div>
      <div class="flex justify-between">
        <p class="text-white-800">Total extras amount</p>
        <p class="text-blue-500">€{{getTotalExtrasAmmount() | number}}</p>
      </div>
    </div>
  </div>

  <div class="flex flex-col">
    <div class="p-9 bg-pink flex gap-4 justify-between rounded-t">
      <div class="flex gap-4 items-center">
        <fa-icon class="py-3 px-4 rounded bg-white" [icon]="['far','sigma']"></fa-icon>
        <div class="flex flex-col gap-1">
          <p class="font-bold text-lg">€{{getTotalExtrasProfit() | number}}</p>
          <p class="text-sm text-white-800">Extras total profit</p>
        </div>
      </div>

      <div *ngIf="isSentOrder">
        <app-toggle toggleLabel="Show cost values" id="cost-values"
          [toggleFormControl]="showExtrasInitialValuesControl"></app-toggle>
      </div>
    </div>

    <div class="bg-white p-9 flex flex-col gap-6 h-full rounded-b">
      <p *ngIf="showExtrasInitialValuesControl.value">Invoiced values:</p>

      <div class="flex justify-between">
        <p class="text-white-800">Transportation total amount</p>
        <p class="text-pink-100">€{{getTotalTransportAmount() | number}}</p>
      </div>
      <div class="flex justify-between">
        <p class="text-white-800">Photo edit total amount</p>
        <p class="text-pink-100">€{{getTotalPhotoEditAmount() | number}}</p>
      </div>

      <div *ngIf="showExtrasInitialValuesControl.value" class="flex flex-col gap-6 border-t border-white-500 pt-6">
        <p>Cost values:</p>
        <div class="flex justify-between">
          <p class="text-white-800">Transportation total amount</p>
          <p class="text-pink-100">€{{getTotalCostTransportAmount() | number}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-800">COC total amount</p>
          <p class="text-pink-100">€{{getTotalCostCOCAmount() | number}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-800">Photo edit total amount</p>
          <p class="text-pink-100">€{{getTotalCostPhotoEditAmount() | number}}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-white-800">DHL delivery total amount</p>
          <p class="text-pink-100">€{{order!.dhlValue | number}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col">
    <div class="p-9 bg-green-300 flex gap-4 items-center rounded-t">
      <fa-icon class="py-3 px-4 rounded bg-white" [icon]="['far','sigma']"></fa-icon>
      <div class="flex flex-col gap-1">
        <p class="font-bold text-lg">€{{order.profitAnalysis.totalInvoiced| number}}</p>
        <p class="text-sm text-white-800">Total invoice profit</p>
      </div>
    </div>

    <div class="bg-white p-9 flex flex-col gap-6 rounded-b">
      <div class="flex justify-between">
        <p class="text-white-800">Average selling price/car</p>
        <p class="text-green-100">€{{getAverageSellingPrice() | number: '1.0-2'}}</p>
      </div>

      <div class="flex justify-between">
        <p class="text-white-800">Average profit/car</p>
        <p class="text-green-100">€{{getAverageProfit() | number: '1.0-2'}}</p>
      </div>

      <div class="flex justify-between">
        <p class="text-white-800">Minimum invoice profit margin</p>
        <p class="text-green-100"><span class="text-blue-100">{{getMinimumInvoiceProfitMarginPercentage() | number:
            '1.0-2'}}%</span> €{{getMinimumInvoiceProfitMargin() | number}}
        </p>
      </div>
      <div class="flex justify-between">
        <p class="text-white-800">Actual invoice profit margin</p>
        <p class="text-green-100"><span class="text-blue-100">{{getProfitPercentage() | number:
            '1.0-2'}}%</span> €{{getProfit() | number}}</p>
      </div>
    </div>
  </div>
</div>
