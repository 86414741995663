<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
    <div class="font-bold text-2xl">
      <p>My orders</p>
    </div>

    <div class="grid grid-cols-3 gap-6">
      <div class="bg-white p-9">
        <app-pie-chart chartId="ordersStatus" [data]="ordersStatusData"></app-pie-chart>
      </div>

      <div class="bg-white p-9">
        <app-line-chart chartId="finishedOrders" [data]="finishedStatusData"></app-line-chart>
      </div>

      <div class="bg-white p-9 text-sm">
        <p class="font-bold pb-12">Finished orders {{year}} - financial stats</p>

        <div class="flex flex-col gap-9">
          <div>
            <div class="flex justify-between pb-3 items-end">
              <p>{{year}} total amount invoiced</p>

              <p class="text-2xl">€ {{totalInvoicedCurrentYear | number}}</p>
            </div>

            <div class="bg-green-100 w-full h-2"></div>
          </div>

          <div>
            <div class="flex justify-between pb-3 items-end">
              <p>{{mostProfitableMonth}} {{year}} most profitable month</p>

              <p>€ {{mostProfitableMonthValue | number}}</p>
            </div>

            <div class="bg-white-500 w-full h-2">
              <div id="mostProfitable" class="relative bg-green-200 h-2 w-1/2"></div>
            </div>
          </div>

          <div>
            <div class="flex justify-between pb-3 items-end">
              <p>{{leastProfitableMonth}} {{year}} least profitable month</p>

              <p>€ {{leastProfitableMonthValue | number}}</p>
            </div>

            <div class="bg-white-500 w-full h-2">
              <div id="leastProfitable" class="relative bg-green-200 opacity-60 h-2 w-1/2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex gap-6 items-center flex-wrap">
      <app-input id="order-id-filter" [control]="filterGroup.controls.orderId" placeholder="Insert ID"
        label="Order ID"></app-input>

      <app-input id="order-creator-filter" [control]="filterGroup.controls.createdBy" placeholder="Insert email"
        label="Created by"></app-input>

      <app-dropdown *ngIf="isAdmin" [control]="filterGroup.controls.delegatedKam" [resetValue]="true" [options]="kams"
        placeholder="Select KAM" label="Delegated KAM"></app-dropdown>

      <app-input id="buyers-filter" [control]="filterGroup.controls.buyer" placeholder="Insert name"
        label="Buyer"></app-input>

      <app-dropdown id="statuses-filter" [control]="filterGroup.controls.status" [resetValue]="true"
        [options]="statusesDropdown" placeholder="Select status" label="Order status"></app-dropdown>

      <app-dropdown id="statuses-filter" [control]="filterGroup.controls.status" [resetValue]="true"
        [options]="statusesDropdown" placeholder="Select status" label="Payment status"></app-dropdown>

      <app-datepicker id="date-from-filter" [control]="filterGroup.controls.dateFrom" placeholder="dd/mm/yyyy"
        label="Date from"></app-datepicker>

      <app-datepicker id="date-to-filter" [control]="filterGroup.controls.dateTo" placeholder="dd/mm/yyyy"
        label="Date to"></app-datepicker>

      <primary-btn id="search" [blueBtn]="true" (clickEmitter)="searchOrders(true)">Search</primary-btn>

      <secondary-btn (clickEmitter)="resetFilters()">Reset filters</secondary-btn>
    </div>

    <div class="overflow-x-auto flex flex-col">
      <app-table *ngIf="!(tableLoading | async);else tablePlaceholder" [data]="orders"
        [displayedColumns]="displayedColumns" [headersMap]="tableColumns" (objectEmitter)="goToOrder($event)"
        [hidePaginator]="true"></app-table>

      <mat-paginator #paginator [length]="results" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
        aria-label="Select page" (page)="searchOrders()"></mat-paginator>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="grid grid-cols-2 gap-6 gap-y-9">
    <div class="col-span-2 grid grid-cols-3 gap-6">
      <div *ngFor="let i of [].constructor(3)">
        <div class="placeholder-animation h-9"></div>
        <div class="bg-white h-64"></div>
      </div>
    </div>

    <app-table-placeholder class="col-span-2"></app-table-placeholder>
  </div>
</ng-template>

<ng-template #tablePlaceholder>
  <app-table-placeholder></app-table-placeholder>
</ng-template>
