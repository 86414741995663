<app-navbar></app-navbar>

<app-page-layout>
  <div class="page-title">
    <p>Manage Online Cars</p>
  </div>

  <div class="grid grid-cols-5 gap-6">
    <div class="col-span-1">
      <cars-filter #carsFilter></cars-filter>
    </div>

    <!-- table -->
    <div class="col-span-4 flex flex-col" *ngIf="!(loading | async);else placeholderCars">
      <div class="bg-white rounded flex flex-wrap gap-3 text-sm p-6">
        <secondary-btn id="update-cars" btnText="Update selected cars" (clickEmitter)="updateCars()"></secondary-btn>

        <div class="px-6 py-2 bg-blue-100 text-white rounded flex items-center">
          <p>{{results | number}} results available</p>
        </div>

        <div class="px-6 py-2 border border-white-500 rounded flex cursor-default items-center"
          *ngFor="let filter of filtersSelected;let i = index">
          <p>{{filter.group}}:</p>
          <p class="ml-2 mr-4">{{filter.name}}</p>
          <fa-icon [id]="'remove-filter-'+ (i + 1) + '-btn'" [attr.data-cy]="'remove-filter-'+ (i + 1) + '-btn'"
            [icon]="['far','xmark']" class="cursor-pointer" (click)="removeFilter(filter)">
          </fa-icon>
        </div>
      </div>

      <app-table *ngIf="!(tableLoading | async);else tablePlaceholder" class="mt-9 mb-4" #carsTable [data]="cars"
        [displayedColumns]="displayedColumns" [headersMap]="tableColumns" (goToEmitter)="goToCar($event)"
        [hidePaginator]="true"></app-table>

      <mat-paginator class="bg-white-400" [length]="results" [pageSize]="5" showFirstLastButtons #paginator
        (page)="changePage($event)">
      </mat-paginator>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholderCars>
  <div class="col-span-4  flex flex-col gap-2">
    <div class="h-9 w-full bg-white-100"></div>

    <div class="h-9 w-full placeholder-animation" *ngFor="let i of [].constructor(10)"></div>
  </div>
</ng-template>

<ng-template #tablePlaceholder>
  <app-table-placeholder></app-table-placeholder>
</ng-template>
