import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { ViewBuyersComponent } from "./view-buyers.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ViewBuyersComponent
  ]
})

export class ViewBuyersModule { }
