import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import _ from 'lodash';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { IOrderCarFlatView, SearchOrdersCarsBody } from 'src/app/core/models/order.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderStatus } from '../orders-view/orders-view.component';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-orders-cars',
  templateUrl: './orders-cars.component.html',
  styleUrls: ['./orders-cars.component.scss']
})
export class OrdersCarsComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  tableMap = [
    {
      value: 'orderId',
      tableView: 'order number'
    },
    {
      value: 'orderStatus',
      tableView: 'order Status'
    },
    {
      value: 'orderDate',
      tableView: 'order DATE',
      isDateHours: true,
    },
    {
      value: 'carStatus',
      tableView: 'car Status'
    },
    {
      value: 'transportStatus',
      tableView: 'transport Status'
    },
    {
      value: 'readyForPickup',
      tableView: 'ready For Pickup'
    },
    {
      value: 'pickupDate',
      tableView: 'pickup Date',
      isDateSimple: true,
    },
    {
      value: 'estDeliveryDate',
      tableView: 'est Delivery Date',
      isDateSimple: true,
    },
    {
      value: 'carLocation',
      tableView: 'car Location'
    },
    {
      value: 'carDeliveryLocation',
      tableView: 'car Delivery Location'
    },
    {
      value: 'transport By C2C',
      tableView: 'transportByC2C'
    },
    {
      value: 'documents',
      tableView: 'documents'
    },
    {
      value: 'CMR',
      tableView: 'CMR'
    },
    {
      value: 'COC',
      tableView: 'COC'
    },
    {
      value: 'sellerPaid',
      tableView: 'seller Paid'
    },
    {
      value: 'buyerPaid',
      tableView: 'buyer Paid'
    },
    {
      value: 'invoicingPrice',
      tableView: 'invoicing Price',
      isNumber: true
    },
    {
      value: 'invoicingDate',
      tableView: 'invoicing Date'
    },
    {
      value: 'purchasePrice',
      tableView: 'purchase Price',
      isNumber: true
    },
    {
      value: 'sellingPrice',
      tableView: 'selling Price',
      isNumber: true
    },
    {
      value: 'buyerName',
      tableView: 'buyer Name'
    },
    {
      value: 'buyerCountry',
      tableView: 'buyer Country'
    },
    {
      value: 'sellerName',
      tableView: 'seller Name'
    },
    {
      value: 'sellerCountry',
      tableView: 'seller Country'
    },
    {
      value: 'kamBuyer',
      tableView: 'kam Buyer'
    },
    {
      value: 'kamSeller',
      tableView: 'kam Seller'
    },
  ];

  dataSource = new MatTableDataSource<IOrderCarFlatView>();

  displayedColumns = ['vin', 'regNo', 'carDetails', ...this.tableMap.map(t => t.value), 'viewCar'];

  filterBody = this.fb.group({
    vin: new FormControl<string | undefined>(undefined, Validators.minLength(3)),
    regNo: new FormControl<string | undefined>(undefined),
    make: new FormControl<string | undefined>(undefined),
    model: new FormControl<string | undefined>(undefined),
    variant: new FormControl<string | undefined>(undefined),
    orderNo: new FormControl<string | undefined>(undefined),
    orderFrom: new FormControl<string | undefined>(undefined),
    orderTo: new FormControl<string | undefined>(undefined),
    orderStatus: new FormControl<string | undefined>(undefined),
    subStatus: new FormControl<string | undefined>(undefined),
    carStatus: new FormControl<string | undefined>(undefined),
    carLocation: new FormControl<string | undefined>(undefined),
    carDeliveryLocation: new FormControl<string | undefined>(undefined),
    transportStatus: new FormControl<string | undefined>(undefined),
    carReady: new FormControl<boolean | string | undefined>(undefined),
    pickupDateFrom: new FormControl<string | undefined>(undefined),
    pickupDateTo: new FormControl<string | undefined>(undefined),
    transportByC2C: new FormControl<boolean | string | undefined>(undefined),
    kamBuyer: new FormControl<string | undefined>(undefined),
    kamSeller: new FormControl<string | undefined>(undefined),
  });

  countries: DropdownOption[] = this.coreService.countries.map(c => ({ viewValue: c.name, value: c.name }));

  makes: DropdownOption[] = [];
  models: DropdownOption[] = [];
  variants: DropdownOption[] = [];
  transportStatuses: DropdownOption[] = [];
  orderStatuses: DropdownOption[] = Object.values(OrderStatus).map(s => ({ viewValue: s, value: s }));
  orderSubstatuses: DropdownOption[] = [];
  carStatuses: DropdownOption[] = [{ viewValue: 'Confirmed', value: 'Confirmed' }, { viewValue: 'Unset', value: 'Unset' }];
  boolDropdown: DropdownOption[] = [{ viewValue: 'Yes', value: 'yes' }, { viewValue: 'No', value: 'no' }];

  @ViewChild('paginator') paginator: MatPaginator | undefined;

  constructor(private orderService: OrdersService,
    private utilsService: UtilsService,
    private coreService: CoreService,
    private carService: CarService,
    private spinner: SpinnerHandlerService,
    private snackbar: SnackbarService,
    private fb: FormBuilder) { }

  ngOnInit() {
    forkJoin({
      cars: this.orderService.getOrdersCarsFlatView({}),
      makes: this.carService.getCarNomenclatorData('makes'),
      transportStatuses: this.orderService.getTransportStatuses(),
      subStatuses: this.orderService.getOrderSubstatuses()
    }).subscribe(resp => {
      this.dataSource.data = resp.cars;

      this.orderSubstatuses = resp.subStatuses.map(s => ({ value: s.subStatus, viewValue: s.subStatus }));
      this.makes = resp.makes.map(m => { return { value: m, viewValue: m } });
      this.transportStatuses = resp.transportStatuses.map(m => { return { value: m, viewValue: m } });

      this.loading.next(false);

      setTimeout(() => {
        this.dataSource.paginator = this.paginator!;
      }, 0)
    })
  }

  getValue(element: any, value: TableHeaderMap): string {
    if (value.isDateHours) {
      return this.utilsService.utcDateToLocal(_.get(element, value.value.split('.')));
    } else if (value.isDateSimple) {
      return this.utilsService.formatDateValue(_.get(element, value.value.split('.')));
    } else {
      return _.get(element, value.value.split('.'));
    }
  }

  loadModels(make: string) {
    if (make) {
      this.spinner.showProgressBar.next(true);

      this.carService.getCarNomenclatorData(`models?make=${encodeURIComponent(make)}`).subscribe(resp => {
        this.models = resp.map(m => { return { value: m, viewValue: m } });

        this.spinner.showProgressBar.next(false);
      })
    } else {
      this.models = [];
      this.variants = [];

      this.filterBody.controls.model.reset();
      this.filterBody.controls.variant.reset();
    }
  }

  loadVariants(model: string) {
    if (model) {
      this.spinner.showProgressBar.next(true);

      this.carService.getCarNomenclatorData(`variants?make=${encodeURIComponent(this.filterBody.value.make!)}&model=${encodeURIComponent(model)}`).subscribe(resp => {
        this.variants = resp.map(m => { return { value: m, viewValue: m } });

        this.spinner.showProgressBar.next(false);
      })
    } else {
      this.variants = [];

      this.filterBody.controls.variant.reset();
    }
  }

  search() {
    if (this.filterBody.invalid) {
      this.snackbar.negativeSentiment('VIN requires at least 3 characters');
      return;
    }

    let body: SearchOrdersCarsBody = {
      ...this.filterBody.value,
      carReady: this.filterBody.value.carReady ? (this.filterBody.value.carReady === 'yes' ? true : false) : undefined,
      transportByC2C: this.filterBody.value.transportByC2C ? (this.filterBody.value.transportByC2C === 'yes' ? true : false) : undefined,
      orderFrom: this.filterBody.value.orderFrom ? moment(this.filterBody.value.orderFrom).format('yyyy-MM-DD') : null,
      orderTo: this.filterBody.value.orderTo ? moment(this.filterBody.value.orderTo).format('yyyy-MM-DD') : null,
      pickupDateFrom: this.filterBody.value.pickupDateFrom ? moment(this.filterBody.value.pickupDateFrom).format('yyyy-MM-DD') : null,
      pickupDateTo: this.filterBody.value.pickupDateTo ? moment(this.filterBody.value.pickupDateTo).format('yyyy-MM-DD') : null,
    };

    body = this.utilsService.removeBlankAttributes(body);

    this.loading.next(true);

    this.orderService.getOrdersCarsFlatView(body).subscribe({
      next: resp => {
        this.loading.next(false);

        this.dataSource.data = resp;

        setTimeout(() => {
          this.dataSource.paginator = this.paginator!;
        }, 0)
      },
      error: err => {
        this.loading.next(false);
      }
    })
  }

  resetFilters() {
    this.filterBody.reset();

    this.search();
  }

  downloadCars() {
    let wsHeaders = this.tableMap.map(t => t.tableView.toUpperCase());

    wsHeaders.unshift('VIN', 'REG NO', 'MAKE', 'MODEL', 'VARIANT');

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(ws, [wsHeaders]);

    let rows: string[][] = []

    this.dataSource.data.forEach((c: any) => {
      let row: string[] = [c.vin, c.regNo, c.make, c.model, c.variant];

      this.tableMap.forEach(m => {
        row.push(c[m.value] ? this.getValue(c, m) : '');
      });

      rows.push(row);
    })

    XLSX.utils.sheet_add_aoa(ws, rows, { origin: 'A2' });


    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'orders-cars.xlsx');
  }
}
