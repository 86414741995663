<div class="flex gap-9 flex-col">
  <div class="flex gap-6">
    <app-dropdown id="seller-filters" [options]="sellerFilters" [control]="sellerFilterControl" class="flex" label="Filter sellers"
      (emitOnChangeSelect)="filterCars($event)" placeholder="Select seller"></app-dropdown>

    <ng-content></ng-content>
  </div>

  <app-table #carsTable [displayedColumns]="displayedColumns" [headersMap]="headersMap" [data]="cars"
    (objectEmitter)="openModal($event)"></app-table>
</div>
