import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanDeactivate} from "@angular/router";
import { firstValueFrom, Observable } from "rxjs";
import { UnsavedChangesModalComponent } from "src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private dialog: MatDialog) {}

  canDeactivate(component: ComponentCanDeactivate){
    return component.canDeactivate() ?
      true :
      this.showUnsavedWorkModal();
  }

  async showUnsavedWorkModal(): Promise<boolean> {
    const dialogRef = this.dialog.open(
      UnsavedChangesModalComponent, {
      width: '550px',
      autoFocus: false,
      data: {
      }
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }
}
