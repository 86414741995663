import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import hours from 'src/assets/json-files/hours.json';
import { WindowPickupForm } from '../../b2b-seller/seller-store-restrictions/seller-store-restrictions.component';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { EditSellerPickupsBody } from 'src/app/core/models/seller-models/seller-edit.model';

@Component({
  selector: 'app-view-b2b-seller-pickups',
  templateUrl: './view-b2b-seller-pickups.component.html',
  styleUrls: ['./view-b2b-seller-pickups.component.scss']
})
export class ViewB2bSellerPickupsComponent implements OnInit {
  isAdmin = this.coreService.isAdmin;
  editMode = false;

  sellerId = this.b2bSellerSyncService.sellerId;

  public subscriptions: Subscription = new Subscription();

  stores = this.b2bSellerSyncService.sellerResponse!.stores;
  storeOptions = this.b2bSellerSyncService.sellerResponse!.stores.map(s => ({ value: s.id!, viewValue: s.name! }));
  storeControl = new FormControl();
  deliveryWindows = this.b2bSellerSyncService.pickupWindows;

  hours = hours;
  countries: DropdownOption[] = this.coreService.countries.map(c => ({ value: c.id, viewValue: c.name }));
  notes: string = "";

  country = '';
  countryBankHolidaysControl = new FormControl();

  windows: WindowPickupForm[] = [];

  constructor(private b2bSellerSyncService: B2BSellerSyncService,
    private sellerService: SellerService,
    private snackbar: SnackbarService,
    private coreService: CoreService,
    private spinnerService: SpinnerHandlerService) { }

  ngOnInit(): void {
    this.windows = this.deliveryWindows.map(dw => ({
      id: dw.id,
      name: dw.period,
      from: new FormControl(),
      to: new FormControl(),
      checkbox: false
    }))

    this.storeControl.setValue(this.storeOptions[0].value);
    this.loadStore(this.storeOptions[0].value);

    this.subscriptions.add(this.b2bSellerSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.b2bSellerSyncService.setCurrentTab(value);
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveRestriction() {
    if (this.spinnerService.showProgressBar.value) return;

    let windows = this.windows.filter(w => w.checkbox);

    if (windows.length === 0) {
      this.snackbar.negativeSentiment('Stores must have at least one pickup window');

      return;
    }

    if (windows.some(w => !w.from.value || !w.to.value)) {
      this.snackbar.negativeSentiment('Complete hours for the periods selected');

      return;
    }

    this.spinnerService.showProgressBar.next(true);

    let pickups = windows.map(w => ({
      note: this.notes,
      from: w.from.value!,
      to: w.to.value!,
      period: {
        periodId: w.id,
        period: w.name
      }
    }));

    let body = new EditSellerPickupsBody(this.sellerId!, this.storeControl.value!, pickups);

    this.sellerService.editSellerPickups(body).subscribe({
      next: resp => {
        let index = this.b2bSellerSyncService.sellerResponse!.stores.findIndex(s => s.id === this.storeControl.value!);

        this.b2bSellerSyncService.sellerResponse!.stores[index].pickups = body.pickups;

        this.spinnerService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Pickup windows saved');
      },
      error: err => {
        this.spinnerService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  loadStore(storeId: string) {
    this.resetForm();

    let store = this.stores.find(s => s.id === storeId)!;

    this.country = store.countryDetails.country;

    store.pickups.forEach(r => {
      let window = this.windows.find(w => w.id === r.period.periodId)!;

      window.checkbox = true;
      window.from.setValue(r.from.split(":")[0]);
      window.to.setValue(r.to.split(":")[0]);
    })

    if (store.pickups.length > 0) {
      this.countryBankHolidaysControl.setValue(store.countryBank);
      this.notes = store.pickups[0].note!;
    }
  }

  resetForm() {
    this.country = '';
    this.countryBankHolidaysControl.reset();
    this.notes = "";

    this.resetAllWindows();
  }

  resetAllWindows() {
    this.windows.forEach(w => {
      w.checkbox = false;
      w.from.reset();
      w.to.reset();
    })
  }

  getStoreCountry(storeId: string) {
    return this.b2bSellerSyncService.sellerResponse!.stores.find(s => s.id === storeId)?.countryDetails.country;
  }

  getCountryBankValue() {
    return this.countryBankHolidaysControl.value ? this.countryBankHolidaysControl.value : false;
  }
}
