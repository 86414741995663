import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EditBuyerDeliveriesBody } from 'src/app/core/models/buyer.model';
import { BuyerSyncService } from 'src/app/core/services/buyer-flow-sync.service';
import { BuyerService } from 'src/app/core/services/buyer.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import hours from 'src/assets/json-files/hours.json';

interface WindowDeliveryForm {
  id: string,
  name: string,
  from: FormControl,
  to: FormControl,
  checkbox: boolean
}

@Component({
  selector: 'app-view-buyer-deliveries',
  templateUrl: './view-buyer-deliveries.component.html',
  styleUrls: ['./view-buyer-deliveries.component.scss']
})

export class ViewBuyerDeliveriesComponent implements OnInit {
  @Input() isAdmin = false;
  editMode = false;

  buyerId = this.buyerSyncService.buyerId;

  public subscriptions: Subscription = new Subscription();

  stores = this.buyerSyncService.stores;
  storesOptions = this.stores.map(s => ({ value: s.id, viewValue: s.name! }));
  storeControl = new FormControl();

  hours = hours;
  notes: string = "";

  country = '';
  countryBankHolidaysControl = new FormControl();

  windows: WindowDeliveryForm[] = [];
  deliveryWindows = this.buyerSyncService.deliveryWindows;

  constructor(private buyerSyncService: BuyerSyncService,
    private buyerService: BuyerService,
    private snackbar: SnackbarService,
    private spinnerService: SpinnerHandlerService) { }

  ngOnInit(): void {
    this.windows = this.deliveryWindows.map(dw => ({
      id: dw.id,
      name: dw.period,
      from: new FormControl(),
      to: new FormControl(),
      checkbox: false
    }));

    this.storeControl.setValue(this.storesOptions[0].value);
    this.loadStore(this.storesOptions[0].value);

    this.subscriptions.add(this.buyerSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.buyerSyncService.setCurrentTab(value);
      }
    ));

    this.subscriptions.add(this.buyerSyncService.saveFormEvent$.subscribe((value) => {
      this.saveRestriction();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveRestriction() {
    if (this.spinnerService.showProgressBar.value) return;

    let windows = this.windows.filter(w => w.checkbox);

    if (windows.some(w => !w.from.value || !w.to.value)) {
      this.snackbar.negativeSentiment('Complete hours for the periods selected');

      return;
    }

    let deliveries = windows.map(w => ({
      note: this.notes,
      from: `${w.from.value}:00:00`,
      to: `${w.to.value}:00:00`,
      period: {
        periodId: w.id,
        period: w.name,
      }
    }));

    let body = new EditBuyerDeliveriesBody(this.buyerId!, this.storeControl.value!, deliveries);

    this.spinnerService.showProgressBar.next(true);

    this.buyerService.editBuyerDeliveries(body).subscribe({
      next: resp => {
        this.spinnerService.showProgressBar.next(false);

        this.snackbar.positiveSentiment(resp);
      },
      error: err => {
        this.spinnerService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  loadStore(storeId: string) {
    this.resetForm();

    let store = this.stores.find(s => s.id === storeId)!;

    this.country = store.countryDetails.country;

    store.deliveries.forEach(r => {
      let window = this.windows.find(w => w.id === r.period.periodId)!;

      window.checkbox = true;
      window.from.setValue(r.from.split(":")[0]);
      window.to.setValue(r.to.split(":")[0]);
    })

    if (store.deliveries.length > 0) {
      this.countryBankHolidaysControl.setValue(store.countryBank);
      this.notes = store.deliveries[0].note!;
    }
  }

  resetForm() {
    this.country = '';
    this.countryBankHolidaysControl.reset();
    this.notes = "";

    this.resetAllWindows();
  }

  resetAllWindows() {
    this.windows.forEach(w => {
      w.checkbox = false;
      w.from.reset();
      w.to.reset();
    })
  }

  getCountryBankValue() {
    return this.countryBankHolidaysControl.value ? this.countryBankHolidaysControl.value : false;
  }
}
