<div class="twoCols">
  <div class="col-span-2 flex justify-between">
    <app-dropdown id="stores" [options]="storesDropdown" label="Store" [control]="storeToggleControl"
      (emitOnChangeSelect)="changeStore($event)"></app-dropdown>


    <secondary-btn id="edit-mode" *ngIf="isAdmin && !editMode" (clickEmitter)="enterEditMode()">Edit
      stores</secondary-btn>

    <div class="flex gap-6" *ngIf="editMode">
      <secondary-btn *ngIf="!defaultStoreControl.value" btnText="Make default store"
        (clickEmitter)="makeDefaultStore()"></secondary-btn>

      <primary-btn id="save-store" btnText="Save Store" (clickEmitter)="saveStore()"></primary-btn>

      <tertiary-btn id="add-stre" btnText="Add Store" (clickEmitter)="addNewStore()"></tertiary-btn>

      <secondary-btn id="copy-data" btnText="Copy data from main company"
        (clickEmitter)="copyDataFromCompany()"></secondary-btn>
    </div>
  </div>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Store details</p>
    </div>

    <app-main-content-row-layout>
      <p>Store name*</p>
      <app-input id="store-name" placeholder="Insert store name" class="secondContent"
        [control]="storeDetailsForm.controls.name"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Address*</p>
      <app-input id="address" placeholder="Insert address" class="secondContent"
        [control]="storeDetailsForm.controls.address"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Region*</p>
      <div class="secondContent twoCols">
        <app-read-only-input
          [ngClass]=" {'text-gray-disabledInput' : !editMode}">{{companyCountry.country}}</app-read-only-input>

        <app-input id="city" placeholder="Insert city" class="col-span-1"
          [control]="storeDetailsForm.controls.city"></app-input>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>ZIP Code*</p>
      <app-input id="postalcode" placeholder="Insert zip code" class="secondContent"
        [control]="storeDetailsForm.controls.postalCode"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Rep makes*</p>

      <app-dropdown [selectAll]="true" [multipleSelect]="true" [options]="makes" id="repMakes"
        placeholder="Select makes" class="secondContent" [control]="storeDetailsForm.controls.repMakes"></app-dropdown>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Sold makes*</p>

      <app-dropdown [selectAll]="true" [multipleSelect]="true" [options]="makes" id="soldMakes"
        placeholder="Select makes" class="secondContent" [control]="storeDetailsForm.controls.soldMakes"></app-dropdown>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Use country’s bank holidays</p>

      <app-toggle id="country-holidays" class="secondContent"
        [toggleFormControl]="storeDetailsForm.controls.countryBank"></app-toggle>
    </app-main-content-row-layout>
  </app-main-content-layout>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Store info</p>
    </div>

    <app-main-content-row-layout>
      <p>Status</p>

      <div class="secondContent text-end">
        <app-toggle id="status" class="secondContent" [toggleFormControl]="statusControl"
          [toggleLabel]="statusControl.value? 'Active' :'Inactive'"></app-toggle>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Is default store?</p>

      <div class="secondContent text-end">
        <app-toggle id="default" class="secondContent" [toggleFormControl]="defaultStoreControl"
          [toggleLabel]="defaultStoreControl.value? 'Yes' :'No'"></app-toggle>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Date created</p>

      <app-read-only-input class="secondContent" *ngIf="dateCreated">
        {{dateCreated | date:'dd/MM/yyyy'}}
      </app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Last update</p>

      <app-read-only-input class="secondContent" *ngIf="dateUpdated">
        {{dateUpdated | date:'dd/MM/yyyy'}}
      </app-read-only-input>
    </app-main-content-row-layout>

    <div class="">
      <p class="font-bold mb-7">Store users</p>
      <div class="flex flex-col gap-3 p-6 bg-white-400 rounded max-h-52 overflow-auto">
        <div *ngFor="let user of users" class="flex gap-4 items-center">
          <div class="rounded-[100%] w-[6px] h-[6px] bg-blue-100"></div>
          {{user}}
        </div>
      </div>
    </div>
  </app-main-content-layout>
</div>
