<div class="flex flex-col gap-9">
  <div class="grid grid-cols-2 gap-6">
    <app-main-content-layout>
      <div class="title">
        Selected buyer
      </div>

      <app-main-content-row-layout>
        <p>Main company name</p>

        <app-read-only-input class="secondContent">{{order!.buyer.name}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>VAT number</p>

        <app-read-only-input class="secondContent">{{order!.buyer.vat}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Address</p>

        <app-read-only-input class="secondContent">{{order!.buyer.address}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>City</p>

        <app-read-only-input class="secondContent">{{order!.buyer.name}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Postal code</p>

        <app-read-only-input class="secondContent">{{order!.buyer.postalCode}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Country</p>

        <app-read-only-input class="secondContent">{{order!.buyer.country!.location}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>User name</p>

        <app-read-only-input class="secondContent">{{order!.buyer.client!.fullName}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>User email</p>

        <app-read-only-input class="secondContent">{{order!.buyer.client!.email}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>User phone</p>

        <app-read-only-input class="secondContent">{{order!.buyer.client!.phoneNumber}}</app-read-only-input>
      </app-main-content-row-layout>
    </app-main-content-layout>

    <app-main-content-layout>
      <div class="title">
        <p>Delivery store</p>
      </div>

      <app-main-content-row-layout>
        <p>Store name</p>

        <app-read-only-input class="secondContent">{{order!.buyer.store!.name}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Address</p>

        <app-read-only-input class="secondContent">{{order!.buyer.store!.address}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>City</p>

        <app-read-only-input class="secondContent">{{order!.buyer.store!.city}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Postal code</p>

        <app-read-only-input class="secondContent">{{order!.buyer.store!.postalCode}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Country</p>

        <app-read-only-input class="secondContent">{{order!.buyer.store!.country!.location}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Contact name</p>

        <app-read-only-input class="secondContent">{{order!.buyer.store!.contactDetails.firstName}}
          {{order!.buyer.store!.contactDetails.lastName}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Contact email</p>

        <app-read-only-input class="secondContent">{{order!.buyer.store!.contactDetails.email}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Contact phone</p>

        <app-read-only-input class="secondContent">{{order!.buyer.store!.contactDetails.phone}}</app-read-only-input>
      </app-main-content-row-layout>
    </app-main-content-layout>
  </div>

  <app-main-content-layout>
    <div class="title">
      <p>Options</p>
    </div>

    <div class="flex flex-wrap gap-6 py-6 items-center">
      <app-datepicker [control]="orderSettings.controls.reqDelDate" id="req-delivery-date"
        label="Requested delivery date"></app-datepicker>
      <app-datepicker [control]="orderSettings.controls.estPaymentDate" id="est-payment-date"
        label="Estimated payment date"></app-datepicker>

      <app-toggle [toggleFormControl]="orderSettings.controls.transportC2C" id="transport-by-c2c"
        toggleLabel="Transport by cars2click"></app-toggle>

      <app-toggle [toggleFormControl]="orderSettings.controls.separateTransportInv" id="separate-inv-transport"
        toggleLabel="Separate invoice for transport"></app-toggle>

      <app-toggle [toggleFormControl]="orderSettings.controls.oneInvoiceCar" id="invoice-per-car"
        toggleLabel="One invoice per car"></app-toggle>
    </div>

    <textarea [disabled]="true" id="notes-textarea" data-cy="notes-textarea" [(ngModel)]="notes" rows="4"
      class="w-full rounded border border-white-500 p-6 text-blue-100 text-sm focus:outline-none"
      placeholder="Insert notes here..."></textarea>
  </app-main-content-layout>

  <!-- <app-main-content-layout>
    <div class="title">
      <p>Invoices</p>
    </div>

    <div class="flex gap-6">
      <app-read-only-input>
        <div class="flex gap-6">
          <p>Total number of invoices</p>
          <p>1</p>
        </div>
      </app-read-only-input>

      <app-input placeholder="Contract not yet signed"></app-input>
    </div>
  </app-main-content-layout> -->

  <app-table [displayedColumns]="displayedColumns" [headersMap]="headers" [data]="cars"></app-table>

  <!-- <p class="font-bold">Transportation status</p> -->

  <!-- <app-table></app-table> -->
</div>
