<app-navbar></app-navbar>

<app-page-layout class="min-h-[85vh]">
  <div class="flex flex-col gap-9">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">Create car</p>

      <primary-btn id="save-form" [blueBtn]="true" btnText="Create car" (clickEmitter)="createCar()">
      </primary-btn>
    </div>

    <div class="grid grid-cols-2 gap-6" *ngIf="!(loadingInfo | async);else placeholder">
      <div class="col-span-1 flex flex-col gap-6">
        <app-main-content-layout>
          <div class="title">
            <p>Technical data</p>
          </div>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.make.touched && baseCarDataForm.controls.make.invalid}">
              Make*</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select make" id="make" [control]="baseCarDataForm.controls.make"
                (emitOnChangeSelect)="loadModels($event)" [options]="carMakes">
              </app-dropdown>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.model.touched && baseCarDataForm.controls.model.invalid}">
              Model*</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select model" id="model" [disabled]="baseCarDataForm.controls.make.invalid"
                [control]="baseCarDataForm.controls.model" (emitOnChangeSelect)="loadFueltypes($event)"
                [options]="carModels">
              </app-dropdown>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.model.touched && baseCarDataForm.controls.model.invalid}">
              Fuel type*</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select fueltype" id="fueltype"
                [disabled]="baseCarDataForm.controls.model.invalid" [control]="baseCarDataForm.controls.fuelType"
                (emitOnChangeSelect)="loadGearbox($event)" [options]="carFuelTypes">
              </app-dropdown>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.model.touched && baseCarDataForm.controls.model.invalid}">
              Gearbox*</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select gearbox" id="gearbox"
                [disabled]="baseCarDataForm.controls.fuelType.invalid" [control]="baseCarDataForm.controls.gearbox"
                (emitOnChangeSelect)="loadVariants($event)" [options]="carGearboxes">
              </app-dropdown>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.variant.touched && baseCarDataForm.controls.variant.invalid}">
              Variant*</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select variant" id="variant"
                [disabled]="baseCarDataForm.controls.gearbox.invalid" [control]="baseCarDataForm.controls.variant"
                (emitOnChangeSelect)="loadTrims($event)" [options]="carVariants"></app-dropdown>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.trim.touched && baseCarDataForm.controls.trim.invalid}">
              Trim</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select trim" id="trim" [disabled]="baseCarDataForm.controls.variant.invalid"
                [control]="baseCarDataForm.controls.trim" [options]="carTrims"></app-dropdown>
            </div>
          </app-main-content-row-layout>

          <div class="flex justify-end gap-6 items-center">
            <secondary-btn id="reset" (clickEmitter)="resetCar()" btnText="Reset"
              *ngIf="!isFromDuplicate; else isFromDuplicateResetBtn"></secondary-btn>

            <ng-template #isFromDuplicateResetBtn>
              <secondary-btn id="reset" (clickEmitter)="openDuplicateWarningEqModal()" btnText="Reset"></secondary-btn>
            </ng-template>

            <primary-btn id="fueltype" btnText="See identified cars" [disableBtn]="carAlternatives.length === 0"
              (clickEmitter)="openCarsModal()"></primary-btn>

            <primary-btn id="identify" btnText="Identify car" [blueBtn]="true" [disableBtn]="baseCarDataForm.invalid"
              (clickEmitter)="identifyCar()"></primary-btn>
          </div>

          <app-main-content-row-layout>
            <p>Engine capacity</p>
            <app-input placeholder="Insert engine capacity" id="engine-capacity" [isNumberInput]="true"
              [control]="technicalDataForm.controls.engineVolume" class="secondContent"></app-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : technicalDataForm.touched && technicalDataForm.controls.enginePower.touched && technicalDataForm.controls.enginePower.invalid}">
              Engine power*</p>
            <app-input placeholder="Insert engine power" id="engine-power" [isNumberInput]="true"
              [control]="technicalDataForm.controls.enginePower" class="secondContent"></app-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : technicalDataForm.touched && technicalDataForm.controls.bodyType.touched && technicalDataForm.controls.bodyType.invalid}">
              Body type*</p>
            <app-dropdown placeholder="Select body type" id="bodytype" [control]="technicalDataForm.controls.bodyType"
              [options]="carBodyTypes" class="secondContent">
            </app-dropdown>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Driving wheels</p>
            <app-dropdown placeholder="Select driving wheels" id="driving-wheels"
              [control]="technicalDataForm.controls.drivingWheels" [options]="carDrivingWheels" class="secondContent">
            </app-dropdown>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>CO2 NEDC</p>
            <app-input placeholder="Insert CO2 NEDC" id="co2-nedc" [isNumberInput]="true"
              [control]="technicalDataForm.controls.co2NEDC" class="secondContent"></app-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>CO2 WLTP</p>
            <app-input placeholder="Insert CO2 WLTP" id="co2-wltp" [isNumberInput]="true"
              [control]="technicalDataForm.controls.co2WLTP" class="secondContent"></app-input>
          </app-main-content-row-layout>

        </app-main-content-layout>

        <!-- registration form -->
        <app-main-content-layout>
          <div class="title">
            <p>Registration & car details</p>
          </div>

          <app-main-content-row-layout>
            <p>First registration</p>
            <app-datepicker id="first-reg" class="secondContent"
              [control]="registrationForm.controls.firstReg"></app-datepicker>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : registrationForm.controls.manufactureYear.touched && registrationForm.controls.manufactureYear.invalid}">
              Manufacture Year*</p>
            <app-dropdown placeholder="Select manufacture year" id="man-year" class="secondContent"
              [control]="registrationForm.controls.manufactureYear" [options]="carManufactureYears"></app-dropdown>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : registrationForm.touched && registrationForm.controls.mileage.touched && registrationForm.controls.mileage.invalid}">
              Mileage*</p>

            <app-input-number placeholder="Insert mileage" id="mileage" class="secondContent"
              [control]="registrationForm.controls.mileage"></app-input-number>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Color</p>
            <app-dropdown placeholder="Select color" id="color" class="secondContent" [options]="carColors"
              [control]="registrationForm.controls.color">
            </app-dropdown>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Seats</p>
            <app-input placeholder="Insert seats" id="seats" class="secondContent" [isNumberInput]="true"
              [control]="registrationForm.controls.seats">
            </app-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Doors</p>
            <app-input placeholder="Insert doors" id="doors" class="secondContent" [isNumberInput]="true"
              [control]="registrationForm.controls.doors">
            </app-input>
          </app-main-content-row-layout>
        </app-main-content-layout>

        <!-- identification form -->
        <app-main-content-layout>
          <div class="title">
            <p>Identification</p>
          </div>

          <app-main-content-row-layout>
            <p>COC from seller</p>

            <app-toggle class="secondContent flex justify-end"
              [toggleFormControl]="identificationForm.controls.cocFromSeller"
              [toggleLabel]="identificationForm.controls.cocFromSeller.value ? 'Yes' : 'No'"></app-toggle>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : identificationForm.touched && identificationForm.controls.location.touched && identificationForm.controls.location.invalid}">
              Location*</p>
            <app-dropdown placeholder="Select location" id="location" class="secondContent"
              [control]="identificationForm.controls.location" [options]="countries"></app-dropdown>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>VIN</p>
            <p class="text-red" *ngIf="identificationForm.controls.vin.hasError('invalidLength')">
              VIN must have 17 characters
            </p>
            <p class="text-red" *ngIf="identificationForm.controls.vin.hasError('invalidChars')">
              VIN must not contain letters 'O','Q','I'
            </p>

            <app-input placeholder="Insert VIN" id="vin" class="secondContent"
              [control]="identificationForm.controls.vin"></app-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Registration number</p>
            <app-input placeholder="Insert registration number" id="regNo" class="secondContent"
              [control]="identificationForm.controls.regNo"></app-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Ad link</p>
            <app-input placeholder="Insert ad link" id="adlink" class="secondContent"
              [control]="identificationForm.controls.adLink"></app-input>
          </app-main-content-row-layout>

          <textarea id="notes-textarea" data-cy="notes-textarea" [formControl]="identificationForm.controls.otherInfo"
            rows="5" placeholder="Insert notes here"
            class="w-full rounded border border-white-500 p-6 text-blue-100 text-sm"></textarea>
        </app-main-content-layout>
      </div>

      <div class="col-span-1 flex flex-col gap-6">
        <!-- prices form -->
        <app-main-content-layout>
          <div class=" title">
            <p>Journeys & prices settings</p>
          </div>

          <app-main-content-row-layout>
            <div>
              <p>Status</p>
            </div>

            <app-read-only-input class="secondContent">Active</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : pricesForm.touched && pricesForm.controls.buyingPrice.touched && pricesForm.controls.buyingPrice.invalid}">
              Buying price*</p>

            <app-input-number placeholder="Insert buying price" id="buying-price" class="secondContent"
              [control]="pricesForm.controls.buyingPrice"></app-input-number>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : pricesForm.touched && pricesForm.controls.minSellingPrice.touched && (pricesForm.controls.minSellingPrice.invalid || minSellingPriceValidation())}">
              Min Selling price* <fa-icon matTooltip="Min selling price must be higher than buying price"
                matTooltipPosition="above" [icon]="['fal','circle-info']"></fa-icon>
            </p>

            <app-input-number placeholder="Insert min selling price" id="min-selling-price" class="secondContent"
              [control]="pricesForm.controls.minSellingPrice"></app-input-number>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Damages</p>

            <app-input-number placeholder="Insert damages" id="damages" class="secondContent"
              [control]="pricesForm.controls.damages"></app-input-number>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : pricesForm.controls.vatStatus.invalid && pricesForm.controls.vatStatus.touched}">
              VAT type*</p>

            <mat-radio-group class="vat-radio secondContent flex gap-6 text-sm sm:justify-evenly"
              aria-label="Select an option" [formControl]="pricesForm.controls.vatStatus">
              <mat-radio-button [value]="true">{{vatTypes.ExVAT}}</mat-radio-button>
              <mat-radio-button [value]="false">{{vatTypes.InclVAT}}</mat-radio-button>
            </mat-radio-group>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>B2B recommended price</p>

            <div class="secondContent grid grid-cols-2 gap-6 items-center">
              <app-read-only-input class="secondContent">
                {{b2bRecommendedPrice | number}}€
              </app-read-only-input>

              <secondary-btn id="get-bc" (clickEmitter)="getBC()">Get business case</secondary-btn>
            </div>
          </app-main-content-row-layout>


          <app-main-content-row-layout>
            <p>Autocomplete prices</p>

            <div class="secondContent grid grid-cols-2 gap-6 items-center">
              <app-input-number-ngmodel placeholder="Insert price"
                [(inputValue)]="autocompletePrice"></app-input-number-ngmodel>

              <secondary-btn (clickEmitter)="autocompletePrices()"> Autocomplete prices </secondary-btn>
            </div>
          </app-main-content-row-layout>
          <!-- <app-main-content-row-layout>
            <p>Taxes</p>
            <div class="secondContent flex flex-wrap gap-5">
              <div class="bg-white-300 p-3 w-max rounded" *ngFor="let tax of carTaxes">
                <p>{{tax.country}}: ${{tax.value | number}}</p>
              </div>
            </div>
          </app-main-content-row-layout> -->

          <!-- journeys -->
          <div *ngFor="let control of journeysGroupForm.controls; let i = index"
            class="flex flex-col gap-4 pt-4 border-t border-white-500">
            <app-main-content-row-layout>
              <p class="font-bold" [ngClass]="{'text-red': control.value.group.invalid && control.value.group.touched}">
                Journey {{i+1}}</p>

              <app-dropdown [id]="'journey-group-' + (i + 1)" placeholder="Select journey group" class="secondContent"
                [control]="control.value.group" [options]="journeyGroups">
              </app-dropdown>
            </app-main-content-row-layout>

            <app-main-content-row-layout>
              <p [ngClass]="{'text-red font-bold' : sellingPriceValidation(control)}">
                Journey selling price* <fa-icon matTooltip="Journey selling price must be higher than min selling price"
                  matTooltipPosition="above" [icon]="['fal','circle-info']"></fa-icon>
              </p>

              <app-input-number [id]="'journey-price-'+ (i + 1)" class="secondContent" [control]="control.value.price"
                placeholder="Insert selling price"></app-input-number>
            </app-main-content-row-layout>

            <app-main-content-row-layout>
              <p [ngClass]="{'text-red font-bold': control.value.from.invalid && control.value.from.touched}">Start date
              </p>

              <app-datepicker [id]="'journey-from-'+ (i + 1)" class="secondContent" [control]="control.value.from"
                [min]="control.value.unavailabilityForm.controls.startDate.value"
                placeholder="Select date"></app-datepicker>
            </app-main-content-row-layout>

            <app-main-content-row-layout>
              <p p
                [ngClass]="{'text-red font-bold': control.value.startTime.invalid && control.value.startTime.touched}">
                Start time (local time)</p>

              <app-dropdown [id]="'journey-from-time-'+ (i + 1)" class="secondContent" [options]="hours"
                [control]="control.value.startTime" placeholder="Select hour"></app-dropdown>
            </app-main-content-row-layout>

            <app-main-content-row-layout>
              <p p [ngClass]="{'text-red font-bold': control.value.until.invalid && control.value.until.touched}">End
                date</p>

              <app-datepicker [id]="'journey-to-'+ (i + 1)" class="secondContent" [control]="control.value.until"
                [min]="control.value.from.value" (dateChangeEvent)="setDates($event)"
                placeholder="Select date"></app-datepicker>
            </app-main-content-row-layout>

            <app-main-content-row-layout>
              <p p [ngClass]="{'text-red font-bold': control.value.endTime.invalid && control.value.endTime.touched}">
                End time (local time)</p>

              <app-dropdown [id]="'journey-to-time-'+ (i + 1)" class="secondContent" [options]="hours"
                [control]="control.value.endTime" (emitOnChangeSelect)="setInactiveFromHours($event)"
                placeholder="Select hour"></app-dropdown>
            </app-main-content-row-layout>

            <div class="flex gap-8 items-center justify-end">
              <p class="cursor-pointer" (click)="removeJourney(control.value.journeyId.value)">Remove journey</p>
            </div>
          </div>

          <app-main-content-row-layout class="pt-4 border-t border-white-500">
            <div class="secondContent flex justify-end gap-4">
              <secondary-btn id="add-journey" btnText="Add Journey" (clickEmitter)="addJourney()"></secondary-btn>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Inactive from</p>

            <app-read-only-input class="secondContent">
              <p>{{inactiveFrom ? (inactiveFrom | date:"dd/MM/yyyy HH:mm") : "dd/MM/yyyy"}}</p>
            </app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : identificationForm.touched && identificationForm.controls.availableFrom.touched && identificationForm.controls.availableFrom.invalid}">
              Available from*</p>

            <app-datepicker class="secondContent"
              [control]="identificationForm.controls.availableFrom"></app-datepicker>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <div class="col-span-1 flex items-center">
              <p>Source</p>
            </div>

            <app-read-only-input class="secondContent">Manual</app-read-only-input>
          </app-main-content-row-layout>
        </app-main-content-layout>

        <!-- seller -->
        <app-main-content-layout>
          <div class="title">
            <p>Seller details</p>
          </div>

          <app-main-content-row-layout>
            <div [ngClass]="{'text-red font-bold' : sellerControl.touched && sellerControl.invalid}">
              <p>Seller*</p>
            </div>
            <div class="secondContent">
              <input id="search-seller-input" data-cy="search-seller-input" class="search-seller" type="text" matInput
                [matAutocomplete]="auto" [formControl]="sellerControl" placeholder="Search seller">
              <div>
                <mat-autocomplete #auto="matAutocomplete">
                  <div *ngIf="existsResults | async">
                    <mat-option class="autocomplete-option" *ngFor="let seller of (resultItems| async)!"
                      (click)="selectSeller(seller)">
                      <div class="option-item">
                        {{seller.sellerCompanyDetails.name}}
                      </div>
                    </mat-option>
                  </div>
                </mat-autocomplete>
              </div>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Country</p>

            <app-read-only-input class="secondContent">
              {{selectedSeller? selectedSeller.sellerCompanyDetails.countryDetails.country : "No seller"}}
            </app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Address</p>

            <app-read-only-input class="secondContent">
              {{selectedSeller? selectedSeller.sellerCompanyDetails.address : "No seller"}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>VAT</p>

            <app-read-only-input class="secondContent">
              {{selectedSeller? selectedSeller.sellerCompanyDetails.vatDetails.vat : "No seller"}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Phone</p>

            <app-read-only-input class="secondContent">
              {{selectedSeller? selectedSeller.sellerCompanyDetails.phone : "No seller"}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Email</p>

            <app-read-only-input class="secondContent">
              {{selectedSeller? selectedSeller.sellerCompanyDetails.email : "No seller"}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Postal code</p>

            <app-read-only-input class="secondContent">
              {{selectedSeller? selectedSeller.sellerCompanyDetails.postalCode : "No seller"}}</app-read-only-input>
          </app-main-content-row-layout>
        </app-main-content-layout>
      </div>
    </div>

  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="twoCols">
    <app-main-content-placeholder class="col-span-1"></app-main-content-placeholder>
    <app-main-content-placeholder class="col-span-1"></app-main-content-placeholder>
  </div>
</ng-template>


<ng-template #resetCarModal>
  <div>
    <p class="font-bold">Reset car</p>

    <p class="font-bold pt-9 mt-3 border-t border-white-500 pb-7">Are you sure you want to reset this car?</p>

    <p>
      By reseting this car, you will lose information from duplicate event, including equipments
    </p>

    <div class="flex justify-end gap-6 pt-6">
      <button [mat-dialog-close]="false">Cancel</button>

      <button class="bg-[#F2264B] text-white px-3 py-2 rounded" [mat-dialog-close]="true">Yes, reset</button>
    </div>
  </div>
</ng-template>
