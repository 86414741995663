<app-main-content-layout>
  <div class="title">
    <p>Cars documents</p>
  </div>

  <div class="flex justify-between items-center">
    <div class="flex gap-6  items-center">
      <app-input placeholder="Insert document name" label="Document type/name" [control]="docNameControl"></app-input>

      <app-toggle [labelPosition]="'after'" [toggleLabel]="'Visible to buyers'"
        [toggleFormControl]="docVisibilityControl"></app-toggle>

      <div class="bg-white-500 rounded px-9 py-3 flex gap-6" *ngIf="docSelectedName.length > 0">
        <p class="whitespace-nowrap overflow-hidden text-ellipsis max-w-sm">{{docSelectedName}}</p>
        <fa-icon id="remove-file-btn" data-cy="remove-file-btn" class="cursor-pointer" [icon]="['far','xmark']"
          (click)="removeFile()"></fa-icon>
      </div>
    </div>

    <div class="flex gap-6  items-center">
      <div (click)="selectFile.click()"
        class="cursor-pointer active:scale-95 border border-black px-3 py-1 flex items-center gap-4 rounded">
        <fa-icon [icon]="['fal','up-to-line']"></fa-icon>

        <p>Upload file</p>
      </div>

      <primary-btn (clickEmitter)="saveFile()">Save document</primary-btn>
    </div>
  </div>


  <div>
    <p class="mb-6">Document list</p>

    <div class="flex flex-wrap gap-6">
      <div *ngFor="let d of documents" class="bg-white-700 rounded px-9 py-3 flex gap-4 w-fit items-center">
        <p class="whitespace-nowrap overflow-hidden text-ellipsis max-w-sm">{{d.name}}</p>

        <mat-slide-toggle [disabled]="true" [checked]="d.visible" [labelPosition]="'after'">
          Visible to buyers
        </mat-slide-toggle>

        <p class="underline text-blue-500 cursor-pointer active:scale-95 text-sm" (click)="downloadFile(d.id)">see doc</p>

        <fa-icon id="delete-file-btn" data-cy="delete-file-btn" class="cursor-pointer" [icon]="['far','xmark']"
          (click)="deleteFile(d.id)"></fa-icon>
      </div>
    </div>
  </div>

</app-main-content-layout>


<input id="uploadInput" class="hidden" #selectFile type="file" accept=".pdf" (change)="uploadFile($event)">
