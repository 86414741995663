<div *ngIf="!(loading | async);else placeholder">
  <div class="flex justify-between mb-6" *ngIf="!carsUploaded">
    <div class="flex flex-wrap gap-6">
      <app-white-btn (clickEmitter)="removeCars()">Remove cars</app-white-btn>

      <app-dropdown *ngIf="businessCaseIsoCountries.length > 0" label="BC Country" [options]="businessCaseIsoCountries"
        (emitOnChangeSelect)="changeBC($event)" [control]="businessCaseIsoCountriesControl"></app-dropdown>
    </div>

    <primary-btn [blueBtn]="true" btnText="Upload cars" (clickEmitter)="uploadCars()"></primary-btn>
  </div>

  <div class="overflow-x-auto">
    <table mat-table matSort [dataSource]="dataSource"
      class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">

      <ng-container matColumnDef="selectAll">
        <th mat-header-cell *matHeaderCellDef>
        </th>

        <td mat-cell *matCellDef="let element; let i = index">
          <mat-checkbox [id]="'select'+ (i + 1) +'-checkbox'" [attr.data-cy]="'select'+ (i + 1) +'-checkbox'"
            [(ngModel)]="element.isSelected"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="priceError" sticky>
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1"></th>
        <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
          <fa-icon class="text-orange" *ngIf="checkPrice(element)" [icon]="['fal','triangle-exclamation']"
            matTooltip="One or more selling price is lower or equal than buying price"
            matTooltipPosition="above"></fa-icon>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="col.value" *ngFor="let col of tableMap">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
          {{col.tableView}}</th>
        <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
          {{element[col.value]}}
        </td>
      </ng-container>

      <ng-container matColumnDef="damages">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
          damages
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
          <input *ngIf="!carsUploaded" type="number" class="px-3 py-2 border border-white-500"
            [(ngModel)]="element.damages">

          <p *ngIf="carsUploaded" class="px-3 py-2 border border-white-500">{{element.damages | number}}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="minSellingPrice">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
          min selling Price
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
          <input *ngIf="!carsUploaded" type="number" class="px-3 py-2 border border-white-500"
            [(ngModel)]="element.minSellingPrice">

          <p *ngIf="carsUploaded" class="px-3 py-2 border border-white-500">{{element.minSellingPrice | number}}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="buyingPrice">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">buying Price
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
          <input *ngIf="!carsUploaded" type="number" [id]="'buying-price-' + ( i + 1) + '-input'"
            [attr.data-cy]="'buying-price-' + ( i + 1) + '-input'" class="px-3 py-2 border border-white-500"
            [(ngModel)]="element.buyingPrice">

          <p *ngIf="carsUploaded" class="px-3 py-2 border border-white-500">{{element.buyingPrice | number}}</p>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="col.value" *ngFor="let col of journeyPricesCols">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
          <p>
            {{col.tableView}}
          </p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
          <input *ngIf="!carsUploaded" [id]="'selling-price-' + ( i + 1) + '-input'"
            [attr.data-cy]="'selling-price-' + ( i + 1) + '-input'" class="px-3 py-2 border border-white-500"
            [(ngModel)]="element[col.value]">

          <p *ngIf="carsUploaded" class="px-3 py-2 border border-white-500">{{element[col.value] | number}}</p>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100">
      </tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:bg-white-400">
      </tr>
    </table>
  </div>
</div>

<ng-template #placeholder>
  <app-table-placeholder></app-table-placeholder>

  <div *ngIf="bcAsyncMessage.length > 0" class="text-center text-sm mt-20">
    <p>{{bcAsyncMessage}}</p>
    <p class="font-bold mt-3">Please do not leave this page.</p>
  </div>
</ng-template>

<ng-template #displayNumber let-price>
  <div class="flex gap-6 justify-between">
    <p class="px-3 py-2 border border-white-500">{{price | number}}</p>
  </div>
</ng-template>
