<div class="bg-white-800 h-screen flex items-center" [ngClass]="{'!bg-white' : isLoggedIn()}">
  <div class="mx-auto min-w-[40rem]" *ngIf="!isLoggedIn();else placeholder">
    <div class="p-4 flex bg-white-500 gap-4 items-center">
      <img src="./assets/icons/c2c-full-logo-black.svg" alt="" class="max-w-[10rem]">

      <h2 class="pl-8 border-l border-[#b2b2b2] py-1">Admin web</h2>
    </div>

    <div class="p-9 text-center bg-white">
      <h1 class="font-bold text-xl mb-5 xl-mb-9">Admin Web - Log in</h1>

      <p class="mb-9 xl:mb-10">C2C Admin Web is the centralized onboarding and account <br>management tool for C2C
        admins. </p>

      <div class="flex mb-3 justify-center">
        <app-blue-btn class="w-fit" id="login-to-admin" (clickEmitter)="login()">Login to admin account</app-blue-btn>
      </div>

      <button class="text-sm block mx-auto">Login help</button>
    </div>
  </div>
</div>

<ng-template #placeholder>
  <div class="container h-[100vh] flex flex-col justify-center gap-9 text-center">
    <div class="w-full lg:w-2/3 2xl:w-full self-center">
      <mat-progress-bar mode="determinate" [value]="value"></mat-progress-bar>
    </div>

    <p class="text-sm text-white-greyText">Loading, please wait. Do not exit this page.</p>
  </div>
</ng-template>
