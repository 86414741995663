import { Injectable, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom, forkJoin, Subject, of } from "rxjs";
import { DropdownOption } from "src/app/shared/app-dropdown/app-dropdown.component";
import { UnsavedChangesModalComponent } from "src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component";
import { Buyer, BuyerResponse, BuyerStore, BuyerStoreContact, BuyerStoreContactClass, BuyerStoreDeliveryRestriction, BuyerStoreMakes, CreateBuyerContactObjectClass, CreateBuyerDeliveryObjectClass, CreateBuyerObjectClass, CreateBuyerStoreObjectClass, CreateBuyerUserObjectClass } from "../models/buyer.model";
import { BuyerUserClassDTO, UserRoles } from "../models/user.model";
import { BuyerService } from "./buyer.service";
import { InfoService } from "./infos.service";
import { SpinnerHandlerService } from "./overlay-spinner.service";
import { SnackbarService } from "./snackbar.service";
import { UserService } from "./user.service";
import { CoreService } from "./core.service";
import { DeliveryWindows } from "../models/info.model";
import { CarService } from "./car.service";


@Injectable({
  providedIn: 'root'
})

export class BuyerSyncService implements OnInit {
  public loadingInfo: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private isRouteChangeEvent: Subject<string> = new Subject();
  public isRouteChangeEvent$ = this.isRouteChangeEvent.asObservable();

  private currentTab: BehaviorSubject<string> = new BehaviorSubject('init');
  public currentTab$ = this.currentTab.asObservable();

  private saveFormEvent: Subject<MouseEvent> = new Subject();
  public saveFormEvent$ = this.saveFormEvent.asObservable();

  public buyerId: string | undefined;

  buyer: Buyer | undefined;
  stores: BuyerStore[] = [];
  storeMakes: BuyerStoreMakes[] = [];
  contacts: BuyerStoreContactClass[] = [];
  users: BuyerUserClassDTO[] = [];
  restrictions: BuyerStoreDeliveryRestriction[] = [];
  kams: DropdownOption[] = [];
  groups: DropdownOption[] = [];
  makes: DropdownOption[] = [];
  buyerKam: string = '';

  buyerGetResponse: BuyerResponse | undefined;

  createBuyerStore: CreateBuyerStoreObjectClass[] = [];

  public customerTypes: DropdownOption[] = [];
  public storesOptions: DropdownOption[] = [];
  public priorities: DropdownOption[] = [];
  public deliveryWindows: DeliveryWindows[] = [];

  constructor(private infoService: InfoService,
    private userService: UserService,
    private dialog: MatDialog,
    private buyerService: BuyerService,
    private router: Router,
    private carService: CarService,
    private spinnerService: SpinnerHandlerService,
    private snackbar: SnackbarService,
    private coreService: CoreService) { }

  ngOnInit() {
  }

  changeTab(page: string): void {
    this.isRouteChangeEvent.next(page);
  }

  setCurrentTab(page: string): void {
    this.currentTab.next(page);
  }

  emitSaveEvent(event: MouseEvent) {
    this.saveFormEvent.next(event);
  }

  async loadInfo() {
    forkJoin({
      priorities: this.infoService.getPriorities(),
      deliveryWindows: this.infoService.getDeliveryWindows(),
      kams: this.coreService.isAdmin ? this.userService.getKAMUsers() : of(null),
      groups: this.carService.getCarGroups(),
      makes: this.carService.getCarNomenclatorData('makes'),
      buyer: this.buyerId ? this.buyerService.getBuyer(this.buyerId) : of(null)
    }).subscribe(resp => {
      this.priorities = resp.priorities.map(p => { return { value: p.id, viewValue: p.priority } });
      this.makes = resp.makes.map(m => { return { value: m, viewValue: m } });
      this.deliveryWindows = resp.deliveryWindows;
      this.groups = resp.groups.filter(g => g.name.toLocaleLowerCase() != 'showroom').map(g => ({ viewValue: g.name, value: g.id }));

      if (resp.kams) {
        this.kams = resp.kams.map(k => ({ viewValue: k.username, value: k.kamId }));
      }

      if (resp.buyer) {
        this.buyer = resp.buyer.buyerCompanyDetails;
        this.buyerKam = resp.buyer.kamDetails.id;
        this.stores = resp.buyer.stores;
        this.buyerGetResponse = resp.buyer;
      }

      this.loadingInfo.next(false);
    })
  }

  async showUnsavedChangesModal(): Promise<boolean> {
    const dialogRef = this.dialog.open(
      UnsavedChangesModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
      }
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }

  checkStoreContacts(): boolean {
    return this.stores.some(s => {
      let users = this.users.filter(u => u.buyerStores!.some(st => st.store === s.tempId && st.roles.includes('contact')));

      let contacts = this.contacts.filter(c => c.store === s.tempId);

      return (users.length + contacts.length) === 0;
    });
  }

  createBuyer() {
    if (!this.stores.every(s => this.users.some(u => u.buyerStores?.find(us => us.store === s.tempId)))) {
      this.snackbar.negativeSentiment('At least one user should be assigned for each store');

      return;
    }

    if (this.checkStoreContacts()) {
      this.snackbar.negativeSentiment('There are stores with no contacts! Please add at least one!');

      return;
    }

    this.spinnerService.showProgressBar.next(true);

    let buyer = new CreateBuyerObjectClass(this.coreService.partialToFull(this.buyer!), this.buyerKam);

    buyer.stores = this.stores.map(s => new CreateBuyerStoreObjectClass(s));

    buyer.stores[0].defaultStore = true;

    this.users.forEach(u => {
      u.buyerStores.forEach(s => {
        let store = buyer.stores.find(st => st.tempId === s.store)!;

        store.users.push(new CreateBuyerUserObjectClass(u, s.roles.includes(UserRoles.Contact), s.contactRoleDetails!.accEmailFlag, s.contactRoleDetails!.carDeliveryEmailFlag, s.contactRoleDetails!.documentEmailFlag));
      })
    });

    this.restrictions.forEach(r => {
      let store = buyer.stores.find(s => s.tempId === r.store)!;

      store.deliveries.push(new CreateBuyerDeliveryObjectClass(this.coreService.partialToFull(r)));
    });

    this.contacts.forEach(c => {
      let store = buyer.stores.find(s => s.tempId === c.store)!;

      store.contacts.push(new CreateBuyerContactObjectClass(this.coreService.partialToFull(c)));
    });

    buyer.stores.forEach(s => delete (s.tempId));

    this.buyerService.createBuyer(buyer).subscribe({
      next: resp => {
        this.spinnerService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Buyer created successfully');

        this.router.navigate([`buyer/company/${resp.buyerId}`]);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinnerService.showProgressBar.next(false);
      }
    })
  }
}
