import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, firstValueFrom, forkJoin } from "rxjs";
import { AllOrderTasks, CreateOrderBodyClass, CurrenciesToEuro, Order, OrderCar, OrderOptions, OrderSettings, OrderTask, OrderTeammate, OrderUpdatedProfitResponse, SendOrderBodyClass } from "../models/order.model";
import { FormControl } from "@angular/forms";
import { TransportMatrix } from "src/app/features/settings/transport-matrix/transport-matrix.component";
import { ISellerResponse } from "../models/seller.model";
import { SettingsService } from "./settings.service";
import { CoreService } from "./core.service";
import { SnackbarService } from "./snackbar.service";
import { Router } from "@angular/router";
import { SpinnerHandlerService } from "./overlay-spinner.service";
import { UtilsService } from "./utils.service";
import { OrdersService } from "./orders.service";
import { ConstantsEnum, VATType } from "../models/info.model";
import { VINCheckDetails } from "../models/car.model";
import { DropdownOption } from "src/app/shared/app-dropdown/app-dropdown.component";
import { CompanyInvoice } from "src/app/features/settings/company-invoices/company-invoices.component";
import { OrderStatus } from "src/app/features/orders/orders-view/orders-view.component";

@Injectable({
  providedIn: 'root'
})

export class OrdersFlowSyncService {
  loading = new BehaviorSubject(true);

  orderId: string | undefined;
  order: Order | undefined;

  buyerId = '';
  clientId = '';

  orderSettings: OrderSettings | undefined;
  orderOptions: OrderOptions | undefined;

  batchId: string = '';
  vinDetails: VINCheckDetails[] = [];

  transportByCars2click = new FormControl(true);
  documentsToCompanyAddress = new FormControl(true);

  transportStatus: DropdownOption[] = [];
  orderSubStatuses: DropdownOption[] = [];
  carList: OrderCar[] = [];
  notes = '';

  invoiceCompanies: CompanyInvoice[] = [];

  private isRouteChangeEvent: Subject<string> = new Subject();
  public isRouteChangeEvent$ = this.isRouteChangeEvent.asObservable();

  private currentTab: BehaviorSubject<string> = new BehaviorSubject('init');
  public currentTab$ = this.currentTab.asObservable();

  private continueEvent: Subject<MouseEvent> = new Subject<MouseEvent>();
  public continueEvent$ = this.continueEvent.asObservable();

  private saveOrderEvent: Subject<MouseEvent> = new Subject();
  public saveOrderEvent$ = this.saveOrderEvent.asObservable();

  private sendOrderEvent: Subject<MouseEvent> = new Subject();
  public sendOrderEvent$ = this.sendOrderEvent.asObservable();

  private carsTableSubjectEvent = new Subject<string>();
  carsTableSubjectEvent$ = this.carsTableSubjectEvent.asObservable();

  private orderInfoUpdateSubjectEvent = new Subject<string>();
  orderInfoUpdateSubjectEvent$ = this.orderInfoUpdateSubjectEvent.asObservable();

  private orderTasksEvent = new Subject<AllOrderTasks>();
  orderTasksEvent$ = this.orderTasksEvent.asObservable();

  private orderProfitEvent = new Subject<OrderCar[]>();
  orderProfitEvent$ = this.orderProfitEvent.asObservable();

  dhlFee = 0;
  cocValue = 0;
  photoEditValue = 0;

  transportMatrix: TransportMatrix[] = [];
  currencies: CurrenciesToEuro[] = [];

  orderSeller: ISellerResponse[] = [];

  possibleTeammates: OrderTeammate[] | undefined;

  isOwner = new BehaviorSubject(false);

  orderStatus = '';

  orderTasks: OrderTask[] = [];

  constructor(private settingsService: SettingsService,
    private coreService: CoreService,
    private snackbar: SnackbarService,
    private router: Router,
    private spinner: SpinnerHandlerService,
    private utilsService: UtilsService,
    private ordersService: OrdersService) { }

  loadInfo() {
    forkJoin({
      transportMatrix: this.settingsService.getTransportMatrix(),
      constants: this.settingsService.getConstants()
    }).subscribe(resp => {
      this.transportMatrix = resp.transportMatrix;
      this.dhlFee = resp.constants.find(c => c.name === ConstantsEnum.DHL)!.value;
      this.cocValue = resp.constants.find(c => c.name === ConstantsEnum.COC)!.value;
      this.photoEditValue = resp.constants.find(c => c.name === ConstantsEnum.PhotoValue)!.value;

      if (this.orderId) {
        // this.loadOrder();
      } else {
        this.loading.next(false);
      }
    })
  }

  resetInfo() {
    this.loading.next(true);

  }

  changeTab(page: string): void {
    this.isRouteChangeEvent.next(page);
  }

  setCurrentTab(page: string): void {
    this.currentTab.next(page);
  }

  emitSendOrderEvent(event: MouseEvent) {
    this.sendOrderEvent.next(event);
  }

  emitSaveOrderEvent(event: MouseEvent) {
    this.saveOrderEvent.next(event);
  }

  emitContinueEvent(event: any) {
    this.continueEvent.next(event);
  }

  emitCarsTableUpdate() {
    this.carsTableSubjectEvent.next('');
  }

  emitOrderInfoUpdate() {
    this.orderInfoUpdateSubjectEvent.next('');
  }

  emitOrderTasksEvent(tasks: AllOrderTasks) {
    this.orderTasksEvent.next(tasks);
  }

  emitOrderProfitEvent(cars: OrderCar[]) {
    this.orderProfitEvent.next(cars);
  }

  deselectAllCarsFromList() {
    this.carList = this.carList.map(c => ({
      ...c,
      isSelected: false
    }))
  }

  sendOrder() {
    if (!this.orderSettings) {
      this.snackbar.negativeSentiment('Order settings has missing fields');
      return;
    }

    if (!this.orderOptions) {
      this.snackbar.negativeSentiment('Order options has missing fields');
      return;
    }

    if (this.carList.length < 1) {
      this.snackbar.negativeSentiment('No car added in order');
      return;
    }

    this.spinner.showProgressBar.next(true);

    let order = new CreateOrderBodyClass(this.coreService.partialToFull(this.orderSettings!), this.coreService.partialToFull(this.orderOptions), this.transportByCars2click.value!, this.batchId, this.notes);

    if (this.orderId) {
      order = new SendOrderBodyClass(this.coreService.partialToFull(this.orderSettings!), this.coreService.partialToFull(this.orderOptions), this.transportByCars2click.value!, this.batchId, this.notes, this.orderId!);
    }


    if (this.coreService.isAdmin) {
      order.kamId = this.orderSettings?.kam.kamId;
    }

    // let sellers = this.carList.map(c => c.sellerId!).filter((v, i, a) => a.indexOf(v) === i);

    // order.sellers = sellers.map(s => ({
    //   sellerId: s,
    //   cars: []
    // }))

    // this.carList.forEach(c => {
    //   let seller = order.sellers?.find(s => s.sellerId === c.sellerId);

    //   seller?.cars?.push(new CreateOrderCarClass(this.coreService.partialToFull(c)));
    // });

    this.ordersService.createOrder(order).subscribe({
      next: resp => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment(resp);
        this.router.navigate(['orders']);
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error.path.split('.').map((p: string) => this.splitPath(p)).join(' - '));
      }
    });
  }

  splitPath(path: string) {
    let splits = path.split('[');

    let value = splits[0];

    value = this.utilsService.splitWordByCamelcase(value);
    value = this.utilsService.capitalizeFirstLetter(value);

    if (splits[1]) {
      let no = splits[1].split(']', 1)[0];

      value = `${value} ${parseInt(no) + 1}`;
    }

    return value;
  }

  saveOrder() {
    if (!this.orderSettings) {
      this.snackbar.negativeSentiment('Order settings has missing fields');
      return;
    }

    if (!this.orderOptions) {
      this.snackbar.negativeSentiment('Order options has missing fields');
      return;
    }

    if (this.carList.length < 1) {
      this.snackbar.negativeSentiment('No car added in order');
      return;
    }

    this.spinner.showProgressBar.next(true);

    let order = new CreateOrderBodyClass(this.coreService.partialToFull(this.orderSettings!), this.coreService.partialToFull(this.orderOptions), this.transportByCars2click.value!, this.batchId, this.notes);

    if (this.orderId) {
      order = new SendOrderBodyClass(this.coreService.partialToFull(this.orderSettings!), this.coreService.partialToFull(this.orderOptions), this.transportByCars2click.value!, this.batchId, this.notes, this.orderId!);
    }

    if (this.coreService.isAdmin) {
      order.kamId = this.orderSettings?.kam.kamId;
    }

    // let sellers = this.carList.map(c => c.sellerId!).filter((v, i, a) => a.indexOf(v) === i);

    // order.sellers = sellers.map(s => ({
    //   sellerId: s,
    //   cars: []
    // }))

    // this.carList.forEach(c => {
    //   let seller = order.sellers?.find(s => s.sellerId === c.sellerId);

    //   seller?.cars?.push(new CreateOrderCarClass(this.coreService.partialToFull(c)));
    // });

    this.ordersService.saveCurrentOrder(order).subscribe({
      next: resp => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment(resp);
        this.router.navigate(['orders']);
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error.path.split('.').map((p: string) => this.splitPath(p)).join(' - '));
      }
    });
  }

  async loadSentOrder(orderId: string, isOrderFinished?: boolean): Promise<Order> {
    let resp = await firstValueFrom(forkJoin({
      order: isOrderFinished ? this.ordersService.getOrder(orderId) : this.ordersService.getCreatedOrder(orderId),
      invCompanies: this.settingsService.getCompanyInvoice(),
      // task: this.ordersService.getTasks(orderId),
      transportStatus: this.ordersService.getTransportStatuses(),
      subStatuses: this.ordersService.getOrderSubstatuses()
    }));

    this.transportStatus = resp.transportStatus.map(t => ({ value: t, viewValue: t }));
    this.orderSubStatuses = resp.subStatuses.map(t => ({ value: t.subStatus, viewValue: t.subStatus }));

    this.invoiceCompanies = resp.invCompanies;

    // this.orderTasks = this.alterOrderTasks(resp.task, resp.order);

    this.notes = resp.order.notes;
    this.transportByCars2click.setValue(resp.order.transportDetails.transportByC2C);
    this.documentsToCompanyAddress.setValue(resp.order.documentToCompanyAddress);

    this.order = resp.order;
    this.orderId = resp.order.orderId;

    this.orderStatus = resp.order.status;

    this.updateCarList();

    this.loading.next(false);

    return resp.order;
  }

  loadProfits(order: Order) {
    this.order = order;

    this.updateCarList();

    this.emitOrderProfitEvent(this.carList);
    this.emitOrderInfoUpdate();
  }

  alterOrderTasks(tasks: AllOrderTasks, order: Order) {
    function getCarDescription(order: Order, task: OrderTask) {
      let car = order.sellers?.find(s => s.details.id === task.sellerId)!.cars?.find(c => c.carMainInfoId === task.carMainInfoId!)!;
      return `${car.carConfig.make} ${car.carConfig.model}`;
    }

    let orderTasks = [];

    orderTasks = tasks.sellerTasks.map(t => ({
      ...t,
      sellerName: t.sellerId ? order.sellers!.find(s => s.details!.id === t.sellerId)?.details?.name : ''
    }));

    orderTasks = orderTasks.concat(tasks.carTasks.map(t => ({
      ...t,
      sellerName: t.sellerId ? order.sellers!.find(s => s.details.id === t.sellerId)?.details?.name : '',
      carDescription: t.sellerId ? getCarDescription(order, t) : ''
    })));

    return orderTasks;
  }

  updateCarList() {
    let cars = this.order!.sellers.reduce((arr: any, seller) => {
      return arr.concat(seller.cars!)
    }, []);

    cars.forEach((c: OrderCar) => {
      let seller = this.order!.sellers.find(s => s.cars?.find(car => car.carMainInfoId === c.carMainInfoId))!;

      c.carConfig.sellerName = seller.details.name;
      c.carConfig.seller = seller.details.id!;
      c.carConfig.locationName = seller.details.country.name;
      c.carPricing.invoiceSellingPrice = c.carPricing.invoiceSellingPrice;
      c.vatString = seller.details.vatStatus ? VATType.ExVAT : VATType.InclVAT;
    });

    this.carList = cars;
  }
}
