import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { BuyerStoreDeliveryRestriction } from 'src/app/core/models/buyer.model';
import { BuyerSyncService } from 'src/app/core/services/buyer-flow-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import hours from 'src/assets/json-files/hours.json';

interface WindowDeliveryForm {
  id: string,
  name: string,
  from: FormControl,
  to: FormControl,
  checkbox: boolean
}

@Component({
  selector: 'app-buyer-store-restrictions',
  templateUrl: './buyer-store-restrictions.component.html',
  styleUrls: ['./buyer-store-restrictions.component.scss']
})

export class BuyerStoreRestrictionsComponent implements OnInit {
  public subscriptions: Subscription = new Subscription();

  stores = this.buyerSyncService.stores;
  storesOptions = this.stores.map(s => ({ value: s.tempId!, viewValue: s.name! }));
  storeControl = new FormControl();

  hours = hours;
  countries: DropdownOption[] = this.coreService.countries.map(c => ({ value: c.id, viewValue: c.name }));
  notes: string = "";

  country = '';
  countryBankHolidaysControl = new FormControl();

  windows: WindowDeliveryForm[] = [];

  constructor(private buyerSyncService: BuyerSyncService,
    private snackbar: SnackbarService,
    private coreService: CoreService) { }

  ngOnInit(): void {
    this.windows = this.buyerSyncService.deliveryWindows.map(dw => ({
      id: dw.id,
      name: dw.period,
      from: new FormControl(),
      to: new FormControl(),
      checkbox: false
    }))

    this.storeControl.setValue(this.storesOptions[0].value);
    this.loadStore(this.storesOptions[0].value!);

    this.subscriptions.add(this.buyerSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.buyerSyncService.setCurrentTab(value);
      }
    ));

    this.subscriptions.add(this.buyerSyncService.saveFormEvent$.subscribe((value) => {
      if (this.checkWindows()) {
        this.snackbar.negativeSentiment('Complete hours for the periods selected');

        return;
      } else if (!this.checkStoreWindows()) {
        this.snackbar.negativeSentiment('Every store must have at least one delivery program saved');

        return;
      }

      this.buyerSyncService.createBuyer();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveRestriction() {
    if (this.checkWindows()) {
      this.snackbar.negativeSentiment('Complete hours for the periods selected');

      return;
    }

    let windows = this.windows.filter(w => w.checkbox);

    windows.forEach(w => {
      let restriction: BuyerStoreDeliveryRestriction = this.buyerSyncService.restrictions.find(r => r.store === this.storeControl.value && r.period.periodId === w.id)!;

      restriction.note = this.notes;
      restriction.from = `${w.from.value}:00:00`;
      restriction.to = `${w.to.value}:00:00`;

      this.snackbar.positiveSentiment('Restriction saved!');
    });
  }

  loadStore(storeId: string) {
    if (this.checkWindows()) {
      this.snackbar.negativeSentiment('Complete hours for the periods selected');

      return;
    }

    this.resetForm();

    let store = this.stores.find(s => s.tempId === storeId)!;

    this.country = this.countries.find(c => c.value === store.countryDetails.countryId)!.viewValue;

    let restrictions = this.buyerSyncService.restrictions.filter(r => r.store === storeId);

    restrictions.forEach(r => {
      let window = this.windows.find(w => w.id === r.period.periodId)!;

      window.checkbox = true;
      window.from.setValue(r?.from?.split(":")[0]);
      window.to.setValue(r?.to?.split(":")[0]);
    })

    this.countryBankHolidaysControl.setValue(store.countryBank);

    if (restrictions.length > 0) {
      this.notes = restrictions[0].note!;
    }
  }

  modifyWindow(event: MatCheckboxChange, periodId: string) {
    if (event.checked) {
      this.buyerSyncService.restrictions.push({
        store: this.storeControl.value,
        period: {
          period: '',
          periodId: periodId
        },
        from: '',
        to: '',
        note: ''
      });
    } else {
      this.buyerSyncService.restrictions.splice(this.buyerSyncService.restrictions.findIndex(r => r.period.periodId === periodId && r.store === this.storeControl.value), 1);

      let window = this.windows.find(w => w.id === periodId);
      window?.from.reset();
      window?.to.reset();
    }
  }

  resetForm() {
    this.country = '';
    this.countryBankHolidaysControl.reset();
    this.notes = "";

    this.resetAllWindows();
  }

  resetAllWindows() {
    this.windows.forEach(w => {
      w.checkbox = false;
      w.from.reset();
      w.to.reset();
    })
  }

  getStoreCountry(storeId: string) {
    return this.buyerSyncService.stores.find(s => s.tempId === storeId)?.countryDetails.countryId;
  }

  getCountryBankValue() {
    return this.countryBankHolidaysControl.value ? this.countryBankHolidaysControl.value : false;
  }

  checkWindows(): boolean {
    let windows = this.windows.filter(w => w.checkbox);

    return windows.some(w => !w.from.value || !w.to.value);
  }

  checkStoreWindows(): boolean {
    return this.buyerSyncService.stores.every(s => this.buyerSyncService.restrictions.find(r => r.store === s.tempId));
  }
}
