import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SellerUserDTO } from 'src/app/core/models/seller-models/seller-create.model';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import * as uuid from 'uuid';

interface UserStore {
  id: string,
  contactFlag: boolean,
  contactRoleDetails: {
    accEmailFlag: boolean,
    carPickupEmailFlag: boolean,
    documentEmailFlag: boolean,
  }
}

@Component({
  selector: 'app-seller-users',
  templateUrl: './seller-users.component.html',
  styleUrls: ['./seller-users.component.scss']
})
export class SellerUsersComponent implements OnInit {
  createMode: boolean = true;
  sellerId = this.b2bSyncService.sellerId;

  public saveFormSubscription: Subscription = new Subscription();
  public routeSubscription: Subscription = new Subscription();

  dateCreated = new Date();
  statusControl = new FormControl({ value: false, disabled: true });

  userDetailsForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    countryId: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: [''],
    tempId: new FormControl<string | undefined>(undefined)
  });

  contactForm = this.fb.group({
    accountingEmail: new FormControl<boolean>(false),
    carDeliveryEmail: new FormControl<boolean>(false),
    documentsEmail: new FormControl<boolean>(false),
  })

  stores: DropdownOption[] = this.b2bSyncService.sellerStores.map(s => ({ viewValue: s.name!, value: s.tempId! }));
  storeControl = new FormControl<string[]>([''], Validators.required);

  userDropdown: DropdownOption[] = this.b2bSyncService.sellerUsers.map(u => ({ viewValue: `${u.firstName} ${u.lastName}`, value: u.tempId! }));
  countries = this.coreService.countriesDropdown;
  userControl = new FormControl();
  isContact = false;

  selectedStoreControl = new FormControl();
  storesSelected: DropdownOption[] = [];

  usersStoresMap: Map<string, UserStore[]> = new Map();

  constructor(private b2bSyncService: B2BSellerSyncService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.routeSubscription = this.b2bSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.checkStoreUser() && (value === 'contacts' || value === 'restrictions')) {
          this.snackbar.negativeSentiment('At least one user should be assigned for each store');

          return;
        }

        if (this.createMode) {
          let goNext = await this.coreService.openUnsavedChangesModal();

          if (goNext) {
            this.b2bSyncService.sellerUsers.splice(this.b2bSyncService.sellerUsers.length - 1, 1);

            this.b2bSyncService.setCurrentTab(value);
          }
        } else {
          this.b2bSyncService.setCurrentTab(value);
        }
      }
    )

    this.saveFormSubscription = this.b2bSyncService.saveFormEvent$.subscribe((value) => {
      let saved = this.saveUser();

      if (this.checkStoreUser()) {
        this.snackbar.negativeSentiment('At least one user should be assigned for each store');

        return;
      }

      if (saved) this.b2bSyncService.setCurrentTab('contacts');
    });

    if (this.userDropdown.length > 0) {
      this.userControl.setValue(this.userDropdown[0].value);

      this.b2bSyncService.sellerUsers.forEach(u => {
        this.usersStoresMap.set(u.tempId!, u.stores!.map(s => ({
          id: s.store,
          contactFlag: s.roles.includes('contact'),
          contactRoleDetails: s.contactRoleDetails
        })));
      });

      this.patchUserForm(this.b2bSyncService.sellerUsers.find(u => u.tempId === this.userDropdown[0].value)!);
    } else {
      this.userDetailsForm.disable();
      this.storeControl.disable();
      this.createMode = false;
    }
  }

  ngOnDestroy() {
    this.saveFormSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  saveUser() {
    if (this.userDetailsForm.invalid || this.userDetailsForm.disabled) {
      this.snackbar.negativeSentiment('Complete all required fields!');
      return false;
    }

    let index = this.b2bSyncService.sellerUsers.findIndex(u => u.tempId === this.userControl.value!)!;

    let stores = this.usersStoresMap.get(this.userControl.value!)!;

    if (this.selectedStoreControl.value) {
      stores[stores.findIndex(s => s.id === this.selectedStoreControl.value!)] = {
        id: this.selectedStoreControl.value!,
        contactFlag: this.isContact,
        contactRoleDetails: {
          accEmailFlag: this.contactForm.controls.accountingEmail.value!,
          carPickupEmailFlag: this.contactForm.controls.carDeliveryEmail.value!,
          documentEmailFlag: this.contactForm.controls.documentsEmail.value!,
        }
      }
    }

    this.b2bSyncService.sellerUsers[index] = new SellerUserDTO(this.userDetailsForm.value, false, false, false, false, this.userControl.value!, stores!.map(s => ({
      store: s.id,
      roles: s.contactFlag ? ['contact', 'user'] : ['user'],
      contactRoleDetails: s.contactRoleDetails
    })))

    this.loadUserDropdown();
    this.createMode = false;

    this.snackbar.positiveSentiment('User saved');

    return true;
  }

  loadUserDropdown() {
    this.userDropdown = this.b2bSyncService.sellerUsers.map(c => ({ viewValue: `${c.firstName} ${c.lastName}`, value: c.tempId! }));
  }

  async changeUser(userId: string) {
    if (this.createMode) {
      const currentUserId = this.userControl.value;

      let resp = await this.coreService.openUnsavedChangesModal();

      if (resp) {
        if (currentUserId) this.b2bSyncService.sellerUsers.splice(this.b2bSyncService.sellerUsers.findIndex(s => s.tempId === currentUserId), 1);

        this.loadUserDropdown();

        this.continueChangeUserFlow(userId);
      } else {
        this.userControl.setValue(currentUserId);
      }
    } else {
      this.continueChangeUserFlow(userId);
    }
  }

  continueChangeUserFlow(userId: string) {
    let user = this.b2bSyncService.sellerUsers.find(c => c.tempId === userId);

    this.patchUserForm(user!);
  }

  patchUserForm(user: SellerUserDTO) {
    this.userDetailsForm.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      countryId: user.countryId
    });

    this.statusControl.setValue(true);

    this.storeControl.setValue(user.stores!.map(s => s.store));
    this.storesSelected = this.stores.filter(s => this.storeControl.value!.includes(s.value));

    this.userDetailsForm.controls.tempId.setValue(user.tempId);

    let userStores = this.usersStoresMap.get(user.tempId!);

    if (userStores!.length > 0) {
      this.selectedStoreControl.setValue(userStores![0].id);

      this.patchContactForm(userStores![0]);
    }

    this.createMode = false;
  }

  addUser() {
    if (this.createMode) return this.snackbar.negativeSentiment('You already started to create a new user!');

    if (this.userDropdown.length === 0) {
      this.userDetailsForm.enable();
      this.storeControl.enable();
    }

    this.createMode = true;

    const tempId = uuid.v4();

    this.b2bSyncService.sellerUsers.push(new SellerUserDTO(this.userDetailsForm.value, false, false, false, false, tempId));

    this.userDropdown.push({ value: tempId, viewValue: 'New User' });

    this.userDetailsForm.reset({ phoneNumber: '' });
    this.contactForm.reset({ accountingEmail: false, carDeliveryEmail: false, documentsEmail: false });
    this.storeControl.reset();
    this.selectedStoreControl.reset();

    this.storesSelected = [];

    this.isContact = false;

    this.userControl.setValue(tempId);

    this.usersStoresMap.set(tempId, []);

    this.userDetailsForm.controls.tempId.setValue(tempId);
  }

  //synchronzies stores between the ones allocated and the contact tab
  changeStoresSelected() {
    setTimeout(() => {
      this.storesSelected = this.stores.filter(s => this.storeControl.value!.includes(s.value));

      if (this.selectedStoreControl.value && this.storesSelected.length > 0 && !this.storesSelected.find(s => s.value === this.selectedStoreControl.value!)) {
        this.selectedStoreControl.setValue(this.storesSelected[0].value);

        this.patchContactForm(this.usersStoresMap.get(this.userControl.value!)!.find(s => s.id === this.storesSelected[0].value)!);
      } else if (this.selectedStoreControl.value && !this.storesSelected.find(s => s.value === this.selectedStoreControl.value!)) {
        this.isContact = false;

        this.contactForm.reset({
          accountingEmail: false,
          documentsEmail: false,
          carDeliveryEmail: false
        });
      }

      let oldStores = this.usersStoresMap.get(this.userControl.value!)!;
      let newStores: UserStore[] = [];

      this.storesSelected.forEach(s => {
        let store = oldStores.find(ss => s.value === ss.id);

        if (store) {
          newStores.push(store);
        } else {
          newStores.push({
            id: s.value,
            contactFlag: false,
            contactRoleDetails: {
              accEmailFlag: false,
              documentEmailFlag: false,
              carPickupEmailFlag: false
            }
          });
        }

        this.usersStoresMap.set(this.userControl.value!, newStores);
      });
    }, 0);
  }

  patchContactForm(store: UserStore) {
    this.isContact = store.contactFlag;

    this.contactForm.patchValue({
      accountingEmail: store.contactFlag ? store.contactRoleDetails.accEmailFlag : false,
      carDeliveryEmail: store.contactFlag ? store.contactRoleDetails.carPickupEmailFlag : false,
      documentsEmail: store.contactFlag ? store.contactRoleDetails.documentEmailFlag : false,
    });
  }

  //saves current contact info for the selected store if any
  changeStoreSelected(storeId: string) {
    let userStores = this.usersStoresMap.get(this.userControl.value!)!;

    if (this.selectedStoreControl.value!) {
      userStores[userStores.findIndex(s => s.id === this.selectedStoreControl.value!)] = {
        id: this.selectedStoreControl.value!,
        contactFlag: this.isContact,
        contactRoleDetails: {
          accEmailFlag: this.contactForm.controls.accountingEmail.value!,
          carPickupEmailFlag: this.contactForm.controls.carDeliveryEmail.value!,
          documentEmailFlag: this.contactForm.controls.documentsEmail.value!,
        }
      };
    }

    this.patchContactForm(userStores.find(s => s.id === storeId)!);
  }

  checkStoreUser() {
    return !this.b2bSyncService.sellerStores.every(s => this.b2bSyncService.sellerUsers.some(u => u.stores?.find(us => us.store === s.tempId)));
  }
}
