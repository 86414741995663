import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Buyer, BuyerStore, BuyerStoreContact, LoggedKAMBuyer } from "../models/buyer.model";
import { CreateStaffUserBody, KAMBuyers, KAMSearch, User, UserInfo } from "../models/user.model";

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(private http: HttpClient) { }

  public createUser(user: User): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}create-user`, user);
  }

  public createStaffUser(user: CreateStaffUserBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}create-user`, user);
  }

  public getKAMUsers(): Observable<KAMSearch[]> {
    return this.http.post<KAMSearch[]>(`${environment.backendApiUrl}kams`,{});
  }

  public getUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${environment.backendApiUrl}user-info`);
  }

  public getBuyerUsers(buyerId: string): Observable<User[]> {
    return this.http.get<User[]>(`${environment.backendApiUrl}get-buyer-clients/${buyerId}`);
  }

  public getSellerUsers(sellerId: string): Observable<User[]> {
    return this.http.get<User[]>(`${environment.backendApiUrl}get-seller-clients/${sellerId}`);
  }

  public getKAMBuyers(): Observable<KAMBuyers[]> {
    return this.http.get<KAMBuyers[]>(`${environment.backendApiUrl}get-kam-buyers`);
  }

  public getKAMBuyerCompanyDetails(buyerId: string): Observable<Buyer> {
    return this.http.post<Buyer>(`${environment.backendApiUrl}get-kam-buyer-company-details`, { buyerId: buyerId });
  }

  public getKAMBuyerCompanyUsers(buyerId: string): Observable<User[]> {
    return this.http.post<User[]>(`${environment.backendApiUrl}get-kam-buyer-company-users`, { buyerId: buyerId });
  }

  public getKAMBuyerCompanyUserStores(buyerId: string, clientId: string): Observable<BuyerStore[]> {
    return this.http.post<BuyerStore[]>(`${environment.backendApiUrl}get-kam-buyer-company-stores-by-user`, { buyerId: buyerId, clientId: clientId });
  }

  public getKAMBuyerCompanyStoreContact(storeId: string): Observable<BuyerStoreContact[]> {
    return this.http.post<BuyerStoreContact[]>(`${environment.backendApiUrl}get-kam-buyer-company-stores-user-contacts`, { storeId: storeId });
  }

  public getLoggedKAMBuyers(): Observable<LoggedKAMBuyer[]> {
    return this.http.get<LoggedKAMBuyer[]>(`${environment.backendApiUrl}get-logged-kam-buyer-companies`);
  }

  public getLoggedKAMBuyerCompanyDetails(buyerId: string): Observable<Buyer> {
    return this.http.post<Buyer>(`${environment.backendApiUrl}get-logged-kam-buyer-company-details`, { buyerId: buyerId });
  }

  public getLoggedKAMBuyerCompanyUserStores(buyerId: string, clientId: string): Observable<BuyerStore[]> {
    return this.http.post<BuyerStore[]>(`${environment.backendApiUrl}get-logged-kam-buyer-stores-by-users`, { buyerId: buyerId, clientId: clientId });
  }

  public getLoggedKAMBuyerCompanyStoreContact(storeId: string): Observable<BuyerStoreContact[]> {
    return this.http.post<BuyerStoreContact[]>(`${environment.backendApiUrl}get-logged_kam-buyer-company-stores-user-contacts`, { storeId: storeId });
  }

  public addToCart(carMainInfoId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}add-to-buy-list`, { carMainInfoId: carMainInfoId });
  }
}
