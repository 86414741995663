import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderCar } from 'src/app/core/models/order.model';
import { CoreService } from 'src/app/core/services/core.service';

export interface OrderSelectCarsDocumentsDetailsModalData {
  car: OrderCar,
  documentToCompanyAddress: boolean
}

@Component({
  selector: 'app-order-select-cars-documents-details-modal',
  templateUrl: './order-select-cars-documents-details-modal.component.html',
  styleUrls: ['./order-select-cars-documents-details-modal.component.scss']
})
export class OrderSelectCarsDocumentsDetailsModalComponent implements OnInit {
  car = this.data.car;
  countries = this.coreService.countriesDropdown;

  form = this.fb.group({
    country: new FormControl<string>({ value: '', disabled: true }),
    city: [this.car.documentsDetails.city],
    address: [this.car.documentsDetails.address],
    zipcode: [this.car.documentsDetails.postalCode],
    firstName: [this.car.documentsDetails.contactDetails?.firstName],
    lastName: [this.car.documentsDetails.contactDetails?.lastName],
    email: [this.car.documentsDetails.contactDetails?.email],
    phone: [this.car.documentsDetails.contactDetails?.phone],
  })

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<OrderSelectCarsDocumentsDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderSelectCarsDocumentsDetailsModalData,
    private coreService: CoreService) { }

  ngOnInit(): void {
    if (this.data.documentToCompanyAddress) {
      this.form.disable();
    }
  }

  update() {
    // this.car.documentsDetails = {
    //   ...this.car.documentsDetails,
    //   city: this.form.controls.city.value!,
    //   address: this.form.controls.address.value!,
    //   postalCode: this.form.controls.zipcode.value!,
    //   contactDetails: {
    //     firstName: this.form.controls.firstName.value!,
    //     lastName: this.form.controls.lastName.value!,
    //     email: this.form.controls.email.value!,
    //     phone: this.form.controls.phone.value!,
    //     fullName: `${this.form.controls.firstName.value} ${this.form.controls.lastName.value}`
    //   }
    // }

    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
