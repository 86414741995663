<div class="flex flex-col gap-9">
  <div class="flex justify-between items-center">
    <p class="font-bold text-xl">Add car group</p>
    <fa-icon [icon]="['far','xmark']" class="cursor-pointer" (click)="close()"></fa-icon>
  </div>

  <app-input id="group-name" [control]="nameControl" label="Name" class="w-1/2" (keyPressEmitter)="keyPress($event)">
  </app-input>

  <primary-btn id="add-car-group-modal" class="w-1/4" btnText="Add car group"
    (clickEmitter)="saveCarGroup()"></primary-btn>
</div>
