<div class="flex flex-col gap-9 opacity-50" *ngIf="!(loading | async);else placeholder">
  <app-main-content-layout>
    <div class="flex flex-wrap gap-6 text-sm">
      <div class="py-2 px-3 text-white bg-green-100 rounded-2xl">
        <p>Total profit: €{{totalProfit | number}}</p>
      </div>
      <div class="py-2 px-3 text-green-100 bg-green-300 rounded-2xl">
        <p>IST profit: €{{istProfit | number}}</p>
      </div>
      <div class="py-2 px-3 text-green-100 bg-green-300 rounded-2xl">
        <p>KAMs profit: €{{kamsProfit | number}}</p>
      </div>
    </div>

    <div class="grid grid-cols-6 gap-6 sliders mt-4" *ngFor="let mate of teammates; let i = index" [id]="'slider-' + i">
      <div class="col-span-2 flex items-center gap-3">
        <mat-checkbox [disabled]="true" [id]="'main-actor-'+i+'-checkbox'" [attr.data-cy]="'main-actor-'+i+'-checkbox'"
          [(ngModel)]="mate.mainActor" (change)="changeMainActor($event,mate.userId)">
          <p class="text-sm">Main actor</p>
        </mat-checkbox>

        <app-read-only-input class="w-full" label="Select seller KAM">
          {{mate.username}}
        </app-read-only-input>
      </div>


      <div class="relative">
        <input [disabled]="true" [id]="'profit-percentage-'+i+'-input'" [attr.data-cy]="'profit-percentage-'+i+'-input'"
          class="w-full max-w-[6.25rem] !border !border-white-300 !p-3 !rounded" autocomplete="off" type="number"
          [(ngModel)]="mate.credit" [min]="0" [max]="100" (ngModelChange)="changePercentage(mate,i)">

        <p class="absolute top-3 text-lg left-14">%</p>
      </div>

      <mat-slider [disabled]="true" [id]="'profit-value-'+i+'-slider'" [attr.data-cy]="'profit-value-'+i+'-slider'"
        [(ngModel)]="mate.profit" [min]="0" [max]="kamsProfit" class="col-span-2 w-full"
        (valueChange)="changeProfit($event!,i)" (input)="mate.profit = $event.value!">
      </mat-slider>

      <div class="grid grid-cols-3 gap-3 items-center">
        <div class="col-span-2 bg-white-500 border border-white-500 rounded py-3 relative text-center profit h-fit">
          <p>€{{mate.profit | number}}</p>
        </div>
        <div [id]="'remove-teammate-'+i+'-btn'" [attr.data-cy]="'remove-teammate-'+i+'-btn'"
          class="w-1/2  rounded py-3 relative text-center" *ngIf="!mate.mandatory">
          <fa-icon [icon]="['far','xmark']"></fa-icon>
        </div>
      </div>
    </div>

    <div class="flex gap-6">
      <app-blue-btn [disableBtn]="true" id="add-teammate" class="w-fit" (clickEmitter)="addTeammate()">Add
        teammate</app-blue-btn>
      <primary-btn [disableBtn]="true" id="save-profits" (clickEmitter)="saveProfits()">Save profits</primary-btn>
    </div>
  </app-main-content-layout>

  <app-table [displayedColumns]="displayedColumns" [headersMap]="headers" [data]="cars"></app-table>
</div>

<ng-template #placeholder>
  <app-main-content-placeholder class="mb-6"></app-main-content-placeholder>
  <app-table-placeholder></app-table-placeholder>
</ng-template>
