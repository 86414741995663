<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 pr-6">
  <div class="flex justify-between items-center">
    <p class="text-2xl">Manually add cars</p>

    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close()"></fa-icon>
  </div>

  <div class="flex justify-between gap-6">
    <div class="grid grid-cols-3 w-3/4 gap-9">
      <app-input id="regNo" [control]="regNoControl" [label]="'Registration number'"
        placeholder="Search by registration number"></app-input>

      <app-dropdown id="make-filter" [control]="makeControl" [options]="makes" label="Make" placeholder="Select make"
        (emitOnChangeSelect)="selectMake($event)" [resetValue]="true"></app-dropdown>

      <app-dropdown id="model-filter" [control]="modelControl" [options]="models" label="Model"
        placeholder="Select model" (emitOnChangeSelect)="selectModel($event)" [resetValue]="true"></app-dropdown>

      <app-input id="vin-filter" [control]="vinControl" label="VIN" placeholder="Search by VIN"></app-input>
      <app-input id="id-filter" [control]="idControl" label="ID" placeholder="Search by ID"></app-input>
    </div>

    <div class="flex flex-wrap gap-6 pr-4">
      <primary-btn class="w-full" id="add-cars" [blueBtn]="true" (clickEmitter)="addCars()">Add cars</primary-btn>
      <secondary-btn class="w-full" id="reset-filters" (clickEmitter)="resetFilters()">Reset filters</secondary-btn>
    </div>
  </div>

  <app-table #carsTable [displayedColumns]="displayColumn" [headersMap]="headers" [data]="cars"
    *ngIf="!(loading | async);else placeholder" [hidePaginator]="true"></app-table>

  <mat-paginator #paginator [length]="noOfCars" [pageSizeOptions]="[5, 10, 20]" (page)="pageChange()">
  </mat-paginator>
</div>

<ng-template #placeholder>
  <div class="flex flex-col gap-2">
    <div class="h-9 w-full bg-white-100"></div>

    <div class="h-9 w-full placeholder-animation" *ngFor="let i of [].constructor(5)"></div>
  </div>
</ng-template>
