import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-secondary-button-filled',
  templateUrl: './secondary-button-filled.component.html',
  styleUrls: ['./secondary-button-filled.component.scss']
})
export class SecondaryButtonFilledComponent implements OnInit {
  @Input() btnText: string = '';
  @Input() id = "id";
  @Input() disableBtn: boolean = false;

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  emitClick(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }
}
