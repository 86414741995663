import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoles } from 'src/app/core/models/user.model';
import { CoreService } from 'src/app/core/services/core.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl!: string;

  value = Math.floor(Math.random() * (50 - 30) + 30);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private coreService: CoreService
  ) { }

  ngOnInit() {
    this.activatedRoute.fragment.subscribe(async fragment => {
      if (fragment) {
        const urlParams = new URLSearchParams(fragment!);

        if (urlParams.get('id_token')) {
          localStorage.setItem('token', urlParams.get('id_token')!);

          setTimeout(() => {
            this.value += Math.floor(Math.random() * (50 - 30) + 30);
          }, 1000);

          this.coreService.loadInfo().then(user => {
            if (user && (user.role === UserRoles.ADMIN || user.role === UserRoles.KAM)) {
              if (localStorage.getItem('redirectURL')) {
                let redirect = localStorage.getItem('redirectURL');

                localStorage.removeItem('redirectURL');

                this.router.navigate([redirect]);
              } else {
                this.router.navigate(['']);
              }
            } else {
              this.router.navigate(['restricted']);
            }
          });
        }
      }
    })
  }
}
