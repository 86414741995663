import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderProcessInvoiceCompaniesModalComponent } from './order-process-invoice-companies-modal/order-process-invoice-companies-modal.component';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-process-invoice-companies',
  templateUrl: './order-process-invoice-companies.component.html',
  styleUrls: ['./order-process-invoice-companies.component.scss']
})
export class OrderProcessInvoiceCompaniesComponent implements OnInit {
  headersMap: TableHeaderMap[] = [
    {
      value: 'carConfig.sellerName',
      tableView: 'seller'
    },
    {
      value: 'carPricing.invoiceSellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatString',
      tableView: 'vat'
    },
    {
      value: 'carConfig.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carConfig.make',
      tableView: 'make'
    },
    {
      value: 'carConfig.model',
      tableView: 'model'
    },
    {
      value: 'carConfig.vin',
      tableView: 'vin'
    },
    {
      value: 'carConfig.mileage',
      tableView: 'mileage'
    },
  ]

  displayedColumns = ['invCompany', ...this.headersMap.map(h => h.value)];

  cars = this.orderSyncService.carList;

  buyerName = this.orderSyncService.order!.buyer.name;

  invoiceCompanies = this.orderSyncService.invoiceCompanies.map(c => ({ value: c.id!, viewValue: c.name }));

  disableContinueBtn = false;

  constructor(private orderSyncService: OrdersFlowSyncService,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private spinner: SpinnerHandlerService,
    private orderService: OrdersService,
    private router: Router) { }

  ngOnInit() {
    if (this.cars.every(c => c.invoicingCompany.id)) {
      this.disableContinueBtn = true;

      this.displayedColumns[0] = 'invoicingCompany.name'

      this.headersMap.unshift({
        value: 'invoicingCompany.name',
        tableView: 'Invoice company'
      });
    }
  }

  isNumber(val: any): boolean {
    return _.isNumber(val);
  }

  getValue(element: any, path: string): string {
    return _.get(element, path.split('.'))
  }

  continue(event: MouseEvent) {
    if (this.cars.some(c => !c.invoicingCompany.id)) {
      this.snackbar.negativeSentiment('Select invoice company for all cars');

      return;
    }

    let invoiceCompaniesIds = this.cars.map(c => c.invoicingCompany.id);


    let distinctIds = invoiceCompaniesIds.filter((id, index) => { return invoiceCompaniesIds.indexOf(id) === index });

    if (distinctIds.length > 1) {
      const dialogRef = this.dialog.open(
        OrderProcessInvoiceCompaniesModalComponent, {
        width: '900px',
        autoFocus: false,
        data: {
          companies: distinctIds.map(c => this.invoiceCompanies.find(inv => inv.value === c)!.viewValue)
        }
      });

      dialogRef.afterClosed().subscribe(resp => {
        if (resp) {
          this.updateCarsInvoiceCompanies();
        }
      });
    } else {
      this.updateCarsInvoiceCompanies();
    }
  }

  updateCarsInvoiceCompanies() {
    this.spinner.showProgressBar.next(true);

    let cars = this.cars.map(c => ({
      carMainInfoId: c.carMainInfoId,
      invoicingCompanyId: c.invoicingCompany.id
    }));

    this.orderService.updateCarsInvoicingCompany(this.orderSyncService.orderId!, cars).subscribe({
      next: response => {
        if (response.newOrders) {
          this.spinner.showProgressBar.next(false);

          this.snackbar.positiveSentiment(`${response.newOrders.length} new orders have been created`);

          this.router.navigate(['orders']);
        } else {
          this.orderSyncService.order!.sellers.forEach(s => {
            s.cars.forEach(c => {
              c.invoicingCompany.id = this.cars.find(car => car.carMainInfoId === c.carMainInfoId)!.invoicingCompany.id;
              c.invoicingCompany.name = this.invoiceCompanies.find(inv => inv.value === c.invoicingCompany.id)!.viewValue;
            });
          });

          this.spinner.showProgressBar.next(false);

          this.orderSyncService.emitContinueEvent(null);
        }
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    });
  }
}
