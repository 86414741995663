import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public page: string = 'transport-matrix';

  loading = new BehaviorSubject<boolean>(true);

  routes = [
    // {
    //   viewValue: "Dealer mapping",
    //   value: "dealer-mapping"
    // },
    {
      viewValue: "Transport Matrix",
      value: "transport-matrix"
    },
    {
      viewValue: "Car groups",
      value: "car-groups"
    },
    {
      viewValue: "VIN check",
      value: "vin-check"
    },
    {
      viewValue: "Country Holiday",
      value: "country-holiday"
    },
    {
      viewValue: "Company Invoices",
      value: "company-invoices"
    }
  ];

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private coreService: CoreService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['page']) {
        this.page = params['page'];
      }
    });

    if (this.coreService.user!.superAdmin) {
      this.routes = [...this.routes,
      {
        viewValue: "Manage cars inventory",
        value: "manage-cars"
      },
      {
        viewValue: "Create staff users",
        value: "create-users"
      }
      ]
    }

    this.loading.next(false);
  }

  navigateTo(page: string) {
    this.router.navigate([`settings/${page}`])
  }
}
