import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './app-checkbox.component.html',
  styleUrls: ['./app-checkbox.component.scss']
})
export class AppCheckboxComponent implements OnInit {
  @Input() checkboxValue: boolean | undefined;
  @Input() disabledCheckbox = false;

  @Output() checkboxValueChange = new EventEmitter<boolean>();

  @Input() label: string | undefined;
  @Input() componentId = 'id';

  constructor() { }

  ngOnInit(): void {
  }

}
