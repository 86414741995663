import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment';
import { debounceTime, tap, takeUntil, Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { CarBusinesscaseRequestBody, CarDetails, CarGroupJourney, CreateManualCarBody, IdentifyCarRequestClass, IdentifyCarResponse } from 'src/app/core/models/car.model';
import { VATType } from 'src/app/core/models/info.model';
import { ISellerResponse, SellerSearch } from 'src/app/core/models/seller.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { vinValidator } from 'src/app/features/car-view/car-details/car-details.component';
import { CarIdentifiedModalComponent, CarIdentifiedModalComponentCar } from 'src/app/features/car-view/car-details/car-identified-modal/car-identified-modal.component';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import hours from 'src/assets/json-files/hours.json';

@Component({
  selector: 'app-car-manual-upload',
  templateUrl: './car-manual-upload.component.html',
  styleUrls: ['./car-manual-upload.component.scss']
})
export class CarManualUploadComponent implements OnInit {
  loadingInfo = this.carFlowSyncService.loadingInfo;

  isFromDuplicate = false;

  //region  dropdowns_data
  carMakes: DropdownOption[] = [];
  carModels: DropdownOption[] = [];
  carManufactureYears: DropdownOption[] = Array.from({ length: (new Date().getFullYear() - 1998) }, (_, i) => i + 2000).reverse().map(y => ({ value: y.toString(), viewValue: y.toString() }));
  carVariants: DropdownOption[] = [];
  carTrims: DropdownOption[] = [];

  carFuelTypes: DropdownOption[] = [];
  carGearboxes: DropdownOption[] = [];
  carDrivingWheels: DropdownOption[] = [];
  carBodyTypes: DropdownOption[] = [];
  carColors: DropdownOption[] = [];
  journeyGroups: DropdownOption[] = [];
  hours = hours;
  countries: DropdownOption[] = this.coreService.countriesDropdown;
  //endregion

  carTaxes: { value: number, country: string }[] = [];
  carAlternatives: IdentifyCarResponse[] = [];
  carId: string | undefined;


  //region forms_for_car_data
  baseCarDataForm = this.fb.group({
    make: new FormControl<string | null>(null, Validators.required),
    model: new FormControl<string | null>(null, Validators.required),
    fuelType: ['', Validators.required],
    gearbox: ['', Validators.required],
    variant: new FormControl<string | null>(null, Validators.required),
    trim: new FormControl<string | null>(null),
    idAdac: new FormControl<number | null>(null),
  })

  technicalDataForm = this.fb.group({
    engineVolume: new FormControl<number | null>(null),
    enginePower: new FormControl<number | null>(null, Validators.required),
    bodyType: new FormControl<string | null>(null, Validators.required),
    drivingWheels: new FormControl<string | null>(null),
    co2NEDC: new FormControl<number | null>(null),
    co2WLTP: new FormControl<number | null>(null),
  })

  pricesForm = this.fb.group({
    buyingPrice: new FormControl<number | null>(null, Validators.required),
    minSellingPrice: new FormControl<number | null>(null, Validators.required),
    damages: new FormControl<number | null>(null),
    vatStatus: new FormControl<boolean | null>(null, Validators.required),
  });

  registrationForm = this.fb.group({
    firstReg: new FormControl<any>(null),
    mileage: new FormControl<number | null>(null, Validators.required),
    color: new FormControl<string | null>(null),
    seats: new FormControl<number | null>(null),
    doors: new FormControl<number | null>(null),
    manufactureYear: new FormControl('', Validators.required)
  });


  identificationForm = this.fb.group({
    vin: new FormControl<string | null>(null, [vinValidator()]),
    cocFromSeller: new FormControl<boolean>(false),
    regNo: new FormControl<string | null>(null),
    adLink: new FormControl<string | null>(null),
    otherInfo: new FormControl<string | null>(null),
    location: new FormControl<string | null>(null, Validators.required),
    availableFrom: new FormControl<any>(null, Validators.required)
  });
  //endregion

  b2bRecommendedPrice = 0;

  vatTypes = VATType;

  statusControl = new FormControl<string | undefined>({ value: '', disabled: true });
  sourceControl = new FormControl<string | undefined>({ value: '', disabled: true });

  inactiveFrom: Date | undefined;

  journeysGroupForm = new FormArray<FormControl>([]);
  newJourneyGroupCounter = 0;

  destroyed = new Subject<void>();
  existsResults = new BehaviorSubject<boolean>(false);
  resultItems = new BehaviorSubject<ISellerResponse[]>([]);

  sellerControl = new FormControl("", Validators.required);
  selectedSeller: ISellerResponse | undefined;

  autocompletePrice: number | undefined = undefined;

  @ViewChild('resetCarModal') resetCarModalEqTemplate: TemplateRef<any> | undefined;

  constructor(protected fb: FormBuilder,
    protected coreService: CoreService,
    protected carFlowSyncService: CarFlowSyncService,
    protected spinnerHandlerService: SpinnerHandlerService,
    protected carService: CarService,
    protected snackbar: SnackbarService,
    protected dialog: MatDialog,
    protected router: Router,
    protected utilsService: UtilsService,
    protected sellerService: SellerService) { }

  ngOnInit(): void {
    let loadingInfo$ = this.loadingInfo.asObservable();

    loadingInfo$.subscribe(resp => {
      this.carMakes = this.carFlowSyncService.carMakes;
      this.carBodyTypes = this.carFlowSyncService.carBodyTypes;
      this.carDrivingWheels = this.carFlowSyncService.carDrivingWheels;
      this.carColors = this.carFlowSyncService.carColors;
      this.journeyGroups = this.carFlowSyncService.carGroups;
    });

    this.carFlowSyncService.loadInfo();

    if (this.carFlowSyncService.carDuplicateBody) {
      this.isFromDuplicate = true;

      this.sellerService.getSeller(this.carFlowSyncService.carDuplicateBody.seller).subscribe({
        next: resp => {
          this.sellerControl.setValue(resp.sellerCompanyDetails.name);
          this.selectedSeller = resp;
          this.carFlowSyncService.selectedSeller = resp;
        },
        error: err => {
          this.snackbar.negativeSentiment(err.error);
        }
      });

      this.loadDuplicateCar(this.carFlowSyncService.carDuplicateBody);
    } else {
      this.technicalDataForm.disable();
      this.registrationForm.disable();
      this.identificationForm.disable();
      this.pricesForm.disable();
    }

    this.sellerControl.valueChanges.pipe(debounceTime(400), tap(() => { }), takeUntil(this.destroyed))
      .subscribe((value) => {
        if (value) {
          this.searchSeller(value);
        } else {
          this.resultItems.next([]);
        }
      });

    this.resultItems.pipe(tap((result) => {
      this.existsResults.next(result.length > 0);
    })).subscribe();
  }

  ngOnDestroy() {
    this.carFlowSyncService.loadingInfo.next(true);
  }

  createCar() {
    if (this.baseCarDataForm.invalid || this.technicalDataForm.invalid || this.pricesForm.invalid || this.registrationForm.invalid || this.identificationForm.invalid || !this.selectedSeller) {
      this.baseCarDataForm.markAllAsTouched();
      this.technicalDataForm.markAllAsTouched();
      this.pricesForm.markAllAsTouched();
      this.registrationForm.markAllAsTouched();
      this.identificationForm.markAllAsTouched();
      this.sellerControl.markAllAsTouched();
      this.snackbar.negativeSentiment("Not all required field completed!");
      return;
    }

    if (this.journeysGroupForm.controls.length === 0) {
      this.snackbar.negativeSentiment('No journeys');
      return;
    }

    if (this.journeysGroupForm.controls.some(c => c.value['from'].invalid || c.value['until'].invalid || c.value['group'].invalid || c.value['price'].invalid || c.value['startTime'].invalid || c.value['endTime'].invalid)) {
      this.journeysGroupForm.controls.forEach(c => {
        c.value['from'].markAsTouched();
        c.value['until'].markAsTouched();
        c.value['group'].markAsTouched();
        c.value['price'].markAsTouched();
        c.value['startTime'].markAsTouched();
        c.value['endTime'].markAsTouched();
      });

      return;
    }

    if (this.journeysGroupForm.controls.some(c => this.sellingPriceValidation(c))) {
      this.snackbar.negativeSentiment('There is one journey with invalid price');
      return;
    }

    let firstJourney = this.journeysGroupForm.controls[0];

    this.spinnerHandlerService.showProgressBar.next(true);

    let journeys = this.journeysGroupForm.controls.map(c => ({
      dateFrom: moment(`${moment(c.value.from.value).format('yyyy-MM-DD')} ${c.value.startTime.value}:00:00`).utc().format('yyyy-MM-DD HH:mm:ss'),
      dateTo: moment(`${moment(c.value.until.value).format('yyyy-MM-DD')} ${c.value.endTime.value}:00:00`).utc().format('yyyy-MM-DD HH:mm:ss'),
      groupId: c.value.group.value,
      sellingPrice: c.value.price.value,
    }));

    let car = new CreateManualCarBody(this.baseCarDataForm.value, this.technicalDataForm.value, this.pricesForm.value, this.registrationForm.value, this.identificationForm.value, this.selectedSeller.companyId!, journeys);

    this.carService.createCarDetails(car).subscribe({
      next: resp => {
        if (this.isFromDuplicate) {
          forkJoin({
            masterEq: this.carService.editCarMasterEquipments(resp.carMainInfoId, this.carFlowSyncService.carMasterEq!),
            customEq: this.carService.editCarC2VEquipments(resp.carMainInfoId, this.carFlowSyncService.carC2VEq!),
          }).subscribe(eqResp => {
            this.carFlowSyncService.carMasterEq = undefined;
            this.carFlowSyncService.carC2VEq = undefined;

            this.spinnerHandlerService.showProgressBar.next(false);
            this.snackbar.positiveSentiment('Car created');

            this.router.navigate([`car/car-details/${resp.carMainInfoId}`]);
          })
        } else {
          this.spinnerHandlerService.showProgressBar.next(false);
          this.snackbar.positiveSentiment('Car created');

          this.router.navigate([`car/car-details/${resp.carMainInfoId}`]);
        }
      },
      error: err => {
        this.spinnerHandlerService.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  loadModels(make: string) {
    this.spinnerHandlerService.showProgressBar.next(true);

    //reset controls
    this.baseCarDataForm.controls.model.reset();
    this.baseCarDataForm.controls.fuelType.reset();
    this.baseCarDataForm.controls.gearbox.reset();
    this.baseCarDataForm.controls.variant.reset();
    this.baseCarDataForm.controls.trim.reset();

    const query = `models?make=${encodeURIComponent(make)}`;

    this.carService.getCarNomenclatorData(query).subscribe(resp => {
      this.carModels = resp.map(m => { return { viewValue: m, value: m } });

      this.spinnerHandlerService.showProgressBar.next(false);
    });
  }

  loadFueltypes(model: string) {
    this.spinnerHandlerService.showProgressBar.next(true);

    //reset controls
    this.baseCarDataForm.controls.fuelType.reset();
    this.baseCarDataForm.controls.gearbox.reset();
    this.baseCarDataForm.controls.variant.reset();
    this.baseCarDataForm.controls.trim.reset();

    const query = `fuelTypes?make=${encodeURIComponent(this.baseCarDataForm.controls.make.value!)}&model=${encodeURIComponent(model)}`;

    this.carService.getCarSpecificationData(query).subscribe(resp => {
      this.carFuelTypes = resp.map(m => { return { viewValue: m.name, value: m.name } });

      this.spinnerHandlerService.showProgressBar.next(false);
    })
  }

  loadGearbox(fuelType: string) {
    this.spinnerHandlerService.showProgressBar.next(true);

    //reset controls
    this.baseCarDataForm.controls.gearbox.reset();
    this.baseCarDataForm.controls.variant.reset();
    this.baseCarDataForm.controls.trim.reset();

    let query = `gearboxes?make=${encodeURIComponent(this.baseCarDataForm.controls.make.value!)}&model=${encodeURIComponent(this.baseCarDataForm.controls.model.value!)}&fuelType=${encodeURIComponent(fuelType)}`;

    this.carService.getCarSpecificationData(query).subscribe(resp => {
      this.carGearboxes = resp.map(m => { return { viewValue: m.name, value: m.name } });

      this.spinnerHandlerService.showProgressBar.next(false);
    })
  }

  loadVariants(gearbox: string) {
    this.spinnerHandlerService.showProgressBar.next(true);

    //reset controls
    this.baseCarDataForm.controls.variant.reset();
    this.baseCarDataForm.controls.trim.reset();

    let query = `variants?make=${encodeURIComponent(this.baseCarDataForm.controls.make.value!)}&model=${encodeURIComponent(this.baseCarDataForm.controls.model.value!)}&fuelType=${encodeURIComponent(this.baseCarDataForm.controls.fuelType.value!)}&gearbox=${encodeURIComponent(gearbox)}`;

    this.carService.getCarNomenclatorData(query).subscribe(resp => {
      this.carVariants = resp.map(m => { return { viewValue: m, value: m } });

      this.spinnerHandlerService.showProgressBar.next(false);
    })
  }

  loadTrims(variant: string) {
    this.spinnerHandlerService.showProgressBar.next(true);

    //reset controls
    this.baseCarDataForm.controls.trim.reset();

    let query = `trims?make=${encodeURIComponent(this.baseCarDataForm.controls.make.value!)}&model=${encodeURIComponent(this.baseCarDataForm.controls.model.value!)}&fuelType=${encodeURIComponent(this.baseCarDataForm.controls.fuelType.value!)}&gearbox=${encodeURIComponent(this.baseCarDataForm.controls.gearbox.value!)}&variant=${encodeURIComponent(variant)}`;

    this.carService.getCarNomenclatorData(query).subscribe(resp => {
      this.carTrims = resp.map(m => { return { viewValue: m, value: m } });

      this.spinnerHandlerService.showProgressBar.next(false);
    })
  }

  identifyCar() {
    this.spinnerHandlerService.showProgressBar.next(true);

    let carRequest = new IdentifyCarRequestClass(this.baseCarDataForm.value);

    this.carService.identifyCar(carRequest).subscribe({
      next: (resp) => {
        this.carAlternatives = resp;

        this.spinnerHandlerService.showProgressBar.next(false);
      },
      error: (e) => {
        this.spinnerHandlerService.showProgressBar.next(false);

        if (e.status === 404) this.snackbar.negativeSentiment('Car not found')
      }
    })
  }

  sellingPriceValidation(journey: FormControl<any>) {
    return this.pricesForm.controls.minSellingPrice.value ? this.pricesForm.controls.minSellingPrice.value > (journey.value.price.value ? journey.value.price.value : 0) : false;
  }

  minSellingPriceValidation() {
    return this.pricesForm.controls.buyingPrice.value ? this.pricesForm.controls.buyingPrice.value > (this.pricesForm.controls.minSellingPrice.value ? this.pricesForm.controls.minSellingPrice.value : 0) : false;
  }

  resetCar() {
    this.baseCarDataForm.reset();
    this.baseCarDataForm.enable();

    this.technicalDataForm.reset();
    this.technicalDataForm.disable();

    this.registrationForm.reset();
    this.registrationForm.disable();

    this.identificationForm.reset();
    this.identificationForm.disable();

    this.pricesForm.reset();
    this.pricesForm.disable();

    this.carAlternatives = [];
  }

  openDuplicateWarningEqModal() {
    let dialogRef = this.dialog.open(this.resetCarModalEqTemplate!, {
      width: '800px',
      maxWidth: '90vw',
      id: 'reset-car',
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.carFlowSyncService.carMasterEq = undefined;
        this.carFlowSyncService.carC2VEq = undefined;

        this.isFromDuplicate = false;

        this.resetCar();
      }
    });
  }

  openCarsModal() {
    const dialogRef = this.dialog.open(
      CarIdentifiedModalComponent, {
      width: '800px',
      autoFocus: false,
      data: {
        cars: this.carAlternatives
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.baseCarDataForm.disable();

        this.patchSelectedCar(resp);
      }
    })
  }

  patchSelectedCar(car: CarIdentifiedModalComponentCar) {
    this.baseCarDataForm.controls.trim.setValue(car.trim ? car.trim : null);

    this.technicalDataForm.patchValue({
      enginePower: car.enginePower,
      engineVolume: car.engineVolume,
      co2NEDC: car.co2NEDC,
      co2WLTP: car.co2WLTP
    });

    this.registrationForm.patchValue({
      doors: car.doors,
      seats: car.seats
    });

    this.technicalDataForm.enable();
    this.pricesForm.enable();
    this.identificationForm.enable();
    this.registrationForm.enable();

    this.baseCarDataForm.controls.idAdac.setValue(car.idAdac);

    if (car.bodyType) {
      this.carBodyTypes = [{ value: car.bodyType, viewValue: car.bodyType }];
      this.technicalDataForm.controls.bodyType.setValue(car.bodyType);
    } else {
      this.carService.getCarSpecificationData('chassis').subscribe(resp => {
        this.carBodyTypes = resp.map(b => ({ value: b.name, viewValue: b.name }));
      })
    }

    if (car.drivingWheels) {
      this.carDrivingWheels = [{ value: car.drivingWheels, viewValue: car.drivingWheels }];
      this.technicalDataForm.controls.drivingWheels.setValue(car.drivingWheels);
    } else {
      this.carService.getCarSpecificationData('drivingWheels').subscribe(resp => {
        this.carDrivingWheels = resp.map(b => ({ value: b.name, viewValue: b.name }));
      })
    }
  }

  getBC() {
    if (this.baseCarDataForm.invalid) {
      this.snackbar.negativeSentiment('Technical data invalid');
      return;
    }

    if (this.registrationForm.controls.manufactureYear.invalid) {
      this.snackbar.negativeSentiment('No manufacture year selected');
      return;
    }

    if (this.registrationForm.controls.mileage.invalid) {
      this.snackbar.negativeSentiment('No mileage');
      return;
    }

    if (this.technicalDataForm.invalid) {
      this.snackbar.negativeSentiment('No body type or no engine power');
      return;
    }

    if (this.identificationForm.controls.location.invalid) {
      this.snackbar.negativeSentiment('No location');
      return;
    }

    if (!this.registrationForm.controls.firstReg.value) {
      this.snackbar.negativeSentiment('No first registration date');
      return;
    }

    let firstReg = this.registrationForm.controls.firstReg.value;

    let body: CarBusinesscaseRequestBody = {
      countries: [this.coreService.countries.find(c => c.id === this.identificationForm.controls.location.value!)!.iso],
      make: this.baseCarDataForm.controls.make.value!,
      model: this.baseCarDataForm.controls.model.value!,
      manufactureYear: parseInt(this.registrationForm.controls.manufactureYear.value!),
      fuelType: this.baseCarDataForm.controls.fuelType.value!,
      gearbox: this.baseCarDataForm.controls.gearbox.value!,
      bodyType: this.technicalDataForm.controls.bodyType.value!,
      variant: this.baseCarDataForm.controls.variant.value!,
      mileage: this.registrationForm.controls.mileage.value!,
      enginePower: this.technicalDataForm.controls.enginePower.value!,
      firstReg: firstReg ? (typeof (firstReg) != 'string' ? firstReg.format("yyyy-MM-DD") : firstReg) : "",
      accessories: [],
      carMainInfoId: ""
    };

    this.spinnerHandlerService.showProgressBar.next(true);

    this.carService.getCarsBusinessCase(body).subscribe({
      next: resp => {
        let bc = resp.find(bc => bc.spotCountry === this.coreService.countries.find(c => c.id === this.identificationForm.controls.location.value!)?.iso);

        this.b2bRecommendedPrice = bc ? bc.spotPrice : 0;

        this.spinnerHandlerService.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinnerHandlerService.showProgressBar.next(false);
      }
    })
  }

  addJourney() {
    if (this.journeysGroupForm.controls.some(c => c.value['from'].invalid || c.value['until'].invalid || c.value['group'].invalid || c.value['startTime'].invalid || c.value['endTime'].invalid || c.value['price'].invalid)) {
      this.snackbar.negativeSentiment('You must complete previous journey before adding a new one!');
      return;
    }

    let firstJourney = true;
    let lastJourney = new FormControl<any>('');

    if (this.journeysGroupForm.controls.length > 0) {
      lastJourney = this.journeysGroupForm.controls.at(-1)!;

      lastJourney.value.from.disable();
      lastJourney.value.startTime.disable();
      lastJourney.value.endTime.disable();
      lastJourney.value.until.disable();

      firstJourney = false;
    }

    const journey = new FormControl({
      group: new FormControl<string | null>('', Validators.required),
      from: new FormControl<string | null>(
        {
          value: !firstJourney ? lastJourney.value.until.value : null,
          disabled: !firstJourney
        }, Validators.required),
      until: new FormControl<string | null>(null, Validators.required),
      startTime: new FormControl<string | null>({
        value: !firstJourney ? lastJourney.value.endTime.value : null,
        disabled: !firstJourney
      }, Validators.required),
      endTime: new FormControl<string | null>(null, Validators.required),
      price: new FormControl<number | null>(null, Validators.required),
      journeyId: new FormControl(`newJourney${this.newJourneyGroupCounter++}`),
      unavailabilityForm: this.fb.group({
        startDate: [firstJourney ? new Date() : lastJourney.value.to],
        endDate: []
      })
    });

    this.journeysGroupForm.push(journey);
  }

  removeJourney(journeyId: string) {
    if (this.journeysGroupForm.length < 2) {
      this.snackbar.negativeSentiment('You cannot delete all journeys');
      return;
    }

    let index = this.journeysGroupForm.controls.findIndex(c => c.value.journeyId.value === journeyId);

    if (index === 0) {
      this.journeysGroupForm.controls.splice(index, 1);

      if (this.journeysGroupForm.controls.length === 1) {
        let lastJourney = this.journeysGroupForm.controls[0];

        lastJourney.value.until.enable();
        lastJourney.value.endTime.enable();

      }
    } else if (index + 1 === this.journeysGroupForm.length) {
      this.journeysGroupForm.controls.pop();

      let lastJourney = this.journeysGroupForm.controls.at(-1)!;

      this.inactiveFrom = lastJourney.value.until.value;

      lastJourney.value.until.enable();
      lastJourney.value.endTime.enable();

      if (this.journeysGroupForm.length === 1) {
        lastJourney.value.from.enable();
        lastJourney.value.startTime.enable();
      }
    } else {
      let nextJourney = this.journeysGroupForm.controls[index + 1];

      nextJourney.value.from.setValue(this.journeysGroupForm.controls[index].value.from.value);

      this.journeysGroupForm.controls.splice(index, 1);
    }
  }

  autocompletePrices() {
    if (!this.autocompletePrice || this.autocompletePrice <= 0) {
      this.snackbar.negativeSentiment('Insert positive value for autocomplete prices');
    }

    this.journeysGroupForm.controls.forEach(c => {
      c.value.price.setValue(this.autocompletePrice);
    });
  }

  setDates(date: any) {
    this.inactiveFrom = date;
  }

  setInactiveFromHours(hour: string) {
    this.inactiveFrom = new Date(moment(this.inactiveFrom).hours(parseInt(hour)).format('yyyy/MM/DD HH:mm'));
  }

  searchSeller(value: string) {
    let searchSeller: SellerSearch = {
      name: value,
      details: false
    }

    this.sellerService.searchSeller(searchSeller).subscribe(data => {
      this.resultItems.next(data);
    });
  }

  selectSeller(seller: ISellerResponse) {
    this.selectedSeller = seller;
    this.sellerControl.setValue(seller.sellerCompanyDetails.name!);
  }

  loadDuplicateCar(car: CarDetails) {
    this.carModels.push({ value: car.model, viewValue: car.model });
    this.carFuelTypes.push({ value: car.fuelType, viewValue: car.fuelType });
    this.carGearboxes.push({ value: car.gearbox, viewValue: car.gearbox });
    this.carVariants.push({ value: car.variant, viewValue: car.variant });
    this.carTrims.push({ value: car.trim, viewValue: car.trim });

    this.baseCarDataForm.patchValue({ ...car });
    this.technicalDataForm.patchValue({ ...car });
    this.registrationForm.patchValue({ ...car, manufactureYear: car.manufactureYear.toString() });
    this.identificationForm.patchValue({
      cocFromSeller: car.cocFromSeller,
      location: car.location,
      adLink: car.adLink,
      otherInfo: car.otherInfo
    });

    this.baseCarDataForm.disable();

    this.loadJourneys(car.carGroupJourney);

    this.carFlowSyncService.carDuplicateBody = undefined;
  }

  loadJourneys(journeys: CarGroupJourney[]) {
    journeys.forEach(j => {
      let localeStartTime = this.utilsService.utcDateToLocal(j.dateFrom);
      let localeEndTime = this.utilsService.utcDateToLocal(j.dateTo);

      let startTime = this.utilsService.utcDateToLocal(j.dateFrom).split(' ')[1].split(':')[0];
      let endTime = this.utilsService.utcDateToLocal(j.dateTo).split(' ')[1].split(':')[0];

      const journey = new FormControl({
        group: new FormControl<string>(j.groupId, Validators.required),
        from: new FormControl<Date>(new Date(localeStartTime), Validators.required),
        until: new FormControl<Date>(new Date(localeEndTime), Validators.required),
        startTime: new FormControl<string>(startTime, Validators.required),
        endTime: new FormControl<string>(endTime, Validators.required),
        price: new FormControl<number | null>(null, Validators.required),
        journeyId: new FormControl(j.journeyId),
        unavailabilityForm: this.fb.group({
          startDate: [new Date()],
          endDate: [new Date(localeStartTime) > new Date() ? new Date() : new Date(localeStartTime)]
        }),
        pastJourney: false
      });

      this.journeysGroupForm.push(journey);
    });

    if (this.journeysGroupForm.controls.length > 1) {
      this.journeysGroupForm.controls.forEach((jc, index) => {
        jc.value.startTime.disable();
        jc.value.from.disable();

        if (index + 1 < this.journeysGroupForm.controls.length) {
          jc.value.endTime.disable();
          jc.value.until.disable();
        }
      });
    }
  }
}
