import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface BtnToggleOption {
  value: string,
  viewValue: string,
  disabled?: BehaviorSubject<boolean>
}

@Component({
  selector: 'app-btn-toggle',
  templateUrl: './app-btn-toggle.component.html',
  styleUrls: ['./app-btn-toggle.component.scss']
})
export class AppBtnToggleComponent implements OnInit {
  @Input() options: BtnToggleOption[] = [];
  @Input() isDarkBlue: boolean = false;
  @Input() selectedOption: string | undefined;

  @Output() changeEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  changeToggle(value: string) {
    this.changeEmitter.emit(value);
  }
}
