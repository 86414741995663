import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { VATType } from 'src/app/core/models/info.model';
import { SellerOffer, SellerOfferStatus } from 'src/app/core/models/offer.model';
import { CarService } from 'src/app/core/services/car.service';
import { OffersService } from 'src/app/core/services/offers.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-view-seller-offers',
  templateUrl: './view-seller-offers.component.html',
  styleUrls: ['./view-seller-offers.component.scss']
})
export class ViewSellerOffersComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  headers: TableHeaderMap[] = [
    {
      value: 'status',
      tableView: 'status'
    },
    {
      value: 'createdAt',
      tableView: 'Date sent'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'carDetails.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carDetails.vin',
      tableView: 'vin'
    },
    {
      value: 'carDetails.make',
      tableView: 'make'
    },
    {
      value: 'carDetails.model',
      tableView: 'model'
    },
    {
      value: 'carDetails.gearbox',
      tableView: 'gearbox'
    },
    {
      value: 'carDetails.enginePower',
      tableView: 'power'
    },
    {
      value: 'carDetails.mileage',
      tableView: 'mileage'
    },
    {
      value: 'initialPrice',
      tableView: 'initial Price'
    },
    {
      value: 'currentPrice',
      tableView: 'current Price'
    }
  ];

  displayedColumns = ['selectAll'].concat(this.headers.map(h => h.value), ['details']);

  offers: SellerOffer[] = [];

  offerStatuses = SellerOfferStatus;

  statusFilters: DropdownOption[] = [];
  makes: DropdownOption[] = [];
  models: DropdownOption[] = [];
  years: DropdownOption[] = Array.from({ length: (new Date().getFullYear() - 1998) }, (_, i) => i + 2000).reverse().map(y => ({ value: y.toString(), viewValue: y.toString() }));

  filterGroup = this.fb.group({
    status: '',
    make: '',
    model: '',
    year: '',
    vin: ''
  });

  @ViewChild('offersTable') offersTable: AppTableComponent | undefined;

  constructor(private offerService: OffersService,
    private utilsService: UtilsService,
    private router: Router,
    private fb: FormBuilder,
    private carService: CarService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit(): void {
    forkJoin({
      offers: this.offerService.getSellerOffers(),
      makes: this.carService.getCarNomenclatorData('makes')
    }).subscribe(resp => {
      this.offers = resp.offers.map(o => ({
        ...o,
        createdAt: this.utilsService.formatDate(new Date(o.createdAt)),
        isSelected: false,
        vatType: o.vat ? VATType.ExVAT : VATType.InclVAT
      }));

      this.statusFilters = Object.values(SellerOfferStatus).map(s => ({ value: s, viewValue: this.utilsService.splitWordByUnderline(s) }));

      this.makes = resp.makes.map(m => ({ value: m, viewValue: m }));

      this.statusFilters.unshift({ value: 'all', viewValue: 'All' });
      this.makes.unshift({ value: 'all', viewValue: 'All' });
      this.years.unshift({ value: 'all', viewValue: 'All' });


      this.loading.next(false);
    })
  }

  goToOffer(offer: SellerOffer) {
    this.router.navigate([`seller-offer/${offer.id}`]);
  }

  filterTable() {
    let offers: SellerOffer[] = this.offers;

    // status filter
    if (this.filterGroup.controls.status.value && this.filterGroup.controls.status.value != 'all') {
      offers = offers.filter(o => o.status === this.filterGroup.controls.status.value);
    }

    // make filter
    if (this.filterGroup.controls.make.value && this.filterGroup.controls.make.value != 'all') {
      offers = offers.filter(o => o.carDetails.make === this.filterGroup.controls.make.value);
    }

    // model filter
    if (this.filterGroup.controls.model.value && this.filterGroup.controls.model.value != 'all') {
      offers = offers.filter(o => o.carDetails.model === this.filterGroup.controls.model.value);
    }

    // year filter
    if (this.filterGroup.controls.year.value && this.filterGroup.controls.year.value != 'all') {
      offers = offers.filter(o => o.carDetails.manufactureYear.toString() === this.filterGroup.controls.year.value);
    }

    // vin filter
    if (this.filterGroup.controls.vin.value) {
      offers = offers.filter(o => o.carDetails.vin.match(this.filterGroup.controls.vin.value!));
    }

    this.offersTable?.updateTable(offers);
  }

  loadModels(model: string) {
    this.spinner.showProgressBar.next(true);

    this.carService.getCarNomenclatorData(`models?make=${encodeURIComponent(model)}`).subscribe(resp => {
      this.models = resp.map(m => ({ value: m, viewValue: m }));

      this.models.unshift({ value: 'all', viewValue: 'All' });

      this.spinner.showProgressBar.next(false);
    });
  }
}
