<app-navbar></app-navbar>

<app-page-layout class="min-h-[85vh]" *ngIf="!(loading | async)">
  <div class="my-9 pb-9 border-b border-white-500">
    <app-btn-toggle [options]="routes" [selectedOption]="page" (changeEmitter)="navigateTo($event)"></app-btn-toggle>
  </div>

  <div>
    <div [ngSwitch]="page">
      <!-- <app-dealer-mapping *ngSwitchCase="'dealer-mapping'"></app-dealer-mapping> -->

      <app-transport-matrix *ngSwitchCase="'transport-matrix'"></app-transport-matrix>

      <app-car-groups *ngSwitchCase="'car-groups'"></app-car-groups>

      <app-country-holiday *ngSwitchCase="'country-holiday'"></app-country-holiday>

      <app-company-invoices *ngSwitchCase="'company-invoices'"></app-company-invoices>

      <app-manage-cars-inventory *ngSwitchCase="'manage-cars'"></app-manage-cars-inventory>

      <app-create-users *ngSwitchCase="'create-users'"></app-create-users>

      <app-vin-check *ngSwitchCase="'vin-check'"></app-vin-check>

      <app-transport-matrix *ngSwitchDefault></app-transport-matrix>
    </div>

  </div>

</app-page-layout>

<app-progress-bar></app-progress-bar>


<ng-template #spinner>
  <app-spinner></app-spinner>
</ng-template>
