import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import moment from 'moment';
import { ConfirmSellerPaymentBody } from 'src/app/core/models/order.model';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { OrderStatus } from '../../orders-view/orders-view.component';

@Component({
  selector: 'app-order-process-payments',
  templateUrl: './order-process-payments.component.html',
  styleUrls: ['./order-process-payments.component.scss']
})
export class OrderProcessPaymentsComponent implements OnInit {
  buyerPaid = this.orderFlowService.order?.buyer.buyerPaid;
  buyerPaidControl = new FormControl<any>({ value: this.orderFlowService.order?.buyer.buyerPaidAt, disabled: !this.buyerPaid }, Validators.required);
  buyerTransportPaidControl = new FormControl();

  sellersDropdown: DropdownOption[] = this.orderFlowService.order!.sellers!.map(s => ({ value: s.details!.id!, viewValue: s.details!.name! }));
  sellerControl = new FormControl(this.sellersDropdown[0].value);

  ibanControl = new FormControl({ value: '', disabled: this.orderFlowService.orderStatus === OrderStatus.FINISHED }, Validators.required);
  ocrControl = new FormControl({ value: '', disabled: this.orderFlowService.orderStatus === OrderStatus.FINISHED }, Validators.required);
  sellerPaid = false;
  sellerPaidControl = new FormControl<any>({ value: '', disabled: true }, Validators.required);

  finishedOrder = this.orderFlowService.orderStatus === OrderStatus.FINISHED;

  // emailSentToSeller = this.sellers[0].details?.invoiceEmailSent ? this.sellers[0].details?.invoiceEmailSent : undefined;
  // emailSentToKAM = this.sellers[0].kamSeller!.invoiceSellerEmailRemainder ? this.sellers[0].kamSeller!.invoiceSellerEmailRemainder : undefined;

  constructor(private orderService: OrdersService,
    private orderFlowService: OrdersFlowSyncService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit(): void {
    this.changeSeller(this.sellersDropdown[0].value);

    if (this.finishedOrder) {
      this.buyerPaidControl.disable();
      this.buyerTransportPaidControl.disable();
    }
  }

  toggleBuyerPaid(event: MatCheckboxChange) {
    if (event.checked) {
      this.buyerPaidControl.enable();
    } else {
      this.buyerPaidControl.reset();
      this.buyerPaidControl.disable();
    }
  }

  toggleBuyerTransportPaid(event: MatCheckboxChange) {
    if (event.checked) {
      this.buyerTransportPaidControl.enable();
    } else {
      this.buyerTransportPaidControl.reset();
      this.buyerTransportPaidControl.disable();
    }
  }
  confirmBuyerPayment() {
    if (this.buyerPaidControl.invalid) {
      this.snackbar.negativeSentiment('No date set');
      return;
    }

    this.spinner.showProgressBar.next(true);

    let date = typeof (this.buyerPaidControl.value) === 'string' ? moment(this.buyerPaidControl.value).format("yyyy-MM-DD") : this.buyerPaidControl.value!.format("yyyy-MM-DD");

    this.orderService.confirmBuyerPayment(this.orderFlowService.order?.orderId!, true, date).subscribe({
      next: resp => {
        this.orderFlowService.order!.buyer.buyerPaid = true;
        this.orderFlowService.order!.buyer.buyerPaidAt = this.buyerPaidControl.value;

        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment('Buyer paid confirmed!');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  changeSeller(sellerId: string) {
    let seller = this.orderFlowService.order!.sellers.find(s => s.details.id === sellerId);

    this.ibanControl.setValue(seller!.details.bank);
    this.ocrControl.setValue(seller!.details.OCR);
    this.sellerPaid = seller!.details.sellerPayment;
    // this.emailSentToSeller = seller?.details?.invoiceEmailSent!;
    // this.emailSentToKAM = seller?.kamSeller?.invoiceSellerEmailRemainder!;

    if (this.sellerPaid) {
      this.sellerPaidControl.setValue(seller!.details.sellerPaymentAt);
      this.finishedOrder ? this.sellerPaidControl.disable() : this.sellerPaidControl.enable();
    } else {
      this.sellerPaidControl.reset();
      this.sellerPaidControl.disable();
    }
  }

  toggleSellerPaid(event: MatCheckboxChange) {
    if (event.checked) {
      this.sellerPaidControl.enable();
    } else {
      this.sellerPaidControl.reset();
      this.sellerPaidControl.disable();
    }
  }

  confirmSellerPayment() {
    let seller = this.orderFlowService.order!.sellers?.find(s => s.details?.id === this.sellerControl.value!)!;

    if (!(seller.details.OCR || (seller.details.OCR && seller.details.OCR.length === 0)) || (!seller.details.bank || (seller.details.bank && seller.details.bank.length === 0))) {
      this.snackbar.negativeSentiment('You must first save IBAN and OCR!');
      return;
    }

    if (!this.sellerPaid || this.sellerPaidControl.invalid) {
      this.snackbar.negativeSentiment('No date set');
      return;
    }

    this.spinner.showProgressBar.next(true);

    let date = typeof (this.sellerPaidControl.value) === 'string' ? moment(this.sellerPaidControl.value).format("yyyy-MM-DD") : this.sellerPaidControl.value!.format("yyyy-MM-DD");

    let body: ConfirmSellerPaymentBody = {
      orderId: this.orderFlowService.order?.orderId!,
      sellerId: this.sellerControl.value!,
      sellerPayment: true,
      sellerPaymentAt: date,
      OCR: seller.details.OCR,
      bank: seller.details.bank
    }

    this.orderService.confirmSellerPayment(body).subscribe({
      next: resp => {
        this.orderFlowService.order!.sellers = resp;

        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment('Seller paid confirmed!');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  sendSellerNotification() {
    let seller = this.orderFlowService.order!.sellers?.find(s => s.details?.id === this.sellerControl.value!);

    if (seller!.details!.OCR!.length === 0 || seller!.details!.bank!.length === 0 || (seller!.details!.sellerPayment!.toString() === "")) {
      this.snackbar.negativeSentiment('You must first save IBAN, OCR and payment date!');
      return;
    }

    this.spinner.showProgressBar.next(true);

    this.orderService.sendSellerEmailNotification(this.orderFlowService.order?.orderId!, this.sellerControl.value!, moment().format("yyyy-MM-DD")).subscribe(resp => {
      // this.emailSentToSeller = new Date();
      // seller!.details!.sellerPayment = new Date();

      this.spinner.showProgressBar.next(false);
      this.snackbar.positiveSentiment('Email sent to seller!');
    })
  }

  sendKAMReminder() {
    this.spinner.showProgressBar.next(true);

    this.orderService.sendKAMReminder(this.orderFlowService.order?.orderId!, this.sellerControl.value!).subscribe({
      next: resp => {
        // this.emailSentToKAM = new Date();
        this.spinner.showProgressBar.next(false);

        let seller = this.orderFlowService.order!.sellers?.find(s => s.details?.id === this.sellerControl.value!);

        // seller!.kamSeller!.invoiceSellerEmailRemainder = new Date();

        this.snackbar.positiveSentiment('Reminder sent to KAM!');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  saveIBANOCR() {
    if (this.ibanControl.invalid || this.ocrControl.invalid) {
      this.snackbar.negativeSentiment('IBAN and OCR fields must be completed!');
      return;
    }

    this.spinner.showProgressBar.next(true);

    let date = this.sellerPaidControl.value ? (typeof (this.sellerPaidControl.value) === 'string' ? this.sellerPaidControl.value : this.sellerPaidControl.value!.format("yyyy-MM-DD")) : "";

    let body: ConfirmSellerPaymentBody = {
      orderId: this.orderFlowService.order?.orderId!,
      sellerId: this.sellerControl.value!,
      sellerPayment: this.sellerPaid,
      sellerPaymentAt: date,
      OCR: this.ocrControl.value!,
      bank: this.ibanControl.value!
    }

    this.orderService.confirmSellerPayment(body).subscribe({
      next: resp => {
        this.orderFlowService.order!.sellers = resp;

        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment('IBAN and OCR saved!');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  checkTransportC2C(): boolean {
    return this.orderFlowService.order!.transportDetails.transportByC2C && this.orderFlowService.order!.separateInvoiceForTransport;
  }
}
