<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <div class="flex justify-between items-center">
    <p class="text-2xl">Add order documents</p>

    <!-- <fa-icon class="cursor-pointer" [icon]="['far','xmark']" (click)="close()"></fa-icon> -->
  </div>

  <div class="grid grid-cols-2 gap-6">
    <p>Document name</p>

    <app-input [control]="nameControl"></app-input>

    <app-toggle [toggleFormControl]="visibleToBuyersControl" id="order-doc-visible" class="col-start-2 justify-self-end"
      toggleLabel="Visible to buyers"></app-toggle>

    <div class="bg-white-400 p-4 rounded col-span-2">
      <div
        class="bg-white-700 rounded border-2 border-dashed border-blue-200 p-5 flex flex-col items-center gap-9 py-9">
        <fa-icon class="text-6xl" [icon]="['fal','up-to-line']"></fa-icon>

        <div class="bg-white rounded px-9 py-3 flex gap-6 w-fit" *ngIf="fileName.length > 0; else chooseFile">
          <p>{{fileName}}</p>

          <fa-icon id="remove-file-btn" data-cy="remove-file-btn" class="cursor-pointer" [icon]="['far','xmark']"
            (click)="removeFile()"></fa-icon>
        </div>

        <ng-template #chooseFile>
          <div class="flex flex-col gap-6 items-center">
            <app-white-btn class="w-fit" (clickEmitter)="uploadFile.click()" btnText="Upload file"></app-white-btn>
          </div>
        </ng-template>
      </div>
    </div>

    <primary-btn class="col-start-2 w-fit justify-self-end" (clickEmitter)="uploadDocument()">Add document</primary-btn>

    <input class="hidden" [multiple]="true" #uploadFile type="file" accept=".pdf" (change)="handleFile($event)">
  </div>

</div>
