<div class="flex justify-end mb-6" *ngIf="!carsUploaded">
  <primary-btn [blueBtn]="true" btnText="Identify cars" (clickEmitter)="identifyCars()"></primary-btn>
</div>

<div class="twoCols">
  <!-- check settings -->
  <app-main-content-layout>
    <div class="title">
      Cars Settings
    </div>

    <div class="twoCols" *ngIf="!carsUploaded">
      <app-dropdown id="seller-country" [options]="countries" placeholder="Select country" label="Seller filter country"
        [resetValue]="true" [control]="countryControl">
      </app-dropdown>
    </div>

    <div class="twoCols" *ngIf="!carsUploaded">
      <input id="search-seller-input" data-cy="search-seller-input" class="search-seller" type="text" matInput
        [matAutocomplete]="auto" [formControl]="sellerControl" placeholder="Search seller">

      <mat-autocomplete #auto="matAutocomplete">
        <div *ngIf="existsResults | async">
          <mat-option class="autocomplete-option" *ngFor="let seller of (resultItems| async)!"
            (click)="selectSeller(seller)">
            <div class="option-item">
              {{seller.sellerCompanyDetails.name}}
            </div>
          </mat-option>
        </div>
      </mat-autocomplete>
    </div>

    <app-main-content-row-layout class="pt-6 border-t border-white-500">
      <p>Seller name</p>

      <app-read-only-input class="secondContent">
        {{selectedSeller ? selectedSeller.sellerCompanyDetails.name : 'No seller selected'}}
      </app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Address</p>

      <app-read-only-input class="secondContent">
        {{selectedSeller ? selectedSeller.sellerCompanyDetails.address : 'No seller selected'}}
      </app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Phone</p>

      <app-read-only-input class="secondContent">
        {{selectedSeller ? selectedSeller.sellerCompanyDetails.phone : 'No seller selected'}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Email</p>

      <app-read-only-input class="secondContent">
        {{selectedSeller ? selectedSeller.sellerCompanyDetails.email : 'No seller selected'}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Cars location</p>

      <app-dropdown placeholder="Select country" id="country" class="secondContent" [control]="carsLocationControl"
        [options]="countries"></app-dropdown>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Available from</p>

      <app-datepicker id="avaialbleFrom" class="secondContent" [min]="today"
        [control]="availableFromControl"></app-datepicker>
    </app-main-content-row-layout>

    <!-- journeys -->
    <div *ngFor="let control of journeysGroupForm.controls; let i = index"
      class="flex flex-col gap-4 pt-4 border-t border-white-500">
      <app-main-content-row-layout>
        <p class="font-bold" [ngClass]="{'text-red': control.value.group.invalid && control.value.group.touched}">
          Journey {{i+1}}
        </p>

        <app-dropdown [id]="'journey-group-' + (i + 1)" placeholder="Select journey group" class="secondContent"
          [control]="control.value.group" [options]="journeyGroups">
        </app-dropdown>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p [ngClass]="{'text-red font-bold': control.value.from.invalid && control.value.from.touched}">
          Start date
        </p>

        <app-datepicker [id]="'journey-from-'+ (i + 1)" class="secondContent" [control]="control.value.from"
          [min]="control.value.unavailabilityForm.controls.startDate.value" [max]="control.value.until.value"
          placeholder="Select date"></app-datepicker>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p [ngClass]="{'text-red font-bold': control.value.startTime.invalid && control.value.startTime.touched}">Start
          time
          (local time)
        </p>

        <app-dropdown [id]="'journey-from-time-'+ (i + 1)" class="secondContent" [options]="hours"
          [control]="control.value.startTime" placeholder="Select hour"></app-dropdown>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p [ngClass]="{'text-red font-bold': control.value.until.invalid && control.value.until.touched}">End date</p>

        <app-datepicker [id]="'journey-to-'+ (i + 1)" class="secondContent" [control]="control.value.until"
          [min]="control.value.from.value" [max]="control.value.unavailabilityForm.controls.endDate.value"
          (dateChangeEvent)="setDates($event)" placeholder="Select date"></app-datepicker>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p [ngClass]="{'text-red font-bold': control.value.endTime.invalid && control.value.endTime.touched}">
          End time (local time)
        </p>

        <app-dropdown [id]="'journey-to-time-'+ (i + 1)" class="secondContent" [options]="hours"
          [control]="control.value.endTime" (emitOnChangeSelect)="setInactiveFromHours($event)"
          placeholder="Select hour"></app-dropdown>
      </app-main-content-row-layout>

      <div class="flex justify-end" *ngIf="!carsUploaded">
        <p class="cursor-pointer" (click)="removeJourney(control.value.journeyId.value)">Remove journey</p>
      </div>
    </div>

    <app-main-content-row-layout *ngIf="!carsUploaded">
      <div class="secondContent flex justify-end gap-4">
        <secondary-btn id="add-journey" btnText="Add Journey" (clickEmitter)="addJourney()"></secondary-btn>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Inactive from</p>

      <app-read-only-input class="secondContent">
        <p>{{inactiveFrom ? (inactiveFrom | date:"dd/MM/yyyy HH:mm") : "dd/MM/yyyy"}}</p>
      </app-read-only-input>
    </app-main-content-row-layout>
  </app-main-content-layout>

  <!-- file import -->
  <app-main-content-layout>
    <div class="title">
      File import
    </div>

    <div class="bg-white-400 p-4 rounded">
      <div
        class="bg-white-700 rounded border-2 border-dashed border-blue-200 p-5 flex flex-col items-center gap-9 py-9">
        <fa-icon class="text-6xl" [icon]="['fal','up-to-line']"></fa-icon>
        <div class="bg-white rounded px-9 py-3 flex gap-6 w-fit" *ngIf="excelFileName; else chooseFile">
          <p>{{excelFileName}}</p>
          <fa-icon id="remove-file-btn" data-cy="remove-file-btn" class="cursor-pointer" [icon]="['far','xmark']"
            (click)="removeFile()" *ngIf="!carsUploaded"></fa-icon>
        </div>

        <ng-template #chooseFile>
          <div class="flex flex-col gap-6 items-center">
            <app-white-btn class="w-fit" [disableBtn]="!fileGenerated" (clickEmitter)="selectExcel.click()"
              btnText="Upload file"></app-white-btn>

            <app-white-btn class="w-fit" (clickEmitter)="generateExcelFile()"
              btnText=" Generate upload file"></app-white-btn>

            <p class="text-sm text-white-800">In order for Upload to be active, you must first generate the upload file
            </p>
          </div>
        </ng-template>
      </div>
    </div>

    <input class="hidden" [multiple]="true" #selectExcel type="file" accept=".xlsx" [formControl]="uploadControl"
      (change)="handleFileSelect($event)">
  </app-main-content-layout>
</div>
