import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, firstValueFrom, zip } from 'rxjs';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AddCountryModalComponent } from './add-country-modal/add-country-modal.component';

export interface TransportMatrixRow {
  countryFrom: string,
  countryFromId: string,
  countriesTo: {
    country: string,
    countryId: string,
    value: number
  }[]
}

export interface TransportMatrix {
  id?: string,
  countryFrom: string,
  countryTo: string,
  value: number,
  status: string,
}

export interface CreateTransportMatrixBody {
  status: boolean,
  countryId: string
}

export interface EditTransportMatrixBody {
  status: boolean,
  value: number
}

@Component({
  selector: 'app-transport-matrix',
  templateUrl: './transport-matrix.component.html',
  styleUrls: ['./transport-matrix.component.scss']
})

export class TransportMatrixComponent implements OnInit {
  loading = new BehaviorSubject(true);

  displayedColumns = ['countryOrigin', 'delete'];
  filteredColumns = this.displayedColumns;

  countries: DropdownOption[] = this.coreService.countries.map(c => ({ value: c.id, viewValue: c.name }));
  countriesInMatrix: DropdownOption[] = [];

  rows: TransportMatrixRow[] = [];
  matrix: TransportMatrix[] = [];

  dataSource = new MatTableDataSource<TransportMatrixRow>(this.rows);

  countryFromControl = new FormControl();
  countryToControl = new FormControl();

  constructor(private coreService: CoreService,
    private dialog: MatDialog,
    private settingsService: SettingsService,
    private spinnerHandlerService: SpinnerHandlerService,
    private snackBar: SnackbarService
  ) { }

  ngOnInit() {
    this.loadMatrix();
  }

  openAddCountryModal() {
    const dialogRef = this.dialog.open(
      AddCountryModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
        countries: this.countries
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) this.saveCountry(resp);
    })
  }

  loadMatrix() {
    this.settingsService.getTransportMatrix().subscribe(resp => {
      this.matrix = resp;

      if (this.matrix.length > 0) {
        let countries = this.matrix.reduce((arr: string[], tm) => {
          if (!arr.includes(tm.countryFrom)) arr.push(tm.countryFrom);

          return arr;
        }, []);

        this.countriesInMatrix = countries.map(c => ({ viewValue: this.countries.find(co => co.value === c)!.viewValue, value: c }));

        this.countriesInMatrix.sort(function (a, b) {
          return (a.viewValue < b.viewValue) ? -1 : (a.viewValue > b.viewValue) ? 1 : 0;
        });

        this.countriesInMatrix.forEach(countryFrom => {
          this.rows.push({
            countryFrom: countryFrom.viewValue,
            countryFromId: countryFrom.value,
            countriesTo: this.countriesInMatrix.map(countryTo => ({
              country: countryTo.viewValue,
              countryId: countryTo.value,
              value: this.matrix.find(m => m.countryFrom === countryFrom.value && m.countryTo === countryTo.value)!.value
            }))
          })
        });

        this.displayedColumns.splice(1, 0, ...this.countriesInMatrix.map(c => c.viewValue));

        this.dataSource.data = this.rows;

        this.loading.next(false);
      } else {
        this.loading.next(false);
      }
    })
  }

  saveCountry(country: string) {
    this.loading.next(true);


    this.settingsService.createTransportMatrix({ status: true, countryId: country }).subscribe({
      next: resp => {
        this.snackBar.positiveSentiment(resp);

        this.countriesInMatrix = [];
        this.rows = [];
        this.displayedColumns = ['countryOrigin', 'delete'];

        this.loadMatrix();
      },
      error: err => {
        this.loading.next(false);
        this.snackBar.negativeSentiment(err.error);
      }
    })
  }

  saveInput(countryToId: string, countryFrom: TransportMatrixRow) {
    this.spinnerHandlerService.showProgressBar.next(true);

    let matrixRow = this.matrix.find(m => m.countryFrom === countryFrom.countryFromId && m.countryTo === countryToId);

    let transMatrix: EditTransportMatrixBody = {
      value: countryFrom.countriesTo.find(c => c.countryId === countryToId)!.value,
      status: true
    }

    this.settingsService.editTransportMatrix(transMatrix, matrixRow!.id!).subscribe({
      next: resp => {
        this.spinnerHandlerService.showProgressBar.next(false);

        this.snackBar.positiveSentiment(resp);
      },
      error: err => {
        this.spinnerHandlerService.showProgressBar.next(false);

        this.snackBar.negativeSentiment(err.error);
      }
    })
  }

  resetFilters() {
    this.dataSource.data = this.rows;

    let columns = ['countryOrigin', 'delete'];

    this.rows.forEach(r => {
      columns.splice(columns.length - 1, 0, r.countryFrom);
    });

    this.displayedColumns = columns;

    this.countryFromControl.reset();
    this.countryToControl.reset();
  }

  filterTable() {
    let data = this.rows;
    let columns = ['countryOrigin', 'delete'];

    this.rows.forEach(r => {
      columns.splice(columns.length - 1, 0, r.countryFrom);
    });

    if (this.countryFromControl.value && this.countryFromControl.value.length > 0) {
      data.forEach(d => {
        d.countryFromId = this.countries.find(c => c.viewValue === d.countryFrom)!.value;
      })

      data = data.filter(d => this.countryFromControl.value.find((cc: string) => cc === d.countryFromId));
    }

    if (this.countryToControl.value && this.countryToControl.value.length > 0) {
      let countriesControlValues = this.countryToControl.value.map((c: string) => this.countries.find(country => country.value === c)?.viewValue);

      columns = columns.filter(c => countriesControlValues.includes(c) || c === 'delete' || c === 'countryOrigin');
    }

    this.displayedColumns = columns;
    this.dataSource.data = data;
  }
}
