import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, Subject, debounceTime, forkJoin, takeUntil, tap } from 'rxjs';
import { VATType } from 'src/app/core/models/info.model';
import { SearchCarWithoutOffer, SearchCarsWOOffersBody } from 'src/app/core/models/offer.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

interface CreateOfferSettingsCarsModalData {
  buyerId: string,
  clientId: string,
  carList: string[]
}

@Component({
  selector: 'app-create-offer-settings-cars-modal',
  templateUrl: './create-offer-settings-cars-modal.component.html',
  styleUrls: ['./create-offer-settings-cars-modal.component.scss']
})

export class CreateOfferSettingsCarsModalComponent implements OnInit {
  loading = new BehaviorSubject(true);

  private filtersStringEvent: Subject<boolean> = new Subject();
  public filtersStringEvent$ = this.filtersStringEvent.asObservable();
  public destroyed = new Subject<void>();

  headers: TableHeaderMap[] = [
    {
      value: 'sellerDetails.name',
      tableView: 'seller'
    },
    {
      value: 'carPrices.shopPrice',
      tableView: 'price'
    },
    {
      value: 'regNo',
      tableView: 'Registration number'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'firstReg',
      tableView: 'First registration'
    },
    {
      value: 'color',
      tableView: 'color'
    }
  ]

  displayColumn = ['selectAll'].concat(this.headers.map(h => h.value));

  buyerId = this.data.buyerId;
  clientId = this.data.clientId;
  tableCars = this.data.carList;

  cars: SearchCarWithoutOffer[] = [];
  returnCars: SearchCarWithoutOffer[] = [];

  makes: DropdownOption[] = [];
  models: DropdownOption[] = [];

  makeControl = new FormControl();
  modelControl = new FormControl();
  regNoControl = new FormControl();
  vinControl = new FormControl();
  idControl = new FormControl();

  countries = this.coreService.countries;

  noOfCars = 0;

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;
  @ViewChild('paginator') paginator: MatPaginator | undefined;

  constructor(private carService: CarService,
    private snackbarService: SnackbarService,
    private coreService: CoreService,
    public dialogRef: MatDialogRef<CreateOfferSettingsCarsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateOfferSettingsCarsModalData) {
  }

  ngOnInit(): void {
    let searchBody = new SearchCarsWOOffersBody(0, 5, this.clientId, this.buyerId, undefined, undefined, []);

    forkJoin({
      search: this.carService.searchCarsOffer(searchBody),
      makes: this.carService.getCarNomenclatorData('makes')
    }).subscribe(resp => {
      this.makes = resp.makes.map(m => ({ value: m, viewValue: m }));

      this.cars = resp.search.cars.map(c => ({
        ...c,
        vatType: c.vatStatus ? VATType.ExVAT : VATType.InclVAT
      }));

      this.noOfCars = resp.search.nrOfCars;

      this.loading.next(false);
    })

    this.filtersStringEvent$.pipe(debounceTime(1000),
      tap(() => { }),
      takeUntil(this.destroyed)).subscribe(resp => {
        this.loading.next(true);

        if (resp) this.paginator!.pageIndex = 0;

        let makeModel: any = [];

        if (this.makeControl.value) {
          makeModel.push({
            make: this.makeControl.value,
            model: this.modelControl.value ? this.modelControl.value : ''
          });
        };

        let searchBody = new SearchCarsWOOffersBody(this.paginator!.pageIndex, this.paginator!.pageSize, this.clientId, this.buyerId, this.regNoControl.value, this.vinControl.value, makeModel);

        this.carService.searchCarsOffer(searchBody).subscribe(resp => {
          this.loading.next(false);

          setTimeout(() => {
            this.carsTable?.updateTable(resp.cars.map(c => ({
              ...c,
              vatType: c.vatStatus ? VATType.ExVAT : VATType.InclVAT
            })));

            this.noOfCars = resp.nrOfCars;
          }, 0)
        })
      });

    this.vinControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next(true);
    });

    this.regNoControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next(true);
    });

    this.idControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next(true);
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  selectMake(make: string) {
    this.modelControl.reset();
    this.models = [];

    if (make === 'all') {
      this.modelControl.reset();
      this.models = [];
    } else {
      this.carService.getCarNomenclatorData(`models?make=${make}`).subscribe(resp => this.models = [{ value: 'all', viewValue: 'All makes' }].concat(resp.map(m => ({ value: m, viewValue: m }))));
    }

    setTimeout(() => {
      this.filtersStringEvent.next(true);
    })
  }

  selectModel(model: string) {
    this.filtersStringEvent.next(true);
  }

  resetFilters() {
    this.regNoControl.reset();
    this.vinControl.reset();
    this.idControl.reset();
    this.makeControl.reset();
    this.modelControl.reset();
    this.models = [];
  }

  addCars() {
    this.returnCars = <SearchCarWithoutOffer[]>this.carsTable?.dataSource.data.filter(c => c.isSelected && !this.tableCars.includes(c.carMainInfoId!));

    this.dialogRef.close(this.returnCars);
    this.snackbarService.positiveSentiment('Cars added!');
  }

  checkCarInCart(carMainInfoId: string): boolean {
    return this.tableCars.includes(carMainInfoId);
  }

  close() {
    this.returnCars = this.returnCars.map(c => ({ ...c, isSelected: false }));

    this.dialogRef.close(this.returnCars);
  }

  pageChange() {
    this.filtersStringEvent.next(false);
  }
}
