<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <div class="flex justify-between items-center">
    <p class="text-2xl">Cars from accepted offers</p>

    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close()"></fa-icon>
  </div>

  <app-table #carsTable [displayedColumns]="displayColumn" [headersMap]="headers" [data]="cars"
    *ngIf="!(loading | async);else placeholder"></app-table>

  <div class="flex justify-end">
    <primary-btn id="add-cars-modal" [blueBtn]="true" (clickEmitter)="addCars()">Add cars</primary-btn>
  </div>
</div>

<ng-template #placeholder>
  <app-table-placeholder></app-table-placeholder>
</ng-template>
