import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, catchError, firstValueFrom, forkJoin, of, Subject } from "rxjs";
import { DropdownOption } from "src/app/shared/app-dropdown/app-dropdown.component";
import { UnsavedChangesModalComponent } from "src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component";
import { CarBusinessCase, CarBusinesscaseRequestBody, CarC2VEquipments, CarDetails, CarGroupJourney, CarMasterEquipments } from "../models/car.model";
import { CarOnlineListUploadBody } from "../models/cars-inventory.model";
import { CarService } from "./car.service";
import { ISeller, ISellerResponse } from "../models/seller.model";
import { TransportMatrix } from "src/app/features/settings/transport-matrix/transport-matrix.component";
import { SettingsService } from "./settings.service";

@Injectable({
  providedIn: 'root'
})

export class CarFlowSyncService {
  loadingInfo: BehaviorSubject<boolean> = new BehaviorSubject(true);
  listAfterUpload = new BehaviorSubject(false);

  private isRouteChangeEvent: Subject<string> = new Subject();
  public isRouteChangeEvent$ = this.isRouteChangeEvent.asObservable();

  private currentTab: BehaviorSubject<string> = new BehaviorSubject('init');
  public currentTab$ = this.currentTab.asObservable();

  carId: string = "";
  carDetails: CarDetails | undefined;
  selectedSeller: ISellerResponse | undefined;

  // car dropdown info
  carMakes: DropdownOption[] = [];
  carStatuses: DropdownOption[] = [];
  carGroups: DropdownOption[] = [];
  carColors: DropdownOption[] = [];
  carBodyTypes: DropdownOption[] = [];
  carDrivingWheels: DropdownOption[] = [];

  excelFile: any;

  // edit car equipments
  carC2VEq: CarC2VEquipments | undefined;
  carMasterEq: CarMasterEquipments | undefined;
  adacEq: CarMasterEquipments | undefined;
  c2vEq: string[] = [];


  carTransportMatrix: TransportMatrix[] = [];

  bc: CarBusinessCase[] = [];

  carDuplicateBody: CarDetails | undefined;

  // online list upload variables
  carsAfterIdentifyDTO: any[] = [];
  carOnlineListUpload: CarOnlineListUploadBody | undefined;
  journeys: any;

  constructor(private carService: CarService,
    private settingsService: SettingsService,
    private dialog: MatDialog) {
  }

  loadInfo() {
    forkJoin({
      makes: this.carService.getCarNomenclatorData('makes'),
      colors: this.carService.getCarSpecificationData('colors'),
      groups: this.carService.getCarGroups(),
    }).subscribe({
      next: async resp => {
        this.carMakes = resp.makes.map(m => { return { viewValue: m, value: m } });
        this.carGroups = resp.groups.map(r => { return { viewValue: r.name, value: r.id } });
        this.carColors = resp.colors.map(r => ({ viewValue: r.name, value: r.name }));

        this.loadingInfo.next(false);
      },
      error: err => {
        this.loadingInfo.next(false);
      }
    });
  }

  loadCar() {
    forkJoin({
      colors: this.carService.getCarSpecificationData('colors'),
      statuses: this.carService.getCarStatuses(),
      car: this.carService.getCarDetails(this.carId!),
      groups: this.carService.getCarGroups(),
      bodytypes: this.carService.getCarSpecificationData('chassis'),
      drivingWheels: this.carService.getCarSpecificationData('drivingWheels'),
    }).subscribe({
      next: async resp => {
        this.carStatuses = resp.statuses.map(r => { return { viewValue: r.status, value: r.id } });
        this.carColors = resp.colors.map(r => ({ viewValue: r.name, value: r.name }));
        this.carDetails = resp.car;
        this.carBodyTypes = resp.bodytypes.map(b => ({ value: b.name, viewValue: b.name }));
        this.carDrivingWheels = resp.drivingWheels.map(b => ({ value: b.name, viewValue: b.name }));
        this.carGroups = resp.groups.map(r => { return { viewValue: r.name, value: r.id } });

        this.loadingInfo.next(false);
      },
      error: err => {
        this.loadingInfo.next(false);
      }
    })
  }

  changeTab(page: string): void {
    this.isRouteChangeEvent.next(page);
  }

  setCurrentTab(page: string): void {
    this.currentTab.next(page);
  }

  async showUnsavedChangesModal(): Promise<boolean> {
    const dialogRef = this.dialog.open(
      UnsavedChangesModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
      }
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }

  async getCarEquipments() {
    if (this.c2vEq.length > 0) {
      return {
        c2vEq: this.c2vEq,
        carC2VEq: this.carC2VEq,
        adacEq: this.adacEq,
        carMasterEq: this.carMasterEq
      };
    } else {
      let eqResp = await firstValueFrom(forkJoin({
        adacEq: this.carService.getCarEquipmentsFromADAC(this.carId),
        c2vEq: this.carService.getC2VEquipments(),
        carCustomEq: this.carService.getCarCustomEquipments(this.carId),
        carEq: this.carService.getCarMasterEquipments(this.carId),
      }));

      this.c2vEq = eqResp.c2vEq;
      this.carC2VEq = eqResp.carCustomEq;
      this.adacEq = eqResp.adacEq;
      this.carMasterEq = eqResp.carEq;

      return {
        c2vEq: this.c2vEq,
        carC2VEq: this.carC2VEq,
        adacEq: this.adacEq,
        carMasterEq: this.carMasterEq
      };
    }
  }

  async getCarTransportMatrix() {
    if (this.carTransportMatrix.length > 0) {
      return this.carTransportMatrix;
    } else {
      this.carTransportMatrix = await firstValueFrom(this.settingsService.getTransportMatrix());

      return this.carTransportMatrix;
    }
  }

  async getCarBC(countries: string[]) {
    if (this.bc.length > 0) {
      return this.bc;
    } else {

      let body: CarBusinesscaseRequestBody = {
        countries: countries,
        make: this.carDetails!.make,
        model: this.carDetails!.model,
        manufactureYear: this.carDetails!.manufactureYear,
        fuelType: this.carDetails!.fuelType,
        gearbox: this.carDetails!.gearbox,
        bodyType: this.carDetails!.bodyType,
        variant: this.carDetails!.variant,
        mileage: this.carDetails!.mileage,
        enginePower: this.carDetails!.enginePower,
        firstReg: this.carDetails!.firstReg === 'None' ? '' : this.carDetails!.firstReg,
        accessories: [],
        carMainInfoId: this.carDetails!.carMainInfoId
      };

      let bcResp = await (firstValueFrom(this.carService.getCarsBusinessCase(body).pipe(catchError((error: any) => {
        return of(error.error);
      }))));

      return bcResp;
    }
  }
}
