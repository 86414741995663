import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CarsOnListImportCar } from 'src/app/core/models/cars-inventory.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import tableColumns from 'src/assets/json-files/table-mapping/cars-on-list-cars-table.json';
import excelMap from 'src/assets/json-files/cars-on-list-import-mapping.json';
import * as XLSX from 'xlsx';
import { UtilsService } from 'src/app/core/services/utils.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';

@Component({
  selector: 'app-cars-on-list-view',
  templateUrl: './cars-on-list-view.component.html',
  styleUrls: ['./cars-on-list-view.component.scss']
})
export class CarsOnListViewComponent implements OnInit {
  loading: BehaviorSubject<boolean> = new BehaviorSubject(true);

  cars: CarsOnListImportCar[] = [];
  displayedColumns = tableColumns.map(t => t.value);
  tableColumns = tableColumns;
  excelMap = excelMap;
  countries = this.coreService.countries.map(c => ({ value: c.id, viewValue: c.name }));

  constructor(private carService: CarService,
    private activatedRoute: ActivatedRoute,
    private coreService: CoreService,
    private utilsService: UtilsService,
    private router: Router,
    private carFlowSyncService: CarFlowSyncService,
    private spinnerService: SpinnerHandlerService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      let listId = params['list'];

      // this.carService.getCarList(listId).subscribe(resp => {
      //   this.cars = resp.cars ? resp.cars : [];
      //   this.cars = this.cars.map(c => ({
      //     ...c,
      //     id: c.carTechInfoId,
      //     country: this.countries.find(co => co.value === c.country)?.viewValue,
      //     dealerName: resp.dealer?.name,
      //     groupName: resp.group?.name,
      //     manufactureYear: c.manufactureYear?.toString()
      //   }));

      //   this.spinnerService.showProgressBar.next(false);
      //   this.loading.next(false);
      // })
    });

    this.displayedColumns.push('edit');
  }

  ngOnDestroy() {
  }

  exportRejectedCars() {
    let wsHeaders = this.excelMap.map(t => this.utilsService.capitalizeFirstLetter(t.excelValue));
    wsHeaders.push('Errors');

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [wsHeaders]);

    let rows: string[][] = []

    XLSX.utils.sheet_add_aoa(ws, rows, { origin: 'A2' });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'declined-cars.xlsx');
  }

  goToCar(car: CarsOnListImportCar) {
    this.router.navigate([`car/car-details/${car.id}`]);
  }
}
