import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownOption } from '../app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-dropdown-ngmodel',
  templateUrl: './app-dropdown-ngmodel.component.html',
  styleUrls: ['./app-dropdown-ngmodel.component.scss']
})
export class AppDropdownNgmodelComponent implements OnInit {
  @Input() dropdownValue: string | undefined;
  @Output() dropdownValueChange = new EventEmitter<string>();

  @Input() options: DropdownOption[] = [];

  @Input() label: string = "";
  @Input() placeholder: string = '';
  @Input() id: string = 'id';

  @Input() multipleSelect: boolean = false;
  @Input() disabled: boolean = false;
  @Input() resetValue: boolean = false;
  @Input() selectAll: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
