<div class="overflow-x-auto">
  <table mat-table matSort [dataSource]="dataSource"
    class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">

    <ng-container matColumnDef="selectAll">
      <th mat-header-cell *matHeaderCellDef>
        <div class="flex gap-3">
          <mat-checkbox id="select-all-checkbox" data-cy="select-all-checkbox"
            (change)="selectAll($event)"></mat-checkbox>
          <p>SELECT ALL</p>
        </div>
      </th>

      <td mat-cell *matCellDef="let element; let i = index">
        <mat-checkbox [id]="'select-row-' + (i + 1) + '-checkbox'"
          [attr.data-cy]="'select-row-' + (i + 1) + '-checkbox'" [(ngModel)]="element.isSelected"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="priceError">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1"></th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <fa-icon class="text-orange" *ngIf="element.price <= element.buyingPrice"
          [icon]="['fal','triangle-exclamation']" matTooltip="Selling price lower or equal than buying price"
          matTooltipPosition="above"></fa-icon>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="col.value" *ngFor="let col of tableMap">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        {{col.tableView}}</th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">{{element[col.value]}}
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">Selling Price
      </th>
      <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
        <input [id]="'selling-price-' + (i + 1) + '-input'" [attr.data-cy]="'selling-price-' + (i + 1) + '-input'"
          class="px-3 py-2 border border-white-500" [(ngModel)]="element.price">
      </td>
    </ng-container>

    <ng-container matColumnDef="buyingPrice">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">buying Price
      </th>
      <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
        <input [id]="'buying-price-' + (i + 1) + '-input'" [attr.data-cy]="'buying-price-' + (i + 1) + '-input'"
          class="px-3 py-2 border border-white-500" [(ngModel)]="element.buyingPrice">
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>
        EDIT
      </th>
      <td class="!py-6" mat-cell *matCellDef="let element; let i = index">
        <fa-icon [id]="'edit-car-' + (i + 1) + '-btn'" [attr.data-cy]="'edit-car-' + (i + 1) + '-btn'"
          class="cursor-pointer" [icon]="['fal','pen-to-square']" (click)="editCar(element)"></fa-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="hover:bg-white-400"
      [ngClass]="{'partially':row.identified === 'Partially Identified','unidentified': row.identified === 'Unidentified' || row.identified === 'Invalid data','bg-white-500':row.isSelected}">
    </tr>
  </table>

  <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
  </mat-paginator>
</div>

<div class="bg-blue-100 z-50 sticky py-11 w-full bottom-9 flex gap-6 items-baseline pl-20" *ngIf="batchResponse">
  <fa-icon class="text-green-100 text-xl" [icon]="['fal','hexagon-check']"></fa-icon>
  <p class="font-bold text-green-100">{{batchResponse.addedCars}} cars imported</p>
  <p class="text-white-300 px-6 border-l border-r border-white-300">{{batchResponse.faultyCars.length}}
    cars have been rejected</p>
  <p id="export-rejected-btn" data-cy="export-rejected-btn" class="text-white cursor-pointer"
    (click)="exportRejectedCars()">
    Download rejected</p>
</div>
