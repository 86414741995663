import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, share } from 'rxjs';

const reqMaps = [
  'countries',
  'priorities',
  'makes',
  'delivery-windows',
  'seller-types',
  'get-sub-statuses',
  'get-list-of-vin-check-available-countries',
  'car-nomenclator/makes',
  'car-statuses',
  'car-sources',
  'car-groups',
  'car-shop-types',
  'get-constants',
  'car-nomenclator/fuelTypes',
  'car-nomenclator/gearboxes',
  'car-nomenclator/chassis',
  'car-nomenclator/colors',
  'car-nomenclator/drivingWheels',
  'car-nomenclator/makes'
];

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache: Map<string, HttpResponse<any>> = new Map();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === "GET" && reqMaps.includes(req.url.split('api/')[1])) {
      const cachedResponse = this.cache.get(req.url);

      if (cachedResponse) {
        return of(cachedResponse.clone())
      } else {
        return next.handle(req).pipe(tap(stateEvent => {
          if (stateEvent instanceof HttpResponse) {
            this.cache.set(req.url, stateEvent.clone())
          }
        }), share());
      }
    } else {
      return next.handle(req);
    }
  }
}
