import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IDocument, OrderCar, RemoveOrderDocumentBody, UpdateDocumentsDetailsBody, UploadOrderDocumentBody } from 'src/app/core/models/order.model';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddOrderDocumentModalComponent } from './add-order-document-modal/add-order-document-modal.component';
import { OrdersService } from 'src/app/core/services/orders.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import _ from 'lodash';
import { OrderCarDocumentsModalComponent } from './order-car-documents-modal/order-car-documents-modal.component';
import { OrderStatus } from '../../orders-view/orders-view.component';

interface TransportOrderDoc extends IDocument {
  transportId: string,
  documentType: string
}

@Component({
  selector: 'app-order-process-documents',
  templateUrl: './order-process-documents.component.html',
  styleUrls: ['./order-process-documents.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  imports: [
    SharedModule
  ]
})

export class OrderProcessDocumentsComponent implements OnInit {
  headersMap: TableHeaderMap[] = [
    {
      value: 'carConfig.sellerName',
      tableView: 'seller'
    },
    {
      value: 'carPricing.invoiceSellingPrice',
      tableView: 'price'
    },
    {
      value: 'carConfig.vin',
      tableView: 'vin'
    },
    {
      value: 'carConfig.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carConfig.make',
      tableView: 'make'
    },
    {
      value: 'carConfig.model',
      tableView: 'model'
    },
    {
      value: 'carRegisteredC2C',
      tableView: 'car Registered C2C'
    },
    {
      value: 'carDeRegisteredC2C',
      tableView: 'car deregistered C2C'
    },
    {
      value: 'dhlRequested',
      tableView: 'DHL Requested'
    },
    {
      value: 'dhlTrackingNo',
      tableView: 'DHL Tracking No'
    },
    {
      value: 'documentsDetails.documents.registration.receivedAt',
      tableView: 'registration Documents Received'
    },
    {
      value: 'documentsDetails.documents.registration.sentAt',
      tableView: 'registration Documents Sent'
    },
    {
      value: 'documentsDetails.documents.coc.receivedAt',
      tableView: 'COC Requested'
    },
    {
      value: 'documentsDetails.documents.coc.cocFrom',
      tableView: 'COC from'
    }
  ]

  displayedColumns = this.headersMap.map(h => h.value);
  displayedColumnsWithExpand = [...this.displayedColumns, 'expand'];

  cars: OrderCar[] = this.orderFlowService.carList;

  orderDocuments = this.orderFlowService.order!.documents;

  transportDocuments: TransportOrderDoc[] = [];

  expandedCar: OrderCar | null = null;

  constructor(private orderFlowService: OrdersFlowSyncService,
    private dialog: MatDialog,
    private orderService: OrdersService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit(): void {
    this.orderFlowService.order!.transportDetails.transports.forEach(t => {
      this.transportDocuments = [...this.transportDocuments, ...(t.documents.CMR.map(d => ({ ...d, transportId: t.transportId, documentType: 'CMR' }))), ...(t.documents.invoice.map(d => ({ ...d, transportId: t.transportId, documentType: 'INV' })))];
    });
  }

  openAddDocumentModal() {
    const dialogRef = this.dialog.open(
      AddOrderDocumentModalComponent, {
      width: '800px',
      maxWidth: '95vw',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        let body: UploadOrderDocumentBody = {
          content: resp.fileData,
          name: resp.fileName,
          orderId: this.orderFlowService.orderId!,
          visible: resp.visible
        }

        this.spinner.showProgressBar.next(true);

        this.orderService.uploadOrderDocument(body).subscribe({
          next: resp => {
            this.orderDocuments = resp;
            this.orderFlowService.order!.documents = resp;

            this.snackbar.positiveSentiment('Document uploaded');

            this.spinner.showProgressBar.next(false);
          },
          error: err => {
            this.snackbar.negativeSentiment(err.error);

            this.spinner.showProgressBar.next(false);
          }
        })
      }
    });
  }

  downloadOrderDocument(docId: string) {
    this.spinner.showProgressBar.next(true);

    this.orderService.downloadOrderDocument(this.orderFlowService.orderId!, docId).subscribe({
      next: resp => {
        window.open(resp, "_blank");

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    })
  }

  downloadTransportDocument(doc: TransportOrderDoc) {
    this.spinner.showProgressBar.next(true);

    let body = {
      orderId: this.orderFlowService.orderId!,
      transportId: doc.transportId,
      docType: doc.documentType,
      id: doc.id
    };

    this.orderService.downloadTransportDocument(body).subscribe({
      next: resp => {
        window.open(resp, "_blank");

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    })
  }

  openCarDocumentDetails(car: OrderCar) {
    const dialogRef = this.dialog.open(
      OrderCarDocumentsModalComponent, {
      width: '1000px',
      maxWidth: '95vw',
      autoFocus: false,
      data: {
        car: JSON.parse(JSON.stringify(car)),
        orderId: this.orderFlowService.orderId,
        finishedOrder: this.orderFlowService.orderStatus === OrderStatus.FINISHED
      }
    });

    dialogRef.afterClosed().subscribe(modalResp => {
      if (modalResp && modalResp.documentsDetails) {
        this.spinner.showProgressBar.next(true);

        let body = new UpdateDocumentsDetailsBody(this.orderFlowService.orderId!, car.carConfig.seller, car.carMainInfoId, modalResp.documentsDetails);

        this.orderService.updateCarDocumentsSettings(body).subscribe({
          next: sellers => {
            this.orderFlowService.order!.sellers = sellers;

            this.orderFlowService.updateCarList();

            this.cars = this.orderFlowService.carList;

            this.spinner.showProgressBar.next(false);
            this.snackbar.positiveSentiment('Car document details updated successfully!');
          },
          error: err => {
            this.spinner.showProgressBar.next(false);
            this.snackbar.negativeSentiment(err.error);
          }
        });
      } else if (modalResp) {
        let sellerIndex = this.orderFlowService.order!.sellers.findIndex(s => s.details.id === car.carConfig.seller);

        let carIndex = this.orderFlowService.order!.sellers[sellerIndex].cars.findIndex(c => c.carMainInfoId === car.carMainInfoId);

        this.orderFlowService.order!.sellers[sellerIndex].cars[carIndex].documentsDetails.documents.registration.documents = modalResp.documents.registrationDocuments;
        this.orderFlowService.order!.sellers[sellerIndex].cars[carIndex].documentsDetails.documents.cc.documents = modalResp.documents.ccDocuments;
        this.orderFlowService.order!.sellers[sellerIndex].cars[carIndex].documentsDetails.documents.coc.documents = modalResp.documents.cocDocuments;
        this.orderFlowService.order!.sellers[sellerIndex].cars[carIndex].documentsDetails.documents.invoicing.documents = modalResp.documents.invoicingDocuments;

        this.cars = this.orderFlowService.carList;
      }
    });
  }

  getValue(element: any, value: TableHeaderMap): string {
    return _.get(element, value.value.split('.'));
  }

  removeOrderDocument(docId: string, docType: string) {
    this.spinner.showProgressBar.next(true);

    let body: RemoveOrderDocumentBody = {
      orderId: this.orderFlowService.orderId!,
      id: docId,
      filter: {}
    }

    let transportDocType = '';
    let transportId = '';

    if (docType === 'transport') {
      transportDocType = this.transportDocuments.find(t => t.id === docId)!.documentType;
      transportId = this.transportDocuments.find(t => t.id === docId)!.transportId;

      body.filter = {
        transportFilter: {
          transportId: transportId,
          docType: transportDocType
        }
      }
    }

    this.orderService.removeOrderDocument(body).subscribe({
      next: resp => {
        if (docType === 'transport') {
          let transport = this.orderFlowService.order!.transportDetails.transports.find(t => t.transportId === transportId)!;

          if (transportDocType === 'INV') {
            transport.documents.invoice = resp;
          } else {
            transport.documents.CMR = resp;
          }

          this.transportDocuments = [];

          this.orderFlowService.order!.transportDetails.transports.forEach(t => {
            this.transportDocuments = [...this.transportDocuments, ...(t.documents.CMR.map(d => ({ ...d, transportId: t.transportId, documentType: 'CMR' }))), ...(t.documents.invoice.map(d => ({ ...d, transportId: t.transportId, documentType: 'INV' })))];
          });

        } else {
          this.orderFlowService.order!.documents = resp;
          this.orderDocuments = resp;
        }

        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment('Car document details updated successfully!');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  downloadCarDocument(docId: string, docType: string, carId: string) {
    let body = {
      orderId: this.orderFlowService.orderId!,
      id: docId,
      docType: docType,
      carMainInfoId: carId
    };

    this.orderService.downloadOrderCarDocument(body).subscribe({
      next: resp => {
        window.open(resp, "_blank");

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    })
  }
}
