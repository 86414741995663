<div class="flex flex-col gap-9">
  <app-btn-toggle [options]="routes" [selectedOption]="page" (changeEmitter)="changePage($event)"></app-btn-toggle>

  <div [ngSwitch]="page">
    <app-order-process-car-confirmation *ngSwitchCase="'confirmation'"></app-order-process-car-confirmation>

    <app-order-process-car-prices *ngSwitchCase="'price'"></app-order-process-car-prices>

    <app-order-process-car-details *ngSwitchCase="'details'"></app-order-process-car-details>

    <app-order-process-car-documents *ngSwitchCase="'documents'"></app-order-process-car-documents>
  </div>
</div>
