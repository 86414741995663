<div class="twoCols">
  <app-main-content-layout>
    <div class="title">
      <p>Seller details</p>
    </div>

    <app-main-content-row-layout>
      <div class="mb-4">
        <mat-checkbox id="seller-b2b-ready-checkbox" data-cy="seller-b2b-ready-checkbox" [checked]="true"
          [disabled]="true">Seller B2B ready</mat-checkbox>
      </div>
    </app-main-content-row-layout>

    <div class="twoCols">
      <app-dropdown id="country" placeholder="Select country" [options]="countries" label="Seller country"
        [control]="countryControl">
      </app-dropdown>
    </div>

    <div class="twoCols">
      <input id="search-seller-input" data-cy="search-seller-input" class="search-seller" type="text" matInput
        [matAutocomplete]="auto" [formControl]="sellerControl" placeholder="Search seller">
      <secondary-btn id="create-seller" class="w-4/5" btnText="Create B2B Seller"
        (clickEmitter)="goToSeller()"></secondary-btn>
      <mat-autocomplete #auto="matAutocomplete">
        <div *ngIf="existsResults | async">
          <mat-option class="autocomplete-option" *ngFor="let seller of (resultItems| async)!"
            (click)="selectSeller(seller)">
            <div class="option-item">
              {{seller.name}}
            </div>
          </mat-option>
        </div>
      </mat-autocomplete>
    </div>

    <app-main-content-row-layout class="pt-6 border-t border-white-500">
      <p>Seller name</p>

      <app-read-only-input class="secondContent text-white-800" [ngClass]="{'!text-blue-100' : selectedSeller}">{{selectedSeller ? selectedSeller.name : 'Seller name'}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Store</p>

      <app-dropdown placeholder="Select store" id="store" class="secondContent" [control]="storeControl" [options]="stores"
        [disabled]="!(stores.length > 0)" (emitOnChangeSelect)="changeLocation($event)"></app-dropdown>
    </app-main-content-row-layout>




    <app-main-content-row-layout>
      <p>Address</p>

      <app-read-only-input class="secondContent text-white-800" [ngClass]="{'!text-blue-100' : selectedSeller}">{{selectedSeller ? selectedSeller.address : 'Address'}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Phone</p>

      <app-read-only-input class="secondContent text-white-800" [ngClass]="{'!text-blue-100' : selectedSeller}">{{selectedSeller ? selectedSeller.phone : 'Phone'}}</app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Email</p>

      <app-read-only-input class="secondContent text-white-800" [ngClass]="{'!text-blue-100' : selectedSeller}">{{selectedSeller ? selectedSeller.email : 'Email'}}</app-read-only-input>
    </app-main-content-row-layout>

  </app-main-content-layout>

  <app-main-content-layout>
    <div class="title">
      <p>File import</p>
    </div>

    <div class="bg-white-400 p-4 rounded">
      <div
        class="bg-white-700 rounded border-2 border-dashed border-blue-200 p-5 flex flex-col items-center gap-9 py-9">
        <fa-icon class="text-6xl" [icon]="['fal','up-to-line']"></fa-icon>

        <div class="bg-white rounded px-9 py-3 flex gap-6 w-fit" *ngIf="excelFileName; else chooseFile">
          <p>{{excelFileName}}</p>
          <fa-icon id="remove-file-btn" data-cy="remove-file-btn" class="cursor-pointer" [icon]="['far','xmark']"
            (click)="removeFile()"></fa-icon>
        </div>

        <a href="assets/import file template (cars on list).xlsx"
          class="cursor-pointer text-sm underline text-white-600">Download template file</a>

        <ng-template #chooseFile>
          <div id="choose-file-btn" data-cy="choose-file-btn"
            class="bg-white rounded px-9 py-3 flex gap-6 w-fit active:scale-95 cursor-pointer"
            (click)="selectExcel.click()">
            <p>Choose file</p>
            <fa-icon [icon]="['fal','file-plus']"></fa-icon>
          </div>
        </ng-template>
      </div>
    </div>

    <input class="hidden" [multiple]="true" #selectExcel type="file" accept=".xlsx" [formControl]="uploadControl"
      (change)="handleFileSelect($event)">
  </app-main-content-layout>

  <app-main-content-layout>
    <div class="title">
      <p>List details</p>
    </div>

    <app-main-content-row-layout>
      <p>Available until*</p>

      <app-datepicker id="available-until" placeholder="dd/mm/yyyy" [min]="today" class="secondContent"
        [control]="listDetailsForm.controls.availableUntil">
      </app-datepicker>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>List name*</p>

      <app-input id="list-name" placeholder="Insert list name" class="secondContent"
        [control]="listDetailsForm.controls.name"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Car group*</p>

      <app-dropdown id="car-group" placeholder="Select car group" class="secondContent"
        [control]="listDetailsForm.controls.carGroup" [options]="carGroups"></app-dropdown>
    </app-main-content-row-layout>
  </app-main-content-layout>
</div>
