<div class="grid grid-cols-2 gap-6" *ngIf="!(loadingPage | async);else placeholder">
  <div class="col-span-2 flex justify-end">
    <primary-btn (clickEmitter)="saveEquipments()" btnText="Save equipments"></primary-btn>
  </div>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Optional equipment</p>
    </div>

    <div class="flex gap-6">
      <div class="relative w-4/6">
        <fa-icon (click)="selectOpt.toggle()" class="absolute left-2 top-[0.625rem] text-xl cursor-pointer"
          [icon]="['fal','square-plus']"></fa-icon>
        <mat-select id="optional-eq-select" data-cy="optional-eq-select" #selectOpt
          class="border border-white-500 text-blue-100 p-3 pl-9 rounded" [multiple]="true"
          [placeholder]="'Insert new equipment'" [formControl]="optionalControl">
          <mat-option *ngFor="let option of optionalEquipmentOptions" [value]="option.name">
            {{option.viewName}}
          </mat-option>
        </mat-select>
      </div>

      <secondary-btn id="add-opt-eq" btnText="Add equipment" (clickEmitter)="addOptionalEq()"></secondary-btn>
    </div>

    <div class="flex flex-wrap gap-6">
      <div class="flex rounded-3xl bg-blue-200 text-white w-fit p-3 text-sm"
        *ngFor="let eq of optionalEquipmentSelected">
        <p>{{eq.viewName}}</p>
        <fa-icon class="ml-3 cursor-pointer" (click)="removeEquipment(0,eq)" [icon]="['fas','xmark']"></fa-icon>
      </div>
    </div>
  </app-main-content-layout>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Pack equipment</p>
    </div>

    <div class="flex gap-6">
      <div class="relative w-4/6">
        <fa-icon (click)="selectPack.toggle()" class="absolute left-2 top-[0.625rem] text-xl cursor-pointer"
          [icon]="['fal','square-plus']"></fa-icon>
        <mat-select id="pack-eq-select" data-cy="pack-eq-select" #selectPack
          class="border border-white-500 text-blue-100 p-3 pl-9 rounded" [multiple]="true"
          [placeholder]="'Insert new equipment'" [formControl]="packControl">
          <mat-option *ngFor="let option of packEquipmentOptions" [value]="option.name">
            {{option.viewName}}
          </mat-option>
        </mat-select>
      </div>

      <secondary-btn id="add-pack-eq" btnText="Add equipment" (clickEmitter)="addPackEq()"></secondary-btn>
    </div>

    <div class="flex flex-wrap gap-6">
      <div class="flex rounded-3xl bg-blue-200 text-white w-fit p-3 text-sm" *ngFor="let eq of packEquipmentSelected">
        <p>{{eq.viewName}}</p>
        <fa-icon class="ml-3 cursor-pointer" (click)="removeEquipment(1,eq)" [icon]="['fas','xmark']"></fa-icon>
      </div>
    </div>
  </app-main-content-layout>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <div class="flex justify-between items-center">
        <p>Standard equipment</p>

        <p class="text-red text-sm" *ngIf="equipmentStandardNotSaved" matTooltip="Press save button"
          matTooltipPosition="above">Not saved</p>
      </div>
    </div>

    <div class="flex flex-wrap gap-6">
      <div class="flex rounded-3xl bg-blue-200 text-white w-fit p-3 text-sm"
        *ngFor="let eq of standardEquipmentOptions">
        <p>{{eq.viewName}}</p>
      </div>
    </div>
  </app-main-content-layout>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Custom equipment</p>
    </div>

    <div class="flex gap-6">
      <div class="relative w-4/6">
        <fa-icon (click)="selectCustom.toggle()" class="absolute left-2 top-[0.625rem] text-xl cursor-pointer"
          [icon]="['fal','square-plus']"></fa-icon>
        <mat-select id="custom-eq-select" data-cy="custom-eq-select" #selectCustom
          class="border border-white-500 text-blue-100 p-3 pl-9 rounded" [multiple]="true"
          [placeholder]="'Insert new equipment'" [formControl]="customControl">
          <mat-option *ngFor="let option of customEquipmentOptions" [value]="option.name">
            {{option.viewName}}
          </mat-option>
        </mat-select>
      </div>

      <secondary-btn id="add-custom-eq" btnText="Add equipment" (clickEmitter)="addCustomEq()"></secondary-btn>
    </div>

    <div class="flex flex-wrap gap-6">
      <div class="flex rounded-3xl bg-blue-200 text-white w-fit p-3 text-sm" *ngFor="let eq of customEquipmentSelected">
        <p>{{eq.viewName}}</p>
        <fa-icon class="ml-3 cursor-pointer" (click)="removeEquipment(2,eq)" [icon]="['fas','xmark']"></fa-icon>
      </div>
    </div>
  </app-main-content-layout>
</div>

<ng-template #placeholder>
  <div class="twoCols">
    <app-main-content-placeholder></app-main-content-placeholder>
    <app-main-content-placeholder></app-main-content-placeholder>
  </div>
</ng-template>
