import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { RequestCarComponent } from "./request-car/request-car.component";
import { ViewCarRequestsComponent } from './view-car-requests/view-car-requests.component';


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    RequestCarComponent,
    ViewCarRequestsComponent
  ]
})

export class RequestCarModule { }
