<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <div class="flex justify-between items-center">
    <p class="text-2xl">{{car.carConfig.make}} {{car.carConfig.model}}</p>

    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close()"></fa-icon>
  </div>

  <app-image-slider *ngIf="carPhotos.length > 0" [images]="carPhotos"></app-image-slider>

  <app-btn-toggle [options]="routes" (changeEmitter)="changeTab($event)" [selectedOption]="tab"></app-btn-toggle>

  <div [ngSwitch]="tab">
    <div *ngSwitchCase="'details'" class="twoCols">
      <div class="flex flex-col gap-6">
        <div class="flex justify-between">
          <p>Make</p>

          <p>{{car.carConfig.make}}</p>
        </div>

        <div class="flex justify-between">
          <p>Model</p>

          <p>{{car.carConfig.model}}</p>
        </div>

        <div class="flex justify-between">
          <p>Variant</p>

          <p>{{car.carConfig.variant}}</p>
        </div>

        <div class="flex justify-between">
          <p>Trim</p>

          <p>{{car.carConfig.trim}}</p>
        </div>

        <div class="flex justify-between">
          <p>Fueltype</p>

          <p>{{car.carConfig.fuelType}}</p>
        </div>

        <div class="flex justify-between">
          <p>Gearbox</p>

          <p>{{car.carConfig.gearbox}}</p>
        </div>

        <div class="flex justify-between">
          <p>Bodytype</p>

          <p>{{car.carConfig.bodyType}}</p>
        </div>

        <div class="flex justify-between">
          <p>Seats</p>

          <p>{{car.carConfig.seats}}</p>
        </div>

        <div class="flex justify-between">
          <p>Doors</p>

          <p>{{car.carConfig.doors}}</p>
        </div>

        <div class="flex justify-between">
          <p>Mileage</p>

          <p>{{car.carConfig.mileage | number}}</p>
        </div>

        <div class="flex justify-between">
          <p>Manufacture year</p>

          <p>{{car.carConfig.manufactureYear}}</p>
        </div>


      </div>

      <div class="pl-6 border-l border-white-500 flex flex-col gap-6">
        <div class="flex justify-between">
          <p>First reg</p>

          <p>{{car.carConfig.firstReg}}</p>
        </div>

        <div class="flex justify-between">
          <p>Engine volume</p>

          <p>{{car.carConfig.engineVolume | number}}</p>
        </div>

        <div class="flex justify-between">
          <p>Engine power</p>

          <p>{{car.carConfig.enginePower | number}}</p>
        </div>
        <div class="flex justify-between">
          <p>Driving wheels</p>

          <p>{{car.carConfig.drivingWheels}}</p>
        </div>

        <div class="flex justify-between">
          <p>Color</p>

          <p>{{car.carConfig.color}}</p>
        </div>

        <div class="flex justify-between">
          <p>CO2 WLTP</p>

          <p>{{car.carConfig.co2WLTP}}</p>
        </div>

        <div class="flex justify-between">
          <p>CO2 NEDC</p>

          <p>{{car.carConfig.co2NEDC}}</p>
        </div>

        <div class="flex justify-between">
          <p>Reg no</p>

          <p>{{car.carConfig.regNo}}</p>
        </div>

        <div class="flex justify-between">
          <p>VIN</p>

          <p>{{car.carConfig.vin}}</p>
        </div>

        <div class="flex justify-between">
          <p>Damages</p>

          <p>{{car.carConfig.damages | number}}</p>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'equipments'" class="twoCols text-sm">
      <div class="flex flex-col gap-6">
        <div *ngFor="let eq of equipments | slice: 0: (equipments.length /2)">
          <p>{{eq}}</p>
        </div>
      </div>

      <div class="pl-6 border-l border-white-500 flex flex-col gap-6">
        <div *ngFor="let eq of equipments | slice: (equipments.length /2): equipments.length">
          <div class="flex gap-3 items-center cursor-pointer">
            <p>{{eq}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #chevronDown>
  <fa-icon class="" [icon]="['fal','chevron-down']"></fa-icon>
</ng-template>
