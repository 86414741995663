import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CarsOnListImportCar, CarsOnListImportList } from 'src/app/core/models/cars-inventory.model';
import * as XLSX from 'xlsx';
import mapping from 'src/assets/json-files/cars-on-list-import-mapping.json';
import { BehaviorSubject, debounceTime, Subject, takeUntil, tap } from 'rxjs';
import { ISeller, SellerSearch } from 'src/app/core/models/seller.model';
import { SellerService } from 'src/app/core/services/seller.service';
import { CarService } from 'src/app/core/services/car.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { Router } from '@angular/router';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'app-car-on-list-import-settings',
  templateUrl: './car-on-list-import-settings.component.html',
  styleUrls: ['./car-on-list-import-settings.component.scss']
})
export class CarOnListImportSettingsComponent implements OnInit {
  uploadControl = new FormControl();
  excelFileName: string | undefined;

  mapping = mapping;
  eqMapping = [
    {
      objectValue: "navigation",
      excelValue: "Navigation"
    },
    {
      objectValue: "applePlayConnect",
      excelValue: "Apple play or Connect"
    },
    {
      objectValue: "leatherInterior",
      excelValue: "Leather interior"
    },
    {
      objectValue: "heatedSeats",
      excelValue: "Heated seats"
    },
    {
      objectValue: "panorama",
      excelValue: "Panorama"
    },
    {
      objectValue: "adaptiveCruise",
      excelValue: "Adaptive cruise"
    },
    {
      objectValue: "driverAssist",
      excelValue: "Driver assist"
    },
    {
      objectValue: "camera",
      excelValue: "Camera"
    },
    {
      objectValue: "towbar",
      excelValue: "Towbar"
    },
    {
      objectValue: "xenon",
      excelValue: "Xenon/LED headlights"
    },
    {
      objectValue: "digitalDisplay",
      excelValue: "Digital display"
    },
    {
      objectValue: "heatPump",
      excelValue: "Heat Pump"
    },
    {
      objectValue: "parkingAid",
      excelValue: "Parking aid (front or rear)"
    }
  ];

  today = new Date();

  carGroups: DropdownOption[] = [];
  cars: CarsOnListImportCar[] = [];
  selectedSeller: ISeller | undefined;

  destroyed = new Subject<void>();
  resultItems = new BehaviorSubject<ISeller[]>([]);
  existsResults = new BehaviorSubject<boolean>(false);
  sellerControl = new FormControl("");

  listDetailsForm = this.fb.group({
    availableUntil: new FormControl<any>(null, Validators.required),
    name: ['', Validators.required],
    carGroup: ['', Validators.required]
  });

  countries: DropdownOption[] = JSON.parse(JSON.stringify(this.coreService.countriesDropdown));
  stores: DropdownOption[] = [];
  countryControl = new FormControl();
  storeControl = new FormControl();

  @Output() saveEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private fb: FormBuilder,
    private sellerService: SellerService,
    private carService: CarService,
    private snackbar: SnackbarService,
    private carFlowSyncService: CarFlowSyncService,
    private router: Router,
    private spinnerService: SpinnerHandlerService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.carService.getCarGroups().subscribe(resp => {
      this.carGroups = resp.map(r => { return { viewValue: r.name, value: r.id } });
    });

    this.countries.unshift({ value: 'all', viewValue: 'All' })

    this.sellerControl.valueChanges.pipe(
      debounceTime(400),
      tap(
        () => {
        }
      ),
      takeUntil(this.destroyed))
      .subscribe((value) => {
        if (value) {
          this.searchSeller(value);
        } else {
          this.resultItems.next([]);
        }
      });

    this.resultItems.pipe(
      tap((result) => {
        this.existsResults.next(result.length > 0);
      })
    ).subscribe();

    if (this.carFlowSyncService.excelFile) {
      this.uploadControl.setValue(this.carFlowSyncService.excelFile);
      this.excelFileName = this.carFlowSyncService.excelFile.name;
    }

    // this.selectedSeller = this.carFlowSyncService.selectedSeller;
    this.storeControl.enable();
  }

  ngOnDestroy() {
    this.existsResults.complete();
    this.destroyed.next();
    this.destroyed.complete();
  }

  uploadCars() {
    if (this.spinnerService.showProgressBar.value) return;

    if (!this.selectedSeller) {
      this.snackbar.negativeSentiment('No seller selected');

      return;
    }

    if (this.listDetailsForm.invalid) {
      this.snackbar.negativeSentiment('Complete all fields');

      return;
    }

    this.spinnerService.showProgressBar.next(true);



    // this.carFlowSyncService.selectedSeller = this.selectedSeller;

    // this.carService.uploadOnlineListCars({ cars: this.cars }).subscribe(resp => {
    //   this.carFlowSyncService.onlineListImportResponse = resp;

    //   this.spinnerService.showProgressBar.next(false);

    //   this.saveEmitter.emit('');
    // })
  }

  searchSeller(value: string) {
    let searchSeller: SellerSearch = {
      name: value,
      details: true,
      b2bReady: true
    };

    if (this.countryControl.value && this.countryControl.value != 'all') searchSeller.country = this.countryControl.value;

    // this.sellerService.searchSeller(searchSeller).subscribe((data) => {
    //   this.resultItems.next(data);
    // });
  }

  selectSeller(seller: ISeller) {
    this.selectedSeller = seller;
    this.sellerControl.setValue(seller.name!);

  }

  async handleFileSelect(evt: Event) {
    const target = evt.target as HTMLInputElement;

    const reader: FileReader = new FileReader();

    if (target.files && target.files.length > 0) {
      reader.readAsBinaryString(target.files![0]);

      this.cars = [];
      this.excelFileName = target.files![0].name;

      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        //, cellDates: true

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws);

        let rowError = -1;

        let firstRow: any = data[0];

        if (firstRow['Brand'].match('mandatory')) data.splice(0, 1);

        data.forEach((d: any, index) => {
          let car: CarsOnListImportCar = {};

          if (this.mapping.some(m => m.isInteger && isNaN(d[m.excelValue]))) {
            rowError = index;
            return;
          }

          Object.keys(d).forEach((i) => {
            car[(mapping.find(m => m.excelValue === i)?.objectValue) as keyof typeof car] = d[i];

            if (i === 'First reg YYYY-MM-DD') {
              car.firstReg = this.formatDate(new Date((d[i] - 25569) * 86400000));
            }
          });

          car.notes = d['Notes'] ? d['Notes'] : '';

          this.addEquipment(d, car);

          this.cars.push(car);
        });

        if (data.length > this.cars.length) {
          this.cars = [];

          let car: any = data[rowError];

          let field = this.mapping.find(m => m.isInteger && isNaN(car[m.excelValue]));

          this.snackbar.negativeSentiment(`Car ${rowError + 1}, ${field?.excelValue} is not number input!`);

          return;
        }

        this.carFlowSyncService.excelFile = target.files![0];

        this.snackbar.positiveSentiment('File uploaded successfully!');
      };
    }
  }

  removeFile() {
    this.uploadControl.reset();
    this.cars = [];
    this.excelFileName = undefined;
  }

  formatDate(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  changeLocation(storeId: string) {
  }

  goToSeller() {
    this.router.navigate(['seller/b2b-seller']);
  }

  addEquipment(data: any, car: any) {
    this.eqMapping.forEach(eq => {
      if (data[eq.excelValue]) {
        car[eq.objectValue] = true;
      } else {
        car[eq.objectValue] = false;
      }
    })
  }

  getCountry(countryId: string) {
    return this.countries.find(c => c.value === countryId)?.viewValue;
  }
}
