import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CarsVINCheck, VINCheckDetails } from 'src/app/core/models/car.model';
import { OrderCar } from 'src/app/core/models/order.model';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderSeeCarDetailsModalComponent } from '../../order-shared-components/order-see-car-details-modal/order-see-car-details-modal.component';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { OrdersService } from 'src/app/core/services/orders.service';

@Component({
  selector: 'app-order-equipments',
  templateUrl: './order-equipments.component.html',
  styleUrls: ['./order-equipments.component.scss']
})
export class OrderEquipmentsComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);
  subscription = new Subscription();

  batchId = this.orderFlowService.batchId;
  cars = this.orderFlowService.carList;

  carsVINDetails: VINCheckDetails[] = [];

  headers: TableHeaderMap[] = [
    {
      value: 'vinChecked',
      tableView: 'vin Checked'
    },
    {
      value: 'sellerName',
      tableView: 'seller'
    },
    {
      value: 'sellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'regNo',
      tableView: 'Registration number'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'gearbox',
      tableView: 'gearbox'
    },
    {
      value: 'fuelType',
      tableView: 'fuelType'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'firstReg',
      tableView: 'First registration'
    },
    {
      value: 'color',
      tableView: 'color'
    }
  ]

  displayedColumns = ['selectAll'].concat(this.headers.map(h => h.value), ['details']);

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;

  constructor(private orderFlowService: OrdersFlowSyncService,
    private orderService: OrdersService,
    private carService: CarService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.subscription.add(this.orderFlowService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.orderFlowService.setCurrentTab(value);
      }
    ));

    this.subscription.add(this.orderFlowService.continueEvent$.subscribe(
      async (value) => {
        this.orderFlowService.setCurrentTab('review');
      }
    ));

    this.subscription.add(this.orderFlowService.saveOrderEvent$.subscribe(
      resp => {
        this.orderFlowService.saveOrder();
      }
    ));

    if (this.orderFlowService.batchId.length > 0) {
      this.getVINDetails();
    } else {
      this.loading.next(false);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  vinCheck() {
    // let vinCheckBody: CarsVINCheck = {
    //   vins: this.cars.filter(c => c.vin).map(c => ({
    //     carMainInfoId: c.carMainInfoId!,
    //     locationId: c.location!,
    //     vin: c.vin!
    //   }))
    // }

    // this.carService.vinCheck(vinCheckBody).subscribe({
    //   next: resp => {
    //     this.orderFlowService.batchId = resp.batchId;

    //     this.orderService.updateOrderBatchID(this.orderFlowService.orderId!, resp.batchId).subscribe(val => {
    //       this.snackbar.positiveSentiment('VIN check process started');
    //     })
    //   },
    //   error: err => {

    //   }
    // })
  }

  getVINDetails() {
    if (!this.loading.value) this.spinner.showProgressBar.next(true);

    this.carService.getVINDetails(this.batchId).subscribe({
      next: resp => {
        if (typeof (resp) != 'string') {

          this.carsVINDetails = resp;

          this.orderFlowService.carList = this.orderFlowService.carList.map(c => ({
            ...c,
            vinChecked: resp.find(vinDetails => vinDetails.carMainInfoId === c.carMainInfoId) != undefined
          }));

          this.carsTable?.updateTable(this.orderFlowService.carList);
        }

        this.loading.next(false);

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.loading.next(false);

        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err);
      }
    })
  }

  hasBatchId(): boolean {
    return this.orderFlowService.batchId.length > 0;
  }

  openDetailsModal(car: OrderCar) {
    let vinDetails = this.carsVINDetails.find(v => v.carMainInfoId === car.carMainInfoId);

    if (vinDetails) {


    } else {
      this.snackbar.negativeSentiment('Car VIN not decoded');
    }
  }
}
