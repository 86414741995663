import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderCar } from 'src/app/core/models/order.model';
import { OrdersService } from 'src/app/core/services/orders.service';
import { TransportMatrix } from 'src/app/features/settings/transport-matrix/transport-matrix.component';

export interface OrderSelectPricesDetailsModalData {
  car: OrderCar,
  transportMatrix: TransportMatrix[],
  transportByCars2click: boolean,
  buyerLoc: string,
  cocValue: number,
  photoEditValue: number
}

@Component({
  selector: 'app-order-select-prices-details-modal',
  templateUrl: './order-select-prices-details-modal.component.html',
  styleUrls: ['./order-select-prices-details-modal.component.scss']
})

export class OrderSelectPricesDetailsModalComponent implements OnInit {
  car: OrderCar = this.data.car;
  transportMatrix = this.data.transportMatrix;
  // dhlFee = this.car.dhlFee!;
  // cocValue = this.data.cocValue;
  // photoEdit = this.data.photoEditValue;

  // invoicePriceControl = new FormControl(this.car.invoiceSellingPrice);
  // photoEditControl = new FormControl(false);
  // reqCOCControl = new FormControl(this.car.documentsDetails.cocDocuments.required);
  // finalDamageControl = new FormControl(this.car.finalDamages);
  transportControl = new FormControl({ value: this.data.transportByCars2click, disabled: true });

  transportationFee = 0;

  constructor(public dialogRef: MatDialogRef<OrderSelectPricesDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderSelectPricesDetailsModalData) { }

  ngOnInit(): void {
    // if (this.car.fromOffer) {
    //   this.invoicePriceControl.setValue(this.car.offeredPrice);
    //   this.invoicePriceControl.disable();
    // }

    let carLoc = this.car.carConfig.location;

    let transportFee = this.transportMatrix.find(t => t.countryFrom === carLoc && t.countryTo === this.data.buyerLoc);

    this.transportationFee = transportFee?.value ? transportFee?.value : 0;
  }

  calculateProfit(): number {
    return 0;
    // let invoice = this.invoicePriceControl.value ? this.invoicePriceControl.value : this.car.sellingPrice!;

    // return invoice - this.car.buyingPrice!;
  }

  updateCar() {
    // this.car.invoiceSellingPrice = this.invoicePriceControl.value ? this.invoicePriceControl.value : this.car.sellingPrice!;

    // this.car.documentsDetails.cocDocuments.required = this.reqCOCControl.value!;

    // this.car.documentsDetails.cocDocuments.cocValue = this.reqCOCControl.value ? this.cocValue : 0;

    // this.car.finalDamages = this.finalDamageControl.value ? this.finalDamageControl.value : 0;

    // this.car.actualProfit = this.calculateProfit();

    // this.car.transportationFee = this.transportationFee;

    this.dialogRef.close();
  }

  closeModal() {
    this.dialogRef.close();
  }
}
