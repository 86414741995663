import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { isNumber } from 'lodash';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OffersService } from 'src/app/core/services/offers.service';
import { CreateOfferCarsOfferPriceModalComponent } from './create-offer-cars-offer-price-modal/create-offer-cars-offer-price-modal.component';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { CreateOfferSettingsCarsModalComponent } from './create-offer-settings-cars-modal/create-offer-settings-cars-modal.component';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { Router } from '@angular/router';
import { SearchCarWithoutOffer } from 'src/app/core/models/offer.model';
import _ from 'lodash';

@Component({
  selector: 'app-create-offer-cars',
  templateUrl: './create-offer-cars.component.html',
  styleUrls: ['./create-offer-cars.component.scss']
})
export class CreateOfferCarsComponent implements OnInit {
  sellerFilterControl = new FormControl();
  sellerFilters: DropdownOption[] = [];

  dataSource = new MatTableDataSource<SearchCarWithoutOffer>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  headers: TableHeaderMap[] = [
    {
      value: 'sellerDetails.name',
      tableView: 'seller'
    },
    {
      value: 'carPrices.shopPrice',
      tableView: 'price'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'regNo',
      tableView: 'Registration number'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'gearbox',
      tableView: 'gearbox'
    },
    {
      value: 'enginePower',
      tableView: 'power'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'firstReg',
      tableView: 'First registration'
    },
    {
      value: 'color',
      tableView: 'color'
    }
  ]

  displayedColumns = ['selectAll', 'offeredPrice', 'carGroupDetails.name', ...this.headers.map(h => h.value), 'setPrice'];

  constructor(private dialog: MatDialog,
    private offerService: OffersService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService,
    private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  isNumber(val: any): boolean {
    return isNumber(val);
  }

  filterCars(seller: string) {
    this.dataSource.filterPredicate = (data: SearchCarWithoutOffer, filter: string) => {
      return data.sellerDetails.id == filter;
    }

    this.dataSource.filter = seller;
  }

  selectAll(event: MatCheckboxChange) {
    this.dataSource.data = this.dataSource.data.map(d => ({ ...d, isSelected: event.checked }));
  }

  openCarsModal() {
    const dialogRef = this.dialog.open(
      CreateOfferSettingsCarsModalComponent, {
      width: '2560px',
      maxWidth: '95vw',
      autoFocus: false,
      disableClose: true,
      data: {
        buyerId: this.offerService.offerSettings?.buyerId,
        clientId: this.offerService.offerSettings?.clientId,
        carList: this.dataSource.data.map(c => c.carMainInfoId)
      },
    });

    dialogRef.afterClosed().subscribe((resp: SearchCarWithoutOffer[]) => {
      this.dataSource.data = this.dataSource.data.concat(resp.map(c => ({
        ...c,
        isSelected: false
      })));


      this.sellerFilters = this.dataSource.data.reduce((arr: DropdownOption[], car: SearchCarWithoutOffer) => {
        if (!arr.find(s => s.value === car.sellerDetails.id)) {
          arr.push({ value: car.sellerDetails.id, viewValue: car.sellerDetails.name });
        }

        return arr;
      }, [])
    })
  }

  openSetPriceModal(car: SearchCarWithoutOffer) {
    const dialogRef = this.dialog.open(
      CreateOfferCarsOfferPriceModalComponent, {
      width: '700px',
      maxWidth: '95vw',
      autoFocus: false,
      data: {
        car: {
          ...car,
          locationName: car.locationDetails.name,
          sourceName: car.sourceDetails.name,
          minSellingPrice: car.carPrices.minSellingPrice,
          sellingPrice: car.carPrices.shopPrice
        }
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        let carUpdate = this.dataSource.data.find(c => c.carMainInfoId === car.carMainInfoId)!;

        carUpdate.offeredPrice = resp.value;
      }
    })
  }

  createOffers() {
    if (this.spinner.showProgressBar.value) return;

    if (this.dataSource.data.length === 0) {
      this.snackbar.negativeSentiment('No cars added');
      return;
    }

    if (this.dataSource.data.some(c => c.offeredPrice === null || c.offeredPrice === undefined || c.offeredPrice <= 0)) {
      this.snackbar.negativeSentiment('Cars with no offer price set!');
      return;
    }

    this.spinner.showProgressBar.next(true);

    let offers = this.offerService.offerSettings!;

    offers.cars = this.dataSource.data.map(c => ({ carMainInfoId: c.carMainInfoId!, value: typeof (c.offeredPrice) === "string" ? parseInt(c.offeredPrice!) : c.offeredPrice! }));

    this.offerService.createOffers(offers).subscribe({
      next: resp => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment('Offers created succesfully!');
        this.router.navigate(['view-offers']);
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  removeCars() {
    let cars = this.dataSource.data.filter(c => c.isSelected).map(c => c.carMainInfoId);

    cars.forEach(c => {
      let index = this.dataSource.data.findIndex(car => car.carMainInfoId === c);

      this.dataSource.data.splice(index, 1);
    });

    this.dataSource._updateChangeSubscription();
  }

  getValue(element: any, path: string): string {
    return _.get(element, path.split('.'))
  }
}
