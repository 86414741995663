import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

export interface AddCarToTransportModalData {
  cars: string[],
  transports: DropdownOption[]
}

@Component({
  selector: 'app-add-car-to-transport-modal',
  templateUrl: './add-car-to-transport-modal.component.html',
  styleUrls: ['./add-car-to-transport-modal.component.scss']
})

export class AddCarToTransportModalComponent implements OnInit {
  transportControl = new FormControl('', Validators.required);
  transports = this.data.transports;

  createTransportBool = false;

  constructor(public dialogRef: MatDialogRef<AddCarToTransportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddCarToTransportModalData) { }

  ngOnInit(): void {
  }

  addCars() {
    if (this.transportControl.invalid) {
      this.transportControl.markAllAsTouched();
      return;
    }

    this.dialogRef.close({
      transport: this.transportControl.value,
      newTransport: false
    });
  }
}
