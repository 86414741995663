<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loading | async)">
    <div class="page-title">
      <p>Online list upload</p>
    </div>

    <app-btn-toggle [options]="routes" [selectedOption]="page" (changeEmitter)="changePage($event)"></app-btn-toggle>

    <div [ngSwitch]="page">
      <app-online-list-upload-settings #settings *ngSwitchCase="'settings'"></app-online-list-upload-settings>

      <app-online-list-upload-review #review *ngSwitchCase="'review'"></app-online-list-upload-review>

      <app-online-list-upload-prices #prices *ngSwitchCase="'prices'"></app-online-list-upload-prices>

      <app-online-list-upload-photos #photos *ngSwitchCase="'photos'"></app-online-list-upload-photos>
    </div>
  </div>
</app-page-layout>
