<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">View order</p>
    </div>

    <div class="flex justify-between">
      <div class="flex overflow-auto">
        <app-btn-toggle [options]="routes" [selectedOption]="page"
          (changeEmitter)="changePage($event)"></app-btn-toggle>
      </div>
    </div>

    <!-- <app-expansion-panel title="Tasks" [expandedPanel]="true" panelId="buyerPanel">
      <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
        <div class="grid grid-cols-3 gap-6">
          <div class="p-6 rounded bg-red-100"
            *ngFor="let task of tasks | slice: taskPaginator.pageIndex * 6: taskPaginator.pageIndex * 6 + 6">
            <div class="flex justify-between">
              <p>Task name</p>

              <p class="bg-blue-100 text-white px-3 py-1 rounded-2xl text-xs">CATEGORY {{task}}</p>
            </div>
          </div>
        </div>

        <mat-paginator #taskPaginator [length]="20" [pageSize]="6" [hidePageSize]="true" aria-label="Select page">
        </mat-paginator>
      </div>
    </app-expansion-panel> -->

    <app-orders-kpi [isSentOrder]="true"></app-orders-kpi>

    <div [ngSwitch]="page">
      <app-order-view-kam-orders-settings [order]="order" *ngSwitchCase="'order'" [cars]="cars"></app-order-view-kam-orders-settings>

      <app-order-view-kam-chat *ngSwitchCase="'chat'"></app-order-view-kam-chat>

      <app-order-view-kam-claims *ngSwitchCase="'claims'"></app-order-view-kam-claims>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="flex flex-col gap-9">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">Process order</p>
    </div>

    <div class="flex justify-between">
      <div class="flex overflow-auto">
        <app-btn-toggle [options]="routes" [selectedOption]="page"
          (changeEmitter)="changePage($event)"></app-btn-toggle>
      </div>

      <div class="flex gap-6">
        <secondary-btn>Drop offer</secondary-btn>
        <primary-btn>Save order</primary-btn>
      </div>
    </div>

    <div class="twoCols">
      <app-main-content-placeholder></app-main-content-placeholder>
      <app-main-content-placeholder></app-main-content-placeholder>
    </div>
  </div>

</ng-template>
