<div class="flex flex-col gap-9">
  <app-expansion-panel title="Buyer & delivery" [expandedPanel]="true" panelId="buyerDeliveryPanel">
    <div class="py-7 grid grid-cols-2 gap-6 border-t border-white-300">
      <div class="pr-6 border-r border-white-300 flex flex-col gap-6">
        <p class="text-white-800">Buyer</p>
        <app-main-content-row-layout>
          <p>Buyer name</p>

          <app-read-only-input class="secondContent">{{buyer.name}}</app-read-only-input>
        </app-main-content-row-layout>
        <app-main-content-row-layout>
          <p>Country</p>

          <app-read-only-input class="secondContent">{{buyer.address}}</app-read-only-input>
        </app-main-content-row-layout>
        <app-main-content-row-layout>
          <p>VAT</p>

          <app-read-only-input class="secondContent">{{buyer.vat}}</app-read-only-input>
        </app-main-content-row-layout>
        <app-main-content-row-layout>
          <p>Zipcode</p>

          <app-read-only-input class="secondContent">{{buyer.postalCode}}</app-read-only-input>
        </app-main-content-row-layout>
        <app-main-content-row-layout>
          <p>Address</p>

          <app-read-only-input class="secondContent">{{buyer.address}}</app-read-only-input>
        </app-main-content-row-layout>
        <app-main-content-row-layout>
          <p>KAM</p>

          <app-read-only-input class="secondContent">{{kam.username}}</app-read-only-input>
        </app-main-content-row-layout>
      </div>

      <div class="flex flex-col gap-6">
        <p class="text-white-800">Contact user</p>

        <app-main-content-row-layout>
          <p>Full name</p>

          <app-read-only-input class="secondContent">{{buyer.store!.contactDetails!.firstName}}
            {{buyer.store!.contactDetails!.lastName}}</app-read-only-input>
        </app-main-content-row-layout>
        <app-main-content-row-layout>
          <p>Email</p>

          <app-read-only-input class="secondContent">{{buyer.store!.contactDetails!.email}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Phone</p>

          <app-read-only-input class="secondContent">{{buyer.store!.contactDetails!.phone}}</app-read-only-input>
        </app-main-content-row-layout>

        <div class="flex flex-wrap gap-6">
          <mat-checkbox id="send-acc-email-checkbox" data-cy="send-acc-email-checkbox" [(ngModel)]="contact.accEmail"
            [disabled]="true">Send accounting email to this
            user</mat-checkbox>

          <mat-checkbox id="send-pickup-email-checkbox" data-cy="send-pickup-email-checkbox"
            [(ngModel)]="contact.carDeliveryEmail" [disabled]="true">Send pickup email to this
            user</mat-checkbox>

          <mat-checkbox id="send-doc-email-checkbox" data-cy="send-doc-email-checkbox"
            [(ngModel)]="contact.documentEmail" [disabled]="true">Send documents email to this
            user</mat-checkbox>
        </div>

        <p class="text-white-800">Delivery address 1</p>

        <div class="flex flex-wrap gap-6">
          <div class="py-2 px-3 rounded bg-white-100 border border-white-500">
            {{buyer.store.name}}
          </div>
          <div class="py-2 px-3 rounded bg-white-100 border border-white-500">
            {{buyer.store.country.location}}
          </div>
          <div class="py-2 px-3 rounded bg-white-100 border border-white-500">
            {{buyer.store.postalCode}}
          </div>
          <div class="py-2 px-3 rounded bg-white-100 border border-white-500">
            {{buyer.store.address}}
          </div>
        </div>
      </div>
    </div>
  </app-expansion-panel>

  <app-seller-filter-table #carsTable [displayedColumns]="displayedColumns" [headersMap]="headers" [cars]="cars"
    class="" (openModalEmitter)="openModal($event)">
    <div class="flex gap-6" *ngIf="!finishedOrder">
      <secondary-btn id="set-same-address" (clickEmitter)="setSameStoreAddress()">Delivery to same
        store</secondary-btn>
    </div>
  </app-seller-filter-table>
</div>
