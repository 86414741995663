<app-main-content-layout *ngIf="!(loading |async);else placeholder">
  <div class="grid grid-cols-2 gap-9">
    <div class="col-span-1">
      <app-main-content-row-layout>
        <p>Origin country</p>
        <app-input id="country" class="secondContent" [control]="countryControl"></app-input>
      </app-main-content-row-layout>
    </div>

    <div class="col-span-2 border-b border-white-500"></div>

    <app-main-content-row-layout *ngFor="let country of matrix"
      class="col-span-1 flex flex-col gap-6 even:pl-9 even:border-l border-white-500">
      <p>{{country.country}}</p>
      <div class="secondContent bg-white-100 border border-white-500 rounded p-[0.625rem]">
        <p>{{country.value}}</p>
      </div>
    </app-main-content-row-layout>
  </div>
</app-main-content-layout>

<ng-template #placeholder>
  <div class="p-9 bg-white rounded grid grid-cols-2 gap-9">
    <div class="col-span-1 placeholder-animation h-9"></div>
    <div class="h-[1px] bg-white-500 col-span-2"></div>
    <div class="col-span-1 flex flex-col gap-6">
      <div class="placeholder-animation h-9"></div>
      <div class="placeholder-animation h-9"></div>
      <div class="placeholder-animation h-9"></div>
    </div>
    <div class="col-span-1 flex flex-col gap-6">
      <div class="placeholder-animation h-9"></div>
      <div class="placeholder-animation h-9"></div>
      <div class="placeholder-animation h-9"></div>
    </div>
  </div>
</ng-template>
