import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrdersComponent } from './order-create/orders.component';
import { OrderSettingsComponent } from './order-create/order-settings/order-settings.component';
import { OrderSelectCarsComponent } from './order-create/order-select-cars/order-select-cars.component';
import { OrderSelectCarsListComponent } from './order-create/order-select-cars/order-select-cars-list/order-select-cars-list.component';
import { OrderSelectPricesComponent } from './order-create/order-select-cars/order-select-prices/order-select-prices.component';
import { OrderSelectPricesDetailsModalComponent } from './order-create/order-select-cars/order-select-prices/order-select-prices-details-modal/order-select-prices-details-modal.component';
import { OrderSelectCarsDeliveryComponent } from './order-create/order-select-cars/order-select-cars-delivery/order-select-cars-delivery.component';
import { OrderSelectCarsDeliveryDetailsModalComponent } from './order-create/order-select-cars/order-select-cars-delivery/order-select-cars-delivery-details-modal/order-select-cars-delivery-details-modal.component';
import { OrderSelectCarsDocumentsComponent } from './order-create/order-select-cars/order-select-cars-documents/order-select-cars-documents.component';
import { OrderSelectCarsDocumentsDetailsModalComponent } from './order-create/order-select-cars/order-select-cars-documents/order-select-cars-documents-details-modal/order-select-cars-documents-details-modal.component';
import { OrderEquipmentsComponent } from './order-create/order-equipments/order-equipments.component';
import { OrderReviewComponent } from './order-create/order-review/order-review.component';
import { OrdersViewComponent } from './orders-view/orders-view.component';
import { OrderProcessComponent } from './order-process/order-process.component';
import { OrdersKpiComponent } from './orders-kpi/orders-kpi.component';
import { OrderProcessBuyerComponent } from './order-process/order-process-buyer/order-process-buyer.component';
import { OrderProcessSellerComponent } from './order-process/order-process-seller/order-process-seller.component';
import { OrderProcessCarComponent } from './order-process/order-process-car/order-process-car.component';
import { OrderProcessCarConfirmationComponent } from './order-process/order-process-car/order-process-car-confirmation/order-process-car-confirmation.component';
import { OrderProcessCarPricesComponent } from './order-process/order-process-car/order-process-car-prices/order-process-car-prices.component';
import { OrderProcessCarDetailsComponent } from './order-process/order-process-car/order-process-car-details/order-process-car-details.component';
import { OrderProcessCarDocumentsComponent } from './order-process/order-process-car/order-process-car-documents/order-process-car-documents.component';
import { OrderProcessPaymentsComponent } from './order-process/order-process-payments/order-process-payments.component';
import { OrderProcessTransportComponent } from './order-process/order-process-transport/order-process-transport.component';
import { OrderProcessClaimsComponent } from './order-process/order-process-claims/order-process-claims.component';
import { OrderProcessLogsComponent } from './order-process/order-process-logs/order-process-logs.component';
import { OrderProcessChatComponent } from './order-process/order-process-chat/order-process-chat.component';
import { OrderProcessSellerCarPickupDetailsModalComponent } from './order-process/order-process-seller/order-process-seller-car-pickup-details-modal/order-process-seller-car-pickup-details-modal.component';
import { OrderProcessCarPricesModalComponent } from './order-process/order-process-car/order-process-car-prices/order-process-car-prices-modal/order-process-car-prices-modal.component';
import { AddCarsFromOffersModalComponent } from './order-shared-components/add-cars-from-offers-modal/add-cars-from-offers-modal.component';
import { ManuallyAddCarsModalComponent } from './order-shared-components/manually-add-cars-modal/manually-add-cars-modal.component';
import { OrderProcessProfitComponent } from './order-process/order-process-profit/order-process-profit.component';
import { OrderProcessProfitAddteammateModalComponent } from './order-process/order-process-profit/order-process-profit-addteammate-modal/order-process-profit-addteammate-modal.component';
import { OrderProcessCarDocumentsModalComponent } from './order-process/order-process-car/order-process-car-documents/order-process-car-documents-modal/order-process-car-documents-modal.component';
import { SellerFilterTableComponent } from './order-shared-components/seller-filter-table/seller-filter-table.component';
import { OrderSeeCarDetailsModalComponent } from './order-shared-components/order-see-car-details-modal/order-see-car-details-modal.component';
import { OrderViewKamComponent } from './order-view-kam/order-view-kam.component';
import { OrderViewKamOrdersSettingsComponent } from './order-view-kam/order-view-kam-orders-settings/order-view-kam-orders-settings.component';
import { OrderViewKamClaimsComponent } from './order-view-kam/order-view-kam-claims/order-view-kam-claims.component';
import { OrderViewKamChatComponent } from './order-view-kam/order-view-kam-chat/order-view-kam-chat.component';
import { OrderPendingProcessComponent } from './order-pending-process/order-pending-process.component';
import { CreateTransportModalComponent } from './order-process/order-process-transport/create-transport-modal/create-transport-modal.component';
import { AddCarToTransportModalComponent } from './order-process/order-process-transport/add-car-to-transport-modal/add-car-to-transport-modal.component';
import { OrderProcessDocumentsComponent } from './order-process/order-process-documents/order-process-documents.component';
import { OrderProcessInvoiceCompaniesComponent } from './order-process/order-process-invoice-companies/order-process-invoice-companies.component';
import { OrderProcessInvoiceCompaniesModalComponent } from './order-process/order-process-invoice-companies/order-process-invoice-companies-modal/order-process-invoice-companies-modal.component';
import { AddOrderDocumentModalComponent } from './order-process/order-process-documents/add-order-document-modal/add-order-document-modal.component';
import { OrderCarDocumentsModalComponent } from './order-process/order-process-documents/order-car-documents-modal/order-car-documents-modal.component';
import { OrdersCarsComponent } from './orders-cars/orders-cars.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    OrderProcessDocumentsComponent
  ],
  declarations: [
    OrdersComponent,
    OrderSettingsComponent,
    OrderSelectCarsComponent,
    OrderSelectCarsListComponent,
    OrderSelectPricesComponent,
    OrderSelectPricesDetailsModalComponent,
    OrderSelectCarsDeliveryComponent,
    OrderSelectCarsDeliveryDetailsModalComponent,
    OrderSelectCarsDocumentsComponent,
    OrderSelectCarsDocumentsDetailsModalComponent,
    OrderEquipmentsComponent,
    OrderReviewComponent,
    OrdersViewComponent,
    OrderProcessComponent,
    OrdersKpiComponent,
    OrderProcessBuyerComponent,
    OrderProcessSellerComponent,
    OrderProcessInvoiceCompaniesComponent,
    OrderProcessCarComponent,
    OrderProcessCarConfirmationComponent,
    OrderProcessCarPricesComponent,
    OrderProcessCarDetailsComponent,
    OrderProcessCarDocumentsComponent,
    OrderProcessPaymentsComponent,
    OrderProcessTransportComponent,
    OrderProcessClaimsComponent,
    OrderProcessLogsComponent,
    OrderProcessChatComponent,
    OrderProcessSellerCarPickupDetailsModalComponent,
    OrderProcessCarPricesModalComponent,
    AddCarsFromOffersModalComponent,
    ManuallyAddCarsModalComponent,
    OrderProcessProfitComponent,
    OrderProcessProfitAddteammateModalComponent,
    OrderProcessCarDocumentsModalComponent,
    SellerFilterTableComponent,
    OrderSeeCarDetailsModalComponent,
    OrderViewKamComponent,
    OrderViewKamOrdersSettingsComponent,
    OrderViewKamClaimsComponent,
    OrderViewKamChatComponent,
    OrderPendingProcessComponent,
    CreateTransportModalComponent,
    AddCarToTransportModalComponent,
    OrderProcessInvoiceCompaniesModalComponent,
    AddOrderDocumentModalComponent,
    OrderCarDocumentsModalComponent,
    OrdersCarsComponent
  ]
})
export class OrdersModule { }
