<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9">
    <div class="font-bold text-2xl">
      <p>Cars on list search</p>
    </div>

    <div class="overflow-x-auto" *ngIf="!(loading | async);else placeholder">
      <app-table [data]="lists" [displayedColumns]="displayedColumns" [headersMap]="headersTable"
        (goToEmitter)="goToList($event)"></app-table>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <app-table-placeholder></app-table-placeholder>
</ng-template>
