<div class="flex flex-col gap-6" *ngIf="!(loading | async);else placeholder">
  <div class="grid grid-cols-6 xl:grid-cols-8 gap-6">
    <app-dropdown id="country-from" class="col-span-1" placeholder="Select country" label="Country from"
      [options]="countries" [multipleSelect]="true" [control]="countryFromControl"></app-dropdown>

    <app-dropdown id="country-to" class="col-span-1" placeholder="Select country" label="Country to"
      [options]="countries" [multipleSelect]="true" [control]="countryToControl"></app-dropdown>

    <!-- <app-dropdown id="status" class="col-span-1" placeholder="Select status" label="Status"></app-dropdown> -->

    <div class="col-span-1 flex gap-4">
      <secondary-btn id="reset-filters" class="col-span-1" btnText="Reset"
        (clickEmitter)="resetFilters()"></secondary-btn>
      <primary-btn id="filter-table" class="col-span-1" btnText="Search" (clickEmitter)="filterTable()"></primary-btn>
    </div>

    <tertiary-btn id="add-country" class="col-span-1 col-start-8 add-country" btnText="Add Country"
      (clickEmitter)="openAddCountryModal()">
    </tertiary-btn>
  </div>

  <div class="overflow-x-auto">
    <table mat-table [dataSource]="dataSource"
      class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">
      <ng-container matColumnDef="countryOrigin">
        <th mat-header-cell *matHeaderCellDef> COUNTRY ORIGIN </th>
        <td mat-cell *matCellDef="let element"> {{element.countryFrom}} </td>
      </ng-container>

      <ng-container *ngFor="let country of countriesInMatrix; let i = index " [matColumnDef]="country.viewValue">
        <th mat-header-cell *matHeaderCellDef class="!p-2"> {{country.viewValue}} </th>
        <td mat-cell *matCellDef="let element;let row = index" class="!p-2">
          <div class="flex gap-1 items-center">
            <input type="number" [id]="'transport-fee-' + row + '-' + country.viewValue + '-input'"
              [attr.data-cy]="'transport-fee-' + row + '-' + country.viewValue + '-input'"
              class="px-3 py-2 border border-white-500" [(ngModel)]="element.countriesTo[i].value">

            <fa-icon [id]="'save-fee-' + row + '-' + country.viewValue + '-btn'"
              [attr.data-cy]="'save-fee-' + row + '-' + country.viewValue + '-btn'" matTooltip="Save input"
              matTooltipPosition="above" class="text-xl cursor-pointer" [icon]="['fal','floppy-disk']"
              (click)="saveInput(country.value,element)">
            </fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <fa-icon [icon]="['fal','trash-can']"></fa-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>

<ng-template #placeholder>
  <div class="w-full flex flex-col gap-6">
    <div class="w-full h-9 placeholder-animation"></div>

    <div class="flex flex-col gap-2 col-span-2">
      <div class="bg-white-100 h-8"></div>
      <div class="placeholder-animation h-6" *ngFor="let i of [].constructor(5)"></div>
    </div>
  </div>
</ng-template>
