export interface DeliveryWindows {
  id: string,
  period: string
}

export interface Constants {
  value: number,
  name: string
}

export enum ConstantsEnum {
  DHL = 'DHL',
  COC = 'cocValue',
  PhotoValue = 'photoEdit'
}

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum VATType {
  ExVAT = 'Ex VAT',
  InclVAT = 'Included VAT'
}

export enum OrderCarConfirmation {
  Confirmed = 'Confirmed',
  Canceled = 'Canceled',
  Unset = 'Unset'
}

export enum OrderTransportStatus {
  Ordered = "Ordered",
  Confirmed = "Confirmed",
  Ongoing = "Ongoing",
  Delivered = "Delivered",
}

export enum CarStatus {
  Active = "Active",
  Reserved = "Reserved",
  Sold = "Sold",
  Inactive = "Inactive",
}
