import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, debounceTime, firstValueFrom, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { CarsOnListImportCar, ICarBatchIdentifyBody } from 'src/app/core/models/cars-inventory.model';
import { CoreService } from 'src/app/core/services/core.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import * as XLSX from 'xlsx';
import mapping from 'src/assets/json-files/cars-on-list-import-mapping.json';
import { CarService } from 'src/app/core/services/car.service';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import hours from 'src/assets/json-files/hours.json';
import moment from 'moment';
import { ISellerResponse, SellerSearch } from 'src/app/core/models/seller.model';
import { MatDialog } from '@angular/material/dialog';
import { OnlineListNewJourneyAlertModalComponent } from '../online-list-new-journey-alert-modal/online-list-new-journey-alert-modal.component';


@Component({
  selector: 'app-online-list-upload-settings',
  templateUrl: './online-list-upload-settings.component.html',
  styleUrls: ['./online-list-upload-settings.component.scss']
})
export class OnlineListUploadSettingsComponent implements OnInit {
  equipmentHeaders = [
    {
      objectValue: "navigation",
      excelValue: "Navigation"
    },
    {
      objectValue: "applePlayConnect",
      excelValue: "Apple play or Connect"
    },
    {
      objectValue: "leatherInterior",
      excelValue: "Leather interior"
    },
    {
      objectValue: "heatedSeats",
      excelValue: "Heated seats"
    },
    {
      objectValue: "panorama",
      excelValue: "Panorama"
    },
    {
      objectValue: "adaptiveCruise",
      excelValue: "Adaptive cruise"
    },
    {
      objectValue: "driverAssist",
      excelValue: "Driver assist"
    },
    {
      objectValue: "camera",
      excelValue: "Camera"
    },
    {
      objectValue: "towbar",
      excelValue: "Towbar"
    },
    {
      objectValue: "xenon",
      excelValue: "Xenon/LED headlights"
    },
    {
      objectValue: "digitalDisplay",
      excelValue: "Digital display"
    },
    {
      objectValue: "heatPump",
      excelValue: "Heat Pump"
    },
    {
      objectValue: "parkingAid",
      excelValue: "Parking Aid"
    }
  ];

  routeSubscription = new Subscription();

  uploadControl = new FormControl();
  excelFileName: string | undefined;
  mapping = mapping;

  destroyed = new Subject<void>();
  resultItems = new BehaviorSubject<ISellerResponse[]>([]);
  existsResults = new BehaviorSubject<boolean>(false);
  sellerControl = new FormControl("");

  cars: CarsOnListImportCar[] = [];
  selectedSeller: ISellerResponse | undefined;

  countries = this.coreService.countriesDropdown;

  journeysGroupForm = new FormArray<FormControl>([]);
  journeyGroups = this.carFlowSync.carGroups;

  journeyCounter = 0;

  inactiveFrom: Date | undefined;

  carsLocationControl = new FormControl('', Validators.required);
  countryControl = new FormControl();
  availableFromControl = new FormControl<Date | null>(null, Validators.required);

  hours = hours;

  today = new Date();

  fileGenerated = false;
  journeysChanged = true;
  carsIdentified = false;
  carsUploaded = false;

  @Output() saveEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private utilsService: UtilsService,
    private coreService: CoreService,
    private snackbar: SnackbarService,
    private carService: CarService,
    private sellerService: SellerService,
    private fb: FormBuilder,
    private router: Router,
    private carFlowSync: CarFlowSyncService,
    private progressBarService: SpinnerHandlerService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.routeSubscription = this.carFlowSync.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.journeysChanged) {
          this.snackbar.negativeSentiment('Journeys have been changed, reupload new excel file');
          return;
        }

        this.carFlowSync.setCurrentTab(value);
      }
    );

    this.sellerControl.valueChanges.pipe(debounceTime(400), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      if (value) {
        this.searchSeller(value);
      } else {
        this.resultItems.next([]);
      }
    });

    this.resultItems.pipe(
      tap((result) => {
        this.existsResults.next(result.length > 0);
      })
    ).subscribe();

    if (this.carFlowSync.carOnlineListUpload) {
      this.carsLocationControl.setValue(this.carFlowSync.carOnlineListUpload.location);
      this.selectedSeller = this.carFlowSync.selectedSeller;
      this.availableFromControl.setValue(new Date(this.carFlowSync.carOnlineListUpload.availableFrom));

      this.carFlowSync.journeys.forEach((j: any, index: number) => {
        const journey = new FormControl({
          group: new FormControl<string>(j.group, Validators.required),
          from: new FormControl<Date>(new Date(j.dateFrom), Validators.required),
          until: new FormControl<Date>(new Date(j.dateTo), Validators.required),
          startTime: new FormControl<string>(j.startTime, Validators.required),
          endTime: new FormControl<string>(j.endTime, Validators.required),
          journeyId: new FormControl(`newJourney${this.journeyCounter++}`),
          unavailabilityForm: this.fb.group({
            startDate: [index === 0 ? this.today : this.carFlowSync.journeys[index - 1].from],
            endDate: []
          })
        });

        this.journeysGroupForm.push(journey);
      });

      this.inactiveFrom = this.journeysGroupForm.controls[this.journeysGroupForm.controls.length - 1].value.until.value;

      this.setInactiveFromHours(this.journeysGroupForm.controls[this.journeysGroupForm.controls.length - 1].value.endTime.value);

      this.excelFileName = this.carFlowSync.excelFile;

      this.carsIdentified = true;
      this.journeysChanged = false;

      if (this.carFlowSync.carsAfterIdentifyDTO[0].carMainInfoId) {
        this.carsLocationControl.disable();
        this.availableFromControl.disable();

        this.journeysGroupForm.controls.forEach(c => {
          c.value['from'].disable();
          c.value['until'].disable();
          c.value['group'].disable();
          c.value['startTime'].disable();
          c.value['endTime'].disable();
        });

        this.carsUploaded = true;
      }
    } else {
      this.addJourney();
    }
  }

  ngOnDestroy() {
    this.existsResults.complete();
    this.destroyed.next();
    this.destroyed.complete();
    this.routeSubscription.unsubscribe();
  }

  searchSeller(value: string) {
    let searchSeller: SellerSearch = {
      name: value,
      details: true,
      b2bReady: true
    };

    if (this.countryControl.value && this.countryControl.value != 'all') searchSeller.country = this.countryControl.value;

    this.sellerService.searchSeller(searchSeller).subscribe((data) => {
      this.resultItems.next(data);
    });
  }

  selectSeller(seller: ISellerResponse) {
    this.selectedSeller = seller;
    this.sellerControl.setValue(seller.sellerCompanyDetails.name!);
  }

  async handleFileSelect(evt: Event) {
    const target = evt.target as HTMLInputElement;

    const reader: FileReader = new FileReader();

    if (target.files && target.files.length > 0) {
      reader.readAsBinaryString(target.files![0]);

      this.cars = [];
      this.excelFileName = target.files![0].name;

      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        //, cellDates: true

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws);

        if (data.length === 0) {
          this.snackbar.negativeSentiment('Excel has no cars');

          return;
        }

        data.forEach((d: any) => {
          let car: CarsOnListImportCar = {
            prices: [],
            c2vEquipment: []
          };

          Object.keys(d).forEach((i) => {
            if (i.toLowerCase().match('journey')) {
              car.prices!.push(d[i]);
            } else if (this.equipmentHeaders.find(e => e.excelValue === i)) {
              car.c2vEquipment?.push(this.equipmentHeaders.find(e => e.excelValue === i)!.objectValue);
            } else if (i.toLowerCase().match('coc')) {
              car.cocFromSeller = true;
            } else {
              car[(mapping.find(m => m.excelValue === i)?.objectValue) as keyof typeof car] = d[i];

              if (i === 'First reg YYYY-MM-DD') {
                if (typeof (d[i]) === 'string') {
                  car.firstReg = d[i];
                } else {
                  car.firstReg = this.utilsService.formatDate(new Date((d[i] - 25569) * 86400000));
                }
              } else if (i.toLowerCase().match('vat status')) {
                car.vatStatus = d[i].toLowerCase() === ('ex vat');
              }
            }
          });

          this.cars.push(car);
        });


        //REFACTOR VALIDATION
        // let headers = this.mapping.filter(x => this.equipmentHeaders.findIndex(y => y.objectValue === x.objectValue) === -1).map(x => x.objectValue);

        // headers.splice(-1);

        // let carProperties =  Object.keys(this.cars[0]);

        // let checkAllHeaders = headers.every(h => carProperties.includes(h));

        // if (!checkAllHeaders) {
        //   this.snackbar.negativeSentiment('Excel headers not matching template');

        //   this.cars = [];
        // }

        if (this.cars.some(c => c.prices!.length != this.journeysGroupForm.controls.length)) {
          this.snackbar.negativeSentiment('Car prices doesnt match journey numbers');

          this.cars = [];
        } else {
          this.snackbar.positiveSentiment('File uploaded successfully!');
        }
      };
    }
  }

  identifyCars() {
    if (this.cars.some(c => c.prices!.length != this.journeysGroupForm.controls.length)) {
      this.snackbar.negativeSentiment('Car prices doesnt match journey numbers');

      return;
    }

    if (this.cars.length < 1) {
      this.snackbar.negativeSentiment('No cars uploaded!');
      return;
    }

    if (this.journeysGroupForm.controls.some(c => c.value['from'].invalid || c.value['until'].invalid || c.value['group'].invalid || c.value['startTime'].invalid || c.value['endTime'].invalid)) {
      this.journeysGroupForm.controls.forEach(c => {
        c.value['from'].markAsTouched();
        c.value['until'].markAsTouched();
        c.value['group'].markAsTouched();
        c.value['startTime'].markAsTouched();
        c.value['endTime'].markAsTouched();
      });

      this.snackbar.negativeSentiment('You must complete all journeys');

      return;
    }

    if (!this.selectedSeller) {
      this.snackbar.negativeSentiment('No seller selected!');
      return;
    }

    if (this.availableFromControl.invalid) {
      this.snackbar.negativeSentiment('No available from selected!');
      return;
    }

    if (this.carsLocationControl.invalid) {
      this.snackbar.negativeSentiment('No location selected!');
      return;
    }

    this.progressBarService.showProgressBar.next(true);

    this.carFlowSync.carOnlineListUpload = {
      sellerId: this.selectedSeller.companyId,
      availableFrom: moment(this.availableFromControl.value!).format("yyyy-MM-DD"),
      location: this.carsLocationControl.value!,
      journeys: this.journeysGroupForm.controls.map(c => ({
        dateFrom: moment(`${moment(c.value.from.value).format("yyyy-MM-DD")} ${c.value.startTime.value}:00:00`).utc().format('yyyy-MM-DD HH:mm:ss'),
        dateTo: moment(`${moment(c.value.until.value).format("yyyy-MM-DD")} ${c.value.endTime.value}:00:00`).utc().format('yyyy-MM-DD HH:mm:ss'),
        groupId: c.value.group.value,
      })),
      cars: []
    };

    this.carFlowSync.journeys = this.journeysGroupForm.controls.map(c => ({
      dateFrom: c.value.from.value,
      startTime: c.value.startTime.value,
      endTime: c.value.endTime.value,
      dateTo: c.value.until.value,
      group: c.value.group.value,
    }));

    this.carFlowSync.selectedSeller = this.selectedSeller;

    this.carFlowSync.excelFile = this.excelFileName;

    let body: ICarBatchIdentifyBody = {
      cars: this.cars.map(c => ({
        make: c.make ? c.make.toString() : '',
        model: c.model ? c.model.toString() : '',
        variant: c.variant ? c.variant.toString() : '',
        trim: c.trim ? c.trim.toString() : '',
        gearbox: c.gearbox ? c.gearbox.toString() : '',
        fuelType: c.fuelType ? c.fuelType.toString() : '',
        manufactureYear: c.manufactureYear ? c.manufactureYear : null,
        enginePower: c.enginePower ? c.enginePower : null,
        bodyType: c.bodyType ? c.bodyType.toString() : '',
      })),
    };

    this.carService.carBatchIdentify(body).subscribe({
      next: resp => {

        this.carFlowSync.carsAfterIdentifyDTO = this.cars.map((c, index) => {
          let car: any = {
            ...c,
            ...resp[index],
          };

          if (resp[index].alternatives && resp[index].alternatives.length > 0) {
            car = {
              ...car,
              ...resp[index].alternatives[0]
            }
          }

          c.prices?.forEach((p, index) => {
            car[`journey${index}`] = p
          });

          return car;
        });

        this.progressBarService.showProgressBar.next(false);

        this.carFlowSync.setCurrentTab('review');
      },
      error: err => {
        this.progressBarService.showProgressBar.next(false);
      }
    })
  }

  autocompletePrices() {
    let price = this.journeysGroupForm.controls[0].value.price.value;

    this.journeysGroupForm.controls.forEach(c => {
      c.value.price.setValue(price);
    });
  }

  setDates(date: any) {
    this.inactiveFrom = date;
  }

  setInactiveFromHours(hour: string) {
    this.inactiveFrom = new Date(moment(this.inactiveFrom).hours(parseInt(hour)).format('yyyy/MM/DD HH:mm'));
  }

  removeFile() {
    this.uploadControl.reset();
    this.cars = [];
    this.excelFileName = undefined;
  }

  goToSeller() {
    this.router.navigate(['seller/b2b-seller']);
  }

  async addJourney() {
    if (this.journeysGroupForm.controls.some(c => c.value['from'].invalid || c.value['until'].invalid || c.value['group'].invalid || c.value['startTime'].invalid || c.value['endTime'].invalid)) {
      this.snackbar.negativeSentiment('You must complete previous journey before adding a new one!');
      return;
    }

    if (this.carsIdentified && !(await this.openNewJourneyAlertModal())) {
      return;
    }

    let firstJourney = true;
    let lastJourney = new FormControl<any>('');

    if (this.journeysGroupForm.controls.length > 0) {
      lastJourney = this.journeysGroupForm.controls.at(-1)!;

      lastJourney.value.from.disable();
      lastJourney.value.startTime.disable();
      lastJourney.value.endTime.disable();
      lastJourney.value.until.disable();

      firstJourney = false;
    }

    const journey = new FormControl({
      group: new FormControl<string | null>('', Validators.required),
      from: new FormControl<string | null>(
        {
          value: !firstJourney ? lastJourney.value.until.value : null,
          disabled: !firstJourney
        }, Validators.required),
      until: new FormControl<string | null>(null, Validators.required),
      startTime: new FormControl<string | null>({
        value: !firstJourney ? lastJourney.value.endTime.value : null,
        disabled: !firstJourney
      }, Validators.required),
      endTime: new FormControl<string | null>(null, Validators.required),
      journeyId: new FormControl(`newJourney${this.journeyCounter++}`),
      unavailabilityForm: this.fb.group({
        startDate: [firstJourney ? this.today : lastJourney.value.to],
        endDate: []
      })
    });

    this.journeysGroupForm.push(journey);

    this.fileGenerated = false;

    this.journeysChanged = true;
  }

  removeJourney(journeyId: string) {
    if (this.journeysGroupForm.length < 2) {
      this.snackbar.negativeSentiment('You cannot delete all journeys');
      return;
    }

    let index = this.journeysGroupForm.controls.findIndex(c => c.value.journeyId.value === journeyId);

    if (index === 0) {
      this.journeysGroupForm.controls.splice(index, 1);

      if (this.journeysGroupForm.controls.length === 1) {
        let lastJourney = this.journeysGroupForm.controls[0];

        lastJourney.value.until.enable();
        lastJourney.value.endTime.enable();
      }
    } else if (index + 1 === this.journeysGroupForm.length) {
      this.journeysGroupForm.controls.pop();

      let lastJourney = this.journeysGroupForm.controls.at(-1)!;

      this.inactiveFrom = lastJourney.value.until.value;

      lastJourney.value.until.enable();
      lastJourney.value.endTime.enable();

      if (this.journeysGroupForm.length === 1) {
        lastJourney.value.from.enable();
        lastJourney.value.startTime.enable();
      }
    } else {
      let nextJourney = this.journeysGroupForm.controls[index + 1];

      nextJourney.value.from.setValue(this.journeysGroupForm.controls[index].value.from.value);

      this.journeysGroupForm.controls.splice(index, 1);
    }

    this.fileGenerated = false;

    this.journeysChanged = true;
  }

  generateExcelFile() {
    if (this.journeysGroupForm.controls.some(c => c.value['from'].invalid || c.value['until'].invalid || c.value['group'].invalid || c.value['startTime'].invalid || c.value['endTime'].invalid)) {
      this.journeysGroupForm.controls.forEach(c => {
        c.value['from'].markAsTouched();
        c.value['until'].markAsTouched();
        c.value['group'].markAsTouched();
        c.value['startTime'].markAsTouched();
        c.value['endTime'].markAsTouched();
      });

      this.snackbar.negativeSentiment('You must complete all journeys');

      return;
    }

    let wsHeaders = mapping.map(t => t.excelValue);

    this.journeysGroupForm.controls.forEach((c, index) => wsHeaders.push(`Journey${index + 1}`))

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [wsHeaders]);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'upload-cars-template.xlsx');

    this.fileGenerated = true;
  }

  async openNewJourneyAlertModal(): Promise<boolean> {
    const dialogRef = this.dialog.open(
      OnlineListNewJourneyAlertModalComponent, {
      width: '550px',
      autoFocus: false
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }
}
