<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9">
    <div class="font-bold text-2xl">
      <p>Request cars - New request</p>
    </div>

    <div class="grid grid-cols-2 gap-6">
      <app-main-content-layout>
        <div class="title">
          <p>Settings</p>
        </div>

        <app-main-content-row-dropdown componentId="makes" [multipleSelect]="true" title="Makes (multiple)"
          placeholder="Select makes"></app-main-content-row-dropdown>

        <app-main-content-row-dropdown componentId="models" [multipleSelect]="true" title="Models (multiple)"
          placeholder="Select models"></app-main-content-row-dropdown>

        <app-main-content-row-dropdown componentId="fueltypes" [multipleSelect]="true" title="Fuel types (multiple)"
          placeholder="Select fuel types"></app-main-content-row-dropdown>

        <app-main-content-row-dropdown componentId="gearbox" [multipleSelect]="true" title="Gearbox (multiple)"
          placeholder="Select gearbox"></app-main-content-row-dropdown>

        <app-main-content-row-dropdown componentId="yearFrom" title="Year from"
          placeholder="Select year from"></app-main-content-row-dropdown>

        <app-main-content-row-input-number componentId="mileageFrom" title="Mileage from (km)"
          placeholder="Insert mileage from (km)"></app-main-content-row-input-number>

        <app-main-content-row-input-number componentId="mileageTo" title="Mileage to (km)"
          placeholder="Insert mileage to (km)"></app-main-content-row-input-number>

        <app-main-content-row-input-number componentId="priceFrom" title="Price ex. VAT from (€)"
          placeholder="Insert price ex. VAT from (€)"></app-main-content-row-input-number>

        <app-main-content-row-input-number componentId="priceTo" title="Price ex. VAT to (€)"
          placeholder="Insert price ex. VAT to (€)"></app-main-content-row-input-number>
      </app-main-content-layout>

      <div class="flex flex-col gap-6">
        <app-main-content-layout>
          <div class="title">
            <p>Search profile</p>
          </div>

          <app-main-content-row-layout>
            <p>Search profile</p>

            <app-dropdown id="profile" class="secondContent" placeholder="Select profile"></app-dropdown>
          </app-main-content-row-layout>

          <app-blue-btn id="search-seller" class="self-end">Search seller</app-blue-btn>
        </app-main-content-layout>

        <app-main-content-layout>
          <div class="title">
            <p>Matching sellers</p>
          </div>

          <app-main-content-row-layout>
            <p>Country</p>

            <app-dropdown id="country" class="secondContent" placeholder="Select country"></app-dropdown>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>KAM</p>

            <app-dropdown id="kam" class="secondContent" placeholder="Select KAM"></app-dropdown>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <div class="secondContent flex justify-between">
              <p class="text-white-800">100 matching sellers</p>

              <p>Reset</p>
            </div>
          </app-main-content-row-layout>

          <app-table [displayedColumns]="displayedColumns" [headersMap]="headers" [data]="sellers"></app-table>

          <app-blue-btn id="send-request" class="self-end">Send request</app-blue-btn>
        </app-main-content-layout>
      </div>
    </div>
  </div>
</app-page-layout>
