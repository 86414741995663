<div class="flex flex-col gap-9 max-h-[80vh] p-6 overflow-y-scroll">
  <div class="text-2xl font-bold">
    <p>Modify unidentified cars</p>
  </div>

  <div class="grid grid-cols-2 gap-6">
    <p>Imported data</p>

    <p>Manual data</p>

    <app-read-only-input label="Make">{{car.make}}</app-read-only-input>

    <app-dropdown id="make" placeholder="Select make" label="Make" [control]="identificationForm.controls.make"
      (emitOnChangeSelect)="loadModels($event)" [options]="makes"></app-dropdown>

    <app-read-only-input label="Model">{{car.model}}</app-read-only-input>

    <app-dropdown id="model" placeholder="Select model" label="Model" [control]="identificationForm.controls.model"
      [disabled]="identificationForm.controls.make.invalid" (emitOnChangeSelect)="loadFueltypes($event)"
      [options]="models"></app-dropdown>

    <app-read-only-input label="Fueltype">{{car.fuelType}}</app-read-only-input>

    <app-dropdown id="fueltype" placeholder="Select fuel type" label="Fuel type"
      [control]="identificationForm.controls.fuelType" [disabled]="identificationForm.controls.model.invalid"
      (emitOnChangeSelect)="loadGearbox($event)" [options]="fuelTypes"></app-dropdown>

    <app-read-only-input label="Gearbox">{{car.gearbox}}</app-read-only-input>

    <app-dropdown id="gearbox" placeholder="Select gearbox" label="Gearbox"
      [control]="identificationForm.controls.gearbox" [disabled]="identificationForm.controls.fuelType.invalid"
      (emitOnChangeSelect)="loadVariants($event)" [options]="gearboxes"></app-dropdown>

    <app-read-only-input label="Variant">{{car.variant}}</app-read-only-input>


    <app-dropdown id="variant" placeholder="Select variant" label="Variant"
      [control]="identificationForm.controls.variant" [disabled]="identificationForm.controls.gearbox.invalid"
      (emitOnChangeSelect)="loadTrims($event)" [options]="variants"></app-dropdown>

    <app-read-only-input label="Trim">{{car.trim ? car.trim : 'No data'}}</app-read-only-input>

    <app-dropdown id="trim" placeholder="Select trim" label="Trim" [control]="identificationForm.controls.trim"
      [disabled]="identificationForm.controls.variant.invalid" [options]="trims"></app-dropdown>
  </div>

  <div class="flex justify-end">
    <primary-btn id="identify-car" btnText="Identify Car" [blueBtn]="true" (clickEmitter)="identifyCar()"></primary-btn>
  </div>

  <div class="flex flex-col bg-white-400 p-4 rounded" *ngFor="let carA of carAlternatives; let i = index">
    <div class="flex justify-between items-center">
      <div class="flex flex-col gap-2">
        <p class="font-bold">{{carA.fullCarName}}</p>
      </div>

      <div class="flex gap-6 items-center">
        <secondary-btn [id]="'more-info' + i" btnText="See less" (clickEmitter)="toggleCarIdentification(i)"
          *ngIf="carA.identificationToggle;else moreInfo"></secondary-btn>

        <ng-template #moreInfo>
          <secondary-btn [id]="'less-info' + i" btnText="See more"
            (clickEmitter)="toggleCarIdentification(i)"></secondary-btn>
        </ng-template>

        <primary-btn [id]="'select' + i" btnText="Select" (clickEmitter)="closeModal(i)"></primary-btn>
      </div>
    </div>

    <div class="text-sm flex flex-col gap-4 panel" [id]="'car-identification-'+i">
      <p class="font-bold">Car identification</p>

      <div class="flex flex-wrap gap-x-8 gap-y-3">
        <div class="flex gap-3" *ngFor="let info of carA.identificationStrings!">
          <p class="text-white-600">{{info.label}}</p>
          <p>{{info.value}}</p>
        </div>
      </div>

      <div class="flex justify-between" *ngIf="carA.equipmentToggle;else showEq">
        <p class="font-bold">Equipment</p>

        <p [id]="'hide-eq-' + i + '-btn'" [attr.data-cy]="'hide-eq-' + i + '-btn'" class="text-blue-500 cursor-pointer"
          (click)="toggleCarEquipment(i)">Hide equipment</p>
      </div>

      <ng-template #showEq>
        <div class="text-end">
          <p [id]="'show-eq-' + i + '-btn'" [attr.data-cy]="'show-eq-' + i + '-btn'"
            class="text-blue-500 cursor-pointer" (click)="toggleCarEquipment(i)">Show equipment</p>
        </div>
      </ng-template>
    </div>

    <div class="flex flex-wrap gap-x-8 gap-y-3 panel" [id]="'car-equipment-'+i">
      <div class="flex gap-3" *ngFor="let eq of carA.equipmentString!">
        <fa-icon [icon]="['fas','circle-check']"></fa-icon>
        <p>{{eq}}</p>
      </div>
    </div>
  </div>

  <div class="w-full" [ngStyle]="{'opacity': (progressBarActive | async) ? '1' : '0' }">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
