<div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
  <app-btn-toggle [options]="routes" [selectedOption]="page" (changeEmitter)="page = $event"></app-btn-toggle>

  <div class="flex flex-wrap gap-6 pt-9 border-t border-white-500">
    <app-dropdown [control]="countryControl" [options]="countries" placeholder="Select country"
      label="Country"></app-dropdown>

    <app-input [control]="vinControl" placeholder="Insert VIN" label="VIN"></app-input>

    <primary-btn [blueBtn]="true" btnText="Decode" (clickEmitter)="searchVIN()"></primary-btn>
  </div>

  <div [ngSwitch]="page" *ngIf="carData">
    <app-main-content-layout *ngSwitchCase="'vin'">
      <div class="text-xl">
        {{ carData.make }} {{ carData.model }} {{ carData.generation }} {{ carData.variant }},
        {{ carData.manufactureStart }}
        -
        {{ carData.manufactureEnd }}
      </div>

      <div *ngIf="carData.oemVehicle" class="flex gap-4 pt-6">
        <div class="bg-white-500 text-black rounded-full text-xs px-3 py-[.38rem]">
          <span class="text-white-600">OEM data Make & Model:</span> {{ carData.oemVehicle.makeDescription }}
          {{ carData.oemVehicle.modelDescription }}
        </div>
        <div class="bg-white-500 text-black rounded-full text-xs px-3 py-[.38rem]">
          <span class="text-white-600">OEM data Variant:</span> {{ carData.oemVehicle.typeDescription }}
        </div>
        <div class="bg-white-500 text-black rounded-full text-xs px-3 py-[.38rem]">
          <span class="text-white-600">OEM data Trim:</span> {{ carData.oemVehicle.trimLineName }}
        </div>
      </div>

      <div class="grid grid-cols-2 pt-9">
        <div class="text-sm border-r border-white-500 pr-12">
          <div class="flex justify-between mb-6">
            <p>Make</p>
            <p>{{ carData.make }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Model</p>
            <p>{{ carData.model }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Submodel </p>
            <p>{{ carData.submodel }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Variant </p>
            <p>{{ carData.variant }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Trim </p>
            <p>{{ carData.trim }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Generation </p>
            <p>{{ carData.generation }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>VIN</p>
            <p>{{ carData.vinCode }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Power HP</p>
            <p>{{ carData.powerHP }}HP</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Power kW</p>
            <p>{{ carData.powerKW }}kW</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Capacity</p>
            <p>{{ carData.engineVolume ? (carData.engineVolume.value | number) : '-' }} {{ carData.engineVolume?.unit }}
            </p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Engine type</p>
            <p>
              {{ carData.fuelType?.name}}
            </p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Geartype</p>
            <p>
              {{ carData.gearbox?.name}}
            </p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Gears number</p>
            <p>{{ carData.numberOfGears }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Driving wheel</p>
            <p>
              {{ carData.drivingWheel?.name}}
            </p>
          </div>
          <div class="flex justify-between mb-6">
            <p>List price net</p>
            <p>{{ carData.priceNetto }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>List price gross</p>
            <p>{{ carData.priceBrutto }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Colour</p>
            <p>{{ carData.colourDescription }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Doors</p>
            <p>{{ carData.doors }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Body</p>
            <p>
              {{ carData.bodyType?.name }}
            </p>
          </div>
        </div>

        <div class="text-sm border-white-500 pl-12">
          <div class="flex justify-between mb-6">
            <p>Seats</p>
            <p>{{ carData.seats }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Emmision class EU</p>
            <p>{{ carData.pollutionNorm }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>No. of cilinders</p>
            <p>{{ carData.numberOfCylinder }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Torque</p>
            <p>{{ carData.torque ? (carData.torque.value | number) : '-' }}{{ carData.torque?.unit }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Length</p>
            <p>{{ carData.length ? (carData.length.value | number) : '-' }}{{ carData.length?.unit }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Height</p>
            <p>{{ carData.height ? (carData.height.value | number) : '-' }}{{ carData.height?.unit }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Width</p>
            <p>{{ carData.width ? (carData.width.value | number) : '-' }}{{ carData.width?.unit }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Fuel tank capacity</p>
            <p>{{ carData.tankCapacity ? (carData.tankCapacity.value | number) : '-' }}{{ carData.tankCapacity?.unit }}
            </p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Curb weight</p>
            <p>{{ carData.weight ? (carData.weight.value | number) : '-' }}{{ carData.weight?.unit }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Top speed</p>
            <p>{{ carData.topSpeed ? (carData.topSpeed.value | number) : '-' }}{{ carData.topSpeed?.unit }}</p>
          </div>

          <div class="flex justify-between mb-6">
            <p>Urban consumption</p>
            <p>{{ carData.consumptionUrban ? (carData.consumptionUrban.value | number) : '-'
              }}{{carData.consumptionUrban?.unit }}
            </p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Extra urban consumption</p>
            <p>{{ carData.consumptionExtraUrban ? (carData.consumptionExtraUrban.value | number) : '-'
              }}{{carData.consumptionExtraUrban?.unit }}
            </p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Mixed consumption NEDC</p>
            <p>{{ carData.consumptionNedc ? (carData.consumptionNedc.value | number) : '-' }}{{
              carData.consumptionNedc?.unit }}
            </p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Mixed consumption WLTP</p>
            <p>{{ carData.consumptionWltp ? (carData.consumptionWltp.value | number) : '-'
              }}{{carData.consumptionWltp?.unit }}
            </p>
          </div>
          <div class="flex justify-between mb-6">
            <p>CO2 emissions NEDC</p>
            <p>{{ carData.co2Nedc }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>CO2 emissions WLTP</p>
            <p>{{ carData.co2Wltp }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Electric range</p>
            <p>{{ carData.electricRange }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Front tyres notation</p>
            <p>{{ carData.frontTyreNotation }}</p>
          </div>
          <div class="flex justify-between mb-6">
            <p>Rear tyres notation</p>
            <p>{{ carData.rearTyreNotation }}</p>
          </div>
        </div>
      </div>
    </app-main-content-layout>

    <app-main-content-layout *ngSwitchCase="'standard'">
      <div class="text-sm twoCols">
        <div class="flex flex-col gap-6">
          <div
            *ngFor="let eq of standardEquipment | slice: 0: (standardEquipment.length === 1 ? 1 : standardEquipment.length/2)">
            <div class="flex gap-3 items-center cursor-pointer" (click)="eq.isOpen = !eq.isOpen">
              <fa-icon [icon]="['fal',eq.isOpen ? 'chevron-up' : 'chevron-down']"></fa-icon>
              <p>{{eq.name}}</p>
            </div>

            <div class="ml-3 mt-3">
              <div *ngFor="let subEq of eq.equipmentItems" class="flex gap-3 items-center"
                [ngClass]="{'hidden' : !eq.isOpen}">
                <fa-icon [icon]="['fal','circle-check']"></fa-icon>
                <p>{{subEq.name}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="pl-6 border-l border-white-500 flex flex-col gap-6">
          <div
            *ngFor="let eq of standardEquipment | slice: (standardEquipment.length === 1 ? 1 : standardEquipment.length/2): standardEquipment.length">
            <div class="flex gap-3 items-center cursor-pointer" (click)="eq.isOpen = !eq.isOpen">
              <fa-icon [icon]="['fal',eq.isOpen ? 'chevron-up' : 'chevron-down']"></fa-icon>
              <p>{{eq.name}}</p>
            </div>

            <div class="ml-3 mt-3" [ngClass]="{'hidden' : !eq.isOpen}">
              <div *ngFor="let subEq of eq.equipmentItems" class="flex gap-3 items-center">
                <fa-icon [icon]="['fal','circle-check']"></fa-icon>
                <p>{{subEq.name}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-main-content-layout>

    <app-main-content-layout *ngSwitchCase="'optional'">
      <div class="text-sm twoCols">
        <div class="flex flex-col gap-6">
          <div
            *ngFor="let eq of optionalEquipment | slice: 0: (optionalEquipment.length === 1 ? 1 : optionalEquipment.length /2)">
            <div class="flex justify-between gap-3 items-center cursor-pointer" (click)="eq.isOpen = !eq.isOpen">
              <div class="flex gap-3 items-center">
                <fa-icon [icon]="['fal',eq.isOpen ? 'chevron-up' : 'chevron-down']"></fa-icon>
                <p>{{eq.name}}</p>
              </div>


              <p>{{eq.priceBrutto}} {{eq.currency}}</p>
            </div>

            <div class="ml-3 mt-3">
              <div *ngFor="let subEq of eq.equipmentItems" class="flex gap-3 items-center"
                [ngClass]="{'hidden' : !eq.isOpen}">
                <fa-icon [icon]="['fal','circle-check']"></fa-icon>
                <p>{{subEq.name}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="pl-6 border-l border-white-500 flex flex-col gap-6">
          <div
            *ngFor="let eq of optionalEquipment | slice: (optionalEquipment.length === 1 ? 1 : optionalEquipment.length /2): optionalEquipment.length">
            <div class="flex justify-between gap-3 items-center cursor-pointer" (click)="eq.isOpen = !eq.isOpen">
              <div class="flex gap-3 items-center">
                <fa-icon [icon]="['fal',eq.isOpen ? 'chevron-up' : 'chevron-down']"></fa-icon>
                <p>{{eq.name}}</p>
              </div>


              <p>{{eq.priceBrutto}} {{eq.currency}}</p>
            </div>

            <div class="ml-3 mt-3" [ngClass]="{'hidden' : !eq.isOpen}">
              <div *ngFor="let subEq of eq.equipmentItems" class="flex gap-3 items-center">
                <fa-icon [icon]="['fal','circle-check']"></fa-icon>
                <p>{{subEq.name}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-main-content-layout>
  </div>
</div>

<ng-template #placeholder>
  <div class="twoCols">
    <app-main-content-placeholder></app-main-content-placeholder>
    <app-main-content-placeholder></app-main-content-placeholder>
  </div>
</ng-template>
