import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SellerCompanyDTO } from 'src/app/core/models/seller-models/seller-create.model';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { InfoService } from 'src/app/core/services/infos.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-seller-company',
  templateUrl: './seller-company.component.html',
  styleUrls: ['./seller-company.component.scss']
})
export class SellerCompanyComponent implements OnInit {
  createMode: boolean = this.b2bService.createMode;

  public saveFormSubscription: Subscription = new Subscription();
  public routeSubscription: Subscription = new Subscription();

  sellerId = this.b2bService.sellerId;
  validatedVAT: boolean = false;
  stores = this.b2bService.sellerStores;
  seller = this.b2bSellerSyncService.seller;

  countryIsoControl: FormControl = new FormControl('', Validators.required);
  vatControl: FormControl = new FormControl('', Validators.required);

  countryIsoCodes: DropdownOption[] = this.coreService.countries.map(co => ({ value: co.id, viewValue: co.iso }));
  countries: DropdownOption[] = this.coreService.countries.map(co => ({ value: co.id, viewValue: co.name }));
  makes: DropdownOption[] = this.b2bService.makes;
  sellerTypes: DropdownOption[] = this.b2bService.sellerTypes;
  validationTypes: DropdownOption[] = this.b2bService.validationTypes;
  kamUsers: DropdownOption[] = this.b2bService.kams;

  dateCreated = new Date();

  statusControl = new FormControl({ value: true, disabled: true });
  vatStatusControl = new FormControl({ value: false, disabled: true });

  sellerDetailsForm = this.fb.group({
    name: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    website: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    makesSold: new FormControl<string[] | undefined>([], Validators.required),
    makesRep: new FormControl<string[] | undefined>([], Validators.required),
    sellerType: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    kamId: ['', Validators.required]
  });

  constructor(private coreService: CoreService,
    private infoService: InfoService,
    private fb: FormBuilder,
    private b2bService: B2BSellerSyncService,
    private snackbar: SnackbarService,
    private spinnerService: SpinnerHandlerService,
    private b2bSellerSyncService: B2BSellerSyncService) { }

  ngOnInit() {
    this.routeSubscription = this.b2bService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.sellerDetailsForm.invalid) {
          this.snackbar.negativeSentiment('You need to complete ad settings to continue');
        } else {
          this.b2bService.setCurrentTab(value);
        }
      }
    )

    this.saveFormSubscription = this.b2bService.saveFormEvent$.subscribe((value) => {
      if (this.sellerDetailsForm.invalid) {
        this.snackbar.negativeSentiment('You need to complete all required fields');

        return;
      }

      if (this.vatControl.invalid) {
        this.snackbar.negativeSentiment('You need to complete VAT field');

        return;
      }

      this.saveSeller();
    });

    if (this.seller) {
      this.patchB2BSellerValues(this.seller);
    }
  }

  ngOnDestroy() {
    this.saveFormSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  saveSeller() {
    let seller = new SellerCompanyDTO(this.sellerDetailsForm.value, this.sellerDetailsForm.controls.country.value!, this.vatControl.value, this.vatStatusControl.value!)

    this.b2bSellerSyncService.seller = seller;

    this.b2bService.setCurrentTab('stores');
  }

  patchB2BSellerValues(seller: SellerCompanyDTO) {
    this.sellerDetailsForm.patchValue({
      name: seller.name,
      kamId: seller.kamId,
      sellerType: seller.typeId,
      address: seller.address,
      city: seller.city,
      country: seller.countryId,
      postalCode: seller.postalCode,
      website: seller.companyWebsite,
      phone: seller.phone,
      email: seller.email,
      makesRep: seller.repMakes,
      makesSold: seller.soldMakes
    })

    this.vatStatusControl.setValue(seller.vatStatus);
    this.vatControl.setValue(seller.vat);

    if (seller.vatStatus) {
      this.vatControl.disable();
      this.validatedVAT = true;
      this.sellerDetailsForm.controls.country.disable();
    }
  }

  openCountriesAvailabilityModal() {
    this.infoService.openCountriesAvailabilityModal();
  }

  validateVAT() {
    let isoCode = this.countryIsoCodes.find(c => c.value === this.countryIsoControl.value)!.viewValue;

    if (this.vatControl.invalid || this.countryIsoControl.invalid || !this.infoService.checkVATNumber(isoCode, this.vatControl.value)) {
      this.snackbar.negativeSentiment("Invalid VAT, check VAT number and country code");

      return;
    }

    this.spinnerService.showProgressBar.next(true);

    this.infoService.validateVAT(this.vatControl.value, isoCode).subscribe({
      next: (resp) => {
        this.sellerDetailsForm.patchValue({
          name: resp.companyName,
          address: resp.address,
          country: this.countryIsoCodes.find(c => c.viewValue === isoCode)!.value
        })

        this.vatStatusControl.setValue(true);
        this.vatControl.disable();
        this.sellerDetailsForm.enable();
        this.sellerDetailsForm.controls.country.disable();
        this.validatedVAT = true;

        this.spinnerService.showProgressBar.next(false);
      },
      error: (err) => {
        this.snackbar.negativeSentiment("VAT not validated");
        this.spinnerService.showProgressBar.next(false);
      }
    });
  }

  resetVat() {
    this.vatControl.reset();
    this.vatControl.enable();
    this.vatStatusControl.reset();
    this.countryIsoControl.reset();
    this.validatedVAT = false;
    this.vatStatusControl.setValue(false);
    this.sellerDetailsForm.controls.country.enable();
  }
}
