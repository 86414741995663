<div class="flex flex-col gap-9">
  <!-- transports details -->
  <div class="grid grid-cols-3 gap-6">
    <div class="twoCols bg-white rounded p-9">
      <div class="flex flex-col justify-between gap-4 pr-6 border-r border-white-500">
        <p>Number of cars included in transport</p>

        <p class="text-blue-500 text-2xl">{{getCarsInTransport() | number }}</p>
      </div>

      <div class="flex flex-col justify-between gap-4">
        <p>Number of cars not included in transport</p>

        <p class="text-blue-500 text-2xl">{{orderTransportDetails.carsNotInTransport}}</p>
      </div>
    </div>

    <div class="twoCols bg-white rounded p-9">
      <div class="flex flex-col justify-between gap-4 pr-6 border-r border-white-500">
        <p>Initial total transportation fee</p>

        <p class="text-blue-500 text-2xl">€{{orderTransportDetails.fee | number}}</p>
      </div>

      <div class="flex flex-col justify-between gap-4">
        <p>Actual total transportation fee</p>

        <p class="text-blue-500 text-2xl">€{{orderTransportDetails.actualFee | number}}</p>
      </div>
    </div>

    <div class="twoCols bg-white rounded p-9">
      <div class="flex flex-col justify-between gap-4 pr-6 border-r border-white-500">
        <p>Transportation profit <br><span class="text-sm text-white-800">(initial profit - actual profit)</span></p>

        <p class="text-blue-500 text-2xl">€{{orderTransportDetails.profit | number}}</p>
      </div>

      <div class="flex flex-col justify-between gap-4">
        <p>Number of transports <br><span class="text-sm text-white-800">(whole order)</span></p>

        <p class="text-blue-500 text-2xl">{{transports.length}}</p>
      </div>
    </div>
  </div>

  <!-- cars not in transport table -->
  <div class="flex justify-between items-center" *ngIf="carsNotInTransport.length > 0">
    <p class="text-xl">Cars not included in transport</p>

    <div class="flex gap-6" *ngIf="orderStatus != orderStatuses.FINISHED">
      <app-white-btn id="add-transport-order" [disableBtn]="orderStatus === orderStatuses.PENDING"
        (clickEmitter)="openAddTransportModal()">
        Create transport order
      </app-white-btn>

      <primary-btn id="add-transport-to-order" [disableBtn]="orderStatus === orderStatuses.PENDING"
        (clickEmitter)="openAddCarsToTransportModal()">
        Add cars to transport order
      </primary-btn>
    </div>
  </div>

  <app-table #carsNotInTransportTable *ngIf="carsNotInTransport.length > 0" [displayedColumns]="displayedColumns"
    [headersMap]="headers" [data]="carsNotInTransport" id="carsNotInTransportTable"></app-table>


  <!-- transport dropdown and buttons -->
  <div class="flex justify-between">
    <app-dropdown id="transports" label="Transport" placeholder="Select transport" [control]="transportControl"
      [options]="transportDropdown" (emitOnChangeSelect)="selectTransport($event)"></app-dropdown>

    <div class="flex gap-6" *ngIf="orderStatus != orderStatuses.FINISHED">
      <secondary-btn id="delete-transport-order" [disableBtn]="true">Delete transport order</secondary-btn>
      <app-white-btn id="remove-from-transport-order" (clickEmitter)="removeCarFromTransport()">Remove cars from
        transport
        order</app-white-btn>
    </div>
  </div>

  <!-- cars in transport table -->
  <p class="text-xl" *ngIf="carsInTransport.length > 0">Cars included in transport</p>

  <app-table #carsInTransportTable *ngIf="carsInTransport.length > 0" [displayedColumns]="displayedColumns"
    [headersMap]="headers" [data]="carsInTransport" id="carsInTransportTable"></app-table>

  <!-- transport info -->
  <app-main-content-layout *ngIf="selectedTransport">
    <div class="title">
      <p>Transportation docs</p>
    </div>

    <div class="twoCols">
      <div class="flex flex-col gap-6 pr-6 border-r border-white-500">
        <app-main-content-row-layout>
          <p>Company</p>

          <app-input class="secondContent" [control]="transportDetailsForm.controls.companyName"
            placeholder="Insert company name"></app-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Transportation cost</p>

          <app-read-only-input class="secondContent">€{{getTransportCost() | number}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Transportation status</p>

          <app-dropdown placeholder="Select status" class="secondContent"
            [control]="transportDetailsForm.controls.status" [options]="transportStatuses"></app-dropdown>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Pick up date</p>

          <app-datepicker class="secondContent" [control]="transportDetailsForm.controls.pickupDate"></app-datepicker>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Estimated delivery</p>

          <app-datepicker class="secondContent"
            [control]="transportDetailsForm.controls.estimatedDeliveryDate"></app-datepicker>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p
            [ngClass]="{'font-bold text-red' : transportDetailsForm.controls.status.value === transportStatusEnum.Delivered && !transportDetailsForm.controls.actualDeliveryDate.value}">
            Actual
            delivery date</p>

          <app-datepicker class="secondContent"
            [disabled]="transportDetailsForm.controls.status.value != transportStatusEnum.Delivered"
            [control]="transportDetailsForm.controls.actualDeliveryDate"></app-datepicker>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Load</p>

          <app-input class="secondContent" [control]="transportDetailsForm.controls.load"
            placeholder="Insert truck load (Empty/Partial/Full)"></app-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Truck reg</p>

          <app-input class="secondContent" [control]="transportDetailsForm.controls.truckReg"
            placeholder="Insert truck reg"></app-input>
        </app-main-content-row-layout>

        <!-- <app-main-content-row-layout>
          <app-checkbox componentId="transporter-paid" label="Transporter paid" [disabledCheckbox]="true"
            (checkboxValueChange)="toggleDatePicker('paid',$event)"
            [(checkboxValue)]="transporterPaidSent"></app-checkbox>

          <app-datepicker id="transporter-paid" class="secondContent"
            [control]="transporterPaidSentControl"></app-datepicker>
        </app-main-content-row-layout>

        <div class="flex justify-end">
          <app-blue-btn [disableBtn]="transporterPaidSentControl.disabled || !transporterPaidSentControl.value"
            id="confirm-transporter-paid">Confirm transporter
            paid</app-blue-btn>
        </div> -->

        <app-main-content-row-layout>
          <app-checkbox componentId="transportation-invoice-sent" label="Transportation invoice sent"
            [disabledCheckbox]="selectedTransport.documents.invoice.length === 0"
            (checkboxValueChange)="toggleDatePicker('invoice',$event)"
            [(checkboxValue)]="transportInvoiceSent"></app-checkbox>

          <app-datepicker id="seller-paid" class="secondContent"
            [control]="transportDetailsForm.controls.invoiceSentAt"></app-datepicker>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <app-checkbox componentId="cmr-sent" label="CMR sent"
            [disabledCheckbox]="selectedTransport.documents.CMR.length === 0"
            (checkboxValueChange)="toggleDatePicker('cmr',$event)" [(checkboxValue)]="cmrSent"></app-checkbox>

          <app-datepicker id="seller-paid" class="secondContent"
            [control]="transportDetailsForm.controls.CMRSentAt"></app-datepicker>
        </app-main-content-row-layout>

        <div class="flex justify-end" *ngIf="orderStatus != orderStatuses.FINISHED">
          <app-blue-btn (clickEmitter)="saveTransportDetails()">
            Save transport details
          </app-blue-btn>
        </div>
      </div>

      <div class="flex flex-col gap-6">
        <input id="uploadInput" class="hidden" #selectFile type="file" accept=".pdf" [formControl]="uploadFileControl"
          (change)="uploadFile($event)">

        <app-main-content-row-layout>
          <p>Transportation invoice</p>

          <div class="secondContent flex flex-col gap-4 items-end">
            <secondary-btn *ngIf="orderStatus != orderStatuses.FINISHED" id="upload-transport-invoice"
              (clickEmitter)="openUpload(docTypes.Transport)" [disableBtn]="!checkTransportSeparate()">
              <div class="flex items-center gap-4 rounded">
                <fa-icon [icon]="['fal','up-to-line']"></fa-icon>

                <p>Upload file</p>
              </div>
            </secondary-btn>
          </div>
        </app-main-content-row-layout>

        <div class="flex flex-wrap gap-6 pt-6 border-t border-white-500"
          *ngIf="selectedTransport.documents.invoice.length > 0">
          <div class="bg-white-700 rounded px-9 py-3 flex gap-4 w-fit items-center"
            *ngFor="let doc of selectedTransport.documents.invoice; let i = index">
            <p class="break-all">{{doc.name}}</p>
          </div>
        </div>

        <app-main-content-row-layout class="mt-4">
          <p>CMR</p>

          <div class="secondContent flex flex-col gap-4 items-end">
            <secondary-btn *ngIf="orderStatus != orderStatuses.FINISHED" id="upload-tcmr"
              (clickEmitter)="openUpload(docTypes.CMR)">
              <div class="flex items-center gap-4 rounded">
                <fa-icon [icon]="['fal','up-to-line']"></fa-icon>

                <p>Upload file</p>
              </div>
            </secondary-btn>
          </div>
        </app-main-content-row-layout>

        <div class="flex flex-wrap gap-6 pt-6 border-t border-white-500"
          *ngIf="selectedTransport.documents.CMR.length > 0">
          <div class="bg-white-700 rounded px-9 py-3 flex gap-4 w-fit items-center"
            *ngFor="let doc of selectedTransport.documents.CMR; let i = index">
            <p class="break-all">{{doc.name}}</p>
          </div>
        </div>

      </div>
    </div>
  </app-main-content-layout>

  <!-- transport pickup and delivery locations -->
  <app-main-content-layout *ngIf="selectedTransport">
    <div class="title">
      <p>Locations</p>
    </div>

    <div class="twoCols">
      <div class="flex flex-col gap-9 pr-6 border-r border-white-500">
        <div class="flex flex-col gap-6" *ngFor="let location of pickupLocations; let i = index">
          <p class="text-sm font-bold">PICK UP LOCATION {{i+1}}</p>

          <div class="flex flex-col gap-6">
            <div class="grid grid-cols-1 xl:grid-cols-2 gap-6 items-center" *ngFor="let car of location.cars">
              <div class="flex gap-6 bg-blue-100 text-white py-3 px-6 rounded w-fit">
                <p>{{car.make}}</p>
                <p class="px-6 border-r border-l border-l-blue-300 border-r-blue-300">{{car.model}}</p>
                <p>{{car.regNo}}</p>
              </div>

              <div class="grid grid-cols-3 gap-4 items-center mt-1 lg:mt-0">
                <app-input-number-ngmodel [disabled]="orderStatus === orderStatuses.FINISHED"
                  [id]="'cost-car-'+car.carMainInfoId" class="col-span-2" placeholder="Insert car transport cost"
                  label="Car transport cost" [(inputValue)]="car.cost"></app-input-number-ngmodel>

                <primary-btn (clickEmitter)="saveCost(car)" *ngIf="orderStatus != orderStatuses.FINISHED"
                  [disableBtn]="!(car.cost && car.cost > 0)">Save</primary-btn>
              </div>
            </div>
          </div>

          <app-main-content-row-layout>
            <p>Country</p>

            <app-read-only-input class="secondContent">{{location.locationDetails.location}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>City</p>

            <app-read-only-input class="secondContent">{{location.locationDetails.city ? location.locationDetails.city :
              'Not set'}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Address</p>

            <app-read-only-input class="secondContent">{{location.locationDetails.address ?
              location.locationDetails.address :
              'Not set'}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Zipcode</p>

            <app-read-only-input class="secondContent">{{location.locationDetails.postalCode ?
              location.locationDetails.postalCode :
              'Not set'}}</app-read-only-input>
          </app-main-content-row-layout>
        </div>
      </div>

      <div class="flex flex-col gap-9 pr-6 border-r border-white-500">
        <div class="flex flex-col gap-6" *ngFor="let location of deliveryLocations; let i = index">
          <p class="text-sm font-bold">DELIVERY LOCATION {{i+1}}</p>

          <div class="flex flex-col gap-6">
            <div class="flex gap-6 bg-blue-100 text-white py-3 px-6 rounded w-fit" *ngFor="let car of location.cars">
              <p>{{car.make}}</p>
              <p class="px-6 border-r border-l border-l-blue-300 border-r-blue-300">{{car.model}}</p>
              <p>{{car.regNo}}</p>
            </div>
          </div>

          <app-main-content-row-layout>
            <p>Country</p>

            <app-read-only-input class="secondContent">{{buyerCountry}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>City</p>

            <app-read-only-input class="secondContent">{{location.locationDetails.city ?
              location.locationDetails.city :
              'Not set'}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Address</p>

            <app-read-only-input class="secondContent">{{location.locationDetails.address ?
              location.locationDetails.address :
              'Not set'}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Zipcode</p>

            <app-read-only-input class="secondContent">{{location.locationDetails.postalCode ?
              location.locationDetails.postalCode :
              'Not set'}}</app-read-only-input>
          </app-main-content-row-layout>
        </div>
      </div>
    </div>
  </app-main-content-layout>
</div>
