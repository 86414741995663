import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { Buyer, BuyerStore, BuyerStoreContact } from 'src/app/core/models/buyer.model';
import { Order, OrderSettings } from 'src/app/core/models/order.model';
import { KAMBuyers, User } from 'src/app/core/models/user.model';
import { BuyerService } from 'src/app/core/services/buyer.service';
import { CoreService } from 'src/app/core/services/core.service';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UserService } from 'src/app/core/services/user.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-order-settings',
  templateUrl: './order-settings.component.html',
  styleUrls: ['./order-settings.component.scss']
})
export class OrderSettingsComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);
  subscription = new Subscription();

  orderId = this.ordersService.orderId;
  isAdmin = this.coreService.isAdmin;

  selectedKAM: KAMBuyers | undefined;
  kams: KAMBuyers[] = [];
  kamDropdown: DropdownOption[] = [];
  kamControl = new FormControl('', Validators.required);

  selectedBuyer: Buyer | undefined;
  buyers: Partial<Buyer>[] = [];
  buyersDropdown: DropdownOption[] = [];

  selectedUser: User | undefined;
  users: User[] = [];
  usersDropdown: DropdownOption[] = [];

  selectedStore: BuyerStore | undefined;
  allBuyerStores: BuyerStore[] = [];
  stores: DropdownOption[] = [];

  selectedContact: BuyerStoreContact | undefined;
  contacts: BuyerStoreContact[] = [];
  contactsDropdown: DropdownOption[] = [];

  countries = this.coreService.countries;

  buyerControls = this.fb.group({
    buyer: ['', Validators.required],
    user: ['', Validators.required],
    store: ['', Validators.required],
    contact: ['', Validators.required],
  });

  orderDateControl = new FormControl<any>(null, Validators.required);

  constructor(private buyerService: BuyerService,
    private ordersService: OrdersFlowSyncService,
    private fb: FormBuilder,
    private coreService: CoreService,
    private userService: UserService,
    private spinnerService: SpinnerHandlerService,
    private snackbarService: SnackbarService) { }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.userService.getKAMBuyers().subscribe(resp => {
        this.kams = resp;
        this.kamDropdown = resp.map(k => ({ value: k.kamId, viewValue: `${k.firstName} ${k.lastName}` }));

        if (this.ordersService.orderId && this.ordersService.order) {
          this.loadOrderInfo(this.ordersService.order);
        } else if (this.ordersService.orderSettings) {
          this.loadInfo(this.ordersService.orderSettings);
        } else {
          this.loading.next(false);
        }
      })
    } else {
      this.selectedKAM = {
        kamId: this.coreService.user!.user.id,
        email: this.coreService.user!.user.email,
        phoneNumber: this.coreService.user!.user.phone,
        firstName: this.coreService.user!.user.firstName,
        lastName: this.coreService.user!.user.lastName,
        buyers: []
      }

      this.userService.getLoggedKAMBuyers().subscribe(resp => {
        // this.buyers = resp.map(b => ({
        //   id: '',
        //   vatStatus: true,
        // }));

        this.buyersDropdown = resp.map(b => ({ value: b.buyerId!, viewValue: b.name! }));

        if (this.ordersService.orderId && this.ordersService.order) {
          this.loadOrderInfo(this.ordersService.order);
        } else if (this.ordersService.orderSettings) {
          this.loadInfo(this.ordersService.orderSettings);
        } else {
          this.loading.next(false);
        }
      });
    }

    this.subscription.add(this.ordersService.continueEvent$.subscribe(resp => {
      if (this.loading.value) return;

      if (this.buyerControls.invalid || (this.isAdmin && this.kamControl.invalid) || this.orderDateControl.invalid) {
        this.snackbarService.negativeSentiment('Not all fields completed!');
        return;
      }

      this.save();

      this.ordersService.setCurrentTab('cars');
    }))

    this.subscription.add(this.ordersService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.loading.value) return;

        if (this.buyerControls.invalid || (this.isAdmin && this.kamControl.invalid) || this.orderDateControl.invalid) {
          this.snackbarService.negativeSentiment('Not all fields completed!');
          return;
        }

        this.save();

        this.ordersService.setCurrentTab(value);
      }
    ));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadOrderInfo(order: Order) {
    //patch form
    // this.buyerControls.patchValue({
    //   buyer: order.buyer.buyerId,
    //   user: order.buyer.client.clientId,
    //   store: order.buyer.store.storeId,
    //   contact: order.buyer.store!.contactDetails.id,
    // });

    //adds contacts to dropdown
    this.contactsDropdown.push({ viewValue: `${order.buyer.store!.contactDetails.firstName} ${order.buyer.store!.contactDetails.lastName}`, value: order.buyer.store!.contactDetails.id });

    //completes contact info
    // this.selectedContact = {
    //   firstName: order.buyer.store!.contactDetails.firstName,
    //   lastName: order.buyer.store!.contactDetails.lastName,
    //   email: order.buyer.store!.contactDetails.email,
    //   phone: order.buyer.store!.contactDetails.phone,
    //   id: order.buyer.store!.contactDetails.id
    // }

    this.buyerControls.disable();
    this.kamControl.disable();

    this.orderDateControl.setValue(order.orderDate);
    this.orderDateControl.disable();

    if (this.isAdmin) {
      //if admin must complete kam info
      this.kamControl.setValue(order.kamBuyer.id);
      this.selectKAM(order.kamBuyer.id);

      // this.getADMINInfos(order.buyer.buyerId, order.buyer.client.clientId, order.buyer.store.storeId);
    } else {
      // this.getKAMInfos(order.buyer.buyerId, order.buyer.client.clientId, order.buyer.store.storeId);
    }
  }

  loadInfo(orderSettings: OrderSettings) {
    this.buyerControls.patchValue({
      buyer: '',
      user: orderSettings.userBuyer.clientId,
      store: orderSettings.buyerStore.id,
    });

    this.orderDateControl.setValue(orderSettings.orderDate);

    if (this.isAdmin) {
      this.kamControl.setValue(orderSettings.kam.kamId);
      this.selectedKAM = orderSettings.kam;
    }

    this.selectedBuyer = orderSettings.buyer;
    this.selectedUser = orderSettings.userBuyer;
    this.selectedStore = orderSettings.buyerStore;
    this.selectedContact = orderSettings.buyerContact;

    if (this.isAdmin) {
      this.buyersDropdown = this.selectedKAM!.buyers.map(b => ({ value: b.id!, viewValue: b.name! }));

      this.getADMINInfos('orderSettings.buyer.id!', orderSettings.userBuyer.clientId!, orderSettings.buyerStore.id!);
    } else {
      this.getKAMInfos('orderSettings.buyer.id!', orderSettings.userBuyer.clientId!, orderSettings.buyerStore.id!);
    }
  }

  getADMINInfos(buyerId: string, userId: string, storeId: string, contactId?: string) {
    forkJoin({
      buyer: this.userService.getKAMBuyerCompanyDetails(buyerId),
      users: this.userService.getKAMBuyerCompanyUsers(buyerId)
    }).subscribe(resp => {
      if (this.ordersService.orderId) {
        this.setCarsDeliveryDocumentsCountries(resp.buyer, resp.users, userId);
      }

      this.users = resp.users;
      this.usersDropdown = resp.users.map(u => ({ value: u.clientId!, viewValue: `${u.firstName} ${u.lastName}` }));

      this.userService.getKAMBuyerCompanyUserStores(buyerId, userId).subscribe(stores => {
        if (this.ordersService.orderId) {
          this.selectedStore = stores.find(s => s.id === storeId);
        }

        this.allBuyerStores = stores;
        this.stores = stores.map(s => ({ value: s.id!, viewValue: s.name! }));

        if (contactId) {
          this.userService.getKAMBuyerCompanyStoreContact(storeId).subscribe(contacts => {
            this.contacts = contacts;
            this.contactsDropdown = contacts.map(c => ({ value: c.email, viewValue: `${c.firstName} ${c.lastName}` }));

            this.loading.next(false);
          })
        } else {
          this.loading.next(false);
        }
      })
    })
  }

  getKAMInfos(buyerId: string, userId: string, storeId: string, contactId?: string) {
    forkJoin({
      buyer: this.userService.getLoggedKAMBuyerCompanyDetails(buyerId),
      users: this.buyerService.getLoggedKAMBuyerUsers(buyerId)
    }).subscribe(resp => {
      if (this.ordersService.orderId) {
        this.setCarsDeliveryDocumentsCountries(resp.buyer, resp.users, userId);
      }

      this.users = resp.users;
      this.usersDropdown = resp.users.map(u => ({ value: u.clientId!, viewValue: `${u.firstName} ${u.lastName}` }));

      this.userService.getLoggedKAMBuyerCompanyUserStores(buyerId, userId).subscribe(stores => {
        if (this.ordersService.orderId) {
          this.selectedStore = stores.find(s => s.id === storeId);
        }

        this.allBuyerStores = stores;
        this.stores = stores.map(s => ({ value: s.id!, viewValue: s.name! }));

        if (contactId) {
          this.userService.getLoggedKAMBuyerCompanyStoreContact(storeId).subscribe(contacts => {
            this.contacts = contacts;
            this.contactsDropdown = contacts.map(c => ({ value: c.email, viewValue: `${c.firstName} ${c.lastName}` }));

            this.loading.next(false);
          })
        } else {
          this.loading.next(false);
        }
      })
    })
  }

  setCarsDeliveryDocumentsCountries(buyer: Buyer, users: User[], userId: string) {
    this.selectedBuyer = buyer;
    this.selectedUser = users.find(u => u.clientId === userId);
    this.ordersService.carList = this.ordersService.carList.map(c => ({
      ...c,
      deliveryCountry: buyer.countryDetails.countryId,
      deliveryCountryName: buyer.countryDetails.country,
      documentsCountry: buyer.countryDetails.countryId,
      documentsCountryName: buyer.countryDetails.country,
    }))
  }

  save() {
    this.ordersService.orderSettings = {
      kam: this.selectedKAM!,
      buyer: this.selectedBuyer!,
      userBuyer: this.selectedUser!,
      buyerStore: this.selectedStore!,
      buyerContact: this.selectedContact!,
      orderDate: typeof (this.orderDateControl.value) === 'string' ? this.orderDateControl.value! : this.orderDateControl.value!.format("yyyy-MM-DD")
    };
  }

  selectKAM(kamId: string) {
    this.selectedKAM = this.kams.find(b => b.kamId === kamId);

    this.buyersDropdown = this.selectedKAM!.buyers.map(b => ({ value: b.id!, viewValue: b.name! }));
  }

  selectBuyer(buyerId: string) {
    this.spinnerService.showProgressBar.next(true);

    if (this.isAdmin) {
      forkJoin({
        buyer: this.userService.getKAMBuyerCompanyDetails(buyerId),
        users: this.userService.getKAMBuyerCompanyUsers(buyerId)
      }).subscribe(resp => {
        this.selectedBuyer = resp.buyer;

        this.users = resp.users;
        this.usersDropdown = resp.users.map(u => ({ value: u.clientId!, viewValue: `${u.firstName} ${u.lastName}` }));

        this.spinnerService.showProgressBar.next(false);
      })
    } else {
      forkJoin({
        buyerDetails: this.userService.getLoggedKAMBuyerCompanyDetails(buyerId),
        users: this.buyerService.getLoggedKAMBuyerUsers(buyerId)
      }).subscribe(resp => {
        this.selectedBuyer = resp.buyerDetails;

        this.users = resp.users;
        this.usersDropdown = resp.users.map(u => ({ value: u.clientId!, viewValue: `${u.firstName} ${u.lastName}` }));

        this.spinnerService.showProgressBar.next(false);
      })
    }
  }

  selectUser(userId: string) {
    this.selectedUser = this.users.find(u => u.clientId === userId);
    this.spinnerService.showProgressBar.next(true);

    if (this.isAdmin) {
      this.userService.getKAMBuyerCompanyUserStores('this.selectedBuyer!.id!', userId).subscribe(resp => {
        this.allBuyerStores = resp;
        this.stores = resp.map(s => ({ value: s.id!, viewValue: s.name! }));

        this.spinnerService.showProgressBar.next(false);
      })
    } else {
      this.userService.getLoggedKAMBuyerCompanyUserStores('this.selectedBuyer!.id!', userId).subscribe(resp => {
        this.allBuyerStores = resp;
        this.stores = resp.map(s => ({ value: s.id!, viewValue: s.name! }));

        this.spinnerService.showProgressBar.next(false);
      })
    }
  }

  selectStore(storeId: string) {
    this.selectedStore = this.allBuyerStores.find(s => s.id === storeId);
    this.spinnerService.showProgressBar.next(true);

    if (this.isAdmin) {
      this.userService.getKAMBuyerCompanyStoreContact(storeId).subscribe(resp => {
        this.contacts = resp;
        this.contactsDropdown = resp.map(c => ({ value: c.email, viewValue: `${c.firstName} ${c.lastName}` }));

        this.spinnerService.showProgressBar.next(false);
      })
    } else {
      this.userService.getLoggedKAMBuyerCompanyStoreContact(storeId).subscribe(resp => {
        this.contacts = resp;
        this.contactsDropdown = resp.map(c => ({ value: c.email, viewValue: `${c.firstName} ${c.lastName}` }));

        this.spinnerService.showProgressBar.next(false);
      })
    }
  }

  selectContact(contactId: string) {
    this.selectedContact = this.contacts.find(c => c.email === contactId);
  }
}
