import { Component, OnInit } from '@angular/core';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './app-spinner.component.html',
  styleUrls: ['./app-spinner.component.scss']
})
export class AppSpinnerComponent {
  spinnerActive: boolean = true;

  constructor(public spinnerHandler: SpinnerHandlerService) {
    this.spinnerHandler.showSpinner.subscribe(this.showSpinner.bind(this));
  }

  showSpinner(state: boolean) {
    this.spinnerActive = state;
  };

}
