<div class="flex flex-col gap-9">
  <div class="flex justify-between">
    <app-dropdown id="stores" label="Store" placeholder="Select store" (emitOnChangeSelect)="loadStore($event)"
      [control]="storeControl" [options]="storesOptions"></app-dropdown>

    <secondary-btn id="edit-mode" *ngIf="isAdmin && !editMode" (clickEmitter)="editMode = true">Edit
      deliveries</secondary-btn>

    <secondary-btn *ngIf="editMode" id="save-restriction" btnText="Save restriction"
      (clickEmitter)="saveRestriction()"></secondary-btn>
  </div>

  <app-main-content-layout>
    <div class="title">
      <p>Add/update deliveries</p>
    </div>

    <div class="twoCols">
      <div class="col-span-1 flex flex-col gap-9">
        <app-main-content-row-layout class="py-9 border-b border-white-500"
          *ngFor="let window of windows;let i = index">
          <div class="flex gap-6 items-center">
            <p class="min-w-[4.7rem]">{{window.name}}</p>

            <mat-checkbox [id]="'enable-window-'+i+'-checkbox'" [attr.data-cy]="'enable-window-'+i+'-checkbox'"
              [disabled]="!editMode" [(ngModel)]="window.checkbox"></mat-checkbox>
          </div>

          <div class="secondContent twoCols">
            <app-dropdown [id]="'select-hour-from-window-' + i" class="col-span-1" placeholder="Select hour"
              label="From" [options]="hours" [disabled]="!window.checkbox || !editMode"
              [control]="window.from"></app-dropdown>

            <app-dropdown [id]="'select-hour-to-window-' + i" class="col-span-1" placeholder="Select hour" label="To"
              [options]="hours" [disabled]="!window.checkbox || !editMode" [control]="window.to"></app-dropdown>
          </div>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Country</p>

          <app-read-only-input class="secondContent">{{country}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Use country’s bank holidays</p>

          <app-toggle [toggleDisabled]="!editMode" id="country-holidays" class="secondContent"
            [toggleFormControl]="countryBankHolidaysControl" [toggleDisabled]="true"></app-toggle>
        </app-main-content-row-layout>
      </div>

      <div class="col-span-1 pl-9 border-l border-white-500">
        <textarea [disabled]="!editMode" id="notes-textarea" data-cy="notes-textarea" [(ngModel)]="notes" rows="15"
          class="w-full rounded border border-white-500 p-6 text-blue-100 text-sm focus:outline-none"
          placeholder="Insert notes here..."></textarea>
      </div>
    </div>
  </app-main-content-layout>
</div>
