import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ISellerStore } from 'src/app/core/models/seller.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

interface SellerTableRow {
  id: string,
  isSelected: boolean,
  isB2B: boolean,
  sellerOnboard: string,
  kamName: string,
  country: {
    country: string,
    countryId: string
  },
  companyName: string,
  vat: string,
  address: string,
  defaultStore: string | undefined,
  makesRep: string,
  makesSold: string,
  status: string,
}

@Component({
  selector: 'app-view-sellers',
  templateUrl: './view-sellers.component.html',
  styleUrls: ['./view-sellers.component.scss']
})
export class ViewSellersComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  tableColumns: TableHeaderMap[] = [
    {
      tableView: 'KAM',
      value: 'kamName'
    },
    {
      tableView: 'COUNTRY',
      value: 'country.country'
    },
    {
      tableView: 'NAME',
      value: 'companyName'
    },
    {
      tableView: 'SELLER ONBOARD',
      value: 'isB2B'
    },
    {
      tableView: 'vat',
      value: 'vat'
    },
    {
      tableView: 'ADDRESS',
      value: 'address'
    },
    {
      tableView: 'default Store',
      value: 'defaultStore'
    },
    {
      tableView: 'Makes Represented',
      value: 'makesRep'
    },
    {
      tableView: 'Makes sold',
      value: 'makesSold'
    },
    {
      tableView: 'status',
      value: 'status'
    }
  ];

  displayedColumns: string[] = ['selectAll'].concat(this.tableColumns.map(t => t.value), ['edit']);
  sellers: SellerTableRow[] = [];
  filteredSellers: SellerTableRow[] = [];

  nameControl = new FormControl();
  countryControl = new FormControl();
  kamControl = new FormControl();
  statusControl = new FormControl();
  makesSoldControl = new FormControl();
  makesRepControl = new FormControl();


  countries: DropdownOption[] = this.coreService.countriesDropdown;
  makes: DropdownOption[] = [];
  statuses: DropdownOption[] = [{ viewValue: 'B2B ready', value: 'b2b' }, { viewValue: 'Online', value: 'online' }];


  @ViewChild('sellerTable') sellerTable: AppTableComponent | undefined;

  constructor(private sellerService: SellerService,
    private coreService: CoreService,
    private carService: CarService,
    private router: Router) { }

  ngOnInit(): void {
    forkJoin({
      sellers: this.sellerService.getAllSellers(),
      makes: this.carService.getCarNomenclatorData('makes'),
    }).subscribe(resp => {
      this.sellers = resp.sellers.map(s => ({
        id: s.companyId,
        isSelected: false,
        isB2B: s.sellerCompanyDetails.b2bReady,
        sellerOnboard: s.sellerCompanyDetails.b2bReady ? 'B2B ready' : 'Online',
        kamName: `${s.kamDetails.firstName} ${s.kamDetails.lastName}`,
        country: s.sellerCompanyDetails.countryDetails,
        companyName: s.sellerCompanyDetails.name,
        vat: s.sellerCompanyDetails.vatDetails.vat,
        address: s.sellerCompanyDetails.address,
        defaultStore: s.stores.find((s: ISellerStore) => s.defaultStore)?.name,
        makesRep: s.sellerCompanyDetails.repMakes.join(`, `),
        makesSold: s.sellerCompanyDetails.soldMakes.join(`, `),
        status: s.sellerCompanyDetails.status ? 'Active' : 'Inactive',
      }));

      this.makes = resp.makes.map(m => ({ value: m, viewValue: m }));

      this.filteredSellers = this.sellers;

      this.loading.next(false);
    })
  }

  goToSeller(seller: SellerTableRow) {
    this.router.navigate([`seller/company/${seller.id}`])
  }

  applyFilters() {
    let data = this.sellers;

    if (this.nameControl.value) {
      data = data.filter(s => s.companyName.toLowerCase().match(this.nameControl.value.toLowerCase()));
    }

    if (this.countryControl.value) {
      data = data.filter(s => s.country.countryId === this.countryControl.value);
    }

    if (this.kamControl.value) {
      data = data.filter(s => s.kamName.toLowerCase().match(this.kamControl.value.toLowerCase()));
    }

    if (this.makesSoldControl.value) {
      data = data.filter(s => {
        let makesFound: string[] = [];

        this.makesSoldControl.value.forEach((pc: string) => {
          let found = s.makesSold!.split(', ').find(m => m === pc);

          found ? makesFound.push(found) : '';
        });

        return makesFound ? makesFound.length === this.makesSoldControl.value.length : false;
      })
    }

    if (this.makesRepControl.value) {
      data = data.filter(s => {
        let makesFound: string[] = [];

        this.makesRepControl.value.forEach((pc: string) => {
          let found = s.makesRep!.split(', ').find(m => m === pc);

          found ? makesFound.push(found) : '';
        });

        return makesFound ? makesFound.length === this.makesRepControl.value.length : false;
      })
    }

    if (this.statusControl.value) {
      let status = this.statusControl.value === "b2b" ? true : false;

      data = data.filter(s => s.isB2B === status);
    }

    this.filteredSellers = data;

    this.sellerTable?.updateTable(this.filteredSellers);
  }

  resetFilters() {
    this.countryControl.reset();
    this.statusControl.reset();
    this.kamControl.reset();
    this.makesSoldControl.reset();
    this.makesRepControl.reset();
    this.statusControl.reset();

    this.filteredSellers = this.sellers;

    this.sellerTable?.updateTable(this.filteredSellers);
  }
}
