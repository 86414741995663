import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderCar } from 'src/app/core/models/order.model';
import { CoreService } from 'src/app/core/services/core.service';

export interface OrderProcessCarDocumentsModalData {
  car: OrderCar,
  orderId: string,
  finishedOrder: boolean
}

@Component({
  selector: 'app-order-process-car-documents-modal',
  templateUrl: './order-process-car-documents-modal.component.html',
  styleUrls: ['./order-process-car-documents-modal.component.scss']
})
export class OrderProcessCarDocumentsModalComponent implements OnInit {
  finishedOrder = this.data.finishedOrder;

  car: OrderCar = JSON.parse(JSON.stringify(this.data.car));

  countries = this.coreService.countriesDropdown;

  documentsDeliveryForm = this.fb.group({
    country: [this.car.documentsDetails.country.id],
    city: [this.car.documentsDetails.city],
    address: [this.car.documentsDetails.address],
    zipcode: [this.car.documentsDetails.postalCode],
    firstName: [this.car.documentsDetails.contactDetails?.firstName],
    lastName: [this.car.documentsDetails.contactDetails?.lastName],
    email: [this.car.documentsDetails.contactDetails?.email],
    phone: [this.car.documentsDetails.contactDetails?.phone],
  });

  constructor(public dialogRef: MatDialogRef<OrderProcessCarDocumentsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderProcessCarDocumentsModalData,
    private coreService: CoreService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    if (this.finishedOrder) {
      this.documentsDeliveryForm.disable();
    }
  }

  close() {
    this.dialogRef.close();
  }

  update() {
    let documentsDetails = {
      country: this.documentsDeliveryForm.controls.country.value,
      city: this.documentsDeliveryForm.controls.city.value!,
      address: this.documentsDeliveryForm.controls.address.value!,
      postalCode: this.documentsDeliveryForm.controls.zipcode.value!,
      email: this.documentsDeliveryForm.controls.email.value!,
      phone: this.documentsDeliveryForm.controls.phone.value!,
      firstName: this.documentsDeliveryForm.controls.firstName.value!,
      lastName: this.documentsDeliveryForm.controls.lastName.value!,
    };

    this.dialogRef.close(documentsDetails);
  }
}
