import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-toggle',
  templateUrl: './app-toggle.component.html',
  styleUrls: ['./app-toggle.component.scss']
})
export class AppToggleComponent implements OnInit {
  @Input() toggleFormControl: FormControl = new FormControl();
  @Input() toggleLabel: string = "";
  @Input() toggleDisabled: boolean = false;
  @Input() id = 'id';
  @Input() labelPosition: 'before' | 'after' = "before";

  @Output() toggleEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  emitToggle(event: MatSlideToggleChange) {
    this.toggleEmitter.emit(event.checked);
  }
}
