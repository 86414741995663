import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BuyerStore, BuyerStoreClass } from 'src/app/core/models/buyer.model';
import { BuyerSyncService } from 'src/app/core/services/buyer-flow-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import * as uuid from 'uuid';

@Component({
  selector: 'app-buyer-stores',
  templateUrl: './buyer-stores.component.html',
  styleUrls: ['./buyer-stores.component.scss']
})
export class BuyerStoresComponent implements OnInit {
  public subscriptions: Subscription = new Subscription();

  createMode: boolean = false;
  buyerId = this.buyerSyncService.buyerId;

  stores: Partial<BuyerStore>[] = JSON.parse(JSON.stringify(this.buyerSyncService.stores));
  storesDropdown: DropdownOption[] = this.buyerSyncService.stores.map(s => ({ value: s.id ? s.id : s.tempId!, viewValue: s.name! }));
  public disableSave: boolean = false;

  dateCreated = new Date();
  statusControl = new FormControl({ value: false, disabled: true });

  companyCountry = this.coreService.countries.find(c => c.id === this.buyerSyncService.buyer!.countryDetails.countryId)!;

  public makes: DropdownOption[] = this.buyerSyncService.makes;
  public contacts: string[] = [];
  notes = '';

  public statusRadio = [
    {
      value: 'admin',
      viewValue: 'Admin'
    },
    {
      value: 'userUpdated',
      viewValue: 'User updated'
    },
  ];

  public storeToggleControl: FormControl = new FormControl();

  public storeForm = this.fb.group({
    storeName: ['', Validators.required],
    tempId: '',
    postalCode: ['', Validators.required],
    address: ['', Validators.required],
    city: ['', Validators.required],
    carSalesEst: [0, Validators.required],
    make: [[''], Validators.required],
    countryBank: [false]
  })

  constructor(
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private buyerSyncService: BuyerSyncService,
    private coreService: CoreService
  ) { }

  async ngOnInit() {
    this.subscriptions.add(this.buyerSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.storeForm.invalid) {
          let goNext = await this.buyerSyncService.showUnsavedChangesModal();

          goNext ? this.buyerSyncService.setCurrentTab(value) : 0;
        } else {
          this.buyerSyncService.setCurrentTab(value);
        }
      }
    ));

    this.subscriptions.add(this.buyerSyncService.saveFormEvent$.subscribe(
      (value) => {
        if (this.storeForm.invalid) {
          this.storeForm.markAllAsTouched();
          this.snackbar.negativeSentiment('You need to complete all required fields');
          return;
        }

        this.saveStore();
        this.buyerSyncService.setCurrentTab('users');
      }
    ));

    if (this.stores.length > 0) {
      this.storeToggleControl.setValue(this.stores[0].id ? this.stores[0].id : this.stores[0].tempId);

      this.patchForm(this.stores[0]);
    } else {
      this.addNewStore();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveStore() {
    if (this.storeForm.invalid) {
      this.storeForm.markAllAsTouched();
      this.snackbar.negativeSentiment('You need to complete all required fields');
      return;
    }

    let store = new BuyerStoreClass(this.storeForm.value, this.companyCountry.id, this.notes);

    if (this.stores.find(s => s.tempId === store.tempId)) {
      this.stores[this.stores.findIndex(s => s.tempId === store.tempId)] = store;
    } else {
      store.tempId = uuid.v4();
      this.stores.push(store);
    }

    this.buyerSyncService.stores = [];

    this.stores.forEach(s => this.buyerSyncService.stores.push(this.coreService.partialToFull(s)));

    this.storesDropdown = this.buyerSyncService.stores.map(s => ({ value: s.tempId!, viewValue: s.name! }));
    this.createMode = false;

    this.storeToggleControl.setValue(store.tempId);

    this.snackbar.positiveSentiment('Store saved!');
  }

  addNewStore() {
    if (this.createMode) {
      this.snackbar.negativeSentiment("You already started to create a new store");
      return;
    }

    const tempId = uuid.v4();

    this.stores.push({ tempId: tempId, name: 'New Store' });
    this.storesDropdown.push({ value: tempId, viewValue: 'New Store' });
    this.storeToggleControl.setValue(tempId);
    this.storeForm.reset({
      countryBank: false,
      tempId: tempId
    });
    this.notes = '';
    this.createMode = true;
  }

  changeStore(storeId: string) {
    if (this.createMode) {
      const currentStoreId = this.storeToggleControl.value;

      const dialogRef = this.dialog.open(
        UnsavedChangesModalComponent, {
        width: '550px',
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.stores.splice(this.stores.findIndex(s => s.tempId === currentStoreId), 1);

          let store = this.stores.find(s => s.id === storeId || s.tempId === storeId)!;
          this.storesDropdown = this.stores.map(s => ({ value: s.id ? s.id : s.tempId!, viewValue: s.name! }));
          this.createMode = false;

          this.patchForm(store);
        } else {
          this.storeToggleControl.setValue(currentStoreId);
        }
      });
    } else {
      let store = this.stores.find(s => s.id === storeId || s.tempId === storeId)!;

      this.patchForm(store);
    }
  }

  patchForm(store: Partial<BuyerStore>) {
    this.storeForm.patchValue({
      storeName: store.name,
      address: store.address,
      city: store.city,
      postalCode: store.postalCode,
      carSalesEst: store.carSalesEst,
      make: store.makes
    });

    this.notes = store.note!;
    this.statusControl.setValue(store.status!);

    if (store.tempId) this.storeForm.controls.tempId.setValue(store.tempId);
  }

  copyData() {
    if (this.buyerSyncService.buyer) {
      let buyer = this.buyerSyncService.buyer!;
      this.storeForm.patchValue({
        storeName: buyer.name,
        address: buyer.address,
        city: buyer.city,
        postalCode: buyer.postalCode
      })
    } else {
      this.snackbar.negativeSentiment('No company saved!');
    }
  }
}
