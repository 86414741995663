import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-placeholder',
  template: `
    <div class="flex flex-col gap-2 col-span-2">
      <div class="bg-white-100 h-14"></div>
      <div class="placeholder-animation h-14" *ngFor="let i of [].constructor(5)"></div>
    </div>
  `,
})
export class TablePlaceholderComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
