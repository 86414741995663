import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { timeout } from 'rxjs';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AddCountryHolidayModalComponent } from './add-country-holiday-modal/add-country-holiday-modal.component';

export interface CountryHoliday {
  id?: string,
  country: string,
  date: Date
}

export enum MonthList {
  January = 0,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December
}

@Component({
  selector: 'app-country-holiday',
  templateUrl: './country-holiday.component.html',
  styleUrls: ['./country-holiday.component.scss']
})

export class CountryHolidayComponent implements OnInit {
  monthList = MonthList;
  monthOption: DropdownOption[] = []

  monthNo: number = 0;
  year: number = 0;
  days: { day: number, isToday: boolean, isHoliday: boolean }[] = [];

  holidays: CountryHoliday[] = [];

  firstDayNumber: number = 0;

  countries: DropdownOption[] = this.coreService.countries.map(c => ({ value: c.id, viewValue: c.name }));
  countryControl = new FormControl();

  showCalendar: boolean = true;

  constructor(private settingsService: SettingsService,
    private coreService: CoreService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private spinnerService: SpinnerHandlerService) { }

  async ngOnInit() {
    let today = new Date();

    this.monthNo = today.getMonth();
    this.year = today.getFullYear();

    let keys = Object.keys(this.monthList).filter((v) => !isNaN(Number(v)));
    this.monthOption = keys.map((k) => ({ value: k, viewValue: this.monthList[Number.parseInt(k)] }));

    if (this.countries.length > 0) {
      this.countryControl.setValue(this.countries[0].value);

      this.loadCountryHolidays(this.countries[0].value);
    }
  }

  nextMonth() {
    this.monthNo++;

    if (this.monthNo === 12) {
      this.monthNo = 0;
      this.year++;
    }

    this.setDateData();
  }

  previousMonth() {
    this.monthNo--;

    if (this.monthNo < 0) {
      this.monthNo = 11;
      this.year--;
    }

    this.setDateData();
  }

  setDateData() {
    let holidays = this.holidays.filter(r => (new Date(r.date)).getMonth() === this.monthNo).map(r => (new Date(r.date).getDate()));

    let noDays = new Date(this.year, this.monthNo + 1, 0).getDate();
    this.firstDayNumber = new Date(this.year, this.monthNo, 1).getDay();

    this.days = [];

    for (let i = 1; i < noDays + 1; i++) {
      this.days.push({
        day: i,
        isToday: this.isToday(i),
        isHoliday: holidays.includes(i)
      })
    }

    if (this.showCalendar && this.holidays.length > 0) {
      setTimeout(() => {
        document.getElementById('1')!.style.gridColumnStart = (this.firstDayNumber + 1).toString();
      })
    }

    this.spinnerService.showProgressBar.next(false);
  }

  isToday(day: number): boolean {
    let today = new Date();

    return today.getFullYear() === this.year && today.getMonth() === this.monthNo && today.getDate() === day;
  }

  loadCountryHolidays(countryId: string) {
    this.spinnerService.showProgressBar.next(true);

    this.settingsService.getCountryHolidays(countryId).subscribe((resp) => {
      this.holidays = resp;

      this.setDateData();
    })
  }

  changeMonth(month: string) {
    this.monthNo = Number.parseInt(month);

    this.setDateData();
  }

  openAddCountryHolidayModal() {
    if (!this.countryControl.value) {
      this.snackbar.negativeSentiment('No country selected!');
      return;
    }

    const dialogRef = this.dialog.open(
      AddCountryHolidayModalComponent, {
      width: '800px',
      autoFocus: false,
      data: {
        countries: this.countries,
        countrySelected: this.countryControl.value,
        months: this.monthOption,
        holidays: this.holidays
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (this.countryControl.value) {
        this.loadCountryHolidays(this.countryControl.value);
      }
    })
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar;

    if (this.showCalendar) {
      setTimeout(() => {
        document.getElementById('1')!.style.gridColumnStart = (this.firstDayNumber + 1).toString();
      })
    }
  }

  deleteHoliday(holidayId: string) {
    this.settingsService.deleteCountryHoliday(holidayId).subscribe(resp => {
      this.snackbar.positiveSentiment("Holiday removed!");

      if (this.countryControl.value) this.loadCountryHolidays(this.countryControl.value);
    });
  }
}
