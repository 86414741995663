<app-main-content-layout>
  <div class="title">
    <p>Payment & Bank settings</p>
  </div>

  <div class="grid grid-cols-2 gap-6">
    <div class="flex flex-col gap-6 pr-6 border-r border-white-500">
      <app-main-content-row-layout>
        <p>Estimated payment date</p>

        <app-read-only-input class="secondContent">12/02/2023</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <mat-checkbox [disabled]="finishedOrder" id="buyer-paid-checkbox" data-cy="buyer-paid-checkbox"
          [(ngModel)]="buyerPaid" (change)="toggleBuyerPaid($event)">Buyer paid</mat-checkbox>

        <app-datepicker id="buyer-paid" class="secondContent" [control]="buyerPaidControl"></app-datepicker>
      </app-main-content-row-layout>

      <div class="flex justify-end">
        <primary-btn [disableBtn]="buyerPaidControl.disabled || !buyerPaidControl.value" id="confirm-buyer-payment"
          *ngIf="!finishedOrder" (clickEmitter)="confirmBuyerPayment()">Confirm buyer
          payment</primary-btn>
      </div>

      <div class="flex w-full pt-9 border-t border-white-500">
        <app-dropdown id="sellers" class="w-fit" label="Sellers" [options]="sellersDropdown" [control]="sellerControl"
          (emitOnChangeSelect)="changeSeller($event)"></app-dropdown>
      </div>

      <app-main-content-row-layout>
        <p>Bank giro/IBAN*</p>

        <app-input id="iban" [control]="ibanControl" placeholder="Insert IBAN" class="secondContent"></app-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>OCR*</p>

        <app-input id="ocr" [control]="ocrControl" placeholder="Insert OCR" class="secondContent"></app-input>
      </app-main-content-row-layout>

      <div class="flex justify-end">
        <app-blue-btn id="save-iban" (clickEmitter)="saveIBANOCR()" *ngIf="!finishedOrder">Save IBAN/OCR</app-blue-btn>
      </div>

      <app-main-content-row-layout>
        <mat-checkbox id="seller-paid-checkbox" data-cy="seller-paid-checkbox" [(ngModel)]="sellerPaid"
          (change)="toggleSellerPaid($event)" [disabled]="finishedOrder">Seller paid</mat-checkbox>

        <app-datepicker id="seller-paid" class="secondContent" [control]="sellerPaidControl"></app-datepicker>
      </app-main-content-row-layout>

      <div class="flex justify-end">
        <primary-btn [disableBtn]="sellerPaidControl.disabled || !sellerPaidControl.value" id="confirm-seller-payment"
          (clickEmitter)="confirmSellerPayment()" *ngIf="!finishedOrder">
          Confirm seller payment
        </primary-btn>
      </div>
    </div>

    <div class="flex flex-col gap-6">
      <app-main-content-row-layout>
        <p>Email sent to seller</p>

        <app-read-only-input class="secondContent">
          <p>{{'dd/MM/yyyy' }}</p>
        </app-read-only-input>
      </app-main-content-row-layout>

      <div class="flex justify-end">
        <app-blue-btn id="send-notification" (clickEmitter)="sendSellerNotification()" [disableBtn]="true">
          Send notification
        </app-blue-btn>
      </div>

      <app-main-content-row-layout>
        <p>Reminder sent to KAM</p>

        <app-read-only-input class="secondContent">
          <p>{{ 'dd/MM/yyyy' }}</p>
        </app-read-only-input>
      </app-main-content-row-layout>

      <div class="flex justify-end">
        <app-blue-btn id="send-reminder" (clickEmitter)="sendKAMReminder()" [disableBtn]="true">Send
          reminder</app-blue-btn>
      </div>

      <!-- <app-dropdown placeholder="Select transport" class="flex pt-6 border-t border-white-500"></app-dropdown>

      <app-main-content-row-layout>
        <mat-checkbox id="buyer-paid-transport-checkbox" data-cy="buyer-paid-transport-checkbox"
          [disabled]="!checkTransportC2C()" (change)="toggleBuyerTransportPaid($event)">Buyer paid
          transportation</mat-checkbox>

        <app-datepicker id="buyer-transport-paid" class="secondContent"
          [control]="buyerTransportPaidControl"></app-datepicker>
      </app-main-content-row-layout>

      <div class="flex justify-end">
        <app-blue-btn [disableBtn]="true" id="confirm-buyer-transport-payment">
          Confirm transport payment
        </app-blue-btn>
      </div> -->
    </div>
  </div>
</app-main-content-layout>
