<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 pr-6">
  <div>
    <p class="text-2xl">Manually add cars</p>
  </div>

  <div class="flex justify-between">
    <div class="grid grid-cols-3 w-3/4 gap-9">
      <app-input id="regNo" [control]="regNoControl" [label]="'Registration number'"
        placeholder="Search by registration number"></app-input>

      <app-dropdown id="make" [control]="makeControl" [options]="makes" label="Make" placeholder="Select make"
        (emitOnChangeSelect)="selectMake($event)"></app-dropdown>

      <app-dropdown id="model" [control]="modelControl" [options]="models" label="Model"
        placeholder="Select model" (emitOnChangeSelect)="selectModel($event)"></app-dropdown>

      <app-input id="vin" [control]="vinControl" label="VIN" placeholder="Search by VIN"></app-input>
      <app-input id="id" [control]="idControl" label="ID" placeholder="Search by ID"></app-input>
    </div>

    <div class="flex gap-6 pr-4">
      <secondary-btn id="reset-filters" (clickEmitter)="resetFilters()">Reset filters</secondary-btn>
      <primary-btn id="add-cars-modal" [blueBtn]="true" (clickEmitter)="addCars()">Add</primary-btn>
    </div>

  </div>

  <app-table #carsTable [displayedColumns]="displayColumn" [headersMap]="headers" [data]="cars"
    *ngIf="!(loading | async);else placeholder"></app-table>
</div>

<ng-template #placeholder>
  <div class="flex flex-col gap-2">
    <div class="h-9 w-full bg-white-100"></div>

    <div class="h-9 w-full placeholder-animation" *ngFor="let i of [].constructor(5)"></div>
  </div>
</ng-template>
