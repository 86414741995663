<div class="flex justify-between mb-6 items-center" *ngIf="!carsUploaded">
  <app-white-btn (clickEmitter)="removeCars()">Remove cars</app-white-btn>

  <div class="flex gap-6 items-center">
    <app-toggle toggleLabel="Show only cars with issues" (toggleEmitter)="filterCars($event)"></app-toggle>

    <fa-icon matTooltip="Cars selected must have same make, model, gearbox, fueltype, engine power"
      matTooltipPosition="above" [icon]="['fal', 'circle-info']"></fa-icon>

    <secondary-btn btnText="Modify selected" (clickEmitter)="editMultipleCars()"></secondary-btn>

    <primary-btn [blueBtn]="true" btnText="Continue" (clickEmitter)="continue()"></primary-btn>
  </div>
</div>

<div class="overflow-x-auto">
  <table mat-table matSort [dataSource]="dataSource"
    class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">

    <ng-container matColumnDef="selectAll">
      <th mat-header-cell *matHeaderCellDef>
        <div class="flex gap-3">
          <mat-checkbox (change)="selectAll($event)" #selectAllCheckbox></mat-checkbox>
          <p>SELECT ALL</p>
        </div>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-checkbox [id]="'select'+ (i + 1) +'-checkbox'" [attr.data-cy]="'select'+ (i + 1) +'-checkbox'"
          [(ngModel)]="element.isSelected"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="regNo" sticky>
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        reg Number
      </th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <input class="px-3 py-2 border border-white-500" [(ngModel)]="element['regNo']" [disabled]="carsUploaded"
          [ngClass]="{'input-disabled': carsUploaded}">
      </td>
    </ng-container>

    <ng-container matColumnDef="vin" sticky>
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        vin
      </th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <input class="px-3 py-2 border border-white-500" [(ngModel)]="element['vin']" [disabled]="carsUploaded"
          [ngClass]="{'input-disabled': carsUploaded}">
      </td>
    </ng-container>

    <ng-container [matColumnDef]="col.value" *ngFor="let col of tableMapFirstCols" sticky>
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        {{col.tableView}}</th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <p class="!border-r !border-white-500 !pr-6">
          {{element[col.value]}}
        </p>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="col.value" *ngFor="let col of tableMap">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        {{col.tableView}}</th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">{{element[col.value]}}
      </td>
    </ng-container>

    <ng-container matColumnDef="co2NEDC">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        co2 NEDC
      </th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <app-input-number-ngmodel [(inputValue)]="element['co2NEDC']"
          [disabled]="carsUploaded"></app-input-number-ngmodel>
      </td>
    </ng-container>

    <ng-container matColumnDef="co2WLTP">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        co2 WLTP
      </th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <app-input-number-ngmodel [(inputValue)]="element['co2WLTP']"
          [disabled]="carsUploaded"></app-input-number-ngmodel>
      </td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        color
      </th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <app-dropdown-ngmodel [(dropdownValue)]="element['color']" [options]="colors"
          [disabled]="carsUploaded"></app-dropdown-ngmodel>
      </td>
    </ng-container>

    <ng-container matColumnDef="doors">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        doors
      </th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <app-input-number-ngmodel [(inputValue)]="element['doors']"
          [disabled]="carsUploaded"></app-input-number-ngmodel>
      </td>
    </ng-container>

    <ng-container matColumnDef="seats">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        seats
      </th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <app-input-number-ngmodel [(inputValue)]="element['seats']"
          [disabled]="carsUploaded"></app-input-number-ngmodel>
      </td>
    </ng-container>

    <ng-container matColumnDef="drivingWheels">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        drivingWheels
      </th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <app-dropdown-ngmodel [(dropdownValue)]="element['drivingWheels']" [options]="drivingWheels"
          [disabled]="carsUploaded"></app-dropdown-ngmodel>
      </td>
    </ng-container>

    <ng-container matColumnDef="mileage">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        mileage
      </th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <app-input-number-ngmodel [(inputValue)]="element['mileage']"
          [disabled]="carsUploaded"></app-input-number-ngmodel>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="!px-4">
        EDIT
      </th>
      <td class="!p-6" mat-cell *matCellDef="let element;let i = index">
        <fa-icon [id]="'edit-' + (i + 1) + 'btn'" [attr.data-cy]="'edit-' + (i + 1) + '-btn'" class="cursor-pointer"
          [icon]="['fal','pen-to-square']" (click)="editCar(element)"></fa-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:bg-white-400"
      [ngClass]="{'partially':row.identifyStatus === 'Partially identified','unidentified': row.identifyStatus === 'Unidentified' || row.identifyStatus === 'Invalid data','bg-white-500':row.isSelected}">
    </tr>
  </table>

  <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons (page)="changePageEvent()">
  </mat-paginator>
</div>
