<div class="flex flex-col gap-6">
  <app-main-content-layout>
    <div class="flex justify-between">
      <div class="flex gap-3 items-center">
        <p class="text-white-800 pr-3 border-r border-white-500">VIN</p>

        <p class="pr-3 border-r border-white-500">{{offer!.carDetails.vin}}</p>

        <p class="text-white-800 pr-3 border-r border-white-500">Registration number</p>

        <p>{{offer!.carDetails.regNo}}</p>

        <app-blue-btn *ngIf="offer!.carDetails.vin.length > 0" class="ml-10" id="decode-vin"
          (clickEmitter)="decodeVin()">Decode VIN</app-blue-btn>
      </div>

      <secondary-btn *ngIf="vinDecoded">Resolve conflicts</secondary-btn>
    </div>
  </app-main-content-layout>

  <app-main-content-layout>
    <div class="grid grid-cols-4 gap-6">
      <p class="col-start-2">Seller input</p>

      <p>Decoded output</p>

      <!-- make -->
      <p class="col-start-1 self-center">Make</p>

      <app-read-only-input>{{offer!.carDetails.make}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>

      <!-- model -->
      <p class="col-start-1 self-center">Model</p>

      <app-read-only-input>{{offer!.carDetails.model}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>

      <!-- fuelType -->
      <p class="col-start-1 self-center">Fueltype</p>

      <app-read-only-input>{{offer!.carDetails.fuelType}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>

      <!-- gearbox -->
      <p class="col-start-1 self-center">Gearbox</p>

      <app-read-only-input>{{offer!.carDetails.gearbox}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>

      <!-- variant -->
      <p class="col-start-1 self-center">Variant</p>

      <app-read-only-input>{{offer!.carDetails.variant}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>

      <!-- trim -->
      <p class="col-start-1 self-center">Trim</p>

      <app-read-only-input>{{offer!.carDetails.trim}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>


      <div class="col-start-1 col-span-4 border-b border-white-500"></div>

      <!-- bodytype -->
      <p class="col-start-1 self-center">Bodytype</p>

      <app-read-only-input>{{offer!.carDetails.bodyType ? offer!.carDetails.bodyType : 'None'}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>

      <!-- driving wheels -->
      <p class="col-start-1 self-center">Driving wheels</p>

      <app-read-only-input>{{offer!.carDetails.drivingWheels ? offer!.carDetails.drivingWheels :
        'None'}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>

      <!-- engine power -->
      <p class="col-start-1 self-center">Engine power</p>

      <app-read-only-input>{{offer!.carDetails.enginePower ? (offer!.carDetails.enginePower | number) :
        'None'}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>

      <!-- engine volume -->
      <p class="col-start-1 self-center">Engine volume</p>

      <app-read-only-input>{{offer!.carDetails.engineVolume ? (offer!.carDetails.engineVolume | number) :
        'None'}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>

      <!-- co2 nedc -->
      <p class="col-start-1 self-center">CO2 NEDC</p>

      <app-read-only-input>{{offer!.carDetails.CO2NEDC ? (offer!.carDetails.CO2NEDC | number) :
        'None'}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>

      <!-- co2 wltp -->
      <p class="col-start-1 self-center">CO2WLTP</p>

      <app-read-only-input>{{offer!.carDetails.CO2WLTP ? (offer!.carDetails.CO2WLTP | number) :
        'None'}}</app-read-only-input>

      <app-read-only-input>BMW</app-read-only-input>
    </div>
  </app-main-content-layout>
</div>
