import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateOfferSettingsComponent } from './create-offer-settings/create-offer-settings.component';
import { CreateOfferCarsComponent } from './create-offer-cars/create-offer-cars.component';
import { BehaviorSubject } from 'rxjs';
import { OffersService } from 'src/app/core/services/offers.service';
import { BuyerService } from 'src/app/core/services/buyer.service';
import { BuyerResponse } from 'src/app/core/models/buyer.model';

@Component({
  selector: 'app-create-offer',
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.scss'],
  providers: [OffersService]
})

export class CreateOfferComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  buyers: BuyerResponse[] = [];

  routes = [
    {
      viewValue: "Offer settings",
      value: "settings",
    },
    {
      viewValue: "Select cars",
      value: "cars",
      disabled: new BehaviorSubject<boolean>(true)
    }
  ]

  page = 'settings';


  @ViewChild('offerSettings') offerSettings: CreateOfferSettingsComponent | undefined;
  @ViewChild('offerCars') offerCars: CreateOfferCarsComponent | undefined;

  constructor(private buyerService: BuyerService) { }

  ngOnInit(): void {
    this.buyerService.getBuyersAndUsers().subscribe(resp => {
      this.buyers = resp;

      this.loading.next(false);
    });
  }

  changePage(page: string) {
    this.page = page;
  }

  continue() {
    if (this.offerSettings?.save()) {
      this.routes[1].disabled?.next(false);
      this.page = 'cars';
    }
  }

  createOffers() {
    this.offerCars?.createOffers();
  }
}
