import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-read-only-input',
  template: `
    <div class="w-full bg-white-500 border border-white-500 rounded p-[0.625rem] relative">
        <p class="absolute z-10 text-white-300 text-xs -top-5 left-0" *ngIf="label">{{label}}</p>
        <ng-content></ng-content>
    </div>
  `,
})

export class ReadOnlyInputComponent implements OnInit {
  @Input() label: string | undefined;

  constructor() { }

  ngOnInit() { }
}
