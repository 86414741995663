import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CarBusinessCase, CarDetails } from 'src/app/core/models/car.model';
import { VATType } from 'src/app/core/models/info.model';
import { OfferStatus } from 'src/app/core/models/offer.model';
import { OrderCar } from 'src/app/core/models/order.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';


interface CreateOfferCarsOfferPriceModalData {
  car: CarDetails,
  latestOffer: number,
  bc: CarBusinessCase,
  buyerCountry: string,
  expirationDate: string,
  orderStatus: string
}

@Component({
  selector: 'app-create-offer-cars-offer-price-modal',
  templateUrl: './create-offer-cars-offer-price-modal.component.html',
  styleUrls: ['./create-offer-cars-offer-price-modal.component.scss']
})
export class CreateOfferCarsOfferPriceModalComponent implements OnInit {
  car = this.data.car;

  latestOffer = this.data.latestOffer;
  expiresOn = this.data.expirationDate;

  priceControl = new FormControl(null, Validators.required);
  newExpiratonDateControl = new FormControl();

  bc = this.data.bc;

  buyerCountry = this.data.buyerCountry;

  vatStatus = VATType;

  today = new Date();

  isExpiredOffer = this.data.orderStatus && this.data.orderStatus === OfferStatus.Expired;

  constructor(public dialogRef: MatDialogRef<CreateOfferCarsOfferPriceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateOfferCarsOfferPriceModalData,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
  }

  setPrice() {
    if (this.priceControl.invalid) {
      this.snackbar.negativeSentiment('No offer value');
      return;
    }

    if (this.priceControl.value! < this.car.minSellingPrice) {
      this.snackbar.negativeSentiment('Offer must be higher than minimum selling price');
      return;
    }

    if (new Date(this.expiresOn) < this.today && !this.newExpiratonDateControl.value) {
      this.snackbar.negativeSentiment('New expiration date required');
      return;
    }

    this.dialogRef.close({
      value: this.priceControl.value,
      expiresOn: this.newExpiratonDateControl.value
    });
  }

  isNaN(value: number) {
    return isNaN(value);
  }
}
