<div class="flex flex-col gap-4 w-full" *ngIf="!isMobile; else mobile">
  <div class="w-full h-96 border-2 border-white-200 rounded">
    <img class="h-full w-full object-contain cursor-pointer" [src]="selectedImage" alt="" (click)="openFullScreenPhoto()">
  </div>

  <carousel [arrows]="true" [height]="150" class="!z-0">
    <div *ngFor="let image of images" class="carousel-cell w-32 h-32">
      <img class="w-full h-full object-contain" [src]="image" alt="" (click)="selectImage(image)">
    </div>
  </carousel>
</div>

<ng-template #mobile>
  <carousel [arrows]="false" [dots]="true" [cellWidth]="'100%'" [height]="300" class="!z-0 image-slider">
    <div *ngFor="let image of images" class="carousel-cell w-32 h-32">
      <img class="w-full h-full object-contain" [src]="image" alt="" (click)="selectImage(image)">
    </div>
  </carousel>
</ng-template>
