import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CarsVINCheck, VINCheckDetails } from 'src/app/core/models/car.model';
import { ApproveCarBody, Order, OrderCar } from 'src/app/core/models/order.model';
import { CarService } from 'src/app/core/services/car.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderSeeCarDetailsModalComponent } from '../order-shared-components/order-see-car-details-modal/order-see-car-details-modal.component';
import { InfoService } from 'src/app/core/services/infos.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { FormControl, Validators } from '@angular/forms';

interface CarOrderProcess extends OrderCar {
  carC2CStatus: string
}

@Component({
  selector: 'app-order-pending-process',
  templateUrl: './order-pending-process.component.html',
  styleUrls: ['./order-pending-process.component.scss']
})

export class OrderPendingProcessComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  order: Order | undefined;
  cars: CarOrderProcess[] = [];

  headers: TableHeaderMap[] = [
    {
      value: 'carC2CStatus',
      tableView: 'C2C Review'
    },
    {
      value: 'clientValidated',
      tableView: 'Client validated'
    },
    {
      value: 'sellerName',
      tableView: 'seller'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'showRoomReady',
      tableView: 'showroom Ready'
    },
    {
      value: 'actualBuyingPrice',
      tableView: 'actual Buying Price'
    },
    {
      value: 'actualBuyingPriceEuro',
      tableView: 'actual Buying Price euro'
    },
    {
      value: 'offeredPrice',
      tableView: 'offered Price'
    },
    {
      value: 'buyingPrice',
      tableView: 'Buying price'
    },
    {
      value: 'minSellingPrice',
      tableView: 'minimum selling price'
    },
    {
      value: 'sellingPrice',
      tableView: 'Online Selling price'
    },
    {
      value: 'invoiceSellingPrice',
      tableView: 'invoice selling price'
    },
    {
      value: 'actualProfit',
      tableView: 'profit'
    },
    {
      value: 'documentsDetails.cocDocuments.required',
      tableView: 'requires COC'
    },
    {
      value: 'documentsDetails.cocDocuments.cocValue',
      tableView: 'coc value'
    },
    {
      value: 'damages',
      tableView: 'Initial declared damages'
    },
    {
      value: 'finalDamages',
      tableView: 'final damage'
    },
    {
      value: 'transportationFee',
      tableView: 'Transportation fee'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'regNo',
      tableView: 'Registration number'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'firstReg',
      tableView: 'first registration'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'color',
      tableView: 'color'
    },
  ];

  carsVINDetails: VINCheckDetails[] = [];

  displayedColumns = this.headers.map(h => h.value).concat(['details']);

  vinCountries: DropdownOption[] = [];

  countryControl = new FormControl('', Validators.required);

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;

  constructor(private orderService: OrdersService,
    private activatedRoute: ActivatedRoute,
    private carService: CarService,
    private snackbar: SnackbarService,
    private spinnerService: SpinnerHandlerService,
    private infoService: InfoService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params['orderId']) {
        forkJoin({
          order: this.orderService.getOrder(params['orderId']),
          // vinCountries: this.carService.getVINCountries(),
          countries: this.infoService.getCountries()
        }).subscribe(resp => {
          this.order = resp.order;

          this.cars = resp.order.sellers!.reduce((arr: CarOrderProcess[], seller) => {
            // arr = arr.concat(seller.cars!.map(c => (
            //   {
            //     ...c,
            //     sellerName: seller.details!.name,
            //     sellerId: seller.details!.sellerId,
            //     carC2CStatus: c.c2cValidated ? 'Accepted' : ''
            //   })));

            return arr;
          }, []);

          if (this.order.removedCars && this.order.removedCars.length > 0) {
            this.cars = this.cars.concat(this.order.removedCars.map(c => ({
              ...c,
              carC2CStatus: 'Rejected'
            })));
          }

          // this.vinCountries = resp.vinCountries.filter(c => resp.countries.find(co => c.iso === co.iso)).map(c => ({ viewValue: c.name, value: resp.countries.find(co => co.iso === c.iso)!.id }));

          this.loading.next(false);
        })
      }
    });
  }

  vinCheck() {
    if (this.countryControl.invalid) {
      this.snackbar.negativeSentiment('Select a country for VIN check');

      return;
    }

    let vinCheckBody: CarsVINCheck = {
      vins: this.cars.filter(c => c.carConfig.vin).map(c => ({
        carMainInfoId: c.carMainInfoId!,
        locationId: this.countryControl.value!,
        vin: c.carConfig.vin!
      }))
    }

    this.spinnerService.showProgressBar.next(true);

    this.carService.vinCheck(vinCheckBody).subscribe({
      next: resp => {
        this.order!.vinBatch.batchId = resp.batchId;

        this.orderService.updateOrderBatchID(this.order!.orderId!, resp.batchId).subscribe({
          next: val => {
            this.spinnerService.showProgressBar.next(false);

            this.snackbar.positiveSentiment('VIN check process has started');
          },
          error: err => {
            this.snackbar.negativeSentiment(err.error);

            this.spinnerService.showProgressBar.next(false);
          }
        })
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinnerService.showProgressBar.next(false);
      }
    })
  }

  openDetailsModal(car: CarOrderProcess) {
    if (this.spinnerService.showProgressBar.value) return;

    let vinDetails = this.carsVINDetails.find(v => v.carMainInfoId === car.carMainInfoId);

    const dialogRef = this.dialog.open(
      OrderSeeCarDetailsModalComponent, {
      width: '1200px',
      autoFocus: false,
      data: {
        car: car,
        vinDetails: vinDetails,
        showValidateButtons: car.carC2CStatus.length === 0
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp && resp.accept) {
        // let body: ApproveCarBody = {
        //   orderId: this.order!.orderId!,
        //   carMainInfoId: car.carMainInfoId!,
        //   sellerId: car.sellerId!
        // };

        this.spinnerService.showProgressBar.next(true);

        // this.orderService.approveProposedCar(body).subscribe({
        //   next: value => {
        //     this.spinnerService.showProgressBar.next(false);

        //     let index = this.cars.findIndex(c => c.carMainInfoId === car.carMainInfoId);

        //     // this.cars[index].c2cValidated = true;

        //     this.cars[index].carC2CStatus = 'Accepted';

        //     this.carsTable?.updateTable(this.cars);

        //     this.snackbar.positiveSentiment('Car accepted');
        //   },
        //   error: err => {
        //     this.spinnerService.showProgressBar.next(false);

        //     this.snackbar.negativeSentiment(err.error);
        //   }
        // });
      } else if (resp) {
        // let body: ApproveCarBody = {
        //   orderId: this.order!.orderId!,
        //   carMainInfoId: car.carMainInfoId!,
        //   sellerId: car.sellerId!
        // };

        // this.spinnerService.showProgressBar.next(true);

        // this.orderService.rejectProposedCar(body).subscribe({
        //   next: value => {
        //     let index = this.cars.findIndex(c => c.carMainInfoId === car.carMainInfoId);

        //     this.cars[index].carC2CStatus = 'Rejected';

        //     this.carsTable?.updateTable(this.cars);

        //     this.spinnerService.showProgressBar.next(false);

        //     this.snackbar.positiveSentiment('Car rejected');
        //   },
        //   error: err => {
        //     this.spinnerService.showProgressBar.next(false);

        //     this.snackbar.negativeSentiment(err.error);
        //   }
        // });
      }
    })
  }

  getVINDetails() {
    this.spinnerService.showProgressBar.next(true);

    this.carService.getVINDetails(this.order!.vinBatch.batchId).subscribe({
      next: resp => {
        if (typeof (resp) === 'string') {
          this.snackbar.negativeSentiment('VIN still decoding');
        } else {
          this.carsVINDetails = resp;

          this.snackbar.positiveSentiment('VIN details retrieved');
        }

        this.spinnerService.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinnerService.showProgressBar.next(false);
      }
    });
  }
}
