<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <div class="flex justify-between items-center">
    <p class="text-2xl">Edit pickup location, contact and car ready at date</p>

    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close()"></fa-icon>
  </div>

  <div class="grid grid-cols-2 gap-6">
    <app-toggle [toggleLabel]="'Ready for pick-up'" [toggleFormControl]="form.controls.carReady"
      (toggleEmitter)="enableDatePicker($event)"></app-toggle>

    <app-datepicker [label]="'Car ready at'" [control]="form.controls.carReadyAt"></app-datepicker>

    <p>Country</p>

    <app-read-only-input>{{car.pickupDetails.location}}</app-read-only-input>

    <p>City</p>

    <app-input id="pickup-city" placeholder="Insert city" [control]="form.controls.city"></app-input>

    <p>Pickup Address</p>

    <app-input id="pickup-address" placeholder="Insert address" [control]="form.controls.address"></app-input>

    <p>Zipcode</p>

    <app-input id="pickup-postalcode" placeholder="Insert zipcode" [control]="form.controls.zipcode"></app-input>

    <p>Contact person first name</p>

    <app-input id="pickup-contact-firstname" placeholder="Insert contact name"
      [control]="form.controls.firstName"></app-input>

    <p>Contact person last name</p>

    <app-input id="pickup-contact-lastname" placeholder="Insert contact name"
      [control]="form.controls.lastName"></app-input>

    <p>Email</p>

    <app-input id="pickup-contact-email" placeholder="Insert contact email" [control]="form.controls.email"></app-input>

    <p>Phone</p>

    <app-input id="pickup-contact-phone" placeholder="Insert phone" [control]="form.controls.phone"></app-input>
  </div>

  <div class="flex justify-end" *ngIf="!finishedOrder">
    <primary-btn id="update-pickup-settings" [blueBtn]="true" (clickEmitter)="update()">Update</primary-btn>
  </div>
</div>
