<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <div class="flex justify-between items-center">
    <p class="text-2xl">Edit documents delivery location and contact</p>

    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']" (click)="close()"></fa-icon>
  </div>

  <div class="grid grid-cols-2 gap-6">
    <p>Country</p>

    <app-dropdown id="country" placeholder="Select country" [options]="countries" [control]="form.controls.country"></app-dropdown>

    <p>City</p>

    <app-input id="city" placeholder="Insert city" [control]="form.controls.city"></app-input>

    <p>Delivery Address</p>

    <app-input id="address" placeholder="Insert address" [control]="form.controls.address"></app-input>

    <p>Zipcode</p>

    <app-input id="zipcode" placeholder="Insert zipcode" [control]="form.controls.zipcode"></app-input>

    <p>Contact person first name</p>

    <app-input id="firstname" placeholder="Insert contact first name" [control]="form.controls.firstName"></app-input>

    <p>Contact person last name</p>

    <app-input id="lastname" placeholder="Insert contact last name" [control]="form.controls.lastName"></app-input>

    <p>Email</p>

    <app-input id="email" placeholder="Insert contact email" [control]="form.controls.email"></app-input>

    <p>Phone</p>

    <app-input id="phone" placeholder="Insert phone" [control]="form.controls.phone"></app-input>
  </div>

  <div class="flex justify-end">
    <primary-btn id="update-documents-delivery" [blueBtn]="true" (clickEmitter)="update()">Update</primary-btn>
  </div>
</div>
