import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-main-content-row-input-number',
  template: `
    <app-main-content-row-layout>
      <p [ngClass]="{'text-red font-bold' : control.invalid && control.touched}">{{title}}*</p>

      <app-input-number [id]="componentId" [placeholder]="placeholder" [control]="control" class="secondContent"></app-input-number>
    </app-main-content-row-layout>
  `,
})
export class MainContentRowInputNumberComponent implements OnInit {
  @Input() control = new FormControl();
  @Input() title = '';
  @Input() componentId = '';
  @Input() placeholder = '';


  constructor() { }

  ngOnInit() { }
}
