<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <div class="flex justify-between items-center">
    <p class="text-2xl">{{car.carConfig.make}} {{car.carConfig.model}}</p>

    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close()"></fa-icon>
  </div>

  <div class="twoCols">
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">Address and contact for documents</p>

    <p>Country</p>

    <app-dropdown id="doc-country" placeholder="Select country" [options]="countries"
      [control]="documentsDeliveryForm.controls.country"></app-dropdown>

    <p>City</p>

    <app-input id="doc-city" placeholder="Insert city" [control]="documentsDeliveryForm.controls.city"></app-input>

    <p>Delivery Address</p>

    <app-input id="doc-address" placeholder="Insert address"
      [control]="documentsDeliveryForm.controls.address"></app-input>

    <p>Zipcode</p>

    <app-input id="doc-postalcode" placeholder="Insert zipcode"
      [control]="documentsDeliveryForm.controls.zipcode"></app-input>

    <p>Contact person first name</p>

    <app-input id="doc-contact-firstname" placeholder="Insert contact first name"
      [control]="documentsDeliveryForm.controls.firstName"></app-input>

    <p>Contact person last name</p>

    <app-input id="doc-contact-lastname" placeholder="Insert contact last name"
      [control]="documentsDeliveryForm.controls.lastName"></app-input>

    <p>Email</p>

    <app-input id="doc-contact-email" placeholder="Insert contact email"
      [control]="documentsDeliveryForm.controls.email"></app-input>

    <p>Phone</p>

    <app-input id="doc-contact-phone" placeholder="Insert phone"
      [control]="documentsDeliveryForm.controls.phone"></app-input>
  </div>

  <div class="flex justify-end" *ngIf="!finishedOrder">
    <primary-btn id="update-documents-settings" [blueBtn]="true" (clickEmitter)="update()">Update</primary-btn>
  </div>
</div>
