import { ISellerStorePickup } from "../seller.model";

export class EditSellerCompanyBody {
  companyId: string;
  kamId: string;
  countryId: string;
  typeId: string;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  phone: string;
  email: string;
  vat: string;
  vatStatus: boolean;
  repMakes: string[];
  soldMakes: string[];
  companyWebsite: string;
  status: boolean;

  constructor(sellerForm: any, country: string, vat: string, vatStatus: boolean, companyId: string, status: boolean) {
    this.kamId = sellerForm.kamId;
    this.companyId = companyId;
    this.address = sellerForm.address;
    this.city = sellerForm.city;
    this.countryId = country;
    this.companyWebsite = sellerForm.website;
    this.email = sellerForm.email;
    this.repMakes = sellerForm.makesRep;
    this.soldMakes = sellerForm.makesSold;
    this.name = sellerForm.name
    this.postalCode = sellerForm.postalCode;
    this.phone = sellerForm.phone;
    this.typeId = sellerForm.sellerType;
    this.vat = vat;
    this.vatStatus = vatStatus;
    this.status = status;
  }
}

export class CreateSellerStoreBody {
  companyId: string;
  name: string;
  countryId: string;
  city: string;
  address: string;
  postalCode: string;
  defaultStore: boolean;
  repMakes: string[];
  soldMakes: string[];
  countryBank: boolean;

  constructor(store: any, companyId: string, countryId: string) {
    this.companyId = companyId;
    this.name = store.name;
    this.countryId = countryId;
    this.city = store.city;
    this.address = store.address;
    this.postalCode = store.postalCode;
    this.defaultStore = false;
    this.repMakes = store.repMakes;
    this.soldMakes = store.soldMakes;
    this.countryBank = store.countryBank ? true : false;
  }
}

export class EditSellerStoreBody extends CreateSellerStoreBody {
  id: string;
  status: boolean;

  constructor(sellerForm: any, companyId: string, status: boolean, storeId: string, defaultStore: boolean, countryId: string) {
    super(sellerForm, companyId, countryId);

    this.id = storeId;
    this.countryId = countryId;
    this.status = status ? true : false;
    this.defaultStore = defaultStore;
  }
}

export class CreateSellerUserBody {
  companyId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  countryId: string;
  stores: {
    id: string,
    contactFlag: boolean,
    contactRoleDetails: {
      accEmailFlag: boolean,
      carPickupEmailFlag: boolean,
      documentEmailFlag: boolean,
    }
  }[];

  constructor(sellerId: string, userForm: any, stores: any, email?: string) {
    this.companyId = sellerId;
    this.firstName = userForm.firstName;
    this.lastName = userForm.lastName;
    this.phoneNumber = userForm.phoneNumber ? userForm.phoneNumber : '';
    this.countryId = userForm.countryId;
    this.stores = stores;
    this.email = email!;
  }
}

export class EditSellerUserBody extends CreateSellerUserBody {
  id: string;
  isActive: boolean;

  constructor(sellerId: string, userForm: any, stores: any, id: string, isActive: boolean) {
    super(sellerId, userForm, stores);

    this.id = id;
    this.isActive = isActive;
  }
}

export class CreateSellerContactBody {
  companyId: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountingEmail: boolean;
  carPickupEmail: boolean;
  documentsEmail: boolean;

  constructor(sellerId: string, storeId: string, contactForm: any) {
    this.companyId = sellerId;
    this.id = storeId;
    this.firstName = contactForm.firstName;
    this.lastName = contactForm.lastName;
    this.email = contactForm.email;
    this.phone = contactForm.phone ? contactForm.phone : '';
    this.accountingEmail = contactForm.accountingEmail;
    this.carPickupEmail = contactForm.carPickupEmail;
    this.documentsEmail = contactForm.documentsEmail;
  }
}

export class EditSellerPickupsBody {
  companyId: string;
  id: string;
  pickups: ISellerStorePickup[];

  constructor(companyId: string, id: string, pickups: ISellerStorePickup[]) {
    this.companyId = companyId
    this.id = id
    this.pickups = pickups
  }
}
