<div class="flex flex-col gap-9">
  <div class="flex justify-between">
    <p class="text-2xl font-bold">Create staff user</p>

    <app-blue-btn id="save-user" (clickEmitter)="createUser()">Save user</app-blue-btn>
  </div>

  <app-main-content-layout>
    <div class="twoCols">
      <div class="flex flex-col gap-6">

        <app-main-content-row-input title="First name" placeholder="Insert first name"
          [control]="userForm.controls.firstName" id="first-name"></app-main-content-row-input>

        <app-main-content-row-input title="Last name" class="secondContent" placeholder="Insert last name"
          [control]="userForm.controls.lastName" id="last-name"></app-main-content-row-input>

        <app-main-content-row-input title="Email" class="secondContent" placeholder="Insert email"
          [control]="userForm.controls.email" id="email"></app-main-content-row-input>
      </div>

      <div class="flex flex-col gap-6 pl-6 border-l border-white-500">
        <app-main-content-row-input title="Phone" class="secondContent" placeholder="Insert phone"
          [control]="userForm.controls.phoneNumber" id="phone"></app-main-content-row-input>

        <app-main-content-row-dropdown title="Country" class="secondContent" placeholder="Insert country"
          [control]="userForm.controls.country" id="country" [options]="countries"></app-main-content-row-dropdown>

        <app-main-content-row-layout>
          <p>Roles</p>

          <div class="secondContent">
            <mat-radio-group class="secondContent flex gap-6" aria-label="Select an option"
              [formControl]="userForm.controls.roles">
              <mat-radio-button [value]="'admin'">Admin</mat-radio-button>
              <mat-radio-button [value]="'kam'">KAM</mat-radio-button>
            </mat-radio-group>

            <div *ngIf="userForm.controls.roles.value && userForm.controls.roles.value === 'kam'"
              class="flex gap-6 mt-6">
              <app-checkbox [(checkboxValue)]="kamSeller" componentId="kamSeller" label="KAM Seller"></app-checkbox>
              <app-checkbox [(checkboxValue)]="kamBuyer" componentId="kamBuyer" label="KAM Buyer"></app-checkbox>
            </div>
          </div>
        </app-main-content-row-layout>
      </div>
    </div>
  </app-main-content-layout>
</div>
