import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import _ from "lodash";
import moment from "moment";
import { Observable, Subscriber, firstValueFrom } from "rxjs";
import { TableHeaderMap } from "src/app/shared/app-table/app-table.component";

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  constructor(private http: HttpClient) { }

  public formatDate(date: Date): string {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}-${(date.getDate()).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`
  }

  public utcDateToLocal(date: string) {
    var offset = moment(date).utcOffset();

    return moment.utc(date).utcOffset(offset).format('yyyy-MM-DD HH:mm:ss');
  }

  public capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public splitWordByCamelcase(string: string): string {
    string = this.capitalizeFirstLetter(string);

    return string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  public splitWordByUnderline(string: string): string {
    string = this.capitalizeFirstLetter(string);

    return string.replaceAll('_', ' ');
  }

  public fillRangeNumber(size: number, startAt: number) {
    return [...Array(size).keys()].map(i => i + startAt);
  }

  public replaceAll(str: string, find: string, replace: string) {
    function escapeRegExp(string: string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  }

  public equipmentArrayTransform(equipArray: string[], arrayOfEquipments: any[]) {
    let eq = arrayOfEquipments.map(eq => Object.getOwnPropertyNames(eq)).flat(1);

    eq.forEach(e => {
      equipArray.push(this.splitWordByCamelcase(e));
    })
  }

  async convertToBase64(file: File, deleteString?: boolean) {
    const convertObserver = new Observable((subscriber: Subscriber<string>) => {
      this.readFile(file, subscriber);
    })

    let base64string = await firstValueFrom(convertObserver);

    if (deleteString) base64string = base64string.split("base64,")[1];

    return base64string;
  }

  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);

      subscriber.complete();
    }
  }

  formatDateValue(date: any) {
    return date ? (typeof (date) === 'string' ? moment(date).format("yyyy-MM-DD") : date.format("yyyy-MM-DD")) : '';
  }

  removeBlankAttributes(obj: any) {
    let result: any = {};

    let keys = Object.keys(obj);

    keys.forEach(k => {
      if (obj[k] || obj[k] === false) {
        result[k] = obj[k];
      }
    })

    return result;
  }

  getValue(element: any, value: TableHeaderMap): string {
    if (value.isDateHours) {
      return this.utcDateToLocal(_.get(element, value.value.split('.')));
    } else if (value.isDateSimple) {
      return this.formatDateValue(_.get(element, value.value.split('.')));
    } else {
      return _.get(element, value.value.split('.'));
    }
  }
}
