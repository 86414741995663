<app-navbar></app-navbar>

<app-page-layout>
  <div class="page-title">
    <p>Shop</p>
  </div>

  <div class="grid grid-cols-4 gap-6">
    <div class="col-span-1 flex flex-col gap-6">
      <cars-filter #carsFilter [isInternalShop]="true"></cars-filter>
    </div>

    <div class="col-span-3 flex flex-col gap-6" *ngIf="!(loading | async);else placeholderCars">
      <!-- filters selected -->
      <div class="bg-white rounded flex flex-wrap gap-3 text-sm p-6 items-center">
        <app-dropdown [control]="bcCountryControl" [options]="bcCountry"
          (emitOnChangeSelect)="emitCountryChange($event)" id="bc-country" label="Business case country"></app-dropdown>

        <div class="px-6 py-2 bg-blue-100 text-white rounded">
          <p>{{results | number}} results available</p>
        </div>

        <div class="px-6 py-2 border border-white-500 rounded flex cursor-default"
          *ngFor="let filter of filtersSelected;let i = index">
          <p>{{filter.group}}:</p>
          <p class="ml-2 mr-4">{{filter.name}}</p>
          <fa-icon [id]="'remove-filter-' + (i + 1) + '-btn'" [icon]="['far','xmark']" class="cursor-pointer"
            (click)="removeFilter(filter)">
          </fa-icon>
        </div>
      </div>

      <!-- <div class="grid grid-cols-4 items-center gap-4">
        <app-dropdown id="sort-by" placeholder="Sort by" class="col-span-1"></app-dropdown>
        <mat-checkbox id="select-all-checkbox" data-cy="select-all-checkbox" class="col-span-1"
          (change)="selectAll($event)">Select all</mat-checkbox>
        <secondary-btn id="export-cars" class="col-span-1 col-start-4 justify-self-end" (clickEmitter)="exportCars()"
          btnText="Export Selected"></secondary-btn>
      </div> -->

      <!-- results -->
      <div class="flex flex-col gap-6" *ngIf="!(loadingCars | async);else placeholderCars">
        <app-car-shop-card *ngFor="let car of cars" [car]="car"></app-car-shop-card>
      </div>

      <mat-paginator [length]="results" [pageSize]="20" aria-label="Select page" (page)="pageChange($event)">
      </mat-paginator>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholderCars>
  <div class="col-span-3 flex flex-col gap-6">
    <div class="grid grid-cols-3 gap-9 items-center bg-white rounded p-6" *ngFor="let i of [].constructor(5)">
      <div class="col-span-1">
        <img class="rounded object-contain opacity-30" src="./assets/background-photos/car-placeholder.png" alt="">
      </div>
      <div class="col-span-1 flex flex-col gap-6">
        <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
      </div>
      <div class="col-span-1 flex flex-col gap-6">
        <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
      </div>
    </div>
  </div>
</ng-template>
