<div class="flex flex-col gap-9">
  <div class="flex justify-between">
    <p class="font-bold">
      Buyer: {{buyerName}}
    </p>

    <primary-btn *ngIf="!disableContinueBtn" (clickEmitter)="continue($event)">Continue</primary-btn>
  </div>

  <div class="overflow-x-auto">
    <table mat-table matSort [dataSource]="cars"
      class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">
      <ng-container [matColumnDef]="'invCompany'">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
          Invoice company
        </th>
        <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">

          <app-dropdown-ngmodel [(dropdownValue)]="element.invoicingCompany.id" placeholder="Select invoice company"
            [options]="invoiceCompanies"></app-dropdown-ngmodel>

        </td>
      </ng-container>

      <ng-container [matColumnDef]="col.value" *ngFor="let col of headersMap">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
          {{col.tableView}}</th>
        <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
          <p>
            {{!isNumber(getValue(element,col.value)) ? getValue(element,col.value) : (getValue(element,col.value) |
            number)}}
          </p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100"></tr>
      <tr [ngClass]="{'isSelected' : row.isSelected}" class="hover:bg-white-500" mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
