<div class="twoCols">
  <div class="col-span-2 flex justify-between items-center">
    <app-dropdown id="stores" [options]="storesDropdown" label="Store" [control]="storeToggleControl"
      (emitOnChangeSelect)="changeStore($event)"></app-dropdown>

    <secondary-btn id="edit-mode" *ngIf="isAdmin && !editMode" (clickEmitter)="enterEditMode()">Edit
      stores</secondary-btn>

    <div class="flex gap-6" *ngIf="editMode">
      <secondary-btn *ngIf="!defaultStoreControl.value" btnText="Make default store"
        (clickEmitter)="makeDefaultStore()"></secondary-btn>

      <primary-btn id="save-store" btnText="Save Store" (clickEmitter)="saveStore()"></primary-btn>

      <tertiary-btn id="add-stre" btnText="Add Store" (clickEmitter)="addNewStore()"></tertiary-btn>

      <secondary-btn id="copy-data" btnText="Copy data from main company" (clickEmitter)="copyData()"></secondary-btn>
    </div>
  </div>

  <div class="col-span-1 flex flex-col gap-6">
    <!-- store profile -->
    <app-main-content-layout>
      <div class="title">
        <p>Store profile</p>
      </div>
      <app-main-content-row-layout>
        <p>Store name*</p>
        <app-input id="store-name" [control]="storeForm.controls.name" class="secondContent"
          placeholder="Insert store name"></app-input>
      </app-main-content-row-layout>
      <app-main-content-row-layout>
        <p>Region*</p>
        <div class="secondContent twoCols">
          <app-read-only-input
            [ngClass]=" {'text-gray-disabledInput' : !editMode}">{{companyCountry.name}}</app-read-only-input>

          <app-input id="city" class="col-span-1" [control]="storeForm.controls.city" placeholder="Insert city">
          </app-input>
        </div>
      </app-main-content-row-layout>
      <app-main-content-row-layout>
        <p>ZIP/Postal code*</p>
        <app-input id="postalcode" class="secondContent" [control]="storeForm.controls.postalCode"
          placeholder="Insert postal code"></app-input>
      </app-main-content-row-layout>
      <app-main-content-row-layout>
        <p>Address*</p>
        <app-input id="address" class="secondContent" [control]="storeForm.controls.address"
          placeholder="Insert address"></app-input>
      </app-main-content-row-layout>
    </app-main-content-layout>

    <!-- add basic profile -->
    <app-main-content-layout>
      <div class="title">
        <p>Add basic profile</p>
      </div>

      <app-main-content-row-layout>
        <p>Dealer group makes*</p>
        <app-dropdown id="group-makes" class="secondContent mt-2 xl:mt-0" [options]="makes" [multipleSelect]="true"
          [selectAll]="true" [control]="storeForm.controls.makes"
          placeholder="Select dealer group makes"></app-dropdown>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Estimated monthly car sales*</p>
        <app-input id="estimated-sales" class="secondContent" [isNumberInput]="true"
          [control]="storeForm.controls.carSalesEst" placeholder="Insert estimated monthly car sales"></app-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Use country’s bank holidays</p>

        <app-toggle id="country-holidays" class="secondContent"
          [toggleFormControl]="storeForm.controls.countryBank"></app-toggle>
      </app-main-content-row-layout>
    </app-main-content-layout>
  </div>

  <!-- right side panel -->
  <div class="col-span-1 flex flex-col gap-6">
    <app-main-content-layout>
      <div class="title">
        <p>Store info</p>
      </div>
      <app-main-content-row-layout>
        <p>Status</p>
        <div class="secondContent text-end">
          <app-toggle id="status" class="secondContent" [toggleFormControl]="statusControl"
            [toggleLabel]="statusControl.value? 'Active' :'Inactive'"></app-toggle>
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Is default store?</p>

        <div class="secondContent text-end">
          <app-toggle id="default" class="secondContent" [toggleFormControl]="defaultStoreControl"
            [toggleLabel]="defaultStoreControl.value? 'Yes' :'No'"></app-toggle>
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Date created</p>

        <app-read-only-input class="secondContent" *ngIf="dateCreated; else noDate">
          {{dateCreated | date:'dd/MM/yyyy'}}
        </app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Last update</p>

        <app-read-only-input class="secondContent" *ngIf="dateUpdated; else noDate">
          {{dateUpdated | date:'dd/MM/yyyy'}}
        </app-read-only-input>
      </app-main-content-row-layout>

      <div class="">
        <p class="font-bold mb-7">Store users</p>
        <div class="flex flex-col gap-3 p-6 bg-white-400 rounded max-h-52 overflow-auto">
          <div *ngFor="let user of users" class="flex gap-4 items-center">
            <div class="rounded-[100%] w-[6px] h-[6px] bg-blue-100"></div>
            {{user}}
          </div>
        </div>
      </div>
    </app-main-content-layout>

    <app-main-content-layout>
      <div class="title">
        Notes
      </div>

      <textarea [disabled]="!editMode" id="notes-textarea" data-cy="notes-textarea" [(ngModel)]="notes" rows="5"
        class="w-full rounded border border-white-500 p-6 text-blue-100 text-sm focus:outline-none"
        placeholder="Insert notes here..."></textarea>
    </app-main-content-layout>
  </div>
</div>

<ng-template #noDate>
  <app-read-only-input>
    <p>dd/mm/yy</p>
  </app-read-only-input>
</ng-template>
