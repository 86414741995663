import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimaryBtnComponent } from './primary-btn/primary-btn.component';
import { SecondaryBtnComponent } from './secondary-btn/secondary-btn.component';
import { TertiaryBtnComponent } from './tertiary-btn/tertiary-btn.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppInputComponent } from './app-input/app-input.component';
import { AppDropdownComponent } from './app-dropdown/app-dropdown.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppMaterialModule } from './material.module';
import { RightSideContentLayoutComponent } from './layouts/right-side-content-layout.component';
import { TopSideContentLayoutComponent } from './layouts/top-side-layout.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MainContentLayoutComponent } from './layouts/main-content-layout.component';
import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component';
import { UnsavedChangesModalComponent } from './unsaved-changes-modal/unsaved-changes-modal.component';
import { AppToggleComponent } from './app-toggle/app-toggle.component';
import { AppBtnToggleComponent } from './app-btn-toggle/app-btn-toggle.component';
import { AppPieChartComponent } from './app-pie-chart/app-pie-chart.component';
import { AppAreaChartComponent } from './app-area-chart/app-area-chart.component';
import { AppVerticalBarChartComponent } from './app-vertical-bar-chart/app-vertical-bar-chart.component';
import { AppHorizontalBarChartComponent } from './app-horizontal-bar-chart/app-horizontal-bar-chart.component';
import { AppSpinnerComponent } from './app-spinner/app-spinner.component';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { InfoContentLayoutComponent } from './layouts/info-content-layout.component';
import { AppProgressBarComponent } from './app-progress-bar/app-progress-bar.component';
import { AppDatepickerComponent } from './app-datepicker/app-datepicker.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { PageLayoutComponent } from './layouts/page-layout.component';
import { AppExpansionPanelComponent } from './app-expansion-panel/app-expansion-panel.component';
import { AppImageSliderComponent } from './app-image-slider/app-image-slider.component';
import { MainContentRowLayoutComponent } from './layouts/main-content-row-layout.component';
import { CountriesAvailabilityModalComponent } from './countries-availability-modal/countries-availability-modal.component';
import { AppTableComponent } from './app-table/app-table.component';
import { ReadOnlyInputComponent } from './layouts/read-only-input.component';
import { MainContentPlaceholderComponent } from './layouts/main-content-placeholder.component';
import { SecondaryButtonFilledComponent } from './secondary-button-filled/secondary-button-filled.component';
import { EllipsisPipe } from '../core/pipelines/ellipsis.pipe';
import { AppLineChartComponent } from './app-line-chart/app-line-chart.component';
import { WhiteBtnComponent } from './white-btn/white-btn.component';
import { BlueBtnComponent } from './blue-btn/blue-btn.component';
import { AppInputNumberComponent } from './app-input-number/app-input-number.component';
import { NumberCommaDirective } from './app-input-number/number-comma.directive';
import { TablePlaceholderComponent } from './layouts/table-placeholder.component';
import { MainContentRowInputComponent } from './layouts/main-content-row-input.component';
import { MainContentRowDropdownComponent } from './layouts/main-content-row-dropdown.component';
import { MainContentRowInputNumberComponent } from './layouts/main-content-row-input-number.component';
import { AppInputNumberNgmodelComponent } from './app-input-number-ngmodel/app-input-number-ngmodel.component';
import { AppCheckboxComponent } from './app-checkbox/app-checkbox.component';
import { ImageFullScreenComponent } from './app-image-slider/image-full-screen/image-full-screen.component';
import { AppDropdownNgmodelComponent } from './app-dropdown-ngmodel/app-dropdown-ngmodel.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppMaterialModule,
    FontAwesomeModule,
    IvyCarouselModule
  ],
  declarations: [
    PrimaryBtnComponent,
    SecondaryBtnComponent,
    TertiaryBtnComponent,
    AppInputComponent,
    AppDropdownComponent,
    AppDropdownNgmodelComponent,
    AppToggleComponent,
    DropdownFilterComponent,
    RightSideContentLayoutComponent,
    TopSideContentLayoutComponent,
    UnsavedChangesModalComponent,
    AppBtnToggleComponent,
    AppPieChartComponent,
    AppAreaChartComponent,
    AppVerticalBarChartComponent,
    AppHorizontalBarChartComponent,
    AppSpinnerComponent,
    AppNavbarComponent,
    InfoContentLayoutComponent,
    AppProgressBarComponent,
    AppDatepickerComponent,
    PageLayoutComponent,
    AppExpansionPanelComponent,
    AppImageSliderComponent,
    MainContentRowLayoutComponent,
    MainContentLayoutComponent,
    CountriesAvailabilityModalComponent,
    AppTableComponent,
    ReadOnlyInputComponent,
    MainContentPlaceholderComponent,
    SecondaryButtonFilledComponent,
    EllipsisPipe,
    AppLineChartComponent,
    WhiteBtnComponent,
    BlueBtnComponent,
    AppInputNumberComponent,
    NumberCommaDirective,
    TablePlaceholderComponent,
    MainContentRowInputComponent,
    MainContentRowDropdownComponent,
    MainContentRowInputNumberComponent,
    AppInputNumberNgmodelComponent,
    AppCheckboxComponent,
    ImageFullScreenComponent
  ],
  exports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppMaterialModule,
    FontAwesomeModule,
    PrimaryBtnComponent,
    SecondaryBtnComponent,
    TertiaryBtnComponent,
    AppInputComponent,
    AppDropdownComponent,
    AppDropdownNgmodelComponent,
    AppToggleComponent,
    DropdownFilterComponent,
    RightSideContentLayoutComponent,
    TopSideContentLayoutComponent,
    AppBtnToggleComponent,
    AppPieChartComponent,
    AppAreaChartComponent,
    AppVerticalBarChartComponent,
    AppHorizontalBarChartComponent,
    AppSpinnerComponent,
    AppNavbarComponent,
    InfoContentLayoutComponent,
    AppProgressBarComponent,
    AppDatepickerComponent,
    IvyCarouselModule,
    PageLayoutComponent,
    AppExpansionPanelComponent,
    AppImageSliderComponent,
    MainContentRowLayoutComponent,
    MainContentLayoutComponent,
    CountriesAvailabilityModalComponent,
    AppTableComponent,
    ReadOnlyInputComponent,
    MainContentPlaceholderComponent,
    SecondaryButtonFilledComponent,
    EllipsisPipe,
    AppLineChartComponent,
    WhiteBtnComponent,
    BlueBtnComponent,
    AppInputNumberComponent,
    TablePlaceholderComponent,
    MainContentRowInputComponent,
    MainContentRowDropdownComponent,
    MainContentRowInputNumberComponent,
    AppInputNumberNgmodelComponent,
    AppCheckboxComponent,
    ImageFullScreenComponent
  ],
})
export class SharedModule { }
