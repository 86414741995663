<div class="dropdown relative">
  <p class="absolute z-10 text-white-300 text-xs -top-5" *ngIf="label">{{label}}</p>
  <mat-form-field appearance="outline" class="bg-white w-full rounded"
    [ngClass]="{ 'disabled-dropdown' : control.disabled || disabled}">
    <mat-select [id]="id + '-dropdown'" [attr.data-cy]="id + '-dropdown'" [disabled]="disabled" [formControl]="control"
      [multiple]="multipleSelect" [placeholder]="placeholder">
      <mat-option *ngIf="resetValue" (onSelectionChange)="emitChange($event)">None</mat-option>

      <mat-checkbox *ngIf="selectAll" class="mat-option" [indeterminate]="isIndeterminate()" [checked]="isChecked()"
        (click)="$event.stopPropagation()" (change)="toggleSelection($event)">
        Select All
      </mat-checkbox>

      <mat-option *ngFor="let option of options" [value]="option.value" (onSelectionChange)="emitChange($event)">
        {{option.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
