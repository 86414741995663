import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderCar, UpdateDocumentsAddressBody, UpdateDocumentsDetailsBody } from 'src/app/core/models/order.model';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderProcessCarDocumentsModalComponent } from './order-process-car-documents-modal/order-process-car-documents-modal.component';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { OrderStatus } from '../../../orders-view/orders-view.component';

export enum CarDocumentsType {
  COC = 'COC',
  CC = 'CC',
  REGISTRATION = 'REG',
  INVOICE = 'INV'
}

@Component({
  selector: 'app-order-process-car-documents',
  templateUrl: './order-process-car-documents.component.html',
  styleUrls: ['./order-process-car-documents.component.scss']
})

export class OrderProcessCarDocumentsComponent implements OnInit {
  headers: TableHeaderMap[] = [
    {
      value: 'carConfig.sellerName',
      tableView: 'seller'
    },
    {
      value: 'carPricing.invoiceSellingPrice',
      tableView: 'price'
    },
    {
      value: 'carConfig.vin',
      tableView: 'vin'
    },
    {
      value: 'carConfig.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carConfig.make',
      tableView: 'make'
    },
    {
      value: 'carConfig.model',
      tableView: 'model'
    },
    {
      value: 'documentsDetails.country.name',
      tableView: 'country'
    },
    {
      value: 'documentsDetails.city',
      tableView: 'city'
    },
    {
      value: 'documentsDetails.address',
      tableView: 'address'
    },
    {
      value: 'documentsDetails.postalCode',
      tableView: 'postal code'
    },
    {
      value: 'documentsDetails.contactDetails.fullName',
      tableView: 'contact'
    },
    {
      value: 'documentsDetails.contactDetails.email',
      tableView: 'email'
    },
    {
      value: 'documentsDetails.contactDetails.phone',
      tableView: 'phone'
    },
    {
      value: 'carRegisteredC2C',
      tableView: 'car Registered C2C'
    },
    {
      value: 'carDeRegisteredC2C',
      tableView: 'car deregistered C2C'
    },
    {
      value: 'dhlRequested',
      tableView: 'DHL Requested'
    },
    {
      value: 'dhlTrackingNo',
      tableView: 'DHL Tracking No'
    },
    {
      value: 'documentsDetails.documents.registration.receivedAt',
      tableView: 'registration Documents Received'
    },
    {
      value: 'documentsDetails.documents.registration.sentAt',
      tableView: 'registration Documents Sent'
    },
    {
      value: 'documentsDetails.documents.coc.receivedAt',
      tableView: 'COC Requested'
    },
    {
      value: 'documentsDetails.documents.coc.cocFrom',
      tableView: 'COC from'
    }
  ]

  displayedColumns = this.headers.map(h => h.value).concat(['details']);

  cars: OrderCar[] = this.orderFlowService.carList;

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;

  constructor(private orderFlowService: OrdersFlowSyncService,
    private orderService: OrdersService,
    private dialog: MatDialog,
    private spinner: SpinnerHandlerService,
    private snackbar: SnackbarService) { }

  ngOnInit() {
    this.setDocumentsContactFullName();
  }

  openModal(car: OrderCar) {
    const dialogRef = this.dialog.open(
      OrderProcessCarDocumentsModalComponent, {
      width: '1200px',
      autoFocus: false,
      disableClose: this.orderFlowService.orderStatus != OrderStatus.FINISHED,
      data: {
        car: JSON.parse(JSON.stringify(car)),
        orderId: this.orderFlowService.orderId,
        finishedOrder: this.orderFlowService.orderStatus === OrderStatus.FINISHED
      }
    });

    dialogRef.afterClosed().subscribe(modalResp => {
      if (modalResp) {
        this.spinner.showProgressBar.next(true);

        let body = new UpdateDocumentsAddressBody(this.orderFlowService.orderId!, car.carConfig.seller, car.carMainInfoId, modalResp);

        this.orderService.updateCarDocumentsAddress(body).subscribe({
          next: sellers => {
            this.orderFlowService.order!.sellers = sellers;

            this.orderFlowService.updateCarList();

            this.cars = this.orderFlowService.carList;

            this.setDocumentsContactFullName();

            this.carsTable!.updateTable(this.cars);

            this.spinner.showProgressBar.next(false);
            this.snackbar.positiveSentiment('Car document details updated successfully!');
          },
          error: err => {
            this.spinner.showProgressBar.next(false);
            this.snackbar.negativeSentiment(err.error);
          }
        });
      }
    });
  }

  setDocumentsContactFullName() {
    this.cars.forEach(c => c.documentsDetails.contactDetails.fullName = `${c.documentsDetails.contactDetails.firstName} ${c.documentsDetails.contactDetails.lastName}`)
  }
}
