import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "../services/auth.service";
import { CoreService } from "../services/core.service";
import { UserRoles } from "../models/user.model";
import { MsalService } from "@azure/msal-angular";

@Injectable()
export class AuthGuardMaintenance implements CanActivate {
  private jwtHelperService = new JwtHelperService();

  constructor(private authService: AuthService,
    private coreService: CoreService,
    private router: Router,
    private msalService: MsalService) { }

  canActivate() {
    if (this.coreService.user && !(this.coreService.user.role === UserRoles.ADMIN || this.coreService.user.role === UserRoles.KAM)) {
      this.router.navigate(['restricted']);
    }

    let token = localStorage.getItem('msalToken');

    if (token && this.jwtHelperService.isTokenExpired(token)) {
      this.authService.logout(window.location.pathname.slice(1));

      return false;
    } else if (token) {
      this.authService.token = token;

      return true;
    }
    else {
      this.authService.logout(window.location.pathname.slice(1));

      return false;
    }
  }

  canActivateCoreService(): string | undefined {
    let token = localStorage.getItem('msalToken');

    if (token && this.jwtHelperService.isTokenExpired(token)) {
      return undefined;
    } else if (token) {
      this.authService.token = token;
      return token;
    }
    else {
      return undefined;
    }
  }
}
