import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CarOnlineListUploadResponse, CarsBatchUploadResponse } from 'src/app/core/models/cars-inventory.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import excelMap from 'src/assets/json-files/cars-on-list-import-mapping.json';
import * as XLSX from 'xlsx';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ModifyUnidentifiedCarsModalComponent } from '../../online-list-upload/modify-unidentified-cars-modal/modify-unidentified-cars-modal.component';
import { ModifyPartiallyIdentifiedCarModalComponent } from '../../online-list-upload/modify-partially-identified-car-modal/modify-partially-identified-car-modal.component';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { Router } from '@angular/router';
import { CarIdentifiedModalComponentCar } from 'src/app/features/car-view/car-details/car-identified-modal/car-identified-modal.component';


@Component({
  selector: 'app-car-on-list-import-review',
  templateUrl: './car-on-list-import-review.component.html',
  styleUrls: ['./car-on-list-import-review.component.scss']
})
export class CarOnListImportReviewComponent implements OnInit {
  tableMap: TableHeaderMap[] = [
    {
      value: 'regNo',
      tableView: 'reg number'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'manufactureYear',
      tableView: 'model Year'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'variant',
      tableView: 'variant'
    },
    {
      value: 'trim',
      tableView: 'trim'
    },
    {
      value: 'firstReg',
      tableView: 'first Reg'
    },
    {
      value: 'fuelType',
      tableView: 'fuel Type'
    },
    {
      value: 'gearbox',
      tableView: 'gearbox'
    },
    {
      value: 'bodyType',
      tableView: 'chassis'
    },
    {
      value: 'enginePower',
      tableView: 'engine Power'
    },
    {
      value: 'engineVolume',
      tableView: 'engine volume'
    },
    {
      value: 'co2NEDC',
      tableView: 'co2 NEDC'
    },
    {
      value: 'co2WLTP',
      tableView: 'co2 WLTP'
    },
    {
      value: 'color',
      tableView: 'color'
    },
    {
      value: 'doors',
      tableView: 'doors'
    },
    {
      value: 'seats',
      tableView: 'seats'
    },
    {
      value: 'drivingWheels',
      tableView: 'driving Wheels'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'vatStatus',
      tableView: 'vat Status'
    },
    {
      value: 'damages',
      tableView: 'damages'
    }
  ];

  displayedColumns = ['selectAll', 'priceError'].concat(this.tableMap.map(t => t.value), ['price', 'buyingPrice', 'edit']);

  dataSource = new MatTableDataSource<CarOnlineListUploadResponse>();

  batchResponse: CarsBatchUploadResponse | undefined;
  excelMap = excelMap;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private carFlowSyncService: CarFlowSyncService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private carService: CarService,
    private utilsService: UtilsService,
    private spinnerService: SpinnerHandlerService,
    private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  uploadCars() {
    if (this.spinnerService.showProgressBar.value) return;

    // if (this.dataSource.data.some(c => c.identified != 'Identified')) {
    //   this.snackbar.negativeSentiment("Not all cars have been identified!");
    //   return;
    // }



    this.spinnerService.showProgressBar.next(true);


    // list!.cars = this.dataSource.data.map(c => ({
    //   ...c,
    //   id: '',
    //   trim: c.trim ? c.trim : ''
    // }));

    // this.carService.createCarsOnListImportList(list).subscribe({
    //   next: (resp) => {

    //     this.spinnerService.showProgressBar.next(false);

    //     this.router.navigate([`cars-on-list/${resp.carsList}`]);
    //   },
    //   error: (err) => {
    //     this.spinnerService.showProgressBar.next(false);

    //     this.snackbar.negativeSentiment('Something went wrong, check excel file');
    //   }
    // })
  }

  selectAll(event: MatCheckboxChange) {
    this.dataSource.data = this.dataSource.data.map(d => ({ ...d, isSelected: event.checked }))
  }

  editCar(car: CarOnlineListUploadResponse) {
    if (this.spinnerService.showProgressBar.value) return;

    // if (car.identified === 'Unidentified' || car.identifyStatus === 'Invalid data') {
    //   this.openModifyUnidentifiedCarsModal(car);
    // } else {
    //   this.openModifyPartiallyIdentifiedCarModal(car);
    // }
  }

  editMultipleCars() {
    // if (this.spinnerService.showProgressBar.value) return;

    // let cars = this.dataSource.data.filter(c => c.isSelected);

    // if (cars.length === 0) {
    //   this.snackbar.negativeSentiment('No cars selected!');
    //   return;
    // }

    // if (cars.some(c => c.make != cars[0].make
    //   || c.model != cars[0].model
    //   || c.gearbox != cars[0].gearbox
    //   || c.fuelType != cars[0].fuelType
    //   || c.enginePower != cars[0].enginePower)
    // ) {
    //   this.snackbar.negativeSentiment('Different cars selected! Please select same cars!');
    //   return;
    // }

    // if (cars.some(c => c.identifyStatus != cars[0].identifyStatus)) {
    //   this.snackbar.negativeSentiment('Different levels of identification selected! Please select cars that are equally identified!');
    //   return;
    // }

    // if (cars[0].identifyStatus === 'Unidentified' || cars[0].identifyStatus === 'Invalid data') {
    //   this.openModifyUnidentifiedCarsModal(undefined, true);
    // } else {
    //   this.openModifyPartiallyIdentifiedCarModal(cars[0], true);
    // }
  }

  openModifyUnidentifiedCarsModal(car?: CarOnlineListUploadResponse, multipleEdit: boolean = false) {
    if (this.spinnerService.showProgressBar.value) return;

    const dialogRef = this.dialog.open(
      ModifyUnidentifiedCarsModalComponent, {
      width: '800px',
      autoFocus: false,
      data: {}
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (multipleEdit && resp) {
        this.updateMultipleCars(resp);
      } else if (resp) {
        this.updateSingleCar(resp, car!.id);
      }
    })
  }

  openModifyPartiallyIdentifiedCarModal(car: CarOnlineListUploadResponse, multipleEdit: boolean = false) {
    const dialogRef = this.dialog.open(
      ModifyPartiallyIdentifiedCarModalComponent, {
      width: '900px',
      autoFocus: false,
      data: {
        car: car
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (multipleEdit && resp) {
        this.updateMultipleCars(resp);
      } else if (resp) {
        this.updateSingleCar(resp, car.id);
      }
    })
  }

  updateMultipleCars(car: CarIdentifiedModalComponentCar) {
    // this.dataSource.data.filter(c => c.isSelected).forEach(c => {
    //   c.make = car.make!;
    //   c.model = car.model!;
    //   c.variant = car.variant!;
    //   c.trim = car.trim!;
    //   c.fuelType = car.fuelType!;
    //   c.gearbox = car.gearbox!;
    //   c.identified = 'Identified';
    //   c.engineVolume = car.engineVolume ? car.engineVolume : (c.engineVolume ? c.engineVolume : 0);
    //   c.enginePower = car.enginePower ? car.enginePower : c.enginePower;
    //   c.co2NEDC = car.co2NEDC ? car.co2NEDC : c.co2NEDC;
    //   c.co2WLTP = car.co2WLTP ? car.co2WLTP : c.co2WLTP;
    //   c.drivingWheels = car.drivingWheels ? car.drivingWheels : c.drivingWheels;
    //   c.doors = car.doors ? car.doors : c.doors;
    //   c.seats = car.seats ? car.seats : c.seats;
    //   c.idAdac = car.idAdac;
    // });
  }

  updateSingleCar(car: CarIdentifiedModalComponentCar, carId: number) {
    let updateCar: CarOnlineListUploadResponse = this.dataSource.data.find(c => c.id === carId)!;

    // updateCar.make = car.make!;
    // updateCar.model = car.model!;
    // updateCar.variant = car.variant!;
    // updateCar.trim = car.trim!;
    // updateCar.fuelType = car.fuelType!;
    // updateCar.gearbox = car.gearbox!;
    // updateCar.identified = 'Identified';
    // updateCar.engineVolume = car.engineVolume ? car.engineVolume : (updateCar.engineVolume ? updateCar.engineVolume : 0);
    // updateCar.enginePower = car.enginePower ? car.enginePower : updateCar.enginePower;
    // updateCar.co2NEDC = car.co2NEDC ? car.co2NEDC : updateCar.co2NEDC;
    // updateCar.co2WLTP = car.co2WLTP ? car.co2WLTP : updateCar.co2WLTP;
    // updateCar.drivingWheels = car.drivingWheels ? car.drivingWheels : updateCar.drivingWheels;
    // updateCar.doors = car.doors ? car.doors : updateCar.doors;
    // updateCar.seats = car.seats ? car.seats : updateCar.seats;
    // updateCar.idAdac = car.idAdac;
  }

  exportRejectedCars() {
    let wsHeaders = this.excelMap.map(t => this.utilsService.capitalizeFirstLetter(t.excelValue));
    wsHeaders.push('Errors');

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [wsHeaders]);

    let rows: string[][] = []

    this.batchResponse?.faultyCars.forEach((c: any) => {
      let row: string[] = [];

      excelMap.forEach(m => {
        row.push(c[m.objectValue] ? c[m.objectValue] : '');
      })

      row.push(c.rejectReason.join(", "));
      rows.push(row);
    })

    XLSX.utils.sheet_add_aoa(ws, rows, { origin: 'A2' });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'declined-cars.xlsx');
  }

}
