<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9">
    <div class="font-bold text-2xl">
      <p>Cars on list</p>
    </div>

    <div class="overflow-x-auto" *ngIf="!(loading | async);else placeholder">
      <app-table [data]="cars" [displayedColumns]="displayedColumns" [headersMap]="tableColumns"
        (goToEmitter)="goToCar($event)"></app-table>
    </div>
  </div>
</app-page-layout>

<!-- <div class="bg-blue-100 z-50 sticky py-11 w-full bottom-9 flex gap-6 items-baseline pl-20" *ngIf="carImportResponse">
  <fa-icon class="text-green-100 text-xl" [icon]="['fal','hexagon-check']"></fa-icon>
  <p class="font-bold text-green-100">{{carImportResponse.addedCarsNo}} cars imported</p>
  <p class="text-white-300 px-6 border-l border-r border-white-300">{{carImportResponse.carsWithProblems.length +
    carImportResponse.carsUnidentified.length + carImportResponse.carsWithAlternatives.length}}
    cars unidentified and rejected</p>
  <p class="text-white cursor-pointer" id="export-rejected-cars-btn" data-cy="export-rejected-cars-btn"
    (click)="exportRejectedCars()">Download rejected</p>
</div> -->

<ng-template #placeholder>
  <app-table-placeholder></app-table-placeholder>
</ng-template>
