<div class="grid grid-cols-2 gap-6">
  <div class="col-span-2 flex justify-end" *ngIf="isAdmin && !editMode">
    <secondary-btn id="save-buyer" (clickEmitter)="enterEditMode()">Edit buyer</secondary-btn>
  </div>

  <div class="col-span-2 flex justify-end" *ngIf="editMode">
    <primary-btn id="save-buyer" (clickEmitter)="saveForm()">Save buyer</primary-btn>
  </div>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Buyer Details</p>
    </div>

    <div class="grid grid-cols-4 gap-4 border-b border-white-500 pb-6 items-center">
      <app-input id="vat" [label]="'VAT*'" class="col-span-1" placeholder="Insert VAT"
        [control]="vatControl"></app-input>
    </div>

    <app-main-content-row-dropdown *ngIf="isAdmin;else kamEmail" title="KAM" componentId="kam" placeholder="Select KAM"
      [control]="companyForm.controls.kamId" [options]="kams"></app-main-content-row-dropdown>

    <ng-template #kamEmail>
      <app-main-content-row-layout>
        <p>KAM</p>

        <app-read-only-input class="secondContent">{{user.email}}</app-read-only-input>
      </app-main-content-row-layout>
    </ng-template>


    <app-main-content-row-input title="Company name" [componentId]="'company-name'" placeholder="Insert company name"
      [control]="companyForm.controls.name"></app-main-content-row-input>

    <app-main-content-row-layout>
      <p
        [ngClass]="{'text-red font-bold' : (companyForm.controls.country.invalid && companyForm.controls.country.touched) || ((companyForm.controls.city.invalid && companyForm.controls.city.touched) )}">
        Region*</p>

      <div class="twoCols secondContent">
        <app-dropdown id="country" class="col-span-1" [control]="companyForm.controls.country" [options]="countries"
          placeholder="Select country">
        </app-dropdown>
        <app-input id="city" class="col-span-1" [control]="companyForm.controls.city" placeholder="Insert city">
        </app-input>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-input title="ZIP/Postal code" componentId="postalcode" placeholder="Insert postal code"
      [control]="companyForm.controls.postalCode"></app-main-content-row-input>

    <app-main-content-row-input title="Address" componentId="address" placeholder="Insert address"
      [control]="companyForm.controls.address"></app-main-content-row-input>

    <app-main-content-row-dropdown title="Dealer group makes" componentId="group-makes"
      placeholder="Select dealer group makes" [control]="companyForm.controls.make" [options]="makes"
      [multipleSelect]="true"></app-main-content-row-dropdown>

    <app-main-content-row-layout>
      <p>Cars2view dealer ID</p>
      <app-input id="c2v-dealer-id" class="secondContent" [control]="companyForm.controls.cars2viewDealer"
        placeholder="Insert cars2view dealer id"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-input title="Company website" componentId="website" placeholder="Insert company website"
      [control]="companyForm.controls.website"></app-main-content-row-input>

    <app-main-content-row-layout>
      <p>Phone</p>
      <app-input id="phone" class="secondContent" [control]="companyForm.controls.phone" placeholder="Insert phone">
      </app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <div class="flex items-center">
        <p>Email</p>

        <p class="relative -left-[2.9375rem] top-6 text-sm text-red opacity-0 whitespace-nowrap"
          [ngClass]="{'opacity-100':companyForm.controls.email.hasError('email')}">Insert valid email</p>
      </div>

      <app-input id="email" class="secondContent" [control]="companyForm.controls.email" placeholder="Insert email">
      </app-input>
    </app-main-content-row-layout>

    <app-main-content-row-dropdown title="Priority" componentId="priority" placeholder="Select priority"
      [control]="companyForm.controls.priority" [options]="priorities"></app-main-content-row-dropdown>
  </app-main-content-layout>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Buyer info</p>
    </div>

    <app-main-content-row-layout>
      <p>Status</p>

      <div class="secondContent text-end">
        <app-toggle id="status" class="secondContent" [toggleLabel]="statusControl.value ? 'Active' : 'Inactive'"
          [toggleFormControl]="statusControl"></app-toggle>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>VAT Status</p>
      <div class="secondContent text-end">
        <app-toggle id="vies-status" [toggleLabel]="vatStatusControl.value ? 'Active' : 'Inactive'"
          [toggleFormControl]="vatStatusControl"></app-toggle>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Date created</p>

      <app-read-only-input class="secondContent" *ngIf="dateCreated; else noDate">
        {{dateCreated | date:'dd/MM/yyyy'}}
      </app-read-only-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Last update</p>

      <app-read-only-input class="secondContent" *ngIf="dateUpdated; else noDate">
        {{dateUpdated | date:'dd/MM/yyyy'}}
      </app-read-only-input>
    </app-main-content-row-layout>

    <div>
      <p class="font-bold mb-7">List of stores</p>
      <div class="p-6 bg-white-400 rounded flex flex-col gap-3 max-h-52 overflow-auto">
        <div *ngFor="let store of storesOptions" class="flex gap-4 items-center">
          <div class="rounded-[100%] w-[6px] h-[6px] bg-blue-100"></div>
          {{store.viewValue}}
        </div>
      </div>
    </div>

    <div>
      <p class="font-bold mb-7">List of closed sales groups</p>
      <div class="p-6 bg-white-400 rounded flex flex-col gap-3 max-h-52 overflow-auto">
        <div *ngFor="let group of carGroups" class="flex gap-4 items-center">
          <div class="rounded-[100%] w-[6px] h-[6px] bg-blue-100"></div>
          {{group}}
        </div>
      </div>
    </div>
  </app-main-content-layout>
</div>

<ng-template #noDate>
  <app-read-only-input>
    <p>dd/mm/yy</p>
  </app-read-only-input>
</ng-template>
