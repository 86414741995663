<app-dropdown [options]="sellerFilters" [control]="sellerFilterControl" class="flex" label="Filter sellers"
  (emitOnChangeSelect)="filterCars($event)" placeholder="Select seller"></app-dropdown>

<div class="overflow-x-auto mt-9">
  <table mat-table matSort [dataSource]="dataSource"
    class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">

    <ng-container matColumnDef="selectAll">
      <th mat-header-cell *matHeaderCellDef>
        <div class="flex gap-3">
          <mat-checkbox id="select-all-checkbox" data-cy="select-all-checkbox"
            (change)="selectAll($event)"></mat-checkbox>
          <p>SELECT ALL</p>
        </div>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-checkbox [id]="'select'+ (i + 1) +'-checkbox'" [attr.data-cy]="'select'+ (i + 1) +'-checkbox'"
          [(ngModel)]="element.isSelected"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="col.value" *ngFor="let col of headers">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        {{col.tableView}}</th>
      <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
        <p *ngIf="!isNumber(element[col.value]);else numberDisplay">{{element[col.value]}}</p>
        <ng-template #numberDisplay>
          <p>{{element[col.value] | number}}</p>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="sellerValidationType">
      <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
        SELLER status</th>
      <td class="!py-2" mat-cell *matCellDef="let element">
        <div [ngSwitch]="element.sellerValidationType">
          <div *ngSwitchCase="sellerStatus.Approved">
            <p class="text-green-200">Approved</p>
          </div>
          <div *ngSwitchCase="sellerStatus.NotRequested">
            <app-secondary-button-filled>Not Requested</app-secondary-button-filled>
          </div>
          <div *ngSwitchCase="sellerStatus.Requested">
            <p class="text-orange-100">Pending requested</p>
          </div>
          <div *ngSwitchCase="sellerStatus.Rejected">
            <p class="text-red">Rejected</p>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100"></tr>
    <tr [ngClass]="{'isSelected' : row.isSelected}" class="hover:bg-white-500" mat-row
      *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
  </mat-paginator>
</div>
