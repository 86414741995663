<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 pr-6">
  <div class="flex w-full h-64 justify-center">
    <img class="object-contain"
      [src]="car.profilePhoto != 'None' ? car.profilePhoto : './assets/background-photos/car-placeholder.png'" alt="">
  </div>

  <div class="flex flex-col gap-6">
    <p class="font-bold text-xl">{{car.make}} {{car.model}}, {{car.variant}}</p>

    <div class="flex flex-wrap gap-3">
      <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
        {{car.manufactureYear}}
      </div>
      <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
        {{car.mileage | number}}km
      </div>
      <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
        {{car.fuelType}}
      </div>
      <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
        {{car.gearbox}}
      </div>
    </div>

    <div class="flex gap-6 flex-wrap">
      <div class="flex items-center gap-1">
        <p>Selling price</p>

        <p class="text-2xl text-blue-200">€{{car.sellingPrice | number}}</p>

        <p class="text-sm text-white-600">{{car.vatStatus ? vatStatus.ExVAT : vatStatus.InclVAT}}</p>
      </div>


      <div class="flex items-center gap-1">
        <p>Minimum selling price</p>

        <p class="text-2xl text-blue-200">€{{car.minSellingPrice | number}}</p>

        <p class="text-sm text-white-600">{{car.vatStatus ? vatStatus.ExVAT : vatStatus.InclVAT}}</p>
      </div>

      <div class="flex items-center gap-1" *ngIf="latestOffer">
        <p>Latest offer sent</p>

        <p class="text-2xl text-blue-200">€{{latestOffer | number}}</p>

        <p class="text-sm text-white-600">{{car.vatStatus ? vatStatus.ExVAT : vatStatus.InclVAT}}</p>
      </div>
    </div>

    <!-- <div>
      <p class="text-sm">Available: Now</p>
    </div> -->
  </div>

  <div *ngIf="bc">
    <div class="font-bold">
      <p>Business case for {{buyerCountry}}:</p>
    </div>

    <div class="grid grid-cols-2 gap-x-9 gap-y-3 mt-6">
      <div class="col-span-1 flex gap-3">
        <fa-icon [icon]="['fal','check']"></fa-icon>
        <p>Retail price: €{{bc.spotPrice | number}}</p>
      </div>

      <div class="col-span-1 flex gap-3">
        <fa-icon [icon]="['fal','check']"></fa-icon>
        <p>Monthly sold: {{bc.carsSold30Days | number}}</p>
      </div>

      <div class="col-span-1 flex gap-3">
        <fa-icon [icon]="['fal','check']"></fa-icon>
        Sales factor: {{!isNaN(bc.salesFactor) ? (bc.salesFactor | number) : 'No data'}}
      </div>

      <div class="col-span-1 flex gap-3 text-green-200"
        [ngClass]="{'!text-red': (bc.spotPrice - (priceControl.value ? priceControl.value : 0) < 0)}">
        <fa-icon [icon]="['fal','check']"></fa-icon>
        <p class="font-bold">
          Est Profit: €{{bc.spotPrice - (priceControl.value ? priceControl.value : 0) | number : '1.0-2'}}</p>
      </div>
    </div>
  </div>

  <div class="flex gap-3">
    <div class="flex gap-3">
      <fa-icon [icon]="['fal','cube']"></fa-icon>
      <p>Location: <span class="font-bold">{{car.locationName}}</span></p>
    </div>

    <div class="flex gap-3">
      <fa-icon [icon]="['fal','share-nodes']"></fa-icon>
      <p>Source: <span class="text-blue-500">{{car.sourceName ? car.sourceName : car.source}}</span></p>
    </div>
  </div>

  <p>Please calculate the offered prices including transportation for each vehicle. The price you set here will be the
    price the buyer will pay incl. transport</p>

  <div class="flex justify-between" *ngIf="isExpiredOffer">
    <app-datepicker label="New expiration date" [control]="newExpiratonDateControl" [min]="today"></app-datepicker>
  </div>

  <div *ngIf="expiresOn">
    <p>Expiration date: {{expiresOn | date:'dd/MM/yyyy'}}</p>
  </div>

  <div class="flex justify-between">
    <app-input-number id="offer-price" placeholder="Insert amount here (€ EUR only)" [control]="priceControl"
      class="w-1/2"></app-input-number>

    <app-blue-btn id="set-price" (clickEmitter)="setPrice()">Set offer</app-blue-btn>
  </div>
</div>
