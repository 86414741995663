import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-content-placeholder',
  template: `
    <app-main-content-layout>
      <div class="title">
        <div class="placeholder-animation h-6 w-1/4"></div>
      </div>

      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
    </app-main-content-layout>
  `,
})
export class MainContentPlaceholderComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
