import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { MonthList } from 'src/app/features/settings/country-holiday/country-holiday.component';

@Component({
  selector: 'app-line-chart',
  templateUrl: './app-line-chart.component.html',
  styleUrls: ['./app-line-chart.component.scss']
})
export class AppLineChartComponent implements AfterViewInit {
  @Input() data: any;
  @Input() chartId: string = "";
  @Input() title: string = "";
  chart: Chart | undefined;
  months = Object.keys(MonthList);

  constructor() { }

  ngAfterViewInit() {
    Chart.register(...registerables);

    let data = {
      labels: this.months.filter(m => isNaN(Number(m))).map(m => m.substring(0, 3)),
      datasets: [{
        label: this.title,
        data: [5, 2, 7, 0, 3, 2, 8, 5, 3],
        fill: true,
        borderColor: '#A349FD',
        backgroundColor: '#F9F4FF'
      }],
    };

    if (this.data) {
      data = this.data;
    }

    this.chart = new Chart(this.chartId, {
      type: 'line',
      data: data,
      options: {
        // maintainAspectRatio: false,
        // responsive: true,
        scales: {
          x: {
            grid: {
              borderColor: '#ffffff',
              lineWidth: 0
            }
          },
          y: {
            grid: {
              borderColor: '#ffffff',
              lineWidth: 0
            }
          }
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        plugins: {
          filler: {
            propagate: false,
          },
          legend: {
            display: false,
            position: 'right',
            labels: {
              usePointStyle: true
            }
          },
          title: {
            text: this.data ? this.data.datasets[0].label : this.title,
            align: 'start',
            display: true,
            font: {
              size: 14,
              weight: 'bold',
            },
            color: '#00304A',
            padding: {
              bottom: 50
            }
          },
        }
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  resizeChart() {
    this.chart?.resize();
  }
}
