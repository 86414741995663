<div class="flex flex-col gap-9">
  <div class="flex justify-between">
    <div class="flex gap-6 items-center">
      <app-dropdown id="countries" label="Country" class="col-span-1" [options]="countries"
        (emitOnChangeSelect)="loadCountryHolidays($event)" [control]="countryControl"
        placeholder="Select country"></app-dropdown>

      <app-dropdown id="months" placeholder="Select month" label="Month" class="col-span-1" [options]="monthOption"
        (emitOnChangeSelect)="changeMonth($event)">
      </app-dropdown>

      <tertiary-btn id="all-holidays" class="col-span-1" btnText="See all holidays"
        *ngIf="showCalendar;else showAllDatesBtn" (clickEmitter)="toggleCalendar()"></tertiary-btn>

      <ng-template #showAllDatesBtn>
        <tertiary-btn id="see-calendar" class="col-span-1" btnText="See calendar"
          (clickEmitter)="toggleCalendar()"></tertiary-btn>
      </ng-template>
    </div>

    <primary-btn id="add-holiday" btnText="Add new holiday" (clickEmitter)="openAddCountryHolidayModal()">
    </primary-btn>
  </div>

  <!-- calendar -->
  <div *ngIf="holidays.length > 0;else noResults">
    <div class="bg-white rounded" *ngIf="showCalendar;else allDates">
      <div class="border-b border-white-500 px-12 xl:px-32 py-8 flex gap-16">
        <p class="font-bold text-xl min-w-[10rem]">{{monthList[monthNo]}} {{year}}</p>

        <div class="flex gap-4 text-2xl">
          <fa-icon id="prev-month-btn" data-cy="prev-month-btn" [icon]="['fal','square-chevron-left']"
            class="cursor-pointer" (click)="previousMonth()"></fa-icon>

          <fa-icon id="next-month-btn" data-cy="next-month-btn" [icon]="['fal','square-chevron-right']"
            class="cursor-pointer" (click)="nextMonth()"></fa-icon>
        </div>
      </div>

      <div class="grid grid-cols-7 px-12 xl:px-32 pb-16">
        <div class="col-span-1 py-9 px-3 xl:px-6">Su</div>
        <div class="col-span-1 py-9 px-3 xl:px-6">Mo</div>
        <div class="col-span-1 py-9 px-3 xl:px-6">Tu</div>
        <div class="col-span-1 py-9 px-3 xl:px-6">We</div>
        <div class="col-span-1 py-9 px-3 xl:px-6">Th</div>
        <div class="col-span-1 py-9 px-3 xl:px-6">Fr</div>
        <div class="col-span-1 py-9 px-3 xl:px-6">Sa</div>

        <div class="col-span-1 p-3 xl:p-6 border min-h-[8.75rem] border-white-500 font-bold text-xl"
          *ngFor="let day of days" [ngClass]="{'bg-white-500' : day.isToday}" [id]="day.day">
          {{day.day}}

          <div *ngIf="day.isToday" class="text-white bg-blue-100 py-1 px-3 text-sm w-fit font-medium rounded">
            <p>Today</p>
          </div>

          <div *ngIf="day.isHoliday" class="bg-blue-400 py-1 px-3 text-sm w-fit font-medium rounded">
            <p>Holiday</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #allDates>
    <div class="bg-white rounded grid grid-cols-2 p-9">
      <div class="col-span-1 flex flex-col gap-6">
        <div>
          <p class="text-xl font-bold">All Holidays</p>
        </div>
        <div *ngFor="let holiday of holidays;let i = index"
          class="flex justify-between items-center border-b border-white-500 pb-4">
          <p>{{holiday.date | date:'MMMM d'}}</p>

          <fa-icon [id]="'remove-holiday-' + i + '-btn'" [attr.data-cy]="'remove-holiday-' + i + '-btn'"
            class="text-red cursor-pointer" matTooltip="Remove holiday" matTooltipPosition="above"
            [icon]="['fal','trash-can']" (click)="deleteHoliday(holiday.id!)">
          </fa-icon>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #noResults>
  <div class="p-12">
    <div class="h-48 flex">
      <img class="object-contain mx-auto relative -left-12" src="./assets/background-photos/no-results.png" alt="">
    </div>
    <div class="text-center">
      <p class="text-2xl mt-3">No results available.</p>
      <p class="text-sm text-white-600 mt-3">Please select a country or change the current one.</p>
    </div>
  </div>
</ng-template>
