import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Order, OrderCar } from 'src/app/core/models/order.model';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { OrdersService } from 'src/app/core/services/orders.service';

@Component({
  selector: 'app-order-view-kam',
  templateUrl: './order-view-kam.component.html',
  styleUrls: ['./order-view-kam.component.scss']
})
export class OrderViewKamComponent implements OnInit {
  loading = new BehaviorSubject(true);

  routes = [
    {
      viewValue: "Order settings",
      value: "order",
    },
    // {
    //   viewValue: "Chat",
    //   value: "chat",
    // },
    // {
    //   viewValue: "Claims",
    //   value: "claims",
    // }
  ];

  page = this.routes[0].value;

  cars: OrderCar[] = [];

  tasks = [...Array(20).keys()];

  order: Order | undefined;

  constructor(private orderService: OrdersService,
    private orderFlowSyncService: OrdersFlowSyncService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params['orderId']) {
        this.orderService.getOrder(params['orderId']).subscribe(resp => {
          this.order = resp;

          this.orderFlowSyncService.order = resp;

          let cars = resp.sellers!.reduce((arr: any, seller) => {
            return arr.concat(seller.cars!)
          }, []);

          cars.forEach((c: OrderCar) => {
            let seller = resp.sellers?.find(s => s.cars?.find(car => car.carMainInfoId === c.carMainInfoId));

            c.carConfig.sellerName = seller?.details?.name!;
            // c.vatType = seller?.details?.vatStatus ? 'Incl nonrefundable VAT' : 'Ex VAT';
            // c.sellerId = seller?.details?.sellerId;
            // c.currency = seller?.details?.currency;
            // c.dhlFee = resp.dhlValue;
          });

          this.cars = cars;

          this.loading.next(false);
        })
      }
    })
  }

  changePage(page: string) {
    this.page = page;
  }
}
