import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CurrenciesToEuro, OrderCar } from 'src/app/core/models/order.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

export interface OrderProcessCarPricesModalData {
  car: OrderCar,
  currencies: CurrenciesToEuro[],
  transportC2C: boolean,
  finishedOrder: boolean
}

@Component({
  selector: 'app-order-process-car-prices-modal',
  templateUrl: './order-process-car-prices-modal.component.html',
  styleUrls: ['./order-process-car-prices-modal.component.scss']
})

export class OrderProcessCarPricesModalComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  offerWarningUnderstood = false;

  finishedOrder = this.data.finishedOrder;

  car: OrderCar = JSON.parse(JSON.stringify(this.data.car));

  invoicePriceControl = new FormControl(this.car.carPricing.invoiceSellingPrice, Validators.required);
  // showroomReadyControl = new FormControl(this.car.showRoomReady);

  reqCOCControl = new FormControl<boolean>(this.car.documentsDetails.documents.coc.required);

  cocBySeller = new FormControl<boolean>({ value: this.car.documentsDetails.documents.coc.cocBySeller, disabled: !this.reqCOCControl.value! });
  cocValueControl = new FormControl({ value: this.car.documentsDetails.documents.coc.cocValue, disabled: this.cocBySeller.value! || !this.reqCOCControl.value! });
  cocFromValue = new FormControl<string>({ value: this.car.documentsDetails.documents.coc.cocFrom, disabled: this.cocBySeller.value! || !this.reqCOCControl.value! });

  reqPhotoEditControl = new FormControl(this.car.photoEditing.photoEdit);
  photoEditValueControl = new FormControl({ value: this.car.photoEditing.photoEditActualValue, disabled: !this.reqPhotoEditControl.value! });

  finalDamageControl = new FormControl(this.car.carConfig.actualDamages);

  transportControl = new FormControl({ value: this.data.transportC2C, disabled: true });

  currencies = this.data.currencies.map(c => ({ viewValue: c.currency, value: c.currency }));
  currencyControl = new FormControl(this.car.carPricing.currency);

  rateControl = new FormControl(this.car.exchangeRate, Validators.required);

  buyingPriceControl = new FormControl<any>(this.car.carPricing.actualBuyingPrice);

  constructor(public dialogRef: MatDialogRef<OrderProcessCarPricesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderProcessCarPricesModalData,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.subscriptions.add(this.buyingPriceControl.valueChanges.subscribe(value => {
      this.car.carPricing.actualBuyingPriceEuro = this.rateControl.value! * (typeof (value) === 'string' ? parseInt(value.split(',').join('')) : value);
      this.car.carPricing.actualBuyingPrice = typeof (value) === 'string' ? parseInt(value.split(',').join('')) : value;
    }));

    this.subscriptions.add(this.rateControl.valueChanges.subscribe((value: any) => {
      if (!isNaN(value)) {
        this.car.carPricing.actualBuyingPriceEuro = this.buyingPriceControl.value! * value!;
      }
    }));

    if (!this.car.carPricing.actualBuyingPriceEuro) this.car.carPricing.actualBuyingPriceEuro = 0;

    if (this.finishedOrder) {
      this.invoicePriceControl.disable();
      this.reqCOCControl.disable();
      this.cocValueControl.disable();
      this.cocBySeller.disable();
      this.cocFromValue.disable();
      this.reqPhotoEditControl.disable();
      this.photoEditValueControl.disable();
      this.finalDamageControl.disable();
      this.currencyControl.disable();
      this.rateControl.disable();
      this.buyingPriceControl.disable();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  update() {
    if (this.invoicePriceControl.touched && !this.offerWarningUnderstood && this.car.carPricing.offerDetails.offeredValue && this.car.carPricing.offerDetails.offeredValue > 0) {
      this.snackbar.negativeSentiment('Offer available, read warning shown above');
      return;
    }

    if (this.invoicePriceControl.invalid || this.rateControl.invalid) {
      this.snackbar.negativeSentiment('Invoice selling price or exchange rate invalid');
      return;
    }

    let priceSettings = {
      photoEditActualValue: this.photoEditValueControl.value,
      actualBuyingPrice: parseInt(this.car.carPricing.actualBuyingPrice!.toString()),
      actualBuyingPriceEuro: parseInt(this.car.carPricing.actualBuyingPriceEuro!.toString()),
      invoiceSellingPrice: this.invoicePriceControl.value,
      actualDamages: this.finalDamageControl.value ? this.finalDamageControl.value : 0,
      rate: this.rateControl.value,
      currency: this.currencyControl.value,
      cocValue: this.cocValueControl.value ? this.cocValueControl.value : 0,
      cocFrom: this.cocFromValue.value ? this.cocFromValue.value : '',
      cocBySeller: this.cocBySeller.value,
      notes: ''
    }

    this.dialogRef.close(priceSettings);
  }

  close() {
    this.dialogRef.close();
  }

  calculateProfit(): number {
    return this.invoicePriceControl.value!
      - this.car.carPricing.actualBuyingPriceEuro
      - this.cocValueControl.value!
      + (this.car.photoEditing.photoEditValue - this.photoEditValueControl.value!)
      + (this.transportControl.value ? (this.car.carPricing.transportationFee - this.car.carPricing.transportationActualFee) : 0);
  }

  togglePhotoEdit(check: boolean) {
    check ? this.photoEditValueControl.enable() : this.photoEditValueControl.disable();
  }

  toggleCOCBySeller(check: boolean) {
    if (check) {
      this.cocFromValue.setValue(this.car.carConfig.sellerName);
      this.cocValueControl.setValue(0);

      this.cocFromValue.disable();
      this.cocValueControl.disable();
    } else {
      this.cocFromValue.setValue('');
      this.cocValueControl.setValue(0);

      this.cocFromValue.enable();
      this.cocValueControl.enable();
    }
  }

  getExchangeRate() {
    this.rateControl.setValue(this.data.currencies.find(c => c.currency === this.currencyControl.value!)!.rate);
  }
}
