import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOptionSelectionChange } from '@angular/material/core';

export interface DropdownOption {
  value: string,
  viewValue: string
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './app-dropdown.component.html',
  styleUrls: ['./app-dropdown.component.scss']
})
export class AppDropdownComponent implements OnInit {
  @Input() options: DropdownOption[] = [];
  @Input() control: FormControl = new FormControl();

  @Input() label: string = "";
  @Input() placeholder: string = '';
  @Input() id: string = 'id';

  @Input() multipleSelect: boolean = false;
  @Input() disabled: boolean = false;
  @Input() resetValue: boolean = false;
  @Input() selectAll: boolean = false;

  @Output() emitOnChangeSelect: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() { }

  emitChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) this.emitOnChangeSelect.emit(event.source.value);
  }

  isChecked(): boolean {
    return this.control.value && this.control.value && this.options.length
      && this.control.value.length === this.options.length;
  }

  isIndeterminate(): boolean {
    return this.control.value && this.control.value.length > 0
      && this.control.value.length < this.options.length;
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.control.patchValue(this.options.map(o => o.value));
    } else {
      this.control.patchValue([]);
    }
  }
}
