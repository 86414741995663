import { Component, OnInit } from '@angular/core';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-request-car',
  templateUrl: './request-car.component.html',
  styleUrls: ['./request-car.component.scss']
})
export class RequestCarComponent implements OnInit {
  headers: TableHeaderMap[] = [
    {
      value: 'country',
      tableView: 'Country'
    },
    {
      value: 'seller',
      tableView: 'Seller name'
    },
    {
      value: 'carsBought',
      tableView: 'Cars bought 90 days'
    },
    {
      value: 'type',
      tableView: 'type'
    },
  ];

  displayedColumns = this.headers.map(h => h.value);

  sellers = [{
    country: 'Germany',
    seller: 'Auto Haus Halle',
    carsBought: 400,
    type: 'B2B ready'
  }]

  constructor() { }

  ngOnInit(): void {
  }

}
