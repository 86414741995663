import { Component, OnInit } from '@angular/core';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { Location } from '@angular/common';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-b2b-seller',
  templateUrl: './b2b-seller.component.html',
  styleUrls: ['./b2b-seller.component.scss'],
  providers: [B2BSellerSyncService]
})

export class B2bSellerComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  public loadingInfo = this.b2bSyncService.loadingInfo;

  routes = [
    {
      viewValue: "Main company",
      value: "company",
      disabled: new BehaviorSubject(false)
    },
    {
      viewValue: "Stores",
      value: "stores",
      disabled: new BehaviorSubject(true)
    },
    {
      viewValue: "Users",
      value: "users",
      disabled: new BehaviorSubject(true)
    },
    {
      viewValue: "Contacts",
      value: "contacts",
      disabled: new BehaviorSubject(true)
    },
    {
      viewValue: "Pickups",
      value: "restrictions",
      disabled: new BehaviorSubject(true)
    }
  ]

  page = this.routes[0].value;


  constructor(private b2bSyncService: B2BSellerSyncService,
    private location: Location) { }

  ngOnInit() {
    this.subscriptions.add(
      this.b2bSyncService.currentTab$.subscribe(
        (page) => {
          if (page != 'init') {
            let route = this.routes.find((r: { value: string; }) => r.value === page)!;

            route.disabled!.next(false);

            this.page = route.value;

            this.location.replaceState(`create-b2b-seller`);
          }
        }
      ));

    this.b2bSyncService.loadInfo();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  navigateTo(page: string) {
    this.b2bSyncService.changeTab(page);
  }

  saveForm(event: MouseEvent) {
    this.b2bSyncService.emitSaveEvent(event);
  }
}
