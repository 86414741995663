import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { PublishSellerOfferCarBody, SellerOffer, SellerOfferStatus } from 'src/app/core/models/offer.model';
import { OffersService } from 'src/app/core/services/offers.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { PublishCarModalComponent } from './publish-car-modal/publish-car-modal.component';
import { CarService } from 'src/app/core/services/car.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-seller-offer',
  templateUrl: './seller-offer.component.html',
  styleUrls: ['./seller-offer.component.scss']
})
export class SellerOfferComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);
  offer: SellerOffer | undefined;

  routes = [
    {
      viewValue: "Offer details",
      value: "details",
    },
    {
      viewValue: "Business case",
      value: "bc",
    },
    {
      viewValue: "Technical specs",
      value: "techSpecs",
    }
    // {
    //   viewValue: "Equipment",
    //   value: "equipment",
    // },
    // {
    //   viewValue: "Photos",
    //   value: "photos",
    // }
  ];

  page = this.routes[0].value;

  sellerOfferStatus = SellerOfferStatus;

  carGroups: DropdownOption[] = [];

  constructor(private activedRoute: ActivatedRoute,
    private offerService: OffersService,
    private spinner: SpinnerHandlerService,
    private dialog: MatDialog,
    private carService: CarService,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.activedRoute.params.subscribe(params => {
      if (params['offerId']) {
        forkJoin({
          offer: this.offerService.getSellerOffer(params['offerId']),
          groups: this.carService.getCarGroups()
        }).subscribe({
          next: resp => {
            this.offer = resp.offer;
            this.carGroups = resp.groups.map(g => ({ viewValue: g.name, value: g.id }));

            this.loading.next(false);
          },
          error: err => {

          }
        })
      }
    });
  }

  changePage(event: string) {
    if (this.spinner.showProgressBar.value || this.loading.value) return;

    this.page = event;
  }

  changeStatus(offer: SellerOffer) {
    this.offer = offer;
  }

  publishCar() {
    if (this.spinner.showProgressBar.value) return;

    let dialogRef = this.dialog.open(
      PublishCarModalComponent,
      {
        width: '800px',
        maxWidth: '80vw',
        data: {
          groups: this.carGroups
        }
      }
    );

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        let body: PublishSellerOfferCarBody = {
          carGroupJourneys: [{
            dateFrom: resp.dateFrom,
            dateTo: resp.dateTo,
            group: resp.group,
            sellingPrice: resp.price
          }],
          minSellingPrice: resp.minSellingPrice
        }


        this.spinner.showProgressBar.next(true);

        this.offerService.publishSellerOfferCar(this.offer!.id, body).subscribe({
          next: value => {
            this.spinner.showProgressBar.next(false);

            this.offer = value;
          },
          error: err => {
            this.spinner.showProgressBar.next(false);

            this.snackbar.negativeSentiment(err.error);
          }
        })
      }
    })
  }
}
