<div class="flex flex-col gap-9">
  <div class="flex justify-between">
    <div class="flex gap-6">
      <primary-btn id="vin-check" [blueBtn]="true" (clickEmitter)="vinCheck()">VIN check all cars</primary-btn>

      <secondary-btn id="get-vin-details" *ngIf="hasBatchId()" (clickEmitter)="getVINDetails()">Get VIN check
        details</secondary-btn>
    </div>

    <div class="flex gap-6">
      <secondary-btn id="send-admin-to-inspect">Send admin to inspect</secondary-btn>
    </div>
  </div>

  <div *ngIf="!(loading | async);else placeholder">
    <app-table (objectEmitter)="openDetailsModal($event)" #carsTable [data]="cars" [headersMap]="headers"
      [displayedColumns]="displayedColumns"></app-table>
  </div>
</div>

<ng-template #placeholder>
  <app-table-placeholder></app-table-placeholder>
</ng-template>
