import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { CarDetails } from 'src/app/core/models/car.model';
import { InternalShopService } from 'src/app/core/services/internal-shop.service';
import { CarsFilterComponent } from '../cars-inventory/cars-filter/cars-filter.component';
import { CoreService } from 'src/app/core/services/core.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { FormControl } from '@angular/forms';

export interface Filter {
  group: string,
  groupValue: string,
  name: string,
  isChecked: boolean,
  extraValue?: string
}

export interface FiltersList {
  fuelType: Filter[],
  gearbox: Filter[],
  countries: Filter[],
  bodyType: Filter[],
  carGroup: Filter[],
  category: Filter[],
  carStatus: Filter[],
  sellers: Filter[]
}

@Component({
  selector: 'app-internal-shop',
  templateUrl: './internal-shop.component.html',
  styleUrls: ['./internal-shop.component.scss'],
  providers: [InternalShopService]
})
export class InternalShopComponent implements OnInit {
  loading = this.internalShopService.loading;
  loadingCars = this.internalShopService.loadingCars;

  private subscriptions: Subscription = new Subscription();

  public filtersSelected: Filter[] = [];

  cars: CarDetails[] = [];

  results = 0;

  bcCountry: DropdownOption[] = this.coreService.countriesDropdown;

  bcCountryControl = new FormControl(this.coreService.countries.find(c => c.iso === 'DE')?.id);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('carsFilter') carsFilter: CarsFilterComponent | undefined;

  constructor(private internalShopService: InternalShopService,
    private coreService: CoreService) { }

  ngOnInit(): void {
    this.subscriptions.add(this.internalShopService.carSubjectEvent$.subscribe(resp => {
      this.cars = resp.cars;

      if (resp.pageReset && this.paginator) this.paginator.pageIndex = 0;

      this.results = resp.nrOfCars;
    }));

    this.internalShopService.bcCountry = this.bcCountryControl.value!;

    this.subscriptions.add(this.internalShopService.filtersSelectedEvent$.subscribe(resp => this.filtersSelected = resp));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  pageChange(event: PageEvent) {
    this.internalShopService.changePage(event.pageIndex);
  }

  removeFilter(filter: Filter) {
    this.carsFilter?.removeFilter(filter);
  }

  emitCountryChange(country: string) {
    this.internalShopService.changeBcCountry(country);
  }

  selectAll(event: MatCheckboxChange) {
    // this.filteredCars = this.filteredCars.map(c => ({ ...c, isSelected: event.checked }));
  }

  exportCars() {
    // console.log(this.filteredCars[0])
    // let wsHeaders = this.excelMap.map(t => this.utilsService.capitalizeFirstLetter(t.excelValue));
    // wsHeaders.push('Errors');

    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    // XLSX.utils.sheet_add_aoa(ws, [wsHeaders]);

    // let rows: string[][] = []

    // this.filteredCars.filter(c => c.isSelected).forEach((c: any) => {
    //   let row: string[] = [];

    //   excelMap.forEach(m => {
    //     row.push(c[m.objectValue] ? c[m.objectValue] : '');
    //   })

    //   row.push(c.rejectReason.join(", "));
    //   rows.push(row);
    // })

    // XLSX.utils.sheet_add_aoa(ws, rows, { origin: 'A2' });

    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // XLSX.writeFile(wb, 'declined-cars.xlsx');
  }
}
