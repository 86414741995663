import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedFontAwesomeModule } from './shared/font-awesome.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PendingChangesGuard } from './core/guards/can-deactivate-component.guard';
import { CarViewModule } from './features/car-view/car-view.module';
import { InternalShopModule } from './features/internal-shop/internal-shop.module';
import { SettingsModule } from './features/settings/settings.module';
import { CoreService } from './core/services/core.service';
import { SellerModule } from './features/seller/seller.module';
import { CarsInventoryModule } from './features/cars-inventory/cars-inventory.module';
import { AuthGuardMaintenance } from './core/guards/auth.guard';
import { AuthInterceptorService } from './core/interceptors/auth-token.interceptor';
import { OrdersModule } from './features/orders/orders.module';
import { OffersModule } from './features/offers/offers.module';
import { CreateBuyerModule } from './features/buyer/create-buyer/create-buyer.module';
import { ViewBuyerModule } from './features/buyer/view-buyer/view-buyer.module';
import { RequestCarModule } from './features/request-car/request-car.module';
import { ViewBuyersModule } from './features/buyer/view-buyers/view-buyers.module';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';
import { SellYourCarModule } from './features/sell-your-car/sell-your-car.module';
import { RestrictedPageComponent } from './features/restricted-page/restricted-page.component';
import { LoginComponent } from './features/login/login.component';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MSAL_INSTANCE, MsalBroadcastService, MsalModule, MsalService } from '@azure/msal-angular';
import { HomePageComponent } from './features/home-page/home-page.component';
import { NotFoundComponent } from './features/not-found/not-found.component';

export function coreServiceFactory(coreService: CoreService): Function {
  return () => coreService.init();
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.MSAL_CLIENT_ID,
      redirectUri: environment.MSAL_LOGIN_REDIRECT_URI,
      authority: environment.MSAL_AUTHORITY,
      postLogoutRedirectUri: environment.MSAL_LOGOUT_REDIRECT_URI,
      knownAuthorities: [environment.MSAL_KNOWN_AUTHORITIES],
    },
    cache: {
      cacheLocation: 'localStorage'
    }
  })
}

@NgModule({
  declarations: [
    AppComponent,
    RestrictedPageComponent,
    NotFoundComponent,
    LoginComponent,
    HomePageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedFontAwesomeModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CarViewModule,
    InternalShopModule,
    SettingsModule,
    SellerModule,
    CreateBuyerModule,
    CarsInventoryModule,
    OrdersModule,
    OffersModule,
    ViewBuyerModule,
    ViewBuyersModule,
    RequestCarModule,
    SellYourCarModule,
    MsalModule
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5500 } },
    PendingChangesGuard,
    AuthGuardMaintenance,
    CoreService,
    {
      provide: APP_INITIALIZER,
      useFactory: coreServiceFactory,
      multi: true,
      deps: [CoreService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: OrderTaskInterceptor,
    //   multi: true
    // },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
