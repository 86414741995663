import moment from "moment";
import { BuyerUser, BuyerUserClassDTO, KAMUser, User } from "./user.model";

export interface Buyer {
  address: string;
  city: string;
  countryDetails: {
    country: string,
    countryId: string,
    iso: string
  }
  companyWebsite: string,
  createdAt: Date;
  dealerC2V: string,
  email: string;
  eventsDetails: BuyerCompanyEvent[],
  makes: string[],
  name: string;
  postalCode: string;
  phone: string;
  priorityDetails: {
    priority: string,
    priorityId: string
  };
  status: boolean;
  modifiedAt: Date;
  vatDetails: {
    vat: string,
    vatStatus: boolean,
    vatValidationDate: Date
  }
  statusTable?: string,
  users?: User[],
  id?: string,
}

export class BuyerClass implements Buyer {
  address: string;
  city: string;
  companyWebsite: string;
  createdAt: Date;
  dealerC2V: string;
  email: string;
  eventsDetails: BuyerCompanyEvent[];
  makes: string[];
  name: string;
  postalCode: string;
  phone: string;
  priorityDetails: {
    priority: string;
    priorityId: string;
  };
  status: boolean;
  modifiedAt: Date;
  vatDetails: {
    vat: string;
    vatStatus: boolean;
    vatValidationDate: Date;
  };
  countryDetails: {
    country: string,
    countryId: string,
    iso: string
  };

  constructor(buyerForm: any, country: string, vat: string, vatStatus: boolean) {
    this.address = buyerForm.address;
    this.city = buyerForm.city;
    this.countryDetails = {
      countryId: country,
      country: '',
      iso: ''
    }
    this.companyWebsite = buyerForm.website;
    this.createdAt = new Date();
    this.dealerC2V = buyerForm.cars2viewDealer;
    this.email = buyerForm.email;
    this.eventsDetails = [];
    this.makes = buyerForm.make;
    this.name = buyerForm.name
    this.postalCode = buyerForm.postalCode;
    this.phone = buyerForm.phone;
    this.priorityDetails = {
      priority: '',
      priorityId: buyerForm.priority
    }
    this.status = true;
    this.modifiedAt = new Date();
    this.vatDetails = {
      vat: vat,
      vatStatus: vatStatus,
      vatValidationDate: new Date()
    }
  }
}

export interface BuyerStore {
  address: string;
  carSalesEst: number;
  city: string;
  countryBank: boolean;
  contacts: BuyerStoreContact[],
  countryDetails: {
    country: string,
    countryId: string,
    iso: string
  };
  createdAt: string;
  defaultStore: boolean;
  deliveries: BuyerStoreDeliveryRestriction[]
  id: string;
  makes: string[];
  modifiedAt: string;
  name: string;
  note: string;
  postalCode: string;
  status: boolean;
  tempId?: string;
}

export class BuyerStoreClass implements BuyerStore {
  address: string;
  carSalesEst: number;
  city: string;
  countryBank: boolean;
  contacts: BuyerStoreContact[];
  countryDetails: { country: string; countryId: string; iso: string; };
  createdAt: string;
  defaultStore: boolean;
  deliveries: BuyerStoreDeliveryRestriction[];
  id: string;
  makes: string[];
  modifiedAt: string;
  name: string;
  note: string;
  postalCode: string;
  status: boolean;
  tempId?: string | undefined;
  users?: { contactFlag: boolean; contactRoleDetails: { accEmailFlag: boolean; carDeliveryEmailFlag: boolean; documentEmailFlag: boolean; }; firstName: string; lastName: string; email: string; phoneNumber: string; country: string; }[] | undefined;

  constructor(storeForm: any, country: string, notes: string) {
    this.address = storeForm.address;
    this.carSalesEst = storeForm.carSalesEst;
    this.city = storeForm.city;
    this.countryBank = storeForm.countryBank;
    this.contacts = [];
    this.countryDetails = {
      country: '',
      countryId: country,
      iso: ''
    };
    this.createdAt = '';
    this.defaultStore = false;
    this.deliveries = []
    this.id = '';
    this.makes = storeForm.make;
    this.modifiedAt = '';
    this.name = storeForm.storeName
    this.note = notes;
    this.postalCode = storeForm.postalCode;
    this.status = true;
    this.tempId = storeForm.tempId;
    this.users = [];
  }
}

export interface BuyerStoreContact {
  accountingEmail: boolean,
  carDeliveryEmail: boolean,
  documentsEmail: boolean,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
}

export class BuyerStoreContactClass {
  tempId?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  phone?: string;
  store?: string;
  accountingEmail?: boolean;
  carDeliveryEmail?: boolean;
  documentsEmail?: boolean;

  constructor(contactForm: any) {
    this.tempId = contactForm.tempId
    this.firstName = contactForm.firstName
    this.lastName = contactForm.lastName
    this.fullName = contactForm.fullName
    this.email = contactForm.email
    this.phone = contactForm.phone
    this.store = contactForm.store
    this.accountingEmail = contactForm.accountingEmail
    this.carDeliveryEmail = contactForm.carDeliveryEmail
    this.documentsEmail = contactForm.documentsEmail
  }
}

export interface BuyerStoreDeliveryRestriction {
  note: string,
  from: string,
  to: string,
  store?: string,
  period: {
    period: string,
    periodId: string
  }
}

export interface BuyerMakes {
  make: string[],
  company: string
}

export interface BuyerStoreMakes {
  makes: string[],
  store: string
}

export interface LoggedKAMBuyer {
  buyerId: string,
  name: string
}

export interface BuyerCompanyEvent {
  title: string,
  description: string,
  date: string
}

export interface ReplaceBuyerKAMBody {
  kamId: string,
  oldKamId: string,
  buyer: string
}

export interface BuyerResponse {
  buyerCompanyDetails: Buyer,
  companyId: string,
  kamDetails: KAMUser,
  stores: BuyerStore[],
  users: BuyerUser[]
}

//Interfaces for create full buyer
export class CreateBuyerObjectClass {
  kamId: string;
  countryId: string;
  priorityId: string;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  phone: string;
  email: string;
  dealerC2V: string;
  vat: string;
  vatStatus: boolean;
  companyEvents: BuyerCompanyEvent[];
  makes: string[];
  stores: CreateBuyerStoreObjectClass[];
  companyWebsite: string;

  constructor(buyer: Buyer, kamId: string) {
    this.kamId = kamId;
    this.vat = buyer.vatDetails.vat;
    this.vatStatus = buyer.vatDetails.vatStatus;
    this.name = buyer.name;
    this.priorityId = buyer.priorityDetails.priorityId;
    this.address = buyer.address;
    this.city = buyer.city;
    this.countryId = buyer.countryDetails.countryId;
    this.postalCode = buyer.postalCode;
    this.phone = buyer.phone ? buyer.phone : '';
    this.email = buyer.email ? buyer.email : '';
    this.makes = buyer.makes;
    this.companyWebsite = buyer.companyWebsite;
    this.dealerC2V = '';
    this.companyEvents = [{ title: 'Buyer creation', description: 'Buyer was created', date: moment().utc().format('yyyy-MM-DD') }];
    this.stores = [];
  }
}

export interface CreateFullBuyerResponse {
  buyerId: string,
  faultyUsers: any[],
  alreadyExistingUsers: any[]
}

export class CreateBuyerStoreObjectClass {
  name: string;
  countryId: string;
  city: string;
  address: string;
  postalCode: string;
  carSalesEst: number;
  countryBank: boolean;
  note: string;
  defaultStore: boolean;
  makes: string[];
  tempId?: string;
  users: CreateBuyerUserObject[];
  contacts: CreateBuyerContactObject[];
  deliveries: CreateBuyerDeliveryObject[];

  constructor(store: BuyerStore) {
    this.name = store.name;
    this.countryId = store.countryDetails.countryId;
    this.city = store.city;
    this.address = store.address;
    this.postalCode = store.postalCode;
    this.carSalesEst = store.carSalesEst;
    this.countryBank = store.countryBank;
    this.note = store.note;
    this.defaultStore = false;
    this.makes = store.makes!;
    this.tempId = store.tempId;
    this.users = [];
    this.contacts = [];
    this.deliveries = [];
  }
}

export interface CreateBuyerUserObject {
  contactFlag: boolean,
  contactRoleDetails: {
    accEmailFlag: boolean,
    carDeliveryEmailFlag: boolean,
    documentEmailFlag: boolean
  },
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  countryId: string,
  closedSalesGroups: string[],
}

export class CreateBuyerUserObjectClass implements CreateBuyerUserObject {
  contactFlag: boolean;
  contactRoleDetails: { accEmailFlag: boolean; carDeliveryEmailFlag: boolean; documentEmailFlag: boolean; };
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryId: string;
  closedSalesGroups: string[];

  constructor(u: BuyerUserClassDTO, contactFlag: boolean, accEmailFlag: boolean, carDeliveryEmailFlag: boolean, documentEmailFlag: boolean) {
    this.firstName = u.firstName;
    this.lastName = u.lastName;
    this.email = u.email;
    this.phoneNumber = u.phoneNumber ? u.phoneNumber : '';
    this.countryId = u.countryId;
    this.contactFlag = contactFlag;
    this.contactRoleDetails = {
      accEmailFlag: accEmailFlag ? accEmailFlag : false,
      carDeliveryEmailFlag: carDeliveryEmailFlag ? carDeliveryEmailFlag : false,
      documentEmailFlag: documentEmailFlag ? documentEmailFlag : false
    };
    this.closedSalesGroups = u.closedSalesGroups;
  }
}

export interface CreateBuyerDeliveryObject {
  note: string,
  from: string,
  to: string,
  periodId: string,
}

export class CreateBuyerDeliveryObjectClass implements CreateBuyerDeliveryObject {
  note: string;
  from: string;
  to: string;
  periodId: string;

  constructor(delivery: Required<BuyerStoreDeliveryRestriction>) {
    this.note = delivery.note;
    this.from = delivery.from;
    this.to = delivery.to;
    this.periodId = delivery.period.periodId;
  }
}

export interface CreateBuyerContactObject {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  accountingEmail: boolean,
  carDeliveryEmail: boolean,
  documentsEmail: boolean
}
export class CreateBuyerContactObjectClass implements CreateBuyerContactObject {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountingEmail: boolean;
  carDeliveryEmail: boolean;
  documentsEmail: boolean;

  constructor(contact: Required<BuyerStoreContact>) {
    this.firstName = contact.firstName;
    this.lastName = contact.lastName;
    this.email = contact.email;
    this.phone = contact.phone ? contact.phone : '';
    this.accountingEmail = contact.accountingEmail ? contact.accountingEmail : false;
    this.carDeliveryEmail = contact.carDeliveryEmail ? contact.carDeliveryEmail : false;
    this.documentsEmail = contact.documentsEmail ? contact.documentsEmail : false;
  }
}

//Classes for buyer create entities
export class CreateBuyerStoreBody {
  companyId: string;
  name: string;
  countryId: string;
  city: string;
  address: string;
  postalCode: string;
  carSalesEst: number;
  countryBank: boolean;
  note: string;
  defaultStore: boolean;
  makes: string[];

  constructor(store: any, companyId: string, note: string, countryId: string) {
    this.companyId = companyId;
    this.name = store.name;
    this.countryId = countryId;
    this.city = store.city;
    this.address = store.address;
    this.postalCode = store.postalCode;
    this.carSalesEst = store.carSalesEst;
    this.countryBank = store.countryBank;
    this.note = note;
    this.defaultStore = false;
    this.makes = store.makes!;
  }
}

export class CreateBuyerUserBody {
  companyId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  countryId: string;
  closedSalesGroups: string[];
  stores: {
    id: string,
    contactFlag: boolean,
    contactRoleDetails: {
      accEmailFlag: boolean,
      carDeliveryEmailFlag: boolean,
      documentEmailFlag: boolean,
    }
  }[];

  constructor(buyerId: string, userForm: any, stores: any, email: string) {
    this.companyId = buyerId;
    this.firstName = userForm.firstName;
    this.lastName = userForm.lastName;
    this.phoneNumber = userForm.phoneNumber ? userForm.phoneNumber : '';
    this.countryId = userForm.countryId;
    this.closedSalesGroups = userForm.closedSalesGroups ? userForm.closedSalesGroups : [];
    this.stores = stores;
    this.email = email;
  }
}

export class CreateBuyerContactBody {
  companyId: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountingEmail: boolean;
  carDeliveryEmail: boolean;
  documentsEmail: boolean;

  constructor(contactForm: any, companyId: string, storeId: string) {
    this.companyId = companyId;
    this.id = storeId;
    this.firstName = contactForm.firstName
    this.lastName = contactForm.lastName
    this.email = contactForm.email
    this.phone = contactForm.phone
    this.accountingEmail = contactForm.accountingEmail
    this.carDeliveryEmail = contactForm.carDeliveryEmail
    this.documentsEmail = contactForm.documentsEmail
  }
}
//Classes for buyer edit
export class EditBuyerBody {
  companyId: string;
  kamId: string;
  countryId: string;
  priorityId: string;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  phone: string;
  email: string;
  dealerC2V: string;
  vat: string;
  vatStatus: boolean;
  makes: string[];
  companyWebsite: string;
  status: boolean;

  constructor(buyerForm: any, country: string, vat: string, vatStatus: boolean, companyId: string, status: boolean) {
    this.kamId = buyerForm.kamId;
    this.companyId = companyId;
    this.address = buyerForm.address;
    this.city = buyerForm.city;
    this.countryId = country;
    this.companyWebsite = buyerForm.website;
    this.dealerC2V = buyerForm.cars2viewDealer;
    this.email = buyerForm.email;
    this.makes = buyerForm.make;
    this.name = buyerForm.name
    this.postalCode = buyerForm.postalCode;
    this.phone = buyerForm.phone;
    this.priorityId = buyerForm.priority;
    this.vat = vat;
    this.vatStatus = vatStatus;
    this.status = status;
  }
}

export class EditBuyerStoreBody {
  companyId: string;
  id: string;
  name: string;
  countryId: string;
  city: string;
  address: string;
  postalCode: string;
  carSalesEst: number;
  countryBank: boolean;
  note: string;
  defaultStore: boolean;
  makes: string[];
  status: boolean;

  constructor(storeForm: any, buyerId: string, storeId: string, countryId: string, defaultStore: boolean, note: string, status: boolean) {
    this.companyId = buyerId;
    this.id = storeId;
    this.name = storeForm.name;
    this.countryId = countryId;
    this.city = storeForm.city;
    this.address = storeForm.address;
    this.postalCode = storeForm.postalCode;
    this.carSalesEst = storeForm.carSalesEst;
    this.countryBank = storeForm.countryBank;
    this.note = note;
    this.status = status;
    this.defaultStore = defaultStore;
    this.makes = storeForm.makes;
  }
}

export class EditBuyerDeliveriesBody {
  companyId: string;
  id: string;
  deliveries: {
    note: string,
    from: string,
    to: string,
    period: {
      periodId: string,
      period: string,
    }
  }[];

  constructor(companyId: string, id: string, deliveries: any) {
    this.companyId = companyId;
    this.id = id;
    this.deliveries = deliveries;
  }
}

export class EditBuyerUserBody {
  companyId: string;
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isActive: boolean;
  countryId: string;
  closedSalesGroups: string[];
  stores: {
    id: string,
    contactFlag: boolean,
    contactRoleDetails: {
      accEmailFlag: boolean,
      carDeliveryEmailFlag: boolean,
      documentEmailFlag: boolean,
    }
  }[];

  constructor(buyerId: string, userId: string, userForm: any, stores: any, isActive: boolean) {
    this.companyId = buyerId;
    this.id = userId;
    this.firstName = userForm.firstName;
    this.lastName = userForm.lastName;
    this.phoneNumber = userForm.phoneNumber;
    this.isActive = isActive;
    this.countryId = userForm.countryId;
    this.closedSalesGroups = userForm.closedSalesGroups ? userForm.closedSalesGroups : userForm.details.closedSalesGroups;
    this.stores = stores;
  }
}

//Interfaces for buyers search
export interface BuyerSearchBody {
  page: number,
  itemsPerPage: number,
  vat?: string,
  name?: string,
  username?: string,
  country?: string,
  makes?: string,
  priority?: string,
  status?: boolean,
}

export interface BuyerSearchResp {
  companies: Buyer[],
  nrOfCompanies: number
}
