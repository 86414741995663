import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, firstValueFrom, zip } from 'rxjs';
import { CarDetails } from 'src/app/core/models/car.model';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-manage-cars-inventory',
  templateUrl: './manage-cars-inventory.component.html',
  styleUrls: ['./manage-cars-inventory.component.scss']
})
export class ManageCarsInventoryComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);
  loadingTable = new BehaviorSubject<boolean>(false);

  cars: CarDetails[] = [];

  results = 0;

  headers: TableHeaderMap[] = [
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'fuelType',
      tableView: 'fuelType'
    },
    {
      value: 'gearbox',
      tableView: 'gearbox'
    },
    {
      value: 'sellingPrice',
      tableView: 'price'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'firstReg',
      tableView: 'first registration'
    }
  ];

  displayedColumns = ['selectAll', ...this.headers.map(h => h.value)];

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private carService: CarService,
    private spinnerHandlerService: SpinnerHandlerService,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.carService.getCarFilteredStaff({ page: 0, itemsPerPage: 5 }).subscribe(resp => {
      this.cars = resp.cars;
      this.results = resp.nrOfCars;

      this.loading.next(false);
    });
  }

  deleteCars() {
    if (this.spinnerHandlerService.showProgressBar.value) return;

    let carsToDelete = this.carsTable!.dataSource.data.filter(c => c.isSelected);

    this.spinnerHandlerService.showProgressBar.next(true);

    const carsToDeletePromise: Promise<string>[] = [];

    carsToDelete.forEach(c => {
      carsToDeletePromise.push(firstValueFrom(this.carService.deleteCar(c.carMainInfoId)));
    });

    zip(carsToDeletePromise).subscribe({
      next: resp => {
        this.carService.getCarFilteredStaff({ page: 0, itemsPerPage: 5 }).subscribe(resp => {
          this.cars = resp.cars;

          this.carsTable?.updateTable(this.cars);

          this.spinnerHandlerService.showProgressBar.next(false);
        });
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinnerHandlerService.showProgressBar.next(false);
      }
    })
  }

  pageChange(event: PageEvent) {
    this.loadCars(event.pageIndex, this.paginator.pageSize);
  }

  loadCars(page: number, nrOfCars: number) {
    this.loadingTable.next(true);

    this.carService.getCarFilteredStaff({ page: page, itemsPerPage: nrOfCars }).subscribe(resp => {
      this.cars = resp.cars;

      this.results = resp.nrOfCars;

      this.loadingTable.next(false);
    });
  }
}
