<div class="twoCols">
  <app-main-content-layout>
    <div class="title">
      <p>Seller details</p>
    </div>

    <div class="grid grid-cols-4 gap-4 border-b border-white-500 pb-6 items-center">
      <app-dropdown id="country-code" class="col-span-1" [label]="'State code'" [control]="countryIsoControl"
        [options]="countryIsoCodes" *ngIf="!validatedVAT" placeholder="Pick one">
      </app-dropdown>

      <app-input id="vat" [label]="'VAT'" placeholder="Insert VAT" class="col-span-1"
        [control]="vatControl"></app-input>

      <primary-btn id="validate-vat" (clickEmitter)="validateVAT()" *ngIf="!validatedVAT"
        [disableBtn]="!vatControl.value || vatControl.value.length <= 0 || !countryIsoControl.value || countryIsoControl.value.length <= 0"
        class="col-span-1 col-start-1" [btnText]="'Validate VAT'"></primary-btn>

      <secondary-btn id="reset-vat" class="col-span-1" [btnText]="'Reset VAT'" (clickEmitter)="resetVat()"
        [disableBtn]="!vatControl.value || vatControl.value.length <= 0 ">
      </secondary-btn>

      <secondary-btn id="vat-rules" class="col-span-1 flex items-center text-blue-100" [btnText]="'VAT rules'"
        [disableBtn]="true" *ngIf="!validatedVAT">
      </secondary-btn>
    </div>

    <app-main-content-row-dropdown title="KAM" componentId="kam" placeholder="Select KAM"
      [control]="sellerDetailsForm.controls.kamId" [options]="kamUsers"></app-main-content-row-dropdown>

    <app-main-content-row-input title="Company name" [componentId]="'company-name'" placeholder="Insert company name"
      [control]="sellerDetailsForm.controls.name"></app-main-content-row-input>

    <app-main-content-row-dropdown title="Seller type" componentId="seller-type" placeholder="Select seller type"
      [control]="sellerDetailsForm.controls.sellerType" [options]="sellerTypes"></app-main-content-row-dropdown>

    <app-main-content-row-input title="Address" componentId="address" placeholder="Insert address"
      [control]="sellerDetailsForm.controls.address"></app-main-content-row-input>

    <app-main-content-row-layout>
      <p
        [ngClass]="{'text-red font-bold' : (sellerDetailsForm.controls.country.invalid && sellerDetailsForm.controls.country.touched) || ((sellerDetailsForm.controls.city.invalid && sellerDetailsForm.controls.city.touched) )}">
        Region*</p>

      <div class="secondContent twoCols">
        <app-dropdown id="countries" class="col-span-1" [options]="countries" placeholder="Select country"
          [control]="sellerDetailsForm.controls.country"></app-dropdown>
        <app-input id="city" class="col-span-1" placeholder="Insert city"
          [control]="sellerDetailsForm.controls.city"></app-input>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-input title="ZIP/Postal code" componentId="postalcode" placeholder="Insert postal code"
      [control]="sellerDetailsForm.controls.postalCode"></app-main-content-row-input>

    <app-main-content-row-layout>
      <div class="flex items-center">
        <p>Email*</p>

        <p class="relative -left-[2.9375rem] top-6 text-sm text-red opacity-0 whitespace-nowrap"
          [ngClass]="{'opacity-100':sellerDetailsForm.controls.email.hasError('email')}">Insert valid email</p>
      </div>

      <app-input id="email" class="secondContent" [control]="sellerDetailsForm.controls.email"
        placeholder="Insert email">
      </app-input>
    </app-main-content-row-layout>

    <app-main-content-row-input title="Phone" componentId="phone" placeholder="Insert phone"
      [control]="sellerDetailsForm.controls.phone"></app-main-content-row-input>

    <app-main-content-row-input title="Website" componentId="website" placeholder="Insert website"
      [control]="sellerDetailsForm.controls.website"></app-main-content-row-input>

    <app-main-content-row-dropdown title="Makes sold" componentId="makes-sold" placeholder="Select makes sold"
      [control]="sellerDetailsForm.controls.makesSold" [multipleSelect]="true" [options]="makes"
      [selectAll]="true"></app-main-content-row-dropdown>

    <app-main-content-row-dropdown title="Makes represented" componentId="makes-represented"
      placeholder="Select makes represented" [control]="sellerDetailsForm.controls.makesRep" [multipleSelect]="true"
      [options]="makes" [selectAll]="true"></app-main-content-row-dropdown>
  </app-main-content-layout>

  <div class="flex flex-col gap-6">
    <app-main-content-layout>
      <div class="title">
        <p>Seller info</p>
      </div>

      <app-main-content-row-layout>
        <p>Status</p>

        <div class="secondContent text-end">
          <mat-slide-toggle [disabled]="true" [checked]="true" labelPosition="before">
            Active
          </mat-slide-toggle>
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>VAT Status</p>
        <div class="secondContent text-end">
          <app-toggle id="vies-status" [toggleLabel]="vatStatusControl.value ? 'Active' : 'Inactive'"
            [toggleFormControl]="vatStatusControl"></app-toggle>
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Date created</p>

        <app-read-only-input class="secondContent">
          {{dateCreated | date:'dd/MM/yyyy'}}
        </app-read-only-input>
      </app-main-content-row-layout>
    </app-main-content-layout>

  </div>
</div>
