import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { OrderCar, UpdateCarPickupDetailsClass } from 'src/app/core/models/order.model';
import { OrdersService } from 'src/app/core/services/orders.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderProcessSellerCarPickupDetailsModalComponent } from './order-process-seller-car-pickup-details-modal/order-process-seller-car-pickup-details-modal.component';
import { SellerService } from 'src/app/core/services/seller.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import * as _ from 'lodash';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { ISellerResponse } from 'src/app/core/models/seller.model';
import { UserRoles } from 'src/app/core/models/user.model';
import { OrderStatus } from '../../orders-view/orders-view.component';

@Component({
  selector: 'app-order-process-seller',
  templateUrl: './order-process-seller.component.html',
  styleUrls: ['./order-process-seller.component.scss']
})
export class OrderProcessSellerComponent implements OnInit {
  loading = new BehaviorSubject(true);

  headers: TableHeaderMap[] = [
    {
      value: 'carConfig.sellerName',
      tableView: 'seller'
    },
    {
      value: 'carConfig.sellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatString',
      tableView: 'vat'
    },
    {
      value: 'carConfig.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carConfig.make',
      tableView: 'make'
    },
    {
      value: 'carConfig.model',
      tableView: 'model'
    },
    {
      value: 'carConfig.vin',
      tableView: 'vin'
    },
    {
      value: 'carReadyAt',
      tableView: 'car ready at',
      isDateSimple: true
    },
    {
      value: 'pickupDetails.location',
      tableView: 'pickup country'
    },
    {
      value: 'pickupDetails.city',
      tableView: 'pickup city'
    },
    {
      value: 'pickupDetails.address',
      tableView: 'pickup  address'
    },
    {
      value: 'pickupDetails.postalCode',
      tableView: 'pickup zipcode'
    },
    {
      value: 'pickupDetails.contactDetails.fullName',
      tableView: 'pickup contact'
    },
    {
      value: 'pickupDetails.contactDetails.email',
      tableView: 'pickup contact email'
    },
    {
      value: 'pickupDetails.contactDetails.phone',
      tableView: 'pickup contact phone'
    },
  ];

  displayedColumns = [...this.headers.map(h => h.value), 'details'];

  sellersDropdown: DropdownOption[] = [];
  sellerControl = new FormControl();
  sellers: ISellerResponse[] = this.ordersFlowService.orderSeller;
  selectedSeller: ISellerResponse | undefined;

  storeControl = new FormControl();
  stores: DropdownOption[] = [];
  contactControl = new FormControl();
  contacts: DropdownOption[] = [];

  cars = this.ordersFlowService.carList;

  finishedOrder = this.ordersFlowService.orderStatus === OrderStatus.FINISHED;

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;

  constructor(private ordersFlowService: OrdersFlowSyncService,
    private orderService: OrdersService,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private sellerService: SellerService,
    private spinnerService: SpinnerHandlerService) { }

  async ngOnInit(): Promise<void> {
    let seller = this.sellers.find(s => s.companyId === this.ordersFlowService.order!.sellers[0].details.id);

    if (seller) {
      this.selectedSeller = seller;

      this.loadStoreAndContactDropdowns();
    } else {
      let sellerResp = await firstValueFrom(this.sellerService.getSeller(this.ordersFlowService.order!.sellers[0].details.id));

      this.ordersFlowService.orderSeller.push(sellerResp);

      this.selectedSeller = sellerResp;

      this.sellers = this.ordersFlowService.orderSeller;

      this.loadStoreAndContactDropdowns();
    }

    this.sellersDropdown = this.ordersFlowService.order!.sellers!.map(s => ({ value: s.details!.id, viewValue: s.details!.name! }));

    this.sellerControl.setValue(this.sellersDropdown[0].value);
    this.selectedSeller = this.sellers[0];

    this.cars = this.ordersFlowService.order!.sellers.find(s => s.details.id === this.selectedSeller!.companyId)!.cars;

    this.setPickupContactFullName();

    this.loading.next(false);
  }

  selectSeller(sellerId: string) {
    let seller = this.sellers.find(s => s.companyId === sellerId);

    this.cars = this.ordersFlowService.order!.sellers.find(s => s.details.id === sellerId)!.cars;

    this.setPickupContactFullName()

    this.carsTable!.updateTable(this.cars);

    this.loading.next(true);

    if (seller) {
      this.selectedSeller = seller;
      this.loadStoreAndContactDropdowns();
    } else {
      this.loadSeller(sellerId);
    }
  }

  loadStoreAndContactDropdowns() {
    this.stores = this.selectedSeller!.stores.map(s => ({ value: s.id, viewValue: s.name }));
    this.storeControl.setValue(this.stores[0].value);

    this.contacts = this.selectedSeller!.users.filter(u => u.details.sellerStores.find(s => s.store === this.storeControl.value! && s.roles.includes(UserRoles.Contact))).map(u => ({ value: u.id, viewValue: `${u.firstName} ${u.lastName}` }));

    this.contacts = [...this.contacts, ...this.selectedSeller!.stores[0].contacts.map(c => ({ value: c.email!, viewValue: `${c.firstName} ${c.lastName}` }))];

    this.contactControl.setValue(this.contacts[0].value);

    this.loading.next(false);
  }

  openModal(car: OrderCar) {
    const dialogRef = this.dialog.open(
      OrderProcessSellerCarPickupDetailsModalComponent, {
      width: '900px',
      autoFocus: false,
      disableClose: !this.finishedOrder,
      data: {
        car: JSON.parse(JSON.stringify(car)),
        finishedOrder: this.finishedOrder
      },
    });

    dialogRef.afterClosed().subscribe(pickupDetails => {
      if (pickupDetails) {
        this.spinnerService.showProgressBar.next(true);

        let body = new UpdateCarPickupDetailsClass(this.ordersFlowService.order!.orderId, car.carConfig.seller, car.carMainInfoId, pickupDetails);

        this.orderService.updateCarPickupDetails(body).subscribe({
          next: sellers => {
            this.ordersFlowService.order!.sellers = sellers;

            this.ordersFlowService.updateCarList();

            this.cars = this.ordersFlowService.order!.sellers.find(s => s.details.id === car.carConfig.seller)!.cars;

            this.setPickupContactFullName();

            this.carsTable!.updateTable(this.cars);

            this.spinnerService.showProgressBar.next(false);
            this.snackbar.positiveSentiment('Car delivery details updated successfully!');
          },
          error: err => {
            this.spinnerService.showProgressBar.next(false);
            this.snackbar.negativeSentiment(err.error);
          }
        });
      }
    });
  }

  async loadSeller(sellerId: string) {
    let sellerResp = await firstValueFrom(this.sellerService.getSeller(sellerId));

    this.ordersFlowService.orderSeller.push(sellerResp);

    this.selectedSeller = sellerResp;

    this.sellers = this.ordersFlowService.orderSeller;

    this.loading.next(false);

    this.loadStoreAndContactDropdowns();
  }

  setPickupContactFullName() {
    this.cars.forEach(c => c.pickupDetails.contactDetails.fullName = `${c.pickupDetails.contactDetails.firstName} ${c.pickupDetails.contactDetails.lastName}`)
  }
}
