import { NgModule } from "@angular/core";
import { GridsterModule } from "angular-gridster2";
import { SharedModule } from "src/app/shared/shared.module";
import { CarBusinesscaseComponent } from "./car-businesscase/car-businesscase.component";
import { CarDetailsComponent } from "./car-details/car-details.component";
import { CarEquipmentComponent } from "./car-equipment/car-equipment.component";
import { CarImagesComponent } from "./car-images/car-images.component";
import { CarTransportComponent } from "./car-transport/car-transport.component";
import { CarViewComponent } from "./car-view.component";
import { CarIdentifiedModalComponent } from './car-details/car-identified-modal/car-identified-modal.component';
import { CarDocumentsComponent } from "./car-documents/car-documents.component";

@NgModule({
  imports: [
    SharedModule,
    GridsterModule
  ],
  declarations: [
    CarViewComponent,
    CarEquipmentComponent,
    CarDetailsComponent,
    CarImagesComponent,
    CarBusinesscaseComponent,
    CarTransportComponent,
    CarIdentifiedModalComponent,
    CarDocumentsComponent
  ]
})

export class CarViewModule { }
