import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderCar } from 'src/app/core/models/order.model';
import { CoreService } from 'src/app/core/services/core.service';

export interface OrderSelectCarsDeliveryDetailsModalData {
  car: OrderCar,
  country: string,
  finishedOrder: boolean
}

@Component({
  selector: 'app-order-select-cars-delivery-details-modal',
  templateUrl: './order-select-cars-delivery-details-modal.component.html',
  styleUrls: ['./order-select-cars-delivery-details-modal.component.scss']
})

export class OrderSelectCarsDeliveryDetailsModalComponent implements OnInit {
  car = this.data.car;
  country = this.data.country;
  finishedOrder = this.data.finishedOrder;

  form = this.fb.group({
    city: [this.car.deliveryDetails.city],
    address: [this.car.deliveryDetails.address],
    zipcode: [this.car.deliveryDetails.postalCode],
    firstName: [this.car.deliveryDetails.contactDetails?.firstName],
    lastName: [this.car.deliveryDetails.contactDetails?.lastName],
    email: [this.car.deliveryDetails.contactDetails?.email],
    phone: [this.car.deliveryDetails.contactDetails?.phone]
  })

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<OrderSelectCarsDeliveryDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderSelectCarsDeliveryDetailsModalData) { }

  ngOnInit(): void {
    if(this.finishedOrder) this.form.disable();
  }

  update() {
    this.car.deliveryDetails = {
      city: this.form.controls.city.value!,
      address: this.form.controls.address.value!,
      postalCode: this.form.controls.zipcode.value!,
      contactDetails: {
        firstName: this.form.controls.firstName.value!,
        lastName: this.form.controls.lastName.value!,
        email: this.form.controls.email.value!,
        phone: this.form.controls.phone.value!,
        fullName: `${this.form.controls.firstName.value!} ${this.form.controls.lastName.value!}`
      }
    }

    this.dialogRef.close(this.car.deliveryDetails);
  }

  close() {
    this.dialogRef.close();
  }
}
