import { ISellerStorePickup } from "../seller.model";

export class FullB2BSellerDTO {
  name: string;
  address: string;
  kamId: string;
  vat: string;
  city: string;
  postalCode: string;
  companyWebsite: string;
  b2bReady: boolean;
  phone: string;
  email: string;
  countryId: string;
  typeId: string;
  vatStatus: boolean;
  repMakes: string[];
  soldMakes: string[];
  stores: SellerStoreDTO[];

  constructor(seller: SellerCompanyDTO) {
    this.name = seller.name;
    this.kamId = seller.kamId;
    this.address = seller.address;
    this.vat = seller.vat;
    this.city = seller.city;
    this.postalCode = seller.postalCode;
    this.companyWebsite = seller.companyWebsite;
    this.b2bReady = true;
    this.phone = seller.phone;
    this.email = seller.email;
    this.countryId = seller.countryId;
    this.typeId = seller.typeId;
    this.vatStatus = seller.vatStatus;
    this.repMakes = seller.repMakes;
    this.soldMakes = seller.soldMakes;
    this.stores = [];
  }
}

export class SellerCompanyDTO {
  kamId: string;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  b2bReady: boolean;
  companyWebsite: string;
  phone: string;
  email: string;
  vat: string;
  vatStatus: boolean;
  countryId: string;
  typeId: string;
  repMakes: string[];
  soldMakes: string[];

  constructor(sellerForm: any, country: string, vat: string, vatStatus: boolean) {
    this.kamId = sellerForm.kamId;
    this.address = sellerForm.address;
    this.city = sellerForm.city;
    this.countryId = country;
    this.companyWebsite = sellerForm.website;
    this.email = sellerForm.email;
    this.repMakes = sellerForm.makesRep;
    this.soldMakes = sellerForm.makesSold;
    this.name = sellerForm.name
    this.postalCode = sellerForm.postalCode;
    this.phone = sellerForm.phone;
    this.typeId = sellerForm.sellerType;
    this.vat = vat;
    this.vatStatus = vatStatus;
    this.b2bReady = true;
  }
}

export class SellerStoreDTO {
  name: string;
  city: string;
  address: string;
  postalCode: string;
  countryId: string;
  tempId?: string;
  defaultStore: boolean;
  countryBank: boolean;
  contacts: SellerContactDTO[];
  users: SellerUserDTO[];
  pickups: SellerPickupDTO[];
  repMakes: string[];
  soldMakes: string[];

  constructor(storeForm: any, country: string) {
    this.name = storeForm.name;
    this.city = storeForm.city;
    this.address = storeForm.address;
    this.postalCode = storeForm.postalCode;
    this.countryId = country;
    this.tempId = storeForm.tempId;
    this.countryBank = storeForm.countryBank;
    this.defaultStore = false;
    this.repMakes = storeForm.makesRep;
    this.soldMakes = storeForm.makesSold;
    this.contacts = [];
    this.users = [];
    this.pickups = [];
  }
}

export class SellerUserDTO {
  contactFlag: boolean;
  contactRoleDetails:
    {
      accEmailFlag: boolean;
      carPickupEmailFlag: boolean;
      documentEmailFlag: boolean;
    };
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryId: string;
  tempId?: string;
  stores?: {
    store: string,
    roles: string[],
    contactRoleDetails: {
      accEmailFlag: boolean,
      carPickupEmailFlag: boolean,
      documentEmailFlag: boolean,
    }
  }[];

  constructor(u: any, contactFlag: boolean, accEmailFlag: boolean, carPickupEmailFlag: boolean, documentEmailFlag: boolean, tempId?: string, stores?: any) {
    this.firstName = u.firstName;
    this.lastName = u.lastName;
    this.email = u.email;
    this.phoneNumber = u.phoneNumber;
    this.countryId = u.countryId;
    this.contactFlag = contactFlag;
    this.contactRoleDetails = {
      accEmailFlag: accEmailFlag ? accEmailFlag : false,
      carPickupEmailFlag: carPickupEmailFlag ? carPickupEmailFlag : false,
      documentEmailFlag: documentEmailFlag ? documentEmailFlag : false
    };
    this.tempId = tempId;
    this.stores = stores;
  }
}

export class SellerContactDTO {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountingEmail: boolean;
  carPickupEmail: boolean;
  documentsEmail: boolean;
  tempId?: string;
  store?: string;

  constructor(contact: any) {
    this.firstName = contact.firstName;
    this.lastName = contact.lastName;
    this.email = contact.email;
    this.tempId = contact.tempId;
    this.store = contact.store;
    this.phone = contact.phone ? contact.phone : '';
    this.accountingEmail = contact.accountingEmail ? contact.accountingEmail : false;
    this.carPickupEmail = contact.carPickupEmail ? contact.carPickupEmail : false;
    this.documentsEmail = contact.documentsEmail ? contact.documentsEmail : false;
  }
}

export class SellerPickupDTO {
  note: string;
  from: string;
  to: string;
  periodId: string;

  constructor(pickup: ISellerStorePickup) {
    this.note = pickup.note;
    this.from = pickup.from;
    this.to = pickup.to;
    this.periodId = pickup.period.periodId;
  }
}
