<div class="flex flex-col gap-9">
  <div class="flex justify-between items-center">
    <div class="flex items-center gap-6">
      <app-dropdown id="countries" label="Country" placeholder="Select country" [options]="countries"
        (emitOnChangeSelect)="countrySelected($event)">
      </app-dropdown>
      <app-input id="search" class="min-w-[17.5rem]" placeholder="Search" [control]="searchControl"></app-input>

      <primary-btn id="get-dealers" [blueBtn]="true" btnText="Search" (clickEmitter)="getDealers()"
        [disableBtn]="!selectedCountryPortalId"></primary-btn>
    </div>

    <div class="flex gap-6">
      <secondary-btn id="merge-dealers" btnText="Merge dealers" (clickEmitter)="mergeDealers()"> </secondary-btn>

      <tertiary-btn id="create-seller" btnText="Create seller"></tertiary-btn>
    </div>
  </div>

  <div *ngIf="dealers.length > 0;else noResults">
    <app-table [data]="dealers" [displayedColumns]="displayedColumns" [headersMap]="tableHeaders"></app-table>
  </div>
</div>

<ng-template #noResults>
  <div class="p-12">
    <div class="h-48 flex">
      <img class="object-contain mx-auto" src="./assets/background-photos/no-table-results.png" alt="">
    </div>
    <div class="text-center">
      <p class="text-2xl mt-3">No results available.</p>
      <p class="text-sm text-white-600 mt-3">Please select a country or change the current one.</p>
    </div>
  </div>
</ng-template>
