<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">Process pending order</p>
    </div>

    <app-main-content-layout>
      <div class="title">
        <p>Buyer</p>
      </div>

      <div class="grid grid-cols-2 gap-6">
        <div class="flex flex-col gap-6 pr-6 border-r border-white-500">
          <app-main-content-row-layout>
            <p>Name</p>

            <app-read-only-input class="secondContent">{{order?.buyer!.name}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Country</p>

            <app-read-only-input class="secondContent">{{order?.buyer!.country!.location}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Address</p>

            <app-read-only-input class="secondContent">{{order?.buyer!.address}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>KAM</p>

            <app-read-only-input class="secondContent">{{order?.kamBuyer!.username}}</app-read-only-input>
          </app-main-content-row-layout>
        </div>

        <div class="flex flex-col gap-6">
          <app-main-content-row-layout>
            <p>Store name</p>

            <app-read-only-input class="secondContent">{{order?.buyer!.store!.name}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Store country</p>

            <app-read-only-input class="secondContent">{{order?.buyer!.store!.name}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Store address</p>

            <app-read-only-input class="secondContent">{{order?.buyer!.store!.address}}</app-read-only-input>
          </app-main-content-row-layout>
        </div>
      </div>

    </app-main-content-layout>

    <app-main-content-layout>
      <div class="title">
        <p>Profit analysis</p>
      </div>

      <div class="grid grid-cols-2 gap-6">
        <app-main-content-row-layout class="pr-6 border-r border-white-500">
          <p>Total invoiced amount</p>

          <app-read-only-input class="secondContent">{{order!.totalInvoiced | number}}€</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Total profit</p>

          <app-read-only-input class="secondContent">
            <p [ngClass]="order!.totalActualProfit > 0 ? 'text-green-100' : 'text-red'">{{order!.totalActualProfit |
              number}}€</p>
          </app-read-only-input>
        </app-main-content-row-layout>
      </div>
    </app-main-content-layout>

    <div class="flex gap-6">
      <app-dropdown placeholder="Select VIN country" label="VIN Country" [options]="vinCountries"
        [control]="countryControl" id="vin-country"></app-dropdown>

      <primary-btn id="vin-check" [blueBtn]="true" (clickEmitter)="vinCheck()">VIN check all cars</primary-btn>

      <secondary-btn id="get-vin-details" *ngIf="order!.vinBatch.batchId.length > 0"
        (clickEmitter)="getVINDetails()">Get VIN check
        details</secondary-btn>
    </div>

    <app-table #carsTable [displayedColumns]="displayedColumns" [headersMap]="headers" [data]="cars"
      (objectEmitter)="openDetailsModal($event)"></app-table>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="flex flex-col gap-9">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">Process pending order</p>
    </div>

    <div class="twoCols">
      <app-main-content-placeholder></app-main-content-placeholder>
      <app-main-content-placeholder></app-main-content-placeholder>
    </div>

    <app-table-placeholder></app-table-placeholder>
  </div>

</ng-template>
