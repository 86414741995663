<div class="flex flex-col gap-9">
  <app-main-content-layout>
    <div class="title">
      <p>Options</p>
    </div>

    <div class="flex gap-6 pt-6">
      <app-datepicker id="request-delivery-date" [control]="requestDeliveryDateControl" placeholder="dd/mm/yyyy"
        label="Requested delivery date*"></app-datepicker>

      <app-datepicker id="estimated-payment-date" [control]="estimatedPaymentDateControl" placeholder="dd/mm/yyyy"
        label="Estimated payment date*"></app-datepicker>
    </div>

    <div class="flex flex-wrap gap-6">
      <app-toggle id="transport-by-c2c" [toggleFormControl]="transportCars2clickControl"
        toggleLabel="Transport by Cars2click" (toggleEmitter)="toggleTransport($event)"></app-toggle>

      <app-toggle id="separate-invoice-transport" [toggleFormControl]="separateInvoiceTransportControl"
        toggleLabel="Separate invoice for transport" [toggleDisabled]="!transportCars2clickControl.value!"></app-toggle>

      <app-toggle id="separate-invoice" [toggleFormControl]="separateInvoiceCarControl"
        toggleLabel="One invoice per car"></app-toggle>

      <app-toggle id="documents-company-address" [toggleFormControl]="documentsSameAddressControl"
        toggleLabel="Documents to company address" (toggleEmitter)="setDocumentsToCompanyAddress($event)"></app-toggle>
    </div>
  </app-main-content-layout>

  <!-- <app-orders-kpi *ngIf="!(page === 'select')" [cars]="cars"></app-orders-kpi> -->

  <div class="flex justify-between" *ngIf="page === 'select'">
    <div class="flex gap-6">
      <primary-btn id="open-add-cars-modal" [blueBtn]="true" (clickEmitter)="openManuallyAddCarsModal()">Manually add
        cars</primary-btn>

      <secondary-btn id="open-add-from-offer-cars-modal" (clickEmitter)="openCarsFromOffersModal()">Add cars from offers</secondary-btn>
    </div>

    <div class="flex gap-6">
      <!-- <secondary-btn id="import-from-cart-cars">Import cars from cart</secondary-btn> -->
      <secondary-btn id="remove-selected-cars" (clickEmitter)="removeSelectedCars()">Remove selected
        cars</secondary-btn>
    </div>
  </div>

  <app-btn-toggle #carsTabs [options]="routes" [selectedOption]="page"
    (changeEmitter)="changeTab($event)"></app-btn-toggle>

  <div [ngSwitch]="page">
    <app-order-select-cars-list #selectCars *ngSwitchCase="'select'"></app-order-select-cars-list>

    <app-order-select-prices *ngSwitchCase="'price'"></app-order-select-prices>

    <app-order-select-cars-delivery *ngSwitchCase="'delivery'"></app-order-select-cars-delivery>

    <app-order-select-cars-documents *ngSwitchCase="'docs'"></app-order-select-cars-documents>
  </div>
</div>
