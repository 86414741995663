<div class="bg-white px-6 rounded">
  <div class="flex justify-between py-6">
    <div class="flex gap-3 cursor-pointer text-[0.9375rem]" (click)="togglePanel()" [id]="panelId + '-toggle'"
      [attr.data-cy]="panelId + '-toggle'">
      <div class="min-w-[0.875rem]">
        <fa-icon *ngIf="expandedPanel;else chevronRight" [icon]="['fas','chevron-down']"></fa-icon>
      </div>
      <ng-template #chevronRight>
        <fa-icon [icon]="['fas','chevron-right']"></fa-icon>
      </ng-template>
      <p class="font-bold">{{title}}</p>
    </div>

    <fa-icon [id]="panelId + '-remove-btn'" [attr.data-cy]="panelId + '-remove-btn'" *ngIf="showXMark"
      [icon]="['fas','xmark']" class="cursor-pointer" (click)="emitXmarkClick()"></fa-icon>
  </div>

  <div [id]="panelId" class="panel">
    <ng-content></ng-content>
  </div>
</div>
