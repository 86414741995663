export interface SellerType {
  id: string,
  type: string
}

export enum SellerStatus {
  Approved = "Approved",
  Rejected = "Rejected",
  NotRequested = "Not requested",
  Requested = "Requested"
}
