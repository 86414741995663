import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { B2bSellerComponent } from "./b2b-seller/b2b-seller.component";
import { SellerCompanyComponent } from "./b2b-seller/seller-company/seller-company.component";
import { SellerStoresComponent } from "./b2b-seller/seller-stores/seller-stores.component";
import { OnlineSellerComponent } from "./online-seller/online-seller.component";
import { SellerStoreContactsComponent } from './b2b-seller/seller-store-contacts/seller-store-contacts.component';
import { SellerUsersComponent } from "./b2b-seller/seller-users/seller-users.component";
import { SellerStoreRestrictionsComponent } from './b2b-seller/seller-store-restrictions/seller-store-restrictions.component';
import { ViewSellersComponent } from './view-sellers/view-sellers.component';
import { SellerReportsComponent } from './b2b-seller/seller-reports/seller-reports.component';
import { ViewB2bSellerComponent } from "./view-b2b-seller/view-b2b-seller.component";
import { ViewB2bSellerCompanyComponent } from "./view-b2b-seller/view-b2b-seller-company/view-b2b-seller-company.component";
import { ViewB2bSellerStoresComponent } from './view-b2b-seller/view-b2b-seller-stores/view-b2b-seller-stores.component';
import { ViewB2bSellerUsersComponent } from './view-b2b-seller/view-b2b-seller-users/view-b2b-seller-users.component';
import { ViewB2bSellerContactsComponent } from './view-b2b-seller/view-b2b-seller-contacts/view-b2b-seller-contacts.component';
import { ViewB2bSellerPickupsComponent } from './view-b2b-seller/view-b2b-seller-pickups/view-b2b-seller-pickups.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    OnlineSellerComponent,
    B2bSellerComponent,
    SellerCompanyComponent,
    SellerStoresComponent,
    SellerStoreContactsComponent,
    SellerUsersComponent,
    SellerStoreRestrictionsComponent,
    ViewSellersComponent,
    SellerReportsComponent,
    ViewB2bSellerComponent,
    ViewB2bSellerCompanyComponent,
    ViewB2bSellerStoresComponent,
    ViewB2bSellerUsersComponent,
    ViewB2bSellerContactsComponent,
    ViewB2bSellerPickupsComponent
  ]
})

export class SellerModule { }
