import { Component, OnInit } from '@angular/core';
import { BuyerService } from 'src/app/core/services/buyer.service';

export interface ViesStatus {
  code: string,
  countryName: string,
  status: number,
  interpretedStatus?: string,
  vatExample?: string
}

@Component({
  selector: 'app-countries-availability-modal',
  templateUrl: './countries-availability-modal.component.html',
  styleUrls: ['./countries-availability-modal.component.scss']
})
export class CountriesAvailabilityModalComponent implements OnInit {
  public statuses: ViesStatus[] = [];

  public vatExamples = [
    {
      code: 'AT',
      example: 'U12345678 - 9 characters. The first character is always ‘U’.'
    },
    {
      code: 'BE',
      example: '1234567890 - 10 characters. Prefix with zero ‘0’ if the customer provides a 9 digit VAT number.'
    },
    {
      code: 'BG',
      example: '123456789, 1234567890 - 9 or 10 characters.'
    },
    {
      code: 'CY',
      example: '12345678X - 9 characters.The last character must always be a letter.'
    },
    {
      code: 'HR',
      example: '12345678901 - 11 characters.'
    },
    {
      code: 'CZ',
      example: '12345678, 123456789, 1234567890 - 8, 9 or 10 characters. If more than 10 characters are provided delete the first 3 as these are a tax code.'
    },
    {
      code: 'DE',
      example: '123456789 - 9 characters.'
    },
    {
      code: 'DK',
      example: '12345678 - 8 characters.'
    },
    {
      code: 'EE',
      example: '123456789 - 9 characters.'
    },
    {
      code: 'EL',
      example: '123456789 - 9 characters.'
    },
    {
      code: 'ES',
      example: 'X12345678, 12345678X, X1234567X - 9 characters. Includes one or two alphabetical characters (first or last or first and last).'
    },
    {
      code: 'FI',
      example: '12345678 - 8 characters.'
    },
    {
      code: 'FR',
      example: '12345678901, X1234567890, 1X123456789, XX123456789 - 11 characters. May include alphabetical characters (any except O or I) as first or second or first and second characters.'
    },
    {
      code: 'CR',
      example: '12345678901 - 11 characters.'
    },
    {
      code: 'HU',
      example: '12345678 - 8 characters.'
    },
    {
      code: 'IE',
      example: '1234567X, 1X23456X, 1234567XX - 8 or 9 characters. Includes one or two alphabetical characters (last, or second and last, or last 2).'
    },
    {
      code: 'IT',
      example: '12345678901 - 11 characters.'
    },
    {
      code: 'LT',
      example: '123456789, 123456789012 - 9 or 12 characters.'
    },
    {
      code: 'LU',
      example: '12345678 - 8 characters.'
    },
    {
      code: 'LV',
      example: '12345678901 11 characters.'
    },
    {
      code: 'MT',
      example: '12345678 - 8 characters.'
    },
    {
      code: 'NL',
      example: '123456789B01 - 12 characters. The tenth character is always B.'
    },
    {
      code: 'PL',
      example: '1234567890 - 10 characters.'
    },
    {
      code: 'PT',
      example: '123456789 - 9 characters.'
    },
    {
      code: 'RO',
      example: '12, 123, 1234, 12345, 123456, 1234567, 12345678, 123456789, 1234567890 - From 2 to 10 characters.'
    },
    {
      code: 'SE',
      example: '123456789012 - 12 characters.'
    },
    {
      code: 'SI',
      example: '12345678 - 8 characters.'
    },
    {
      code: 'SK',
      example: '1234567890 - 10 characters.'
    },
    {
      code: 'XI',
      example: 'XX123, 123456789, 123456789012 - 5, 9 or 12 characters.'
    }
  ]

  constructor(private buyerService: BuyerService) { }

  ngOnInit() {
    this.buyerService.getVATStatus().subscribe(resp => {
      this.statuses = resp.map(s => {
        return {
          code: s.code,
          countryName: s.countryName,
          status: s.status,
          interpretedStatus: s.status === 0 ? 'service available' : 'service unavailable',
          vatExample: this.vatExamples.find(v => v.code === s.code)?.example
        }
      });
    })
  }

}
