import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { CarBusinessCase, CarDetails } from 'src/app/core/models/car.model';
import { VATType } from 'src/app/core/models/info.model';
import { CoreService } from 'src/app/core/services/core.service';
import { InternalShopService } from 'src/app/core/services/internal-shop.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-car-shop-card',
  templateUrl: './car-shop-card.component.html',
  styleUrls: ['./car-shop-card.component.scss']
})
export class CarShopCardComponent implements OnInit {
  @Input() car: CarDetails | undefined;
  photo = '';
  isNew = false;

  vatStatus = VATType;

  subscription = new Subscription();

  bc: CarBusinessCase | undefined;
  bcCountry = '';

  availableFrom = new Date();
  availableTo = new Date();

  countries = this.coreService.countries;

  constructor(private router: Router,
    private userService: UserService,
    private snackbar: SnackbarService,
    private internalShopService: InternalShopService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.photo = this.car?.profilePhoto && this.car.profilePhoto != 'None' ? this.car.profilePhoto : environment.carPlaceholder;

    this.car!.createdAt = new Date(parseInt(JSON.stringify(this.car!.createdAt)) * 1000);

    let date = new Date();
    date.setDate(date.getDate() - 7);

    this.isNew = (new Date(this.car!.createdAt.toLocaleString())) > date;

    this.changeBC(this.internalShopService.bcCountry);

    if(this.car?.available){
      this.availableFrom = new Date(this.car!.available.from);

      this.availableTo = new Date(this.car!.available.to);
    }

    this.subscription.add(this.internalShopService.bcCountryEvent$.subscribe(resp => {
      this.changeBC(resp);
    }))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToCar(carId: string) {
    this.router.navigate([`shop/car/${carId}`])
  }

  addToCart() {
    this.userService.addToCart(this.car?.carMainInfoId!).subscribe({
      next: resp => this.snackbar.positiveSentiment(resp),
      error: err => this.snackbar.negativeSentiment(err.error)
    })
  }

  isNotNumber(value: any) {
    return isNaN(value) || !isFinite(value);
  }

  changeBC(country: string) {
    let countryISO = this.countries.find(c => c.id === country)?.iso;

    if (this.car!.businessCase) {
      this.bc = this.car!.businessCase.find(bc => bc.spotCountry === countryISO);

      this.bcCountry = this.countries.find(c => c.id === country)!.name;
    } else {
      this.bc = undefined;
    }
  }
}
