import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';

@Component({
  selector: 'app-seller-reports',
  templateUrl: './seller-reports.component.html',
  styleUrls: ['./seller-reports.component.scss']
})
export class SellerReportsComponent implements OnInit {
  sellerId = this.b2bSyncService.sellerId;

  public routeSubscription: Subscription = new Subscription();

  constructor(private b2bSyncService: B2BSellerSyncService) { }

  ngOnInit() {
    this.routeSubscription = this.b2bSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.b2bSyncService.setCurrentTab(value);
      }
    )
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
