import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss']
})
export class OrderReviewComponent implements OnInit {
  subscription = new Subscription();

  buyer = this.orderService.orderSettings?.buyer;
  user = this.orderService.orderSettings?.userBuyer;
  store = this.orderService.orderSettings?.buyerStore;
  contact = this.orderService.orderSettings?.buyerContact;

  orderOptions = this.fb.group({
    deliveryDate: [this.orderService.orderOptions?.requestDeliveryDate],
    estimatedPaymentDate: [this.orderService.orderOptions?.estimatedDeliveryDate],
    transportC2C: [this.orderService.transportByCars2click.value],
    separateTransportInvoice: this.orderService.orderOptions?.separateInvoiceTransport,
    separateInvoice: this.orderService.orderOptions?.separateInvoiceCar,
    documentsSameAddress: [this.orderService.documentsToCompanyAddress.value]
  });

  notes = this.orderService.notes;

  cars = this.orderService.carList;

  headers: TableHeaderMap[] = [
    {
      value: 'sellerName',
      tableView: 'seller'
    },
    {
      value: 'invoiceSellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'regNo',
      tableView: 'Registration number'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'firstReg',
      tableView: 'First registration'
    },
    {
      value: 'color',
      tableView: 'color'
    },
    {
      value: 'offeredPrice',
      tableView: 'offered price'
    }
  ]

  displayedColumns = this.headers.map(h => h.value);


  constructor(private orderService: OrdersFlowSyncService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.orderOptions.disable();

    this.subscription.add(this.orderService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.orderService.setCurrentTab(value);
      }
    ));

    this.subscription.add(this.orderService.sendOrderEvent$.subscribe(
      async (value) => {
        this.orderService.notes = this.notes;
        this.orderService.sendOrder();
      }
    ));

    this.subscription.add(this.orderService.saveOrderEvent$.subscribe(
      resp => {
        this.orderService.notes = this.notes;
        this.orderService.saveOrder();
      }
    ))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
