import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IconName, IconPrefix, IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html',
  styleUrls: ['./app-input.component.scss'],
})
export class AppInputComponent implements OnInit {
  @Input() isNumberInput: boolean = false;
  @Input() placeholder: string = '';
  @Input() control: FormControl = new FormControl();
  @Input() label: string | undefined;
  @Input() faIcon: IconName = 'user';
  @Input() faIconGroup: IconPrefix = 'fal';
  @Input() id = '';
  @Input() disabled: boolean = false;

  @Output() keyPressEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() focusOutEmitter: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit() { }

  keyPressEmit(event: KeyboardEvent) {
    this.keyPressEmitter.emit(event.key);
  }

  focusOutEmit() {
    this.focusOutEmitter.emit('');
  }

  removeCommas() {
    this.control.setValue(this.control.value.replace(',', ''));
  }

  addCommas() {
    this.control.setValue('252,523');
  }
}
