import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-content-layout',
  template: `
    <div class="bg-white-400 p-9 rounded-t border-b border-white-500">
        <p class="font-bold">{{title}}</p>
      </div>
    <div class="bg-white-400 p-9 rounded-b border-white-500">
      <ng-content></ng-content>
    </div>
  `,
})

export class InfoContentLayoutComponent implements OnInit {
  @Input() title: string = "";

  constructor() { }

  ngOnInit() { }
}
