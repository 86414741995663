import { Component, OnInit } from '@angular/core';
import { OrderCar } from 'src/app/core/models/order.model';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-order-process-claims',
  templateUrl: './order-process-claims.component.html',
  styleUrls: ['./order-process-claims.component.scss']
})
export class OrderProcessClaimsComponent implements OnInit {
  headers: TableHeaderMap[] = [
    {
      value: 'sellerName',
      tableView: 'seller'
    },
    {
      value: 'invoiceSellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'regNo',
      tableView: 'Registration number'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'location',
      tableView: 'country'
    },
    {
      value: 'deliveryCountryName',
      tableView: 'country'
    },
    {
      value: 'deliveryCity',
      tableView: 'city'
    },
    {
      value: 'deliveryAddress',
      tableView: 'delivery  address'
    },
    {
      value: 'deliveryZipcode',
      tableView: 'zipcode'
    },
    {
      value: 'deliveryContactName',
      tableView: 'contact'
    },
    {
      value: 'deliveryContactEmail',
      tableView: 'email'
    },
    {
      value: 'deliveryContactPhone',
      tableView: 'phone'
    },
  ]

  displayedColumns = this.headers.map(h => h.value);

  cars: OrderCar [] = [];

  constructor() { }

  ngOnInit(): void {
  }
}
