<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loadingInfo | async);else placeholder">

    <div class="flex justify-between">
      <p class="font-bold text-2xl">View Buyer</p>
    </div>

    <div class="flex overflow-auto">
      <app-btn-toggle [options]="routes" [selectedOption]="page" (changeEmitter)="navigateTo($event)"></app-btn-toggle>
    </div>

    <div [ngSwitch]="page">
      <app-view-buyer-company [isAdmin]="isAdmin" *ngSwitchCase="'company'"></app-view-buyer-company>

      <app-view-buyer-stores [isAdmin]="isAdmin" *ngSwitchCase="'stores'"></app-view-buyer-stores>

      <app-view-buyer-users [isAdmin]="isAdmin" *ngSwitchCase="'users'"></app-view-buyer-users>

      <app-view-buyer-contacts [isAdmin]="isAdmin" *ngSwitchCase="'contacts'"></app-view-buyer-contacts>

      <app-view-buyer-deliveries [isAdmin]="isAdmin" *ngSwitchCase="'restrictions'"></app-view-buyer-deliveries>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="grid grid-cols-2 gap-6 gap-y-9">
    <div class="placeholder-animation w-1/4 h-6 col-span-1"></div>

    <div class="placeholder-animation w-1/4 h-6 col-span-1 place-self-end"></div>

    <div class="placeholder-animation h-6 col-span-2"></div>

    <app-main-content-placeholder class="col-span-1"></app-main-content-placeholder>
    <app-main-content-placeholder class="col-span-1"></app-main-content-placeholder>
  </div>
</ng-template>
