import { NgModule } from '@angular/core';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faChevronUp, faChevronDown, faCircleInfo, faMagnifyingGlass, faPenToSquare, faStore, faTrashCan, faUser, faXmarkLarge, faPlus, faCheck, faShareNodes, faCube, faSquarePlus, faFloppyDisk, faSquareChevronLeft, faSquareChevronRight, faHexagonCheck, faUpToLine, faFilePlus, faCircleCheck, faPen, faTriangleExclamation, faEllipsisVertical } from '@fortawesome/pro-light-svg-icons';
import { faXmark, faChevronRight, faChevronDown as farChevronDown, faSigma, faArrowRightArrowLeft, faPaperPlaneTop, faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation as fasTriangleExclamation, faXmark as fasXmark, faChevronRight as fasChevronRight, faChevronDown as fasChevronDown, faCircleCheck as fasCircleCheck, faSquareArrowUpRight, faSquareArrowDownLeft } from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  declarations: [],
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
  providers: [],
  bootstrap: [],
})
export class SharedFontAwesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCircleInfo);
    library.addIcons(faStore);
    library.addIcons(faUser);
    library.addIcons(faMagnifyingGlass);
    library.addIcons(faTrashCan);
    library.addIcons(faChevronUp);
    library.addIcons(faChevronDown);
    library.addIcons(faPenToSquare);
    library.addIcons(faXmarkLarge);
    library.addIcons(faPlus);
    library.addIcons(faXmark);
    library.addIcons(fasXmark);
    library.addIcons(faChevronRight);
    library.addIcons(fasChevronRight);
    library.addIcons(farChevronDown);
    library.addIcons(fasChevronDown);
    library.addIcons(faCheck);
    library.addIcons(faShareNodes);
    library.addIcons(faCube);
    library.addIcons(faSquarePlus);
    library.addIcons(faFloppyDisk);
    library.addIcons(faSquareChevronLeft);
    library.addIcons(faSquareChevronRight);
    library.addIcons(faHexagonCheck);
    library.addIcons(faUpToLine);
    library.addIcons(faFilePlus);
    library.addIcons(faCircleCheck);
    library.addIcons(fasCircleCheck);
    library.addIcons(faPen);
    library.addIcons(faTriangleExclamation);
    library.addIcons(fasTriangleExclamation);
    library.addIcons(faSigma);
    library.addIcons(faArrowRightArrowLeft);
    library.addIcons(faPaperPlaneTop);
    library.addIcons(faEllipsisVertical);
    library.addIcons(faSquareArrowUpRight);
    library.addIcons(faSquareArrowDownLeft);
    library.addIcons(faArrowDownToLine);
  }
}
