import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { CarsOnListImportComponent } from "./cars-on-list-import/cars-on-list-import.component";
import { CarsOnListViewComponent } from "./cars-on-list/cars-on-list-view/cars-on-list-view.component";
import { CarsOnListComponent } from "./cars-on-list/cars-on-list.component";
import { ManageCarsComponent } from './manage-cars/manage-cars.component';
import { CarsFilterComponent } from './cars-filter/cars-filter.component';
import { MakeModelFilterComponent } from "../internal-shop/make-model-filter/make-model-filter.component";
import { SearchCarByIdModalComponent } from './cars-filter/search-car-by-id-modal/search-car-by-id-modal.component';
import { UpdateCarsModalComponent } from './manage-cars/update-cars-modal/update-cars-modal.component';
import { ModifyUnidentifiedCarsModalComponent } from './online-list-upload/modify-unidentified-cars-modal/modify-unidentified-cars-modal.component';
import { ModifyPartiallyIdentifiedCarModalComponent } from './online-list-upload/modify-partially-identified-car-modal/modify-partially-identified-car-modal.component';
import { CarOnListImportSettingsComponent } from './cars-on-list-import/car-on-list-import-settings/car-on-list-import-settings.component';
import { CarOnListImportReviewComponent } from './cars-on-list-import/car-on-list-import-review/car-on-list-import-review.component';
import { CarManualUploadComponent } from "./car-manual-upload/car-manual-upload.component";
import { OnlineListUploadModule } from "./online-list-upload/online-list-upload.module";


@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    CarsOnListImportComponent,
    CarsOnListComponent,
    CarsOnListViewComponent,
    ManageCarsComponent,
    CarsFilterComponent,
    MakeModelFilterComponent,
    SearchCarByIdModalComponent,
    UpdateCarsModalComponent,
    ModifyUnidentifiedCarsModalComponent,
    ModifyPartiallyIdentifiedCarModalComponent,
    CarOnListImportSettingsComponent,
    CarOnListImportReviewComponent,
    CarManualUploadComponent,
  ],
  exports: [
    CarsFilterComponent
  ]
})

export class CarsInventoryModule { }
