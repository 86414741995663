import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilterComponent implements OnInit {
  @Input() dropdownControl: FormControl = new FormControl();
  @Input() options: string[] = [];
  @Input() label: string | undefined;
  @Input() id = 'id';

  filteredOptions: Observable<string[]> | undefined;

  constructor() { }

  ngOnInit() {
    this.initFIlteredOptions();
  }

  initFIlteredOptions() {
    this.filteredOptions = this.dropdownControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
}
