import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-add-order-document-modal',
  templateUrl: './add-order-document-modal.component.html',
  styleUrls: ['./add-order-document-modal.component.scss']
})
export class AddOrderDocumentModalComponent implements OnInit {
  fileData = '';
  fileName = '';

  nameControl = new FormControl('', Validators.required);
  visibleToBuyersControl = new FormControl(false);

  constructor(private utilsService: UtilsService,
    private snackbar: SnackbarService,
    public dialogRef: MatDialogRef<AddOrderDocumentModalComponent>) { }

  ngOnInit() {
  }

  handleFile(event: Event) {
    const target = event.target as HTMLInputElement;

    let file = target.files![0];

    this.utilsService.convertToBase64(file).then(fileBase64 => {
      this.fileData = fileBase64;
      this.fileName = file.name;
    });
  }

  removeFile() {
    this.fileData = '';
    this.fileName = '';
  }

  uploadDocument() {
    if (this.nameControl.invalid) {
      this.snackbar.negativeSentiment('Insert document name');
      return;
    }

    if (this.fileData.length === 0 || this.fileName.length === 0) {
      this.snackbar.negativeSentiment('Upload document');
      return;
    }

    this.dialogRef.close({
      fileName: this.nameControl.value,
      fileData: this.fileData,
      visible: this.visibleToBuyersControl.value
    });
  }
}
