import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CarsVINCheck } from 'src/app/core/models/car.model';
import { SellerOffer } from 'src/app/core/models/offer.model';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-seller-offer-vin',
  templateUrl: './seller-offer-vin.component.html',
  styleUrls: ['./seller-offer-vin.component.scss']
})
export class SellerOfferVinComponent implements OnInit {
  @Input() offer: SellerOffer | undefined;
  vinDecoded = false;

  @Output() vinCheckEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private carService: CarService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit(): void {
  }

  decodeVin() {
    let body: CarsVINCheck = {
      vins: [{
        carMainInfoId: '',
        locationId: this.offer!.carDetails.country.id,
        vin: this.offer!.carDetails.vin
      }]
    }

    this.spinner.showProgressBar.next(true);

    this.carService.vinCheck(body).subscribe({
      next: resp => {
        this.spinner.showProgressBar.next(false);

        this.offer!.carDetails.vinCheckId = resp.batchId;

        this.vinCheckEmitter.emit(resp.batchId);

        this.snackbar.positiveSentiment('VIN check process has started');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }
}
