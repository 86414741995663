import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { VINCheckDetailsEquipment } from 'src/app/core/models/car.model';
import { IVinResponse } from 'src/app/core/models/vin-check.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { BtnToggleOption } from 'src/app/shared/app-btn-toggle/app-btn-toggle.component';

@Component({
  selector: 'app-vin-check',
  templateUrl: './vin-check.component.html',
  styleUrls: ['./vin-check.component.scss']
})
export class VinCheckComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  routes: BtnToggleOption[] = [
    {
      value: 'vin',
      viewValue: 'VIN Decode',
      disabled: new BehaviorSubject<boolean>(false)
    },
    {
      value: 'standard',
      viewValue: 'Standard equipment',
      disabled: new BehaviorSubject<boolean>(true)
    },
    {
      value: 'optional',
      viewValue: 'Optional equipment',
      disabled: new BehaviorSubject<boolean>(true)
    }
  ];

  page = this.routes[0].value;

  countries = this.coreService.countries.map(c => ({ value: c.iso, viewValue: c.name }));

  vinControl = new FormControl('', Validators.required);
  countryControl = new FormControl('', Validators.required);

  carData: IVinResponse | undefined;

  optionalEquipment: VINCheckDetailsEquipment[] = [];
  standardEquipment: VINCheckDetailsEquipment[] = [];

  constructor(private coreService: CoreService,
    private carService: CarService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit() {
    this.carService.getVINCountries().subscribe(resp => {
      this.countries = resp.map(c => ({ value: c.iso, viewValue: c.country }));

      this.loading.next(false);
    })
  }

  searchVIN() {
    if (this.vinControl.invalid || this.countryControl.invalid) {
      this.snackbar.negativeSentiment('Complete VIN and select country');

      return;
    }

    this.spinner.showProgressBar.next(true);

    this.carService.getCarVINCheck(this.vinControl.value!, this.countryControl.value!).subscribe({
      next: resp => {
        this.carData = resp;

        this.optionalEquipment = resp.optionalEquipments.map((eq: any) => ({
          ...eq,
          isOpen: false,
        }));

        this.standardEquipment = resp.standardEquipments.map((eq: any) => ({
          ...eq,
          isOpen: false,
        }));

        this.spinner.showProgressBar.next(false);

        this.routes.forEach(r => r.disabled?.next(false))
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }
}
