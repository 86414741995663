import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddCompanyInvoiceModalComponent } from './add-company-invoice-modal/add-company-invoice-modal.component';
import { CoreService } from 'src/app/core/services/core.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { BehaviorSubject, firstValueFrom, zip } from 'rxjs';

export interface CompanyInvoice {
  id?: string,
  countryId: string,
  countryName?: string,
  name: string,
  address: string,
  taxValue: number
}

@Component({
  selector: 'app-company-invoices',
  templateUrl: './company-invoices.component.html',
  styleUrls: ['./company-invoices.component.scss']
})

export class CompanyInvoicesComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  headersMap: TableHeaderMap[] = [
    {
      value: 'name',
      tableView: 'Company name'
    },
    {
      value: 'countryName',
      tableView: 'company country'
    },
    {
      value: 'taxValue',
      tableView: 'VAT VALUE %'
    },
    {
      value: 'address',
      tableView: 'address'
    },
  ];

  companies: CompanyInvoice[] = [];

  displayedColumns = ['selectAll', 'name', 'countryName', 'taxValue','address', 'edit'];
  countries = this.coreService.countriesDropdown;

  @ViewChild('companiesTable') companiesTable: AppTableComponent | undefined;

  constructor(private dialog: MatDialog,
    private coreService: CoreService,
    private settingsService: SettingsService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit(): void {
    this.settingsService.getCompanyInvoice().subscribe(resp => {
      this.companies = resp.map(c => ({
        ...c,
        countryName: this.countries.find(co => co.value === c.countryId)!.viewValue
      }));

      this.loading.next(false);
    });
  }

  addCompany() {
    const dialogRef = this.dialog.open(
      AddCompanyInvoiceModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
        countries: this.countries
      }
    });

    dialogRef.afterClosed().subscribe((company: CompanyInvoice) => {
      if (company) {
        this.spinner.showProgressBar.next(true);

        this.settingsService.createCompanyInvoice(company).subscribe({
          next: resp => {
            company.countryName = this.countries.find(c => c.value === company.countryId)!.viewValue;

            this.companies.push(company);

            this.companiesTable?.updateTable(this.companies);

            this.spinner.showProgressBar.next(false);
            this.snackbar.positiveSentiment(resp);
          },
          error: err => {
            this.spinner.showProgressBar.next(false);
            this.snackbar.negativeSentiment(err.error);
          }
        })
      }
    })
  }

  removeCompany() {
    let companiesToRemove = this.companiesTable?.dataSource.data.filter(c => c.isSelected);

    if (companiesToRemove?.length === 0) {
      this.snackbar.negativeSentiment('No companies selected');
      return;
    }

    this.spinner.showProgressBar.next(true);

    let companiesToRemovePromise: Promise<string>[] = [];

    companiesToRemove!.forEach((c: CompanyInvoice) => {
      companiesToRemovePromise.push(firstValueFrom(this.settingsService.deleteCompanyInvoice(c.id!)));
    });

    zip(companiesToRemovePromise).subscribe({
      next: resp => {
        companiesToRemove!.forEach(co => {
          this.companies.splice(this.companies.findIndex(comp => comp.id === co.id), 1);
        })

        this.companiesTable?.updateTable(this.companies);

        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment('Companies deleted');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment(err.error);
      }
    })
  }

  editCompany(company: CompanyInvoice) {
    const dialogRef = this.dialog.open(
      AddCompanyInvoiceModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
        company: company,
        countries: this.countries
      }
    });

    dialogRef.afterClosed().subscribe((company: CompanyInvoice) => {
      if (company) {
        this.spinner.showProgressBar.next(true);

        let companyId = company.id!;

        delete company.id;
        delete company.countryName;

        this.settingsService.editCompanyInvoice(company, companyId).subscribe({
          next: resp => {
            company.countryName = this.countries.find(c => c.value === company.countryId)!.viewValue;
            company.id = companyId;

            this.companies[this.companies.findIndex(co => co.id === companyId)] = company;

            this.companiesTable?.updateTable(this.companies);

            this.spinner.showProgressBar.next(false);
            this.snackbar.positiveSentiment(resp);
          },
          error: err => {
            this.spinner.showProgressBar.next(false);
            this.snackbar.negativeSentiment(err.error);
          }
        })
      }
    })
  }
}
