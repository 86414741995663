import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Order, OrderCar } from 'src/app/core/models/order.model';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';

@Component({
  selector: 'app-orders-kpi',
  templateUrl: './orders-kpi.component.html',
  styleUrls: ['./orders-kpi.component.scss']
})
export class OrdersKpiComponent implements OnInit {
  updateSubscription = new Subscription()

  @Input() isSentOrder = false;

  cars = this.orderService.carList;
  order = this.orderService.order!;

  showExtrasInitialValuesControl = new FormControl();

  constructor(private orderService: OrdersFlowSyncService) { }

  ngOnInit(): void {
    this.updateSubscription = this.orderService.orderProfitEvent$.subscribe(resp => {
      this.cars = resp;

      this.order = this.orderService.order!;
    });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  getTotalBuyingPrice(): number {
    return this.cars.reduce((sum, car) => {
      return sum + car.carPricing.buyingPrice!;
    }, 0)
  }

  getTotalInvoicedAmount(): number {
    return this.cars.reduce((sum, car) => {
      let invoice: number = car.carPricing.invoiceSellingPrice ? car.carPricing.invoiceSellingPrice : 0;
      return sum + invoice;
    }, 0)
  }

  getNrOfCars(): number {
    return this.cars.length;
  }

  getTotalTransportAmount(): number {
    return this.cars.reduce((sum, car) => {
      let transportationFee = car.carPricing.transportationFee ? car.carPricing.transportationFee : 0;
      return sum + transportationFee;
    }, 0)
  }

  getTotalCostTransportAmount(): number {
    return this.cars.reduce((sum, car) => {
      let transportationFee = car.carPricing.transportationActualFee ? car.carPricing.transportationActualFee : 0;
      return sum + transportationFee;
    }, 0)
  }

  getTotalCostCOCAmount(): number {
    return this.cars.reduce((sum, car) => {
      let cocValue = car.documentsDetails.documents.coc.cocValue ? car.documentsDetails.documents.coc.cocValue : 0;
      return sum + cocValue;
    }, 0)
  }

  getTotalPhotoEditAmount(): number {
    return this.cars.reduce((sum, car) => {
      let photoEdit = car.photoEditing && car.photoEditing.photoEditValue ? car.photoEditing.photoEditValue : 0;
      return sum + photoEdit;
    }, 0)
  }

  getTotalCostPhotoEditAmount(): number {
    return this.cars.reduce((sum, car) => {
      let photoEdit = car.photoEditing && car.photoEditing.photoEditActualValue ? car.photoEditing.photoEditActualValue : 0;
      return sum + photoEdit;
    }, 0)
  }

  getAverageProfit(): number {
    let profit: number = this.cars.reduce((sum, car) => {
      let profit = car.carPricing.actualProfit ? car.carPricing.actualProfit : 0;
      return sum + profit;
    }, 0)

    return profit / this.cars.length;
  }

  getAverageSellingPrice(): number {
    let avgSell: number = this.cars.reduce((sum, car) => {
      let sell = car.carPricing.invoiceSellingPrice ? car.carPricing.invoiceSellingPrice : 0;
      return sum + sell;
    }, 0)

    return avgSell / this.cars.length;
  }

  getProfit(): number {
    let carsProfit = this.cars.reduce((sum, car) => {
      let profit = car.carPricing.actualProfit ? car.carPricing.actualProfit : 0;
      return sum + profit;
    }, 0);

    return carsProfit - this.orderService.order!.dhlValue;
  }

  getProfitPercentage(): number {
    return this.getProfit() * 100 / this.getTotalInvoicedAmount();
  }

  getTotalExtrasAmmount(): number {
    return this.getTotalPhotoEditAmount() + this.getTotalTransportAmount();
  }

  getTotalExtrasProfit(): number {
    return this.getTotalExtrasAmmount() - (this.getTotalCostCOCAmount() + this.getTotalCostPhotoEditAmount() + this.getTotalCostTransportAmount() + this.order!.dhlValue);
  }

  getMinimumInvoiceProfitMargin(): number {
    let minInvoice = this.cars.reduce((sum, car) => {
      return sum + car.carPricing.minSellingPrice!;
    }, 0);

    return minInvoice - this.getTotalBuyingPrice();
  }

  getMinimumInvoiceProfitMarginPercentage(): number {
    return this.getMinimumInvoiceProfitMargin() * 100 / this.getTotalInvoicedAmount();
  }
}
