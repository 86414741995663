import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-order-process-invoice-companies-modal',
  templateUrl: './order-process-invoice-companies-modal.component.html',
  styleUrls: ['./order-process-invoice-companies-modal.component.scss']
})

export class OrderProcessInvoiceCompaniesModalComponent implements OnInit {
  companies = this.data.companies;

  constructor(public dialogRef: MatDialogRef<OrderProcessInvoiceCompaniesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  close(value: boolean) {
    this.dialogRef.close(value);
  }
}
