import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-layout',
  template: `
    <div class="px-12 py-9 w-full">
      <ng-content></ng-content>
    </div>
  `,
})

export class PageLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
