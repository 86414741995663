import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { AppBtnToggleComponent } from 'src/app/shared/app-btn-toggle/app-btn-toggle.component';
import { OrderSelectCarsListComponent } from './order-select-cars-list/order-select-cars-list.component';
import { OrderCar } from 'src/app/core/models/order.model';
import { ManuallyAddCarsModalComponent } from '../../order-shared-components/manually-add-cars-modal/manually-add-cars-modal.component';
import { AddCarsFromOffersModalComponent } from '../../order-shared-components/add-cars-from-offers-modal/add-cars-from-offers-modal.component';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { SellerStatus } from 'src/app/core/models/seller-models/seller-helpers.models';

@Component({
  selector: 'app-order-select-cars',
  templateUrl: './order-select-cars.component.html',
  styleUrls: ['./order-select-cars.component.scss']
})
export class OrderSelectCarsComponent implements OnInit {
  subscription = new Subscription();

  routes = [
    {
      viewValue: "Selection list",
      value: "select",
      disabled: new BehaviorSubject<boolean>(false)
    },
    {
      viewValue: "Price settings",
      value: "price",
      disabled: new BehaviorSubject<boolean>(true)
    },
    {
      viewValue: "Delivery",
      value: "delivery",
      disabled: new BehaviorSubject<boolean>(true)
    },
    {
      viewValue: "Documents",
      value: "docs",
      disabled: new BehaviorSubject<boolean>(true)
    }
  ];

  page = this.routes[0].value;

  sellerStatus = SellerStatus;

  @ViewChild('carsTabs') carsTabs: AppBtnToggleComponent | undefined;
  @ViewChild('selectCars') selectCars: OrderSelectCarsListComponent | undefined;

  requestDeliveryDateControl = new FormControl<any>(null, Validators.required);
  estimatedPaymentDateControl = new FormControl<any>(null, Validators.required);
  separateInvoiceTransportControl = new FormControl(false);
  transportCars2clickControl = this.orderService.transportByCars2click;
  separateInvoiceCarControl = new FormControl(false);
  documentsSameAddressControl = this.orderService.documentsToCompanyAddress;

  cars = this.orderService.carList;

  transportMatrix = this.orderService.transportMatrix;

  constructor(private dialog: MatDialog,
    private orderService: OrdersFlowSyncService,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.subscription.add(this.orderService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.orderService.setCurrentTab(value);
      }
    ));

    this.subscription.add(this.orderService.continueEvent$.subscribe(
      async (value) => {
        if (!this.saveInfos()) return;

        this.orderService.setCurrentTab('equipment');
      }
    ));

    this.subscription.add(this.orderService.saveOrderEvent$.subscribe(
      resp => {
        if (!this.saveInfos()) return;

        this.orderService.saveOrder();
      }
    ));

    if (this.orderService.orderOptions) {
      this.documentsSameAddressControl.setValue(this.orderService.orderOptions.documentsSameAddress);
      this.separateInvoiceCarControl.setValue(this.orderService.orderOptions.separateInvoiceCar);
      this.separateInvoiceTransportControl.setValue(this.orderService.orderOptions.separateInvoiceTransport);
      this.estimatedPaymentDateControl.setValue(this.orderService.orderOptions.estimatedDeliveryDate);
      this.requestDeliveryDateControl.setValue(this.orderService.orderOptions.requestDeliveryDate);
    }

    this.toggleDisableRoute();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  saveInfos() {
    if (this.orderService.carList.length < 1) {
      this.snackbar.negativeSentiment('No cars added in order!');
      return false;
    }

    if (this.requestDeliveryDateControl.invalid || this.estimatedPaymentDateControl.invalid) {
      this.snackbar.negativeSentiment('No request delivery date or no estimated payment date');
      return false;
    }

    this.orderService.orderOptions = {
      requestDeliveryDate: typeof (this.requestDeliveryDateControl.value) === 'string' ? this.requestDeliveryDateControl.value : this.requestDeliveryDateControl.value.format('yyyy-MM-DD'),
      estimatedDeliveryDate: typeof (this.estimatedPaymentDateControl.value) === 'string' ? this.estimatedPaymentDateControl.value : this.estimatedPaymentDateControl.value.format('yyyy-MM-DD'),
      separateInvoiceTransport: this.separateInvoiceTransportControl.value!,
      separateInvoiceCar: this.separateInvoiceCarControl.value!,
      documentsSameAddress: this.documentsSameAddressControl.value!,
    };

    return true;
  }

  openManuallyAddCarsModal() {
    const dialogRef = this.dialog.open(
      ManuallyAddCarsModalComponent, {
      width: '2560px',
      maxWidth: '95vw',
      autoFocus: false,
      data: {
        clientId: this.orderService.orderSettings?.userBuyer.clientId!,
        buyerId: 'this.orderService.orderSettings?.buyer.id!',
        carList: this.orderService.carList
      },
    });

    dialogRef.afterClosed().subscribe(async (resp: OrderCar[]) => {
      if (resp) {
        let cars = resp.filter(c => !this.orderService.carList.find(car => car.carMainInfoId === c.carMainInfoId));

        if (cars.length > 0) {
          this.addCarsToOrder(cars);
        }
      }
    })
  }

  addCarsToOrder(cars: OrderCar[], fromOffer = false) {
    // cars.forEach((c: OrderCar) => {
    //   c = {
    //     ...c,
    //     deliveryDetails: {
    //       country: this.orderService.orderSettings?.buyerStore.countryDetails?.countryId,
    //       countryName: this.orderService.orderSettings?.buyerStore.countryDetails?.country,
    //       city: '',
    //       address: '',
    //       postalCode: '',
    //       contactDetails: {
    //         firstName: '',
    //         lastName: '',
    //         email: '',
    //         phone: '',
    //         fullName: ''
    //       }
    //     },
    //     documentsDetails: {
    //       country: this.orderService.orderSettings?.buyerStore.countryDetails?.countryId,
    //       countryName: this.orderService.orderSettings?.buyerStore.countryDetails?.country,
    //       city: '',
    //       address: '',
    //       postalCode: '',
    //       contactDetails: {
    //         firstName: '',
    //         lastName: '',
    //         email: '',
    //         phone: '',
    //         fullName: ''
    //       },
    //       registrationDocuments: {
    //         documents: [],
    //         documentsReceived: '',
    //         documentsSentAt: '',
    //       },
    //       cocDocuments: {
    //         cocBySeller: false,
    //         cocFrom: "",
    //         cocActualValue: 0,
    //         required: false
    //       }
    //     },
    //     pickupDetails: {
    //       country: '',
    //       countryName: '',
    //       city: '',
    //       address: '',
    //       postalCode: '',
    //       contactDetails: {
    //         firstName: '',
    //         lastName: '',
    //         email: '',
    //         phone: '',
    //         fullName: ''
    //       }
    //     },
    //     transportationFee: this.orderService.transportByCars2click.value ? this.transportMatrix.find(t => t.countryFrom === c.location && t.countryTo === this.orderService.orderSettings?.buyer.countryDetails?.countryId)?.value! : 0,
    //     showRoomReady: false,
    //     finalDamages: 0,
    //     vinDecoded: false,
    //     fromOffer: fromOffer,
    //     // offeredPrice: fromOffer ? c.offeredPrice : 0,
    //     // invoiceSellingPrice: fromOffer ? c.offeredPrice : c.sellingPrice!,
    //     availability: false,
    //     // actualProfit: fromOffer ? c.offeredPrice! - c.buyingPrice! : c.sellingPrice! - c.buyingPrice,
    //   }

    //   // if (this.orderService.documentsToCompanyAddress.value) {
    //   //   c.documentsDetails = {
    //   //     // country: this.orderService.orderSettings?.buyerStore.countryDetails?.countryId,
    //   //     // countryName: this.orderService.orderSettings?.buyerStore.countryDetails?.country,
    //   //     city: this.orderService.orderSettings!.buyer.city!,
    //   //     address: this.orderService.orderSettings!.buyer.address!,
    //   //     postalCode: this.orderService.orderSettings!.buyer.postalCode!,
    //   //     contactDetails: {
    //   //       firstName: this.orderService.orderSettings!.buyerContact.firstName!,
    //   //       lastName: this.orderService.orderSettings!.buyerContact.lastName!,
    //   //       email: this.orderService.orderSettings!.buyerContact.email!,
    //   //       phone: this.orderService.orderSettings!.buyerContact.phone!,
    //   //       // fullName: `${this.orderService.orderSettings!.buyerContact.firstName} ${this.orderService.orderSettings!.buyerContact.lastName}`,
    //   //     },
    //   //     registrationDocuments: {
    //   //       documents: [],
    //   //       documentsReceived: '',
    //   //       documentsSentAt: '',
    //   //     },
    //   //     cocDocuments: {
    //   //       cocBySeller: false,
    //   //       cocFrom: "",
    //   //       cocActualValue: 0,
    //   //       required: false,
    //   //     }
    //   //   }
    //   // }

    //   // this.orderService.carList.push(c);
    // });

    this.orderService.carList = this.orderService.carList.map(c => ({
      ...c,
      isSelected: false
    }));

    this.toggleDisableRoute();

    this.orderService.emitCarsTableUpdate();

    this.cars = this.orderService.carList;

    this.snackbar.positiveSentiment('Cars added to order!');
  }

  changeTab(page: string) {
    this.page = page;
  }

  removeSelectedCars() {
    this.selectCars!.removeCars();

    this.toggleDisableRoute();
  }

  toggleDisableRoute() {
    // if (this.orderService.carList.every(c => c.sellerValidationType === this.sellerStatus.Approved)) {
    //   this.routes.slice(1).forEach(r => {
    //     r.disabled.next(false);
    //   })
    // } else {
    //   this.routes.slice(1).forEach(r => {
    //     r.disabled.next(true);
    //   })
    // }
  }

  setDocumentsToCompanyAddress(event: boolean) {
    if (event) {
      // this.orderService.carList = this.orderService.carList.map(c => ({
      //   ...c,
      //   documentsDetails: {
      //     country: this.orderService.orderSettings?.buyerStore.countryDetails?.countryId,
      //     countryName: this.orderService.orderSettings?.buyerStore.countryDetails?.country,
      //     city: this.orderService.orderSettings!.buyer.city!,
      //     address: this.orderService.orderSettings!.buyer.address!,
      //     postalCode: this.orderService.orderSettings!.buyer.postalCode!,
      //     contactDetails: {
      //       firstName: this.orderService.orderSettings!.buyerContact.firstName!,
      //       lastName: this.orderService.orderSettings!.buyerContact.lastName!,
      //       email: this.orderService.orderSettings!.buyerContact.email!,
      //       phone: this.orderService.orderSettings!.buyerContact.phone!,
      //       fullName: `${this.orderService.orderSettings!.buyerContact.firstName} ${this.orderService.orderSettings!.buyerContact.lastName}`,
      //     },
      //     registrationDocuments: {
      //       documents: [],
      //       documentsReceived: '',
      //       documentsSentAt: '',
      //     },
      //     cocDocuments: c.documentsDetails.cocDocuments
      //   }
      // }));

      this.orderService.emitCarsTableUpdate();
    }
  }

  toggleTransport(event: boolean) {
    let calcTransportFee = (car: any) => {
      let buyerLoc = this.orderService.orderSettings?.buyer.countryDetails?.countryId;

      let carLoc = car.location;

      let transportFee = this.orderService.transportMatrix.find(t => t.countryFrom === carLoc && t.countryTo === buyerLoc);

      return transportFee ? transportFee.value : 0;
    };

    if (event) {
      // this.orderService.carList = this.orderService.carList.map(c => ({
      //   ...c,
      //   transportRequired: true,
      //   transportationFee: calcTransportFee(c),
      //   profit: c.invoiceSellingPrice! - c.buyingPrice! - c.documentsDetails.cocDocuments.cocActualValue - c.dhlFee! - c.transportationFee!
      // }))
    } else {
      // this.orderService.carList = this.orderService.carList.map(c => ({
      //   ...c,
      //   transportRequired: false,
      //   transportationFee: 0,
      //   profit: c.invoiceSellingPrice! - c.buyingPrice! - c.documentsDetails.cocDocuments.cocActualValue - c.dhlFee! - c.transportationFee!
      // }));
    }

    this.orderService.transportByCars2click.setValue(event);
    this.orderService.emitCarsTableUpdate();
  }

  openCarsFromOffersModal() {
    const dialogRef = this.dialog.open(
      AddCarsFromOffersModalComponent, {
      width: '900px',
      autoFocus: false,
      disableClose: true,
      data: {
        clientId: this.orderService.orderSettings?.userBuyer.clientId!,
      },
    });

    dialogRef.afterClosed().subscribe(async (resp: OrderCar[]) => {
      if (resp) {
        let cars = resp.filter(c => !this.orderService.carList.find(car => car.carMainInfoId === c.carMainInfoId));

        if (cars.length > 0) {
          this.addCarsToOrder(cars, true);
        }
      }
    })
  }
}
