import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { ViewBuyerCompanyComponent } from "./view-buyer-company/view-buyer-company.component";
import { ViewBuyerComponent } from "./view-buyer.component";
import { ViewBuyerStoresComponent } from './view-buyer-stores/view-buyer-stores.component';
import { ViewBuyerUsersComponent } from './view-buyer-users/view-buyer-users.component';
import { ViewBuyerContactsComponent } from './view-buyer-contacts/view-buyer-contacts.component';
import { ViewBuyerDeliveriesComponent } from './view-buyer-deliveries/view-buyer-deliveries.component';



@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ViewBuyerComponent,
    ViewBuyerCompanyComponent,
    ViewBuyerStoresComponent,
    ViewBuyerUsersComponent,
    ViewBuyerContactsComponent,
    ViewBuyerDeliveriesComponent
  ]
})

export class ViewBuyerModule { }
