import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import hours from 'src/assets/json-files/hours.json';

@Component({
  selector: 'app-publish-car-modal',
  templateUrl: './publish-car-modal.component.html',
  styleUrls: ['./publish-car-modal.component.scss']
})
export class PublishCarModalComponent implements OnInit {
  today = new Date();
  dateFromControl = new FormControl<any>(null, Validators.required);
  dateToControl = new FormControl<any>(null, Validators.required);
  priceControl = new FormControl<any>(null, Validators.required);
  groupControl = new FormControl('', Validators.required);
  minSellingPriceControl = new FormControl<any>(null, Validators.required);

  startTime = new FormControl<any>('', Validators.required);
  endTime = new FormControl<any>('', Validators.required);

  groups = this.data.groups;

  showError = false;

  hours = hours;

  constructor(public dialogRef: MatDialogRef<PublishCarModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { groups: DropdownOption[] }) { }

  ngOnInit(): void {
  }

  publishCar() {
    if (this.dateFromControl.invalid || this.dateToControl.invalid || this.groupControl.invalid || this.priceControl.invalid || this.startTime.invalid || this.endTime.invalid || this.minSellingPriceControl.invalid) {
      this.showError = true;

      return;
    }

    let startDate = moment(`${moment(this.dateFromControl.value).format('yyyy-MM-DD')} ${this.startTime.value}:00:00`).utc().format('yyyy-MM-DD HH:mm:ss');
    let endDate = moment(`${moment(this.dateToControl.value).format('yyyy-MM-DD')} ${this.endTime.value}:00:00`).utc().format('yyyy-MM-DD HH:mm:ss');

    this.dialogRef.close({
      dateFrom: startDate,
      dateTo: endDate,
      group: this.groupControl.value,
      price: this.priceControl.value,
      minSellingPrice: this.minSellingPriceControl.value
    });
  }
}
