import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { UtilsService } from 'src/app/core/services/utils.service';

export interface TableHeaderMap {
  value: string,
  tableView: string,
  isDateSimple?: boolean,
  isDateHours?: boolean,
}

@Component({
  selector: 'app-table',
  templateUrl: './app-table.component.html',
  styleUrls: ['./app-table.component.scss']
})
export class AppTableComponent implements OnInit {
  @Input() data: any;
  @Input() displayedColumns: string[] = [];
  @Input() headersMap: TableHeaderMap[] = [];
  @Input() id = 'id';
  @Input() hidePaginator = false;
  @Input() addCellColClass = false;
  @Input() addCellValueClass = false;

  @Output() goToEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() objectEmitter: EventEmitter<any> = new EventEmitter<any>();

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  _ = _;

  constructor(private utilsService: UtilsService) { }

  ngOnInit() {
    this.dataSource.data = this.data.map((d: any) => ({
      ...d,
      isSelected: false
    }));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  goToElement(id: string) {
    this.goToEmitter.emit(id);
  }

  updateTable(data: any) {
    this.dataSource.data = data;
  }

  selectAll(event: MatCheckboxChange) {
    this.dataSource.data = this.dataSource.data.map(d => ({ ...d, isSelected: event.checked }))
  }

  isNumber(val: any): boolean {
    return _.isNumber(val);
  }

  seeDetails(object: any) {
    this.objectEmitter.emit(object);
  }

  getValue(element: any, value: TableHeaderMap): string {
    if (value.isDateHours) {
      return this.utilsService.utcDateToLocal(_.get(element, value.value.split('.')));
    } else if (value.isDateSimple) {
      return this.utilsService.formatDateValue(_.get(element, value.value.split('.')));
    } else {
      return _.get(element, value.value.split('.'));
    }
  }
}
