<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">Process order</p>
    </div>

    <div class="flex justify-between">
      <div class="flex overflow-auto">
        <app-btn-toggle [options]="routes" [selectedOption]="page"
          (changeEmitter)="changePage($event)"></app-btn-toggle>
      </div>
    </div>

    <!-- <app-main-content-layout>
      <div class="title">
        <p>Order tasks</p>
      </div>

      <div class="grid grid-cols-3 gap-6">
        <div *ngFor="let task of orderTasks | slice: taskPaginator.pageIndex * 6: taskPaginator.pageIndex * 6 + 6"
          class="p-6 rounded bg-red-100 flex flex-col gap-3 h-full">
          <p class="px-3 py-1 bg-orange-200 text-orange-300 text-xs w-fit rounded">{{task.sellerName!.length > 0 ?
            'Seller: ' + task.sellerName : 'Order'}}</p>

          <p class="text-xs" *ngIf="task.carDescription">Car: {{task.carDescription}}</p>

          <p class="text-sm">{{task.description}}</p>
        </div>
      </div>

      <mat-paginator #taskPaginator [length]="orderTasks.length" [pageSize]="6" [hidePageSize]="true"
        aria-label="Select page">
      </mat-paginator>
    </app-main-content-layout> -->

    <app-expansion-panel *ngIf="page != 'setup'" title="Orders panel" [expandedPanel]="true" panelId="orderPanel">
      <div class="py-7 grid grid-cols-2 gap-6 border-t border-white-300">
        <div class="col-span-2 flex flex-wrap gap-4 justify-between" *ngIf="!finishedOrder">
          <div class="flex gap-6 items-center">
            <app-dropdown label="Order substatus" [options]="subStatuses" placeholder="Select substatus"
              [control]="subStatusControl"></app-dropdown>

            <secondary-btn (clickEmitter)="updateSubStatus()">Update substatus</secondary-btn>
          </div>

          <app-secondary-button-filled [disableBtn]="orderTasks.length > 0" id="finalize-order"
            (clickEmitter)="finalizeOrder()">Finalize
            order</app-secondary-button-filled>
        </div>

        <div class="pr-6 border-r border-white-300 flex flex-col gap-6">
          <app-main-content-row-layout>
            <p>Date created</p>

            <app-read-only-input class="secondContent">{{order!.createdAt | date}}</app-read-only-input>
          </app-main-content-row-layout>
          <app-main-content-row-layout>
            <p>Date modified</p>

            <app-read-only-input class="secondContent">{{order!.modifiedAt | date}}</app-read-only-input>
          </app-main-content-row-layout>
          <app-main-content-row-layout>
            <p>Created by</p>

            <app-read-only-input class="secondContent">{{order!.createdBy.username}}</app-read-only-input>
          </app-main-content-row-layout>
        </div>

        <div class="flex flex-col gap-6">
          <app-main-content-row-layout>
            <p>Invoiced total amount</p>

            <app-read-only-input class="secondContent">€{{order!.profitAnalysis.totalInvoiced |
              number}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Total initial profit</p>

            <app-read-only-input class="secondContent">€{{order!.profitAnalysis.totalInitialProfit|
              number}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Total actual profit</p>

            <app-read-only-input class="secondContent">€{{order!.profitAnalysis.totalActualProfit |
              number}}</app-read-only-input>
          </app-main-content-row-layout>
        </div>
      </div>
    </app-expansion-panel>

    <app-orders-kpi *ngIf="page != 'setup'" [isSentOrder]="true"></app-orders-kpi>

    <app-expansion-panel *ngIf="page != 'setup'" title="Options" [expandedPanel]="true" panelId="optionsPanel">
      <div class="py-7 flex flex-col gap-6 border-t border-white-300">
        <div class="grid grid-cols-5 gap-x-6 gap-y-9 mt-6">
          <app-datepicker class="col-start-1" id="requested-delivery-date" [control]="orderOptions.controls.requestDate"
            placeholder="dd/mm/yyyy" label="Requested delivery date"></app-datepicker>

          <app-datepicker id="estimated-payment" [control]="orderOptions.controls.paymentDate" placeholder="dd/mm/yyyy"
            label="Estimated payment date"></app-datepicker>

          <app-input-number label="DHL value" placeholder="Insert DHL value*"
            [control]="orderOptions.controls.dhlActualValue"></app-input-number>

          <div class="col-start-5 flex justify-end" *ngIf="!finishedOrder">
            <app-blue-btn id="save-order-options" class="w-fit" (clickEmitter)="saveOrderOptions()">Save order
              options</app-blue-btn>
          </div>
        </div>

        <div class="flex flex-wrap gap-6">
          <app-toggle id="transport-by-c2c" [toggleFormControl]="orderOptions.controls.transportC2C"
            [toggleDisabled]="checkTransports()" toggleLabel="Transport by Cars2click"
            (toggleEmitter)="$event ? '' : orderOptions.controls.separateTransportInvoice.setValue(false)"></app-toggle>

          <app-toggle id="separate-transport-invoice"
            [toggleDisabled]="checkTransports() || !orderOptions.controls.transportC2C.value"
            [toggleFormControl]="orderOptions.controls.separateTransportInvoice"
            toggleLabel="Separate invoice for transport"></app-toggle>

          <app-toggle id="separate-invoice" [toggleFormControl]="orderOptions.controls.invoicePerCar"
            toggleLabel="One invoice per car"></app-toggle>
        </div>

        <textarea id="notes-textarea" data-cy="notes-textarea" [(ngModel)]="notes" rows="4" [disabled]="finishedOrder"
          class="w-full rounded border border-white-500 p-6 text-blue-100 text-sm focus:outline-none resize-none"
          placeholder="Insert notes here..."></textarea>
      </div>
    </app-expansion-panel>

    <div [ngSwitch]="page">
      <app-order-process-invoice-companies *ngSwitchCase="'setup'"></app-order-process-invoice-companies>

      <app-order-process-buyer *ngSwitchCase="'buyer'"></app-order-process-buyer>

      <app-order-process-seller *ngSwitchCase="'seller'"></app-order-process-seller>

      <app-order-process-car *ngSwitchCase="'cars'"></app-order-process-car>

      <app-order-process-payments *ngSwitchCase="'payment'"></app-order-process-payments>

      <app-order-process-transport *ngSwitchCase="'transportation'"></app-order-process-transport>

      <app-order-process-profit *ngSwitchCase="'profit'"></app-order-process-profit>

      <app-order-process-documents *ngSwitchCase="'documents'"></app-order-process-documents>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="flex flex-col gap-9">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">Process order</p>
    </div>

    <div class="twoCols">
      <app-main-content-placeholder></app-main-content-placeholder>
      <app-main-content-placeholder></app-main-content-placeholder>
    </div>
  </div>

</ng-template>
