import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderCar, UpdateCarDeliveryDetailsClass } from 'src/app/core/models/order.model';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderSelectCarsDeliveryDetailsModalComponent } from '../../order-create/order-select-cars/order-select-cars-delivery/order-select-cars-delivery-details-modal/order-select-cars-delivery-details-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SellerFilterTableComponent } from '../../order-shared-components/seller-filter-table/seller-filter-table.component';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { OrderStatus } from '../../orders-view/orders-view.component';

@Component({
  selector: 'app-order-process-buyer',
  templateUrl: './order-process-buyer.component.html',
  styleUrls: ['./order-process-buyer.component.scss']
})

export class OrderProcessBuyerComponent implements OnInit {
  headers: TableHeaderMap[] = [
    {
      value: 'carConfig.sellerName',
      tableView: 'seller'
    },
    {
      value: 'carPricing.invoiceSellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatString',
      tableView: 'vat'
    },
    {
      value: 'carConfig.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carConfig.make',
      tableView: 'make'
    },
    {
      value: 'carConfig.model',
      tableView: 'model'
    },
    {
      value: 'carConfig.vin',
      tableView: 'vin'
    },
    {
      value: 'locationName',
      tableView: 'delivery country'
    },
    {
      value: 'deliveryDetails.city',
      tableView: 'delivery city'
    },
    {
      value: 'deliveryDetails.address',
      tableView: 'delivery  address'
    },
    {
      value: 'deliveryDetails.postalCode',
      tableView: 'delivery zipcode'
    },
    {
      value: 'deliveryDetails.contactDetails.fullName',
      tableView: 'delivery contact'
    },
    {
      value: 'deliveryDetails.contactDetails.email',
      tableView: 'delivery contact email'
    },
    {
      value: 'deliveryDetails.contactDetails.phone',
      tableView: 'delivery contact phone'
    },
  ]

  displayedColumns = [...this.headers.map(h => h.value), 'details'];

  cars: OrderCar[] = this.ordersFlowService.carList;

  buyer = this.ordersFlowService.order!.buyer;
  kam = this.ordersFlowService.order!.kamBuyer!;
  contact = this.ordersFlowService.order!.buyer.store!.contactDetails;

  @ViewChild('carsTable') carsTable: SellerFilterTableComponent | undefined;

  finishedOrder = this.ordersFlowService.orderStatus === OrderStatus.FINISHED;

  constructor(private ordersFlowService: OrdersFlowSyncService,
    private orderService: OrdersService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit(): void {
    this.setDeliveryContactFullName();
  }

  openModal(car: OrderCar) {
    const dialogRef = this.dialog.open(
      OrderSelectCarsDeliveryDetailsModalComponent, {
      width: '900px',
      autoFocus: false,
      disableClose: !this.finishedOrder,
      data: {
        car: JSON.parse(JSON.stringify(car)),
        country: this.buyer.country.location,
        finishedOrder: this.finishedOrder
      },
    });

    dialogRef.afterClosed().subscribe((deliveryDetails) => {
      if (deliveryDetails) {
        this.spinner.showProgressBar.next(true);

        let body = new UpdateCarDeliveryDetailsClass(this.ordersFlowService.order?.orderId!, car.carConfig.seller, car.carMainInfoId, deliveryDetails);

        this.orderService.updateCarDeliveryDetails(body).subscribe({
          next: sellers => {
            this.ordersFlowService.order!.sellers = sellers;

            this.ordersFlowService.updateCarList();

            this.cars = this.ordersFlowService.carList;

            this.setDeliveryContactFullName();

            this.carsTable!.updateTable(this.cars);

            this.spinner.showProgressBar.next(false);
            this.snackbar.positiveSentiment('Car delivery details updated successfully!');
          },
          error: err => {
            this.spinner.showProgressBar.next(false);
            this.snackbar.negativeSentiment(err.error);
          }
        });
      }
    });
  }

  setDeliveryContactFullName() {
    this.cars.forEach(c => c.deliveryDetails.contactDetails.fullName = `${c.deliveryDetails.contactDetails.firstName} ${c.deliveryDetails.contactDetails.lastName}`)
  }

  setSameStoreAddress() {
    this.spinner.showProgressBar.next(true)

    this.orderService.updateBulkDeliveryDetails(this.ordersFlowService.orderId!).subscribe({
      next: resp => {
        this.ordersFlowService.order!.sellers = resp;

        this.ordersFlowService.updateCarList();

        this.cars = this.ordersFlowService.carList;

        this.setDeliveryContactFullName();

        this.carsTable!.updateTable(this.cars);

        this.spinner.showProgressBar.next(false);
        this.snackbar.positiveSentiment('Car delivery details updated successfully!');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error)
      }
    })
  }
}
