<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">Create offers</p>
    </div>

    <div class="flex justify-between">
      <div class="flex overflow-auto">
        <app-btn-toggle [options]="routes" [selectedOption]="page"
          (changeEmitter)="changePage($event)"></app-btn-toggle>
      </div>

      <div class="flex gap-6">
        <primary-btn id="continue" *ngIf="page == 'settings'" (clickEmitter)="continue()">Continue</primary-btn>

        <primary-btn id="create-offers" *ngIf="page == 'cars'" (clickEmitter)="createOffers()">Create
          offers</primary-btn>
      </div>
    </div>

    <div [ngSwitch]="page" *ngIf="!(loading | async);else placeholder">
      <app-create-offer-settings [buyers]="buyers" #offerSettings
        *ngSwitchCase="'settings'"></app-create-offer-settings>

      <app-create-offer-cars #offerCars *ngSwitchCase="'cars'"></app-create-offer-cars>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="twoCols">
    <app-main-content-placeholder></app-main-content-placeholder>
    <app-main-content-placeholder></app-main-content-placeholder>
  </div>
</ng-template>
