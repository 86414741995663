import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'app-car-transport',
  templateUrl: './car-transport.component.html',
  styleUrls: ['./car-transport.component.scss']
})
export class CarTransportComponent implements OnInit {
  loading = new BehaviorSubject(true);

  public routeSubscription: Subscription = new Subscription();
  public matrix: { country: string, value: number }[] = [];

  carId = this.carSyncService.carId;
  carDetails = this.carSyncService.carDetails;
  countryControl = new FormControl({ value: '', disabled: true });

  constructor(private carSyncService: CarFlowSyncService,
    private coreService: CoreService) { }

  async ngOnInit() {
    this.routeSubscription = this.carSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.carSyncService.setCurrentTab(value);
      }
    );

    this.countryControl.setValue(this.coreService.countries.find(c => c.id === this.carDetails!.location)!.name);


    let matrix = await this.carSyncService.getCarTransportMatrix();

    let filteredCountries = matrix.filter(r => r.countryFrom === this.carDetails?.location);

    this.matrix = filteredCountries.map(c => ({ country: this.coreService.countries.find(co => co.id === c.countryTo)!.name, value: c.value }))

    this.loading.next(false);
  }
}
