import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './app-pie-chart.component.html',
  styleUrls: ['./app-pie-chart.component.scss']
})
export class AppPieChartComponent implements AfterViewInit {
  @Input() data: any;
  @Input() chartId: string = "";
  @Input() title: string = "";
  chart: Chart | undefined;

  constructor() { }

  ngAfterViewInit() {
    Chart.register(...registerables);

    let data = {
      labels: [
        'Red',
        'Blue',
        'Yellow'
      ],
      datasets: [{
        data: [300, 50, 100],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)'
        ],
        hoverOffset: 4,
        cutout: '80%',
      }],
    };

    if (this.data) {
      data = this.data;
    }

    this.chart = new Chart(this.chartId, {
      type: 'doughnut',
      data: data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              usePointStyle: true
            }
          },
          title: {
            text: this.data ? this.data.datasets[0].label : this.title,
            align: 'start',
            display: true,
            font: {
              size: 14,
              weight: 'bold',
            },
            color: '#00304A',
            padding: {
              bottom: 50
            }
          },
        }
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  resizeChart() {
    this.chart?.resize();
  }
}
