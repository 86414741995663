import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { CountryHoliday } from '../country-holiday.component';

export interface AddCountryHolidayModalData {
  countries: DropdownOption[];
  months: DropdownOption[];
  holidays: CountryHoliday[];
  countrySelected: string;
}
@Component({
  selector: 'app-add-country-holiday-modal',
  templateUrl: './add-country-holiday-modal.component.html',
  styleUrls: ['./add-country-holiday-modal.component.scss']
})

export class AddCountryHolidayModalComponent implements OnInit {
  disableSaveButton = new BehaviorSubject<boolean>(false);

  countries: DropdownOption[] = [];
  months: DropdownOption[] = [];
  days: DropdownOption[] = [];

  countryControl = new FormControl("", Validators.required);
  monthControl = new FormControl("", Validators.required);
  dayControl = new FormControl("", Validators.required);

  holidays: CountryHoliday[] = this.data.holidays;

  constructor(public dialogRef: MatDialogRef<AddCountryHolidayModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddCountryHolidayModalData,
    private snackbar: SnackbarService,
    private settingsService: SettingsService,
    private progressBar: SpinnerHandlerService) { }

  ngOnInit() {
    this.countries = this.data.countries;
    this.months = this.data.months;
    this.countryControl.setValue(this.data.countrySelected);
  }

  loadDays(month: string) {
    let days = (new Date(new Date().getFullYear(), Number.parseInt(month) + 1, 0)).getDate();
    this.days = [...Array(days).keys()].map((n) => ({ value: (n + 1).toString(), viewValue: (n + 1).toString() }))
  }

  addHoliday() {
    if (this.disableSaveButton.value) return;

    if (this.countryControl.invalid || this.monthControl.invalid || this.dayControl.invalid) {
      this.snackbar.negativeSentiment('All fields required!');
      return;
    }

    let holiday = this.holidays.find(h => h.country === this.countryControl.value && (new Date(h.date)).getDate() === Number.parseInt(this.dayControl.value!) && (new Date(h.date)).getMonth() === Number.parseInt(this.monthControl.value!));

    if (holiday) {
      this.snackbar.negativeSentiment('Holiday already exists!');
      return;
    }

    let date = new Date(new Date().getFullYear(), Number.parseInt(this.monthControl.value!), Number.parseInt(this.dayControl.value!)).toLocaleDateString('en-US');;

    this.progressBar.showProgressBar.next(true);
    this.disableSaveButton.next(true);

    this.settingsService.createCountryHoliday(this.countryControl.value!, date).subscribe(resp => {
      this.holidays.push({
        country: this.countryControl.value!,
        date: new Date(date)
      })

      this.progressBar.showProgressBar.next(false);
      this.disableSaveButton.next(false);
      this.snackbar.positiveSentiment(resp);
    });
  }
}
