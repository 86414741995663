import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { CountriesAvailabilityModalComponent } from "src/app/shared/countries-availability-modal/countries-availability-modal.component";
import { environment } from "src/environments/environment";
import { Country } from "../models/country.model";
import { CustomerType } from "../models/customer-type.model";
import { DeliveryWindows } from "../models/info.model";
import { Make } from "../models/make.model";
import { Priority } from "../models/priority.model";
import { ValidateVATRequestBody } from "../models/vat.model";

@Injectable({
  providedIn: 'root'
})

export class InfoService {
  constructor(private http: HttpClient,
    private dialog: MatDialog) { }

  public checkVATNumber(countryCode: string, vat: string) {
    const vatNumberRegex = /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

    return (countryCode + vat).match(vatNumberRegex);
  }

  public validateVAT(vat: string, countryCode: string): Observable<ValidateVATRequestBody> {
    return this.http.post<ValidateVATRequestBody>(`${environment.backendApiUrl}vies-check`, { vatNumber: vat, countryCode: countryCode });
  }

  public openCountriesAvailabilityModal() {
    const dialogRef = this.dialog.open(
      CountriesAvailabilityModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(() => { })
  }

  public getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${environment.backendApiUrl}countries`);
  }

  public getCustomerTypes(): Observable<CustomerType[]> {
    return this.http.get<CustomerType[]>(`${environment.backendApiUrl}customer-types`);
  }

  public getPriorities(): Observable<Priority[]> {
    return this.http.get<Priority[]>(`${environment.backendApiUrl}priorities`);
  }

  public getDeliveryWindows(): Observable<DeliveryWindows[]> {
    return this.http.get<DeliveryWindows[]>(`${environment.backendApiUrl}delivery-windows`);
  }

  public getCurrencyExchangeRatesToEuro(): Observable<any> {
    return this.http.get<any>(`${environment.backendApiUrl}get-exchange-rates-to-euro`);
  }
}
