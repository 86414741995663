import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderCar } from 'src/app/core/models/order.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderSelectCarsDeliveryDetailsModalComponent } from './order-select-cars-delivery-details-modal/order-select-cars-delivery-details-modal.component';
import { SellerFilterTableComponent } from '../../../order-shared-components/seller-filter-table/seller-filter-table.component';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';

@Component({
  selector: 'app-order-select-cars-delivery',
  templateUrl: './order-select-cars-delivery.component.html',
  styleUrls: ['./order-select-cars-delivery.component.scss']
})
export class OrderSelectCarsDeliveryComponent implements OnInit {
  headersMap: TableHeaderMap[] = [
    {
      "value": "make",
      "tableView": "make"
    }, {
      "value": "model",
      "tableView": "model"
    }, {
      "value": "deliveryDetails.countryName",
      "tableView": "country"
    }, {
      "value": "deliveryDetails.city",
      "tableView": "city"
    }, {
      "value": "deliveryDetails.address",
      "tableView": "delivery address"
    }, {
      "value": "deliveryDetails.postalCode",
      "tableView": "zipcode"
    }, {
      "value": "deliveryDetails.contactDetails.fullName",
      "tableView": "contact"
    }, {
      "value": "deliveryDetails.contactDetails.email",
      "tableView": "email"
    }, {
      "value": "deliveryDetails.contactDetails.phone",
      "tableView": "phone"
    }, {
      "value": "regNo",
      "tableView": "reg No"
    }, {
      "value": "vin",
      "tableView": "vin"
    },
  ];

  cars: OrderCar[] = this.orderService.carList;

  displayedColumns = ['selectAll'].concat(this.headersMap.map(c => c.value), ['details']);

  @ViewChild('carsDeliveryTable') carsTable: SellerFilterTableComponent | undefined;

  constructor(private dialog: MatDialog,
    private orderService: OrdersFlowSyncService,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
    // this.cars.forEach(c => {
    //   if (this.orderService.order) {
    //     c.deliveryDetails.country = this.orderService.order.buyer.store.country.id;
    //     c.deliveryDetails.countryName = this.orderService.order.buyer.store.country.name;
    //   } else {
    //     c.deliveryDetails.country = this.orderService.orderSettings?.buyerStore.countryDetails?.countryId;
    //     c.deliveryDetails.countryName = this.orderService.orderSettings?.buyerStore.countryDetails?.country;
    //   }
    // });
  }

  openOrderSelectCarsDeliveryDetailsModalComponent(car: OrderCar) {
    const dialogRef = this.dialog.open(
      OrderSelectCarsDeliveryDetailsModalComponent, {
      width: '900px',
      autoFocus: false,
      disableClose: true,
      data: {
        car: car,
      },
    });

    dialogRef.afterClosed().subscribe(() => {

    })
  }

  setSameStoreAddress() {
    let cars = this.carsTable?.carsTable?.dataSource.data.filter(c => c.isSelected);

    let store = this.orderService.orderSettings?.buyerStore;

    if (cars?.length === 0) {
      this.snackbar.negativeSentiment('No cars selected');
    } else {
      cars?.forEach((c: OrderCar) => {
        c.deliveryDetails.city = store?.city!;
        c.deliveryDetails.address = store?.address!;
        c.deliveryDetails.postalCode = store?.postalCode!;
      });

      this.orderService.carList = <OrderCar[]>this.carsTable?.carsTable?.dataSource.data;

      this.snackbar.positiveSentiment('Delivery address updated');
    }
  }

  setStoreContact() {
    let cars = this.carsTable?.carsTable?.dataSource.data.filter(c => c.isSelected);

    let contact = this.orderService.orderSettings?.buyerContact;

    if (cars?.length === 0) {
      this.snackbar.negativeSentiment('No cars selected');
    } else {
      cars?.forEach((c: OrderCar) => {
        c.deliveryDetails.contactDetails = {
          firstName: contact?.firstName!,
          lastName: contact?.lastName!,
          fullName: `${contact?.firstName} ${contact?.lastName}`,
          email: contact?.email!,
          phone: contact?.phone!,
        }
      });

      this.orderService.carList = <OrderCar[]>this.carsTable?.carsTable?.dataSource.data;
      this.snackbar.positiveSentiment('Delivery contact updated');
    }
  }
}
