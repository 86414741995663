import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-area-chart',
  templateUrl: './app-area-chart.component.html',
  styleUrls: ['./app-area-chart.component.scss']
})
export class AppAreaChartComponent implements AfterViewInit {
  @Input() data: any;
  @Input() chartId: string = '';
  @Input() title: string = '';

  chart: Chart | undefined;

  constructor() { }

  ngAfterViewInit() {
    Chart.register(...registerables);

    const data = {
      labels: [
        'Red',
        'Blue',
        'Yellow'
      ],
      datasets: [
        {
          label: '',
          data: [22, 100, 33],
          borderColor: '#FF9090',
          backgroundColor: 'rgba(255, 144, 144, 0.3)',
          fill: 'start'
        }
      ]
    };

    this.chart = new Chart(this.chartId, {
      type: 'line',
      data: data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          filler: {
            propagate: false,
          },
          title: {
            text: this.title,
            align: 'start',
            display: true,
            font: {
              size: 14,
              weight: 'bold',
            },
            color: '#00304A',
            padding: {
              bottom: 50
            }
          },
          legend: {
            display: false
          }
        },
        interaction: {
          intersect: false,
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          }
        }
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  resizeChart(){
    this.chart?.resize();
  }
}
