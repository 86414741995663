import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-vertical-bar-chart',
  templateUrl: './app-vertical-bar-chart.component.html',
  styleUrls: ['./app-vertical-bar-chart.component.scss']
})
export class AppVerticalBarChartComponent implements OnInit {
  @Input() data: any;
  @Input() title: string | undefined;
  chart: Chart | undefined;

  constructor() { }

  ngOnInit() {
    Chart.register(...registerables);

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const data = {
      labels: labels,
      datasets: [{
        label: '',
        data: [124, 231, 234, 212, 233, 52, 124, 231, 234, 212, 233, 52],
        borderColor: '#00537F',
        backgroundColor: '#00537F',
        barThickness: 6,
      }],
    };

    this.chart = new Chart("verticalBarChart", {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false
          },
        },
        scales: {
          y: {
            grid: {
              offset: true,
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false
            }
          },
          x: {
            grid: {
              display: false,
              drawBorder: false
            }
          }
        }
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  resizeChart(){
    this.chart?.resize();
  }
}
