import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CarService } from 'src/app/core/services/car.service';
import { InternalShopService } from 'src/app/core/services/internal-shop.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-make-model-filter',
  templateUrl: './make-model-filter.component.html',
  styleUrls: ['./make-model-filter.component.scss']
})
export class MakeModelFilterComponent implements OnInit {
  makes: DropdownOption[] = [];
  models: DropdownOption[] = [];

  @Input() filterId: string = '';
  @Input() showMark: boolean = true;

  @Output() emitOnChangeSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitModelsSelect: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() emitRemoveFilter: EventEmitter<string> = new EventEmitter<string>();

  @Input() id = 0;

  public selectedMake = '';
  public modelControl = new FormControl();
  public makeControl = new FormControl();

  constructor(private carService: CarService,
    private internalShopService: InternalShopService) { }

  ngOnInit() {
    this.internalShopService.makeSubjectEvent$.subscribe(resp => {
      this.filterMakes();
    })

    this.filterMakes();
  }

  loadModels(make: string) {
    this.selectedMake = make;
    this.emitOnChangeSelect.emit(make);

    if (!make) {
      this.models = [];
    } else {
      this.carService.getCarNomenclatorData(`models?make=${make}`).subscribe(resp => {
        this.models = resp.map(data => { return { value: data, viewValue: data } });
      })
    }
  }

  filterMakes() {
    this.makes = JSON.parse(JSON.stringify(this.internalShopService.makes));

    if (this.internalShopService.selectedMakes) {
      this.internalShopService.selectedMakes.forEach(make => {
        make != this.selectedMake ? this.makes.splice(this.makes.findIndex(m => m.value === make), 1) : 0;
      })
    }
  }

  removeFilter() {
    this.emitRemoveFilter.emit(this.filterId);
  }

  changeModels(model: string) {
    setTimeout(() => {
      this.emitModelsSelect.emit(this.modelControl.value);
    }, 50)
  }

  resetFirstFilter() {
    this.modelControl.reset();
    this.makeControl.reset();
  }
}
