import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, of, Subject } from "rxjs";
import { DropdownOption } from "src/app/shared/app-dropdown/app-dropdown.component";
import { DeliveryWindows } from "../models/info.model";
import { ISeller, ISellerResponse, ISellerStorePickup } from "../models/seller.model";
import { InfoService } from "./infos.service";
import { SpinnerHandlerService } from "./overlay-spinner.service";
import { SellerService } from "./seller.service";
import { SnackbarService } from "./snackbar.service";
import { UserRoles } from "../models/user.model";
import { CoreService } from "./core.service";
import { Router } from "@angular/router";
import { UserService } from "./user.service";
import { CarService } from "./car.service";
import { FullB2BSellerDTO, SellerCompanyDTO, SellerContactDTO, SellerPickupDTO, SellerStoreDTO, SellerUserDTO } from "../models/seller-models/seller-create.model";

@Injectable({
  providedIn: 'root'
})

export class B2BSellerSyncService {
  public loadingInfo: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private isRouteChangeEvent: Subject<string> = new Subject();
  public isRouteChangeEvent$ = this.isRouteChangeEvent.asObservable();

  private currentTab: BehaviorSubject<string> = new BehaviorSubject('init');
  public currentTab$ = this.currentTab.asObservable();

  private saveFormEvent: Subject<MouseEvent> = new Subject();
  public saveFormEvent$ = this.saveFormEvent.asObservable();

  createMode: boolean = true;

  kams: DropdownOption[] = [];
  makes: DropdownOption[] = [];
  sellerTypes: DropdownOption[] = [];
  validationTypes: DropdownOption[] = [];
  pickupWindows: DeliveryWindows[] = [];

  //full seller creation
  seller: SellerCompanyDTO | undefined;
  sellerStores: SellerStoreDTO[] = [];
  sellerUsers: SellerUserDTO[] = [];
  sellerContacts: SellerContactDTO[] = [];
  sellerPickups: ISellerStorePickup[] = [];

  //seller get
  sellerId: string | undefined;
  sellerResponse: ISellerResponse | undefined;

  constructor(private sellerService: SellerService,
    private spinnerService: SpinnerHandlerService,
    private coreService: CoreService,
    private snackBar: SnackbarService,
    private router: Router,
    private carService: CarService,
    private userService: UserService,
    private infoService: InfoService) { }

  loadInfo() {
    forkJoin({
      sellerTypes: this.sellerService.getSellerTypes(),
      validationsTypes: this.sellerService.getValidationTypes(),
      pickupWindows: this.infoService.getDeliveryWindows(),
      makes: this.carService.getCarNomenclatorData('makes'),
      kams: this.coreService.isAdmin ? this.userService.getKAMUsers() : of(null),
      seller: this.sellerId ? this.sellerService.getSeller(this.sellerId) : of(null)
    }).subscribe(resp => {
      this.sellerTypes = resp.sellerTypes.map(t => ({ viewValue: t.type, value: t.id }));
      this.validationTypes = resp.validationsTypes.map(t => ({ viewValue: t.type, value: t.id }));

      this.makes = resp.makes.map(m => { return { value: m, viewValue: m } });

      this.sellerTypes.splice(this.sellerTypes.findIndex(t => t.viewValue === "Online shop"), 1);
      this.validationTypes = this.validationTypes.filter(vt => vt.viewValue.toLowerCase().match('requested'));

      if (resp.kams) {
        this.kams = resp.kams.map(k => ({ viewValue: k.username, value: k.kamId }));
      }

      this.pickupWindows = resp.pickupWindows;

      if (resp.seller) {
        this.sellerResponse = resp.seller;
      }

      this.loadingInfo.next(false);
    })
  }

  changeTab(page: string): void {
    this.isRouteChangeEvent.next(page);
  }

  setCurrentTab(page: string): void {
    this.currentTab.next(page);
  }

  emitSaveEvent(event: MouseEvent) {
    this.saveFormEvent.next(event);
  }

  createSeller() {
    if (!this.sellerStores.every(s => this.sellerUsers.some(u => u.stores?.find(us => us.store === s.tempId)))) {
      this.snackBar.negativeSentiment('At least one user should be assigned for each store');

      return;
    }

    if (this.checkStoreContacts()) {
      this.snackBar.negativeSentiment('There are stores with no contacts! Please add at least one!');

      return;
    }

    this.spinnerService.showProgressBar.next(true);

    let seller = new FullB2BSellerDTO(this.seller!);

    seller.stores = JSON.parse(JSON.stringify(this.sellerStores));

    seller.stores[0].defaultStore = true;

    this.sellerUsers.forEach(u => {
      u.stores!.forEach(s => {
        let store = seller.stores.find(st => st.tempId === s.store)!;

        store.users.push(new SellerUserDTO(u, s.roles.includes(UserRoles.Contact), s.contactRoleDetails!.accEmailFlag, s.contactRoleDetails!.carPickupEmailFlag, s.contactRoleDetails!.documentEmailFlag));
      })
    });

    this.sellerPickups.forEach(r => {
      let store = seller.stores.find(s => s.tempId === r.store)!;

      store.pickups.push(new SellerPickupDTO(r));
    });

    this.sellerContacts.forEach(c => {
      let store = seller.stores.find(s => s.tempId === c.store)!;

      store.contacts.push(new SellerContactDTO({ ...c, tempId: undefined, store: undefined }));
    });

    seller.stores.forEach(s => delete (s.tempId));

    this.sellerService.createFullB2BSeller(seller).subscribe({
      next: resp => {
        this.spinnerService.showProgressBar.next(false);

        this.snackBar.positiveSentiment('Seller created successfully');

        this.router.navigate([`seller/company/${resp.sellerId}`]);
      },
      error: err => {
        this.snackBar.negativeSentiment(err.error);

        this.spinnerService.showProgressBar.next(false);
      }
    })
  }

  checkStoreContacts(): boolean {
    return this.sellerStores.some(s => {
      let users = this.sellerUsers.filter(u => u.stores!.some(st => st.store === s.tempId && st.roles.includes('contact')));

      let contacts = this.sellerContacts.filter(c => c.store === s.tempId);

      return (users.length + contacts.length) === 0;
    });
  }
}
