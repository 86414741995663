<div class="flex flex-col gap-9 h-[80vh] p-6 overflow-y-scroll">
  <div class="flex justify-between">
    <p class="text-2xl">Manage cars for online shop - Settings</p>
    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" [icon]="['far','xmark']" class="cursor-pointer"
      (click)="close()"></fa-icon>
  </div>

  <div class="flex flex-col gap-4">
    <p>Current selection categories:</p>

    <ng-container
      *ngTemplateOutlet="data; context: { $implicit: {title:'Car groups', elements:infos!.groups}}"></ng-container>

    <ng-container
      *ngTemplateOutlet="data; context: { $implicit: {title:'Status', elements:infos!.statuses}}"></ng-container>

    <ng-container
      *ngTemplateOutlet="data; context: { $implicit: {title:'VAT type', elements:infos!.vats}}"></ng-container>

    <ng-container
      *ngTemplateOutlet="data; context: { $implicit: {title:'Locations', elements:infos!.locations}}"></ng-container>
  </div>

  <div class="border-t border-white-500 pt-9 flex gap-3 text-orange">
    <fa-icon [icon]="['fal','circle-info']"></fa-icon>
    <p class="w-3/5">Please have in mind that current changes will take effect on all selected items. All car
      details will be replaced
      by the new ones below.</p>
  </div>

  <app-dropdown id="cars-status" label="Status" [options]="statuses" placeholder="Select status"
    [control]="statusControl" class="w-3/5"></app-dropdown>

  <app-dropdown id="cars-group" label="Car group" [options]="groups" placeholder="Select group" [control]="groupControl"
    class="w-3/5"></app-dropdown>

  <div>
    <mat-checkbox id="cars-vat-checkbox" data-cy="cars-vat-checkbox" [formControl]="changeVatControl"
      (change)="changeVat($event)">Change VAT</mat-checkbox>
  </div>

  <div class="flex gap-6 items-end">
    <p>Ex VAT</p>
    <app-toggle id="vat-process" [toggleFormControl]="vatStatusControl" [toggleDisabled]="!changeVatControl.value"
      (toggleEmitter)="changeProcess($event)"></app-toggle>
    <p>Incl nonrefundable VAT</p>
  </div>

  <app-dropdown id="vat-processing" [options]="vatProcess" label="VAT processing" class="w-3/5"
    [control]="vatProcessControl" [disabled]="true"></app-dropdown>

  <app-input id="operation-value" placeholder="Insert value to deduct/add" [isNumberInput]="true"
    label="VAT Value to deduct/add" class="w-3/5" [control]="vatValueControl"></app-input>

  <div class="flex gap-6">
    <primary-btn id="save-changes" btnText="Save" (clickEmitter)="updateCars()"></primary-btn>
    <secondary-btn id="cancel-changes" btnText="Cancel" (clickEmitter)="close()"></secondary-btn>
  </div>
</div>

<ng-template #data let-data>
  <div class="flex flex-col gap-3">
    <p class="text-sm text-white-300">{{data.title}}:</p>
    <div class="flex flex-wrap gap-3">
      <div class="bg-white-100 border border-white-500 rounded p-3" *ngFor="let el of data.elements">
        {{el}}
      </div>
    </div>
  </div>
</ng-template>
