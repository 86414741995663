import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { CreateOfferComponent } from "./create-offer/create-offer.component";
import { CreateOfferSettingsComponent } from './create-offer/create-offer-settings/create-offer-settings.component';
import { CreateOfferCarsComponent } from './create-offer/create-offer-cars/create-offer-cars.component';
import { CreateOfferCarsOfferPriceModalComponent } from './create-offer/create-offer-cars/create-offer-cars-offer-price-modal/create-offer-cars-offer-price-modal.component';
import { CreateOfferSettingsCarsModalComponent } from "./create-offer/create-offer-cars/create-offer-settings-cars-modal/create-offer-settings-cars-modal.component";
import { ViewOffersComponent } from './view-offers/view-offers.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    CreateOfferComponent,
    CreateOfferSettingsComponent,
    CreateOfferCarsComponent,
    CreateOfferSettingsCarsModalComponent,
    CreateOfferCarsOfferPriceModalComponent,
    ViewOffersComponent
  ],
})

export class OffersModule { }
