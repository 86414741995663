<div *ngIf="!(loading | async);else placeholder">
  <div class="flex justify-end w-full mb-9">
    <secondary-btn (clickEmitter)="deleteCars()">Delete cars</secondary-btn>
  </div>

  <app-table #carsTable [headersMap]="headers" [displayedColumns]="displayedColumns" [data]="cars"
    *ngIf="!(loadingTable | async);else placeholder" [hidePaginator]="true"></app-table>

  <mat-paginator [length]="results" [pageSizeOptions]="[5, 10, 20]"
    (page)="pageChange($event)">
  </mat-paginator>
</div>

<ng-template #placeholder>
  <div class="w-full">
    <app-table-placeholder></app-table-placeholder>
  </div>
</ng-template>
