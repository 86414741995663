import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BuyerStore, CreateBuyerStoreBody, EditBuyerStoreBody } from 'src/app/core/models/buyer.model';
import { BuyerSyncService } from 'src/app/core/services/buyer-flow-sync.service';
import { BuyerService } from 'src/app/core/services/buyer.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';

@Component({
  selector: 'app-view-buyer-stores',
  templateUrl: './view-buyer-stores.component.html',
  styleUrls: ['./view-buyer-stores.component.scss']
})
export class ViewBuyerStoresComponent implements OnInit {
  subscriptions: Subscription = new Subscription();

  @Input() isAdmin = false;
  editMode = false;

  createMode: boolean = false;
  buyerId = this.buyerSyncService.buyerId;

  stores: BuyerStore[] = JSON.parse(JSON.stringify(this.buyerSyncService.stores));
  storesDropdown: DropdownOption[] = this.buyerSyncService.stores.map(s => ({ value: s.id!, viewValue: s.name! }));

  dateCreated: string | undefined;
  dateUpdated: string | undefined;
  statusControl = new FormControl({ value: false, disabled: true });
  defaultStoreControl = new FormControl({ value: false, disabled: true });

  notes = '';

  companyCountry = this.coreService.countries.find(c => c.id === this.buyerSyncService.buyer!.countryDetails.countryId)!;

  makes: DropdownOption[] = this.buyerSyncService.makes;
  users: string[] = [];

  storeToggleControl: FormControl = new FormControl();

  storeForm = this.fb.group({
    name: ['', Validators.required],
    postalCode: ['', Validators.required],
    address: ['', Validators.required],
    city: ['', Validators.required],
    carSalesEst: [0, Validators.required],
    makes: new FormControl<string[]>([''], Validators.required),
    countryBank: [false, Validators.required]
  })

  constructor(
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private buyerSyncService: BuyerSyncService,
    private coreService: CoreService,
    private spinnerService: SpinnerHandlerService,
    private buyerService: BuyerService
  ) { }

  async ngOnInit() {
    this.subscriptions.add(this.buyerSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.storeForm.invalid || this.storeForm.dirty) {
          let goNext = await this.buyerSyncService.showUnsavedChangesModal();

          goNext ? this.buyerSyncService.setCurrentTab(value) : 0;
        } else {
          this.buyerSyncService.setCurrentTab(value);
        }
      }
    ));

    this.storeToggleControl.setValue(this.stores[0].id);

    this.patchForm(this.stores[0]);

    this.storeForm.disable();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveStore() {
    if (this.spinnerService.showProgressBar.value) return;

    if (this.storeForm.invalid) {
      this.snackbar.negativeSentiment('You need to complete all required fields');
      return;
    }

    this.spinnerService.showProgressBar.next(true);

    if (this.createMode) {
      let body = new CreateBuyerStoreBody(this.storeForm.value, this.buyerId!, this.notes, this.companyCountry.id);

      this.buyerService.createBuyerStore(body).subscribe({
        next: resp => {
          this.buyerSyncService.stores.push(resp);

          this.storesDropdown = this.buyerSyncService.stores.map(s => ({ value: s.id!, viewValue: s.name! }));

          this.storeToggleControl.setValue(resp.id);

          this.spinnerService.showProgressBar.next(false);
          this.createMode = false;

          this.storeForm.markAsPristine();

          this.snackbar.positiveSentiment("Store created succesffully!");

        },
        error: err => {
          this.spinnerService.showProgressBar.next(false);

          this.snackbar.negativeSentiment(err.error);
        }
      });
    } else {
      let store = this.stores.find(s => s.id === this.storeToggleControl.value);

      let body = new EditBuyerStoreBody(this.storeForm.value, this.buyerId!, this.storeToggleControl.value, this.companyCountry.id, this.defaultStoreControl.value!, this.notes, this.statusControl.value!);

      this.buyerService.editBuyerStore(body).subscribe({
        next: resp => {
          this.buyerSyncService.stores = resp;
          this.stores = resp;

          this.snackbar.positiveSentiment('Store updated');

          this.spinnerService.showProgressBar.next(false);
        },
        error: err => {
          this.spinnerService.showProgressBar.next(false);

          this.snackbar.negativeSentiment(err.error);
        }
      })
    }
  }

  addNewStore() {
    if (this.spinnerService.showProgressBar.value) return;

    if (this.createMode) {
      this.snackbar.negativeSentiment("You already started to create a new store");
      return;
    }

    this.dateCreated = undefined;
    this.dateUpdated = undefined;
    this.notes = '';
    this.defaultStoreControl.reset(false);
    this.users = [];

    this.storesDropdown.push({ value: 'newStore', viewValue: 'New Store' });
    this.storeToggleControl.setValue('newStore');
    this.storeForm.reset({
      countryBank: false
    });

    this.createMode = true;
  }

  changeStore(storeId: string) {
    if (this.createMode) {
      const currentStoreId = this.storeToggleControl.value;

      const dialogRef = this.dialog.open(
        UnsavedChangesModalComponent, {
        width: '550px',
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let store = this.stores.find(s => s.id === storeId)!;

          this.storesDropdown = this.stores.map(s => ({ value: s.id!, viewValue: s.name! }));

          this.createMode = false;

          this.patchForm(store);
        } else {
          this.storeToggleControl.setValue(currentStoreId);
        }
      });
    } else {
      let store = this.stores.find(s => s.id === storeId)!;

      this.patchForm(store);
    }
  }

  patchForm(store: BuyerStore) {
    this.storeForm.patchValue({
      name: store.name,
      address: store.address,
      city: store.city,
      postalCode: store.postalCode,
      carSalesEst: store.carSalesEst,
      countryBank: store.countryBank,
      makes: store.makes
    });

    this.notes = store.note!;

    this.statusControl.setValue(store.status!);
    this.defaultStoreControl.setValue(store.defaultStore);
    this.users = this.buyerSyncService.buyerGetResponse!.users.filter(u => u.details.buyerStores.find(s => s.store === store.id)).map(u => `${u.firstName} ${u.lastName}`);
    this.dateCreated = store.createdAt;
    this.dateUpdated = store.modifiedAt;
  }

  copyData() {
    if (this.buyerSyncService.buyer) {
      let buyer = this.buyerSyncService.buyer!;
      this.storeForm.patchValue({
        name: buyer.name,
        address: buyer.address,
        city: buyer.city,
        postalCode: buyer.postalCode
      })
    } else {
      this.snackbar.negativeSentiment('No company saved!');
    }
  }

  enterEditMode() {
    this.editMode = true;
    this.storeForm.enable();
    this.statusControl.enable();
  }

  makeDefaultStore() {
    if (this.spinnerService.showProgressBar.value) return;

    let store = this.stores.find(s => s.id === this.storeToggleControl.value!)!;

    let body = new EditBuyerStoreBody(store, this.buyerId!, store.id, store.countryDetails.countryId, true, store.note, true);

    this.spinnerService.showProgressBar.next(true);

    this.buyerService.editBuyerStore(body).subscribe({
      next: resp => {
        this.buyerSyncService.stores = resp;
        this.stores = resp;

        this.defaultStoreControl.setValue(true);

        this.spinnerService.showProgressBar.next(false);
      },
      error: err => {
        this.spinnerService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }
}
