import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription, take } from 'rxjs';
import { CarStatus } from 'src/app/core/models/info.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'app-car-view',
  templateUrl: './car-view.component.html',
  styleUrls: ['./car-view.component.scss'],
})

export class CarViewComponent implements OnInit {
  loadingInfo: BehaviorSubject<boolean> = this.carSyncService.loadingInfo;

  subscription = new Subscription();

  page: string = 'ad-settings';

  routes = [
    {
      viewValue: "Car details",
      value: "car-details"
    },
    {
      viewValue: "Equipment",
      value: "car-equipment"
    },
    {
      viewValue: "Images",
      value: "car-images"
    },
    {
      viewValue: "Business case",
      value: "businessCase"
    },
    {
      viewValue: "Documents",
      value: "documents"
    },
    {
      viewValue: "Transport",
      value: "transport"
    },
  ];

  constructor(private activatedRoute: ActivatedRoute,
    private location: Location,
    private carSyncService: CarFlowSyncService,
    private router: Router,
    private coreService: CoreService) {
  }

  ngOnInit() {
    if (!this.coreService.isAdmin) {
      this.router.navigate(['404']);
      return;
    }

    this.subscription.add(this.carSyncService.currentTab$.subscribe(
      (page) => {
        if (this.routes.find(r => r.value === page)) {
          this.page = page;
          this.carSyncService.carId ? this.location.replaceState(`car/${page}/${this.carSyncService.carId}`) : this.location.replaceState(`car/${page}`)
        }
      }
    ));

    this.activatedRoute.params.subscribe(params => {
      if (params['page']) {
        let route = this.routes.find(r => r.value === params['page']);

        this.page = route ? route.value : 'car-details';
      }

      if (params['car-id']) {
        this.carSyncService.carId = params['car-id'];
      }

      this.carSyncService.loadCar();
    });

    this.loadingInfo.asObservable().pipe(take(2)).subscribe(resp => {
      if (!resp && this.carSyncService.carDetails!.carStatus === CarStatus.Sold) this.router.navigate(['404']);
    });
  }

  ngOnDestroy() {
    this.carSyncService.carDetails = undefined;
    this.carSyncService.selectedSeller = undefined;
    this.carSyncService.bc = [];
    this.carSyncService.c2vEq = [];
    this.carSyncService.carId = '';
    this.carSyncService.loadingInfo.next(true);
    this.carSyncService.setCurrentTab('init');

    this.subscription.unsubscribe();
  }

  changePage(page: string) {
    this.carSyncService.changeTab(page);
  }
}
