import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CreateSellerContactBody } from 'src/app/core/models/seller-models/seller-edit.model';
import { ISellerContact } from 'src/app/core/models/seller.model';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-view-b2b-seller-contacts',
  templateUrl: './view-b2b-seller-contacts.component.html',
  styleUrls: ['./view-b2b-seller-contacts.component.scss']
})
export class ViewB2bSellerContactsComponent implements OnInit {
  isAdmin = this.coreService.isAdmin;
  editMode = false;

  createMode: boolean = false;
  sellerId = this.b2bSyncService.sellerId;

  public routeSubscription: Subscription = new Subscription();

  dateCreated: string | undefined;
  dateUpdated: string | undefined;
  statusControl = new FormControl({ value: false, disabled: true });

  stores: DropdownOption[] = this.b2bSyncService.sellerResponse!.stores.map(s => ({ viewValue: s.name!, value: s.id! }));
  storesControl = new FormControl(this.stores[0].value);
  storeContacts: ISellerContact[] = [];

  contactsDropdown: DropdownOption[] = [];
  contactControl = new FormControl();

  storeUsers: string[] = [];

  contactDetailsForm = this.fb.group({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    store: '',
    accountingEmail: false,
    carPickupEmail: false,
    documentsEmail: false
  });

  usersContact: string[] = [];

  constructor(private b2bSyncService: B2BSellerSyncService,
    private sellerService: SellerService,
    private snackbar: SnackbarService,
    private fb: FormBuilder,
    private spinnerService: SpinnerHandlerService,
    private coreService: CoreService) { }

  async ngOnInit(): Promise<void> {
    await this.selectStore(this.stores[0].value);

    this.routeSubscription = this.b2bSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.contactDetailsForm.invalid) {
          this.snackbar.negativeSentiment('You need to complete to continue');
        } else {
          this.b2bSyncService.setCurrentTab(value);
        }
      }
    );

    this.contactDetailsForm.disable();
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  saveContact() {
    if (this.spinnerService.showProgressBar.value) return;

    if (this.contactDetailsForm.invalid) {
      this.snackbar.negativeSentiment('You need to complete all required fields');
      return;
    }

    let contact = new CreateSellerContactBody(this.sellerId!, this.storesControl.value!, this.contactDetailsForm.value);

    this.spinnerService.showProgressBar.next(true);

    if (this.createMode) {
      this.sellerService.createSellerContact(contact).subscribe(resp => {
        // this.b2bSyncService.contacts.push(resp);

        // this.loadContactDropdown(resp.store!);

        // this.contactControl.setValue(resp.id);

        // this.createMode = false;

        this.spinnerService.showProgressBar.next(false);

        this.snackbar.positiveSentiment("Contact created successfully!");
      })
    } else {
      // this.sellerService.editSellerStoreContact(contact, this.contactControl.value).subscribe(resp => {
      //   this.b2bSyncService.contacts[this.b2bSyncService.contacts.findIndex(c => c.id === resp.id)] = resp;

      //   this.loadContactDropdown(resp.store!);

      //   this.spinnerService.showProgressBar.next(false);

      //   this.snackbar.positiveSentiment("Contact edited successfully!");
      // })
    }
  }

  async selectStore(storeId: string) {
    if (this.createMode) {
      const currentContactId = this.contactControl.value;
      const currentStoreId = this.storesControl.value;

      let resp = await this.coreService.openUnsavedChangesModal();

      if (resp) {
        this.createMode = false;

        this.continueChangeStoreFlow(storeId);
      } else {
        this.storesControl.setValue(currentStoreId)
        this.contactControl.setValue(currentContactId)
      }
    } else {
      this.continueChangeStoreFlow(storeId)
    }
  }

  continueChangeStoreFlow(storeId: string) {
    this.contactDetailsForm.reset();
    this.contactControl.reset();

    this.contactDetailsForm.controls.store.setValue(storeId);

    this.loadContactDropdown(storeId);

    this.getUserStoreContacts(storeId);

    this.storeContacts = this.b2bSyncService.sellerResponse!.stores.find(s => s.id === storeId)!.contacts;

    this.storeUsers = this.b2bSyncService.sellerResponse!.users.filter(u => u.details.sellerStores!.find(s => s.store === storeId)).map(u => u.email!);

    if (this.storeContacts.length > 0) {
      this.contactControl.setValue(this.storeContacts[0].email!);
      this.changeContact(this.storeContacts[0].email);
    } else {
      this.contactDetailsForm.disable();
    }

    this.createMode = false;
  }

  loadContactDropdown(storeId: string) {
    this.contactsDropdown = this.b2bSyncService.sellerResponse!.stores.find(s => s.id === storeId)!.contacts.map(c => ({ viewValue: `${c.firstName} ${c.lastName}`, value: c.email }));
  }

  addContact() {
    if (this.spinnerService.showProgressBar.value) return;

    if (this.createMode) return this.snackbar.negativeSentiment('You already started to create a new contact!');

    this.createMode = true;

    this.contactsDropdown.push({ value: 'newContact', viewValue: 'New Contact' });

    this.contactDetailsForm.reset({
      carPickupEmail: false,
      documentsEmail: false,
      accountingEmail: false
    });

    this.contactDetailsForm.enable();

    this.contactControl.setValue('newContact');
  }

  async changeContact(contactId: string) {
    if (this.createMode) {
      const currentContactId = this.contactControl.value;

      let resp = await this.coreService.openUnsavedChangesModal();

      if (resp) {
        if (currentContactId) this.loadContactDropdown(this.storesControl.value!);

        this.continueChangeContactFlow(contactId);
      } else {
        this.contactControl.setValue(currentContactId);
      }
    } else {
      this.continueChangeContactFlow(contactId);
    }
  }

  continueChangeContactFlow(contactId: string) {
    let contact = this.storeContacts.find(c => c.email === contactId);

    this.patchContactForm(contact!);
  }

  patchContactForm(contact: ISellerContact) {
    this.contactDetailsForm.patchValue({
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phone,
      accountingEmail: contact.accountingEmail,
      carPickupEmail: contact.carPickupEmail,
      documentsEmail: contact.documentsEmail
    });

    this.createMode = false;

    if (this.editMode) {
      this.contactDetailsForm.enable();
    }
  }

  enterEditMode() {
    this.editMode = true;

    if (this.contactsDropdown.length > 0) this.contactDetailsForm.enable();
  }

  getUserStoreContacts(storeId: string) {
    this.usersContact = this.b2bSyncService.sellerResponse!.users.filter(u => u.details.sellerStores!.some(st => st.store === storeId && st.roles.includes('contact'))).map(u => u.email);
  }
}
