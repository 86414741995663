import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderCar } from 'src/app/core/models/order.model';
import { OrdersService } from 'src/app/core/services/orders.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import _ from 'lodash';
import { OrderCarConfirmation } from 'src/app/core/models/info.model';
import { OrderStatus } from '../../../orders-view/orders-view.component';

@Component({
  selector: 'app-order-process-car-confirmation',
  templateUrl: './order-process-car-confirmation.component.html',
  styleUrls: ['./order-process-car-confirmation.component.scss']
})

export class OrderProcessCarConfirmationComponent implements OnInit {
  headers: TableHeaderMap[] = [
    {
      value: 'carAvailability',
      tableView: 'Confirmation'
    },
    {
      value: 'carConfig.sellerName',
      tableView: 'seller'
    },
    {
      value: 'carPricing.invoiceSellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatString',
      tableView: 'vat type'
    },
    {
      value: 'carConfig.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carConfig.make',
      tableView: 'make'
    },
    {
      value: 'carConfig.model',
      tableView: 'model'
    },
    {
      value: 'carConfig.vin',
      tableView: 'vin'
    },
    {
      value: 'carConfig.mileage',
      tableView: 'mileage'
    },
    {
      value: 'carConfig.firstReg',
      tableView: 'first registration'
    },
    {
      value: 'carConfig.color',
      tableView: 'color'
    },
  ];

  displayedColumns = ['confirm', 'cancel', ...this.headers.map(h => h.value)];

  cars: OrderCar[] = this.orderFlowService.carList;

  removedCars = this.orderFlowService.order?.removedCars;

  removedCarsColumns = ['confirm', ...this.headers.map(h => h.value)];

  orderConfirmationStatus = OrderCarConfirmation;

  @ViewChild('removedCarsTable') removedCarsTable: AppTableComponent | undefined;
  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;

  constructor(private orderService: OrdersService,
    private orderFlowService: OrdersFlowSyncService,
    private snackbar: SnackbarService,
    private spinnerService: SpinnerHandlerService) { }

  ngOnInit() {
    if (this.orderFlowService.orderStatus === OrderStatus.FINISHED) {
      this.displayedColumns.splice(0, 2);
      this.removedCarsColumns.splice(0, 1);
    }
  }

  confirmCar(car: OrderCar, status: string) {
    let body = {
      orderId: this.orderFlowService.orderId!,
      sellerId: car.carConfig.seller,
      carMainInfoId: car.carMainInfoId,
      carAvailability: status
    };

    this.spinnerService.showProgressBar.next(true);

    this.orderService.updateCarAvailability(body).subscribe({
      next: resp => {
        if (status === this.orderConfirmationStatus.Confirmed) {
          let index = this.orderFlowService.carList.findIndex(c => c.carMainInfoId === car.carMainInfoId);

          this.orderFlowService.carList[index].carAvailability = this.orderConfirmationStatus.Confirmed;
        } else {
          this.orderFlowService.loadProfits(resp);
        }

        this.cars = this.orderFlowService.carList;

        this.removedCars = this.orderFlowService.order?.removedCars;

        this.spinnerService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Car updated');
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinnerService.showProgressBar.next(false);
      }
    })
  }

  getValue(element: any, value: TableHeaderMap): string {
    return _.get(element, value.value.split('.'));
  }

  readdRemovedCard(car: OrderCar) {
    this.spinnerService.showProgressBar.next(true);

    this.orderService.updateRemovedCarAvailability(this.orderFlowService.orderId!, car.carMainInfoId).subscribe({
      next: resp => {
        this.orderFlowService.loadProfits(resp);

        this.cars = this.orderFlowService.carList;

        this.removedCars = this.orderFlowService.order?.removedCars;

        this.spinnerService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Car updated');
      },
      error: err => {
        this.spinnerService.showProgressBar.next(false);
      }
    })
  }
}
