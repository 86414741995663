import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CarsOnListImportList } from 'src/app/core/models/cars-inventory.model';
import { CarService } from 'src/app/core/services/car.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-cars-on-list',
  templateUrl: './cars-on-list.component.html',
  styleUrls: ['./cars-on-list.component.scss']
})
export class CarsOnListComponent implements OnInit {
  loading = new BehaviorSubject(true);

  headersTable: TableHeaderMap[] = [
    {
      tableView: 'available Until',
      value: 'availableUntil'
    },
    {
      tableView: 'name',
      value: 'name'
    },
    {
      tableView: 'car Group',
      value: 'carGroup'
    },
    {
      tableView: 'active',
      value: 'active'
    }
  ]

  displayedColumns = this.headersTable.map(t => t.value).concat(['edit']);

  lists: CarsOnListImportList[] = [];

  constructor(private carService: CarService,
    private router: Router) { }

  ngOnInit() {
    forkJoin({
      lists: this.carService.getCarLists(),
      groups: this.carService.getCarGroups()
    }).subscribe(resp => {
      this.lists = resp.lists.map(l => ({ ...l, carGroup: (resp.groups.find(g => g.id === l.carGroup))!.name }));

      this.loading.next(false);
    })
  }

  goToList(list: CarsOnListImportList) {
    this.router.navigate([`cars-on-list/${list.id}`])
  }
}
