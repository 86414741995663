import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { OrderCar } from 'src/app/core/models/order.model';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderSelectPricesDetailsModalComponent } from './order-select-prices-details-modal/order-select-prices-details-modal.component';
import { SellerFilterTableComponent } from '../../../order-shared-components/seller-filter-table/seller-filter-table.component';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';

@Component({
  selector: 'app-order-select-prices',
  templateUrl: './order-select-prices.component.html',
  styleUrls: ['./order-select-prices.component.scss']
})
export class OrderSelectPricesComponent implements OnInit {
  updateSubscription = new Subscription();

  headersMap: TableHeaderMap[] = [{
    "value": "sellerName",
    "tableView": "seller"
  }, {
    "value": "make",
    "tableView": "make"
  }, {
    "value": "model",
    "tableView": "model"
  }, {
    "value": "regNo",
    "tableView": "reg No"
  }, {
    "value": "vin",
    "tableView": "vin"
  }, {
    "value": "showRoomReady",
    "tableView": "showroom Ready"
  }, {
    "value": "offeredPrice",
    "tableView": "offered Price"
  }, {
    "value": "buyingPrice",
    "tableView": "buying Price"
  }, {
    "value": "minSellingPrice",
    "tableView": "min Selling Price"
  }, {
    "value": "sellingPrice",
    "tableView": "selling Price"
  }, {
    "value": "vatType",
    "tableView": "Selling price VAT"
  }, {
    "value": "invoiceSellingPrice",
    "tableView": "invoice Selling Price"
  }, {
    "value": "actualProfit",
    "tableView": "est. profit"
  }, {
    "value": "documentsDetails.cocDocuments.required",
    "tableView": "requires COC"
  }, {
    "value": "documentsDetails.cocDocuments.cocValue",
    "tableView": "invoiced coc Value"
  }, {
    "value": "damages",
    "tableView": "Initial declared damages"
  }, {
    "value": "finalDamages",
    "tableView": "final Damages"
  }, {
    "value": "transportationFee",
    "tableView": "invoiced transportation Fee"
  }, {
    "value": "firstReg",
    "tableView": "first registration"
  }
  ];

  displayedColumns = this.headersMap.map(c => c.value).concat(['details']);

  cars: OrderCar[] = this.orderService.carList;

  @ViewChild('carsPricesTable') carsTable: SellerFilterTableComponent | undefined;

  constructor(private dialog: MatDialog,
    private orderService: OrdersFlowSyncService) { }

  ngOnInit(): void {
    this.updateSubscription = this.orderService.carsTableSubjectEvent$.subscribe(resp => {
      this.carsTable?.updateTable(this.orderService.carList);
    });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  openOrderSelectPricesDetailsModalComponent(car: OrderCar) {
    const dialogRef = this.dialog.open(
      OrderSelectPricesDetailsModalComponent, {
      width: '900px',
      maxWidth: '95vw',
      autoFocus: false,
      disableClose: true,
      data: {
        car: car,
        transportMatrix: this.orderService.transportMatrix,
        transportByCars2click: this.orderService.transportByCars2click.value,
        buyerLoc: this.orderService.orderSettings?.buyer.countryDetails?.countryId,
        cocValue: this.orderService.cocValue,
        photoEditValue: this.orderService.photoEditValue
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      this.orderService.carList = <OrderCar[]>this.carsTable?.carsTable?.dataSource.data;
    });
  }
}
