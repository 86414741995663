<app-main-content-layout>
  <div class="title">
    <p>Chat</p>
  </div>

  <div class="grid grid-cols-2 gap-6">
    <div class="flex flex-col gap-6">
      <div class="p-3 rounded-full bg-white-500 text-sm w-fit cursor-pointer" (click)="changeSubject(subject.value)"
        [ngClass]="{'bg-blue-500 text-white': subject.value === subjectSelected}" *ngFor="let subject of subjects">
        <p>{{subject.viewValue}}</p>
      </div>
    </div>

    <div class="flex flex-col justify-end gap-9 pl-6 border-l border-white-500">
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-2 max-w-[70%]">
          <div class="flex gap-3 text-xs items-center">
            <p class="text-green-100">Name</p>

            <div class="rounded-full bg-white-800 w-1 h-1"></div>

            <p class="text-white-800">date ago</p>
          </div>

          <div class="py-2 px-3 rounded bg-white-400 text-sm">
            <p>But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying
              consequences, or one who avoids a pain that produces no resultant pleasure?</p>
          </div>
        </div>

        <div class="flex flex-col gap-2 max-w-[70%] self-end">
          <div class="flex gap-3 text-xs self-end">
            <p class="text-white-800">date ago</p>
          </div>

          <div class="py-2 px-3 rounded bg-blue-500 text-white text-sm">
            <p>But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying
              consequences, or one who avoids a pain that produces no resultant pleasure?</p>
          </div>
        </div>
      </div>

      <div class="flex gap-6">
        <textarea [(ngModel)]="message" placeholder="Insert message here"
          class="p-3 border border-white-500 rounded outline-0 w-11/12"></textarea>

        <fa-icon (click)="sendMessage()" class="self-end text-2xl cursor-pointer active:scale-95"
          [icon]="['far','paper-plane-top']"></fa-icon>
      </div>
    </div>
  </div>
</app-main-content-layout>
