<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
    <div class="flex justify-between">
      <p class="font-bold text-2xl"><span *ngIf="createMode;else edit">Create</span> online seller</p>
      <ng-template #edit><span>Edit</span></ng-template>
      <div class="flex gap-6">
        <primary-btn id="save" [blueBtn]="true" btnText="Save" (clickEmitter)="saveSeller()"></primary-btn>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-6">
      <app-main-content-layout>
        <div class="title">
          <p>Seller details</p>
        </div>

        <div class="grid grid-cols-4 gap-4 border-b border-white-500 pb-6 items-center">
          <app-dropdown id="country-code" class="col-span-1" [label]="'State code'" [control]="countryIsoControl"
            [options]="countryIsoCodes" *ngIf="!validatedVAT" placeholder="Pick one">
          </app-dropdown>

          <app-input id="vat" [label]="'VAT'" class="col-span-1" [control]="vatControl"
            placeholder="Insert VAT"></app-input>

          <primary-btn id="validate-vat" (clickEmitter)="validateVAT()" *ngIf="!validatedVAT"
            [disableBtn]="!vatControl.value || vatControl.value.length <= 0 || !countryIsoControl.value || countryIsoControl.value.length <= 0"
            class="col-span-1 col-start-1" [btnText]="'Validate VAT'"></primary-btn>

          <secondary-btn id="reset-vat" class="col-span-1" [btnText]="'Reset VAT'" (clickEmitter)="resetVat()"
            [disableBtn]="!vatControl.value || vatControl.value.length <= 0 ">
          </secondary-btn>

          <secondary-btn id="vat-rules" class="col-span-1 flex items-center text-blue-100" [btnText]="'VAT rules'" [disableBtn]="true" *ngIf="!validatedVAT">
          </secondary-btn>
        </div>

        <app-main-content-row-layout>
          <p>Seller name*</p>
          <app-input id="seller-name" placeholder="Insert seller name" class="secondContent"
            [control]="sellerDetailsForm.controls.name"></app-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Country*</p>
          <app-dropdown id="countries" placeholder="Select country" class="secondContent" [options]="countries"
            [control]="sellerDetailsForm.controls.country"></app-dropdown>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>City*</p>
          <app-input id="city" placeholder="Insert city" class="secondContent"
            [control]="sellerDetailsForm.controls.city"></app-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Address</p>
          <app-input id="address" placeholder="Insert address" class="secondContent"
            [control]="sellerDetailsForm.controls.address"></app-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>ZIP Code</p>
          <app-input id="postalcode" placeholder="Insert zip code" class="secondContent"
            [control]="sellerDetailsForm.controls.postalCode"></app-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <div class="flex items-center">
            <p>Email*</p>

            <p class="relative -left-[2.9375rem] top-6 text-sm text-red opacity-0 whitespace-nowrap"
              [ngClass]="{'opacity-100':sellerDetailsForm.controls.email.hasError('email')}">Insert valid email</p>
          </div>

          <app-input id="email" class="secondContent" [control]="sellerDetailsForm.controls.email" placeholder="Insert email">
          </app-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Phone</p>
          <app-input id="phone" placeholder="Insert phone" class="secondContent"
            [control]="sellerDetailsForm.controls.phone"></app-input>
        </app-main-content-row-layout>
      </app-main-content-layout>

      <app-main-content-layout>
        <div class="title">
          <p>Seller info</p>
        </div>

        <app-main-content-row-layout>
          <p>Status</p>
          <app-toggle id="status" class="secondContent" [toggleFormControl]="statusControl"></app-toggle>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>VAT Status</p>
          <app-toggle id="vat-status" class="secondContent" [toggleFormControl]="vatStatusControl"></app-toggle>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Date created</p>

          <app-read-only-input class="secondContent" *ngIf="dateCreated; else noDate">
            {{dateCreated | date:'dd/MM/yyyy'}}
          </app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Last update</p>

          <app-read-only-input class="secondContent" *ngIf="dateUpdated; else noDate">
            {{dateUpdated | date:'dd/MM/yyyy'}}
          </app-read-only-input>
        </app-main-content-row-layout>
      </app-main-content-layout>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="grid grid-cols-2 gap-6 gap-y-9">
    <div class="placeholder-animation w-1/4 h-6 col-span-1"></div>

    <div class="placeholder-animation w-1/4 h-6 col-span-1 place-self-end"></div>

    <app-main-content-placeholder class="col-span-1"></app-main-content-placeholder>
    <app-main-content-placeholder class="col-span-1"></app-main-content-placeholder>
  </div>
</ng-template>

<ng-template #noDate>
  <app-read-only-input>
    <p>dd/mm/yy</p>
  </app-read-only-input>
</ng-template>
