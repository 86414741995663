import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { CarDetails } from 'src/app/core/models/car.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

interface UpdateCarsModalData {
  cars: CarDetails[]
}

interface CarInfoGrouped {
  groups: string[],
  statuses: string[],
  vats: string[],
  locations: string[]
}

export interface BulkUpdateCarsBody {
  carIds: string[],
  carGroup?: string,
  status?: string,
  vatStatus?: boolean,
  vatProcessing?: string,
  vatValue?: number,
  inactiveFrom?: Date
}

@Component({
  selector: 'app-update-cars-modal',
  templateUrl: './update-cars-modal.component.html',
  styleUrls: ['./update-cars-modal.component.scss']
})

export class UpdateCarsModalComponent implements OnInit {
  infos: CarInfoGrouped | undefined;
  vatProcess: DropdownOption[] = [{ value: 'Deduct', viewValue: 'Deduct' }, { value: 'Add', viewValue: 'Add' }];
  vatValueControl = new FormControl({ value: 0, disabled: true });
  vatStatusControl = new FormControl(false);
  vatProcessControl = new FormControl();
  changeVatControl = new FormControl(false);

  statuses: DropdownOption[] = [];
  groups: DropdownOption[] = [];

  statusControl = new FormControl('', Validators.required);
  groupControl = new FormControl('', Validators.required);

  constructor(public dialogRef: MatDialogRef<UpdateCarsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateCarsModalData,
    private carService: CarService,
    private snackbar: SnackbarService,
    private spinnerService: SpinnerHandlerService) { }

  ngOnInit(): void {
    this.infos = {
      groups: this.data.cars.map(c => c.carGroup!).filter((v, i, a) => a.indexOf(v) === i),
      statuses: this.data.cars.map(c => c.carStatus!).filter((v, i, a) => a.indexOf(v) === i),
      vats: this.data.cars.map(c => c.carStatus!).filter((v, i, a) => a.indexOf(v) === i),
      locations: this.data.cars.map(c => c.location!).filter((v, i, a) => a.indexOf(v) === i)
    };


    forkJoin({
      statuses: this.carService.getCarStatuses(),
      groups: this.carService.getCarGroups()
    }).subscribe(resp => {
      this.statuses = resp.statuses.map(s => ({ viewValue: s.status, value: s.id }))
      this.groups = resp.groups.map(s => ({ viewValue: s.name, value: s.id }))
    });
  }

  close() {
    this.dialogRef.close();
  }

  updateCars() {
    if (!this.statusControl.value && !this.groupControl.value && !this.changeVatControl.value) {
      this.snackbar.negativeSentiment('No changes made!');
      return;
    }

    let bulk: BulkUpdateCarsBody = {
      carIds: this.data.cars.map(c => c.carMainInfoId!)
    }

    if (this.statusControl.value) {
      bulk.status = this.statusControl.value;
    }

    if (this.groupControl.value) {
      bulk.carGroup = this.groupControl.value;
    }

    if (this.changeVatControl.value) {
      bulk.vatStatus = this.vatStatusControl.value!;
      bulk.vatProcessing = this.vatProcessControl.value;
      bulk.vatValue = this.vatValueControl.value!;
    }

    this.spinnerService.showProgressBar.next(true);

    this.carService.editMultipleCars(bulk).subscribe(resp => this.spinnerService.showProgressBar.next(false));
  }

  changeVat(event: MatCheckboxChange) {
    if (event.checked) {
      this.vatValueControl.enable();
      this.vatProcessControl.setValue(this.vatProcess[0].value);
    } else {
      this.vatProcessControl.reset();
      this.vatValueControl.reset();
      this.vatStatusControl.reset();
    }
  }

  changeProcess(event: boolean) {
    event ? this.vatProcessControl.setValue(this.vatProcess[1].value) : this.vatProcessControl.setValue(this.vatProcess[0].value);
  }
}
