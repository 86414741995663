<div class="flex flex-col gap-9">
  <p class="text-2xl">{{company ? 'Edit' : 'Add new'}} company invoice</p>

  <div class="grid grid-cols-2 gap-x-6 gap-y-9">
    <app-input id="name-modal" placeholder="Insert company name" [control]="nameControl"
      label="Company name"></app-input>

    <app-input-number id="value-modal" placeholder="Insert VAT value %" label="VAT value %"
      [control]="valueControl"></app-input-number>

    <app-dropdown placeholder="Select company country" id="country-modal" label="Company country"
      [control]="countryControl" [options]="countries"></app-dropdown>

    <app-input placeholder="Insert address" [control]="addressControl" label="Company address"></app-input>

    <primary-btn id="add-company-modal" btnText="Add company" (clickEmitter)="confirm()"></primary-btn>
  </div>
</div>
