import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Order, OrderCar } from 'src/app/core/models/order.model';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-order-view-kam-orders-settings',
  templateUrl: './order-view-kam-orders-settings.component.html',
  styleUrls: ['./order-view-kam-orders-settings.component.scss']
})
export class OrderViewKamOrdersSettingsComponent implements OnInit {
  @Input() order: Order | undefined;
  @Input() cars: OrderCar[] = [];

  notes = '';

  orderSettings = this.fb.group({
    reqDelDate: new Date,
    estPaymentDate: new Date,
    transportC2C: true,
    separateTransportInv: false,
    oneInvoiceCar: false
  });

  headers: TableHeaderMap[] = [
    {
      value: 'carConfig.sellerName',
      tableView: 'seller'
    },
    {
      value: 'carConfig.regNo',
      tableView: 'Registration number'
    },
    {
      value: 'carConfig.vin',
      tableView: 'vin'
    },
    {
      value: 'carConfig.make',
      tableView: 'make'
    },
    {
      value: 'carConfig.model',
      tableView: 'model'
    },
    {
      value: 'carConfig.firstReg',
      tableView: 'first registration'
    },
    {
      value: 'carConfig.mileage',
      tableView: 'mileage'
    },
    {
      value: 'carConfig.color',
      tableView: 'color'
    },
    {
      value: 'carAvailability',
      tableView: 'car Availability'
    },
    {
      value: 'carPricing.actualBuyingPrice',
      tableView: 'actual Buying Price'
    },
    {
      value: 'carPricing.currency',
      tableView: 'buying price currency'
    },
    {
      value: 'carPricing.actualBuyingPriceEuro',
      tableView: 'actual Buying Price euro'
    },
    {
      value: 'carPricing.offerDetails.offeredValue',
      tableView: 'offered Price'
    },
    {
      value: 'carPricing.buyingPrice',
      tableView: 'Buying price'
    },
    {
      value: 'carConfig.vatStatus',
      tableView: 'seller invoice VAT'
    },
    {
      value: 'carPricing.minSellingPrice',
      tableView: 'minimum selling price'
    },
    {
      value: 'carPricing.shopPrice',
      tableView: 'Online Selling price'
    },
    {
      value: 'carPricing.invoiceSellingPrice',
      tableView: 'invoice selling price'
    },
    {
      value: 'carPricing.actualProfit',
      tableView: 'profit'
    },
    {
      value: 'documentsDetails.documents.coc.required',
      tableView: 'requires COC'
    },
    {
      value: 'documentsDetails.documents.coc.cocValue',
      tableView: 'coc value'
    },
    {
      value: 'carConfig.damages',
      tableView: 'Initial declared damages'
    },
    {
      value: 'carConfig.actualDamages',
      tableView: 'final damage'
    },
    {
      value: 'carPricing.transportationActualFee',
      tableView: 'Transportation fee'
    }
  ];

  displayedColumns = this.headers.map(h => h.value);

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.orderSettings.disable();

    this.orderSettings.patchValue({
      reqDelDate: this.order!.requestedDeliveryDate,
      estPaymentDate: this.order!.estimatedPaymentDate,
      transportC2C: this.order!.transportDetails.transportByC2C,
      separateTransportInv: this.order!.separateInvoiceForTransport,
      oneInvoiceCar: this.order!.oneInvoicePerCar
    });

    this.notes = this.order!.notes;
  }

}
