import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-blue-btn',
  templateUrl: './blue-btn.component.html',
  styleUrls: ['./blue-btn.component.scss']
})
export class BlueBtnComponent implements OnInit {
  @Input() btnText: string = '';
  @Input() disableBtn: boolean = false;
  @Input() id = "id";

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  emitClick(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }
}
