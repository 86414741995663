<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">Create order</p>
    </div>

    <div class="flex justify-between">
      <div class="flex overflow-auto">
        <app-btn-toggle [options]="routes" [selectedOption]="page"
          (changeEmitter)="changePage($event)"></app-btn-toggle>
      </div>

      <div class="flex gap-6">
        <secondary-btn id="drop-order" *ngIf="canDropOrder" (clickEmitter)="openDropOrderModal()">Drop order</secondary-btn>
        <app-secondary-button-filled id="continue-flow" *ngIf="page != 'review'"
          (clickEmitter)="continue($event)">Continue</app-secondary-button-filled>
        <primary-btn id="save-order" *ngIf="page != 'settings'" [blueBtn]="page == 'review'" (clickEmitter)="saveOrder($event)">Save
          order</primary-btn>
        <primary-btn id="send-order" *ngIf="page == 'review'" (clickEmitter)="sendOrder($event)">Send order</primary-btn>
      </div>
    </div>

    <div *ngIf="!(loading | async); else placeholder">
      <div [ngSwitch]="page">
        <app-order-settings *ngSwitchCase="'settings'"></app-order-settings>

        <app-order-select-cars *ngSwitchCase="'cars'"></app-order-select-cars>

        <app-order-equipments *ngSwitchCase="'equipment'"></app-order-equipments>

        <app-order-review *ngSwitchCase="'review'"></app-order-review>
      </div>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="twoCols">
    <app-main-content-placeholder></app-main-content-placeholder>
    <app-main-content-placeholder></app-main-content-placeholder>
  </div>
</ng-template>
