import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SellerOffer, SellerOfferStatus } from 'src/app/core/models/offer.model';
import { OffersService } from 'src/app/core/services/offers.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { SellerOfferCounterOfferModalComponent } from './seller-offer-counter-offer-modal/seller-offer-counter-offer-modal.component';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';

@Component({
  selector: 'app-seller-offer-details',
  templateUrl: './seller-offer-details.component.html',
  styleUrls: ['./seller-offer-details.component.scss']
})
export class SellerOfferDetailsComponent implements OnInit {
  @Input() offer: SellerOffer | undefined;

  offerStatus = SellerOfferStatus;

  @Output() offerEmitter: EventEmitter<SellerOffer> = new EventEmitter<SellerOffer>();

  constructor(private offerService: OffersService,
    private router: Router,
    private snackBar: SnackbarService,
    private dialog: MatDialog,
    private spinner: SpinnerHandlerService) { }

  ngOnInit(): void {
  }

  openCounterOfferModal() {
    if (this.spinner.showProgressBar.value) return;

    let dialogRef = this.dialog.open(
      SellerOfferCounterOfferModalComponent,
      {
        width: '600px',
        data: {
          offer: this.offer
        }
      }
    );

    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.changeOfferStatus(resp);
      }
    })
  }

  refuseOffer() {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    this.offerService.refuseSellerOffer(this.offer!.id).subscribe({
      next: resp => {
        this.spinner.showProgressBar.next(false);

        this.snackBar.positiveSentiment('Offer rejected');

        this.changeOfferStatus(resp);
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackBar.negativeSentiment(err.error);
      }
    });
  }

  acceptOffer() {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    this.offerService.acceptSellerOffer(this.offer!.id).subscribe({
      next: resp => {
        this.spinner.showProgressBar.next(false);

        this.snackBar.positiveSentiment('Offer accepted');

        this.changeOfferStatus(resp);
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackBar.negativeSentiment(err.error);
      }
    });
  }

  changeOfferStatus(offer: SellerOffer) {
    this.offer! = offer;

    this.offerEmitter.emit(offer);
  }
}
