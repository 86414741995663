import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderCar } from 'src/app/core/models/order.model';
import { CoreService } from 'src/app/core/services/core.service';

export interface OrderProcessSellerCarPickupDetailsModalData {
  car: OrderCar,
  finishedOrder: boolean
}

@Component({
  selector: 'app-order-process-seller-car-pickup-details-modal',
  templateUrl: './order-process-seller-car-pickup-details-modal.component.html',
  styleUrls: ['./order-process-seller-car-pickup-details-modal.component.scss']
})

export class OrderProcessSellerCarPickupDetailsModalComponent implements OnInit {
  car = this.data.car;
  finishedOrder = this.data.finishedOrder;

  countries = this.coreService.countriesDropdown;

  form = this.fb.group({
    city: [this.car.pickupDetails.city],
    address: [this.car.pickupDetails.address],
    zipcode: [this.car.pickupDetails.postalCode],
    firstName: [this.car.pickupDetails.contactDetails?.firstName],
    lastName: [this.car.pickupDetails.contactDetails?.lastName],
    email: [this.car.pickupDetails.contactDetails?.email],
    phone: [this.car.pickupDetails.contactDetails?.phone],
    carReadyAt: new FormControl({ value: this.car.carReadyAt, disabled: !this.car.carReady }),
    carReady: [this.car.carReady]
  })

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<OrderProcessSellerCarPickupDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderProcessSellerCarPickupDetailsModalData,
    private coreService: CoreService) { }

  ngOnInit(): void {
    if (this.finishedOrder) this.form.disable();
  }

  update() {
    let pickupDetails = {
      city: this.form.controls.city.value!,
      address: this.form.controls.address.value!,
      postalCode: this.form.controls.zipcode.value!,
      contactDetails: {
        firstName: this.form.controls.firstName.value!,
        lastName: this.form.controls.lastName.value!,
        email: this.form.controls.email.value!,
        phone: this.form.controls.phone.value!,
      },
      carReadyAt: this.form.controls.carReadyAt.value,
      carReady: this.form.controls.carReady.value
    }

    this.dialogRef.close(pickupDetails);
  }

  enableDatePicker(check: boolean) {
    check ? this.form.controls.carReadyAt.enable() : this.form.controls.carReadyAt.disable();
  }

  close() {
    this.dialogRef.close();
  }
}
