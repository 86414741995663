<div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
  <app-main-content-layout>
    <div class="flex justify-between">
      <div>
        <svg class="thirdTarget" width="78" height="38" viewBox="0 0 78 38" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M-0.000353897 37.275C-0.000354413 31.3574 1.5547 25.544 4.50862 20.4186C7.46255 15.2932 11.7114 11.0363 16.8283 8.07537L33.6815 37.275L-0.000353897 37.275Z"
            fill="#D4D4D4" />
          <path
            d="M78.0004 37.275C78.0004 31.3574 76.4453 25.544 73.4914 20.4186C70.5374 15.2932 66.2886 11.0363 61.1717 8.07537L44.3185 37.275L78.0004 37.275Z"
            fill="#D4D4D4" />
          <path
            d="M56.7267 4.51825C51.3392 1.55946 45.2279 0.00120146 39.0069 -4.26722e-05C32.7858 -0.00127918 26.674 1.55454 21.2851 4.51119L38.9995 33.725L56.7267 4.51825Z"
            fill="#D4D4D4" />
        </svg>
      </div>

      <!-- <secondary-btn id="update-prices" btnText="Update" (clickEmitter)="updatePrices()"></secondary-btn> -->
    </div>

    <div class="grid grid-cols-2 gap-12">
      <div class="col-span-1 flex flex-col gap-6">
        <app-main-content-row-layout>
          <p>Make</p>

          <app-read-only-input class="secondContent">{{car.make}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Model</p>

          <app-read-only-input class="secondContent">{{car.model}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Manufacture year</p>

          <app-read-only-input class="secondContent">{{car.manufactureYear}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Variant</p>

          <app-read-only-input class="secondContent">{{car.variant}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout *ngIf="car.trim">
          <p>Trim</p>

          <app-read-only-input class="secondContent">{{car.trim}}</app-read-only-input>
        </app-main-content-row-layout>
      </div>

      <div class="col-span-1 flex flex-col gap-6 pl-12 border-l border-white-500">
        <app-main-content-row-layout>
          <p>Damages</p>

          <app-read-only-input class="secondContent">{{car.damages ? car.damages : 0 | number}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Buying price</p>

          <app-read-only-input class="secondContent">{{car.buyingPrice | number}}€</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Min selling price</p>

          <app-read-only-input class="secondContent">{{car.minSellingPrice | number}}€</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Selling price</p>

          <app-read-only-input class="secondContent">{{car.sellingPrice | number}}€</app-read-only-input>
        </app-main-content-row-layout>
      </div>
    </div>
  </app-main-content-layout>

  <!-- <tertiary-btn id="export-table" class="flex w-max" btnText="Export table"></tertiary-btn> -->

  <div class="flex justify-end items-center gap-6" *ngIf="!bcError">
    <primary-btn *ngIf="toggleOverwriteControl.value" (clickEmitter)="saveTable()">Save table</primary-btn>

    <app-toggle (toggleEmitter)="overwriteBCToggle($event)" [toggleFormControl]="toggleOverwriteControl"
      [toggleDisabled]="disableOWToggle.value" toggleLabel="Manual overwrite"></app-toggle>
  </div>

  <div *ngIf="!toggleOverwriteControl.value; else overWriteTable">
    <app-table *ngIf="bc.length > 0 && !bcError;else noBc" [data]="bc" [displayedColumns]="displayedColumns"
      [headersMap]="headersMap"></app-table>
  </div>

  <ng-template #overWriteTable>
    <div class="flex flex-wrap gap-6 items-center mb-6">
      <app-input-number [control]="spotPrice" label="Spot price" placeholder="Insert value"></app-input-number>
      <app-input-number [control]="salesFactor" label="Sales factor 1-99" placeholder="Insert value"></app-input-number>
      <app-input-number [control]="carsSold30" label="Cars sold 30 days" placeholder="Insert value"></app-input-number>
      <app-input-number [control]="carsOnline" label="Cars online" placeholder="Insert value"></app-input-number>

      <app-white-btn (clickEmitter)="applyValues()" btnText="Apply"></app-white-btn>
    </div>

    <div class="overflow-x-auto">
      <table mat-table matSort [dataSource]="bcCopy"
        class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">

        <ng-container matColumnDef="spotCountry">
          <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            spot Country
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
            <p [ngClass]="{'font-bold text-red': element.error && element.error.length > 0}">{{element.spotCountry}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="spotPrice">
          <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            Spot price
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
            <input type="number" [id]="'spot-price-' + ( i + 1) + '-input'"
              [attr.data-cy]="'spot-price-' + ( i + 1) + '-input'" class="px-3 py-2 border border-white-500"
              [(ngModel)]="element.spotPrice">
          </td>
        </ng-container>

        <ng-container matColumnDef="salesFactor">
          <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            sales Factor
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
            <input type="number" [id]="'sales-factor-' + ( i + 1) + '-input'"
              [attr.data-cy]="'sales-factor-' + ( i + 1) + '-input'" class="px-3 py-2 border border-white-500"
              [(ngModel)]="element.salesFactor">
          </td>
        </ng-container>

        <ng-container matColumnDef="carsOnline">
          <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            cars Online
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
            <input type="number" [id]="'cars-online-' + ( i + 1) + '-input'"
              [attr.data-cy]="'cars-online-' + ( i + 1) + '-input'" class="px-3 py-2 border border-white-500"
              [(ngModel)]="element.carsOnline">
          </td>
        </ng-container>

        <ng-container matColumnDef="carsSold30Days">
          <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            cars Sold 30Days
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="!p-2">
            <input type="number" [id]="'cars-sold-' + ( i + 1) + '-input'"
              [attr.data-cy]="'cars-sold-' + ( i + 1) + '-input'" class="px-3 py-2 border border-white-500"
              [(ngModel)]="element.carsSold30Days">
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="overwriteDisplayedColumns" class="bg-white-100"></tr>
        <tr [ngClass]="{'isSelected' : row.isSelected}" class="hover:bg-white-500" mat-row
          *matRowDef="let row; columns: overwriteDisplayedColumns;"></tr>
      </table>

      <!-- <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons *ngIf="!hidePaginator">
    </mat-paginator> -->
    </div>
  </ng-template>


  <ng-template #noBc>
    <div class="font-medium text-xl flex flex-col gap-6">
      <div>
        <p>No business case</p>
      </div>

      <div *ngIf="bcError">
        <p>Error: {{bcError.error}} <span *ngIf="bcError.errorMsg.length > 0">- {{bcError.errorMsg}}</span></p>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #placeholder>
  <div class="flex flex-col gap-9">
    <app-main-content-placeholder></app-main-content-placeholder>

    <app-table-placeholder></app-table-placeholder>
  </div>
</ng-template>

<ng-template #overwriteModal>
  <div>
    <p class="font-bold">Update business case</p>

    <p class="font-bold pt-9 mt-3 border-t border-white-500 pb-7">Are you sure you want to update business case?</p>

    <p>
      Manual input will overwrite current Business Case settings and no data will be further updated for this car unless
      you manually turn back again the Automatic Business Case.
    </p>
    <p> Are you sure you want to continue?</p>

    <div class="flex justify-end gap-6 pt-6">
      <button [mat-dialog-close]="false">Cancel</button>

      <button class="bg-[#F2264B] text-white px-6 py-2 rounded" [mat-dialog-close]="true">Yes</button>
    </div>
  </div>
</ng-template>
