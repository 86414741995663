import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-view-kam-chat',
  templateUrl: './order-view-kam-chat.component.html',
  styleUrls: ['./order-view-kam-chat.component.scss']
})
export class OrderViewKamChatComponent implements OnInit {
  message = '';

  subjects = [
    {
      value: 'carSelection',
      viewValue: 'Car selection'
    },
    {
      value: 'financial',
      viewValue: 'Financial'
    },
    {
      value: 'transport',
      viewValue: 'Transport'
    },
    {
      value: 'docs',
      viewValue: 'DHL, COC, Documents'
    },
  ]

  subjectSelected = this.subjects[0].value;

  constructor() { }

  ngOnInit(): void {
  }

  sendMessage() {
    console.log(this.message);
  }

  changeSubject(subject: string) {
    this.subjectSelected = subject;
  }
}
