import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DisplayGrid, GridType, GridsterConfig, GridsterItem } from 'angular-gridster2';
import { CarDocumentUploadBody, CarPhoto, ReorderCarPhotos, UploadCarPhoto } from 'src/app/core/models/car.model';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-online-list-car-photos-docs-modal',
  templateUrl: './online-list-car-photos-docs-modal.component.html',
  styleUrls: ['./online-list-car-photos-docs-modal.component.scss']
})
export class OnlineListCarPhotosDocsModalComponent implements OnInit {
  car = this.data.car;

  docNameControl = new FormControl('', Validators.required)
  docVisibilityControl = new FormControl(true);

  docSelectedData: string | undefined;
  docSelectedName = "";

  options!: GridsterConfig;
  dashboard: GridsterItem[] = [];

  uploadControl = new FormControl();

  x = 0;
  y = 0;
  columns = 0;


  constructor(public dialogRef: MatDialogRef<OnlineListCarPhotosDocsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilsService: UtilsService,
    private snackbar: SnackbarService,
    private carService: CarService,
    private spinner: SpinnerHandlerService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.car.photos && this.car.photos.length > 0) this.loadPhotos();

    this.options = {
      gridType: GridType.Fixed,
      displayGrid: DisplayGrid.None,
      fixedColWidth: 112,
      fixedRowHeight: 112,
      useBodyForBreakpoint: true,
      setGridSize: true,
      swap: true,
      compactType: 'compactLeft',
      pushItems: false,
      draggable: {
        enabled: true
      },
      resizable: {
        enabled: false
      }
    };
  }

  ngAfterViewInit() {
    this.columns = Math.floor(parseFloat(window.getComputedStyle(document.getElementById('photoContainer')!, null).getPropertyValue('width').slice(0, -2)) / 112);
  }

  uploadFile(event: Event) {
    const target = event.target as HTMLInputElement;

    let file = target.files![0];

    this.utilsService.convertToBase64(file).then(fileBase64 => {
      this.docSelectedData = fileBase64;
      this.docSelectedName = file.name;
    });
  }

  saveDocument() {
    if (this.spinner.showProgressBar.value) return;

    if (this.docNameControl.invalid || !this.docSelectedData) {
      this.snackbar.negativeSentiment('No document name or no document uploaded');

      return;
    }
    let file = {
      name: this.docNameControl.value!,
      data: this.docSelectedData,
      visible: this.docVisibilityControl.value!
    };

    this.spinner.showProgressBar.next(true);

    this.carService.addCarDocument(new CarDocumentUploadBody(this.car.carMainInfoId, file)).subscribe({
      next: resp => {
        this.car.documents = resp;

        this.docNameControl.reset();
        this.docVisibilityControl.reset(false);

        this.docSelectedData = undefined;
        this.docSelectedName = '';

        this.snackbar.positiveSentiment('File uploaded');

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(JSON.parse(err.error));

        this.spinner.showProgressBar.next(false);
      }
    })
  }

  removeFile() {
    this.docSelectedData = '';
    this.docSelectedName = '';
  }

  async handleFileSelect(evt: Event) {
    let photos: any[] = [];

    const target = evt.target as HTMLInputElement;

    const files = target.files as FileList;

    //converts all uploaded images in base64
    for (let i = 0; i < files.length; i++) {
      photos.push({
        originalPhoto: await this.utilsService.convertToBase64(files[i]),
        photoName: files[i].name
      });
    }

    photos.forEach(p => {
      this.dashboard.push({ x: this.x, y: this.y, cols: 1, rows: 1, originalPhoto: p.originalPhoto, photoName: p.photoName });

      this.y = this.x === (this.columns - 1) ? this.y + 1 : this.y;
      this.x = this.x === (this.columns - 1) ? 0 : this.x + 1;
    })

    this.cd.markForCheck();
  }

  removePhotos() {
    if (this.spinner.showProgressBar.value) return;

    if (this.dashboard.filter(item => item['isChecked']).length === 0) {
      this.snackbar.negativeSentiment('No photos selected');

      return;
    }

    this.spinner.showProgressBar.next(true);

    this.dashboard = this.dashboard.filter(item => !item['isChecked']);

    let photos = this.car.photos.filter((p: any) => this.dashboard.find(item => item['_id'] === p._id));

    if (photos.length === this.car.photos.length) {
      this.spinner.showProgressBar.next(false);

      return;
    }

    let body = {
      carMainInfoId: this.car.carMainInfoId,
      photos: photos
    }

    this.carService.reorderCarPhotos(body).subscribe({
      next: resp => {
        this.car.photos = resp.photos;

        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Photos removed');

        this.loadPhotos();
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    });
  }

  closeModal() {
    this.dialogRef.close(this.car);
  }

  uploadPhoto() {
    if (this.spinner.showProgressBar.value) return;

    let photos = this.dashboard.filter(p => !p['_id']).map(p => ({ name: p['photoName']!, content: p['originalPhoto']! }));

    if (photos.length === 0) return;

    this.spinner.showProgressBar.next(true);

    let body: UploadCarPhoto = {
      carMainInfoId: this.car.carMainInfoId,
      uploadedImages: photos
    };

    this.carService.uploadCarPhoto(body).subscribe({
      next: resp => {
        this.car.photos = resp.photos;

        this.reorderPhotos('Photo uploaded', resp);
      },
      error: err => {
        this.spinner.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  reorderPhotos(message?: string, photoBody?: ReorderCarPhotos) {
    if (this.spinner.showProgressBar.value && !photoBody) return;

    this.spinner.showProgressBar.next(true);

    let body: any = undefined;

    if (photoBody) {
      body = photoBody;
    } else {
      this.dashboard.sort(function (a, b) {
        return a.y - b.y || a.x - b.x;
      });

      let photos: CarPhoto[] = [];

      this.dashboard.forEach(ph => {
        if (ph['_id']) {
          photos.push(this.car.photos.find((p: any) => p._id === ph['_id'])!);
        }
      })

      body = {
        carMainInfoId: this.car.carMainInfoId,
        photos: photos
      }
    }

    this.carService.reorderCarPhotos(body).subscribe({
      next: resp => {
        this.spinner.showProgressBar.next(false);

        this.car.photos = resp.photos;

        this.loadPhotos();
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  loadPhotos() {
    setTimeout(() => {
      let divWidth = parseFloat(window.getComputedStyle(document.getElementById('photoContainer')!, null).getPropertyValue('width').slice(0, -2));

      this.x = 0;
      this.y = 0;
      this.dashboard = [];

      this.columns = Math.floor(divWidth / 112);

      this.car.photos.forEach((p: any) => {

        this.dashboard.push({ x: this.x, y: this.y, cols: 1, rows: 1, originalPhoto: p.originalPhoto, _id: p._id, isChecked: false });

        this.y = this.x === (this.columns - 1) ? this.y + 1 : this.y;
        this.x = this.x === (this.columns - 1) ? 0 : this.x + 1;
      });

      this.cd.markForCheck();
    }, 0);
  }
}
