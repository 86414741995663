import { Injectable } from "@angular/core";
import { CounterOfferBody, Offer, OfferSettings, PublishSellerOfferCarBody, SearchOfferResp, SearchOffersBody, SellerOffer } from "../models/offer.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { OrderCar } from "../models/order.model";

@Injectable({
  providedIn: 'root'
})

export class OffersService {
  offerSettings: OfferSettings | undefined;

  constructor(private http: HttpClient) { }

  public createOffers(offers: OfferSettings): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}create-offer`, offers);
  }

  public getAcceptedOffers(clientId: string): Observable<OrderCar[]> {
    return this.http.get<OrderCar[]>(`${environment.backendApiUrl}get-accepted-offers/${clientId}`);
  }

  public getOffers(body: SearchOffersBody): Observable<SearchOfferResp> {
    return this.http.post<SearchOfferResp>(`${environment.backendApiUrl}filter-offers-staff`, body);
  }

  public cancelOffer(offerId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}delete-offer`, { offerId: offerId });
  }

  public declineOffer(offerId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}decline-offer`, { offerId: offerId });
  }

  public acceptOffer(offerId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}accept-offer`, { offerId: offerId });
  }

  public counterOffer(body: CounterOfferBody): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}counter-offer`, body);
  }

  public getSellerOffers(): Observable<SellerOffer[]> {
    return this.http.get<SellerOffer[]>(`${environment.backendApiUrl}seller-offers/`);
  }

  public getSellerOffer(offerId: string): Observable<SellerOffer> {
    return this.http.get<SellerOffer>(`${environment.backendApiUrl}seller-offers/${offerId}`);
  }

  public refuseSellerOffer(offerId: string): Observable<SellerOffer> {
    return this.http.patch<SellerOffer>(`${environment.backendApiUrl}seller-offers/${offerId}/reject/`, {});
  }

  public acceptSellerOffer(offerId: string): Observable<SellerOffer> {
    return this.http.patch<SellerOffer>(`${environment.backendApiUrl}seller-offers/${offerId}/accept/`, {});
  }

  public counterSellerOffer(offerId: string, price: number): Observable<SellerOffer> {
    return this.http.patch<SellerOffer>(`${environment.backendApiUrl}seller-offers/${offerId}/counter/`, { price: price });
  }

  public publishSellerOfferCar(offerId: string, body: PublishSellerOfferCarBody): Observable<SellerOffer> {
    return this.http.patch<SellerOffer>(`${environment.backendApiUrl}seller-offers/${offerId}/publish/`, body);
  }
}
