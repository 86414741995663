<app-main-content-layout class="mb-9">
  <div class="title">
    <p>Order documents</p>
  </div>

  <div class="flex flex-col gap-9">
    <app-blue-btn class="w-fit" (clickEmitter)="openAddDocumentModal()">Add order documents</app-blue-btn>

    <div class="flex flex-wrap gap-6">
      <div class="bg-white-400 py-3 px-9 flex flex-col justify-between gap-6 w-96" *ngFor="let doc of orderDocuments">
        <p>{{doc.name}}</p>

        <div class="flex justify-between">
          <mat-slide-toggle [disabled]="true" [checked]="doc.visible">
            Visible to buyers
          </mat-slide-toggle>

          <div class="flex gap-3 items-center">
            <fa-icon class="cursor-pointer" [icon]="['far','arrow-down-to-line']"
              (click)="downloadOrderDocument(doc.id)"></fa-icon>

            <fa-icon class="cursor-pointer" [icon]="['far','xmark']"
              (click)="removeOrderDocument(doc.id,'order')"></fa-icon>
          </div>
        </div>
      </div>

      <div class="bg-white-400 py-3 px-9 flex flex-col justify-between gap-6 w-96"
        *ngFor="let doc of transportDocuments">
        <p>{{doc.name}}</p>

        <div class="flex justify-between">
          <mat-slide-toggle [disabled]="true" [checked]="doc.visible">
            Visible to buyers
          </mat-slide-toggle>

          <div class="flex gap-3 items-center">
            <fa-icon class="cursor-pointer" [icon]="['far','arrow-down-to-line']"
              (click)="downloadTransportDocument(doc)"></fa-icon>

            <fa-icon class="cursor-pointer" [icon]="['far','xmark']"
              (click)="removeOrderDocument(doc.id,'transport')"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-main-content-layout>

<app-main-content-layout>
  <div class="title">
    <p>Car documents</p>
  </div>

  <div class="document-table overflow-x-auto">
    <table mat-table [dataSource]="cars" multiTemplateDataRows
      class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">
      <ng-container [matColumnDef]="column.value" *ngFor="let column of headersMap">
        <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
          {{column.tableView}}
        </th>

        <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
          {{getValue(element, column)}}
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="expand row"
            (click)="(expandedCar = expandedCar === element ? null : element); $event.stopPropagation()">
            <mat-icon *ngIf="expandedCar !== element">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedCar === element">keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsWithExpand.length">
          <div class="car-element-detail" [@detailExpand]="element == expandedCar ? 'expanded' : 'collapsed'">
            <div class="w-full pt-4 mt-2 border-t border-white-500 flex flex-wrap gap-6 items-center">
              <div class="flex flex-col max-w-[10rem]"
                *ngFor="let doc of element.documentsDetails.documents.invoicing.documents">
                <p class="text-white-800 text-sm">{{doc.name | ellipsis: 20}}</p>

                <app-white-btn
                  (clickEmitter)="downloadCarDocument(doc.id,'INV',element.carMainInfoId)">Download</app-white-btn>
              </div>

              <div class="flex flex-col max-w-[10rem]"
                *ngFor="let doc of element.documentsDetails.documents.coc.documents">
                <p class="text-white-800 text-sm">{{doc.name | ellipsis: 20}}</p>

                <app-white-btn
                  (clickEmitter)="downloadCarDocument(doc.id,'COC',element.carMainInfoId)">Download</app-white-btn>
              </div>

              <div class="flex flex-col max-w-[10rem]"
                *ngFor="let doc of element.documentsDetails.documents.registration.documents">
                <p class="text-white-800 text-sm">{{doc.name | ellipsis: 20}}</p>

                <app-white-btn
                  (clickEmitter)="downloadCarDocument(doc.id,'REG',element.carMainInfoId)">Download</app-white-btn>
              </div>

              <div class="flex flex-col max-w-[10rem]"
                *ngFor="let doc of element.documentsDetails.documents.cc.documents">
                <p class="text-white-800 text-sm">{{doc.name | ellipsis: 20}}</p>

                <app-white-btn
                  (clickEmitter)="downloadCarDocument(doc.id,'CC',element.carMainInfoId)">Download</app-white-btn>
              </div>

              <tertiary-btn (clickEmitter)="openCarDocumentDetails(element)">See details</tertiary-btn>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand" class="bg-white-100"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumnsWithExpand;" class="car-element-row"
        [class.car-expanded-row]="expandedCar === element"
        (click)="expandedCar = expandedCar === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="car-detail-row"></tr>
    </table>
  </div>
</app-main-content-layout>
