import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { CarC2VEquipments, CarMasterEquipments } from 'src/app/core/models/car.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';

export interface CarEquipment {
  value: string | number,
  viewName: string,
  name: string
}

@Component({
  selector: 'app-car-equipment',
  templateUrl: './car-equipment.component.html',
  styleUrls: ['./car-equipment.component.scss']
})

export class CarEquipmentComponent implements OnInit {
  loadingPage: BehaviorSubject<boolean> = new BehaviorSubject(true);
  routeSubscription: Subscription = new Subscription();

  packEquipment: Map<string, string> = new Map<string, string>();
  customEquipment: Map<string, string> = new Map<string, string>();

  standardEquipmentOptions: CarEquipment[] = [];

  optionalEquipmentOptions: CarEquipment[] = [];
  optionalEquipmentSelected: CarEquipment[] = [];
  optionalControl = new FormControl<string[]>([]);


  packEquipmentOptions: CarEquipment[] = [];
  packEquipmentSelected: CarEquipment[] = [];
  packControl = new FormControl<string[]>([]);

  customEquipmentOptions: CarEquipment[] = [];
  customEquipmentSelected: CarEquipment[] = [];
  customControl = new FormControl<string[]>([]);

  equipmentStandardNotSaved: boolean = false;

  constructor(private carSyncService: CarFlowSyncService,
    private carService: CarService,
    private snackbar: SnackbarService,
    private utilsService: UtilsService,
    private spinnerHandlerService: SpinnerHandlerService) {

  }

  async ngOnInit() {
    this.routeSubscription = this.carSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.carSyncService.setCurrentTab(value);
      }
    );

    await this.loadEquipment();
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  saveEquipments() {
    this.spinnerHandlerService.showProgressBar.next(true);

    let equipments: CarMasterEquipments = {
      standardEquipment: this.convertFromArrayToObjects(this.standardEquipmentOptions),
      optionalEquipment: this.convertFromArrayToObjects(this.optionalEquipmentSelected),
      packEquipment: this.convertFromArrayToObjects(this.packEquipmentSelected),
    };

    let customEq: CarC2VEquipments = {
      customEquipment: this.customEquipmentSelected.map(eq => eq.name)
    };

    forkJoin({
      editCarMasterEq: this.carService.editCarMasterEquipments(this.carSyncService.carDetails?.carMainInfoId!, equipments),
      editCarC2VEq: this.carService.editCarC2VEquipments(this.carSyncService.carDetails?.carMainInfoId!, customEq),
    }).subscribe(resp => {
      this.spinnerHandlerService.showProgressBar.next(false);
      this.snackbar.positiveSentiment('Equipment saved successfully!');

      this.equipmentStandardNotSaved = false;

      this.carSyncService.carMasterEq = resp.editCarMasterEq.carEquipment;
      this.carSyncService.carC2VEq! = customEq;
    });
  }

  async loadEquipment() {
    let equipments = await this.carSyncService.getCarEquipments();

    this.addEquipmentToArray(this.optionalEquipmentOptions, equipments.adacEq!.optionalEquipment);
    this.addEquipmentToArray(this.packEquipmentOptions, equipments.adacEq!.packEquipment);
    this.addEquipmentToArray(this.standardEquipmentOptions, equipments.adacEq!.standardEquipment);

    //add car custom eq to list
    this.customEquipmentSelected = equipments.carC2VEq!.customEquipment.map(c => ({ value: 1, viewName: this.utilsService.splitWordByUnderline(c), name: c }));

    if (equipments.carMasterEq!.optionalEquipment) {
      this.addEquipmentToArray(this.optionalEquipmentSelected, equipments.carMasterEq!.optionalEquipment);
      this.addEquipmentToArray(this.packEquipmentSelected, equipments.carMasterEq!.packEquipment);
    }

    this.customEquipmentOptions = equipments.c2vEq.map(c => ({ viewName: this.utilsService.splitWordByUnderline(c), value: 1, name: c }));

    if (!equipments.carMasterEq!.standardEquipment) this.equipmentStandardNotSaved = true;

    this.loadingPage.next(false);
  }

  convertFromArrayToObjects(arrayToTransform: CarEquipment[]) {
    return arrayToTransform.map(element => {
      let newObj = new Object;

      (newObj as any)[element.name] = element.value;

      return newObj;
    })
  }

  addEquipmentToArray(arrayToAddTo: CarEquipment[], arrayOfEquipments: any[]) {
    arrayOfEquipments.forEach(eq => {
      Object.getOwnPropertyNames(eq).forEach((val, idx, array) => {
        arrayToAddTo.push({ value: eq[val], viewName: this.splitWordByCamelcase(val), name: val });
      });
    });
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  splitWordByCamelcase(string: string): string {
    string = this.capitalizeFirstLetter(string);

    return string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  addOptionalEq() {
    if (this.optionalControl.value && this.optionalControl.value.length > 0) {
      this.optionalControl.value.forEach(eq => {
        if (!this.optionalEquipmentSelected.find(e => e.name === eq)) this.optionalEquipmentSelected.push(this.optionalEquipmentOptions.find(op => op.name == eq)!);
      })
    }

    this.optionalControl.reset();
  }

  addPackEq() {
    if (this.packControl.value && this.packControl.value.length > 0) {
      this.packControl.value.forEach(eq => {
        if (!this.packEquipmentSelected.find(e => e.name === eq)) this.packEquipmentSelected.push(this.packEquipmentOptions.find(op => op.name == eq)!);
      })
    }

    this.packControl.reset();
  }

  addCustomEq() {
    if (this.customControl.value && this.customControl.value.length > 0) {
      this.customControl.value.forEach(eq => {
        if (!this.customEquipmentSelected.find(e => e.name === eq)) this.customEquipmentSelected.push(this.customEquipmentOptions.find(op => op.name == eq)!);
      })
    }

    this.customControl.reset();
  }

  removeEquipment(group: number, eq: CarEquipment) {
    switch (group) {
      case 0:
        this.optionalEquipmentSelected.splice(this.optionalEquipmentSelected.indexOf(eq), 1);
        break;
      case 1:
        this.packEquipmentSelected.splice(this.packEquipmentSelected.indexOf(eq), 1);
        break;
      case 2:
        this.customEquipmentSelected.splice(this.customEquipmentSelected.indexOf(eq), 1);
        break;
      default:
        break;
    }
  }
}
