import { AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { Chart, registerables } from 'chart.js';


@Component({
  selector: 'app-horizontal-bar-chart',
  templateUrl: './app-horizontal-bar-chart.component.html',
  styleUrls: ['./app-horizontal-bar-chart.component.scss']
})
export class AppHorizontalBarChartComponent implements AfterViewInit {
  @Input() data: any;
  @Input() chartId: string = '';
  @Input() title: string = '';
  chart: Chart | undefined;

  constructor() { }

  ngAfterViewInit() {
    Chart.register(...registerables);

    const data = {
      labels: [
        'Red',
        'Blue',
        'Yellow',
        'green',
        'sda'
      ],
      datasets: [
        {
          label: '',
          data: [12],
          backgroundColor: '#FF9F43',
        },
        {
          label: '',
          data: [22],
          backgroundColor: '#FF6B6B',
        },
        {
          label: '',
          data: [44],
          backgroundColor: '#00537F',
        },
        {
          label: '',
          data: [232],
          backgroundColor: '#10AC84',
        },
        {
          label: '',
          data: [30],
          backgroundColor: '#FECA57',
        }
      ]
    };

    this.chart = new Chart(this.chartId, {
      type: 'bar',
      data: data,
      options: {
        indexAxis: 'y',
        plugins: {
          title: {
            text: this.title,
            align: 'start',
            display: true,
            font: {
              size: 14,
              weight: 'bold',
            },
            color: '#00304A',
            padding: {
              bottom: 50
            }
          },
          legend: {
            display: false
          }
        },
        skipNull: true,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false
            }
          },
          y: {
            stacked: true,
            grid: {
              offset: true,
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false
            }
          }
        },
      }
    });

  }

  @HostListener('window:resize', ['$event'])
  resizeChart() {
    this.chart?.resize();
  }
}
