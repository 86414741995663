import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { OrderCar, OrderTeammate, SaveProfitSplitBody } from 'src/app/core/models/order.model';
import { OrdersService } from 'src/app/core/services/orders.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderProcessProfitAddteammateModalComponent } from './order-process-profit-addteammate-modal/order-process-profit-addteammate-modal.component';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';

@Component({
  selector: 'app-order-process-profit',
  templateUrl: './order-process-profit.component.html',
  styleUrls: ['./order-process-profit.component.scss']
})

export class OrderProcessProfitComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  headers: TableHeaderMap[] = [
    {
      value: 'sellerName',
      tableView: 'seller'
    },
    {
      value: 'invoiceSellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'regNo',
      tableView: 'Registration number'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'firstReg',
      tableView: 'first registration'
    },
    {
      value: 'color',
      tableView: 'color'
    },
    {
      value: 'showroomReady',
      tableView: 'car availability'
    },
    {
      value: 'sellerStatus',
      tableView: 'sellerStatus'
    }
  ]

  totalProfit = this.orderFlowService.order?.totalActualProfit!;
  istProfit = this.totalProfit / 2;
  kamsProfit = this.totalProfit / 2;

  teammates: OrderTeammate[] = [];
  possibleTeammates: OrderTeammate[] = [];

  displayedColumns = this.headers.map(h => h.value);

  cars: OrderCar[] = this.orderFlowService.carList;

  constructor(private orderService: OrdersService,
    private orderFlowService: OrdersFlowSyncService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private spinnerService: SpinnerHandlerService) { }

  ngOnInit() {
    if (!this.orderFlowService.possibleTeammates) {
      this.orderService.getOrderPossibleTeammates().subscribe(resp => {
        this.orderFlowService.possibleTeammates = resp;
        this.possibleTeammates = resp;
        this.loading.next(false);
      });
    } else {
      this.possibleTeammates = this.orderFlowService.possibleTeammates;
      this.loading.next(false);
    }

    this.teammates = this.orderFlowService.order!.teammates!.map(t => ({
      ...t,
      profit: this.kamsProfit * t.credit / 100
    }));
  }

  changeProfit(profit: number, index: number, credit?: number) {
    for (let i = index + 1; i < this.teammates.length; i++) {
      this.teammates[i].profit = 0;
      this.teammates[i].credit = 0;
    }

    if (index > 0) {
      let tempProfit = this.teammates.slice(0, index).reduce((sum, kam) => { return sum + kam.profit! }, 0);

      if (index === this.teammates.length - 1 && profit > (this.kamsProfit - tempProfit)) {
        let remainingPerc = 100;

        for (let i = 0; i < this.teammates.length - 1; i++) {
          remainingPerc -= this.teammates[i].credit;
        }

        this.teammates[index].credit = credit ? credit : remainingPerc;
        this.teammates[index].profit = this.kamsProfit - tempProfit;
      } else if (profit > (this.kamsProfit - tempProfit)) {
        this.teammates[index].profit = this.kamsProfit - tempProfit;
        this.teammates[index].credit = credit ? credit : Math.round(this.teammates[index].profit! / this.kamsProfit * 100);
      } else {
        this.teammates[index].profit = profit;
        this.teammates[index].credit = credit ? credit : Math.round(this.teammates[index].profit! / this.kamsProfit * 100);
      }
    } else {
      this.teammates[index].profit = profit;
      this.teammates[index].credit = credit ? credit : Math.round(this.teammates[index].profit! / this.kamsProfit * 100);
    }
  }

  addTeammate() {
    const dialogRef = this.dialog.open(
      OrderProcessProfitAddteammateModalComponent, {
      width: '900px',
      autoFocus: false,
      data: {
        teammates: this.teammates,
        possibleTeammates: this.possibleTeammates
      },
    });


    dialogRef.afterClosed().subscribe((resp: OrderTeammate) => {
      if (resp) {
        this.spinnerService.showProgressBar.next(true);

        this.orderService.addTeammateToOrder(resp.userId, this.orderFlowService.order?.orderId!).subscribe(value => {
          console.log(value);
          this.spinnerService.showProgressBar.next(false);

          this.teammates.push({
            credit: 0,
            mainActor: false,
            mandatory: false,
            userId: resp.userId,
            username: resp.username,
            profit: 0,
          });

          this.snackbar.positiveSentiment('Teammate added successfully!');
        })
      }
    })
  }

  removeTeammate(userId: string) {
    this.spinnerService.showProgressBar.next(true);

    this.orderService.removeTeammateToOrder(userId, this.orderFlowService.order?.orderId!).subscribe(resp => {
      this.teammates.splice(this.teammates.findIndex(t => t.userId === userId), 1);

      this.snackbar.positiveSentiment("Teammate removed!");

      this.spinnerService.showProgressBar.next(false);
    })
  }

  changePercentage(mate: OrderTeammate, index: number) {
    mate.credit = Math.round(mate.credit);
    let remainingPerc = 100;

    if (index > 0) {
      for (let i = 0; i < index; i++) {
        remainingPerc -= this.teammates[i].credit;
      }
    }

    if (mate.credit > 100 && index === 0) {
      mate.credit = 100;
    } else if (mate.credit > remainingPerc) {
      mate.credit = remainingPerc;
    } else if (mate.credit < 0) {
      mate.credit = 0;
    }

    (document.getElementById(`profit-percentage-${index}-input`) as HTMLInputElement).value = mate.credit.toString();

    mate.profit = this.kamsProfit * mate.credit / 100;

    this.changeProfit(mate.profit, index, mate.credit);
  }

  saveProfits() {
    let body: SaveProfitSplitBody = {
      orderId: this.orderFlowService.order?.orderId!,
      users: this.teammates.map(t => ({ userId: t.userId, credit: t.credit }))
    };

    this.spinnerService.showProgressBar.next(true);

    this.orderService.saveProfitSplit(body).subscribe({
      next: resp => {
        this.orderFlowService.order!.teammates = this.orderFlowService.order!.teammates!.map(t => ({
          ...t,
          credit: this.teammates.find(tm => tm.userId === t.userId)!.credit
        }));

        this.spinnerService.showProgressBar.next(false);
        this.snackbar.positiveSentiment('Profit split saved successfully!');
      },
      error: err => {
        this.spinnerService.showProgressBar.next(false);
        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  changeMainActor(event: MatCheckboxChange, userId: string) {
    if (event.checked) {
      this.spinnerService.showProgressBar.next(true);
      this.teammates.forEach(t => t.mainActor = t.userId === userId);

      this.orderService.setMainActor(this.orderFlowService.order!.orderId!, userId).subscribe(resp => {
        this.orderFlowService.order?.teammates!.forEach(t => t.mainActor = t.userId === userId);

        this.spinnerService.showProgressBar.next(false);
        this.snackbar.positiveSentiment('Main actor saved!');
      });
    }
  }
}
