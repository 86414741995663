import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { OrderCar } from 'src/app/core/models/order.model';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OrderSelectCarsDocumentsDetailsModalComponent } from './order-select-cars-documents-details-modal/order-select-cars-documents-details-modal.component';
import { SellerFilterTableComponent } from '../../../order-shared-components/seller-filter-table/seller-filter-table.component';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';


@Component({
  selector: 'app-order-select-cars-documents',
  templateUrl: './order-select-cars-documents.component.html',
  styleUrls: ['./order-select-cars-documents.component.scss']
})
export class OrderSelectCarsDocumentsComponent implements OnInit {
  updateSubscription = new Subscription();

  headersMap: TableHeaderMap[] = [
    {
      "value": "make",
      "tableView": "make"
    }, {
      "value": "model",
      "tableView": "model"
    }, {
      "value": "documentsDetails.countryName",
      "tableView": "country"
    }, {
      "value": "documentsDetails.city",
      "tableView": "city"
    }, {
      "value": "documentsDetails.address",
      "tableView": "documents address"
    }, {
      "value": "documentsDetails.postalCode",
      "tableView": "zipcode"
    }, {
      "value": "documentsDetails.contactDetails.fullName",
      "tableView": "contact"
    }, {
      "value": "documentsDetails.contactDetails.email",
      "tableView": "email"
    }, {
      "value": "documentsDetails.contactDetails.phone",
      "tableView": "phone"
    }, {
      "value": "regNo",
      "tableView": "reg No"
    }, {
      "value": "vin",
      "tableView": "vin"
    },
  ];

  cars: OrderCar[] = this.orderService.carList;

  displayedColumns = this.headersMap.map(c => c.value).concat(['details']);

  @ViewChild('carsDeliveryTable') carsTable: SellerFilterTableComponent | undefined;

  constructor(private dialog: MatDialog,
    private orderService: OrdersFlowSyncService) { }

  ngOnInit(): void {
    this.updateSubscription = this.orderService.carsTableSubjectEvent$.subscribe(() => {
      this.carsTable?.updateTable(this.orderService.carList);
    });

    // this.cars.forEach(c => {
    //   if (this.orderService.order) {
    //     c.documentsDetails.country = this.orderService.order.buyer.store.country.id;
    //     c.documentsDetails.countryName = this.orderService.order.buyer.store.country.name;
    //   } else {
    //     c.documentsDetails.country = this.orderService.orderSettings?.buyerStore.countryDetails?.countryId;
    //     c.documentsDetails.countryName = this.orderService.orderSettings?.buyerStore.countryDetails?.country;
    //   }
    // });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  openOrderSelectCarsDocumentsDetailsModalComponent(car: OrderCar) {
    const dialogRef = this.dialog.open(
      OrderSelectCarsDocumentsDetailsModalComponent, {
      width: '900px',
      autoFocus: false,
      disableClose: true,
      data: {
        car: car,
      },
    });

    dialogRef.afterClosed().subscribe(() => {

    })
  }
}
