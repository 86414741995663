import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-content-layout',
  template: `
    <div class="w-full bg-white rounded p-9 h-min flex flex-col gap-6">
      <ng-content></ng-content>
    </div>
  `,
})

export class MainContentLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
