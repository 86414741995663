import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-process-car',
  templateUrl: './order-process-car.component.html',
  styleUrls: ['./order-process-car.component.scss']
})
export class OrderProcessCarComponent implements OnInit {
  routes = [
    {
      viewValue: "Confirmation",
      value: "confirmation",
    },
    {
      viewValue: "Price settings",
      value: "price",
    },
    {
      viewValue: "Documents delivery address",
      value: "documents",
    },
    {
      viewValue: "Car details",
      value: "details",
    },
  ];

  page = this.routes[0].value;

  constructor() { }

  ngOnInit(): void {
  }

  changePage(page: string) {
    this.page = page;
  }
}
