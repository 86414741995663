<div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
  <div class="flex justify-end gap-6">
    <secondary-btn id="remove-selected" btnText="Remove selected" (clickEmitter)="removeSelected()"></secondary-btn>
    <tertiary-btn id="create-group" btnText="Create car group" (clickEmitter)="openAddCarGroupModal()"></tertiary-btn>
  </div>

  <div>
    <app-table #groupsTable [data]="groups" [headersMap]="headers" [displayedColumns]="displayedColumns"></app-table>
  </div>
</div>

<ng-template #placeholder>
  <div class="w-full flex flex-col gap-6">
    <div class="w-full h-9 placeholder-animation"></div>

    <app-table-placeholder></app-table-placeholder>
  </div>
</ng-template>
