import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-white-btn',
  templateUrl: './white-btn.component.html',
  styleUrls: ['./white-btn.component.scss']
})
export class WhiteBtnComponent implements OnInit {
  @Input() btnText: string = '';
  @Input() bgColor: string = '#fff';
  @Input() textColor: string = '#00304A';
  @Input() id = '';
  @Input() disableBtn: boolean = false;

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  emitClick(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }
}
