import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { OnlineListUploadReviewComponent } from './online-list-upload-review/online-list-upload-review.component';
import { OnlineListUploadSettingsComponent } from './online-list-upload-settings/online-list-upload-settings.component';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { OnlineListUploadPricesComponent } from './online-list-upload-prices/online-list-upload-prices.component';

@Component({
  selector: 'app-online-list-upload',
  templateUrl: './online-list-upload.component.html',
  styleUrls: ['./online-list-upload.component.scss'],
  providers: [CarFlowSyncService]
})
export class OnlineListUploadComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);
  afterUpload = this.carSyncService.listAfterUpload;

  page = 'settings';

  public routes = [
    {
      viewValue: "Settings",
      value: "settings",
      disabled: new BehaviorSubject(false)
    },
    {
      viewValue: "Car review & validation",
      value: "review",
      disabled: new BehaviorSubject(true)
    },
    {
      viewValue: "Car prices",
      value: "prices",
      disabled: new BehaviorSubject(true)
    },
    {
      viewValue: "Car photos and documents",
      value: "photos",
      disabled: new BehaviorSubject(true)
    },
  ];

  @ViewChild('settings') settings: OnlineListUploadSettingsComponent | undefined;
  @ViewChild('review') review: OnlineListUploadReviewComponent | undefined;
  @ViewChild('prices') prices: OnlineListUploadPricesComponent | undefined;

  constructor(private carSyncService: CarFlowSyncService,
    private carService: CarService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit(): void {
    forkJoin({
      groups: this.carService.getCarGroups(),
      makes: this.carService.getCarNomenclatorData('makes'),
      drivingWheels: this.carService.getCarSpecificationData('drivingWheels'),
      colors: this.carService.getCarSpecificationData('colors')
    }).subscribe(resp => {
      this.carSyncService.carGroups = resp.groups.map(r => { return { viewValue: r.name, value: r.id } });
      this.carSyncService.carMakes = resp.makes.map(m => { return { viewValue: m, value: m } })
      this.carSyncService.carColors = resp.colors.map(r => ({ viewValue: r.name, value: r.name }));
      this.carSyncService.carDrivingWheels = resp.drivingWheels.map(b => ({ value: b.name, viewValue: b.name }));

      this.loading.next(false);
    });

    this.carSyncService.currentTab$.subscribe(
      (page) => {
        if (this.routes.find(r => r.value === page)) {
          this.page = page;
          this.routes[this.routes.findIndex(r => r.value === page)!].disabled!.next(false);
        }
      }
    )
  }

  ngOnDestroy() {
    this.carSyncService.carOnlineListUpload = undefined;
  }

  changePage(page: string) {
    if (this.spinner.showProgressBar.value) return;

    this.carSyncService.changeTab(page);
  }

  saveSettings() {
    switch (this.page) {
      case 'settings':
        this.settings!.identifyCars();
        break;
      case 'review':
        this.review!
        break;
      case 'prices':
        this.prices!.uploadCars();
        break;
      default:
        break;
    }
  }

  openModifyCarsModal() {
    if (this.spinner.showProgressBar.value) return;

    this.review?.editMultipleCars();
  }

  uploadCars() {
    if (this.spinner.showProgressBar.value) return;

    this.prices?.uploadCars();
  }
}
