<div class="grid grid-cols-2 gap-6">
  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Buyer Details</p>
    </div>

    <div class="grid grid-cols-4 gap-4 border-b border-white-500 pb-6 items-center">
      <app-dropdown id="country-code" class="col-span-1" [label]="'State code'" [control]="countryCodeControl"
        [options]="countryCodes" *ngIf="!validatedVAT" placeholder="Pick one">
      </app-dropdown>

      <app-input id="vat" [label]="'VAT*'" class="col-span-1" placeholder="Insert VAT"
        [control]="vatControl"></app-input>

      <primary-btn id="validate-vat" (clickEmitter)="validateVAT()" *ngIf="!validatedVAT"
        [disableBtn]="!vatControl.value || vatControl.value.length <= 0 || !countryCodeControl.value || countryCodeControl.value.length <= 0"
        class="col-span-1 col-start-1" [btnText]="'Validate VAT'"></primary-btn>

      <secondary-btn id="reset-vat" class="col-span-1" [btnText]="'Reset VAT'" (clickEmitter)="resetVat()"
        [disableBtn]="!vatControl.value || vatControl.value.length <= 0 ">
      </secondary-btn>

      <secondary-btn id="vat-rules" class="col-span-1 flex items-center text-blue-100" [btnText]="'VAT rules'"
        [disableBtn]="true" *ngIf="!validatedVAT">
      </secondary-btn>
    </div>

    <div *ngIf="vatControl.hasError('invalidVAT')" class="mt-2 text-red">
      Invalid VAT, check VAT number and country code
    </div>

    <app-main-content-row-dropdown title="KAM" componentId="kam" placeholder="Select KAM"
      [control]="companyForm.controls.kamId" [options]="kams"></app-main-content-row-dropdown>

    <app-main-content-row-input title="Company name" [componentId]="'company-name'" placeholder="Insert company name"
      [control]="companyForm.controls.name"></app-main-content-row-input>

    <app-main-content-row-layout>
      <p
        [ngClass]="{'text-red font-bold' : (companyForm.controls.country.invalid && companyForm.controls.country.touched) || ((companyForm.controls.city.invalid && companyForm.controls.city.touched) )}">
        Region*</p>

      <div class="twoCols secondContent">
        <app-dropdown id="country" class="col-span-1" [control]="companyForm.controls.country" [options]="countries"
          placeholder="Select country">
        </app-dropdown>
        <app-input id="city" class="col-span-1" [control]="companyForm.controls.city" placeholder="Insert city">
        </app-input>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-input title="ZIP/Postal code" componentId="postalcode" placeholder="Insert postal code"
      [control]="companyForm.controls.postalCode"></app-main-content-row-input>

    <app-main-content-row-input title="Address" componentId="address" placeholder="Insert address"
      [control]="companyForm.controls.address"></app-main-content-row-input>

    <app-main-content-row-dropdown title="Dealer group makes" componentId="group-makes"
      placeholder="Select dealer group makes" [control]="companyForm.controls.make" [options]="makes"
      [multipleSelect]="true" [selectAll]="true"></app-main-content-row-dropdown>

    <app-main-content-row-layout>
      <p>Cars2view dealer ID</p>
      <app-input id="c2v-dealer-id" class="secondContent" [control]="companyForm.controls.cars2viewDealer"
        placeholder="Insert cars2view dealer id"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-input title="Company website" componentId="website" placeholder="Insert company website"
      [control]="companyForm.controls.website"></app-main-content-row-input>

    <app-main-content-row-layout>
      <p>Phone</p>
      <app-input id="phone" class="secondContent" [control]="companyForm.controls.phone" placeholder="Insert phone">
      </app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <div class="flex items-center">
        <p>Email</p>

        <p class="relative -left-[2.9375rem] top-6 text-sm text-red opacity-0 whitespace-nowrap"
          [ngClass]="{'opacity-100':companyForm.controls.email.hasError('email')}">Insert valid email</p>
      </div>

      <app-input id="email" class="secondContent" [control]="companyForm.controls.email" placeholder="Insert email">
      </app-input>
    </app-main-content-row-layout>

    <app-main-content-row-dropdown title="Priority" componentId="priority" placeholder="Select priority"
      [control]="companyForm.controls.priority" [options]="priorities"></app-main-content-row-dropdown>
  </app-main-content-layout>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Buyer info</p>
    </div>

    <app-main-content-row-layout>
      <p>Status</p>

      <div class="secondContent text-end">
        <mat-slide-toggle [disabled]="true" [checked]="true" labelPosition="before">
          Active
        </mat-slide-toggle>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>VAT Status</p>
      <div class="secondContent text-end">
        <app-toggle id="vies-status" [toggleLabel]="vatStatusControl.value ? 'Active' : 'Inactive'"
          [toggleFormControl]="vatStatusControl"></app-toggle>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Date created</p>

      <app-read-only-input class="secondContent" *ngIf="dateCreated">
        {{dateCreated | date:'dd/MM/yyyy'}}
      </app-read-only-input>
    </app-main-content-row-layout>
  </app-main-content-layout>
</div>
