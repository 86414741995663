import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CarService } from 'src/app/core/services/car.service';

@Component({
  selector: 'app-search-car-by-id-modal',
  templateUrl: './search-car-by-id-modal.component.html',
  styleUrls: ['./search-car-by-id-modal.component.scss']
})
export class SearchCarByIdModalComponent implements OnInit {
  searchControl = new FormControl('', Validators.required);
  error = '';

  constructor(private carService: CarService,
    private router: Router,
    private dialogRef: DialogRef) { }

  ngOnInit(): void {
  }

  searchCar() {
    if (this.searchControl.invalid) {
      this.error = 'Please insert ID!';
      return;
    }

    this.error = '';

    this.carService.getCarDetails(this.searchControl.value!).subscribe({
      next: (resp) => {
        this.dialogRef.close();
        this.router.navigate([`car/car-details/${resp.carMainInfoId}`]);
      },
      error: (err) => this.error = 'Car not found!'
    })
  }
}
