<div class="flex flex-col gap-9 max-h-[80vh] p-6 overflow-y-scroll">
  <div class="flex justify-between items-center">
    <p class="text-2xl">Edit photos and documents</p>

    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" [icon]="['far','xmark']" class="cursor-pointer"
      (click)="closeModal()"></fa-icon>
  </div>

  <div>
    <p class="mb-11 font-bold pb-3 border-b border-white-500">Documents</p>

    <div class="flex gap-6 items-center">
      <app-input label="Document name" placeholder="Insert document name" [control]="docNameControl"></app-input>

      <app-toggle [labelPosition]="'after'" [toggleLabel]="'Visible to buyers'"
        [toggleFormControl]="docVisibilityControl"></app-toggle>

      <div class="bg-white-500 rounded px-9 py-3 flex gap-6" *ngIf="docSelectedName.length > 0">
        <p class="whitespace-nowrap overflow-hidden text-ellipsis max-w-sm">{{docSelectedName}}</p>
        <fa-icon id="remove-file-btn" data-cy="remove-file-btn" class="cursor-pointer" [icon]="['far','xmark']"
          (click)="removeFile()"></fa-icon>
      </div>
    </div>

    <div class="flex gap-6 pb-3 border-b border-white-500 mb-11 mt-9">
      <secondary-btn (clickEmitter)="selectFile.click()">Upload document</secondary-btn>

      <primary-btn (clickEmitter)="saveDocument()">Save document</primary-btn>
    </div>

    <p>Document list</p>

    <div class="flex flex-wrap gap-6 mt-6">
      <div class="bg-white-700 rounded px-9 py-3 flex gap-4 w-fit items-center" *ngFor="let d of car.documents">
        <p class="whitespace-nowrap overflow-hidden text-ellipsis max-w-sm">{{d.name}}</p>
      </div>
    </div>
  </div>

  <div>
    <p class="mb-11 font-bold pb-3 border-b border-white-500">Photos</p>
    <div class="flex justify-between items-center">
      <div id="add-box"
        class="w-28 h-28 border-[3px] border-blue-200 rounded text-4xl text-blue-200 border-dashed flex justify-center items-center cursor-pointer hover:shadow-lg active:scale-95"
        (click)="selectImage.click()">
        <fa-icon class="w-10 h-10 bg-white-400 text-center" [icon]="['fal','plus']"></fa-icon>
      </div>

      <div class="flex flex-wrap gap-6">
        <secondary-btn id="reorder-photos" (clickEmitter)="reorderPhotos()">Save photos order</secondary-btn>

        <primary-btn id="upload-photos" (clickEmitter)="uploadPhoto()">Upload photos</primary-btn>

        <tertiary-btn id="remove-photos" (clickEmitter)="removePhotos()">Remove photos</tertiary-btn>

      </div>
    </div>

    <div id="photoContainer">
      <gridster [options]="options" class="h-96 !p-0 bg-white my-9">
        <gridster-item [item]="item" *ngFor="let item of dashboard; let i = index" class="!w-28 !h-28 rounded">
          <div class="car-photos relative w-28 h-28 rounded flex">
            <mat-checkbox [id]="'checkbox-'+i" class="!absolute right-2 top-2" [(ngModel)]="item['isChecked']">
            </mat-checkbox>

            <img [src]="item['originalPhoto']" class="rounded object-cover">

            <div class="top-0 left-0 absolute text-[0.625rem] py-1 px-[0.625rem] text-red bg-red-100 rounded"
              *ngIf="!item['_id']">
              <p>UNSAVED</p>
            </div>
          </div>
        </gridster-item>
      </gridster>
    </div>
  </div>
</div>

<input id="uploadInput" class="hidden" #selectFile type="file" accept=".pdf" (change)="uploadFile($event)">

<input class="hidden" [multiple]="true" #selectImage type="file" [formControl]="uploadControl"
  (change)="handleFileSelect($event)">
