import { Injectable } from "@angular/core";
import { firstValueFrom, forkJoin } from "rxjs";
import { DropdownOption } from "src/app/shared/app-dropdown/app-dropdown.component";
import { Country } from "../models/country.model";
import { UserInfo } from "../models/user.model";
import { InfoService } from "./infos.service";
import { UserService } from "./user.service";
import { UnsavedChangesModalComponent } from "src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})

export class CoreService {
  private jwtHelperService = new JwtHelperService();

  public user: UserInfo | undefined;
  public isAdmin = false;

  countriesDropdown: DropdownOption[] = [];
  countries: Country[] = [];

  constructor(private infoService: InfoService,
    private authService: AuthService,
    private userService: UserService,
    private dialog: MatDialog) { }

  public async init(): Promise<any> {
    if (this.canActivateCoreService()) {
      await this.loadInfo();
    }

    return Promise.resolve();
  }

  async loadInfo() {
    if (!this.canActivateCoreService()) return;

    let resp = await firstValueFrom(forkJoin({
      countries: this.infoService.getCountries(),
      user: this.userService.getUserInfo(),
    }));

    resp.countries.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });

    this.countries = resp.countries;
    this.countriesDropdown = resp.countries.map(c => ({ viewValue: c.name, value: c.id }));

    this.user = resp.user;
    this.isAdmin = resp.user.role === 'ADMIN';

    return Promise.resolve(this.user);
  }

  public partialToFull<T>(x: Partial<T>): T { return x as T; }

  async openUnsavedChangesModal(modalText?: string): Promise<boolean> {
    const dialogRef = this.dialog.open(
      UnsavedChangesModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
        modalText: modalText
      }
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }

  private canActivateCoreService(): string | undefined {
    let token = localStorage.getItem('msalToken');

    if (token && this.jwtHelperService.isTokenExpired(token)) {
      return undefined;
    } else if (token) {
      this.authService.token = token;
      return token;
    }
    else {
      return undefined;
    }
  }
}
