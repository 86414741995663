import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-bc-large-list-alert-modal',
  templateUrl: './bc-large-list-alert-modal.component.html',
  styleUrls: ['./bc-large-list-alert-modal.component.scss']
})
export class BcLargeListAlertModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BcLargeListAlertModalComponent>) { }

  ngOnInit() {
  }

  close(event: number) {
    this.dialogRef.close(event);
  }
}
