<div class="twoCols" *ngIf="!(loading | async); else placeholder">
  <app-main-content-layout>
    <div class="title">
      <p *ngIf="orderId;else newOrder">Order {{orderId}}</p>

      <ng-template #newOrder>
        <p>New order</p>
      </ng-template>
    </div>

    <app-main-content-row-layout>
      <p>Order date*</p>

      <app-datepicker id="order-date" [control]="orderDateControl" placeholder="dd/mm/yyyy" class="secondContent">
      </app-datepicker>
    </app-main-content-row-layout>

    <app-main-content-row-layout *ngIf="isAdmin">
      <p>KAM*</p>

      <app-dropdown id="kam" class="secondContent" [options]="kamDropdown" placeholder="Select KAM" [control]="kamControl"
        (emitOnChangeSelect)="selectKAM($event)"></app-dropdown>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Buyer*</p>

      <app-dropdown id="buyer" class="secondContent" [disabled]="kamControl.invalid && isAdmin" [options]="buyersDropdown"
        placeholder="Select buyer" [control]="buyerControls.controls.buyer"
        (emitOnChangeSelect)="selectBuyer($event)"></app-dropdown>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>User buyer*</p>

      <app-dropdown id="user-buyer" class="secondContent" [options]="usersDropdown" [control]="buyerControls.controls.user"
        placeholder="Select user buyer" [disabled]="buyerControls.controls.buyer.invalid"
        (emitOnChangeSelect)="selectUser($event)"></app-dropdown>
    </app-main-content-row-layout>
    <app-main-content-row-layout>
      <p>Store*</p>

      <app-dropdown id="stores" class="secondContent" [control]="buyerControls.controls.store" placeholder="Select store"
        [disabled]="buyerControls.controls.user.invalid" (emitOnChangeSelect)="selectStore($event)"
        [options]="stores"></app-dropdown>
    </app-main-content-row-layout>
    <app-main-content-row-layout>
      <p>Contact*</p>

      <app-dropdown id="contacts" class="secondContent" [options]="contactsDropdown" (emitOnChangeSelect)="selectContact($event)"
        [control]="buyerControls.controls.contact" placeholder="Select contact"
        [disabled]="buyerControls.controls.store.invalid"></app-dropdown>
    </app-main-content-row-layout>
  </app-main-content-layout>

  <app-main-content-layout>
    <app-expansion-panel title="Order info" [expandedPanel]="true" panelId="orderPanel">
      <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
        <div class="flex justify-between">
          <p>KAM buyer</p>

          <p>{{selectedKAM?.firstName}} {{selectedKAM?.lastName}}</p>
        </div>

        <div class="flex justify-between">
          <p>Creation date</p>

          <p>24/12/2024</p>
        </div>

        <div class="flex justify-between">
          <p>Date modified</p>

          <p>24/12/2024</p>
        </div>
      </div>
    </app-expansion-panel>

    <app-expansion-panel title="Selected buyer company" [expandedPanel]="true" panelId="buyerCompanyPanel">
      <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
        <div class="flex justify-between gap-4">
          <p>Main company name</p>

          <p [title]="selectedBuyer?.name">{{selectedBuyer?.name | ellipsis:20}}</p>
        </div>

        <div class="flex justify-between">
          <p>VAT number</p>

          <p>{{selectedBuyer?.vatDetails?.vat}}</p>
        </div>

        <div class="flex justify-between">
          <p>Address</p>

          <div *ngIf="selectedBuyer">
            <p *ngIf="selectedBuyer.address!.length > 0;else fullAddress">
              {{selectedBuyer!.address | slice:0:20}}...
            </p>

            <ng-template #fullAddress>
              <p>{{selectedBuyer!.address}}</p>
            </ng-template>
          </div>
        </div>

        <div class="flex justify-between">
          <p>City</p>

          <p>{{selectedBuyer?.city}}</p>
        </div>

        <div class="flex justify-between">
          <p>Postal code</p>

          <p>{{selectedBuyer?.postalCode}}</p>
        </div>

        <div class="flex justify-between">
          <p>Country</p>

          <p>{{selectedBuyer?.countryDetails?.country}}</p>
        </div>

        <div class="flex justify-between">
          <p>User name</p>

          <p>{{selectedUser?.firstName}} {{selectedUser?.lastName}}</p>
        </div>


        <div class="flex justify-between">
          <p>User email</p>

          <p>{{selectedUser?.email | ellipsis:20}}</p>
        </div>


        <div class="flex justify-between">
          <p>User phone</p>

          <p>{{selectedUser?.phoneNumber}}</p>
        </div>
      </div>
    </app-expansion-panel>

    <app-expansion-panel title="Delivery store" [expandedPanel]="true" panelId="storePanel">
      <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
        <div class="flex justify-between">
          <p>Store name</p>

          <p>{{selectedStore?.name | ellipsis:20}}</p>
        </div>

        <div class="flex justify-between">
          <p>Address</p>

          <div *ngIf="selectedStore">
            <p>{{selectedStore!.address | ellipsis:20}}</p>
          </div>
        </div>

        <div class="flex justify-between">
          <p>City</p>

          <p>{{selectedStore?.city}}</p>
        </div>

        <div class="flex justify-between">
          <p>Postal code</p>

          <p>{{selectedStore?.postalCode}}</p>
        </div>

        <div class="flex justify-between">
          <p>Country</p>

          <p>{{selectedStore?.countryDetails?.country}}</p>
        </div>

        <div class="flex justify-between">
          <p>Contact name</p>

          <p>{{selectedContact?.firstName}} {{selectedContact?.lastName}}</p>
        </div>


        <div class="flex justify-between">
          <p>Email</p>

          <p>{{selectedContact?.email | ellipsis:20}}</p>
        </div>


        <div class="flex justify-between">
          <p>Phone</p>

          <p>{{selectedContact?.phone}}</p>
        </div>
      </div>
    </app-expansion-panel>
  </app-main-content-layout>
</div>

<ng-template #placeholder>
  <div class="twoCols">
    <app-main-content-placeholder></app-main-content-placeholder>
    <app-main-content-placeholder></app-main-content-placeholder>
  </div>
</ng-template>
