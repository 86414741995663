import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { OrderCar } from 'src/app/core/models/order.model';
import { SellerStatus } from 'src/app/core/models/seller-models/seller-helpers.models';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-order-select-cars-list',
  templateUrl: './order-select-cars-list.component.html',
  styleUrls: ['./order-select-cars-list.component.scss']
})
export class OrderSelectCarsListComponent implements OnInit {
  updateSubscription = new Subscription();

  headers: TableHeaderMap[] = [
    {
      value: 'sellerName',
      tableView: 'seller'
    },
    {
      value: 'sellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'regNo',
      tableView: 'Registration number'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'firstReg',
      tableView: 'First registration'
    },
    {
      value: 'color',
      tableView: 'color'
    },
    {
      value: 'offeredPrice',
      tableView: 'offered price'
    }
  ]

  displayedColumns = ['selectAll'].concat(this.headers.map(h => h.value), ['sellerValidationType']);

  cars = this.orderService.carList;

  sellerStatus = SellerStatus;

  sellerFilterControl = new FormControl();
  sellerFilters: DropdownOption[] = [];

  @ViewChild('carsSelectList') carsTable: AppTableComponent | undefined;

  dataSource = new MatTableDataSource<OrderCar>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private orderService: OrdersFlowSyncService) { }

  ngOnInit(): void {
    this.updateSubscription = this.orderService.carsTableSubjectEvent$.subscribe(resp => {
      this.updateTable();
    })

    this.dataSource.data = this.cars.map((d: OrderCar) => ({
      ...d,
      isSelected: false
    }));

    this.dataSource.filterPredicate = (data: OrderCar, filter: string) => !filter || data.carConfig.sellerName == filter;

    this.sellerFilters = (this.cars.map(c => c.carConfig.sellerName!).filter((v, i, a) => a.indexOf(v) === i)).map(s => ({ value: s, viewValue: s }));
    this.sellerFilters.unshift({ value: '', viewValue: 'All' });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  isNumber(val: any): boolean {
    return typeof val === 'number';
  }

  filterCars(seller: string) {
    this.dataSource.filter = seller;
  }

  selectAll(event: MatCheckboxChange) {
    this.dataSource.data = this.dataSource.data.map(d => ({ ...d, isSelected: event.checked }));
  }

  removeCars() {
    let cars = this.dataSource.data.filter(c => c.carConfig.isSelected);

    cars.forEach(c => {
      this.orderService.carList.splice(this.orderService.carList.findIndex(car => car.carMainInfoId == c.carMainInfoId), 1);
    });

    this.updateTable();
  }

  updateTable() {
    this.dataSource.data = this.orderService.carList;

    this.sellerFilters = (this.orderService.carList.map(c => c.carConfig.sellerName!).filter((v, i, a) => a.indexOf(v) === i)).map(s => ({ value: s, viewValue: s }));
    this.sellerFilters.unshift({ value: '', viewValue: 'All' });
  }
}
