import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  transform(value: string | undefined, length: number): string {
    if (value && value.length > length) {
      return value.substring(0, length).concat('...');
    }else if(value) {
      return value;
    }else{
      return "";
    }
  }
}
