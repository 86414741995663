import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { CarBusinessCase } from 'src/app/core/models/car.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-car-businesscase',
  templateUrl: './car-businesscase.component.html',
  styleUrls: ['./car-businesscase.component.scss']
})

export class CarBusinesscaseComponent implements OnInit {
  loading = new BehaviorSubject(true);
  disableOWToggle = new BehaviorSubject<boolean>(false);

  public routeSubscription: Subscription = new Subscription();

  headersMap: TableHeaderMap[] = [
    {
      value: 'spotCountry',
      tableView: 'Country'
    },
    {
      value: 'spotPrice',
      tableView: 'market value'
    },
    {
      value: 'spotLevel',
      tableView: 'spot level'
    },
    {
      value: 'salesFactor',
      tableView: 'sales Factor'
    },
    {
      value: 'carsSold30Days',
      tableView: 'cars Sold 30 Days'
    },
    {
      value: 'carsOnline',
      tableView: 'cars Online'
    },
    {
      value: 'errorDetails',
      tableView: 'error details'
    }
  ];

  displayedColumns = this.headersMap.map(h => h.value);

  overwriteDisplayedColumns = ['spotCountry', 'spotPrice', 'salesFactor', 'carsSold30Days', 'carsOnline'];

  car = this.carSyncService.carDetails!;

  bc: CarBusinessCase[] = this.car.businessCase;
  bcCopy: CarBusinessCase[] = JSON.parse(JSON.stringify(this.car.businessCase));

  bcError: { error: string, errorMsg: string } | undefined;

  toggleOverwriteControl = new FormControl();

  spotPrice = new FormControl(null, Validators.min(0));
  salesFactor = new FormControl(null, Validators.min(0));
  carsSold30 = new FormControl(null, [Validators.min(0), Validators.max(99)]);
  carsOnline = new FormControl(null, Validators.min(0));

  @ViewChild('overwriteModal') overwriteModalTemplate: TemplateRef<any> | undefined;

  constructor(private carSyncService: CarFlowSyncService,
    private carService: CarService,
    private coreService: CoreService,
    private snackbar: SnackbarService,
    private utilsService: UtilsService,
    private spinner: SpinnerHandlerService,
    private dialog: MatDialog) { }

  async ngOnInit() {
    this.routeSubscription = this.carSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.carSyncService.setCurrentTab(value);
      }
    );

    let bc = this.car.businessCase;

    this.checkBCResponse(bc);

    this.toggleOverwriteControl.setValue(!this.car.businessCaseFlag);

    this.loading.next(false);
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  //Triggers endpoints depending on toggle value
  async overwriteBCToggle(event: boolean) {
    this.disableOWToggle.next(true);

    if (event) {
      let dialogRef = this.dialog.open(this.overwriteModalTemplate!, {
        width: '800px',
        maxWidth: '90vw',
        id: 'ow-bc',
      });

      let resp = await firstValueFrom(dialogRef.afterClosed())

      if (!resp) {
        this.toggleOverwriteControl.setValue(false, { emitEvent: false });
        this.disableOWToggle.next(false);
        return;
      }
    }

    if (!event) {
      this.carService.updateCarBCFlag(this.car.carMainInfoId, true).subscribe({
        next: async resp => {
          this.car.businessCaseFlag = true;

          this.disableOWToggle.next(false);

          this.spinner.showProgressBar.next(false);
        },
        error: err => {
          this.snackbar.negativeSentiment(err.error);

          this.spinner.showProgressBar.next(false);
        }
      })
    }
  }

  //Save table event, check if flag is the same as toggle
  saveTable() {
    let bcToUpdate = this.bcCopy.filter(b => this.car.businessCase.find(bb => bb.spotCountry === b.spotCountry && (bb.salesFactor != b.salesFactor || bb.spotPrice != b.spotPrice || bb.carsSold30Days != b.carsSold30Days || bb.carsOnline != b.carsOnline)));

    bcToUpdate.forEach(b => {
      if (b.spotPrice < 0 || isNaN(b.salesFactor) || b.salesFactor < 1 || b.salesFactor > 99 || b.carsOnline < 0 || b.carsSold30Days < 0) {
        b.error = 'Error';
      } else {
        b.error = '';
      }
    });

    if (bcToUpdate.some(b => b.error && b.error.length > 0)) {
      this.snackbar.negativeSentiment('One or more countries have errors, insert positive values and values between 1-99 for sales factor');

      return;
    } else if (bcToUpdate.length === 0) {
      this.snackbar.negativeSentiment('No rows updated');

      return;
    }

    if (this.spinner.showProgressBar.value) {
      return
    } else if (this.toggleOverwriteControl.value && !this.toggleOverwriteControl.value != this.car.businessCaseFlag) {
      this.spinner.showProgressBar.next(true);

      this.carService.updateCarBCFlag(this.car.carMainInfoId, false).subscribe({
        next: resp => {
          this.car.businessCaseFlag = false;

          this.saveBC(bcToUpdate);
        },
        error: err => {
          this.snackbar.negativeSentiment(err.error);

          this.spinner.showProgressBar.next(false);
        }
      });
    } else {
      this.saveBC(bcToUpdate);
    }
  }

  //Save new value from table overwrite
  saveBC(bcToUpdate: CarBusinessCase[]) {

    this.spinner.showProgressBar.next(true);

    let body = {
      carMainInfoId: this.car.carMainInfoId,
      businessCase: bcToUpdate.map(b => ({
        spotPrice: b.spotPrice,
        carsOnline: b.carsOnline,
        carsSold30Days: b.carsSold30Days,
        salesFactor: b.salesFactor,
        spotCountry: b.spotCountry
      }))
    };

    this.carService.updateCarBCValues(body).subscribe({
      next: resp => {
        this.car.businessCase = JSON.parse(JSON.stringify(this.bcCopy));

        this.snackbar.positiveSentiment(resp);

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    })
  }

  //Applies values from inputs to entire table
  applyValues() {
    if (this.spotPrice.invalid || this.salesFactor.invalid || this.carsOnline.invalid || this.carsSold30.invalid) {
      this.snackbar.negativeSentiment('Insert positive values');
      return;
    }

    this.bcCopy.forEach(b => {
      b.spotPrice = this.spotPrice.value ? this.spotPrice.value : b.spotPrice;
      b.salesFactor = this.salesFactor.value ? this.salesFactor.value : b.salesFactor;
      b.carsOnline = this.carsOnline.value ? this.carsOnline.value : b.carsOnline;
      b.carsSold30Days = this.carsSold30.value ? this.carsSold30.value : b.carsSold30Days;
    });
  }

  //Parses bc response to check if there are errors
  checkBCResponse(bc: any) {
    if (bc[0].code) {
      this.bcError = {
        error: this.utilsService.splitWordByCamelcase(Object.keys(bc[0].details)[0]),
        errorMsg: bc[0].details[Object.keys(bc[0].details)[0]][0]
      }
    } else if (bc.every((b: { spotCountry: null | string; }) => b.spotCountry === null)) {
      this.bcError = {
        error: 'Business case could not be generated',
        errorMsg: ''
      }
    }
  }
}
