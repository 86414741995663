import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-side-content-layout',
  template: `
    <div class="bg-blue-200 w-full h-28">
      <ng-content></ng-content>
    </div>
  `,
})
export class TopSideContentLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
