import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from 'src/app/core/services/core.service';
import { InfoService } from 'src/app/core/services/infos.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UserService } from 'src/app/core/services/user.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-online-seller',
  templateUrl: './online-seller.component.html',
  styleUrls: ['./online-seller.component.scss']
})
export class OnlineSellerComponent implements OnInit {
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  createMode: boolean = true;
  sellerId: string | undefined;
  validatedVAT: boolean = false;

  countryIsoControl: FormControl = new FormControl('', Validators.required);
  vatControl: FormControl = new FormControl('', Validators.required);

  countryIsoCodes: DropdownOption[] = this.coreService.countries.map(co => ({ value: co.id, viewValue: co.iso }));
  countries: DropdownOption[] = this.coreService.countries.map(co => ({ value: co.id, viewValue: co.name }));

  kamUsers: DropdownOption[] = [];
  dateCreated: string | undefined;
  dateUpdated: string | undefined;
  statusControl = new FormControl({ value: false, disabled: true });
  vatStatusControl = new FormControl({ value: false, disabled: true });

  sellerDetailsForm = this.fb.group({
    name: ['', Validators.required],
    country: ['', Validators.required],
    city: ['', Validators.required],
    address: [''],
    email: ['', Validators.email],
    phone: [''],
    postalCode: ['']
  })

  constructor(private coreService: CoreService,
    private infoService: InfoService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private spinnerService: SpinnerHandlerService,
    private sellerService: SellerService,
    private userService: UserService,
    private location: Location,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['sellerId']) {
        this.createMode = false;
        this.sellerId = params['sellerId'];

        this.loadSeller();
      } else {
        this.loading.next(false);
      }
    })
  }

  loadSeller() {
    // this.sellerService.getSeller(this.sellerId!).subscribe(resp => {
    //   this.sellerDetailsForm.patchValue({
    //     name: resp.name,
    //     address: resp.address,
    //     email: resp.email,
    //     country: resp.country,
    //     city: resp.city,
    //     postalCode: resp.postalCode,
    //     phone: resp.phone
    //   })

    //   this.vatControl.setValue(resp.VAT);
    //   this.dateUpdated = resp.modifiedAt;
    //   this.dateCreated = resp.createdAt;
    //   this.statusControl.setValue(resp.status!);

    //   this.loading.next(false);
    // })
  }

  openCountriesAvailabilityModal() {
    this.infoService.openCountriesAvailabilityModal();
  }

  validateVAT() {
    let isoCode = this.countryIsoCodes.find(c => c.value === this.countryIsoControl.value)!.viewValue;

    if (this.vatControl.invalid || this.countryIsoControl.invalid || !this.infoService.checkVATNumber(isoCode, this.vatControl.value)) {
      this.snackbar.negativeSentiment("Invalid VAT, check VAT number and country code");

      return;
    }

    this.spinnerService.showProgressBar.next(true);
    this.infoService.validateVAT(this.vatControl.value, isoCode).subscribe({
      next: (resp) => {
        this.sellerDetailsForm.patchValue({
          name: resp.companyName,
          address: resp.address
        })

        this.sellerDetailsForm.controls.country.setValue(this.countryIsoControl.value);
        this.sellerDetailsForm.controls.country.disable();
        this.vatControl.disable();
        this.validatedVAT = true;
        this.vatStatusControl.setValue(true);

        this.spinnerService.showProgressBar.next(false);
      },
      error: (err) => {
        this.snackbar.negativeSentiment("VAT does not exist");
        this.spinnerService.showProgressBar.next(false);
      }
    });
  }

  resetVat() {
    this.vatControl.reset();
    this.vatControl.enable();
    this.countryIsoControl.reset();
    this.validatedVAT = false;
    this.sellerDetailsForm.controls.country.enable();
  }

  saveSeller() {
    // if (this.sellerDetailsForm.invalid) {
    //   this.snackbar.negativeSentiment('Complete all required fields');
    //   return;
    // }

    // let seller: Seller = new SellerClass(this.sellerDetailsForm.value);
    // seller.country = this.sellerDetailsForm.controls.country.value!;
    // seller.VAT = this.vatControl.value;
    // seller.vatStatus = this.vatStatusControl.value!;

    // if (this.createMode) {
    //   seller.status = true;

    //   this.spinnerService.showProgressBar.next(true);

    //   this.sellerService.createOnlineSeller(seller).subscribe(resp => {
    //     this.sellerId = resp;
    //     this.createMode = false;

    //     this.dateCreated = new Date().toString();
    //     this.dateUpdated = new Date().toString();

    //     this.statusControl.setValue(true);
    //     this.spinnerService.showProgressBar.next(false);
    //     this.location.replaceState(`seller/online-seller/${resp}`);
    //     this.snackbar.positiveSentiment('Seller created successfully!');
    //   })
    // } else {
    //   seller.status = this.statusControl.value!;

    //   this.sellerService.editOnlineSeller(seller, this.sellerId!).subscribe(resp => {
    //     this.snackbar.positiveSentiment('Seller edited successfully!');
    //   })
    // }
  }
}
