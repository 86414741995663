<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9">
    <div class="font-bold text-2xl">
      <p>Seller offer</p>
    </div>

    <div class="flex justify-between">
      <app-btn-toggle [options]="routes" [selectedOption]="page" (changeEmitter)="changePage($event)"></app-btn-toggle>

      <primary-btn *ngIf="offer && offer.status === sellerOfferStatus.OfferAccepted" id="publish-car"
        (clickEmitter)="publishCar()">Publish
        car</primary-btn>
    </div>

    <div [ngSwitch]="page" *ngIf="!(loading | async);else placeholder">
      <app-seller-offer-details (offerEmitter)="changeStatus($event)" *ngSwitchCase="'details'"
        [offer]="offer"></app-seller-offer-details>

      <app-seller-offer-businesscase *ngSwitchCase="'bc'" [offer]="offer"></app-seller-offer-businesscase>

      <app-seller-offer-vin *ngSwitchCase="'techSpecs'" [offer]="offer"></app-seller-offer-vin>
    </div>
  </div>

  <ng-template #placeholder>
    <div class="twoCols">
      <app-main-content-placeholder></app-main-content-placeholder>

      <app-main-content-placeholder></app-main-content-placeholder>
    </div>
  </ng-template>
</app-page-layout>
