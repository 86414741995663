<div class="twoCols">
  <div class="col-span-2 flex justify-end" *ngIf="isAdmin && !editMode">
    <secondary-btn id="save-buyer" (clickEmitter)="enterEditMode()">Edit seller</secondary-btn>
  </div>

  <div class="col-span-2 flex justify-end" *ngIf="editMode">
    <secondary-btn id="save-buyer" (clickEmitter)="saveSeller()">Save seller</secondary-btn>
  </div>

  <app-main-content-layout>
    <div class="title">
      <p>Seller details</p>
    </div>

    <div class="grid grid-cols-4 gap-4 border-b border-white-500 pb-6 items-center">
      <app-input id="vat" [label]="'VAT'" placeholder="Insert VAT" class="col-span-1"
        [control]="vatControl"></app-input>
    </div>

    <app-main-content-row-dropdown *ngIf="isAdmin;else kamEmail" title="KAM" componentId="kam" placeholder="Select KAM"
      [control]="sellerDetailsForm.controls.kamId" [options]="kamUsers"></app-main-content-row-dropdown>

    <ng-template #kamEmail>
      <app-main-content-row-layout>
        <p>KAM</p>

        <app-read-only-input class="secondContent">{{user.email}}</app-read-only-input>
      </app-main-content-row-layout>
    </ng-template>

    <app-main-content-row-layout>
      <p>Company name*</p>
      <app-input id="company-name" class="secondContent" placeholder="Insert company name"
        [control]="sellerDetailsForm.controls.name"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Seller type*</p>
      <app-dropdown id="seller-type" class="secondContent" placeholder="Select seller type"
        [control]="sellerDetailsForm.controls.sellerType" [options]="sellerTypes">
      </app-dropdown>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Address*</p>
      <app-input id="address" class="secondContent" placeholder="Insert address"
        [control]="sellerDetailsForm.controls.address"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Region*</p>
      <div class="secondContent twoCols">
        <app-dropdown id="countries" class="col-span-1" [options]="countries" placeholder="Select country"
          [control]="sellerDetailsForm.controls.country"></app-dropdown>
        <app-input id="city" class="col-span-1" placeholder="Insert city"
          [control]="sellerDetailsForm.controls.city"></app-input>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>ZIP Code*</p>
      <app-input id="postalcode" class="secondContent" placeholder="Insert zip code"
        [control]="sellerDetailsForm.controls.postalCode"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Email*</p>
      <app-input id="email" class="secondContent" placeholder="Insert email"
        [control]="sellerDetailsForm.controls.email"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Phone*</p>
      <app-input id="phone" class="secondContent" placeholder="Insert phone"
        [control]="sellerDetailsForm.controls.phone"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Website*</p>
      <app-input id="website" class="secondContent" placeholder="Insert website"
        [control]="sellerDetailsForm.controls.website"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Makes sold</p>
      <app-dropdown id="makes-sold" class="secondContent" placeholder="Select makes sold"
        [control]="sellerDetailsForm.controls.makesSold" [multipleSelect]="true" [options]="makes">
      </app-dropdown>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Makes represented</p>
      <app-dropdown id="makes-represented" class="secondContent" placeholder="Select makes represented"
        [control]="sellerDetailsForm.controls.makesRep" [multipleSelect]="true" [options]="makes">
      </app-dropdown>
    </app-main-content-row-layout>
  </app-main-content-layout>

  <div class="flex flex-col gap-6">
    <app-main-content-layout>
      <div class="title">
        <p>Seller info</p>
      </div>

      <app-main-content-row-layout>
        <p>Status</p>
        <app-toggle id="status" class="secondContent" [toggleFormControl]="statusControl"></app-toggle>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>VAT Status</p>
        <app-toggle id="vat-status" class="secondContent" [toggleFormControl]="vatStatusControl"></app-toggle>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Date created</p>

        <app-read-only-input class="secondContent" *ngIf="dateCreated">
          {{dateCreated | date:'dd/MM/yyyy'}}
        </app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Last updated</p>

        <app-read-only-input class="secondContent" *ngIf="dateUpdated">
          {{dateUpdated | date:'dd/MM/yyyy'}}
        </app-read-only-input>
      </app-main-content-row-layout>

      <div>
        <p class="font-bold mb-7">List of stores</p>
        <div class="p-6 bg-white-400 rounded flex flex-col gap-3 max-h-52 overflow-auto">
          <div *ngFor="let store of stores" class="flex gap-4 items-center">
            <div class="rounded-[100%] w-[6px] h-[6px] bg-blue-100"></div>
            {{store.name}}
          </div>
        </div>
      </div>
    </app-main-content-layout>
  </div>
</div>
