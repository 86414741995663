import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CarBusinessCase, CarDetails, CarGroupJourney } from 'src/app/core/models/car.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { SettingsService } from 'src/app/core/services/settings.service';
import { FormControl } from '@angular/forms';
import { TransportMatrix } from '../../settings/transport-matrix/transport-matrix.component';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { UtilsService } from 'src/app/core/services/utils.service';
import { UserService } from 'src/app/core/services/user.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { VATType } from 'src/app/core/models/info.model';
import { SimpleResponse } from 'src/app/core/models/request.model';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-car-view-page',
  templateUrl: './car-view-page.component.html',
  styleUrls: ['./car-view-page.component.scss']
})

export class CarViewPageComponent implements OnInit {
  loading = new BehaviorSubject(true);
  carPhotos: string[] = [];
  car: CarDetails | undefined;

  countries: DropdownOption[] = this.coreService.countriesDropdown;

  equipments: string[] = [];

  journeys: CarGroupJourney[] = [];

  journeyGroups: SimpleResponse[] = [];

  vats = VATType;

  bcTableHeaderMap: TableHeaderMap[] = [
    {
      tableView: 'country',
      value: 'spotCountry'
    },
    {
      tableView: 'Sales factor',
      value: 'salesFactor'
    },
    {
      tableView: 'Retail price ex. VAT',
      value: 'spotPrice'
    },
    {
      tableView: 'cars Sold 30Days',
      value: 'carsSold30Days'
    },
    {
      tableView: 'cars Online',
      value: 'carsOnline'
    },
  ];

  tmTableHeaderMap: TableHeaderMap[] = [
    {
      tableView: 'Destination country',
      value: 'countryTo'
    },
    {
      tableView: 'transport Cost',
      value: 'transportCost'
    },
    {
      tableView: 'priceIncluding Transport',
      value: 'priceIncludingTransport'
    }
  ];

  bcDisplayedColumns = this.bcTableHeaderMap.map(h => h.value);
  tmDisplayedColumns = this.tmTableHeaderMap.map(h => h.value);

  isAdmin = this.coreService.isAdmin;

  constructor(private carService: CarService,
    private activatedRoute: ActivatedRoute,
    private coreService: CoreService,
    private settingsService: SettingsService,
    private utilsService: UtilsService,
    private router: Router,
    private spinner: SpinnerHandlerService,
    private userService: UserService,
    private snackbar: SnackbarService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['carId']) {
        let carId = params['carId'];

        forkJoin({
          masterCarEq: this.carService.getCarMasterEquipments(carId),
          carCustomEq: this.carService.getCarCustomEquipments(carId),
          photos: this.carService.getCarPhotos(carId),
          car: this.carService.getCarDetails(carId),
          statuses: this.carService.getCarStatuses(),
          sources: this.carService.getCarSources(),
          groups: this.carService.getCarGroups()
        }).subscribe(resp => {
          this.car = {
            ...resp.car,
            sourceName: resp.sources.find(s => s.id === resp.car.source)!.name,
          };

          this.car.transportMatrix.forEach(tm => tm.countryTo = this.coreService.countries.find(c => c.iso === tm.countryTo)!.name);

          this.journeyGroups = resp.groups;

          this.journeys = resp.car.carGroupJourney.map(j => ({
            ...j,
            dateFrom: this.utilsService.utcDateToLocal(j.dateFrom),
            dateTo: this.utilsService.utcDateToLocal(j.dateTo)
          }));

          this.carPhotos = resp.photos.length > 0 ? resp.photos.map(p => p.originalPhoto!) : [resp.car.profilePhoto];

          if (resp.masterCarEq.optionalEquipment) {
            this.utilsService.equipmentArrayTransform(this.equipments, resp.masterCarEq.optionalEquipment);
            this.utilsService.equipmentArrayTransform(this.equipments, resp.masterCarEq.standardEquipment);
            this.utilsService.equipmentArrayTransform(this.equipments, resp.masterCarEq.packEquipment);
          }

          //add car custom eq to list
          this.equipments = [...this.equipments, ...resp.carCustomEq.customEquipment.map(c => this.utilsService.splitWordByUnderline(c))];

          this.loading.next(false);
        });
      }
    })
  }

  goToCar() {
    this.router.navigate([`car/car-details/${this.car!.carMainInfoId}`])
  }

  goToSearch() {
    this.router.navigate([`shop`])
  }

  addToCart() {
    this.userService.addToCart(this.car!.carMainInfoId).subscribe({
      next: resp => this.snackbar.positiveSentiment(resp),
      error: err => this.snackbar.negativeSentiment(err.error)
    })
  }

  downloadDoc(docId: string) {
    this.spinner.showProgressBar.next(true);

    this.carService.downloadCarDocument(this.car!.carMainInfoId, docId).subscribe({
      next: resp => {
        window.open(resp, "_blank");

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  getJourneyName(j: CarGroupJourney) {
    return this.journeyGroups.find(g => g.id === j.groupId)?.name;
  }
}
