<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 pr-6">
  <p class="text-2xl">Make Counter offer</p>

  <div class="flex justify-between gap-6 relative">
    <app-read-only-input>Current offer: €{{currentOffer | number}}</app-read-only-input>

    <app-input-number [control]="priceControl" label="New offer" placeholder="Insert counter offer"
      id="counter-offer-modal"></app-input-number>

    <p *ngIf="priceControl.invalid && priceControl.touched" class="absolute text-xs text-red -bottom-8">Please insert
      counter offer value</p>
  </div>

  <div class="flex justify-end">
    <app-blue-btn id="counter-offer-modal" (clickEmitter)="sendCounterOffer()">Send counter offer</app-blue-btn>
  </div>
</div>
