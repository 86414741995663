<div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
  <app-main-content-layout>
    <div class="flex justify-between">
      <div>
        <svg class="thirdTarget" width="78" height="38" viewBox="0 0 78 38" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M-0.000353897 37.275C-0.000354413 31.3574 1.5547 25.544 4.50862 20.4186C7.46255 15.2932 11.7114 11.0363 16.8283 8.07537L33.6815 37.275L-0.000353897 37.275Z"
            fill="#D4D4D4" />
          <path
            d="M78.0004 37.275C78.0004 31.3574 76.4453 25.544 73.4914 20.4186C70.5374 15.2932 66.2886 11.0363 61.1717 8.07537L44.3185 37.275L78.0004 37.275Z"
            fill="#D4D4D4" />
          <path
            d="M56.7267 4.51825C51.3392 1.55946 45.2279 0.00120146 39.0069 -4.26722e-05C32.7858 -0.00127918 26.674 1.55454 21.2851 4.51119L38.9995 33.725L56.7267 4.51825Z"
            fill="#D4D4D4" />
        </svg>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-12">
      <div class="col-span-1 flex flex-col gap-6">
        <app-main-content-row-layout>
          <p>Make</p>

          <app-read-only-input class="secondContent">{{car!.make}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Model</p>

          <app-read-only-input class="secondContent">{{car!.model}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Manufacture year</p>

          <app-read-only-input class="secondContent">{{car!.manufactureYear}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Variant</p>

          <app-read-only-input class="secondContent">{{car!.variant}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Trim</p>

          <app-read-only-input class="secondContent">{{car!.trim}}</app-read-only-input>
        </app-main-content-row-layout>
      </div>

      <div class="col-span-1 flex flex-col gap-6 pl-12 border-l border-white-500">
        <app-main-content-row-layout>
          <p>Damages</p>

          <app-read-only-input class="secondContent">{{car!.damages ? car!.damages : 0 | number}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Car price</p>

          <app-read-only-input class="secondContent">{{carPrice | number}}€</app-read-only-input>
        </app-main-content-row-layout>
      </div>
    </div>
  </app-main-content-layout>

  <!-- <tertiary-btn id="export-table" class="flex w-max" btnText="Export table"></tertiary-btn> -->

  <app-table *ngIf="bc.length > 0;else noBc" [data]="bc" [displayedColumns]="displayedColumns"
    [headersMap]="headersMap"></app-table>

  <ng-template #noBc>
    <div>
      No business case
    </div>
  </ng-template>
</div>

<ng-template #placeholder>
  <div class="flex flex-col gap-9">
    <app-main-content-placeholder></app-main-content-placeholder>

    <app-table-placeholder></app-table-placeholder>
  </div>
</ng-template>
