<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <div class="flex justify-between items-center">
    <p class="text-2xl">{{car.carConfig.make}} {{car.carConfig.model}}</p>

    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close()"></fa-icon>
  </div>

  <div class="pb-4 border-b border-white-500">
    <p class="font-bold">Pricing info</p>
  </div>

  <div class="flex gap-6">
    <app-dropdown [options]="currencies" [control]="currencyControl" label="Currency"></app-dropdown>

    <app-input-number id="buying-price-exchange" [control]="buyingPriceControl" label="Value"
      placeholder="Insert value"></app-input-number>

    <app-input id="rate-exchange" [control]="rateControl" label="Rate" placeholder="Insert rate"></app-input>

    <app-read-only-input class="w-[12%] 2xl:w-1/12" label="Currency">EUR</app-read-only-input>

    <app-read-only-input label="Value">{{car.carPricing.actualBuyingPriceEuro | number}}</app-read-only-input>
  </div>

  <secondary-btn (clickEmitter)="getExchangeRate()" class="w-fit" *ngIf="!finishedOrder">
    Get exchange rate
  </secondary-btn>

  <div class="grid grid-cols-2 gap-6">
    <p>Offered price</p>

    <app-read-only-input>
      <p>
        {{car.carPricing.offerDetails.offeredValue ? (car.carPricing.offerDetails.offeredValue| number) : 'No offer'}}
      </p>
    </app-read-only-input>


    <p>Buying price</p>

    <app-read-only-input>€{{car.carPricing.buyingPrice| number}}</app-read-only-input>

    <p>Actual buying price</p>

    <app-read-only-input>{{car.carPricing.actualBuyingPrice| number}} {{currencyControl.value}}</app-read-only-input>

    <p *ngIf="currencyControl.value != 'EUR'">Actual buying price EURO</p>

    <app-read-only-input *ngIf="currencyControl.value != 'EUR'">{{car.carPricing.actualBuyingPriceEuro | number}}
      EUR</app-read-only-input>

    <p>Buying invoice VAT ratio</p>

    <app-read-only-input>25%</app-read-only-input>

    <p>Buyer country VAT deposit reclaim</p>

    <app-read-only-input>€3.000</app-read-only-input>

    <p>Min. selling price</p>

    <app-read-only-input>€{{car.carPricing.minSellingPrice| number}}</app-read-only-input>

    <p>Online selling price</p>

    <app-read-only-input>€{{car.carPricing.shopPrice | number}}</app-read-only-input>

    <p>Selling price VAT</p>

    <app-read-only-input>{{car.vatString}}</app-read-only-input>

    <p>Invoice selling price</p>

    <app-input-number [control]="invoicePriceControl"></app-input-number>

    <div class="col-span-2 bg-gray-100 p-9"
      *ngIf="car.carPricing.offerDetails.offeredValue && car.carPricing.offerDetails.offeredValue > 0 && invoicePriceControl.touched">
      <p class="font-bold pb-6 border-b border-white-500">Offer warning</p>

      <p class="my-9">This car already has an active offer, editing the Invoice selling price will lead to an offer
        cancelling and
        you may lose the offered price.</p>

      <p> In order to update this car you must confirm this setting first.</p>

      <div class="w-full flex justify-end">
        <p (click)="offerWarningUnderstood = true" class="px-9 py-3 text-white bg-red rounded text-sm cursor-pointer"
          *ngIf="!offerWarningUnderstood;else understood">I
          understand</p>

        <ng-template #understood>
          <div class="flex items-center gap-1 text-green-100 text-sm px-9 py-3">
            <fa-icon [icon]="['fas','circle-check']"></fa-icon>
            <p> Understood</p>
          </div>
        </ng-template>
      </div>
    </div>

    <ul class="pl-5 list-disc text-blue-200 col-span-2 flex flex-col gap-3">
      <li>Offered price is the amount negotiated in the offer stage.</li>
      <li>Buying price is the amount of the car when it was imported from cars inventory.</li>
      <li>Minimum selling price is an indicator which is calculating the rentability of the car. When the car comes from
        offer stages, this amount can be greater or equal to the amount negotiated. In other cases, the selling price
        cannot be greater or equal than Invoice selling price.</li>
      <li>Online selling price is the amount present in the online customer shop.</li>
      <li>Invoice selling price is the amount that closes the deal. It cannot be less or equal than Min. selling price
        unless an offer was made on this car. </li>
    </ul>
  </div>

  <div class="grid grid-cols-2 gap-6">
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">COC info</p>

    <p>COC required</p>

    <app-toggle class="justify-self-end" id="required-coc" [toggleLabel]="reqCOCControl.value ? 'Yes' : 'No'"
    [toggleFormControl]="reqCOCControl" [toggleDisabled]="true"></app-toggle>

    <p>COC by seller</p>

    <app-toggle class="justify-self-end" [toggleLabel]="cocBySeller.value ? 'Yes' : 'No'"
      (toggleEmitter)="toggleCOCBySeller($event)" [toggleFormControl]="cocBySeller"></app-toggle>

    <p>COC cost value</p>

    <app-input-number id="coc-value" [control]="cocValueControl"></app-input-number>

    <p>COC from</p>

    <app-input [control]="cocFromValue" placeholder="Insert COC from"></app-input>

    <ul class="pl-5 list-disc text-blue-200 col-span-2 flex flex-col gap-3">
      <li>Requiring COC file enhances car transit thru custom checkpoints. The seller can provide it or it can be
        requested by us or arranged by the buyer.</li>
    </ul>
  </div>

  <div class="grid grid-cols-2 gap-6">
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">Photo editing</p>

    <app-toggle id="required-photo-edit" (toggleEmitter)="togglePhotoEdit($event)" toggleLabel="Requires photo edit"
      [toggleFormControl]="reqPhotoEditControl" class="col-span-2"></app-toggle>

    <p>Photo edit client invoiced value</p>

    <app-read-only-input>€{{car.photoEditing.photoEditValue | number}}</app-read-only-input>

    <p>Photo edit cost value</p>

    <app-input-number id="photo-edit-value" [control]="photoEditValueControl"></app-input-number>
  </div>

  <div class="grid grid-cols-2 gap-6">
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">Damage info</p>

    <p>Initial declared damage</p>

    <app-read-only-input>{{car.carConfig.damages| number}}</app-read-only-input>

    <p>Final damage</p>

    <app-input-number id="final-damages" [control]="finalDamageControl"></app-input-number>

    <ul class="pl-5 list-disc text-blue-200 col-span-2 flex flex-col gap-3">
      <li>Initial declared damage is the one coming from car inventory and Final damage is the one that may occur
        between the buying stage and selling stage. Final damage cannot be empty.</li>
    </ul>
  </div>

  <div class="grid grid-cols-2 gap-6">
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">Transportation info</p>

    <app-toggle id="transport-by-c2c-modal" [toggleFormControl]="transportControl" toggleLabel="Transport by Cars2click"
      class="col-span-2"></app-toggle>

    <p>Transportation invoiced value</p>

    <app-read-only-input>{{car.carPricing.transportationFee| number}}</app-read-only-input>

    <p>Transportation cost value</p>

    <app-read-only-input>{{car.carPricing.transportationActualFee | number}}</app-read-only-input>

    <ul class="pl-5 list-disc text-blue-200 col-span-2 flex flex-col gap-3">
      <li>Transportation is not required and can differ from each country. Please refer to Transportation Matrix to get
        an accurate quotation.</li>
    </ul>
  </div>

  <div class="grid grid-cols-2 gap-6">
    <p class="col-span-2 pb-3 border-b border-white-500 font-bold">Profit info</p>

    <p>Current profit</p>

    <app-read-only-input>
      <p class="text-green-100">{{calculateProfit() | number}}</p>
    </app-read-only-input>

    <ul class="pl-5 list-disc text-blue-200 col-span-2 flex flex-col gap-3">
      <li>The current profit is the following result:<br><span class="font-bold">
          (Invoice selling price) - (Actual Buying price EURO) - (COC cost value) +
          (Photo edit invoiced value) - (Photo edit cost value)
          + (Transportation invoiced value) - (Transportation cost value) = Current profit
        </span></li>
    </ul>
  </div>

  <div class="flex justify-end" *ngIf="!finishedOrder">
    <primary-btn id="update-price-settings" [blueBtn]="true" (clickEmitter)="update()">Update</primary-btn>
  </div>
</div>
