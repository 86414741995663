import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { ISellerStorePickup } from 'src/app/core/models/seller.model';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { BtnToggleOption } from 'src/app/shared/app-btn-toggle/app-btn-toggle.component';
import hours from 'src/assets/json-files/hours.json';

export interface WindowPickupForm {
  id: string,
  name: string,
  from: FormControl,
  to: FormControl,
  checkbox: boolean
}

@Component({
  selector: 'app-seller-store-restrictions',
  templateUrl: './seller-store-restrictions.component.html',
  styleUrls: ['./seller-store-restrictions.component.scss']
})

export class SellerStoreRestrictionsComponent implements OnInit {
  createMode: boolean = true;
  sellerId = this.b2bSyncService.sellerId;

  public saveFormSubscription: Subscription = new Subscription();
  public routeSubscription: Subscription = new Subscription();

  stores: BtnToggleOption[] = this.b2bSyncService.sellerStores.map(s => ({ value: s.tempId!, viewValue: s.name! }));
  storeControl = new FormControl(this.stores[0].value);
  pickupWindows = this.b2bSyncService.pickupWindows;

  hours = hours;
  notes: string = "";

  country = '';
  countryBankHolidaysControl = new FormControl();

  windows: WindowPickupForm[] = [];

  constructor(private b2bSyncService: B2BSellerSyncService,
    private snackbar: SnackbarService,
    private coreService: CoreService) { }

  ngOnInit(): void {
    this.windows = this.pickupWindows.map(dw => ({
      id: dw.id,
      name: dw.period,
      from: new FormControl(),
      to: new FormControl(),
      checkbox: false
    }));

    this.loadStore(this.stores[0].value);

    this.routeSubscription = this.b2bSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.b2bSyncService.setCurrentTab(value);
      }
    )

    this.saveFormSubscription = this.b2bSyncService.saveFormEvent$.subscribe((value) => {
      if (this.checkWindows()) {
        this.snackbar.negativeSentiment('Complete hours for the periods selected');

        return;
      } else if (!this.checkStoreWindows()) {
        this.snackbar.negativeSentiment('Every store must have at least one pickup program saved');

        return;
      }

      this.b2bSyncService.createSeller();
    });
  }

  ngOnDestroy() {
    this.saveFormSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  saveRestriction() {
    if (this.checkWindows()) {
      this.snackbar.negativeSentiment('Complete hours for the periods selected');

      return;
    }

    let windows = this.windows.filter(w => w.checkbox);

    windows.forEach(w => {
      let restriction: ISellerStorePickup = this.b2bSyncService.sellerPickups.find(r => r.store === this.storeControl.value && r.period.periodId === w.id)!;

      restriction.note = this.notes;
      restriction.from = `${w.from.value}:00:00`;
      restriction.to = `${w.to.value}:00:00`;

      this.snackbar.positiveSentiment('Program saved!');
    });
  }

  loadStore(storeId: string) {
    if (this.checkWindows()) {
      this.snackbar.negativeSentiment('Complete hours for the periods selected');

      return;
    }

    this.resetForm();

    let store = this.b2bSyncService.sellerStores.find(s => s.tempId === storeId)!;

    this.countryBankHolidaysControl.setValue(store.countryBank);
    this.country = this.coreService.countries.find(c => c.id === store.countryId)!.name;

    let restrictions = this.b2bSyncService.sellerPickups.filter(r => r.store === storeId);

    restrictions.forEach(r => {
      let window = this.windows.find(w => w.id === r.period.periodId)!;

      window.checkbox = true;
      window.from.setValue(r?.from?.split(":")[0]);
      window.to.setValue(r?.to?.split(":")[0]);
    })

    if (restrictions.length > 0) {
      this.notes = restrictions[0].note!;
    }
  }

  modifyWindow(event: MatCheckboxChange, periodId: string) {
    if (event.checked) {
      this.b2bSyncService.sellerPickups.push({
        store: this.storeControl.value!,
        period: {
          period: '',
          periodId: periodId
        },
        from: '',
        to: '',
        note: ''
      });
    } else {
      this.b2bSyncService.sellerPickups.splice(this.b2bSyncService.sellerPickups.findIndex(r => r.period.periodId === periodId && r.store === this.storeControl.value), 1);

      let window = this.windows.find(w => w.id === periodId);
      window?.from.reset();
      window?.to.reset();
    }
  }

  resetForm() {
    this.countryBankHolidaysControl.reset();
    this.notes = "";

    this.resetAllWindows();
  }

  resetAllWindows() {
    this.windows.forEach(w => {
      w.checkbox = false;
      w.from.reset();
      w.to.reset();
    })
  }

  getStoreCountry(storeId: string) {
    let countryId = this.b2bSyncService.sellerStores.find(s => s.tempId === storeId)!.countryId;

    return this.coreService.countries.find(c => c.id === countryId)!.name;
  }

  checkWindows(): boolean {
    let windows = this.windows.filter(w => w.checkbox);

    return windows.some(w => !w.from.value || !w.to.value);
  }

  checkStoreWindows(): boolean {
    return this.b2bSyncService.sellerStores.every(s => this.b2bSyncService.sellerPickups.find(r => r.store === s.tempId));
  }
}
