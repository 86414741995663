import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { OrderCar } from 'src/app/core/models/order.model';
import { OffersService } from 'src/app/core/services/offers.service';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

export interface AddCarsFromOffersModalData {
  clientId: string
}

@Component({
  selector: 'app-add-cars-from-offers-modal',
  templateUrl: './add-cars-from-offers-modal.component.html',
  styleUrls: ['./add-cars-from-offers-modal.component.scss']
})
export class AddCarsFromOffersModalComponent implements OnInit {
  loading = new BehaviorSubject(true);
  clientId = this.data.clientId;
  cars: OrderCar[] = [];

  headers: TableHeaderMap[] = [
    {
      value: 'sellerName',
      tableView: 'seller'
    },
    {
      value: 'sellingPrice',
      tableView: 'price'
    },
    {
      value: 'vatType',
      tableView: 'vat type'
    },
    {
      value: 'regNo',
      tableView: 'Registration number'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'firstReg',
      tableView: 'First registration'
    },
    {
      value: 'offeredPrice',
      tableView: 'offered price'
    },
    {
      value: 'color',
      tableView: 'color'
    }
  ]

  displayColumn = ['selectAll'].concat(this.headers.map(h => h.value));

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;

  constructor(public dialogRef: MatDialogRef<AddCarsFromOffersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddCarsFromOffersModalData,
    private offerService: OffersService) { }

  ngOnInit(): void {
    this.offerService.getAcceptedOffers(this.clientId).subscribe(resp => {
      this.cars = resp.map(c => ({
        ...c,
        vatType: c.vatString,
        isSelected: false
      }));

      this.loading.next(false);
    })
  }

  addCars() {
    this.dialogRef.close(this.carsTable?.dataSource.data.filter(c => c.isSelected));
  }

  close() {
    this.dialogRef.close();
  }
}
