<div class="overflow-scroll max-h-[80vh] scroll-x overflow-x-hidden flex flex-col gap-9 px-6">
  <p class="font-bold text-2xl">Add cars to transport</p>

  <div class="grid grid-cols-3 gap-x-6 gap-y-9 items-center relative pb-9">
    <app-dropdown class="col-span-2" placeholder="Select transport" [options]="transports"
      [control]="transportControl"></app-dropdown>

    <app-blue-btn id="create-transport-modal" (clickEmitter)="addCars()">Add cars to transport</app-blue-btn>

    <p *ngIf="transportControl.invalid && transportControl.touched" class="col-span-3 text-red text-sm absolute bottom-0">Select transport
    </p>
  </div>
</div>
