import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BuyerSyncService } from 'src/app/core/services/buyer-flow-sync.service';
import { BtnToggleOption } from 'src/app/shared/app-btn-toggle/app-btn-toggle.component';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';

const routes = [
  {
    viewValue: "Main company",
    value: "company",
  },
  {
    viewValue: "Stores",
    value: "stores",
  },
  {
    viewValue: "Users",
    value: "users",
  },
  {
    viewValue: "Contacts",
    value: "contacts",
  },
  {
    viewValue: "Restrictions",
    value: "restrictions",
  }
]

@Component({
  selector: 'app-view-buyer',
  templateUrl: './view-buyer.component.html',
  styleUrls: ['./view-buyer.component.scss'],
  providers: [BuyerSyncService]
})
export class ViewBuyerComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  routes: BtnToggleOption[] = routes;

  public loadingInfo: BehaviorSubject<boolean> = this.buyerSyncService.loadingInfo;
  public page: string = '';

  isAdmin = this.coreService.isAdmin;

  constructor(private activatedRoute: ActivatedRoute,
    private buyerSyncService: BuyerSyncService,
    private coreService: CoreService,
    private location: Location,
    private spinnerService: SpinnerHandlerService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      if (params['page'] && this.routes.find((r: { value: string; }) => r.value === params['page'])) {
        this.page = this.routes.find((r: { value: string; }) => r.value === params['page'])!.value;
      }

      if (params['buyerId']) {
        this.buyerSyncService.buyerId = params['buyerId'];

      } else {
        this.page = 'company';
        this.location.replaceState(`buyer/company`);
      }

      this.buyerSyncService.loadInfo();
    }));

    this.subscriptions.add(this.buyerSyncService.currentTab$.subscribe(
      (page) => {
        let route = this.routes.find((r: { value: string; }) => r.value === page);

        if (route) {
          this.page = route.value;

          this.location.replaceState(`buyer/${this.page}/${this.buyerSyncService.buyerId}`);
        }
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveForm(event: MouseEvent) {
    this.buyerSyncService.emitSaveEvent(event);
  }

  navigateTo(page: string) {
    if (this.spinnerService.showProgressBar.value) return;

    this.buyerSyncService.changeTab(page);
  }
}
