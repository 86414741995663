<div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
  <app-dropdown id="sellers" (emitOnChangeSelect)="selectSeller($event)" [control]="sellerControl"
    [options]="sellersDropdown" class="flex" placeholder="Select seller" label="Seller"></app-dropdown>

  <app-expansion-panel title="Seller details" [expandedPanel]="true" panelId="sellerPanel">
    <div class="py-7 grid grid-cols-2 gap-6 border-t border-white-300">
      <div class="pr-6 border-r border-white-300 flex flex-col gap-6">
        <app-main-content-row-layout>
          <p>Seller name</p>

          <app-read-only-input class="secondContent">{{selectedSeller!.sellerCompanyDetails.name}}</app-read-only-input>
        </app-main-content-row-layout>
        <app-main-content-row-layout>
          <p>Country</p>

          <app-read-only-input
            class="secondContent">{{selectedSeller!.sellerCompanyDetails.countryDetails.country}}</app-read-only-input>
        </app-main-content-row-layout>
        <app-main-content-row-layout>
          <p>VAT</p>

          <app-read-only-input
            class="secondContent">{{selectedSeller!.sellerCompanyDetails.vatDetails.vat}}</app-read-only-input>
        </app-main-content-row-layout>
        <app-main-content-row-layout>
          <p>Zipcode</p>

          <app-read-only-input
            class="secondContent">{{selectedSeller!.sellerCompanyDetails.postalCode}}</app-read-only-input>
        </app-main-content-row-layout>

      </div>

      <div class="flex flex-col gap-6">
        <app-main-content-row-layout>
          <p>Address</p>

          <app-read-only-input
            class="secondContent">{{selectedSeller!.sellerCompanyDetails.address}}</app-read-only-input>
        </app-main-content-row-layout>
        <app-main-content-row-layout>
          <p>KAM</p>

          <app-read-only-input class="secondContent">{{selectedSeller!.kamDetails.username}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Seller type</p>

          <app-read-only-input
            class="secondContent">{{selectedSeller!.sellerCompanyDetails.typeDetails.type}}</app-read-only-input>
        </app-main-content-row-layout>
      </div>
    </div>
  </app-expansion-panel>

  <div class="flex gap-6" *ngIf="!finishedOrder">
    <app-dropdown id="seller-store" [options]="stores" [control]="storeControl" label="Seller store"
      [disabled]="true"></app-dropdown>

    <app-white-btn id="pick-same-address" [disableBtn]="true">Pickup to same store</app-white-btn>

    <app-dropdown id="seller-contact" [options]="contacts" [control]="contactControl" [disabled]="true"
      label="Seller store contact"></app-dropdown>

    <app-white-btn id="same-contact" [disableBtn]="true">Contact from store</app-white-btn>
  </div>

  <app-table #carsTable [displayedColumns]="displayedColumns" [headersMap]="headers" [data]="cars"
    (objectEmitter)="openModal($event)"></app-table>
</div>

<ng-template #placeholder>
  <div class="flex flex-col gap-9">
    <app-main-content-placeholder></app-main-content-placeholder>

    <app-table-placeholder></app-table-placeholder>
  </div>
</ng-template>
