import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DisplayGrid, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { BehaviorSubject, Subscription, retry } from 'rxjs';
import { CarPhoto, ReorderCarPhotos, UploadCarPhoto } from 'src/app/core/models/car.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-car-images',
  templateUrl: './car-images.component.html',
  styleUrls: ['./car-images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CarImagesComponent implements OnInit {
  public loadingPage: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public saveFormSubscription: Subscription = new Subscription();
  public routeSubscription: Subscription = new Subscription();

  uploadControl = new FormControl();

  carPhotos: CarPhoto[] = [];

  options!: GridsterConfig;
  dashboard: GridsterItem[] = [];

  selectedPhoto = '';

  carId = this.carSyncService.carDetails!.carMainInfoId!;

  x = 0;
  y = 0;
  columns = 0;

  constructor(private carService: CarService,
    private carSyncService: CarFlowSyncService,
    private snackBar: SnackbarService,
    private cd: ChangeDetectorRef,
    private utilsService: UtilsService,
    private spinnerHandlerService: SpinnerHandlerService) { }

  ngOnInit() {
    this.routeSubscription = this.carSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.carSyncService.setCurrentTab(value);
      }
    )

    this.loadPhotos();

    this.options = {
      gridType: GridType.Fixed,
      displayGrid: DisplayGrid.None,
      fixedColWidth: 112,
      fixedRowHeight: 112,
      useBodyForBreakpoint: true,
      setGridSize: true,
      swap: true,
      compactType: 'compactLeft',
      pushItems: false,
      draggable: {
        enabled: true
      },
      resizable: {
        enabled: false
      }
    };
  }


  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  reorderPhotos(message?: string, photoBody?: ReorderCarPhotos) {
    if (this.spinnerHandlerService.showProgressBar.value && !photoBody) return;

    this.spinnerHandlerService.showProgressBar.next(true);

    let body: any = undefined;

    if (photoBody) {
      body = photoBody;
    } else {
      this.dashboard.sort(function (a, b) {
        return a.y - b.y || a.x - b.x;
      });

      let photos: CarPhoto[] = [];

      this.dashboard.forEach(ph => {
        if (ph['_id']) {
          photos.push(this.carPhotos.find(p => p._id === ph['_id'])!);
        }
      })

      body = {
        carMainInfoId: this.carId,
        photos: photos
      }
    }

    this.carService.reorderCarPhotos(body).subscribe({
      next: resp => {
        this.loadPhotos(message ? message : 'Photos reorder saved');
      },
      error: err => {
        this.snackBar.negativeSentiment(err.error);
      }
    });
  }

  loadPhotos(message?: string) {
    this.carService.getCarPhotos(this.carId).subscribe({
      next: (resp) => {
        this.carPhotos = resp;

        this.loadingPage.next(false);

        setTimeout(() => {
          let divWidth = parseFloat(window.getComputedStyle(document.getElementById('photoContainer')!, null).getPropertyValue('width').slice(0, -2));

          this.x = 0;
          this.y = 0;
          this.dashboard = [];

          this.columns = Math.floor(divWidth / 112);

          this.carPhotos.forEach(p => {

            this.dashboard.push({ x: this.x, y: this.y, cols: 1, rows: 1, originalPhoto: p.originalPhoto, _id: p._id, isChecked: false });

            this.y = this.x === (this.columns - 1) ? this.y + 1 : this.y;
            this.x = this.x === (this.columns - 1) ? 0 : this.x + 1;
          });

          this.cd.markForCheck();
        }, 0);

        this.spinnerHandlerService.showProgressBar.next(false);

        if (message) this.snackBar.positiveSentiment(message);
      },
      error: (err) => {
        this.snackBar.negativeSentiment('We couldn`t load photos, contact IT')
        this.loadingPage.next(false);
      }
    })
  }

  async handleFileSelect(evt: Event) {
    let photos: any[] = [];

    const target = evt.target as HTMLInputElement;

    const files = target.files as FileList;

    //converts all uploaded images in base64
    for (let i = 0; i < files.length; i++) {
      photos.push({
        originalPhoto: await this.utilsService.convertToBase64(files[i]),
        photoName: files[i].name
      });
    }

    photos.forEach(p => {
      this.dashboard.push({ x: this.x, y: this.y, cols: 1, rows: 1, originalPhoto: p.originalPhoto, photoName: p.photoName });

      this.y = this.x === (this.columns - 1) ? this.y + 1 : this.y;
      this.x = this.x === (this.columns - 1) ? 0 : this.x + 1;
    })

    this.cd.markForCheck();
  }

  importPhoto() {
    this.spinnerHandlerService.showProgressBar.next(true);

    this.carService.importCarStockPhoto(this.carId).subscribe({
      next: resp => {
        this.carPhotos = resp.photos;

        this.reorderPhotos('Photo imported', resp);
      },
      error: err => {
        this.spinnerHandlerService.showProgressBar.next(false);
        this.snackBar.negativeSentiment(err.error);
      }
    });
  }

  uploadPhoto() {
    if (this.spinnerHandlerService.showProgressBar.value) return;

    let photos = this.dashboard.filter(p => !p['_id']).map(p => ({ name: p['photoName']!, content: p['originalPhoto']! }));

    if (photos.length === 0) return;

    this.spinnerHandlerService.showProgressBar.next(true);

    let body: UploadCarPhoto = {
      carMainInfoId: this.carId,
      uploadedImages: photos
    };

    this.carService.uploadCarPhoto(body).subscribe({
      next: resp => {
        this.carPhotos = resp.photos;

        this.reorderPhotos('Photo uploaded', resp);
      },
      error: err => {
        this.spinnerHandlerService.showProgressBar.next(false);
        this.snackBar.negativeSentiment(err.error);
      }
    })
  }

  removePhotos() {
    if (this.spinnerHandlerService.showProgressBar.value) return;

    if (this.dashboard.filter(item => item['isChecked']).length === 0) {
      this.snackBar.negativeSentiment('No photos selected');

      return;
    }

    this.spinnerHandlerService.showProgressBar.next(true);

    this.dashboard = this.dashboard.filter(item => !item['isChecked']);

    let photos = this.carPhotos.filter(p => this.dashboard.find(item => item['_id'] === p._id));

    if (photos.length === this.carPhotos.length) {
      this.spinnerHandlerService.showProgressBar.next(false);

      return;
    }

    let body = {
      carMainInfoId: this.carId,
      photos: photos
    }

    this.carService.reorderCarPhotos(body).subscribe({
      next: resp => {
        this.loadPhotos('Photos removed');
      },
      error: err => {
        this.snackBar.negativeSentiment(err.error);
      }
    });
  }
}
