import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SellerContactDTO } from 'src/app/core/models/seller-models/seller-create.model';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import * as uuid from 'uuid';

@Component({
  selector: 'app-seller-store-contacts',
  templateUrl: './seller-store-contacts.component.html',
  styleUrls: ['./seller-store-contacts.component.scss']
})

export class SellerStoreContactsComponent implements OnInit {
  public routeSubscription: Subscription = new Subscription();
  public saveFormSubscription: Subscription = new Subscription();

  createMode: boolean = false;

  dateCreated = new Date();
  statusControl = new FormControl({ value: false, disabled: true });

  stores: DropdownOption[] = this.b2bSyncService.sellerStores.map(s => ({ viewValue: s.name!, value: s.tempId! }));
  storesControl = new FormControl();

  contactControl = new FormControl();
  contactsDropdown: DropdownOption[] = [];

  public contactForm = this.fb.group({
    firstName: ['', Validators.required],
    tempId: new FormControl<string | undefined>(undefined),
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: [''],
    status: [true],
    store: '',
    accountingEmail: [false],
    carPickupEmail: [false],
    documentsEmail: [false]
  })

  usersContact: string[] = [];

  constructor(private b2bSyncService: B2BSellerSyncService,
    private snackbar: SnackbarService,
    private fb: FormBuilder,
    private coreService: CoreService) { }

  ngOnInit(): void {
    this.storesControl.setValue(this.stores[0].value);
    this.selectStore(this.stores[0].value);
    this.getUserStoreContacts(this.stores[0].value);

    this.routeSubscription = this.b2bSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.b2bSyncService.checkStoreContacts() && value === 'restrictions') {
          this.snackbar.negativeSentiment('There are stores with no contacts! Please add at least one!');

          return;
        } else if (this.createMode) {
          let goNext = await this.coreService.openUnsavedChangesModal();

          if (goNext) {
            this.b2bSyncService.sellerContacts.splice(this.b2bSyncService.sellerContacts.length - 1, 1)

            this.b2bSyncService.setCurrentTab(value);
          }
        } else {
          this.b2bSyncService.setCurrentTab(value);
        }
      }
    );

    this.saveFormSubscription = this.b2bSyncService.saveFormEvent$.subscribe((value) => {
      if (this.b2bSyncService.checkStoreContacts()) {
        this.snackbar.negativeSentiment('There are stores with no contacts! Please add at least one!');
        return;
      } else if (this.contactForm.disabled) {
        this.b2bSyncService.setCurrentTab('restrictions');
      } else if (this.saveContact()) {
        this.b2bSyncService.setCurrentTab('restrictions');
      }
    });
  }

  ngOnDestroy() {
    this.saveFormSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  saveContact() {
    if (this.contactForm.invalid) {
      this.snackbar.negativeSentiment('You need to complete all required fields');
      return false;
    }

    let contact = new SellerContactDTO(this.contactForm.value);

    if (this.b2bSyncService.sellerContacts.find(c => c.tempId === contact.tempId)) {
      this.b2bSyncService.sellerContacts[this.b2bSyncService.sellerContacts.findIndex(c => c.tempId === contact.tempId)] = contact;
    } else {
      contact.tempId = uuid.v4();
      this.b2bSyncService.sellerContacts.push(contact);
    }

    this.loadContactDropdown(contact.store!);
    this.createMode = false;
    this.contactControl.setValue(contact.tempId);
    this.contactForm.controls.tempId.setValue(contact.tempId);

    this.contactForm.markAsPristine();

    this.snackbar.positiveSentiment('Contact saved');

    return true;
  }

  async selectStore(storeId: string) {
    if (this.createMode) {
      const currentContactId = this.contactControl.value;
      const currentStoreId = this.storesControl.value;

      let resp = await this.coreService.openUnsavedChangesModal();

      if (resp) {
        if (currentContactId) this.b2bSyncService.sellerContacts.splice(this.b2bSyncService.sellerContacts.findIndex(s => s.tempId === currentContactId), 1);
        this.createMode = false;

        this.continueChangeStoreFlow(storeId);
      } else {
        this.storesControl.setValue(currentStoreId)
        this.contactControl.setValue(currentContactId)
      }
    } else {
      this.continueChangeStoreFlow(storeId)
    }
  }

  continueChangeStoreFlow(storeId: string) {
    this.contactForm.reset();
    this.contactControl.reset();

    this.contactForm.controls.store.setValue(storeId);

    this.loadContactDropdown(storeId);
    this.getUserStoreContacts(storeId);

    let contacts = this.b2bSyncService.sellerContacts.filter(c => c.store === storeId);

    if (contacts.length > 0) {
      this.contactControl.setValue(contacts[0].tempId);
      this.contactForm.enable();
      this.changeContact(contacts[0].tempId!);
    } else {
      this.contactForm.disable();
      this.createMode = false;
    }
  }

  loadContactDropdown(storeId: string) {
    this.contactsDropdown = this.b2bSyncService.sellerContacts.filter(c => c.store === storeId).map(c => ({ viewValue: `${c.firstName} ${c.lastName}`, value: c.tempId! }));
  }

  addContact() {
    if (this.createMode) return this.snackbar.negativeSentiment('You already started to create a new contact!');

    this.createMode = true;

    const tempId = uuid.v4();

    this.b2bSyncService.sellerContacts.push(new SellerContactDTO({
      tempId: tempId,
      store: this.storesControl.value,
      firstName: 'New',
      lastName: 'Contact'
    }));

    this.contactsDropdown.push({ value: tempId, viewValue: 'New Contact' });

    this.contactControl.setValue(tempId);
    this.contactForm.enable();
    this.contactForm.reset({
      store: this.storesControl.value,
      tempId: tempId
    });
  }

  async changeContact(contactId: string) {
    if (this.createMode) {
      const currentContactId = this.contactControl.value;

      let resp = await this.coreService.openUnsavedChangesModal();

      if (resp) {
        if (currentContactId) this.b2bSyncService.sellerContacts.splice(this.b2bSyncService.sellerContacts.findIndex(s => s.tempId === currentContactId), 1);

        this.loadContactDropdown(this.storesControl.value);

        this.continueChangeContactFlow(contactId);
      } else {
        this.contactControl.setValue(currentContactId);
      }
    } else {
      this.continueChangeContactFlow(contactId);
    }
  }

  continueChangeContactFlow(contactId: string) {
    let contact = this.b2bSyncService.sellerContacts.find(c => c.tempId === contactId);

    this.patchContactForm(contact!);
  }

  patchContactForm(contact: SellerContactDTO) {
    this.contactForm.patchValue({
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phone,
      accountingEmail: contact.accountingEmail!,
      carPickupEmail: contact.carPickupEmail!,
      documentsEmail: contact.documentsEmail!,
      store: contact.store,
      tempId: contact.tempId
    });

    this.statusControl.setValue(true);

    this.createMode = false;
  }

  getUserStoreContacts(storeId: string) {
    let users = this.b2bSyncService.sellerUsers.filter(u => u.stores!.some(st => st.store === storeId && st.roles.includes('contact')));

    this.usersContact = users.map(u => u.email!);
  }
}
