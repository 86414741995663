<div class="flex flex-col gap-6">
  <p class="font-bold">Select the cars on which you want to open the claim</p>

  <div class="flex justify-between">
    <app-dropdown placeholder="Select claim status" label="Claim staus"></app-dropdown>

    <div class="flex justify-end gap-6">
      <secondary-btn>Archive claim</secondary-btn>
      <secondary-btn>Close claim</secondary-btn>
      <app-blue-btn>Create claim</app-blue-btn>
    </div>
  </div>

  <app-table [displayedColumns]="displayedColumns" [headersMap]="headers" [data]="cars"></app-table>
</div>
